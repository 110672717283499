import { TermsAndConditionsSection } from '..';

export const TERMS_AND_CONDITIONS_EN: TermsAndConditionsSection[] = [
  {
    title: 'Disclaimers',
    paragraphs: [
      'Do not rely on HighVizz, any information therein, or its continuation. We provide the platform for HighVizz and all information and services on an "as is" and "as available" basis. HighVizz does not control or vet user generated content for accuracy. We do not provide any express warranties or representations.',
      'To the fullest extent permissible under applicable law, we disclaim any and all implied warranties and representations, including, without limitation, any warranties of merchantability, fitness for a particular purpose, title, accuracy of data, and noninfringement. If you are dissatisfied or harmed by HighVizz or anything related to HighVizz, you may close your HighVizz account and terminate this agreement in accordance with section VI ("term and termination” of the terms and conditions) and such termination shall be your sole and exclusive remedy.',
      'All information shared on HighVizz remains the ownership of the party sharing it and is subject to the non-disclosure agreement implicit with the use of the service. For information to be shared outside the platform, the owner of the information will have to provide authorisation. HighVizz is therefore not liable for any information which is shared outside the platform without prior agreement of its owner. HighVizz is equally not liable for any information being stolen from the platform by any party.',
      'HighVizz is not responsible, and makes no representations or warranties for the delivery of any messages sent through HighVizz to anyone. In addition, we neither warrant nor represent that your use of the service will not infringe the rights of third parties. Any material, service, or technology described or used on the website may be subject to intellectual property rights owned by third parties who have licensed such material, service, or technology to us.',
      'HighVizz does not have any obligation to verify the identity of the users using the service, nor does it have any obligation to monitor the use of the service by other users; therefore, HighVizz disclaims all liability for identity theft or any other misuse of your identity or information. HighVizz does not guarantee that the service will function without interruption or errors in functioning. In particular, the operation of the service may be interrupted due to maintenance, updates, or system or network failures. HighVizz disclaims all liability for damages caused by any such interruption or errors in functioning. Furthermore, HighVizz disclaims all liability for any malfunctioning, impossibility of access, or poor use conditions of the site due to inappropriate equipment, disturbances related to internet service providers, to the saturation of the internet network, and for any other reason.',
    ],
  },
  {
    title: 'Privacy and Copyright Policy',
    paragraphs: [
      'This Privacy Policy applies to all of the products, services and websites offered by HighVizz Inc. or its subsidiaries or affiliates',
      'If you have any questions about this Privacy Policy, please feel free to contact us by email at: info@HighVizz.com',
      {
        header: 'We may collect the following types of information:',
        listItems: [
          'Information you provide - When you sign up for a HighVizz Account, we ask you for personal information. We may combine the information you submit under your account with information from other HighVizz services or third parties in order to provide you with a better experience and to improve the quality of our services. For certain services, we may give you the opportunity to opt out of combining such information.',
          'Cookies - When you visit HighVizz, we send one or more cookies to your computer or other device. We use cookies to improve the quality of our service, including for storing user preferences, improving search results and ad selection, and tracking user trends, such as how people search. HighVizz also uses cookies in its advertising services to help advertisers and publishers serve and manage ads across the web and on HighVizz services.',
          'Log information - When you access HighVizz services via a browser, application or other client our servers automatically record certain information. These server logs may include information such as your web request, your interaction with a service, Internet Protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser or your account.',
          'User communications - When you send email or other communications to HighVizz, we may retain those communications in order to process your inquiries, respond to your requests and improve our services. We may use your email address to communicate with you about our services.',
          'Affiliated HighVizz services on other sites - We offer some of our services on or through other web sites. Personal information that you provide to those sites may be sent to HighVizz in order to deliver the service. We process such information under this Privacy Policy.',
          'Third-party applications - HighVizz may make available third party applications, such as gadgets or extensions, through its services. The information collected by HighVizz when you enable a third party application is processed under this Privacy Policy. Information collected by the third party application provider is governed by their privacy policies.',
          'Unique application number - Certain services include a unique application number that is not associated with your account or you. This number and information about your installation (e.g., operating system type, version number) may be sent to HighVizz when you install or uninstall that service or when that service periodically contacts our servers (for example, to request automatic updates to the software).',
          'Other sites - This Privacy Policy applies to HighVizz services only. We do not exercise control over the sites displayed as search results, sites that include HighVizz applications, products or services, or links from within our various services. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you.',
        ],
      },
      {
        header: 'In addition to the above, we may use the information we collect to:',
        listItems: [
          'Provide, maintain, protect, and improve our services and develop new services',
          'Protect the rights or property of HighVizz or our users',
          'If we use this information in a manner different than the purpose for which it was collected, then we will ask for your consent prior to such use.',
          'HighVizz processes personal information on our servers in the UK and in other countries. In some cases, we process personal information outside your own country.',
        ],
      },
    ],
  },
  {
    title: 'HighVizz Disclaimer and Terms:',
    paragraphs: [
      'This Agreement contains all of the terms and conditions of HighVizz Ltd. applicable to the use of the HighVizz products and services.',
      {
        header: 'Introduction',
        number: 1,
        listItems: [
          'This Agreement contains all of the terms and conditions of HighVizz Ltd. applicable to the use of the HighVizz Ltd. products and services and the www. HighVizz.com website (the "Site") and through any HighVizz mobile application (collectively, " HighVizz "). Please read this Agreement before using the Site. Use of the Site constitutes an agreement with the Terms of Service (this "Agreement"), whether or not you register as a HighVizz user (a "User"). If you wish to become a User and make use of HighVizz products and services, including but not limited to the App (collectively, the "Service"), then please read this Agreement. By using HighVizz, all Users do hereby represent, warrant, understand, agree to and accept all terms and conditions contained herein. If you object to anything in this Agreement or the HighVizz Privacy Policy, do not use HighVizz. This Agreement is subject to change by HighVizz at any time, effective upon posting on the Service, and your use of the Service after such posting will constitute acceptance by you of such changes.',
        ],
      },
      {
        header: 'Purpose and Definitions',
        number: 2,
        listItems: [
          'Mission: The mission of HighVizz is to allow its users to share and manage information relating to health and safety. To achieve our mission, we make services available through our website and mobile applications to help companies and managers report, monitor and analyse health and safety incidents as well as share best practices.',
          'Definition of a User: Are defined as users all companies or individuals having accessed HighVizz through its App or website.',
          'HighVizz’s Involvement in the activities of the platform: HighVizz will not in any way be involved in any of the activities by its Users on or through HighVizz, financially or otherwise. By agreeing with these Terms of Service, the User implicitly agrees to keep confidential any information or content found on or received through HighVizz, unless agreed otherwise with the owner of the content. HighVizz will not share any of the information or content used on its service with any third party other than affiliated companies, or entities which are directly required for the service.',
          'Confidential Information or Content: Is defined as confidential information or content, any information in any format included but not limited to information shared on or through HighVizz. Also included is any information regarding the membership of any members. In short, any information found on or obtained through the platform is to be deemed confidential and not to be shared, distributed, disseminated or referred to outside HighVizz without the required approval of its owner.',
          'Capacity: You have the right, authority, and capacity to enter into this agreement on your own behalf and on behalf on any entity for whom you are acting and to abide by all of the terms and conditions contained herein, and that if any aspect of your participation in HighVizz violates provisions of local law to which you are subject, you will cease using the Service and close your account.',
          'Service: Is defined as the Service, all products and services including but not limited to the site, the site’s infrastructure, any messaging system and any other product made available to the User by HighVizz, through this agreement.',
          'Third party and or affiliates',
        ],
      },
      {
        header: 'Your Obligations',
        number: 3,
        listItems: [
          'Submissions: This clause sets out the license and warranty for your submissions to HighVizz',
          'You own the information, text, or graphics ("Content") you provide HighVizz under this Agreement, and may request its deletion at any time, unless you have shared information or content with others and they have not deleted it, or it was copied or stored by other Users. Any information you submit to HighVizz is royalty-free, not subject to any claims of indemnification and at your own risk of loss.',
          'By providing information to us, you represent and warrant that you are entitled to submit the information and that the information is accurate, not confidential, and not in violation of any contractual restrictions or other third party rights. It is your responsibility to keep your HighVizz profile information accurate and updated.',
          'You will only provide HighVizz, the Site, the Service and other Users with Content that you have a right to provide to HighVizz and to allow HighVizz to display through the Service -- which means that you have adequate rights to all personal data and related information provided by you for display by HighVizz, and that you understand that any other Content you find on or through HighVizz is the sole responsibility of the person who originated such Content.',
          'HighVizz will not be responsible or liable for any use of your Content by HighVizz in accordance with this agreement. You are not relying on HighVizz and you understand that we do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Service or endorse any opinions expressed via the Service.',
          'You understand that by using the Service, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, and that you have no claim against HighVizz for any such material. You understand that you have no claim against HighVizz for the placement of advertising or similar content on the Service or in connection with the display of Content or information from the Service whether submitted by you or others.',
        ],
      },
      {
        header: 'Confidentiality Agreement',
        number: 4,
        listItems: [
          'As a User of HighVizz you agree to keep confidential any information including but not limited to any personal data and related information defined above as “Confidential Information” submitted by any other User of HighVizz unless you have obtained written approval of the User who submitted the Content originally, to use the Content outside of HighVizz.',
        ],
      },
      {
        header: 'Service Eligibility',
        number: 5,
        listItems: [
          'To be eligible to use the Service, you must meet the following criteria and represent and warrant that you: (1) are 18 years of age or older; (2) are not currently restricted from the Service, or not otherwise prohibited from having a HighVizz account, (3) are not a competitor of HighVizz or are not using the Service for reasons that are in competition with HighVizz; (4) will only maintain one HighVizz account at any given time; (5) have full power and authority to enter into this Agreement and doing so will not violate any other agreement to which you are a party; (6) will not violate any rights of HighVizz, including intellectual property rights such as copyright or trademark rights.',
        ],
      },
      {
        header: 'Sign-In Credentials',
        number: 6,
        listItems: [
          'You agree to: (1) Keep your password secure and confidential; (2) not permit others to use your account; (3) refrain from using other Users’ accounts; (4) refrain from selling, trading, or otherwise transferring your HighVizz account to another party; and (5) refrain from charging anyone for access to any portion of HighVizz, or any information therein. Further, you are responsible for anything that happens through your account until you close down your account. If you become aware of any unauthorized use of your account information, you agree to notify HighVizz immediately. To close your account, please contact us via email at info@ HighVizz.com.',
        ],
      },
      {
        header: 'Indemnification',
        number: 7,
        listItems: [
          `You indemnify us and hold us harmless for all damages, losses and costs (including, but not limited to, reasonable attorneys’ fees and costs) related to all third party claims, charges, and investigations, caused by (1) your failure to comply with this Agreement, including, without limitation, your submission of Content that violates third party rights or applicable laws, (2) any Content you submit to other Users, HighVizz and/or the Service, and (3) any activity in which you engage on or through HighVizz.`,
          `In particular, you agree to indemnify and hold HighVizz (and any employee, officer, director or affiliate or subsidiary of HighVizz, each a "Company Person") harmless (including costs and attorneys' fees) from any claim or demand made by any third party due to or arising out of your access to or use of the Service, the violation of this Agreement by you, the infringement by you, or any third party using your account, of any personal data or other right of any person or entity, or for any Content posted through the Service by you (including claims related to defamation, invasion of privacy, or other violation of a person's rights). Your obligations under the foregoing indemnity may not be offset against any other claim you may have against HighVizz or any Company Person. You remain solely responsible for all Content that you upload, post, email, transmit, or otherwise disseminate using, or in connection with, the Service. You agree that the provisions in this paragraph will survive any termination of your account(s) or the Service.`,
          `In addition, you hereby release any claims you may have against HighVizz and any Company Person that are in anyway related to the Service or your use of Content offered through the Service, including any recommendations or referrals you may receive as a result of your registration with HighVizz. You are solely responsible for your use of the Service, for any Content you provide, and for any consequences thereof, including the use of your Content by other users and third parties partners.`,
          `You understand that HighVizz employees and affiliates may participate in the Service and that HighVizz is not responsible for any of their activities, including statements or other information in any emails or other communications such individuals make in that capacity.`,
        ],
      },
      {
        header: 'Subscription renewal and Cancellation',
        number: 8,
        listItems: [
          `Memberships and some paid features (collectively, "Features") may be automatically extended for successive renewal periods of the same duration as the membership and/or Feature term originally selected. HighVizz may continue to renew memberships and/or Features until User requests a cancellation. To cancel a membership and/or Feature, User may contact HighVizz by email at info@ HighVizz.com, or by changing User's account settings through the Site. If User cancels its membership and/or Feature, User will have access to the membership benefits until the end of the then-current membership and/or Feature term; the membership and/or Feature will not be renewed after that term expires. User will not be eligible for a refund of any portion of the membership and/or Feature fees paid for the then-current membership and/or Feature period.`,
          `Membership runs from the 1st of July for a period of 12 month. A membership will grant all employees of the same firm access to the platform through their personal sign-up credentials. A single yearly membership fee is to be paid within 30 days of the start of the subscription. Once paid the fee cannot be reimbursed.`,
        ],
      },
      {
        header: 'Payment Obligations',
        number: 9,
        listItems: [
          `You agree to pay for the Service as per the terms agreed in the subscription agreement. Fees owed depend on the specific type and quantity of Features ordered from time to time. Payment of fees shall not be contingent on any events other than the delivery of the ordered Service and Features.`,
          `You agree to pay the fees currently in effect for the Service and Features you order. HighVizz shall notify you electronically 30 days in advance before making any changes to such fees. It is your responsibility to promptly provide HighVizz with any contact or billing information changes or updates (including phone number, credit card numbers, email addresses, etc.). Account updates may be made on the Site. Your subscription is for your personal use and cannot be assigned, sublicensed, distributed, shared, viewed, accessed, or otherwise transferred to anyone. Service privileges are subject to change from time to time and may be subject to listing and searching limitations.`,
        ],
      },
      {
        header: 'Notifications and Service Messages',
        number: 10,
        listItems: [
          `For purposes of service messages and notices about the Services to you, HighVizz may place a banner notice across its pages to alert you to certain changes such as modifications to this Agreement. Alternatively, notice may consist of an email from HighVizz to an email address associated with your account, even if we have other contact information. You also agree that HighVizz may communicate with you through your HighVizz account or through other means including email, mobile number, telephone, or delivery services including the postal service about your HighVizz account or services associated with HighVizz. Please review your settings on your account to control what kind of messages you receive from HighVizz. You acknowledge and agree that we shall have no liability associated with or arising from your failure to do so to maintain accurate contact or other information, including, but not limited to, your failure to receive critical information about the Service.`,
        ],
      },
      {
        header: 'Privacy and Protection of Personal Information',
        number: 11,
        listItems: [
          `You should carefully read our full Privacy Policy before deciding to become a User as it is hereby incorporated into this Agreement by reference, and governs our treatment of any information, including personally identifiable information you submit to us. Please note that certain information, statements, data, and content (such as photographs) which you may submit to HighVizz, the Site, the Service, and/or other Users, reveal your gender, ethnic origin, nationality, age, and/or other personal information about you or others. You acknowledge that your submission of any information, statements, data, and Content to us is voluntary on your part.`,
        ],
      },
      {
        header: 'Contributions to HighVizz',
        number: 12,
        listItems: [
          `By submitting any information or data ("Contributions") to HighVizz, you acknowledge and agree that: (a) your Contributions do not contain confidential or proprietary information to which you do not hold the relevant rights of sharing; (b) HighVizz shall keep confidential all Contributions; (c) you are not entitled to any compensation or reimbursement of any kind from HighVizz under any circumstances.`,
        ],
      },
      {
        header: 'Requirements of Membership',
        number: 13,
        listItems: [
          `You have the right, authority, and capacity to enter into this agreement on your own behalf and on behalf on any entity for whom you are acting and to abide by all of the terms and conditions contained herein, and that if any aspect of your participation in HighVizz violates provisions of local law to which you are subject, you will cease using the Service and close your account.`,
        ],
      },
      {
        header: 'Other Covenants Necessary to Allow Us to Provide the Service',
        number: 14,
        listItems: [
          `You acknowledge that HighVizz is not obligated to pay you or to cause any other party to pay you anything with respect to your activities on HighVizz, or to feature or otherwise display your Content or Contributions on the Site.`,
          `You acknowledge that you do not rely on HighVizz to monitor or edit the Service (including emails initiated by individuals, regardless of whether those individuals are otherwise associated with HighVizz) and that the Service may contain Content or Contributions which you find offensive or which is untrue or misleading and you hereby waive any objections you might have with respect to viewing such Content or Contributions.`,
          `You agree that this Agreement does not entitle you to any support, upgrades, updates, add-ons patches, enhancements, or fixes for the Services (collectively, "Updates"). HighVizz, however, may occasionally provide automatic Updates to the Service at its sole discretion (and without any advanced notification to you). Any such Updates for the Service shall become part of the Service and subject to this Agreement.`,
          `We may make available one or more APIs for interacting with the Service. Your use of any HighVizz API is subject to this Agreement and the HighVizz API Rules, which will be posted before we make these APIs available (and will become part of this Agreement upon posting)`,
        ],
      },
      {
        header: `HighVizz Community Rules (USER "DOS" and "DON'TS.")`,
        number: 15,
        listItems: [
          `As a condition to access HighVizz, you agree to this Agreement and to strictly observe the following DOs and DON'Ts`,
          {
            header: 'Do undertake the following:',
            listItems: [
              `Comply with all applicable laws, including, without limitation, privacy laws, personal data protection laws, and regulatory requirements;`,
              `Provide accurate information to us and update it as necessary;`,
              `Review and comply with our Privacy Policy;`,
              `Review and comply with notices sent by HighVizz concerning the Service; and`,
              `Use the Service in a professional manner.`,
            ],
          },
          {
            header: `Don't undertake the following: Participants in the HighVizz community will not:`,
            listItems: [
              `Publish inaccurate information in the designated fields on the profile/sign-up form. Please also protect sensitive personal information such as your email address, phone number, street address, or other information that is confidential in nature;`,
              `Create a User profile for anyone other than a natural person;`,
              `Disclose any information made available on HighVizz without prior consent from it's owner;`,
              `Harass, abuse or harm another person, including sending unwelcome communications to others using HighVizz;`,
              `Use or attempt to use another's account without authorization from HighVizz, or create a false identity on HighVizz;`,
              {
                header: `Upload, post, email, transmit or otherwise make available or initiate any Content that:`,
                listItems: [
                  `Falsely states, impersonates or otherwise misrepresents your identity, including but not limited to the use of a pseudonym, or misrepresenting your current or previous positions and qualifications, or your affiliations with a person or entity, past or present;`,
                  `Is unlawful, libelous, abusive, obscene, discriminatory or otherwise objectionable;`,
                  `Adds to a Content field Content that is not intended for such field (i.e. submitting a telephone number in the "title" or any other field, or including telephone numbers, email addresses, street addresses or any personally identifiable information for which there is not a field provided by HighVizz);`,
                  `Includes information that you do not have the right to disclose or make available under any law or regulation (such as information disclosed in violation of personal data protection laws and regulations). This prohibition includes but is not limited to sending messages that contain software viruses, worms, or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment of HighVizz or any User of HighVizz; and/or Forges headers or otherwise manipulate identifiers in order to disguise the origin of any communication transmitted through the Services.`,
                ],
              },
              `Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying intellectual property used to provide the Services, or any part thereof;`,
              `Utilize or copy information, content or any data you view on and/or obtain from HighVizz to provide any service that is competitive, in HighVizz’s sole discretion, with HighVizz;`,
              `Imply or state, directly or indirectly, that you are affiliated with or endorsed by HighVizz unless you have entered into a written agreement with HighVizz (this includes, but is not HighVizz to, representing yourself as an accredited HighVizz trainer if you have not been certified by HighVizz as such);`,
              `Adapt, modify or create derivative works based on HighVizz or technology underlying the Service, or other Users’ Content, in whole or part;`,
              `Deep-link to the Site for any purpose, (i.e. including a link to a HighVizz web page other than HighVizz’s home page) unless expressly authorized in writing by HighVizz or for the purpose of promoting your profile;`,
              `Remove any copyright, trademark or other proprietary rights notices contained in or on HighVizz, including those of both HighVizz and any of its licensors;`,
              `Remove, cover or otherwise obscure any form of advertisement included on HighVizz;`,
              `Collect, use, copy, or transfer any information, including, but not limited to, personal data obtained from HighVizz except as expressly permitted in this Agreement or as the owner of such information may expressly permit;`,
              `Share information of non-Users without their express consent;`,
              `Infringe or use HighVizz’s brand, logos and/or trademarks, including, without limitation, using the word " HighVizz " in any business name, email, or URL or including HighVizz’s trademarks and logos except as expressly permitted by HighVizz;`,
              `Use manual or automated software, devices, scripts robots, other means or processes to access, "scrape," "crawl" or "spider" any web pages or other services contained in the site;`,
              `Use bots or other automated methods to access HighVizz, add or download contacts, send or redirect messages, or perform other activities through HighVizz, unless explicitly permitted by HighVizz;`,
              `Access, via automated or manual means or processes, HighVizz for purposes of monitoring HighVizz’s availability, performance or functionality for any competitive purpose;`,
              `Engage in "framing," "mirroring," or otherwise simulating the appearance or function of HighVizz‘s website;`,
              `Attempt to or actually access HighVizz by any means other than through the interfaces provided by HighVizz such as its mobile application or by navigating to http://www.HighVizz.com using a web browser. This prohibition includes accessing or attempting to access HighVizz using any third-party service, including software-as-a-service platforms that aggregate access to multiple services, including HighVizz;`,
              `Attempt to or actually override any security component included in or underlying HighVizz;`,
              `Engage in any action that directly or indirectly interferes with the proper working of or places an unreasonable load on HighVizz’s infrastructure, including, but not limited to, sending unsolicited communications to other Users or HighVizz personnel, attempting to gain unauthorized access to HighVizz, or transmitting or activating computer viruses through or on HighVizz;`,
              `Interfere with or disrupt or game HighVizz or the Service, including, but not limited to, any servers or networks connected to HighVizz, in particular HighVizz’s search algorithms.`,
              `Claim any ownership right in any material, software or other intellectual property displayed on, published by or otherwise available through HighVizz, other than information and personal data that you own or otherwise have rights to without regard for its appearance on HighVizz.`,
              {
                header: `Post or submit any Content or Contributions that:`,
                listItems: [
                  `include any offensive comments that are connected to race, national origin, gender, sexual preference or physical handicap;`,
                  `include hateful, profane, obscene, indecent, pornographic, sexual or otherwise objectionable Content or language;`,
                  `defame, libel, ridicule, mock, disparage, threaten, harass, intimidate or abuse anyone;`,
                  `promote violence, illegal drug use, or substance abuse or describe how to perform a violent act, use illegal drugs or abuse other substances;`,
                  `violate the contractual, personal, intellectual property or other rights of any person, or promote or constitute illegal activity;`,
                  `reveal any personal information about another individual, including another person's address, phone number, e-mail address, credit card number or any information that may be used to track, contact or impersonate that individual, without that person’s informed consent under applicable personal data protection laws and regulations;`,
                  `trick, defraud, deceive or mislead HighVizz or other users, especially in any attempt to learn sensitive information;`,
                  `make improper use of HighVizz’s support services or submit false reports of abuse or misconduct;`,
                  `forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Service to send altered, deceptive or false source-identifying information;`,
                  `cover or obscure any notice, banner, advertisement or other branding on the Service;`,
                  `disguise the source of materials or other information you submit to the Service or use tools which anonymize your internet protocol address (e.g., anonymous proxy) to access the Service; or`,
                  `interfere with or circumvent any security feature of the Service or any feature that restricts or enforces limitations on use of or access to the Service or the Content.`,
                ],
              },
            ],
          },
        ],
      },
      {
        header: 'Service Availability',
        number: 16,
        listItems: [
          `For as long as HighVizz continues to offer the Service, HighVizz may provide and seek to update, improve and expand the Service. As a result, we allow you to access HighVizz as it may exist and be available on any given day and have no other obligations, except as expressly stated in this Agreement. We may modify, replace, refuse access to, suspend or discontinue HighVizz, partially or entirely, or change and modify prices for all or part of the Services for you or for all our users in our sole discretion. All of these changes shall be effective upon their posting on our Site or by direct communication to you unless otherwise noted. HighVizz further reserves the right to withhold, remove and or discard any Content available as part of your account, with or without notice if deemed by HighVizz to be contrary to this Agreement. For avoidance of doubt, HighVizz has no obligation to store, maintain or provide you a copy of any Content that you or other Users provide when using the Services except where pertaining to personal data.`,
        ],
      },
      {
        header: 'Connections and Interactions with other Users',
        number: 17,
        listItems: [
          `You are solely responsible for your interactions with other Users. HighVizz may limit the number of connections you may have to other Users and may, in certain circumstances, prohibit you from contacting other Users through use of the Service or otherwise limit your use of the Service.`,
          `HighVizz reserves the right, but has no obligation, to monitor disputes between you and other Users and to restrict, suspend, or close your account if HighVizz determines, in our sole discretion.`,
          `If you have a dispute with other Users, you release HighVizz and hereby agree to indemnify HighVizz from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such dispute.`,
        ],
      },
      {
        header: 'Disclaimers',
        number: 18,
        listItems: [
          `Do not rely on HighVizz, any information therein, or its continuation. We provide the platform for HighVizz and all information and services on an "as is" and "as available" basis. HighVizz does not control or vet user generated content for accuracy. We do not provide any express warranties or representations.`,
          `To the fullest extent permissible under applicable law, we disclaim any and all implied warranties and representations, including, without limitation, any warranties of merchantability, fitness for a particular purpose, title, accuracy of data, and noninfringement. If you are dissatisfied or harmed by HighVizz or anything related to HighVizz, you may close your HighVizz account and terminate this agreement in accordance with section vi ("term and termination” of the terms and conditions) and such termination shall be your sole and exclusive remedy.`,
          `All information shared on HighVizz remains the ownership of the party sharing it and is subject to the non-disclosure agreement implicit with the use of the service. For information to be shared outside the platform, the owner of the information will have to provide authorisation. HighVizz is therefore not liable for any information which is shared outside the platform without prior agreement of its owner. HighVizz is equally not liable for any information being stolen from the platform by any party.`,
          `HighVizz is not responsible, and makes no representations or warranties for the delivery of any messages sent through HighVizz to anyone. In addition, we neither warrant nor represent that your use of the service will not infringe the rights of third parties. Any material, service, or technology described or used on the website may be subject to intellectual property rights owned by third parties who have licensed such material, service, or technology to us.`,
          `HighVizz does not have any obligation to verify the identity of the users using the service, nor does it have any obligation to monitor the use of the service by other users; therefore, HighVizz disclaims all liability for identity theft or any other misuse of your identity or information. HighVizz does not guarantee that the service will function without interruption or errors in functioning. In particular, the operation of the service may be interrupted due to maintenance, updates, or system or network failures. HighVizz disclaims all liability for damages caused by any such interruption or errors in functioning. Furthermore, HighVizz disclaims all liability for any malfunctioning, impossibility of access, or poor use conditions of the site due to inappropriate equipment, disturbances related to internet service providers, to the saturation of the internet network, and for any other reason.`,
        ],
      },
      {
        header: 'Limitation of Liability',
        number: 19,
        listItems: [
          `Neither HighVizz nor any of our subsidiaries, affiliated companies, employees, shareholders, or directors (collectively, " HighVizz Affiliates") shall be cumulatively liable for (a) any damages in excess of the amount you have paid HighVizz in the ninety (90) days immediately preceding the date on which you first assert any such claim, if any, or GBP 100, whichever amount is greater, or (b) any special, incidental, indirect, punitive or consequential damages or loss of use, profit, revenue or data to you or any third person arising from your use of the Service, any platform applications or any of the content or other materials on, accessed through or downloaded from HighVizz. This limitation of liability is part of the basis of the bargain between the parties and without it the terms and prices charged would be different. This limitation of liability shall apply regardless of whether (1) you base your claim on contract, tort, statute or any other legal theory, (2) we knew or should have known about the possibility of such damages, or (3) the limited remedies provided in this section fail of their essential purpose.`,
        ],
      },
      {
        header: 'Term and Termination',
        number: 20,
        listItems: [
          `Unless terminated by HighVizz, this Agreement will remain in full force and effect while you use any of the Services. You may terminate this Agreement at any time by deleting all Content you have provided to HighVizz and ceasing to use the Service. HighVizz may terminate this Agreement at any time, particularly if you violate any provision of this Agreement. Upon termination of this Agreement for any reason, you shall destroy and remove from all computers, and other storage media all copies of the any intellectual property owned by HighVizz or any other User that you acquired by use of the Service. Your representations in this Agreement and the provisions of Sections II and any other provision which by their nature are designed to survive termination shall survive any termination or expiration of this Agreement.`,
        ],
      },
      {
        header: 'Dispute Resolution',
        number: 21,
        listItems: [
          `This Agreement or any claim, cause of action or dispute ("claim") arising out of or related to this Agreement shall be governed by the laws of the United Kingdom regardless of your country of origin or where you access HighVizz, and notwithstanding of any conflicts of law principles and the United Nations Convention for the International Sale of Goods. You and HighVizz agree that all claims arising out of or related to this Agreement must be resolved exclusively by a court located in London, UK. You and HighVizz agree to submit to the personal jurisdiction of the courts located within London, UK for the purpose of litigating all such claims. Notwithstanding the above, you agree that HighVizz shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.`,
        ],
      },
      {
        header: 'Waiver/Severability',
        number: 22,
        listItems: [
          `The failure of HighVizz to require or enforce strict performance by you of any provision of this agreement or to exercise any right under this Agreement shall not be construed as a waiver or relinquishment of HighVizz’s right to assert or rely upon any such provision or right in that or any other instance. In fact, HighVizz may choose to enforce certain portions of this Agreement more strictly or to interpret certain provisions more strictly against certain Users than it does against Users in general, and such disparate treatment shall not be grounds for failing to comply with this Agreement as so interpreted. If any portion of this Agreement is deemed unlawful, void or enforceable by any court of competent jurisdiction, this Agreement as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of this Agreement that is unlawful, void or unenforceable shall be stricken from the Agreement.`,
        ],
      },
      {
        header: 'Statute of Limitations',
        number: 23,
        listItems: [
          `You and HighVizz both agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service, this Agreement must be filed within ONE (1) YEAR after such claim or cause of action arose or be forever barred.`,
          `No Third Party Beneficiaries. This Agreement is between you and HighVizz. No User has any rights to force HighVizz to enforce any rights it may have against any you or any other User.`,
        ],
      },
      {
        header: 'Privacy and Copyright Policy',
        number: 24,
        listItems: [
          {
            header: `If you believe that any material on the Service violates this Agreement or your personal data rights, please notify HighVizz as soon as possible by sending an email to, or by mailing a letter to the HighVizz Data Protection Officer (listed below). This Privacy Policy applies to all of the products, services and websites offered by HighVizz Inc. or its subsidiaries or affiliates. If you have any questions about this Privacy Policy, please feel free to contact us by email at: info@ HighVizz.com. We may collect the following types of information:`,
            listItems: [
              `Information you provide - When you sign up for a HighVizz Account, we ask you for personal information. We may combine the information you submit under your account with information from other HighVizz services or third parties in order to provide you with a better experience and to improve the quality of our services. For certain services, we may give you the opportunity to opt out of combining such information.`,
              `Cookies - When you visit HighVizz, we send one or more cookies to your computer or other device. We use cookies to improve the quality of our service, including for storing user preferences, improving search results and ad selection, and tracking user trends, such as how people search. HighVizz also uses cookies in its advertising services to help advertisers and publishers serve and manage ads across the web and on HighVizz services.`,
              `Log information - When you access HighVizz services via a browser, application or other client our servers automatically record certain information. These server logs may include information such as your web request, your interaction with a service, Internet Protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser or your account.`,
              `User communications - When you send email or other communications to HighVizz, we may retain those communications in order to process your inquiries, respond to your requests and improve our services. We may use your email address to communicate with you about our services.`,
              `Affiliated HighVizz services on other sites - We offer some of our services on or through other web sites. Personal information that you provide to those sites may be sent to HighVizz in order to deliver the service. We process such information under this Privacy Policy.`,
              `Third-party applications - HighVizz may make available third party applications, such as gadgets or extensions, through its services. The information collected by HighVizz when you enable a third party application is processed under this Privacy Policy. Information collected by the third party application provider is governed by their privacy policies.`,
              `Unique application number - Certain services include a unique application number that is not associated with your account or you. This number and information about your installation (e.g., operating system type, version number) may be sent to HighVizz when you install or uninstall that service or when that service periodically contacts our servers (for example, to request automatic updates to the software).`,
              `Other sites - This Privacy Policy applies to HighVizz services only. We do not exercise control over the sites displayed as search results, sites that include HighVizz applications, products or services, or links from within our various services. These other sites may place their own cookies or other files on your computer, collect data or solicit personal information from you.`,
            ],
          },
          {
            header: `In addition to the above, we may use the information we collect to:`,
            listItems: [
              `Provide, maintain, protect, and improve our services and develop new services`,
              `Protect the rights or property of HighVizz or our users`,
              `If we use this information in a manner different than the purpose for which it was collected, then we will ask for your consent prior to such use.`,
              `HighVizz processes personal information on our servers in the UK and in other countries. In some cases, we process personal information outside your own country.`,
            ],
          },
        ],
      },
      {
        header: 'Choices',
        number: 25,
        listItems: [
          `Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some HighVizz features and services may not function properly if your cookies are disabled.`,
        ],
      },
      {
        header: 'Information sharing',
        number: 26,
        listItems: [
          {
            header: `HighVizz only shares personal data with other companies or individuals outside of HighVizz in the following limited circumstances:`,
            listItems: [
              `We have your consent. We require opt-in consent for the sharing of any sensitive personal data.`,
              `We provide such information to our subsidiaries, affiliated companies or other trusted businesses or persons for the purpose of processing personal data on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.`,
              `We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable Terms of Service, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against harm to the rights, property or safety of HighVizz, its users or the public as required or permitted by law.`,
              `If HighVizz becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will ensure the confidentiality of any personal information involved in such transactions and provide notice before personal information is transferred and becomes subject to a different privacy policy.`,
            ],
          },
        ],
      },
      {
        header: 'Data Protection',
        number: 27,
        listItems: [
          {
            header: `DEFINITIONS`,
            listItems: [
              `Controller, Processor, Data Subject, Personal Data, Personal Data Breach, processing and appropriate technical and organisational measures: as defined in the Data Protection Legislation;`,
              `Data Protection Legislation: the UK Data Protection Legislation and any other European Union legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of Personal Data (including, without limitation, the privacy of electronic communications);`,
              `UK Data Protection Legislation: all applicable data protection and privacy legislation in force from time to time in the UK including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended.`,
            ],
          },
          {
            header: `DATA PROTECTION`,
            listItems: [
              `27.02.1 Both parties will comply with all applicable requirements of the Data Protection Legislation. This clause 27.02.1 is in addition to, and does not relieve, remove or replace, a party's obligations or rights under the Data Protection Legislation. In this clause 27.02, Applicable Laws means (for so long as and to the extent that they apply to the Provider) the law of the European Union, the law of any member state of the European Union and/or Domestic UK Law; and Domestic UK Law means the UK Data Protection Legislation and any other law that applies in the UK.`,
              `27.02.02 The parties acknowledge that for the purposes of the Data Protection Legislation, the Customer is the Controller and the Provider is the Processor. Schedule 1 sets out the scope, nature and purpose of processing by the Provider, the duration of the processing and the types of Personal Data and categories of Data Subject.`,
              `Without prejudice to the generality of clause 27.02.01, the Customer will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the Personal Data to the Provider and/or lawful collection of the Personal Data by the Provider on behalf of the Customer for the duration and purposes of this agreement.`,
              {
                header: `27.02.03 Without prejudice to the generality of clause 27.02.01, the Provider shall, in relation to any Personal Data processed in connection with the performance by the Provider of its obligations under this agreement:`,
                listItems: [
                  `process that Personal Data only on the documented written instructions of the Customer which are set out in Schedule 1 unless the Provider is required by Applicable Laws to otherwise process that Personal Data. Where the Provider is relying on Applicable Laws as the basis for processing Personal Data, the Provider shall promptly notify the Customer of this before performing the processing required by the Applicable Laws unless those Applicable Laws prohibit the Provider from so notifying the Customer;`,
                  `ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the Customer, to protect against unauthorised or unlawful processing of Personal Data and against accidental loss or destruction of, or damage to, Personal Data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measures (those measures may include, where appropriate, pseudonymising and encrypting Personal Data, ensuring confidentiality, integrity, availability and resilience of its systems and services, ensuring that availability of and access to Personal Data can be restored in a timely manner after an incident, and regularly assessing and evaluating the effectiveness of the technical and organisational measures adopted by it);`,
                  `ensure that all personnel who have access to and/or process Personal Data are obliged to keep the Personal Data confidential; and`,
                  `not transfer any Personal Data outside of the European Economic Area unless the prior written consent of the Customer has been obtained and the following conditions are fulfilled:`,
                  `the Customer or the Provider has provided appropriate safeguards in relation to the transfer;`,
                  `the data subject has enforceable rights and effective legal remedies;`,
                  `the Provider complies with its obligations under the Data Protection Legislation by providing an adequate level of protection to any Personal Data that is transferred; and`,
                  `the Provider complies with reasonable instructions notified to it in advance by the Customer with respect to the processing of the Personal Data;`,
                  `assist the Customer, at the Customer's cost, in responding to any request from a Data Subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;`,
                  `notify the Customer without undue delay on becoming aware of a Personal Data Breach;`,
                  `at the written direction of the Customer, delete or return Personal Data and copies thereof to the Customer on termination of the agreement unless required by Applicable Law to store the Personal Data; and`,
                  `maintain complete and accurate records and information to demonstrate its compliance with this clause 27.02.03.`,
                ],
              },
              `27.02.04 The Customer consents to the Provider appointing third-party processors of Personal Data under this agreement. The Provider confirms that it has entered or (as the case may be) will enter with the third-party processors into a written agreement incorporating terms which are substantially similar to those set out in this clause 27.02 and in either case which the Supplier confirms reflect and will continue to reflect the requirements of the Data Protection Legislation.`,
              `27.02.05 The Customer acknowledges that the Provider will retain the Customer’s data, as defined in Schedule 1, paragraph 1.2, no longer than necessary for the purpose for which it was processed for the duration of this agreement, unless a longer period is required to comply with applicable laws or to comply with the requirements imposed by the Provider’s insurance policy.`,
              `27.02.06 The Supplier may, at any time on not less than 30 days’ notice, revise this clause 27.02 by replacing it with any applicable controller to processor standard clauses or similar terms forming part of an applicable certification scheme (which shall apply when replaced by attachment to this agreement).`,
              {
                header: `Schedule 1 Processing, Personal Data and Data Subjects`,
                listItems: [
                  `1. Processing by the Provider`,
                  `1.1	Scope – The Supplier will only process the data submitted by the Customer, who remains responsible for complying with Data Protection Legislation at all times. The data processed will only encompass information pertaining to health and safety incidents, in accordance with the Purpose of the processing as defined below.`,
                  `1.2	Nature – The data processed will encompass: name of the persons involved in a health and safety incident, job type, name of their employer, type of injury, date of injury.`,
                  `1.3	Purpose of processing – The purpose of processing the personal data is to monitor health and safety incidents, to ensure full compliance with, and the continued improvement of, health and safety measures.`,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
