import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { Review, ReviewStatus } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class ReviewService implements RestService<Review> {
  private reviewChangedSubject = new Subject<SubscriberEvent<Review>>();

  get(params?: any): Promise<AxiosResponse<Review>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Review[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Review>> {
    throw new Error('Method not implemented.');
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Review>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Review>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Review): Promise<AxiosResponse<Review>> {
    return httpClient.post<Review>(URL.REVIEW, resource);
  }

  update(resource: Review): Promise<AxiosResponse<Review>> {
    return httpClient.put<Review>(URL.REVIEW, resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Review>> {
    throw new Error('Method not implemented.');
  }

  getByIncidentId(incidentId: string | number): Promise<AxiosResponse<Review>> {
    return httpClient.get<Review>(buildUrl(URL.REVIEW, ['forIncident', incidentId]));
  }

  createEmptyReview(resource: {
    incidentId: number;
    status: ReviewStatus;
    reviewContent: string;
  }): Promise<AxiosResponse<Review>> {
    return httpClient.post(URL.REVIEW, resource);
  }

  // SUBSCRIPTIONS

  getReviewChangedListener(): Observable<SubscriberEvent<Review>> {
    return this.reviewChangedSubject.asObservable();
  }

  reviewChanged(event: EntityAction, review: Review) {
    this.reviewChangedSubject.next({ event: event, entity: review });
  }
}

export default new ReviewService();
