import React, { createContext, useState, useEffect, useCallback } from 'react';
import { Permission, Subscription } from '../models';
import { PermissionsService } from '../services';
import companyService from '../services/company.service';

export const PermissionsContext = createContext<PermissionsContextType>({} as PermissionsContextType);

export type PermissionsContextType = {
  permissions: Permission[];
  setPermissionsForUser: () => void;
  companySubscriptionMap: Record<number, Subscription[]>;
  getCompanySubscriptions: () => Promise<void>;
  clearData: () => void;
};

const PermissionsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [subscriptions, setSubscriptions] = useState<Record<number, Subscription[]>>({});

  const getCompanySubscriptions = async () => {
    try {
      const { data: companies } = await companyService.getUserVisibleCompanies();
      if (companies) {
        setSubscriptions(companies.reduce((acc, company) => ({ ...acc, [company.id]: company.subscriptions }), {}));
      }
    } catch (error) {}
  };

  const setPermissionsForUser = () => {
    PermissionsService.getAllByUserId().then((response) => {
      setPermissions(response.data);
    });
  };

  const clearData = () => {
    setPermissions([]);
    setSubscriptions({});
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        setPermissionsForUser,
        companySubscriptionMap: subscriptions,
        getCompanySubscriptions,
        clearData,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsProvider };
