import { Col, Descriptions, Row, Skeleton } from 'antd';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  HasButton,
  HasChangePasswordModal,
  HasDropdown,
  HasSpinner,
  HasTextInput,
  HasTitle,
  Notification,
} from '../../components';
import i18n from '../../i18n/config';
import { User } from '../../models';
import { Country } from '../../models/country.model';
import { CountryService, UserService } from '../../services';
import { COLORS, ErrorMessages, Option, SuccessMessages } from '../../shared';
import { formatTextToTitlecase, isObjectEmpty } from '../../utils';
import UserEmailNotifications from './user-email-notifications';

interface UserDetailsPageProps extends WithTranslation {}

interface UserDetailsPageState {
  user: User;
  loading: boolean;
  editMode: boolean;
  showChangePassword: boolean;
}

class HasUserDetailsPage extends React.Component<UserDetailsPageProps, UserDetailsPageState> {
  countries: Country[] = [];
  uneditedUser: User = {} as User;

  state = {
    user: {} as User,
    loading: true,
    editMode: false,
    showChangePassword: false,
  };

  toggleEditMode = () => {
    const editMode = this.state.editMode;
    if (editMode) {
      UserService.updateOwnProfile(this.state.user)
        .then(() => {
          Notification.success(SuccessMessages.USER_UPDATE);
          this.uneditedUser = { ...this.state.user };
        })
        .catch((error) => {
          Notification.error(ErrorMessages.USER_UPDATE(error.response?.data.message));
          this.setState({ user: { ...this.uneditedUser } });
        });
    }

    this.setState({ editMode: !editMode });
  };

  async componentDidMount() {
    this.getData();
  }

  cancelEdit = () => {
    this.setState({ user: { ...this.uneditedUser }, editMode: false });
  };

  getData = async () => {
    const { data: user } = await UserService.getMyUser();
    const { data: countries } = await CountryService.getAll();
    this.countries = countries;
    this.uneditedUser = { ...user };
    this.setState({
      user,
      loading: false,
    });
  };

  showChangePasswordModal = () => {
    this.setState({ showChangePassword: true });
  };

  changePassword = async (password: string): Promise<null> => {
    try {
      await UserService.changePassword(this.state.user.id, password);
      this.setState({ showChangePassword: false });
      Notification.success(SuccessMessages.USER_PASSWORD_CHANGE);
      return new Promise((resolve) => resolve(null));
    } catch (error) {
      Notification.error(ErrorMessages.USER_PASSWORD_CHANGE(error));
      return new Promise((_, reject) => reject());
    }
  };

  userInputChange = (field: keyof User, value: React.FormEvent<HTMLInputElement>) => {
    this.setState({ user: { ...this.state.user, [field]: value } });
  };

  handleUpdatedNotificationsForUser = (updatedUser: User) => {
    this.setState({
      user: updatedUser,
    });
  };

  render() {
    const { user, editMode, showChangePassword } = this.state;
    const loading = !user || isObjectEmpty(user) || false;
    return loading ? (
      <HasSpinner size="large" />
    ) : (
      <div className="d-flex h-100">
        <div
          style={{ backgroundColor: COLORS.WHITE, padding: '16px', border: `1px solid ${COLORS.GRAY_ACCENT_LIGHT}` }}
          className="d-flex flex-row shadow-sm overflow-auto w-50"
        >
          <div className="d-flex flex-column">
            <Skeleton loading={loading} active paragraph={false} title={{ width: 200 }}>
              <HasTitle
                content={i18n.t('user.title')}
                level={3}
                style={{ margin: 0, fontWeight: 700, color: COLORS.NEW_ORANGE }}
                className="mb-3 text-break"
              />
            </Skeleton>
            <Row gutter={[10, 0]}>
              <Col span={12}>
                <Skeleton
                  loading={loading}
                  active
                  paragraph={{ rows: 6, width: [150, 100, 150, 100, 150, 100] }}
                  title={false}
                >
                  {!editMode ? (
                    <Descriptions
                      layout="vertical"
                      colon={false}
                      column={1}
                      size="small"
                      style={{ paddingLeft: '10px' }}
                    >
                      <Descriptions.Item label={i18n.t('user.firstName')} className="text-break">
                        {user.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.lastName')} className="text-break">
                        {user.surname}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.email')} className="text-break">
                        {user.email}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.phone')} className="text-break">
                        {user.phone}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.country')} className="text-break">
                        {i18n.t(user.country?.alpha3)}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.division')} className="text-break">
                        {user.division.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('shared.role')} className="text-break">
                        {formatTextToTitlecase(user.role)}
                      </Descriptions.Item>
                    </Descriptions>
                  ) : (
                    <Descriptions
                      layout="vertical"
                      colon={false}
                      column={1}
                      size="small"
                      style={{ paddingLeft: '10px' }}
                    >
                      <Descriptions.Item label={i18n.t('user.firstName')} className="text-break">
                        <HasTextInput
                          value={user.name}
                          onChange={(event: any) => this.userInputChange('name', event.target.value)}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.lastName')} className="text-break">
                        <HasTextInput
                          value={user.surname}
                          onChange={(event: any) => this.userInputChange('surname', event.target.value)}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.email')} className="text-break">
                        {user.email}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.phone')} className="text-break">
                        <HasTextInput
                          value={user.phone}
                          onChange={(event: any) => this.userInputChange('phone', event.target.value)}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.country')} className="text-break">
                        <HasDropdown<Country>
                          onChange={(value: any, option: Option<Country>) => {
                            user.country = option.forObject;
                          }}
                          style={{ width: '180px' }}
                          defaultValue={user.country?.id}
                          options={this.countries.map<Option<Country>>((country) => ({
                            value: country.id,
                            label: i18n.t(country.alpha3).toString(),
                            forObject: country,
                          }))}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('user.division')} className="text-break">
                        {user.division.name}
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('shared.role')} className="text-break">
                        {formatTextToTitlecase(user.role)}
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Skeleton>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            {!editMode ? (
              <>
                <HasButton type="primary" onClick={this.showChangePasswordModal} disabled={loading}>
                  {i18n.t('shared.changePassword')}
                </HasButton>
                <HasButton type="primary" onClick={this.toggleEditMode} disabled={loading}>
                  {i18n.t('shared.edit')}
                </HasButton>
              </>
            ) : (
              <>
                <HasButton type="primary" onClick={this.toggleEditMode} disabled={loading}>
                  {i18n.t('shared.save')}
                </HasButton>
                <HasButton type="default" onClick={this.cancelEdit} disabled={loading}>
                  {i18n.t('shared.cancel')}
                </HasButton>
              </>
            )}
          </div>
        </div>
        <HasChangePasswordModal
          visible={showChangePassword}
          onOk={this.changePassword}
          onCancel={() => this.setState({ showChangePassword: false })}
        />
        <UserEmailNotifications user={user} setUpdatedUser={this.handleUpdatedNotificationsForUser} />
      </div>
    );
  }
}

export default withTranslation()(HasUserDetailsPage);
