import React from 'react';
import { Action } from '../../models';
import { HasActionsTable } from '../../components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { getUserId } from '../../utils';

interface ActionsPageProps extends RouteComponentProps, WithTranslation {}
class HasActionsPage extends React.Component<ActionsPageProps, {}> {
  navigateToAction = (action: Action) => {
    if (action.riskAssessmentResult && process.env.REACT_APP_RISK_ASSESSMENTS_URL) {
      window.location.href = `${process.env.REACT_APP_RISK_ASSESSMENTS_URL}risk-assessment/action/view/${action.id}`;
    } else {
      this.props.history.push(`events/action/${action.incidentId}?id=${action.id}`);
    }
  };

  render() {
    return (
      <div className="shadow-sm has-incidents-table-height overflow-auto has-container-tile d-flex flex-column">
        <HasActionsTable userId={+getUserId()!} navigationCallback={this.navigateToAction} />
      </div>
    );
  }
}

export default withTranslation()(HasActionsPage);
