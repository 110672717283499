import { isEqual, cloneDeep } from 'lodash';
import React from 'react';
import { CHART_COLORS, COLORS } from '../../../shared';
import { HasChart } from '../../atoms';

interface PieChartProps {
  labels: string[];
  series: number[];
  title: string;
  subtitle?: string;
  clickCallback?: Function;
  debug?: boolean;
}

class HasPieChart extends React.PureComponent<PieChartProps, any> {
  constructor(props: Readonly<PieChartProps>) {
    super(props);

    this.state = {
      options: {
        chart: {
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: this.onClick,
          },
          redrawOnParentResize: false,
        },
        colors: CHART_COLORS,
        tooltip: {
          enabled: true,
          position: 'topRight',
        },
        labels: [],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          fontSize: '10px',
          offsetX: 0,
          offsetY: 0,
          markers: {
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 2,
            vertical: 2,
          },
        },
        title: {
          text: this.props.title,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '700',
            fontFamily: 'Montserrat',
            color: COLORS.NEW_ORANGE,
          },
        },
        subtitle: {
          text: this.props.subtitle,
          align: 'right',
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: COLORS.GRAY_ACCENT_DARK,
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            offsetX: 0,
            offsetY: -10,
          },
        },
        responsive: [
          {
            breakpoint: 1366,
            options: {
              legend: {
                fontSize: '8px',
                width: '100%',
                height: '16px',
                offsetY: 7,
              },
            },
          },
        ],
      },
      series: [],
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps: Readonly<PieChartProps>, prevState: any) {
    if (
      this.props.title !== prevProps.title ||
      this.props.subtitle !== prevProps.subtitle ||
      !isEqual(this.props.labels, prevProps.labels)
    ) {
      this.updateChartData();
    }
  }

  updateChartData = () => {
    const { labels, series, title, subtitle } = this.props;
    this.setState((prevState: Readonly<any>) => {
      const { options } = prevState;
      let newOptions = cloneDeep(options);
      newOptions = {
        ...newOptions,
        labels,
        title: { text: title },
        subtitle: { text: subtitle },
      };
      let newSeries = cloneDeep(series);
      return { options: newOptions, series: newSeries };
    });
  };

  onClick = (event: any, chartContext: any, config: any) => {
    if (this.props.clickCallback) {
      this.props.clickCallback(config.dataPointIndex);
    }
  };

  render() {
    return <HasChart options={this.state.options} series={this.state.series} type="pie" height={'100%'} />;
  }
}

export default HasPieChart;
