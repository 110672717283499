import { AxiosResponse } from 'axios';
import {
  ChartInstance,
  ChartContainer,
  ChartDashboard,
  ChartDashboardMinimized,
  ChartType,
  ChartInstanceMinimized,
  ChartInstanceDataFilter,
  Incident,
  ChartEventsDataFilter,
} from '../models';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { RestService, URL } from './rest.service';

class ChartService implements RestService<ChartDashboard> {
  get(params?: any): Promise<AxiosResponse<ChartDashboard>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<ChartDashboard[]>> {
    return httpClient.get<ChartDashboard[]>(buildUrl(URL.CHART, ['all']));
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<ChartDashboard>> {
    return httpClient.get<ChartDashboard>(buildUrl(URL.CHART, [resourceId]));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<ChartDashboard>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<ChartDashboard>> {
    throw new Error('Method not implemented.');
  }

  create(resource: ChartDashboard): Promise<AxiosResponse<ChartDashboard>> {
    return httpClient.post<ChartDashboard>(buildUrl(URL.CHART, ['layout']), resource);
  }

  update(resource: ChartDashboard): Promise<AxiosResponse<ChartDashboard>> {
    return httpClient.put<ChartDashboard>(buildUrl(URL.CHART, ['layout']), resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<ChartDashboard>> {
    return httpClient.delete<ChartDashboard>(buildUrl(URL.CHART, [resourceId]));
  }

  getAllVisibleMinimized(): Promise<AxiosResponse<ChartDashboardMinimized[]>> {
    return httpClient.get<ChartDashboardMinimized[]>(buildUrl(URL.CHART, ['layouts', 'minimized']));
  }

  getAllEditableMinimized(): Promise<AxiosResponse<ChartDashboardMinimized[]>> {
    return httpClient.get<ChartDashboardMinimized[]>(buildUrl(URL.CHART, ['layouts', 'minimized', 'editable']));
  }

  getChartsForLoggedInUser(): Promise<AxiosResponse<ChartContainer>> {
    return httpClient.get<ChartContainer>(buildUrl(URL.CHART, ['forUser']));
  }

  getChartTypes(): Promise<AxiosResponse<ChartType[]>> {
    return httpClient.get<ChartType[]>(buildUrl(URL.CHART, ['chartTypes']));
  }

  getChartInstaces(): Promise<AxiosResponse<ChartInstanceMinimized[]>> {
    return httpClient.get<ChartInstanceMinimized[]>(buildUrl(URL.CHART, ['library']));
  }

  createChartInstance(resource: ChartInstance): Promise<AxiosResponse<ChartInstance>> {
    return httpClient.post<ChartInstance>(buildUrl(URL.CHART, ['library', 'chartinstance']), resource);
  }

  getChartInstace(resourceId: number): Promise<AxiosResponse<ChartInstance>> {
    return httpClient.get<ChartInstance>(buildUrl(URL.CHART, ['library', 'chartinstance', resourceId]));
  }

  getChartInstanceData(
    resourceId: number,
    chartInstanceDataFilter: ChartInstanceDataFilter
  ): Promise<AxiosResponse<any[]>> {
    return httpClient.post<any[]>(
      buildUrl(URL.CHART, ['chartinstance', 'data', resourceId]),
      chartInstanceDataFilter,
      {}
    );
  }

  getChartInstanceEventsData(chartEventsDataFilter: ChartEventsDataFilter): Promise<AxiosResponse<Incident[]>> {
    return httpClient.post<Incident[]>(buildUrl(URL.CHART, ['chartinstance', 'events']), chartEventsDataFilter, {});
  }

  getChartInstanceEventsCount(chartEventsDataFilter: ChartEventsDataFilter): Promise<AxiosResponse<number>> {
    return httpClient.post<number>(
      buildUrl(URL.CHART, ['chartinstance', 'events', 'count']),
      chartEventsDataFilter,
      {}
    );
  }

  getChartInstanceLayout(resourceId: number): Promise<AxiosResponse<ChartInstance>> {
    return httpClient.get<ChartInstance>(buildUrl(URL.CHART, ['layout', 'chartinstance', resourceId]));
  }

  deleteChartInstance(chartInstanceId: number): Promise<AxiosResponse<void>> {
    return httpClient.delete<void>(buildUrl(URL.CHART, ['library', 'chartinstance', chartInstanceId]));
  }

  deleteDashboard(layoutId: number): Promise<AxiosResponse<void>> {
    return httpClient.delete<void>(buildUrl(URL.CHART, ['layout', layoutId]));
  }
}

export default new ChartService();
