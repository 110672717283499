import { Attachment, FileIsFor, Incident } from '../../../models';
import { EntityAction, IncidentAttachmentService } from '../../../services';
import { HasAttachments } from '../attachments';

class HasIncidentAttachments extends HasAttachments<Incident> {
  protected photosAreFor: FileIsFor = FileIsFor.INCIDENT;
  protected titleStringKey: string = 'attachment.incidentAttachments';
  protected emptyStateStringKey: string | null = null;

  protected getForDownload(id: number): Promise<any> {
    return IncidentAttachmentService.getForDownload(id);
  }

  protected uploadAttachment(isPrivate: boolean, formData: FormData): Promise<any> {
    return IncidentAttachmentService.upload(this.props.photoUploadTargetId, isPrivate, formData);
  }

  protected setPrivate(attachment: Attachment): Promise<any> {
    return IncidentAttachmentService.setPrivate(attachment.id, !attachment.private);
  }

  protected attachmentsChanged(action: EntityAction, attachment: Attachment): void {
    return IncidentAttachmentService.attachmentChanged(action, attachment);
  }
}

export default HasIncidentAttachments;
