import { GlobalOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons';
import React, { CSSProperties, ReactNode } from 'react';
import { Action, LogEntry } from '../../../models';
import { CollapsePanelWithContent, COLORS } from '../../../shared';
import { getDate, getTime, i18nLabelParser } from '../../../utils';
import { HasCollapse, HasParagraph, HasText } from '../../atoms';
import i18n from '../../../i18n/config';
import { isEqual } from 'lodash';
import { Tooltip } from 'antd';
import { TranslationService } from '../../../services';

interface ActionFormEntriesProps {
  entries: LogEntry[];
  action: Action;
  editMode: boolean;
  entryComponent: JSX.Element;
  formatter: Function;
}

interface ActionFormEntriesState {
  showAll: boolean;
  translationResult: { [k: string]: string };
  resultIdsTranslated: string[];
}

class HasActionFormEntries extends React.Component<ActionFormEntriesProps, ActionFormEntriesState> {
  answersToNotShow = ['responsibleUserName', 'dueDate', 'importance'];

  state: ActionFormEntriesState = {
    showAll: false,
    translationResult: {},
    resultIdsTranslated: [],
  };

  shouldComponentUpdate(nextProps: Readonly<ActionFormEntriesProps>, nextState: ActionFormEntriesState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  parseAnswer = (value: string | number | undefined, style: CSSProperties, latest?: boolean): string | ReactNode => {
    if (!value) {
      return '';
    }
    try {
      const answer = this.props.formatter(value);
      const content = answer ? (i18n.exists(answer) ? i18n.t(answer) : answer) : i18n.t('incidents.noEntry');
      return (
        <>
          <HasText content={content} strong style={style} />
        </>
      );
    } catch {
      return i18nLabelParser(value.toString());
    }
  };

  handleTranslationForEntry = (entry?: LogEntry) => {
    if (entry && entry.value) {
      if (this.state.resultIdsTranslated.includes(entry.value + entry.seqNo)) {
        this.setState((previousState) => {
          delete previousState.translationResult[entry.value! + entry.seqNo];
          return {
            ...previousState,
            resultIdsTranslated: previousState.resultIdsTranslated.filter(
              (existingId) => existingId !== entry.value! + entry.seqNo
            ),
          };
        });
      } else {
        let translation = '';
        TranslationService.translate({ textToTranslate: entry.value }).then((result) => {
          translation = result.data.translatedText!;

          this.setState((previousState) => {
            previousState.resultIdsTranslated.push(entry.value! + entry.seqNo);

            return {
              ...previousState,
              translationResult: { ...previousState.translationResult, [entry.value! + entry.seqNo]: translation },
            };
          });
        });
      }
    }
  };

  getEntryValueNode = (
    value: string | number | undefined,
    seqNo: number,
    index: number,
    latest?: boolean
  ): ReactNode => {
    const latestStyle: CSSProperties = latest ? { color: COLORS.PRIMARY_BLUE, fontWeight: 600, marginTop: '5px' } : {};
    let valueToShow = this.state.translationResult[value!.toString() + seqNo]
      ? this.state.translationResult[value!.toString() + seqNo]
      : value;
    return (
      <HasParagraph
        content={this.parseAnswer(
          valueToShow,
          { fontSize: '12px', marginBottom: '14px', fontWeight: 500, ...latestStyle },
          latest
        )}
        className="text-break"
        style={{ fontSize: '12px', marginBottom: '14px', fontWeight: 500, ...latestStyle }}
      />
    );
  };

  buildEntry = (
    entry: LogEntry,
    index: number,
    latest?: boolean,
    showEdit?: boolean,
    translateOptions?: {
      onShowTranslation?: (entryValue?: LogEntry) => void;
      translationResult: { [k: string]: string };
      resultIdsTranslated: string[];
    }
  ): ReactNode => {
    const date = getDate(entry.date);
    const time = getTime(entry.date);

    const value = this.getEntryValueNode(entry.value, entry.seqNo, index, latest);
    const content = (
      <div style={{ fontSize: '12px', color: COLORS.GRAY }}>
        <b>{entry.userName}</b> on <b>{date}</b> at <b>{time}</b>
      </div>
    );

    return (
      <div key={index}>
        <HasParagraph content={content} style={{ color: COLORS.PRIMARY_BLUE, margin: 0 }} />
        {!this.answersToNotShow.includes(entry.fieldKey!) && (
          <div style={{ display: 'grid', gridTemplateColumns: '95% 5%' }}>
            <div>{value}</div>
            <div className="d-flex flex-row justify-content-end">
              {!translateOptions?.resultIdsTranslated.includes(entry.value! + entry.seqNo) && (
                <Tooltip title={i18n.t('incidents.translate')}>
                  <GlobalOutlined
                    onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(entry)}
                    className="align-middle"
                    style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                  />
                </Tooltip>
              )}
              {translateOptions?.resultIdsTranslated.includes(entry.value! + entry.seqNo) && (
                <Tooltip title={i18n.t('incidents.showOriginal')}>
                  <RollbackOutlined
                    onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(entry)}
                    className="align-middle"
                    style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )}
        {this.answersToNotShow.includes(entry.fieldKey!) && <div>{value}</div>}
      </div>
    );
  };

  buildOlderEntries = (entries: LogEntry[]): ReactNode => {
    const entryNodes = entries.map((entry, index) =>
      this.buildEntry(entry, index, false, index === 0, {
        onShowTranslation: this.handleTranslationForEntry,
        translationResult: this.state.translationResult,
        resultIdsTranslated: this.state.resultIdsTranslated,
      })
    );

    const collapsePanelConfigs: CollapsePanelWithContent[] = [
      {
        key: 'action_old_entries',
        header: (
          <HasText
            content={i18n.t('dataDisplay.moreEntries', { count: entries.length })}
            strong
            style={{ fontSize: '12px' }}
          />
        ),
        content: entryNodes,
        className: 'has-collapse has-collapse-panel',
      },
    ];

    return (
      <HasCollapse
        bordered={false}
        expandIcon={(panelProps: any) => (
          <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ verticalAlign: 'middle' }} />
        )}
        panelConfigs={collapsePanelConfigs}
        className="has-collapse"
      />
    );
  };

  buildLatestEntry = (entry: LogEntry | undefined): ReactNode =>
    entry
      ? this.buildEntry(entry, -1, true, false, {
          onShowTranslation: this.handleTranslationForEntry,
          translationResult: this.state.translationResult,
          resultIdsTranslated: this.state.resultIdsTranslated,
        })
      : false;

  render() {
    const entries = [...this.props.entries];
    const latestEntry = this.buildLatestEntry(entries.pop());
    const olderEntries = entries.length > 0 && this.buildOlderEntries(entries);
    return (
      <React.Fragment>
        {olderEntries}
        {latestEntry}
        {this.props.editMode && this.props.entryComponent}
      </React.Fragment>
    );
  }
}

export default HasActionFormEntries;
