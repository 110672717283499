import { Attachment, FileIsFor, Review } from '../../../models';
import { EntityAction, ReviewAttachmentService } from '../../../services';
import { HasAttachments } from '../attachments';

class HasReviewAttachments extends HasAttachments<Review> {
  protected photosAreFor: FileIsFor = FileIsFor.REVIEW;
  protected titleStringKey: string = 'attachment.reviewAttachments';
  protected emptyStateStringKey: string | null = 'attachment.noReview';

  protected getForDownload(id: number): Promise<any> {
    return ReviewAttachmentService.getForDownload(id);
  }

  protected uploadAttachment(isPrivate: boolean, formData: FormData): Promise<any> {
    return ReviewAttachmentService.upload(this.attachmentUploadTargetId, isPrivate, formData);
  }

  protected setPrivate(attachment: Attachment): Promise<any> {
    return ReviewAttachmentService.setPrivate(attachment.id, !attachment.private);
  }

  protected attachmentsChanged(action: EntityAction, attachment: Attachment): void {
    ReviewAttachmentService.attachmentChanged(action, attachment);
  }
}

export default HasReviewAttachments;
