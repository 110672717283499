import React, { ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { Notification } from '../components';
import { GuardFunctionType, GuardPromise, RouteConfig } from '../shared';

interface ProtectedRouteProps extends RouteProps {
  key: number;
  guards: GuardFunctionType[];
  path: string;
  exact?: boolean;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

interface ProtectedRouteState {
  route: ReactNode;
}

export class ProtectedRoute extends Route<RouteConfig> {
  state: ProtectedRouteState = { route: null };

  componentDidMount() {
    this.renderRoute();
  }

  renderRoute = async () => {
    const { canActivate, path, exact, component } = this.props;
    try {
      if (canActivate) {
        await Promise.all<GuardPromise>(canActivate.map((guard) => guard(this.props)));
      }
      this.setState({ route: <Route path={path} component={component} exact={exact} /> });
    } catch (reject) {
      const { redirectToLogin, message } = reject as GuardPromise;
      let component;
      if (redirectToLogin) {
        component = () => <Redirect to={{ pathname: '/login', state: { sessionError: message } }} />;
      } else {
        component = () => <Redirect to="/home" />;
        Notification.error({ ...(message as any) });
      }
      this.setState({ route: <Route component={component} /> });
    }
  };

  render() {
    return this.state.route;
  }
}
