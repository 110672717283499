import { PlusOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import i18n from '../../../i18n/config';
import { Team, User } from '../../../models';
import { CompanyService, TeamService, UserService } from '../../../services';
import { HasButton, HasText, Notification } from '../../atoms';
import { HasManageTeam } from './manage-team.table';
import { HasTeamsTable } from './teams.table';
import { HasViewTeam } from './view-team.table';
import { ErrorMessages, SuccessMessages } from '../../../shared';

interface HasTeamsListProps {
  teams: Team[];
  teamDetailsVisible: boolean;
  setTeamDetailsVisible: (a: boolean) => void;
}

const HasTeamsList: React.FC<HasTeamsListProps> = ({ teams, teamDetailsVisible, setTeamDetailsVisible }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>({});
  const [editView, setEditView] = useState<boolean>(false);
  const [updatedTeam, setUpdatedTeam] = useState<any>({ ...selectedTeam });
  const [companyId, setCompanyId] = useState<number>();

  const subscriptions: Subscription[] = [];

  useEffect(() => setUpdatedTeam(selectedTeam), [selectedTeam]);

  useEffect(() => {
    if (companyId) {
      UserService.getAllUsersForCompany(companyId, NaN, false).then((response) => setUsers(response.data));
    }
    subscriptions.push(
      CompanyService.getCompanyListener().subscribe((companyId) => {
        !isNaN(companyId) && setCompanyId(companyId);
        setTeamDetailsVisible(false);
        setSelectedTeam({});
        setEditView(false);
      }),
      CompanyService.getCompanyChangedListener().subscribe((_) => {
        companyId &&
          CompanyService.getCompanyWithDivisionInformation(companyId).then((response) => {
            setCompanyId(response.data.id);
            setSelectedTeam({});
          });
      })
    );
  }, [companyId]);

  const onEditClick = (team: Team) => {
    setTeamDetailsVisible(true);
    setEditView(true);
    setSelectedTeam(team);
  };

  const onRowClick = (team: Team) => {
    setTeamDetailsVisible(true);
    setSelectedTeam(team);
  };

  const handleDelete = (team: Team) => {
    TeamService.delete(team.id)
      .then(() => {
        TeamService.refreshTeams();
        setSelectedTeam(undefined);
        setTeamDetailsVisible(false);
        Notification.success(SuccessMessages.TEAM_DELETE);
      })
      .catch((error) => Notification.error(ErrorMessages.TEAM_DELETE(error.response?.data.message)));
  };

  const handleSave = () => {
    if (updatedTeam?.id) {
      TeamService.put(updatedTeam, updatedTeam.id)
        .then(() => {
          TeamService.refreshTeams();
          setEditView(false);
          setSelectedTeam(updatedTeam);
          Notification.success(SuccessMessages.TEAM_UPDATE);
        })
        .catch((error) => Notification.error(ErrorMessages.TEAM_UPDATE(error.response?.data.message)));
    } else {
      TeamService.post(updatedTeam)
        .then(() => {
          TeamService.refreshTeams();
          setEditView(false);
          setSelectedTeam(updatedTeam);
          Notification.success(SuccessMessages.TEAM_CREATE);
        })
        .catch((error) => Notification.error(ErrorMessages.TEAM_CREATE(error.response?.data.message)));
    }
  };

  const handleExit = () => {
    if (selectedTeam?.name) {
      setEditView(false);
      setUpdatedTeam(selectedTeam);
    } else {
      setEditView(false);
      setTeamDetailsVisible(false);
      setUpdatedTeam({});
    }
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between mb-3">
        <Skeleton active loading={false} paragraph={false} title={{ width: '150px' }}>
          <HasText
            content={i18n.t('dataDisplay.displayingResults', {
              count: teams.length,
            })}
            style={{ fontSize: '12px' }}
          />
        </Skeleton>
        <HasButton
          onClick={() => {
            setEditView(true);
            setTeamDetailsVisible(true);
            setUpdatedTeam({
              company: { id: companyId },
              code: 11,
            });
          }}
          type="primary"
          icon={<PlusOutlined />}
          style={{ minWidth: 0 }}
        >
          {i18n.t('team.addTeam')}
        </HasButton>
      </div>
      <div className="d-flex flex-row h-100">
        <div className={`team-list ${teamDetailsVisible ? 'hidden' : ''}`} style={{ gap: '20px' }}>
          <HasTeamsTable teams={teams} onRowClick={onRowClick} onEditClick={onEditClick} onDeleteClick={handleDelete} />
        </div>
        {teamDetailsVisible && (
          <div className="team-details">
            {editView ? (
              <HasManageTeam
                team={updatedTeam}
                users={users}
                setNewMembers={(members: any) =>
                  setUpdatedTeam((prevState: any) => ({
                    ...prevState,
                    members,
                  }))
                }
                handleSave={handleSave}
                handleExit={handleExit}
                setTeamName={(value) =>
                  setUpdatedTeam((prevState: any) => ({
                    ...prevState,
                    name: value,
                  }))
                }
              />
            ) : (
              <HasViewTeam
                team={selectedTeam!}
                setEditView={setEditView}
                setTeamDetailsVisible={setTeamDetailsVisible}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HasTeamsList;
