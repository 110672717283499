import { Select } from 'antd';
import React from 'react';
import { MultipleDropdownProps } from '../../../shared';

class HasMultipleSelectDropdown<T> extends React.Component<MultipleDropdownProps<T>, {}> {
  onChangeWrapper = (value: any, option: any) => {
    this.props.onChange && this.props.onChange(value, option);
  };

  onSelectWrapper = (value: any, option: any) => {
    this.props.onSelect && this.props.onSelect(value, option);
  };

  onDeselectWrapper = (value: any, option: any) => {
    this.props.onDeselect && this.props.onDeselect(value, option);
  };

  render() {
    const { mode, onChange, onSelect, onDeselect, children, ...restProps } = this.props;
    return (
      <Select
        {...restProps}
        mode={mode}
        onChange={this.onChangeWrapper}
        onSelect={this.onSelectWrapper}
        onDeselect={this.onDeselectWrapper}
      >
        {children}
      </Select>
    );
  }
}

export default HasMultipleSelectDropdown;
