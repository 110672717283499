import i18n from 'i18next';
import { capitalize } from 'lodash';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const loadPath = `${process.env.REACT_APP_API_URL}i18n/{{lng}}`;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    react: {
      wait: false,
      useSuspense: true,
    },
    backend: {
      backends: [
        Backend,
        resourcesToBackend((language, _, callback) => {
          import(`./${language}/translation.json`)
            .then(({ default: resources }) => {
              callback(null, resources);
            })
            .catch((error) => {
              callback(error, false);
            });
        }),
      ],
      backendOptions: [
        {
          loadPath,
        },
      ],
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'uppercase') return value.toUppercase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return capitalize(value);
        if (lng === 'en') {
          if (format === 'indefiniteArticle') {
            return value.match('^[aieouAIEOU].*') ? 'an ' + value : 'a ' + value;
          }
          return value;
        }
      },
    },
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    detection: {
      order: ['localStorage', 'sessionStorage', 'cookie'],
    },
  });

export default i18n;
