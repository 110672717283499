import React from 'react';
import { HasText } from '../..';
import { Incident, UserRole } from '../../../models';
import { DescriptionProps } from '../../../shared';
import {
  displayPlaceholderIfEmpty,
  getDateTime,
  hidePhoneNumber,
  isUserRoleEqualsTo,
  getLatestFormResultValue,
} from '../../../utils';
import { HasDescription } from '../../molecules';
import i18n from '../../../i18n/config';

interface ReportingOwnerProps {
  incident: Incident;
}

class HasReportingOwnerDescription extends React.Component<ReportingOwnerProps, {}> {
  private getReportingOwnerConfig = (incident: Incident): DescriptionProps => {
    const {
      reportingUser: { name, surname, email, phone, company, division },
    } = incident;
    let hiddenPhoneNumber;
    if (phone) {
      hiddenPhoneNumber = hidePhoneNumber(phone);
    }
    return {
      title: i18n.t('incidents.reportingOwner'),
      className: 'd-flex flex-column flex-grow-1',
      descriptionItems: [
        {
          label: i18n.t('incidents.eventDate'),
          content: <HasText content={getLatestFormResultValue(incident.formResult, 'WHEN_HAPPEN')} strong />,
        },
        {
          label: i18n.t('incidents.reportDate'),
          content: <HasText content={getDateTime(incident.reportedAt)} strong />,
        },
        {
          label: i18n.t('shared.firstName'),
          content: <HasText content={name} strong />,
        },
        {
          label: i18n.t('shared.lastName'),
          content: <HasText content={surname} strong />,
        },
        {
          label: i18n.t('shared.email'),
          content: <HasText content={email} strong />,
        },
        {
          label: i18n.t('shared.phone'),
          content: (
            <HasText
              content={
                isUserRoleEqualsTo(UserRole.OWNER) || isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
                  ? phone
                  : hiddenPhoneNumber
              }
              strong
            />
          ),
        },
        {
          label: i18n.t('shared.company'),
          content: <HasText content={displayPlaceholderIfEmpty(company?.name)} strong />,
        },
        {
          label: i18n.t('shared.division'),
          content: <HasText content={displayPlaceholderIfEmpty(division?.name)} strong />,
        },
      ],
    };
  };

  render() {
    return <HasDescription {...this.getReportingOwnerConfig(this.props.incident)} />;
  }
}

export default HasReportingOwnerDescription;
