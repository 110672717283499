import { TFunction } from 'i18next';

export interface Subscription {
  id: number | null;
  module: Module;
}

export enum Module {
  EQUIPMENTS = 'EQUIPMENTS',
  RISK_ASSESSMENTS = 'RISK_ASSESSMENTS',
}

export type ModuleLabelFunction = (t: TFunction) => string;

export const moduleLabels: Record<string, ModuleLabelFunction> = {
  [Module.EQUIPMENTS]: (t) => t('modules.equipments', 'Equipments'),
  [Module.RISK_ASSESSMENTS]: (t) => t('modules.riskAssessments', 'Risk assessments'),
};
