import React from 'react';
import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';

interface OwnEmptyStateProps extends EmptyProps {}

class HasEmptyState extends React.Component<OwnEmptyStateProps, {}> {
  render() {
    return <Empty {...this.props}>{this.props.children}</Empty>;
  }
}

export default HasEmptyState;
