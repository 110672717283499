import { Permission } from '../models';
import { RestService, URL } from './rest.service';
import { httpClient } from './http.client';
import { buildUrl } from '../utils';
import { AxiosResponse } from 'axios';

class PermissionService implements RestService<Permission> {
  get(params?: any): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  getAll(): Promise<AxiosResponse<Permission[]>> {
    throw new Error('Method not implemented.');
  }
  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  post(body?: any, params?: any): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  put(body?: any, params?: any): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  create(resource: Permission): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  update(resource: Permission): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  delete(resourceId: string | number): Promise<AxiosResponse<Permission>> {
    throw new Error('Method not implemented.');
  }
  getAllByUserId(): Promise<AxiosResponse<Permission[]>> {
    return httpClient.get<Permission[]>(buildUrl(URL.PERMISSIONS, ['forUser']));
  }
}

export default new PermissionService();
