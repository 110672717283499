import React from 'react';
import { HasButton, HasMap, HasParagraph, HasRadioGroup, Notification } from '../../..';
import i18n from '../../../../i18n/config';
import {
  DEFAULT_MAP_CENTER,
  ErrorMessages,
  Location,
  RadioGroupOption,
  TypedRadioGroupProps,
} from '../../../../shared';
import { HasText, HasTextInput } from '../../../atoms';
import { RadioChangeEvent } from 'antd/lib/radio';
import { LocationType, LocationTypeLabel } from '../../../../models';
import { i18nLabelParser } from '../../../../utils';
import { Autocomplete } from '@react-google-maps/api';
import { isUndefined } from 'lodash';

interface LocationRadioGroupProps {
  onChange: (data?: any) => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
}

interface LocationRadioGroupState {
  error: boolean;
  locationTypeRadioOptionsConfig: RadioGroupOption<string>[];
  selectedLocationType: LocationType | undefined;
  address: string | undefined;
  addressCoordinates: Location;
}

class HasLocationRadioGroup extends React.Component<LocationRadioGroupProps, LocationRadioGroupState> {
  private locationTypeRadioConfig: TypedRadioGroupProps<string>;

  private autocomplete: google.maps.places.Autocomplete | null = null;

  constructor(props: Readonly<LocationRadioGroupProps>) {
    super(props);

    this.locationTypeRadioConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onLocationTypeChange,
    };

    const locationTypeRadioOptionsConfig = this.buildRadioOptions();

    this.state = {
      error: false,
      locationTypeRadioOptionsConfig,
      selectedLocationType: undefined,
      address: '',
      addressCoordinates: DEFAULT_MAP_CENTER,
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () =>
      this.setState({
        locationTypeRadioOptionsConfig: this.buildRadioOptions(),
      })
    );
  }

  buildRadioOptions = (): RadioGroupOption<string>[] => {
    return Object.values(LocationType).map((value, index) => ({
      key: index,
      value: value,
      forObject: index,
      label: i18nLabelParser(LocationTypeLabel[value]),
    }));
  };

  onLocationTypeChange = (event: RadioChangeEvent) => {
    this.setState({ selectedLocationType: event.target.value });
    this.props.onChange(event.target.value);
  };

  saveData = () => {
    const { selectedLocationType, address, addressCoordinates } = this.state;
    if (isUndefined(selectedLocationType) || isUndefined(addressCoordinates) || address === '') {
      this.setState({ error: true });
    } else {
      this.props.onSave({ selectedLocationType: LocationTypeLabel[selectedLocationType], address, addressCoordinates });
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  handleMapClick = (event: google.maps.MouseEvent) => {
    const { latLng } = event;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.setState({
          address: results[0].formatted_address,
          addressCoordinates: { lat: latLng.lat(), lng: latLng.lng() },
        });
      } else {
        Notification.error(ErrorMessages.MAPS_GEOCODER_ERROR(''));
      }
    });
  };

  handlePlaceChanged = () => {
    if (this.autocomplete !== null) {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        this.setState({
          address: place.formatted_address,
          addressCoordinates: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        });
      }
    }
  };

  componentWillUnmount() {
    i18n.on('languageChanged', () =>
      this.setState({
        locationTypeRadioOptionsConfig: this.buildRadioOptions(),
      })
    );
  }

  render() {
    const { address, addressCoordinates, error, locationTypeRadioOptionsConfig, selectedLocationType } = this.state;

    return (
      <div className="mt-2">
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup {...this.locationTypeRadioConfig} groupOptions={locationTypeRadioOptionsConfig} />
        <div className="d-flex flex-row justify-content-between">
          {selectedLocationType === LocationType.MAP && (
            <div style={{ height: '15rem' }} className="d-flex flex-column flex-grow-1 mr-4">
              <div className="d-flex flex-column flex-grow-1">
                <HasMap markerLocations={[addressCoordinates]} onMapClick={this.handleMapClick} />
                <Autocomplete
                  onLoad={(autocomplete: google.maps.places.Autocomplete) => (this.autocomplete = autocomplete)}
                  onPlaceChanged={this.handlePlaceChanged}
                >
                  <HasTextInput
                    key="address"
                    value={address}
                    onChange={(event) => this.setState({ address: event.target.value })}
                    placeholder={i18n.t('chatbot.searchAddress')}
                    style={{ marginTop: '10px' }}
                  />
                </Autocomplete>
              </div>
            </div>
          )}
          {selectedLocationType === LocationType.ADDRESS && (
            <Autocomplete
              onLoad={(autocomplete: google.maps.places.Autocomplete) => (this.autocomplete = autocomplete)}
              onPlaceChanged={this.handlePlaceChanged}
            >
              <HasTextInput
                key="simpleAddress"
                value={address}
                onChange={(event) => this.setState({ address: event.target.value })}
                placeholder={i18n.t('chatbot.searchAddress')}
              />
            </Autocomplete>
          )}
          {!isUndefined(selectedLocationType) && (
            <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData} className="align-self-end">
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {error && (
          <HasText
            content={ErrorMessages.MAPS_INVALID_GEOCODER_ADDRESS('').message}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </div>
    );
  }
}

export default HasLocationRadioGroup;
