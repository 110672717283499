import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Skeleton, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ClickParam } from 'antd/lib/menu';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import React from 'react';
import {
  AnonymizeModal,
  DropdownMenu,
  HasBadge,
  HasButton,
  HasChangePasswordModal,
  HasCheckbox,
  HasEditUserModal,
  HasInviteUserModal,
  HasTable,
  HasTag,
  HasText,
  HasTextInput,
  Notification,
} from '../../../components';
import i18n from '../../../i18n/config';
import { Division, User, UserRole, UserSignUpData, UserStatus } from '../../../models';
import { DivisionService, EntityAction, UserService } from '../../../services';
import { COLORS, ErrorMessages, SETTINGS_ACTIONS, SORT_DIRECTIONS, SuccessMessages } from '../../../shared';
import {
  comparatorFn,
  displayPlaceholderIfEmpty,
  formatTextToTitlecase,
  formatUserRole,
  getDate,
  hidePhoneNumber,
  isUserRoleEqualsTo,
} from '../../../utils';

interface UsersSettingsProps {
  showOrHideDeletedUsersCallback: (includeDeletedUsers: boolean) => void;
  companyId: number;
  companyDomain: string;
  users: User[];
  loading: boolean;
}

interface UsersSettingsState {
  users: User[];
  divisions: Division[];
  userToEdit: User;
  showEdit: boolean;
  showChangePassword: boolean;
  showInvite: boolean;
  searchTerm: string;
  showAnonymize: boolean;
  userToRemove: User;
}

const TABLE_ITEM_HEIGHT = 59;
const TABLE_HEADER_HEIGHT = 55;

class HasUsersSettings extends React.Component<UsersSettingsProps, UsersSettingsState> {
  state: UsersSettingsState = {
    users: [] as User[],
    searchTerm: '',
    userToEdit: {} as User,
    divisions: [],
    showEdit: false,
    showChangePassword: false,
    showInvite: false,
    showAnonymize: false,
    userToRemove: {} as User,
  };

  componentDidMount() {
    this.setState({ users: this.props.users });
  }

  componentDidUpdate(prevProps: Readonly<UsersSettingsProps>, prevState: UsersSettingsState) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ users: this.props.users });
    }
    if (!isEqual(prevProps.companyId, this.props.companyId) && this.props.companyId) {
      DivisionService.getDivisionsForCompany(this.props.companyId).then(({ data }) => {
        this.setState({ divisions: data });
      });
    }
  }

  getColumns = (): ColumnProps<User>[] => [
    {
      title: i18n.t('shared.firstName'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      sorter: {
        compare: (a: User, b: User) => comparatorFn<string>(a.name.toUpperCase(), b.name.toUpperCase()),
        multiple: 1,
      },
    },
    {
      title: i18n.t('shared.lastName'),
      dataIndex: 'surname',
      key: 'surname',
      align: 'center',
      sorter: {
        compare: (a: User, b: User) => comparatorFn<string>(a.surname.toUpperCase(), b.surname.toUpperCase()),
        multiple: 2,
      },
    },
    {
      title: i18n.t('shared.phone'),
      key: 'phone',
      align: 'center',
      render: (_: any, { phone }: User) => this.formatPhoneNumber(phone),
    },
    {
      title: i18n.t('shared.email'),
      key: 'email',
      align: 'center',
      ellipsis: true,
      render: (_: any, { email }: User) => displayPlaceholderIfEmpty(email),
    },
    {
      title: i18n.t('shared.division'),
      key: 'department',
      align: 'center',
      render: (_: any, { division }: User) => displayPlaceholderIfEmpty(division?.name),
    },
    {
      title: i18n.t('shared.role'),
      key: 'role',
      align: 'center',
      render: (_: any, record: User) => {
        switch (true) {
          case record.role.toString() === UserRole.ADMIN:
            return (
              <HasTag color={COLORS.PRIMARY_BLUE} style={{ minWidth: '60px', textAlign: 'center' }}>
                {formatUserRole(record.role)}
              </HasTag>
            );
          case record.role.toString() === UserRole.OWNER:
            return (
              <HasTag color={COLORS.BLUE} style={{ minWidth: '60px', textAlign: 'center' }}>
                {formatUserRole(record.role)}
              </HasTag>
            );
          case record.role.toString() === UserRole.GUEST:
            return (
              <HasTag color={COLORS.BLUE_LIGHT} style={{ minWidth: '60px', textAlign: 'center' }}>
                {formatUserRole(record.role)}
              </HasTag>
            );
          case record.role.toString() === UserRole.EMPLOYEE:
            return (
              <HasTag color={COLORS.BLUE_LIGHT} style={{ minWidth: '60px', textAlign: 'center' }}>
                {formatUserRole(record.role)}
              </HasTag>
            );
          case record.role.toString() === UserRole.SUPER_ADMIN:
            return (
              <HasTag color={COLORS.PRIMARY_BLUE} style={{ minWidth: '60px', textAlign: 'center' }}>
                {formatUserRole(record.role)}
              </HasTag>
            );
          case record.role.toString() === UserRole.LIMITED_EMPLOYEE_DIVISION:
            return (
              <Tooltip
                title={
                  <HasText content={formatUserRole(record.role)} style={{ fontSize: '12px', color: COLORS.WHITE }} />
                }
              >
                <HasTag
                  color={COLORS.BLUE_LIGHT}
                  style={{
                    minWidth: '60px',
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {formatUserRole(record.role)}
                </HasTag>
              </Tooltip>
            );
          case record.role.toString() === UserRole.LIMITED_EMPLOYEE_PERSONAL:
            return (
              <Tooltip
                title={
                  <HasText content={formatUserRole(record.role)} style={{ fontSize: '12px', color: COLORS.WHITE }} />
                }
              >
                <HasTag
                  color={COLORS.BLUE_LIGHT}
                  style={{
                    minWidth: '60px',
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {formatUserRole(record.role)}
                </HasTag>
              </Tooltip>
            );
          default:
            return (
              <HasTag style={{ minWidth: '60px', textAlign: 'center' }}>{i18n.t('dataDisplay.unknownValue')}</HasTag>
            );
        }
      },
    },
    {
      title: i18n.t('shared.status'),
      key: 'status',
      align: 'center',
      render: (_: any, record: User) => {
        switch (true) {
          case record.status.toString() === UserStatus[UserStatus.ACTIVE]:
            return <HasBadge color={COLORS.SECONDARY_GREEN} text={formatTextToTitlecase(record.status)} />;
          case record.status.toString() === UserStatus[UserStatus.INACTIVE]:
            return <HasBadge status="default" text={formatTextToTitlecase(record.status)} />;
          case record.status.toString() === UserStatus[UserStatus.PENDING]:
            return (
              <HasBadge
                status="processing"
                color={COLORS.SECONDARY_GREEN_50}
                text={formatTextToTitlecase(record.status)}
              />
            );
          default:
            return <HasBadge status="default" text="Unkown" />;
        }
      },
    },
    {
      title: i18n.t('settings.activationDate'),
      key: 'activationDate',
      align: 'center',
      render: (_: any, record: User) =>
        record.activationDate
          ? getDate(record.activationDate, { day: 'numeric', month: 'long', year: 'numeric' })
          : '-',
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      align: 'center',
      render: (_: any, record: User) => <DropdownMenu user={record} actionSelected={this.actionSelected} />,
    },
  ];

  actionSelected = (clickParam: ClickParam, user: User) => {
    switch (clickParam.key) {
      case SETTINGS_ACTIONS.USER_EDIT:
        this.setState({ userToEdit: user, showEdit: true });
        break;
      case SETTINGS_ACTIONS.USER_CHANGE_PASS:
        this.setState({ userToEdit: user, showChangePassword: true });
        break;
      case SETTINGS_ACTIONS.USER_CHANGE_STATUS:
        this.activateUser(user);
        break;
      case SETTINGS_ACTIONS.USER_REMOVE:
        this.removeUser(user);
        break;
      case SETTINGS_ACTIONS.USER_UNDELETE:
        this.undeleteUser(user);
        break;
      case SETTINGS_ACTIONS.USER_REMOVE_ANON:
        this.setState({ showAnonymize: true, userToRemove: user });
    }
  };

  undeleteUser = async (user: User) => {
    const { companyId } = this.props;
    UserService.undeleteByCompany(user.id, companyId)
      .then(
        () => {
          Notification.success(SuccessMessages.USER_UNDELETE(user.name, user.surname));
          this.props.showOrHideDeletedUsersCallback(true);
        },
        (error) =>
          Notification.error(ErrorMessages.USER_UNDELETE(user.name, user.surname, error.response?.data.message))
      )
      .catch((error) => Notification.error(ErrorMessages.UNEXPECTED_ERROR(error.response?.data.message)));
  };

  saveUser = async (user: User): Promise<null> => {
    try {
      await UserService.update(user);
      Notification.success(SuccessMessages.USER_UPDATE);
      UserService.usersChanged(EntityAction.REFRESH, {} as User);
      if (this.state.userToEdit.division.id !== user.division.id) {
        DivisionService.divisionChanged(EntityAction.REFRESH, {} as Division);
      }
      this.setState({ userToEdit: {} as User, showEdit: false });
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.USER_UPDATE(error.response?.data.message));
      return new Promise((_, reject) => reject());
    }
  };

  changePassword = async (passowrd: string): Promise<null> => {
    // TODO: Replace this logic after Redux integration
    try {
      await UserService.changePassword(this.state.userToEdit.id, passowrd);
      this.setState({ userToEdit: {} as User, showChangePassword: false });
      Notification.success(SuccessMessages.USER_PASSWORD_CHANGE);
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.USER_PASSWORD_CHANGE(error));
      return new Promise((_, reject) => reject());
    }
  };

  activateUser = (user: User) => {
    const newStatus =
      user.status.toString() === UserStatus[UserStatus.ACTIVE]
        ? UserStatus[UserStatus.INACTIVE]
        : UserStatus[UserStatus.ACTIVE];
    UserService.updateUserStatus(user.id, newStatus).then(
      () => {
        newStatus === UserStatus[UserStatus.ACTIVE]
          ? Notification.success(SuccessMessages.USER_ACTIVATE(user.name, user.surname))
          : Notification.success(SuccessMessages.USER_INACTIVATE(user.name, user.surname));
        UserService.usersChanged(EntityAction.REFRESH, {} as User);
      },
      (error) =>
        newStatus === UserStatus[UserStatus.ACTIVE]
          ? Notification.error(ErrorMessages.USER_ACTIVATE(user.name, user.surname, error.response?.data.message))
          : Notification.error(ErrorMessages.USER_INACTIVATE(user.name, user.surname, error.response?.data.message))
    );
  };

  removeUser = (user: User) => {
    const { companyId } = this.props;
    UserService.deleteByCompany(user.id, companyId)
      .then(
        () => {
          Notification.success(SuccessMessages.USER_DELETE(user.name, user.surname));
          UserService.usersChanged(EntityAction.REFRESH, {} as User);
        },
        (error) => Notification.error(ErrorMessages.USER_DELETE(user.name, user.surname, error.response?.data.message))
      )
      .catch((error) => Notification.error(ErrorMessages.UNEXPECTED_ERROR(error.response?.data.message)));
  };

  removeAndAnonymize = async (user: User): Promise<null> => {
    const { companyId } = this.props;
    try {
      await UserService.deleteAndAnonymize(user.id, companyId);
      Notification.success(SuccessMessages.USER_DELETE(user.name, user.surname));
      this.setState({ showAnonymize: false });
      UserService.usersChanged(EntityAction.REFRESH, {} as User);
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.UNEXPECTED_ERROR(error));
      return new Promise((_, reject) => reject());
    }
  };

  inviteUser = async (signUpData: UserSignUpData): Promise<null> => {
    try {
      await UserService.inviteUserToSignUp({ ...signUpData });
      Notification.success(SuccessMessages.USER_INVITE_SIGN_UP(signUpData.email));
      this.setState({ showInvite: false });
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.USER_INVITE_SIGN_UP(signUpData.email, error.response?.data.message));
      return new Promise((_, reject) => reject());
    }
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TODO Replace with API call when it is implemented
    const searchTerm = event.target.value.toLocaleLowerCase();
    if (searchTerm === '') {
      this.setState({ searchTerm: '', users: this.props.users });
    } else {
      let filteredUsers = [] as User[];
      this.props.users.forEach((user) => {
        if (
          user.name.toLocaleLowerCase().includes(searchTerm) ||
          user.surname.toLocaleLowerCase().includes(searchTerm) ||
          user.phone?.includes(searchTerm) ||
          user.email?.includes(searchTerm) ||
          user.company?.name?.toLocaleLowerCase().includes(searchTerm) ||
          user.division?.name?.toLocaleLowerCase().includes(searchTerm) ||
          user.status.toString().toLocaleLowerCase().includes(searchTerm) ||
          user.role.toString().toLocaleLowerCase().includes(searchTerm)
        ) {
          filteredUsers.push(user);
        }
      });
      this.setState({ searchTerm: searchTerm, users: filteredUsers });
    }
  };

  formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber || phoneNumber === '' || phoneNumber === null || phoneNumber === undefined) {
      return '-';
    }
    return !isUserRoleEqualsTo(UserRole.OWNER) && !isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
      ? hidePhoneNumber(phoneNumber)
      : phoneNumber;
  };

  toggleShowDeletedUsers = (event: CheckboxChangeEvent) => {
    this.props.showOrHideDeletedUsersCallback(event.target.checked);
  };

  render() {
    const { companyDomain, loading } = this.props;
    const { users, userToEdit, showEdit, showChangePassword, showInvite, divisions, showAnonymize, userToRemove } =
      this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HasTextInput
              prefix={<SearchOutlined />}
              placeholder={i18n.t('shared.search')}
              onChange={(e) => this.handleSearch(e)}
              style={{ maxWidth: '180px', marginRight: '20px' }}
              size="large"
            />
            <Skeleton loading={loading} active paragraph={false} title={{ width: '150px' }}>
              <HasText
                content={i18n.t('dataDisplay.displayingResults', { count: users.length })}
                style={{ fontSize: '12px' }}
              />
            </Skeleton>
          </div>
          <div>
            <HasCheckbox
              content={i18n.t('settings.showDeleted')}
              disabled={loading}
              onChange={this.toggleShowDeletedUsers}
            />
            <HasButton
              onClick={() => this.setState({ showInvite: true })}
              type="primary"
              disabled={loading}
              icon={<PlusOutlined />}
              style={{ minWidth: 0 }}
            />
          </div>
        </div>
        <HasTable<User>
          rowKey="id"
          data={users}
          columns={this.getColumns()}
          loading={loading}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          useScroll
          rowClassName={(user) => (user.deleted ? 'bg--danger' : '')}
        />
        <HasEditUserModal
          visible={showEdit}
          user={userToEdit}
          divisions={divisions}
          onOk={this.saveUser}
          onCancel={() => this.setState({ userToEdit: {} as User, showEdit: false })}
        />
        <HasChangePasswordModal
          visible={showChangePassword}
          onOk={this.changePassword}
          onCancel={() => this.setState({ showChangePassword: false })}
        />
        <HasInviteUserModal
          domain={'@'.concat(companyDomain)}
          visible={showInvite}
          onCancel={() => this.setState({ showInvite: false })}
          onOk={this.inviteUser}
        />
        <AnonymizeModal
          visible={showAnonymize}
          onOk={this.removeAndAnonymize}
          onCancel={() => this.setState({ showAnonymize: false })}
          user={userToRemove}
        />
      </div>
    );
  }
}

export default HasUsersSettings;
