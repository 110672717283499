export enum SETTINGS_ACTIONS {
  USER_EDIT = 'editUser',
  USER_CHANGE_PASS = 'changePassword',
  USER_CHANGE_STATUS = 'activate',
  USER_REMOVE = 'remove',
  USER_UNDELETE = 'undelete',
  USER_REMOVE_ANON = 'removeAndAnonymize',
}

export enum FILE_SIZE_UNITS {
  B,
  KB,
  MB,
  GB,
  TB,
}

export enum TermsAndConditionsParagraphType {
  TEXT,
  BULLET_LIST,
  SECTIONED_LIST,
}

export enum PDF_EXPORT {
  REPORT,
  INVESTIGATION,
  REVIEW,
  ACTIONS,
  IMAGES,
}

export enum ACTION_ACTIONS {
  OPEN_ACTION = 'openAction',
  CHANGE_STATUS_ACTION = 'changeStatusAction',
  OVERDUE_ACTION = 'overDueAction',
  EDIT_ACTION = 'editAction',
  REASSIGN_ACTION = 'reassignAction',
  DELETE_ACTION = 'deleteAction',
}
