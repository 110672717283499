import React from 'react';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { AutoComplete } from 'antd';

interface OwnAutoCompleteProps extends AutoCompleteProps {
  width: number;
}

class HasAutoComplete extends React.Component<OwnAutoCompleteProps, {}> {
  filterFunction = (input: string, option: any) => {
    return option.label.toLowerCase().trim().includes(input.toLowerCase().trim());
  };

  render() {
    return (
      <AutoComplete
        {...this.props}
        filterOption={this.props.filterOption ? this.filterFunction : undefined}
        style={{ width: this.props.width, cursor: 'text', userSelect: 'all' }}
      />
    );
  }
}

export default HasAutoComplete;
