import React from 'react';
import { ImagePropsType } from '../../../shared';

class HasImage extends React.Component<ImagePropsType, {}> {
  render() {
    return <img {...this.props} alt={this.props.alt} />;
  }
}

export default HasImage;
