import { SolutionOutlined } from '@ant-design/icons';
import { Divider, Steps } from 'antd';
import { StepProps } from 'antd/lib/steps';
import React, { ReactNode } from 'react';
import { Prompt } from 'react-router-dom';
import { HasAvatar, HasTitle } from '../..';
import { IncidentSubType, IncidentSubTypeLabel, Investigation, LogEntry } from '../../../models';
import { COLORS, ComplexRadioOption, DELIMITER, ErrorMessages } from '../../../shared';
import { getNewLogEntry, i18nLabelParser, InvestigationQuestions } from '../../../utils';
import { HasAlert, HasText } from '../../atoms';
import { HasInvestigationFormEntries } from './';
import {
  HasCauseRadioGroup,
  HasComment,
  HasComplexRadioGroup,
  HasPlantContributionForm,
  HasReasonRadioGroup,
  HasRiskMatrixRatioGroup,
  HasSimpleRadioGroup,
} from './form-components';
import i18n from '../../../i18n/config';

interface InvestigationFormProps {
  investigation: Investigation;
  investigationForm: { key: string; data: LogEntry[] }[];
  fieldChangedCallback: any;
}

class HasInvestigationForm extends React.Component<InvestigationFormProps> {
  onEntryChange = (field: string) => {
    this.hasUnsavedData[field] = true;
  };

  onEntrySave = (field: string, data: any) => {
    const newEntry: LogEntry = getNewLogEntry(
      this.props.investigationForm.find((formField) => formField.key === field)?.data,
      data
    );
    this.props.fieldChangedCallback(field, newEntry);
    this.hasUnsavedData[field] = false;
  };

  getSimpleRadioGroupComponent = (entry: string, options: string[], title?: string): ReactNode => (
    <HasSimpleRadioGroup
      radioOptions={options}
      onChange={() => this.onEntryChange(entry)}
      onSave={(data: any, extra?: any) => this.onEntrySave(entry, data)}
      questionTitle={title}
    />
  );

  getFreeTextComponent = (entry: string): ReactNode => (
    <HasComment forEntity={entry} onSave={(data: any) => this.onEntrySave(entry, data)} />
  );

  getComplexRadioGroup = (entry: string, options: ComplexRadioOption[]) => (
    <HasComplexRadioGroup
      radioOptions={options}
      onChange={() => this.onEntryChange(entry)}
      onSave={(data: any) => this.onEntrySave(entry, data)}
    />
  );

  getReasonRadioGroup = (entry: string): ReactNode => (
    <HasReasonRadioGroup
      save={(data: any) => this.onEntrySave(entry, data)}
      changeCallback={() => this.onEntryChange(entry)}
      isInvestigationReason={true}
    />
  );

  getCauseRadioGroup = (entry: string): ReactNode => (
    <HasCauseRadioGroup
      save={(data: any) => this.onEntrySave(entry, data)}
      changeCallback={() => this.onEntryChange(entry)}
      isInvestigationCause={true}
    />
  );

  getRiskMatrixRadioGroup = (entry: string): ReactNode => {
    return (
      <HasRiskMatrixRatioGroup
        onChange={() => this.onEntryChange(entry)}
        onSave={(data: any) => this.onEntrySave(entry, data)}
      />
    );
  };

  // Dictionaries for ReactNodes and usaved data management

  private newEntryComponent: Record<string, ReactNode> = {
    incidentType: this.getSimpleRadioGroupComponent('incidentType', [
      IncidentSubTypeLabel[IncidentSubType.LTI],
      IncidentSubTypeLabel[IncidentSubType.MTI],
      IncidentSubTypeLabel[IncidentSubType.FIRST_AID],
      IncidentSubTypeLabel[IncidentSubType.NON_INJURY],
      IncidentSubTypeLabel[IncidentSubType.PROPERTY_DAMAGE],
    ]),
    areaSafe: this.getComplexRadioGroup('areaSafe', [
      { label: 'shared.yes' },
      { label: 'shared.no', subComponent: this.getFreeTextComponent('areaSafe') },
    ]),
    insuranceCompanyInformed: this.getSimpleRadioGroupComponent('insuranceCompanyInformed', [
      'shared.yes',
      'shared.no',
    ]),
    activity: this.getFreeTextComponent('activity'),
    howDidItHappen: this.getFreeTextComponent('howDidItHappen'),
    riskAssessmentConducted: this.getComplexRadioGroup('riskAssessmentConducted', [
      {
        label: 'shared.yes',
        subComponent: (
          <HasAlert
            type="info"
            message={<HasText content={i18n.t('investigation.uploadDocuments')} style={{ fontSize: '12px' }} />}
            showIcon
            style={{ fontSize: '12px' }}
          />
        ),
        withoutSaveFunctionality: true,
      },
      { label: 'shared.no', subComponent: this.getFreeTextComponent('riskAssessmentConducted') },
    ]),
    hazardsAndRisks: this.getFreeTextComponent('hazardsAndRisks'),
    riskOfHazardIdentified: this.getSimpleRadioGroupComponent('riskOfHazardIdentified', ['shared.yes', 'shared.no']),
    similarHazardsAndRisksExist: this.getSimpleRadioGroupComponent('similarHazardsAndRisksExist', [
      'shared.no',
      'investigation.some',
      'investigation.many',
    ]),
    victimsLengthInRole: this.getSimpleRadioGroupComponent('victimsLengthInRole', [
      `< 6${DELIMITER}investigation.months`,
      `2${DELIMITER}investigation.years`,
      `> 2${DELIMITER}investigation.years`,
    ]),
    victimsLengthOfEmployment: this.getSimpleRadioGroupComponent('victimsLengthOfEmployment', [
      `< 6${DELIMITER}investigation.months`,
      `2${DELIMITER}investigation.years`,
      `> 2${DELIMITER}investigation.years`,
    ]),
    victimHasWorkRelatedIssues: this.getComplexRadioGroup('victimHasWorkRelatedIssues', [
      {
        label: 'shared.yes',
        subComponent: this.getSimpleRadioGroupComponent(
          'victimHasWorkRelatedIssues',
          ['shared.yes', 'shared.no'],
          'investigation.absentFromJob'
        ),
        answerLabel: 'investigation.absentFromJobLabel',
      },
      { label: 'shared.no' },
    ]),
    drugsAndAlcoholInvolved: this.getSimpleRadioGroupComponent('drugsAndAlcoholInvolved', ['shared.yes', 'shared.no']),
    unusualWorkConditions: this.getComplexRadioGroup('unusualWorkConditions', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('unusualWorkConditions') },
      { label: 'shared.no' },
    ]),
    organisationInfluencedTheEvent: this.getComplexRadioGroup('organisationInfluencedTheEvent', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('organisationInfluencedTheEvent') },
      { label: 'shared.no' },
    ]),
    workingAreaMaintained: this.getComplexRadioGroup('workingAreaMaintained', [
      { label: 'shared.yes' },
      { label: 'shared.no', subComponent: this.getFreeTextComponent('workingAreaMaintained') },
    ]),
    werePeopleInvolvedCompetent: this.getComplexRadioGroup('werePeopleInvolvedCompetent', [
      { label: 'shared.yes' },
      { label: 'shared.no', subComponent: this.getFreeTextComponent('werePeopleInvolvedCompetent') },
    ]),
    peopleAuthorizedToUndertakeTask: this.getSimpleRadioGroupComponent('peopleAuthorizedToUndertakeTask', [
      'shared.yes',
      'shared.no',
    ]),
    workplaceLayoutContributeToEvent: this.getComplexRadioGroup('workplaceLayoutContributeToEvent', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('workplaceLayoutContributeToEvent') },
      { label: 'shared.no' },
    ]),
    materialsContributeToEvent: this.getComplexRadioGroup('materialsContributeToEvent', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('materialsContributeToEvent') },
      { label: 'shared.no' },
    ]),
    plantContributeToEvent: (
      <HasPlantContributionForm
        onChange={() => this.onEntryChange('plantContributeToEvent')}
        onSave={(data: any, extra?: any) => this.onEntrySave('plantContributeToEvent', data)}
      />
    ),
    safetyEquipmentContributeToEvent: this.getComplexRadioGroup('safetyEquipmentContributeToEvent', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('safetyEquipmentContributeToEvent') },
      { label: 'shared.no' },
    ]),
    otherInfluenceToEvent: this.getComplexRadioGroup('otherInfluenceToEvent', [
      { label: 'shared.yes', subComponent: this.getFreeTextComponent('otherInfluenceToEvent') },
      { label: 'shared.no' },
    ]),
    finalReason: this.getReasonRadioGroup('finalReason'),
    primaryRootCause: this.getCauseRadioGroup('primaryRootCause'),
    secondaryRootCause: this.getCauseRadioGroup('secondaryRootCause'),
    incidentRequiresCommunicationToWiderAudience: this.getSimpleRadioGroupComponent(
      'incidentRequiresCommunicationToWiderAudience',
      ['shared.yes', 'shared.no']
    ),
    riskMatrix: this.getRiskMatrixRadioGroup('riskMatrix'),
  };

  private hasUnsavedData: Record<string, boolean> = {
    incidentType: false,
    areaSafe: false,
    insuranceCompanyInformed: false,
    activity: false,
    howDidItHappen: false,
    riskAssessmentConducted: false,
    riskOfHazardIdentified: false,
    hazardsAndRisks: false,
    similarHazardsAndRisksExist: false,
    victimsLengthInRole: false,
    victimsLengthOfEmployment: false,
    victimHasWorkRelatedIssues: false,
    drugsAndAlcoholInvolved: false,
    unusualWorkConditions: false,
    organisationInfluencedTheEvent: false,
    workingAreaMaintained: false,
    werePeopleInvolvedCompetent: false,
    peopleAuthorizedToUndertakeTask: false,
    workplaceLayoutContributeToEvent: false,
    materialsContributeToEvent: false,
    plantContributeToEvent: false,
    safetyEquipmentContributeToEvent: false,
    otherInfluenceToEvent: false,
    finalReason: false,
    primaryRootCause: false,
    secondaryRootCause: false,
    incidentRequiresCommunicationToWiderAudience: false,
    riskMatrix: false,
  };

  editToggledOnEntry = (entry: string, wasEditActive: boolean) => {
    if (this.hasUnsavedData.hasOwnProperty(entry)) {
      if (wasEditActive && this.hasUnsavedData[entry]) {
        const response = window.confirm(ErrorMessages.INVESTIGATION_UNSAVED_FORM_EDIT_TOGGLE);
        if (response) {
          this.hasUnsavedData[entry] = false;
        }
        return response;
      } else {
        return true;
      }
    }
  };

  getFormStepConfig = (): StepProps[] => {
    const { investigationForm } = this.props;
    return investigationForm.map((entry, index) => {
      const title = (
        <HasTitle content={i18nLabelParser(InvestigationQuestions[entry.key])} level={4} style={{ fontSize: '12px' }} />
      );
      const description = (
        <React.Fragment>
          <HasInvestigationFormEntries
            entries={entry.data}
            addEntryComponent={this.newEntryComponent[entry.key]}
            editToggleCallback={(wasEditActive: boolean) => this.editToggledOnEntry(entry.key, wasEditActive)}
          />
          <Divider style={{ margin: '16px 0 8px 0' }} />
        </React.Fragment>
      );
      return {
        key: `incident_report_step_${index}`,
        title: title,
        description: description,
        icon: (
          <HasAvatar
            icon={<SolutionOutlined style={{ verticalAlign: 'middle' }} />}
            size="default"
            style={{ backgroundColor: entry.data.length > 0 ? COLORS.NEW_ORANGE : COLORS.ERROR }}
          />
        ),
      };
    });
  };

  render() {
    const { Step } = Steps;
    return (
      <React.Fragment>
        <Prompt
          when={Object.values(this.hasUnsavedData).some((value) => value)}
          message={ErrorMessages.INVESTIGATION_UNSAVED_PAGE_LEAVE}
        />
        <Steps type="default" current={this.props.investigationForm.length} size="default" direction="vertical">
          {this.getFormStepConfig().map((config, index) => (
            <Step key={index} {...config} />
          ))}
        </Steps>
      </React.Fragment>
    );
  }
}

export default HasInvestigationForm;
