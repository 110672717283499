import { CaretRightOutlined, EyeOutlined, LockFilled, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Carousel, Col, Popconfirm, Row } from 'antd';
import React, { ReactNode } from 'react';
import { MdDelete } from 'react-icons/md';
import { Subscription } from 'rxjs';
import i18n from '../../../i18n/config';
import { FileIsFor, Photo, UserRole } from '../../../models';
import { EntityAction, PhotoService } from '../../../services';
import { CollapsePanelWithContent, ConfirmMessages, ErrorMessages, SuccessMessages } from '../../../shared';
import { getImageSrcFromBase64, getUserRole } from '../../../utils';
import { HasCollapse, HasImage, HasModal, HasText, Notification } from '../../atoms';
import { HasEmptyState } from '../../molecules';
import ImageZoom from 'react-medium-image-zoom';
import './carousel-arrows.scss';

interface IncidentPhotosProps {
  photos: Photo[];
  hideInvestigationPhotos?: boolean;
  hideReviewPhotos?: boolean;
}

interface IncidentPhotosState {
  showImageModal: boolean;
  activeCollapseKeys: string | string[] | undefined;
  fullPhotos?: Photo[];
  selectedImage?: number;
}

interface PhotoSection {
  areFor: FileIsFor;
  titleKey: string;
  collapseKey: string;
  photos: Photo[];
  canDelete: boolean;
  showSection: boolean;
}

class HasIncidentPhotos extends React.Component<IncidentPhotosProps, IncidentPhotosState> {
  private sections: PhotoSection[] = [
    {
      areFor: FileIsFor.INCIDENT,
      titleKey: 'gallery.forEventCount',
      collapseKey: 'incidentPhotos',
      photos: [],
      canDelete: [UserRole.OWNER, UserRole.SUPER_ADMIN].includes(getUserRole() as UserRole),
      showSection: true,
    },
    {
      areFor: FileIsFor.INVESTIGATION,
      titleKey: 'gallery.forInvestigationCount',
      collapseKey: 'investigationPhotos',
      photos: [],
      canDelete: true,
      showSection: !this.props.hideInvestigationPhotos,
    },
    {
      areFor: FileIsFor.REVIEW,
      titleKey: 'gallery.forReviewCount',
      collapseKey: 'reviewPhotos',
      photos: [],
      canDelete: true,
      showSection: !this.props.hideReviewPhotos,
    },
    {
      areFor: FileIsFor.ACTION,
      titleKey: 'gallery.forActionsCount',
      collapseKey: 'actionsPhotos',
      photos: [],
      canDelete: true,
      showSection: true,
    },
  ];

  state = {
    showImageModal: false,
    photo: '',
    activeCollapseKeys: [this.sections[0]?.collapseKey],
    fullPhotos: [],
    selectedImage: 0,
  };

  private deleteSubscription: Subscription = PhotoService.getPhotoChangedListener().subscribe((event) => {
    if (event.event === EntityAction.DELETE) {
      const sectionIndex = this.sections.findIndex((section) => section.areFor === event.entity.isFor);
      const section = this.sections[sectionIndex];
      if (section) {
        let collapseKeys = this.state.activeCollapseKeys;
        if (
          (section.photos.length === 0 || (section.photos.length === 1 && section.photos[0].id === event.entity.id)) &&
          collapseKeys
        ) {
          if (typeof collapseKeys === 'string' && collapseKeys === section.collapseKey) {
            this.setState({ activeCollapseKeys: undefined });
          } else if (collapseKeys.includes(section.collapseKey)) {
            collapseKeys.splice(collapseKeys.indexOf(section.collapseKey));
            this.setState({ activeCollapseKeys: collapseKeys });
          }
        }
      }
    }
  });

  handlePreview = async (photo: Photo) => {
    const photoIds = this.props.photos
      .filter((minimisedPhoto) => minimisedPhoto.isFor === photo.isFor)
      .map((minimisedPhoto) => minimisedPhoto.id);
    const fullPhotos = (await PhotoService.getByIds(photoIds)).data;
    const selectedPhotoIndex = fullPhotos.findIndex((fullPhoto) => fullPhoto.id === photo.id);
    this.setState({
      showImageModal: true,
      fullPhotos,
      selectedImage: selectedPhotoIndex !== -1 ? selectedPhotoIndex : 0,
    });
  };

  handleDelete = (photo: Photo) => {
    PhotoService.delete(photo.id).then(
      () => {
        Notification.success(SuccessMessages.PHOTO_DELETE);
        PhotoService.photoChanged(EntityAction.DELETE, photo);
      },
      (error) => Notification.error(ErrorMessages.PHOTO_DELETE(error.response?.data.message))
    );
  };

  handlePrivateChange = (photo: Photo) => {
    PhotoService.setPrivate(photo.id, !photo.isPrivate).then(() => {
      Notification.success(SuccessMessages.PHOTO_PRIVATE(!photo.isPrivate));
      PhotoService.photoChanged(EntityAction.REFRESH, photo);
    });
  };

  getImageContent = (photo: Photo, index: number, canDelete: boolean | undefined): ReactNode => {
    return (
      <div className="has-gallery-image img-thumbnail">
        <HasImage
          alt={i18n.t('gallery.imageNumber', { index: index })}
          src={getImageSrcFromBase64(photo.file.fileContent, photo.file.fileType)}
        />
        <div>
          <EyeOutlined
            onClick={() => this.handlePreview(photo)}
            style={{ padding: '10px', marginRight: '5px' }}
            className="icon-hover primary-vibrant"
          />
          {photo.isOwn ? (
            photo.isPrivate ? (
              <LockOutlined
                onClick={() => this.handlePrivateChange(photo)}
                style={{ padding: '10px', marginRight: '5px' }}
                className={'icon-hover primary-vibrant'}
              />
            ) : (
              <UnlockOutlined
                onClick={() => this.handlePrivateChange(photo)}
                style={{ padding: '10px', marginRight: '5px' }}
                className={'icon-hover primary-vibrant'}
              />
            )
          ) : null}
          {canDelete && (
            <Popconfirm
              title={ConfirmMessages.DELETE_ENTITY(i18n.t('nounsWithArticle.photo'))}
              okText={i18n.t('shared.yes')}
              cancelText={i18n.t('shared.no')}
              onConfirm={() => this.handleDelete(photo)}
              placement="top"
            >
              <span style={{ padding: '10px', marginLeft: '5px' }} className="anticon">
                <MdDelete className="icon-hover danger" />
              </span>
            </Popconfirm>
          )}
        </div>
      </div>
    );
  };

  getPrivateImagePlaceholder = (photo: Photo): ReactNode => {
    return (
      <div className="img-thumbnail">
        <LockFilled style={{ padding: '10px', marginRight: '5px', width: '100%', fontSize: '65px' }} />
        <div className="d-flex flex-column align-items-center">
          <HasText
            content={i18n.t('gallery.privateImage')}
            strong
            type="secondary"
            style={{ marginBottom: '8px', fontSize: '14px' }}
          />
        </div>
      </div>
    );
  };

  getGalleryContent = (photos: Photo[], canDelete?: boolean): ReactNode => (
    <Row align="middle" gutter={[5, 5]}>
      {photos.map((photo, index) => (
        <Col key={index} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          {photo.isPrivate && !photo.isOwn
            ? this.getPrivateImagePlaceholder(photo)
            : this.getImageContent(photo, index, canDelete)}
        </Col>
      ))}
    </Row>
  );

  getPhotoGallery = (): ReactNode => {
    this.sections.forEach((section) => (section.photos = []));
    this.props.photos.forEach((photo) => {
      const sectionIndex = this.sections.findIndex((section) => section.areFor === photo.isFor);
      this.sections[sectionIndex]?.photos.push(photo);
    });

    const panelConfigs: CollapsePanelWithContent[] = [];
    this.sections.forEach((section) => {
      if (section.showSection) {
        panelConfigs.push({
          key: section.collapseKey,
          header: <HasText content={i18n.t(section.titleKey, { count: section.photos.length })} strong />,
          content: this.getGalleryContent(section.photos, section!.canDelete),
          disabled: !section.photos.length,
          className: 'has-collapse has-collapse-panel',
        });
      }
    });
    return (
      <HasCollapse
        bordered={false}
        activeKey={this.state.activeCollapseKeys}
        expandIcon={(panelProps) => <CaretRightOutlined rotate={panelProps.isActive ? 90 : 0} />}
        onChange={(key) => this.setState({ activeCollapseKeys: key })}
        panelConfigs={panelConfigs}
        className="has-collapse"
      />
    );
  };

  componentWillUnmount() {
    this.deleteSubscription.unsubscribe();
  }

  render() {
    const { photos } = this.props;
    const { showImageModal, fullPhotos, selectedImage } = this.state;
    const photoArray: Photo[] = fullPhotos;

    return (
      <React.Fragment>
        <div className={'ant-descriptions-title'}>{i18n.t('gallery.attachedPictures')}</div>
        {!photos.length ? (
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <HasEmptyState description={null}>
              <HasText content={i18n.t('gallery.noPhotos')} strong type="secondary" style={{ fontSize: '14px' }} />
            </HasEmptyState>
          </div>
        ) : (
          <React.Fragment>
            {this.getPhotoGallery()}
            <HasModal
              footer={null}
              visible={showImageModal}
              onCancel={() => this.setState({ showImageModal: false })}
              closable={false}
              centered
              className="img-thumbnail modalStyling"
            >
              {showImageModal ? (
                <Carousel draggable={true} initialSlide={selectedImage} slidesToShow={1} arrows={true} dots={false}>
                  {photoArray.map((fullPhoto, index) => (
                    <ImageZoom
                      key={index}
                      image={{
                        src: getImageSrcFromBase64(fullPhoto.file.fileContent, fullPhoto.file.fileType),
                        style: { maxHeight: '500px' },
                      }}
                      defaultStyles={{ zoomContainer: { zIndex: 3000 } }}
                    />
                  ))}
                </Carousel>
              ) : (
                ''
              )}
            </HasModal>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default HasIncidentPhotos;
