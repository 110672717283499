import { PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Skeleton } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IoMdRefresh } from 'react-icons/io';

import {
  HasButton,
  HasCheckbox,
  HasDivisionModal,
  HasDivisionRemoveModal,
  HasTable,
  HasText,
  HasTitle,
  Notification,
} from '../../../components';
import i18n from '../../../i18n/config';
import { Company, Division, UserRole } from '../../../models';
import { DivisionService, EntityAction, IncidentService } from '../../../services';
import { COLORS, ConfirmMessages, ErrorMessages, SORT_DIRECTIONS, SuccessMessages } from '../../../shared';
import { comparatorFn, displayPlaceholderIfEmpty, isUserRoleEqualsTo } from '../../../utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface DivisionsSettingsProps {
  company: Company;
  showOrHideDeletedDivisionsCallback: (includeDeletedDivisions: boolean) => void;
}

interface DivisionsSettingsState {
  showDivisionModal: boolean;
  showDivisionRemoveModal: boolean;
  isCreate: boolean;
  selectedDivision: Division;
}

const TABLE_ITEM_HEIGHT = 55;
const TABLE_HEADER_HEIGHT = 55;

class HasDivisionsSettings extends React.Component<DivisionsSettingsProps, DivisionsSettingsState> {
  state: DivisionsSettingsState = {
    showDivisionModal: false,
    showDivisionRemoveModal: false,
    isCreate: false,
    selectedDivision: {} as Division,
  };

  getColumns = (): ColumnProps<Division>[] => [
    {
      title: i18n.t('shared.name'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '50%',
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      sorter: (a: Division, b: Division) => comparatorFn<string>(a.name.toUpperCase(), b.name.toUpperCase()),
    },
    {
      title: i18n.t('settings.users'),
      key: 'userCount',
      render: (_, record: Division) => displayPlaceholderIfEmpty(record.userCount?.toString()),
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      render: (_, record: Division) => (
        <>
          {record.deleted ? (
            !isUserRoleEqualsTo(UserRole.ADMIN) && (
              <div style={{ fontSize: '20px' }}>
                <Popconfirm
                  title={ConfirmMessages.UNDELETE_ENTITY(i18n.t('nounsWithArticle.division'))}
                  okText={i18n.t('shared.yes')}
                  cancelText={i18n.t('shared.no')}
                  onConfirm={() => this.undeleteDivision(record)}
                  placement="topLeft"
                >
                  <IoMdRefresh className="icon-hover danger" />
                </Popconfirm>
              </div>
            )
          ) : (
            <div style={{ fontSize: '18px' }}>
              <MdEdit
                onClick={() => this.setState({ showDivisionModal: true, selectedDivision: record })}
                style={{ marginRight: '20px' }}
                className="icon-hover primary"
              />
              {!isUserRoleEqualsTo(UserRole.ADMIN) && (
                <Popconfirm
                  title={ConfirmMessages.DELETE_ENTITY(i18n.t('nounsWithArticle.division'))}
                  okText={i18n.t('shared.yes')}
                  cancelText={i18n.t('shared.no')}
                  onConfirm={() => this.onDeleteDivision(record)}
                  placement="topLeft"
                >
                  <MdDelete className="icon-hover danger" />
                </Popconfirm>
              )}
            </div>
          )}
        </>
      ),
    },
  ];

  onDeleteDivision = (division: Division) => {
    IncidentService.divisionHasAnyIncidents(division.id)
      .then(({ data: exists }) => {
        exists
          ? this.setState({ showDivisionRemoveModal: true, selectedDivision: division })
          : this.deleteDivision(division);
      })
      .catch((error) => Notification.error({ message: error.response.data.message }));
  };

  deleteDivision = (division: Division, divisionToMoveIncidents?: number) => {
    DivisionService.delete(division.id, divisionToMoveIncidents).then(
      () => {
        Notification.success(SuccessMessages.DIVISION_DELETE(division.name));
        DivisionService.divisionChanged(EntityAction.REFRESH, division);
      },
      (error) => Notification.error(ErrorMessages.DIVISION_DELETE_ERROR(division.name, error.response?.data.message))
    );
  };

  undeleteDivision = async (division: Division) => {
    DivisionService.undelete(division.id)
      .then(
        () => {
          Notification.success(SuccessMessages.DIVISION_UNDELETE(division.name));
          this.props.showOrHideDeletedDivisionsCallback(true);
        },
        (error) =>
          Notification.error(ErrorMessages.DIVISION_UNDELETE_ERROR(division.name, error.response?.data.message))
      )
      .catch((error) => Notification.error(ErrorMessages.UNEXPECTED_ERROR(error.response?.data.message)));
  };

  processModalResult = async (division: Division): Promise<null> => {
    if (this.state.isCreate) {
      try {
        await DivisionService.create({ ...division, companyId: this.props.company.id });
        Notification.success(SuccessMessages.DIVISION_CREATE(division.name));
      } catch (error) {
        Notification.error(ErrorMessages.DIVISION_CREATE(error.response?.data.message));
        return new Promise((_, reject) => reject());
      }
    } else {
      try {
        await DivisionService.update(division);
        Notification.success(SuccessMessages.DIVISION_UPDATE);
      } catch (error) {
        Notification.error(ErrorMessages.DIVISION_UPDATE(error.response?.data.message));
        return new Promise((_, reject) => reject());
      }
    }
    DivisionService.divisionChanged(EntityAction.REFRESH, division);
    this.setState({ showDivisionModal: false, isCreate: false });
    return new Promise((resolve) => resolve(null));
  };

  addChildrenToDivisions = (divisions: Division[] | any) => {
    if (!divisions) {
      return null;
    }
    return divisions.map((division: Division) => ({
      ...division,
      children: division.subDivisions?.length ? this.addChildrenToDivisions(division.subDivisions) : null,
    }));
  };

  toggleShowDeletedDivisions = (event: CheckboxChangeEvent) => {
    this.props.showOrHideDeletedDivisionsCallback(event.target.checked);
  };

  render() {
    const { company } = this.props;
    const { showDivisionModal, showDivisionRemoveModal, isCreate, selectedDivision } = this.state;
    const divisions = company.divisions;
    const divisionsWithChildren = this.addChildrenToDivisions(divisions)?.filter(
      (division: Division) => !division.parentId
    );

    return (
      <div className="d-flex flex-column flex-grow-1">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div>
            <HasTitle
              content={i18n.t('shared.divisions')}
              level={4}
              style={{ fontSize: '18px', marginBottom: 0, color: COLORS.NEW_ORANGE }}
            />
            <Skeleton
              active
              paragraph={false}
              title={{ width: '150px' }}
              loading={!divisions || divisions === null || divisions === undefined}
            >
              <HasText
                content={i18n.t('dataDisplay.displayingResults', { count: divisions ? divisions.length : 0 })}
                style={{ fontSize: '12px' }}
              />
            </Skeleton>
          </div>
          <div>
            <HasCheckbox content={i18n.t('settings.showDeleted')} onChange={this.toggleShowDeletedDivisions} />
            <HasButton
              onClick={() =>
                this.setState({ showDivisionModal: true, isCreate: true, selectedDivision: {} as Division })
              }
              type="primary"
              disabled={!divisions || divisions === null || divisions === undefined}
              icon={<PlusOutlined />}
              style={{ minWidth: 0 }}
            />
          </div>
        </div>
        <HasTable<Division>
          rowKey="id"
          data={divisionsWithChildren}
          columns={this.getColumns()}
          loading={!divisions || divisions === null || divisions === undefined}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          useScroll
          rowClassName={(division) => (division.deleted ? 'bg--danger' : '')}
        />
        <HasDivisionModal
          company={company}
          division={selectedDivision}
          isCreate={isCreate}
          visible={showDivisionModal}
          onOk={this.processModalResult}
          onCancel={() => this.setState({ showDivisionModal: false, isCreate: false })}
        />
        <HasDivisionRemoveModal
          division={selectedDivision}
          divisions={
            !divisions || divisions === null || divisions === undefined
              ? []
              : divisions && divisions.filter((division) => division.id !== selectedDivision.id)
          }
          visible={showDivisionRemoveModal}
          onOk={(divisionToMoveIncidents) => this.deleteDivision(selectedDivision, divisionToMoveIncidents)}
          onCancel={() => this.setState({ showDivisionRemoveModal: false })}
        />
      </div>
    );
  }
}

export default HasDivisionsSettings;
