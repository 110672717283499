import { Alert } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { HasParagraph, HasTitle } from '../../components';
import { COLORS } from '../../shared';

interface ErrorBoundaryProps extends WithTranslation {
  showTrace?: boolean;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: { componentStack: string };
}

class HasErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null, errorInfo: { componentStack: '' } };

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { showTrace, t } = this.props;
    const { error, errorInfo } = this.state;
    return error ? (
      <div style={{ padding: '5px', backgroundColor: COLORS.GRAY_WHITE }} className="h-100">
        <div className="shadow-sm has-container-tile h-100 overflow-y-auto">
          <div className="has-fallback-page">
            <MdErrorOutline style={{ fontSize: '120px', color: COLORS.ERROR, marginBottom: '20px' }} />
            <HasTitle
              content={t('fallback.somethingWrong')}
              level={1}
              type="secondary"
              style={{ marginBottom: '40px' }}
            />
            <Alert
              type="error"
              message={
                error && error !== null ? (
                  <div>
                    <HasParagraph content={t('fallback.errorOccurred')} style={{ marginBottom: '5px' }} />
                    <HasParagraph content={(error as any).toString()} style={{ marginBottom: '0' }} strong />
                  </div>
                ) : null
              }
              description={
                showTrace ? (
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                      maxHeight: '200px',
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                    className="overflow-y-auto"
                  >
                    {errorInfo.componentStack.concat(errorInfo.componentStack)}
                  </div>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default withTranslation()(HasErrorBoundary);
