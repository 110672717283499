import { Badge } from 'antd';
import React from 'react';
import { BadgeProps } from 'antd/lib/badge';

class HasBadge extends React.Component<BadgeProps, {}> {
  render() {
    return <Badge {...this.props}>{this.props.children}</Badge>;
  }
}

export default HasBadge;
