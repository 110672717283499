import { Autocomplete } from '@react-google-maps/api';
import React from 'react';
import { MdCheck } from 'react-icons/md';
import { HasButton, HasTextInput } from '../..';
import { MapsContext } from '../../../App';
import { ChatBotCustomComponentProps, DEFAULT_MAP_CENTER, Location, ErrorMessages, COLORS } from '../../../shared';
import { HasMap } from '../map';
import { Toast } from '../../atoms';
import i18n from '../../../i18n/config';

interface ChatBotMapState {
  canChangeLocation: boolean;
  markerPosition: Location;
  searchedAddress: string | undefined;
}

class HasChatBotMap extends React.Component<ChatBotCustomComponentProps, ChatBotMapState> {
  state = {
    canChangeLocation: true,
    markerPosition: this.props.value || { ...DEFAULT_MAP_CENTER },
    searchedAddress: '',
  };

  private autocomplete: google.maps.places.Autocomplete | null = null;

  // TBD how to proceed when error occurs
  handleMapClick = (event: google.maps.MouseEvent) => {
    if (this.state.canChangeLocation) {
      const { latLng } = event;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          this.setState({
            searchedAddress: results[0].formatted_address,
            markerPosition: { lat: latLng.lat(), lng: latLng.lng() },
          });
        } else {
          Toast.error(ErrorMessages.MAPS_GEOCODER_ERROR('').description);
        }
      });
    } else {
      Toast.error(ErrorMessages.INCIDENT_REPORT_LOCATION_ALREADY_SAVED);
    }
  };

  handlePlaceChanged = () => {
    if (this.autocomplete !== null) {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        this.setState({
          searchedAddress: place.formatted_address,
          markerPosition: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        });
      }
    }
  };

  saveLocation = () => {
    const { step, triggerNextStep } = this.props;
    const { markerPosition, searchedAddress } = this.state;
    this.setState({ canChangeLocation: false });
    if (step && triggerNextStep) {
      triggerNextStep({ value: { coords: markerPosition, address: searchedAddress }, trigger: step.metadata.trigger });
    }
  };

  render() {
    const { canChangeLocation, markerPosition, searchedAddress } = this.state;
    return (
      <MapsContext.Consumer>
        {(scriptLoaded) =>
          scriptLoaded && (
            <div
              style={{ height: '250px', backgroundColor: canChangeLocation ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
              className="d-flex flex-row flex-grow-1 has-chat-bot-component"
            >
              <div className="d-flex flex-column flex-grow-1">
                <HasMap markerLocations={[markerPosition]} onMapClick={this.handleMapClick} />
                {canChangeLocation && (
                  <Autocomplete
                    onLoad={(autocomplete: google.maps.places.Autocomplete) => (this.autocomplete = autocomplete)}
                    onPlaceChanged={this.handlePlaceChanged}
                  >
                    <HasTextInput
                      key="address"
                      value={searchedAddress}
                      onChange={(event) => this.setState({ searchedAddress: event.target.value })}
                      placeholder={i18n.t('chatbot.searchAddress')}
                      style={{ marginTop: '10px' }}
                    />
                  </Autocomplete>
                )}
              </div>
              <div className="d-flex">
                {canChangeLocation && (
                  <HasButton
                    type="link"
                    onClick={this.saveLocation}
                    icon={<MdCheck style={{ color: COLORS.SECONDARY_GREEN, fontSize: '20px' }} />}
                    style={{ minWidth: 0, alignSelf: 'center' }}
                  />
                )}
              </div>
            </div>
          )
        }
      </MapsContext.Consumer>
    );
  }
}

export default HasChatBotMap;
