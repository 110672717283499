import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HasImage, HasParagraph, HasTitle } from '../../components';
import './fallback.scss';

class HasNotFoundErrorPage extends React.Component<WithTranslation, {}> {
  render() {
    const { t } = this.props;
    return (
      <div className="shadow-sm has-container-tile h-100">
        <div className="has-fallback-page">
          <HasImage
            src={require('../../assets/images/ic_notfound.svg')}
            style={{ width: '300px', marginBottom: '20px' }}
          />
          <HasTitle content={t('fallback.fallback404Title')} level={1} type="secondary" />
          <HasParagraph content={t('fallback.fallback404Text')} style={{ fontSize: '16px', marginBottom: '40px' }} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(HasNotFoundErrorPage);
