import { SolutionOutlined } from '@ant-design/icons';
import { Divider, Form, Steps } from 'antd';
import en_US from 'antd/lib/calendar/locale/en_US';
import { StepProps } from 'antd/lib/steps';
import { isEmpty, isUndefined } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import { HasAlert, HasAvatar, HasDatePicker, HasDropdown, HasRadioGroup, HasText, HasTextArea, HasTitle } from '../..';
import i18n from '../../../i18n/config';
import {
  Action,
  ActionFormKey,
  ActionImportance,
  DUE_DATE,
  IMPORTANCE,
  OTHER_INFO,
  REQUIRED_ACTION,
  RESPONSIBLE_USER_ID,
  RESPONSIBLE_USER_NAME,
  User,
} from '../../../models';
import {
  COLORS,
  DATE_FORMAT,
  ErrorMessages,
  Option,
  RadioGroupOption,
  TEXT_AREA_MAX_LENGTH,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
} from '../../../shared';
import {
  ActionEntryFormatter,
  ActionPermissions,
  ActionQuestions,
  getActionPermissions,
  getUserFullName,
} from '../../../utils';
import HasActionFormEntries from './action.form.entry';
import { PermissionsContext, PermissionsContextType } from '../../../context';

interface ActionFormProps {
  action: Action;
  users: User[];
  editMode: boolean;
  isCreate: boolean;
  fieldChangedCallback: (field: ActionFormKey, value: string) => void;
}

const { Step } = Steps;

const ACTION_KEYS: Array<ActionFormKey> = [REQUIRED_ACTION, RESPONSIBLE_USER_NAME, DUE_DATE, IMPORTANCE, OTHER_INFO];

const HasActionForm: React.FC<ActionFormProps> = ({ action, users, editMode, isCreate, fieldChangedCallback }) => {
  const { permissions: userPermissions, setPermissionsForUser } = React.useContext(
    PermissionsContext
  ) as PermissionsContextType;

  useEffect(() => {
    setPermissionsForUser();
  }, []);

  const getImportanceRadioGroupConfig = (): TypedRadioGroupProps<ActionImportance> => ({
    size: 'small',
    buttonStyle: 'solid',
    defaultValue: !isCreate && !isUndefined(action) ? action.importance : null,
    onChange: (event: TypedRadioChangeEvent<ActionImportance>) => {
      fieldChangedCallback(IMPORTANCE, event.value);
    },
  });

  const getImportanceRadioGroupOptions = (): RadioGroupOption<ActionImportance>[] => [
    {
      value: ActionImportance.HIGH,
      label: i18n.t('shared.highPriority'),
    },
    {
      value: ActionImportance.MEDIUM,
      label: i18n.t('shared.mediumPriority'),
    },
    {
      value: ActionImportance.LOW,
      label: i18n.t('shared.lowPriority'),
    },
  ];

  const permissions: ActionPermissions = getActionPermissions(action, userPermissions);

  const requiredActionComponent: JSX.Element = (
    <Form.Item
      name={REQUIRED_ACTION}
      colon={false}
      rules={[
        {
          required: true,
          message: (
            <HasText
              content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.requiredAction').toLowerCase())}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
        {
          whitespace: true,
          message: (
            <HasText
              content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.requiredAction').toLowerCase())}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
        {
          max: TEXT_AREA_MAX_LENGTH,
          message: (
            <HasText
              content={ErrorMessages.INVESTIGATION_FREE_TEXT_LENGTH}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
      ]}
    >
      <HasTextArea
        rows={3}
        placeholder={i18n.t('incidents.answerPlaceholder')}
        onChange={(event: any) => fieldChangedCallback(REQUIRED_ACTION, event.target.value)}
        disabled={!permissions.edit && !isCreate}
      />
    </Form.Item>
  );

  const responsibleUserComponent: JSX.Element = isEmpty(users) ? (
    <HasAlert message={i18n.t('action.noUsersAvailable')} type="warning" showIcon />
  ) : (
    <Form.Item
      name={RESPONSIBLE_USER_NAME}
      colon={false}
      rules={[
        {
          required: true,
          message: (
            <HasText
              content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.responsibleUser').toLowerCase())}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
      ]}
    >
      <HasDropdown<User>
        onChange={(value: any, option: Option<User>) => {
          fieldChangedCallback(RESPONSIBLE_USER_NAME, value);
          fieldChangedCallback(RESPONSIBLE_USER_ID, option.forObject.id.toString());
        }}
        placeholder={`${i18n.t('incidents.selectUser')}...`}
        style={{ width: '180px' }}
        options={users.map<Option<User>>((user) => ({ value: user.id, label: getUserFullName(user), forObject: user }))}
        disabled={!permissions.reassign && !isCreate}
      />
    </Form.Item>
  );

  const dueDateComponent: JSX.Element = (
    <Form.Item
      name={DUE_DATE}
      colon={false}
      rules={[
        {
          required: true,
          message: (
            <HasText
              content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.dueDate').toLowerCase())}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
      ]}
    >
      <HasDatePicker
        locale={{ ...en_US, lang: { ...en_US.lang, today: i18n.t('shared.today') } }}
        format={DATE_FORMAT}
        placeholder={i18n.t('incidents.selectDueDate')}
        allowClear={true}
        onChange={(dateMoment: Moment | null) => {
          if (dateMoment) fieldChangedCallback(DUE_DATE, dateMoment.format('YYYY-MM-DD'));
        }}
        style={{ width: '180px' }}
        disabled={!permissions.edit && !isCreate}
      />
    </Form.Item>
  );

  const importanceComponent: JSX.Element = (
    <Form.Item
      name={IMPORTANCE}
      colon={false}
      rules={[
        {
          required: true,
          message: (
            <HasText
              content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.importance').toLowerCase())}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          ),
        },
      ]}
    >
      <HasRadioGroup<ActionImportance>
        {...getImportanceRadioGroupConfig()}
        groupOptions={getImportanceRadioGroupOptions()}
        disabled={!permissions.edit && !isCreate}
      />
    </Form.Item>
  );

  const otherInfoComponent: JSX.Element = (
    <Form.Item name={OTHER_INFO} colon={false}>
      <HasTextArea
        rows={3}
        maxLength={1024}
        placeholder={i18n.t('incidents.answerPlaceholder')}
        onChange={(event: any) => fieldChangedCallback(OTHER_INFO, event.target.value)}
        disabled={!permissions.edit && !isCreate}
      />
    </Form.Item>
  );

  const entryComponents: Record<string, JSX.Element | undefined> = {
    [REQUIRED_ACTION]: requiredActionComponent,
    [RESPONSIBLE_USER_NAME]: responsibleUserComponent,
    [DUE_DATE]: dueDateComponent,
    [IMPORTANCE]: importanceComponent,
    [OTHER_INFO]: otherInfoComponent,
  };

  const getEntryComponent = (key: ActionFormKey): JSX.Element => {
    return (
      <Form
        layout={'vertical'}
        hideRequiredMark={true}
        initialValues={{ ...action, dueDate: action && action.dueDate ? moment(action.dueDate) : null }}
      >
        {entryComponents[key]}
      </Form>
    );
  };

  const getFormStepConfig = (): StepProps[] => {
    const icon = (
      <HasAvatar
        icon={<SolutionOutlined className="align-middle" />}
        size="default"
        style={{ backgroundColor: COLORS.NEW_ORANGE }}
      />
    );

    return ACTION_KEYS.map((key) => {
      const title = (
        <HasTitle content={i18n.t(ActionQuestions[key]).toUpperCase()} level={4} style={{ fontSize: '12px' }} />
      );
      action.history[key].forEach((logEntry) => {
        logEntry.fieldKey = key;
      });

      const description = (
        <>
          <HasActionFormEntries
            editMode={editMode}
            entries={action.history[key]}
            action={action}
            entryComponent={getEntryComponent(key)}
            formatter={ActionEntryFormatter[key]}
          />
          <Divider style={{ margin: '16px 0 8px 0' }} />
        </>
      );
      return {
        title: title,
        description: description,
        icon: icon,
      };
    });
  };

  return (
    <Steps type="default" current={4} size="default" direction="vertical">
      {getFormStepConfig().map((config, index) => (
        <Step key={index} {...config} />
      ))}
    </Steps>
  );
};

export { HasActionForm };
