import { CheckOutlined, WarningFilled } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { capitalize, isEmpty, isUndefined } from 'lodash';
import React, { ReactText, useEffect, useState } from 'react';
import { HasButton, HasSpinner, HasText, HasTitle } from '../../components';
import i18n from '../../i18n/config';
import { Company, FormLabel, IncidentType, NewIncident } from '../../models';
import { ChatBotStep, COLORS, FORM_RESULT_KEYS, ORG_SELECTION } from '../../shared';
import { EventUtils, HistoricalEventsUtils } from '../../utils';
import { HasEventDetails } from './event.details';
import { HasHistoricalEventsList } from './historical.events.list';

interface EventsOverviewProps {
  events: Array<Object>;
  formSteps: { formType: IncidentType; steps: ChatBotStep[] }[];
  formLabels: FormLabel[];
  company: Company;
  onUpload: (event: NewIncident[]) => void;
}

const { confirm } = Modal;

const { REPORTING_PERSON_IS_VICTIM, WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED } = FORM_RESULT_KEYS;

const HasEventsOverview: React.FC<EventsOverviewProps> = ({
  events,
  formSteps,
  formLabels,
  company,
  onUpload,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [mappedEvents, setMappedEvents] = useState<NewIncident[]>([]);
  const [minorProblemsCount, setMinorProblemsCount] = useState<number>(0);
  const [majorProblemsCount, setMajorProblemsCount] = useState<number>(0);

  useEffect(() => {
    const eventsList: NewIncident[] = [];
    let minorProblemsCount = 0;
    let majorProblemsCount = 0;
    events.forEach((event) => {
      const type = HistoricalEventsUtils.getEventType(event);
      const steps = formSteps.find((stepsData) => stepsData.formType === type)?.steps;
      if (!isUndefined(type) && steps) {
        const mappedEvent = HistoricalEventsUtils.mapEventObjectToIncidentModel(steps, company, event, type.toString());
        eventsList.push(mappedEvent);
        if (mappedEvent.invalidEntryKeys && mappedEvent.invalidEntryKeys?.length > 0) minorProblemsCount += 1;
      } else {
        eventsList.push({ type: type ? type.toString() : undefined, formResult: [], hasErrors: true });
        majorProblemsCount += 1;
      }
    });
    setMappedEvents(eventsList);
    setMinorProblemsCount(minorProblemsCount);
    setMajorProblemsCount(majorProblemsCount);
    setLoading(false);
  }, [events, formSteps, company]);

  const selectedEventFormResultValueChanged = (key: ReactText, value: string) => {
    setMappedEvents((prevEvents) => {
      const events = [...prevEvents];
      if (!isUndefined(selectedIndex)) {
        const event = events[selectedIndex];
        const formResults = event.formResult;
        const searchedFormResultIndex = formResults.findIndex(({ key: formResultKey }) => formResultKey === key);
        if (searchedFormResultIndex > -1) {
          if (key === ORG_SELECTION) {
            const division = company.divisions.find(({ id }) => id === +value);
            event.division = division;
            formResults[searchedFormResultIndex].value = division?.name || value;
          } else {
            formResults[searchedFormResultIndex].value = value;
          }
        }
        if (event.invalidEntryKeys) {
          event.invalidEntryKeys = event.invalidEntryKeys.filter(({ key: invalidKey }) => invalidKey !== key);
        }
        if (!event.description) {
          event.description = EventUtils.getDescriptionFromFormResult(event);
        }
        if ([REPORTING_PERSON_IS_VICTIM, WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED].includes(key.toString())) {
          const ignoredKeys = EventUtils.getIgnoredFormResults(event.formResult);
          event.formResult = event.formResult.filter(({ key }) => !ignoredKeys.includes(key.toString()));
          if (!isUndefined(event.invalidEntryKeys)) {
            event.invalidEntryKeys = event.invalidEntryKeys.filter(({ key }) => !ignoredKeys.includes(key.toString()));
          }
        }
      }
      return events;
    });
    setMinorProblemsCount(() => {
      const newCount = mappedEvents.filter(
        (event) => event.invalidEntryKeys && event.invalidEntryKeys?.length > 0
      ).length;
      return newCount;
    });
  };

  const getRelevantFormStructure = (type: string | undefined): ChatBotStep[] | undefined => {
    return type ? formSteps.find(({ formType }) => formType.toString() === type)?.steps : undefined;
  };

  const getRelevantFormLabels = (type: string | undefined): { key: string; question: string }[] => {
    return type ? formLabels.find(({ formType }) => formType === type)?.questionLabels || [] : [];
  };

  const getEventDetailsProps = (selectedIndex: number) => {
    const event = mappedEvents[selectedIndex];
    return {
      event,
      onFormResultChange: selectedEventFormResultValueChanged,
      formSteps: getRelevantFormStructure(event.type),
      formLabels: getRelevantFormLabels(event.type),
      company,
    };
  };

  const getStatisticsTooltips = (): JSX.Element => {
    const withoutProblems = mappedEvents.length - majorProblemsCount - minorProblemsCount;
    return (
      <div>
        {majorProblemsCount > 0 && (
          <Tooltip title={i18n.t('historicalEvents.majorErrorsCount', { count: majorProblemsCount })}>
            <WarningFilled style={{ color: COLORS.ERROR, fontSize: 20 }} className="mr-2" />
          </Tooltip>
        )}
        {minorProblemsCount > 0 && (
          <Tooltip title={i18n.t('historicalEvents.minorErrorsCount', { count: minorProblemsCount })}>
            <WarningFilled style={{ color: COLORS.WARNING, fontSize: 20 }} className="mr-2" />
          </Tooltip>
        )}
        {withoutProblems > 0 && (
          <Tooltip title={i18n.t('historicalEvents.readyForUpload', { count: withoutProblems })}>
            <CheckOutlined style={{ color: COLORS.SECONDARY_GREEN, fontSize: 20 }} className="mr-2" />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleUpload = () => {
    const okCallback = () => {
      onUpload(mappedEvents.filter(({ invalidEntryKeys, hasErrors }) => isEmpty(invalidEntryKeys) && !hasErrors));
    };
    if (minorProblemsCount || majorProblemsCount) {
      confirm({
        title: i18n.t('historicalEvents.uploadConfirm'),
        content: i18n.t('historicalEvents.uploadConfirmBody', {
          count: mappedEvents.length - minorProblemsCount - majorProblemsCount,
          total: mappedEvents.length,
        }),
        onOk: okCallback,
      });
    } else {
      okCallback();
    }
  };

  return (
    <div className="d-flex flex-column flex-grow-1 w-100 overflow-auto">
      {loading ? (
        <HasSpinner size="large" />
      ) : (
        <>
          <div className="d-flex flex-row flex-grow-1 overflow-auto mb-4">
            <div className="col-4 p-0 d-flex flex-column">
              <div className="d-flex flex-row">
                <HasTitle
                  level={4}
                  content={i18n.t('historicalEvents.eventsCount', { count: mappedEvents.length })}
                  className="mr-4"
                />
                {getStatisticsTooltips()}
              </div>
              <div className="flex-grow-1 overflow-auto">
                <HasHistoricalEventsList
                  events={mappedEvents}
                  onRowClick={(index) => setSelectedIndex(index)}
                  selectedIndex={selectedIndex}
                />
              </div>
            </div>
            <div className="col-8 d-flex flex-column">
              <div>
                <HasTitle level={4} content={capitalize(i18n.t('header.eventDetailsGeneric'))} />
              </div>
              <div className="d-flex flex-grow-1 overflow-auto justify-content-around">
                {isUndefined(selectedIndex) ? (
                  <HasText content={i18n.t('historicalEvents.eventDetailsEmptyState')} className="align-self-center" />
                ) : (
                  <HasEventDetails {...getEventDetailsProps(selectedIndex)} />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-shrink-0 float-right justify-content-end">
            <HasButton
              type="primary"
              size="middle"
              onClick={() => handleUpload()}
              disabled={events.length <= minorProblemsCount + majorProblemsCount}
            >
              {i18n.t('attachment.upload')}
            </HasButton>
          </div>
        </>
      )}
    </div>
  );
};

export { HasEventsOverview };
