import { Form, Steps, Tooltip } from 'antd';
import {
  Action,
  ActionClassification,
  ActionComplianceForm,
  ActionRegulatoryQuestions,
  RiskImpact,
  RiskImpactLabelWithTranslation,
  RiskLevel,
  RiskLevelLabel,
  ActionRiskMatrix,
  RiskProbability,
  RiskProbabilityLabelWithTranslation,
  User,
  YesNoOption,
  ActionStatus,
} from '../../../models';
import React, { useEffect, useState } from 'react';
import { StepProps } from 'antd/lib/steps';
import { HasAvatar, HasButton, HasParagraph, HasText, HasTitle, Notification } from '../../atoms';
import { SolutionOutlined } from '@ant-design/icons';
import { COLORS, ErrorMessages, RISK_SCORE_COLOR, RadioGroupOption, TypedRadioGroupProps } from '../../../shared';
import i18n from '../../../i18n/config';
import {
  ActionClassificationLabel,
  ActionRegulatoryQuestionsLabel,
  EventUtils,
  YesNoOptionLabel,
  isLoggedInUserCreatorOrSuperAdminOrOwner,
} from '../../../utils';
import { useTranslation } from 'react-i18next';
import { HasRadioGroup } from '../../molecules';
import { ActionComplianceFormService, ActionService, EntityAction } from '../../../services';

interface ActionComplianceFormProps {
  action: Action;
  currentUser?: User;
  handleRiskColor: (color: string) => void;
}

enum Mode {
  CREATE,
  EDIT,
  VIEW,
}

const HasActionComplianceForm: React.FC<ActionComplianceFormProps> = ({ action, currentUser, handleRiskColor }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [actionCompliance, setActionCompliance] = useState<ActionComplianceForm>();
  const [mode, setMode] = useState<Mode>(action.complianceFormCompleted ? Mode.VIEW : Mode.CREATE);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (mode === Mode.VIEW) {
      ActionComplianceFormService.getByActionId(action.id!)
        .then((response) => {
          setActionCompliance(response.data);
          setMode(Mode.VIEW);

          const impactIndex = EventUtils.getRiskImpactIndex(response.data.impact);
          const probabilityIndex = EventUtils.getRiskProbabilityIndex(response.data.probability);

          impactIndex && probabilityIndex && handleRiskColor(RISK_SCORE_COLOR[impactIndex - 1][probabilityIndex - 1]);
        })
        .catch(() => setMode(Mode.CREATE));
    }
    if (mode === Mode.EDIT) {
      form.setFields([
        {
          name: ActionRegulatoryQuestions.INVESTIGATED_AND_ACTED_UPON,
          value: actionCompliance?.investigatedAndActedUpon,
        },
        {
          name: ActionRegulatoryQuestions.PREVENTIVE_ACTIONS_EFFICENT,
          value: actionCompliance?.preventiveActionsEfficient,
        },
        {
          name: ActionRegulatoryQuestions.ADDITIONAL_RISKS,
          value: actionCompliance?.additionalRisks,
        },
        {
          name: ActionRegulatoryQuestions.CLASSIFICATION,
          value: actionCompliance?.classification,
        },
        {
          name: [ActionRegulatoryQuestions.RISK_MATRIX, ActionRiskMatrix.IMPACT],
          value: actionCompliance?.impact,
        },
        {
          name: [ActionRegulatoryQuestions.RISK_MATRIX, ActionRiskMatrix.PROBABILITY],
          value: actionCompliance?.probability,
        },
      ]);
    }
  }, [mode, action]);

  const updateAction = (riskLevel: RiskLevel, riskScore: number, action: Action) => {
    action.riskLevel = riskLevel;
    action.riskScore = riskScore;
    action.complianceFormCompleted = true;
    ActionService.actionChanged(EntityAction.UPDATE, action);
  };

  const onFinish = (values: any) => {
    if (!form.isFieldsTouched()) {
      setMode(Mode.VIEW);
      return;
    }
    setLoading(true);

    const impactKey = EventUtils.getRiskImpactIndex(values.RISK_MATRIX.IMPACT)!;
    const probabilityKey = EventUtils.getRiskProbabilityIndex(values.RISK_MATRIX.PROBABILITY)!;
    const { riskLevel } = EventUtils.getRiskMatrixValues(impactKey, probabilityKey!);
    const riskScore = impactKey * probabilityKey;

    const newActionComplianceForm: ActionComplianceForm = {
      investigatedAndActedUpon: values.INVESTIGATED_AND_ACTED_UPON,
      preventiveActionsEfficient: values.PREVENTIVE_ACTIONS_EFFICENT,
      additionalRisks: values.ADDITIONAL_RISKS,
      classification: values.CLASSIFICATION,
      impact: values.RISK_MATRIX.IMPACT,
      probability: values.RISK_MATRIX.PROBABILITY,
      riskScore,
      riskLevel,
    };

    if (mode === Mode.CREATE) {
      ActionComplianceFormService.create(newActionComplianceForm, action.id!)
        .then((response) => {
          setMode(Mode.VIEW);
          setActionCompliance(response.data);
          updateAction(riskLevel, riskScore, action);
        })
        .catch((error) => Notification.error({ message: error.response.data.message }))
        .finally(() => setLoading(false));
    } else {
      ActionComplianceFormService.update(newActionComplianceForm, action.id!)
        .then((response) => {
          setMode(Mode.VIEW);
          setActionCompliance(response.data);
          updateAction(riskLevel, riskScore, action);
        })
        .catch((error) => Notification.error({ message: error.response.data.message }))
        .finally(() => setLoading(false));
    }
  };

  // LEFT INTENTIONALY
  // No need for onChange handle because values are updated based on antd form
  const getRadioGroupConfig = (
    questionType: ActionRegulatoryQuestions
  ): TypedRadioGroupProps<boolean | number | string | ActionClassification | RiskProbability | RiskImpact> => ({
    size: 'small',
    buttonStyle: 'solid',
    onChange: (event) => {},
  });

  const getRadioGroupOptions = (
    questionType: ActionRegulatoryQuestions
  ): RadioGroupOption<boolean | number | ActionClassification>[] => {
    if (questionType === ActionRegulatoryQuestions.CLASSIFICATION) {
      return [
        {
          value: ActionClassification.PERSONAL_PROTECTIVE_EQUIPMENT,
          label: ActionClassificationLabel[ActionClassification.PERSONAL_PROTECTIVE_EQUIPMENT](t),
        },
        {
          value: ActionClassification.ADMINISTRATIVE_CONTROL,
          label: ActionClassificationLabel[ActionClassification.ADMINISTRATIVE_CONTROL](t),
        },
        {
          value: ActionClassification.ENGINEER_CONTROL,
          label: ActionClassificationLabel[ActionClassification.ENGINEER_CONTROL](t),
        },
        {
          value: ActionClassification.SUBSTITUTION,
          label: ActionClassificationLabel[ActionClassification.SUBSTITUTION](t),
        },
        {
          value: ActionClassification.ELIMINATION,
          label: ActionClassificationLabel[ActionClassification.ELIMINATION](t),
        },
      ];
    }
    return [
      {
        value: true,
        label: YesNoOptionLabel[YesNoOption.YES](t),
      },
      {
        value: false,
        label: YesNoOptionLabel[YesNoOption.NO](t),
      },
    ];
  };

  const probabilityRadioGroupOptions: RadioGroupOption<RiskProbability>[] = [
    {
      value: RiskProbability.RARE,
      label: `1 - ${RiskProbabilityLabelWithTranslation[RiskProbability.RARE](t)}`,
    },
    {
      value: RiskProbability.UNLIKELY,
      label: `2 - ${RiskProbabilityLabelWithTranslation[RiskProbability.UNLIKELY](t)}`,
    },
    {
      value: RiskProbability.MODERATE,
      label: `3 - ${RiskProbabilityLabelWithTranslation[RiskProbability.MODERATE](t)}`,
    },
    {
      value: RiskProbability.LIKELY,
      label: `4 - ${RiskProbabilityLabelWithTranslation[RiskProbability.LIKELY](t)}`,
    },
    {
      value: RiskProbability.ALMOST_CERTAIN,
      label: `5 - ${RiskProbabilityLabelWithTranslation[RiskProbability.ALMOST_CERTAIN](t)}`,
    },
  ];

  const impactRadioGroupOptions: RadioGroupOption<RiskImpact>[] = [
    {
      value: RiskImpact.INSIGNIFICANT,
      label: `1 - ${RiskImpactLabelWithTranslation[RiskImpact.INSIGNIFICANT](t)}`,
    },
    {
      value: RiskImpact.MINOR,
      label: `2 - ${RiskImpactLabelWithTranslation[RiskImpact.MINOR](t)}`,
    },
    {
      value: RiskImpact.SIGNIFICANT,
      label: `3 - ${RiskImpactLabelWithTranslation[RiskImpact.SIGNIFICANT](t)}`,
    },
    {
      value: RiskImpact.MAJOR,
      label: `4 - ${RiskImpactLabelWithTranslation[RiskImpact.MAJOR](t)}`,
    },
    {
      value: RiskImpact.SEVERE,
      label: `5 - ${RiskImpactLabelWithTranslation[RiskImpact.SEVERE](t)}`,
    },
  ];

  const Component: React.FC<{ questionType: ActionRegulatoryQuestions }> = ({ questionType }) => {
    return (
      <>
        {questionType === ActionRegulatoryQuestions.RISK_MATRIX ? (
          <>
            <HasTitle
              content={i18n.t('shared.impactQuestion', 'HOW SEVERE WOULD THE OUTCOMES BE IF THE EVENT OCCURRED?')}
              style={{ fontSize: '12px', marginTop: '8px' }}
            />
            <Form.Item
              name={[questionType, ActionRiskMatrix.IMPACT]}
              rules={[
                {
                  required: true,
                  message: (
                    <HasText
                      content={ErrorMessages.GENERIC_INPUT_REQUIRED}
                      type="danger"
                      style={{ fontSize: '12px' }}
                    />
                  ),
                },
              ]}
            >
              <HasRadioGroup {...getRadioGroupConfig(questionType)} groupOptions={impactRadioGroupOptions} />
            </Form.Item>
            <HasTitle
              content={i18n.t('shared.probabilityQuestion', 'WHAT IS THE PROBABILITY THAT THIS EVENT WILL HAPPEN?')}
              style={{ fontSize: '12px', marginTop: '8px' }}
            />
            <Form.Item
              name={[questionType, ActionRiskMatrix.PROBABILITY]}
              rules={[
                {
                  required: true,
                  message: (
                    <HasText
                      content={ErrorMessages.GENERIC_INPUT_REQUIRED}
                      type="danger"
                      style={{ fontSize: '12px' }}
                    />
                  ),
                },
              ]}
            >
              <HasRadioGroup {...getRadioGroupConfig(questionType)} groupOptions={probabilityRadioGroupOptions} />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            name={questionType}
            colon={false}
            rules={[
              {
                required: true,
                message: (
                  <HasText content={ErrorMessages.GENERIC_INPUT_REQUIRED} type="danger" style={{ fontSize: '12px' }} />
                ),
              },
            ]}
          >
            <HasRadioGroup {...getRadioGroupConfig(questionType)} groupOptions={getRadioGroupOptions(questionType)} />
          </Form.Item>
        )}
      </>
    );
  };

  const entryFormComponents: Record<string, JSX.Element | undefined> = {
    [ActionRegulatoryQuestions.INVESTIGATED_AND_ACTED_UPON]: (
      <Component questionType={ActionRegulatoryQuestions.INVESTIGATED_AND_ACTED_UPON} />
    ),
    [ActionRegulatoryQuestions.PREVENTIVE_ACTIONS_EFFICENT]: (
      <Component questionType={ActionRegulatoryQuestions.PREVENTIVE_ACTIONS_EFFICENT} />
    ),
    [ActionRegulatoryQuestions.ADDITIONAL_RISKS]: (
      <Component questionType={ActionRegulatoryQuestions.ADDITIONAL_RISKS} />
    ),
    [ActionRegulatoryQuestions.CLASSIFICATION]: <Component questionType={ActionRegulatoryQuestions.CLASSIFICATION} />,
    [ActionRegulatoryQuestions.RISK_MATRIX]: <Component questionType={ActionRegulatoryQuestions.RISK_MATRIX} />,
  };

  const displayEntry = (questionType: ActionRegulatoryQuestions) => {
    switch (questionType) {
      case ActionRegulatoryQuestions.INVESTIGATED_AND_ACTED_UPON:
        return actionCompliance?.investigatedAndActedUpon ? YesNoOptionLabel.YES(t) : YesNoOptionLabel.NO(t);
      case ActionRegulatoryQuestions.PREVENTIVE_ACTIONS_EFFICENT:
        return actionCompliance?.preventiveActionsEfficient ? YesNoOptionLabel.YES(t) : YesNoOptionLabel.NO(t);
      case ActionRegulatoryQuestions.ADDITIONAL_RISKS:
        return actionCompliance?.additionalRisks ? YesNoOptionLabel.YES(t) : YesNoOptionLabel.NO(t);
      case ActionRegulatoryQuestions.CLASSIFICATION:
        return actionCompliance?.classification && ActionClassificationLabel[actionCompliance?.classification](t);
      case ActionRegulatoryQuestions.RISK_MATRIX:
        return displayRiskMatrixEntry() || '-';
    }
  };

  const displayRiskMatrixEntry = () => {
    if (actionCompliance) {
      const impactValue = EventUtils.getRiskImpactIndex(actionCompliance?.impact);
      const probabilityValue = EventUtils.getRiskProbabilityIndex(actionCompliance?.probability);
      if (impactValue && probabilityValue) {
        const matrixValues = EventUtils.getRiskMatrixValues(impactValue, probabilityValue);
        const riskColor = matrixValues.riskColor;

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <HasText
              content={`${i18n.t('shared.impact', 'Impact')}: ${RiskImpactLabelWithTranslation[
                actionCompliance.impact!
              ](t)} (${impactValue})`}
            />
            <HasText
              content={`${i18n.t('shared.probability', 'Probability')}: ${RiskProbabilityLabelWithTranslation[
                actionCompliance.probability!
              ](t)} (${probabilityValue})`}
            />
            <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
              <HasText content={`${i18n.t('shared.riskScore', 'Risk score')}: `} />
              <Tooltip title={RiskLevelLabel?.[actionCompliance.riskLevel as RiskLevel]?.(t)}>
                <div style={{ color: riskColor, fontWeight: 600 }}>{actionCompliance?.riskScore}</div>
              </Tooltip>
            </div>
          </div>
        );
      }
    }
  };

  const getFormStepConfig = (): StepProps[] => {
    const icon = (
      <HasAvatar
        icon={<SolutionOutlined className="align-middle" />}
        size="default"
        style={{ backgroundColor: COLORS.NEW_ORANGE }}
      />
    );

    return Object.values(ActionRegulatoryQuestions).map((value) => {
      const title = (
        <HasTitle content={ActionRegulatoryQuestionsLabel[value](t)} level={4} style={{ fontSize: '12px' }} />
      );

      const description =
        mode !== Mode.VIEW ? (
          entryFormComponents[value]
        ) : (
          <HasParagraph content={null} style={{ color: COLORS.PRIMARY_BLUE, fontSize: '12px', fontWeight: 600 }}>
            {displayEntry(value)}
          </HasParagraph>
        );

      return {
        title: title,
        description: description,
        icon: icon,
      };
    });
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Steps type="default" current={4} size="default" direction="vertical">
          {getFormStepConfig().map((config, index) => (
            <Steps.Step key={index} {...config} />
          ))}
        </Steps>
        {isLoggedInUserCreatorOrSuperAdminOrOwner(action, currentUser) && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {actionCompliance && mode !== Mode.EDIT && (
              <HasButton
                type="primary"
                onClick={() => setMode(Mode.EDIT)}
                style={{ marginRight: '8px' }}
                disabled={action.status !== ActionStatus.CLOSED}
              >
                {i18n.t('shared.edit')}
              </HasButton>
            )}
            {actionCompliance && mode === Mode.EDIT && (
              <HasButton type="default" onClick={() => setMode(Mode.VIEW)} style={{ marginRight: '8px' }}>
                {i18n.t('shared.cancel')}
              </HasButton>
            )}
            {mode !== Mode.VIEW && (
              <HasButton
                type="primary"
                style={{ marginRight: '8px' }}
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                {i18n.t('shared.save')}
              </HasButton>
            )}
          </div>
        )}
      </Form>
    </>
  );
};

export { HasActionComplianceForm };
