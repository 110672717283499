import { SearchOutlined } from '@ant-design/icons';
import Input, { InputProps } from 'antd/lib/input';
import React from 'react';
import i18n from '../../../i18n/config';

interface OwnSearchProps extends Omit<InputProps, 'onSearch' | 'onChange'> {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class HasSearchInput extends React.Component<OwnSearchProps, {}> {
  render() {
    const { onSearch, prefix, placeholder, style, size, ...rest } = this.props;
    return (
      <Input
        {...rest}
        prefix={prefix ? prefix : <SearchOutlined />}
        placeholder={placeholder ? placeholder : i18n.t('shared.search')}
        onChange={onSearch}
        style={{ ...style, maxWidth: '180px', marginRight: '20px' }}
        size={size ? size : 'large'}
      />
    );
  }
}

export default HasSearchInput;
