import { AxiosResponse } from 'axios';
import { Form, FormLabel } from '../models';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { RestService, URL } from './rest.service';

class FormService implements RestService<Form> {
  get(params?: any): Promise<AxiosResponse<Form>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Form[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Form>> {
    throw new Error('Method not implemented.');
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Form>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Form>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Form): Promise<AxiosResponse<Form>> {
    return httpClient.post<Form>(URL.FORM, resource);
  }

  update(resource: Form): Promise<AxiosResponse<Form>> {
    return httpClient.put<Form>(URL.FORM, resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Form>> {
    return httpClient.delete<Form>(buildUrl(URL.FORM, [resourceId]));
  }

  getByFormType(formType: string): Promise<AxiosResponse<Form>> {
    return httpClient.get<Form>(buildUrl(URL.FORM, ['getFormByType']), { params: { formType } });
  }

  getFormLabels(eventId: number): Promise<AxiosResponse<FormLabel>> {
    return httpClient.get<FormLabel>(buildUrl(URL.FORM, ['eventlabels', eventId]));
  }

  getAllFormLabels(): Promise<AxiosResponse<FormLabel[]>> {
    return httpClient.get<FormLabel[]>(buildUrl(URL.FORM, ['eventlabels']));
  }
}

export default new FormService();
