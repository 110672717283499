import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import i18n from '../../../i18n/config';
import { Division } from '../../../models';
import { ChartService } from '../../../services';
import { Option } from '../../../shared';
import { HasDropdown, HasModal } from '../../atoms';

interface DivisionRemoveModalProps {
  division: Division;
  divisions: Division[];
  visible: boolean;
  onOk: (divisionToMoveIncidents: number) => void;
  onCancel: any;
}

interface DivisionRemoveModalState {}

class HasDivisionRemoveModal extends React.Component<DivisionRemoveModalProps, DivisionRemoveModalState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: DivisionRemoveModalProps) {
    super(props);
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.props.onOk(values.divisionToMoveIncidents);
    this.formRef.current?.resetFields();
  };

  render() {
    const { visible } = this.props;

    return (
      <HasModal
        title={i18n.t('settings.migrateIncidents')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: false, htmlType: 'submit', form: 'add-chart-form' }}
        cancelButtonProps={{ disabled: false }}
        destroyOnClose
      >
        <Form
          id="add-chart-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{ type: '' }}
        >
          <Form.Item name="divisionToMoveIncidents" label={i18n.t('settings.divisionHasIncidents')} colon={false}>
            <HasDropdown<Division>
              size="large"
              style={{ fontSize: '14px' }}
              options={this.props.divisions.map<Option<Division>>((division) => ({
                value: division.id,
                label: division.name,
                forObject: division,
              }))}
            />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasDivisionRemoveModal;
