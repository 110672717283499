import { Tag } from 'antd';
import React, { ReactNode } from 'react';
import { CheckableTagProps } from 'antd/lib/tag';

interface OwnCheckableTagProps extends CheckableTagProps {
  content: string | number | ReactNode;
}

class HasCheckableTag extends React.Component<OwnCheckableTagProps, {}> {
  render() {
    const onlyCheckableTagProps: Omit<OwnCheckableTagProps, 'content'> = { ...this.props };
    return <Tag.CheckableTag {...onlyCheckableTagProps}>{this.props.content}</Tag.CheckableTag>;
  }
}

export default HasCheckableTag;
