import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React from 'react';

interface OwnTextAreaProps extends TextAreaProps {}

class HasTextArea extends React.Component<OwnTextAreaProps, {}> {
  render() {
    const { TextArea } = Input;
    return <TextArea {...this.props} />;
  }
}

export default HasTextArea;
