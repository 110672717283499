import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import React, { ReactNode } from 'react';

interface OwnCheckboxProps extends CheckboxProps {
  content?: string | ReactNode;
}

class HasCheckbox extends React.Component<OwnCheckboxProps, {}> {
  render() {
    const checkboxPropsOnly: Omit<OwnCheckboxProps, 'content'> = { ...this.props };
    return <Checkbox {...checkboxPropsOnly}>{this.props.content}</Checkbox>;
  }
}

export default HasCheckbox;
