import { ConsoleSqlOutlined, DownOutlined, MoreOutlined, UpOutlined } from '@ant-design/icons';
import { Badge, Menu, Tag, Tooltip } from 'antd';
import Dropdown from 'antd/lib/dropdown';
import { ClickParam } from 'antd/lib/menu';
import { PaginationConfig } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table/Column';
import { ColumnFilterItem, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { compact, concat, isArray, isEmpty, isEqual, isNull, isUndefined } from 'lodash';
import React, { ReactText } from 'react';
import Highlighter from 'react-highlight-words';
import { Subscription } from 'rxjs';
import { Notification } from '../../../components';
import i18n from '../../../i18n/config';
import {
  Action,
  ActionForm,
  ActionFormKey,
  ActionImportance,
  ActionSourceType,
  ActionStatus,
  IncidentType,
  IncidentTypeLabel,
  Permission,
  RESPONSIBLE_USER_ID,
  RESPONSIBLE_USER_NAME,
  RiskLevel,
  RiskLevelLabel,
  User,
  UserRole,
} from '../../../models';
import {
  ActionEditService,
  ActionService,
  CompanyService,
  DivisionService,
  EntityAction,
  FiltersService,
  UserService,
} from '../../../services';
import {
  ActionImportanceLabel,
  ActionStatusLabel,
  ACTION_ACTIONS,
  DATE_LOCALE,
  ErrorMessages,
  MenuItemWithKey,
  SuccessMessages,
  TableFilters,
  ActionSourceLabel,
  RISK_LEVEL_COLOR,
  COLORS,
} from '../../../shared';
import {
  ACTION_KEYS,
  canWriteAction,
  catchClickEvent,
  formatTextToTitlecase,
  getActionStatusCssClass,
  getActionPermissions,
  getActionStatusColor,
  getActionStatusText,
  getDate,
  getHighlighterProps,
  getUserFullName,
  isUserRoleEqualsTo,
  TABLE_HEADER_HEIGHT,
  TABLE_ITEM_HEIGHT,
  TABLE_PAGE_SIZE,
  TABLE_PAGINATION_HEIGHT,
} from '../../../utils';
import { HasButton, HasModal } from '../../atoms';
import { HasAutoComplete, HasSearchInput } from '../../atoms/data-entry';
import { HasTable, RiskScoreTag } from '../../molecules';
import { HasActionForm } from './action.form';
import { PermissionsContext } from '../../../context';
import moment from 'moment';

interface ActionTableProps {
  navigationCallback: (action: Action) => void;
  userId: number;
}

interface ActionTableState {
  loading: boolean;
  data: Action[];
  total?: number;
  hideOthers: boolean;
  actionToEdit?: Action;
  formInterface: Readonly<ActionForm>;
  actionChanged?: boolean;
  showEdit: boolean;
  showReassign: boolean;
  usersRequested: {
    [companyId: number]: boolean;
  };
  usersCache: {
    [companyId: number]: User[];
  };
}

class HasActionsTable extends React.Component<ActionTableProps, ActionTableState> {
  state: ActionTableState = {
    loading: false,
    data: [] as Action[],
    hideOthers:
      isUserRoleEqualsTo(UserRole.EMPLOYEE) ||
      isUserRoleEqualsTo(UserRole.LIMITED_EMPLOYEE_DIVISION) ||
      isUserRoleEqualsTo(UserRole.LIMITED_EMPLOYEE_PERSONAL),
    showEdit: false,
    showReassign: false,
    usersRequested: {},
    usersCache: {},
    formInterface: {},
  };

  private incidentTypeFilters: ColumnFilterItem[] = [];
  private statusFilters: ColumnFilterItem[] = [];
  private importanceFilters: ColumnFilterItem[] = [];
  private userFilters: ColumnFilterItem[] = [];
  private sourceFilters: ColumnFilterItem[] = [];
  private complianceFormFilters: ColumnFilterItem[] = [];
  private filters: TableFilters;

  private subscriptions: Subscription[] = [];
  private dataLoadedOnCompanySub = false;

  constructor(props: Readonly<ActionTableProps>) {
    super(props);
    this.setTableFilters();
    this.filters = { ...this.getFiltersValues() };
  }

  setTableFilters = () => {
    this.incidentTypeFilters = Object.keys(IncidentType).map((type, index) => ({
      text: i18n.t(IncidentTypeLabel[type]),
      value: index,
    }));
    this.statusFilters = Object.keys(ActionStatus).map((status, index) => ({
      text: i18n.t(ActionStatusLabel[status]),
      value: index,
    }));
    this.importanceFilters = Object.keys(ActionImportance).map((importance, index) => ({
      text: i18n.t(ActionImportanceLabel[importance]),
      value: index,
    }));
    this.userFilters = [{ text: i18n.t('action.myActions'), value: this.props.userId }];
    this.sourceFilters = Object.keys(ActionSourceType).map((source, index) => ({
      text: i18n.t(ActionSourceLabel[source]),
      value: source,
    }));
    this.complianceFormFilters = [
      { text: i18n.t('shared.yes'), value: 1 },
      { text: i18n.t('shared.no'), value: 0 },
    ];
  };

  componentDidMount() {
    const { setPermissionsForUser } = this.context;
    setPermissionsForUser();
    this.subscriptions.push(
      CompanyService.getCompanyListener().subscribe((_) => {
        this.filters.pageNumber = 1;
        this.filters.pageSize = TABLE_PAGE_SIZE;
        FiltersService.saveActionTableFilters(this.filters);
        this.getActionList();
        this.dataLoadedOnCompanySub = true;
      }),
      DivisionService.getDivisionListener().subscribe((_) => {
        this.dataLoadedOnCompanySub ? (this.dataLoadedOnCompanySub = false) : this.getActionList();
      }),
      ActionService.getActionChangedListener().subscribe(async (event) => {
        switch (event.event) {
          case EntityAction.CREATE:
            try {
              await ActionService.create(event.entity);
              Notification.success(SuccessMessages.ACTION_CREATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.UPDATE:
            try {
              await ActionService.update(event.entity);
              Notification.success(SuccessMessages.ACTION_UPDATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.CHANGE_STATUS:
            try {
              await ActionService.changeStatus(event.entity);
              Notification.success(SuccessMessages.ACTION_UPDATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.ASSIGN:
            try {
              await ActionService.assign(event.entity);
              Notification.success(SuccessMessages.ACTION_UPDATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.DELETE:
            try {
              await ActionService.delete(event.entity.id!);
              Notification.success(SuccessMessages.ACTION_DELETE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
        }
        this.getActionList();
      })
    );
    i18n.on('languageChanged', () => this.setTableFilters());
  }

  getActionList = () => {
    this.setState({ loading: true });
    const filters: TableFilters = this.overrideFiltersIfNeeded();
    ActionService.getAllPaginatedAndFiltered(filters)
      .then((data) => {
        this.setState({ data: data.data.content, total: data.data.totalElements, loading: false });
      })
      .catch((error) => Notification.error({ message: error.response.data.message }));
  };

  overrideFiltersIfNeeded = (): TableFilters => {
    if (this.state.hideOthers) {
      const filters = this.filters;
      filters.columns[ACTION_KEYS.RESPONSIBLE_USER] = {
        defaultSortOrder: null,
        defaultFilteredValue: [this.props.userId.toString()],
      };
      return filters;
    }
    return this.filters;
  };

  getFiltersValues = (): TableFilters => {
    const filters = FiltersService.getActionTableFilters();
    if (!isNull(filters)) {
      if (filters.columns[ACTION_KEYS.RESPONSIBLE_USER]?.defaultFilteredValue?.length) {
        filters.columns[ACTION_KEYS.RESPONSIBLE_USER].defaultFilteredValue = [this.props.userId.toString()];
        FiltersService.saveActionTableFilters(filters);
      }
      return filters;
    } else {
      const defaultFiltersState = { columns: {} };
      FiltersService.saveActionTableFilters(defaultFiltersState);
      return defaultFiltersState;
    }
  };

  getFilterAndSorterStatus = (
    field: ACTION_KEYS,
    otherFilterValues?: ReactText[]
  ): Partial<Pick<ColumnProps<Action>, 'defaultSortOrder' | 'defaultFilteredValue'>> => {
    const columnFilters = this.filters.columns[field];
    if (!isUndefined(columnFilters)) {
      const { defaultSortOrder, defaultFilteredValue } = columnFilters;
      const filterValues = compact(concat(defaultFilteredValue, otherFilterValues));
      return {
        defaultSortOrder,
        defaultFilteredValue: !isEmpty(filterValues) ? filterValues : undefined,
      };
    } else {
      return otherFilterValues ? { defaultFilteredValue: otherFilterValues } : {};
    }
  };

  tableSorterValueChanged = (sorter: SorterResult<Action>) => {
    // This function is designed only for single column sorting!
    if (!isUndefined(sorter.columnKey)) {
      const { columnKey, order } = sorter;
      let columnData = this.filters.columns[columnKey];
      columnData = { ...columnData, defaultSortOrder: order };
      this.filters = {
        ...this.filters,
        columns: { ...this.filters.columns, [columnKey]: columnData },
      };

      const columnKeys = Object.keys(this.filters.columns).filter((key) => !isEqual(key, columnKey));
      columnKeys.forEach((key) => {
        let columnData = this.filters.columns[key];
        columnData = { ...columnData, defaultSortOrder: undefined };
        this.filters = {
          ...this.filters,
          columns: { ...this.filters.columns, [key]: columnData },
        };
      });
    }
  };

  tableFilterValueChanged = (filters: Record<string, ReactText[] | null>) => {
    const entries = Object.entries(filters);
    entries.forEach((entry) => {
      let columnData = this.filters.columns[entry[0]];
      columnData = { ...columnData, defaultFilteredValue: entry[1] };
      this.filters = {
        ...this.filters,
        columns: { ...this.filters.columns, [entry[0]]: columnData },
      };
    });
  };

  onTableChange = (
    pagination: PaginationConfig,
    filters: Record<string, ReactText[] | null>,
    sorter: SorterResult<Action> | SorterResult<Action>[],
    extra: TableCurrentDataSource<Action>
  ) => {
    this.filters.pageNumber = pagination.current;
    this.filters.pageSize = pagination.pageSize;
    if (!isArray(sorter)) {
      this.tableSorterValueChanged(sorter);
    }
    this.tableFilterValueChanged(filters);
    FiltersService.saveActionTableFilters(this.filters);
    this.getActionList();
  };

  changeStatusAction = (action: Action) => {
    action.status = action.status === ActionStatus.CLOSED ? ActionStatus.OPEN : ActionStatus.CLOSED;
    ActionService.actionChanged(EntityAction.CHANGE_STATUS, action);
  };

  deleteAction = (action: Action) => {
    ActionService.actionChanged(EntityAction.DELETE, action);
  };

  getUsersForCompanyIfNeeded(companyId: number) {
    const { usersCache, usersRequested } = this.state;
    if (usersCache[companyId] || usersRequested[companyId]) return;
    usersRequested[companyId] = true;
    this.setState({ usersRequested });
    UserService.getAllUsersSmallForCompany(companyId, NaN)
      .then(({ data: users }) => {
        usersCache[companyId] = users;
        this.setState({ usersCache });
      })
      .catch((error) => Notification.error({ message: error.response.data.message }));
  }

  startReassign = (action: Action) => {
    this.getUsersForCompanyIfNeeded(action.companyId);
    const formInterface: Readonly<ActionForm> = ActionEditService.getFormInterface(action);
    this.setState({ actionToEdit: action, showReassign: true, formInterface });
  };

  cancelReassign = () => {
    this.setState({ actionToEdit: undefined, showReassign: false });
  };

  reassignAction = (action: Action, userId: number, userName: string) => {
    const { formInterface } = this.state;
    ActionEditService.fieldChanged(formInterface, RESPONSIBLE_USER_ID, userId.toString());
    ActionEditService.fieldChanged(formInterface, RESPONSIBLE_USER_NAME, userName);
    action = ActionEditService.applyInterfaceAndReturn(formInterface, action);

    ActionService.actionChanged(EntityAction.ASSIGN, action);
    this.setState({ actionToEdit: undefined, showReassign: false });
  };

  startEdit = (action: Action) => {
    this.getUsersForCompanyIfNeeded(action.companyId);
    const formInterface: Readonly<ActionForm> = ActionEditService.getFormInterface(action);
    this.setState({
      actionToEdit: action,
      showEdit: true,
      actionChanged: true,
      formInterface,
    });
  };

  finishEdit = () => {
    const { actionChanged, actionToEdit, formInterface } = this.state;
    if (actionToEdit && actionChanged) {
      const updatedAction: Action = ActionEditService.applyInterfaceAndReturn(formInterface, actionToEdit);
      ActionService.actionChanged(EntityAction.UPDATE, updatedAction);
    }
    this.cancelEdit();
  };

  cancelEdit = () => {
    this.setState({
      actionToEdit: undefined,
      showEdit: false,
      actionChanged: false,
    });
  };

  updateActionField = (field: ActionFormKey, value: string) => {
    const { actionToEdit, formInterface } = this.state;
    if (actionToEdit) {
      ActionEditService.fieldChanged(formInterface, field, value);
      this.setState({ actionChanged: true, formInterface });
    }
  };

  getArrowBasedOnImportance = (importance: ActionImportance | string) => {
    if (importance == ActionImportance.LOW) {
      return <DownOutlined style={{ stroke: COLORS.GREEN, strokeWidth: '70' }} />;
    }
    if (importance == ActionImportance.MEDIUM) {
      return <UpOutlined style={{ stroke: COLORS.YELLOW, strokeWidth: '70' }} />;
    }
    if (importance == ActionImportance.HIGH) {
      return <UpOutlined style={{ stroke: COLORS.LIGHT_RED, strokeWidth: '70' }} />;
    }
  };

  concatCompanyAndDivisionTitle = () => {
    return `${i18n.t('shared.company').concat(` & ${i18n.t('shared.division')}`)}`;
  };

  isDateBefore = (record: Action) => {
    if (record.dueDate) {
      return moment(record.dueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    }
    return false;
  };

  getActionStatus = (record: Action) => {
    return (
      <Tooltip title={getActionStatusText(record)} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className={getActionStatusCssClass(record)} />
        </div>
      </Tooltip>
    );
  };

  getComplianceFormStatus = (completed: boolean) => {
    return (
      <div className="d-flex flex-column align-items-center">
        <Tooltip
          title={
            completed
              ? i18n.t('action.regulatoryQuestions.completed', 'Regulatory questions completed')
              : i18n.t('action.regulatoryQuestions.notCompleted', 'Regulatory questions not completed')
          }
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {completed ? <div className="green-dot" /> : <div className="red-dot" />}
          </div>
        </Tooltip>
      </div>
    );
  };

  getColumns = (permissions: Permission[]): ColumnProps<Action>[] => [
    {
      title: formatTextToTitlecase(i18n.t('action.id')),
      dataIndex: ACTION_KEYS.ID,
      key: ACTION_KEYS.ID,
      render: (_, { id }) => <Highlighter {...getHighlighterProps('#' + id, this.filters.searchTerm)} />,
      sorter: true,
      width: 60,
      ...this.filters.columns[ACTION_KEYS.ID],
    },
    {
      dataIndex: 'status',
      key: ACTION_KEYS.STATUS,
      render: (status: ActionStatus | string, record) =>
        status !== null ? (
          <div className="d-flex flex-column align-items-center">{this.getActionStatus(record)}</div>
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
      ellipsis: true,
      width: 20,
      filters: this.statusFilters,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.STATUS),
    },
    {
      dataIndex: 'complianceFormStatus',
      key: ACTION_KEYS.COMPLIANCE_FORM_STATUS,
      render: (_: null, record) =>
        !isUndefined(record.complianceFormCompleted)
          ? this.getComplianceFormStatus(record.complianceFormCompleted)
          : i18n.t('dataDisplay.unknownValue'),
      ellipsis: true,
      width: 20,
      filters: this.complianceFormFilters,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.COMPLIANCE_FORM_STATUS),
    },
    {
      title: formatTextToTitlecase(i18n.t('action.source', 'Source')),
      dataIndex: ACTION_KEYS.SOURCE_TYPE,
      key: ACTION_KEYS.SOURCE_TYPE,
      render: (_, { sourceType }) => (
        <Tooltip
          title={formatTextToTitlecase(sourceType)}
          placement={'topLeft'}
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
        >
          <div>{formatTextToTitlecase(sourceType)}</div>
        </Tooltip>
      ),
      sorter: true,
      width: 100,
      filters: this.sourceFilters,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.SOURCE_TYPE),
    },
    {
      title: formatTextToTitlecase(i18n.t('shared.origin', 'Origin')),
      dataIndex: ['incidentId', 'incidentType'],
      key: ACTION_KEYS.INCIDENT,
      render: (_: null, record: Action) => {
        return (
          <>
            {record.riskAssessmentResult ? (
              <React.Fragment>
                {i18n.t('action.riskAssessmentResult', 'Risk assessment result')}
                <Highlighter {...getHighlighterProps(` #${record.riskAssessmentResult.id}`, this.filters.searchTerm)} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {i18n.t(IncidentTypeLabel[record.incidentType!])}
                <Highlighter {...getHighlighterProps(` #${record.incidentId}`, this.filters.searchTerm)} />
              </React.Fragment>
            )}
          </>
        );
      },
      filters: this.incidentTypeFilters,
      sorter: true,
      width: 200,
      ellipsis: true,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.INCIDENT),
    },
    {
      title: i18n.t('shared.createdOn'),
      dataIndex: 'createdOn',
      key: ACTION_KEYS.CREATED_ON,
      render: (date: string) => (
        <Tooltip title={getDate(date)} placement={'topLeft'} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>{getDate(date)}</div>
        </Tooltip>
      ),
      ellipsis: true,
      sorter: true,
      width: 100,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.CREATED_ON),
    },
    {
      title: i18n.t('shared.createdBy'),
      dataIndex: 'createdByUserName',
      key: ACTION_KEYS.CREATED_BY,
      render: (text: any) => (
        <Tooltip title={text} placement={'topLeft'} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>
            <Highlighter {...getHighlighterProps(text, this.filters.searchTerm)} />
          </div>
        </Tooltip>
      ),
      ellipsis: true,
      sorter: true,
      width: 180,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.CREATED_BY),
    },
    {
      title: i18n.t('shared.dueDate'),
      dataIndex: 'dueDate',
      key: ACTION_KEYS.DUE_DATE,
      render: (date: string) => (
        <Tooltip title={getDate(date)} placement={'topLeft'} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>{getDate(date)}</div>
        </Tooltip>
      ),
      ellipsis: true,
      sorter: true,
      width: 100,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.DUE_DATE),
    },
    {
      title: this.concatCompanyAndDivisionTitle(),
      dataIndex: 'companyAndDivision',
      key: ACTION_KEYS.COMPANY,
      render: (_, record) => {
        return (
          <Tooltip
            title={
              <div>
                {record.company}
                <br />
                {record.division}
              </div>
            }
            placement={'topLeft'}
            overlayStyle={{ fontSize: 12, fontWeight: 500 }}
          >
            <div>
              <div>{record.company}</div>
              <div>{record.division ? record.division : '-'}</div>
            </div>
          </Tooltip>
        );
      },
      sorter: true,
      ellipsis: true,
      width: 180,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.COMPANY),
    },
    {
      title: i18n.t('shared.requiredAction'),
      dataIndex: 'requiredAction',
      key: ACTION_KEYS.REQUIRED_ACTION,
      render: (requiredAction: string) => (
        <Tooltip title={requiredAction} placement={'topLeft'} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>{requiredAction}</div>
        </Tooltip>
      ),
      sorter: true,
      ellipsis: true,
      width: 300,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.REQUIRED_ACTION),
    },
    {
      title: i18n.t('shared.responsibleUser'),
      dataIndex: 'responsibleUserName',
      key: ACTION_KEYS.RESPONSIBLE_USER,
      render: this.getResponsibleUserElement,
      sorter: !this.state.hideOthers,
      width: 300,
      ...(this.state.hideOthers ? {} : { filters: this.userFilters }),
      ...this.getFilterAndSorterStatus(ACTION_KEYS.RESPONSIBLE_USER),
    },
    {
      title: i18n.t('shared.riskScore', 'Risk score'),
      dataIndex: 'riskScore',
      key: ACTION_KEYS.RISK_SCORE,
      render: (_: any, { riskScore, riskLevel }: Action) => (
        <RiskScoreTag riskScore={riskScore} riskLevel={riskLevel} />
      ),
      width: '60px',
      ...this.filters.columns['riskScore'],
    },
    {
      dataIndex: 'importance',
      key: ACTION_KEYS.IMPORTANCE,
      render: (importance: ActionImportance | string) => {
        if (importance === null || importance === undefined) {
          return i18n.t('dataDisplay.unknownValue');
        } else {
          return (
            <React.Fragment>
              <Tooltip
                title={i18n.t(ActionImportanceLabel[importance])}
                overlayStyle={{ fontSize: 12, fontWeight: 500 }}
              >
                <div>{this.getArrowBasedOnImportance(importance)}</div>
              </Tooltip>
            </React.Fragment>
          );
        }
      },
      width: '30px',
      align: 'center',
      ellipsis: true,
      filters: this.importanceFilters,
      ...this.getFilterAndSorterStatus(ACTION_KEYS.IMPORTANCE),
    },
    {
      render: (_: null, record: Action) => {
        return canWriteAction(permissions) ? (
          <Dropdown overlay={this.getActionsMenu(record)}>
            <MoreOutlined onClick={catchClickEvent} />
          </Dropdown>
        ) : null;
      },
      width: '40px',
      align: 'center',
    },
  ];

  getResponsibleUserElement = (text: any, action: Action) => {
    const { actionToEdit, showReassign, usersCache } = this.state;
    if (actionToEdit?.id === action.id && showReassign) {
      return (
        <HasAutoComplete
          options={usersCache[action.companyId]?.map((user) => ({
            label: getUserFullName(user),
            value: user.id,
          }))}
          onClick={catchClickEvent}
          defaultValue={action.responsibleUserName}
          autoFocus={true}
          filterOption={true}
          defaultOpen={true}
          onBlur={this.cancelReassign}
          onDropdownVisibleChange={this.cancelReassign}
          width={150}
          onSelect={(_: string, option: any) => this.reassignAction(action, option.value, option.label)}
        />
      );
    } else {
      return <Highlighter {...getHighlighterProps(text, this.filters.searchTerm)} />;
    }
  };

  getActionsMenu = (action: Action) => {
    const dropdownMenuConfig: MenuItemWithKey[] = [];
    const { permissions: userPermissions } = this.context;
    const permissions = getActionPermissions(action, userPermissions);
    if (action.status !== ActionStatus.CLOSED) {
      if (permissions.edit) {
        dropdownMenuConfig.push({
          key: ACTION_ACTIONS.EDIT_ACTION,
          title: i18n.t('shared.edit', 'Edit'),
        });
      }
      if (permissions.reassign) {
        dropdownMenuConfig.push({
          key: ACTION_ACTIONS.REASSIGN_ACTION,
          title: i18n.t('action.reassign', 'Reassign'),
        });
      }
    }

    if (permissions.changeStatus) {
      dropdownMenuConfig.push({
        key: ACTION_ACTIONS.CHANGE_STATUS_ACTION,
        title:
          action.status === ActionStatus.CLOSED ? i18n.t('action.reopen', 'Reopen') : i18n.t('action.close', 'Close'),
      });
    }
    if (permissions.delete) {
      dropdownMenuConfig.push({
        key: ACTION_ACTIONS.DELETE_ACTION,
        title: i18n.t('shared.delete', 'Delete'),
      });
    }

    return (
      <Menu onClick={(clickParam) => this.actionSelected(clickParam, action)}>
        {dropdownMenuConfig.length > 0 ? (
          dropdownMenuConfig.map((config) => (
            <Menu.Item key={config.key} disabled={config.disabled} onClick={(param) => catchClickEvent(param.domEvent)}>
              {config.title}
            </Menu.Item>
          ))
        ) : (
          <div className="p-2">{i18n.t('shared.emptyOptionsMenu', 'Options not available')}</div>
        )}
      </Menu>
    );
  };

  actionSelected = (clickParam: ClickParam, action: Action) => {
    switch (clickParam.key) {
      case ACTION_ACTIONS.CHANGE_STATUS_ACTION:
        this.changeStatusAction(action);
        break;
      case ACTION_ACTIONS.EDIT_ACTION:
        this.startEdit(action);
        break;
      case ACTION_ACTIONS.REASSIGN_ACTION:
        this.startReassign(action);
        break;
      case ACTION_ACTIONS.DELETE_ACTION:
        this.deleteAction(action);
        break;
    }
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    if (searchTerm && searchTerm !== '') {
      this.filters.searchTerm = searchTerm;
    } else {
      this.filters.searchTerm = undefined;
    }
    FiltersService.saveActionTableFilters(this.filters);
    this.getActionList();
  };

  rowInteraction = (record: Action) => ({
    onClick: (_: React.MouseEvent) => this.props.navigationCallback(record),
  });

  directExportCSV = () => {
    ActionService.getDirectCsv(this.filters).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${formatTextToTitlecase(i18n.t('action.action_plural'))}-${new Date().toLocaleDateString(DATE_LOCALE)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      },
      (error) => {
        Notification.error(ErrorMessages.CSV_FAIL(error.response?.data.message));
      }
    );
  };

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    i18n.off('languageChanged', () => this.setTableFilters());
  }

  render() {
    const { loading, data: filteredData, total, actionToEdit, showEdit, usersCache } = this.state;
    const { permissions } = this.context;
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-between mb-3">
          <div>
            <HasSearchInput onSearch={(e) => this.handleSearch(e)} value={this.filters.searchTerm} />
          </div>
          <div className="d-flex">
            <HasButton
              type="link"
              onClick={this.directExportCSV}
              disabled={loading}
              className="d-flex align-items-center"
            >
              {i18n.t('incidents.directExportCSV')}
            </HasButton>
          </div>
        </div>
        <HasModal footer={null} visible={actionToEdit && showEdit} onCancel={this.cancelEdit} closable={false} centered>
          {actionToEdit && (
            <div style={{ padding: '30px' }}>
              <HasActionForm
                action={actionToEdit}
                users={usersCache[actionToEdit.companyId]}
                isCreate={false}
                editMode={true}
                fieldChangedCallback={this.updateActionField}
              ></HasActionForm>
              <div style={{ alignSelf: 'flex-end' }}>
                <HasButton type="default" onClick={this.cancelEdit}>
                  {i18n.t('shared.cancel')}
                </HasButton>
                <HasButton type="primary" onClick={this.finishEdit} style={{ float: 'right' }}>
                  {i18n.t('shared.save')}
                </HasButton>
              </div>
            </div>
          )}
        </HasModal>
        <HasTable<Action>
          rowKey="id"
          loading={loading}
          onRow={(record: Action) => this.rowInteraction(record)}
          onChange={this.onTableChange}
          onPageSizeChange={(size: number) => (this.filters.pageSize = size)}
          columns={this.getColumns(permissions)}
          data={filteredData}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          tablePaginationHeight={TABLE_PAGINATION_HEIGHT}
          defaultPageNumber={this.filters.pageNumber}
          total={total}
          isApiPaginated
          size={'small'}
        />
      </React.Fragment>
    );
  }
}

HasActionsTable.contextType = PermissionsContext;

export default HasActionsTable;
