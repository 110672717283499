import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import i18n from '../../../i18n/config';
import { ChartInstanceMinimized } from '../../../models';
import { ChartService } from '../../../services';
import { Option } from '../../../shared';
import { HasDropdown, HasModal } from '../../atoms';

interface AddChartModalProps {
  visible: boolean;
  onOk: (instanceId: number) => void;
  onCancel: any;
}

interface AddChartModalState {
  chartInstances: ChartInstanceMinimized[];
}

class HasAddChartModal extends React.Component<AddChartModalProps, AddChartModalState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: AddChartModalProps) {
    super(props);
    this.state = { chartInstances: [] };
  }

  componentDidMount() {
    this.getAllChartInstance();
  }

  getAllChartInstance = async () => {
    const { data: chartInstances } = await ChartService.getChartInstaces();
    this.setState((previousState) => {
      return { ...previousState, chartInstances };
    });
  };

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.props.onOk(values.instanceId);
    this.formRef.current?.resetFields();
  };

  render() {
    const { visible } = this.props;

    return (
      <HasModal
        title={i18n.t('charts.panelAction.add')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: false, htmlType: 'submit', form: 'add-chart-form' }}
        cancelButtonProps={{ disabled: false }}
        destroyOnClose
      >
        <Form
          id="add-chart-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{ type: '' }}
        >
          <Form.Item name="instanceId" label={i18n.t('charts.instance')} colon={false}>
            <HasDropdown<ChartInstanceMinimized>
              size="large"
              style={{ fontSize: '14px' }}
              options={this.state.chartInstances.map<Option<ChartInstanceMinimized>>((instance) => ({
                value: instance.id ? instance.id : -1,
                label: instance.title ? instance.title : '',
                forObject: instance,
              }))}
            />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasAddChartModal;
