import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';

interface OwnTagProps extends TagProps {}

class HasTag extends React.Component<OwnTagProps, {}> {
  render() {
    return <Tag {...this.props}>{this.props.children}</Tag>;
  }
}

export default HasTag;
