import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import { readString } from 'react-papaparse';
import { HasButton, HasText, Toast } from '../../components';
import i18n from '../../i18n/config';
import { CSV_EXTENSION, ErrorMessages } from '../../shared';
import { isObjectEmpty } from '../../utils';

interface EventUploadProps {
  afterLoad: (events: Array<Object>) => void;
}

const HasEventUpload = ({ afterLoad }: EventUploadProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const parseCsv = (csvString: string) => {
    setLoading(false);

    const csvResult = readString(csvString, { header: true });
    if (!csvResult || !csvResult?.data) {
      Toast.error(ErrorMessages.CSV_PARSE);
    } else if (csvResult.data.length > 1) {
      csvResult.data.splice(0, 1);
      const csvData = csvResult.data.filter((dataPiece) => !isObjectEmpty(dataPiece));
      csvData.length > 0 ? afterLoad(csvData as Object[]) : Toast.error(ErrorMessages.CSV_EMPTY);
    } else {
      Toast.error(ErrorMessages.CSV_EMPTY);
    }
  };

  const handleBeforeUpload = (file: RcFile): boolean => {
    setLoading(true);

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (e.target && e.target.result) {
        parseCsv(e.target.result as string);
      } else {
        Toast.error(ErrorMessages.CSV_PARSE);
      }
    };
    fileReader.readAsText(file);

    return false;
  };

  return (
    <>
      <HasText
        type="secondary"
        content={i18n.t('historicalEvents.uploadInstruction')}
        className="mb-4 text-center"
        style={{ fontSize: '18px' }}
      />
      <Upload beforeUpload={handleBeforeUpload} accept={CSV_EXTENSION} showUploadList={false} multiple={false}>
        <HasButton type="primary" loading={loading} className="mb-2">
          {i18n.t('shared.chooseFile')}
        </HasButton>
      </Upload>
    </>
  );
};

export { HasEventUpload };
