import React from 'react';
import { HasText } from '../..';
import i18n from '../../../i18n/config';
import { Incident, UserRole } from '../../../models';
import { DescriptionProps, FORM_RESULT_KEYS } from '../../../shared';
import { EventUtils, getFormResultValue, hidePhoneNumber, isEqualIgnoreCase, isUserRoleEqualsTo } from '../../../utils';
import { HasDescription } from '../../molecules';

interface InvolvedPartyProps {
  incident: Incident;
}

const {
  REPORTING_PERSON_IS_VICTIM,
  VICTIM_CONTACT_FIRST_NAME,
  VICTIM_CONTACT_LAST_NAME,
  VICTIM_CONTACT_EMAIL,
  VICTIM_CONTACT_PHONE,
} = FORM_RESULT_KEYS;

class HasInvolvedPartyDescription extends React.Component<InvolvedPartyProps, {}> {
  private getInvolvedPartyConfig = (incident: Incident): DescriptionProps => {
    const { maxItem: latestReportingIsVictim } = EventUtils.getLatestFormResultByKey(
      incident.formResult,
      REPORTING_PERSON_IS_VICTIM
    );
    let firstName, lastName, email, phone;
    let hiddenPhoneNumber;

    if (isEqualIgnoreCase(latestReportingIsVictim.value, 'true')) {
      firstName = incident.reportingUser.name;
      lastName = incident.reportingUser.surname;
      email = incident.reportingUser.email;
      phone = incident.reportingUser.phone;
    } else {
      firstName = EventUtils.getLatestFormResultByKey(incident.formResult, VICTIM_CONTACT_FIRST_NAME).maxItem.value;
      lastName = EventUtils.getLatestFormResultByKey(incident.formResult, VICTIM_CONTACT_LAST_NAME).maxItem.value;
      // email and phone not ever used in this case
      email = getFormResultValue(incident.formResult, VICTIM_CONTACT_EMAIL);
      phone = getFormResultValue(incident.formResult, VICTIM_CONTACT_PHONE);
    }
    if (phone) {
      hiddenPhoneNumber = hidePhoneNumber(phone);
    }
    return {
      title: i18n.t('incidents.primaryParty'),
      className: 'd-flex flex-column flex-grow-1',
      descriptionItems: [
        {
          label: i18n.t('shared.firstName'),
          content: <HasText content={firstName} strong />,
        },
        {
          label: i18n.t('shared.lastName'),
          content: <HasText content={lastName} strong />,
        },
        {
          label: i18n.t('shared.email'),
          content: <HasText content={email} strong />,
        },
        {
          label: i18n.t('shared.phoneNumber'),
          content: (
            <HasText
              content={
                isUserRoleEqualsTo(UserRole.OWNER) || isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
                  ? phone
                  : hiddenPhoneNumber
              }
              strong
            />
          ),
        },
      ],
    };
  };

  render() {
    return <HasDescription {...this.getInvolvedPartyConfig(this.props.incident)} />;
  }
}

export default HasInvolvedPartyDescription;
