import React from 'react';
import { ModalBaseProps } from '../../../shared';
import { HasModal, HasParagraph, HasText } from '../../atoms';
import { AiFillWarning } from 'react-icons/ai';
import i18n from '../../../i18n/config';
import { User } from '../../../models';

interface AnonymizeModalProps extends ModalBaseProps<User> {
  user: User;
}

interface AnonymizeModalState {
  updateInProgress: boolean;
}

class AnonymizeModal extends React.Component<AnonymizeModalProps, AnonymizeModalState> {
  state = { updateInProgress: false };

  render() {
    const { visible } = this.props;
    return (
      <HasModal
        title={
          <div className="d-flex flex-row justify-content-center align-items-center">
            <AiFillWarning size={24} color={'#ff4d4f'} style={{ marginRight: 8 }} />
            <HasText content={i18n.t('settings.removeAndAnonymize.title', 'Remove and anonymize?')} />
          </div>
        }
        okText={i18n.t('settings.removeAndAnonymize.button', 'Yes, remove and anonymize')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.props.onCancel}
        closable={false}
        visible={visible}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
        okButtonProps={{
          danger: true,
          loading: this.state.updateInProgress,
          htmlType: 'button',
          onClick: () => this.props.onOk(this.props.user),
        }}
      >
        <HasParagraph
          style={{ padding: 24 }}
          content={i18n.t(
            'settings.removeAndAnonymize.description',
            "Anonymization is an irreversible process, and the user's data will be permanently lost. Are you sure you want to continue?"
          )}
        />
      </HasModal>
    );
  }
}

export default AnonymizeModal;
