import { AxiosResponse } from 'axios';
import { buildUrl } from '../../utils';
import { httpClient } from '../http.client';
import { URL } from '../rest.service';
import { AttachmentService } from '.';
import { Attachment } from '../../models';

class ActionAttachmentService extends AttachmentService {
  protected entityURL: string = URL.ACTION_ATTACHMENT;
  protected uploadSubURL: string = 'upload';
  protected attachmentURL: string = URL.ACTION_ATTACHMENT;

  getAllForEvent(eventId: number): Promise<AxiosResponse<Attachment[]>> {
    return httpClient.get<Attachment[]>(buildUrl(URL.ACTION_ATTACHMENT, ['allForEvent', eventId]));
  }
}

export default new ActionAttachmentService();
