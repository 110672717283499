import { RightOutlined, SwapOutlined, SyncOutlined } from '@ant-design/icons';
import { Col, Result, Row } from 'antd';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HasButton, HasDropdown, HasSpinner, HasStructureDiff, HasText, Notification } from '../../components';
import i18n from '../../i18n/config';
import { Incident, IncidentType, IncidentTypeLabel, NewIncident } from '../../models';
import { IncidentService } from '../../services';
import { COLORS, ErrorMessages, Option, TYPE_SWAP_GROUPS } from '../../shared';

interface EventTypeSwapProps {
  event: Incident;
}

const HasEventTypeSwap: React.FC<EventTypeSwapProps> = ({ event }) => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [swapInProgress, setSwapInProgress] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<IncidentType>();
  const [hasMultipleOptions, setHasMultipleOptions] = useState<boolean>(true);
  const [swappedEventId, setSwappedEventId] = useState<number>();

  useEffect(() => {
    const typeGroup: IncidentType[] = TYPE_SWAP_GROUPS.filter((typeGroup) => typeGroup.includes(event.type))[0];
    if (typeGroup.length === 2) {
      setSelectedType(typeGroup.filter((type) => type !== event.type)[0]);
      setHasMultipleOptions(false);
    } else {
      setHasMultipleOptions(true);
    }
    setPageLoaded(true);
  }, [event.type]);

  const getAvailableTypes = (): Option<IncidentType>[] => {
    const typeGroup: IncidentType[] = TYPE_SWAP_GROUPS.filter((typeGroup) => typeGroup.includes(event.type))[0];
    return typeGroup
      .filter((type) => type !== event.type)
      .map((type) => ({ label: i18n.t(IncidentTypeLabel[type]).toString(), value: type, forObject: type }));
  };

  const getTypeSelector = (): JSX.Element => {
    if (selectedType && !hasMultipleOptions) {
      return <HasText content={i18n.t(IncidentTypeLabel[selectedType])} strong />;
    } else {
      const selectedTypeChanged = (type: IncidentType) => {
        setSelectedType(type);
        setPageLoaded(true);
      };
      return (
        <HasDropdown<IncidentType>
          placeholder={i18n.t('typeSwap.selectType')}
          options={getAvailableTypes()}
          style={{ width: '80%' }}
          onChange={(_, option) => selectedTypeChanged(option.forObject)}
        />
      );
    }
  };

  const saveSwappedEvent = (newEvent?: NewIncident) => {
    if (!isUndefined(newEvent) && !isUndefined(selectedType)) {
      setSwapInProgress(true);
      IncidentService.swapEventType(event.id, selectedType, newEvent)
        .then(
          ({ data: newEventId }) => {
            setSelectedType(undefined);
            setSwappedEventId(newEventId);
          },
          (error) => Notification.error(ErrorMessages.EVENT_SWAP(error.response?.data?.message))
        )
        .catch((error) => Notification.error(ErrorMessages.EVENT_SWAP(error.response?.data?.message)))
        .finally(() => setSwapInProgress(false));
    }
  };

  return (
    <>
      <div className={'ant-descriptions-title'}>{i18n.t('typeSwap.title').toUpperCase()}</div>
      {!pageLoaded ? (
        <HasSpinner size="large" />
      ) : (
        <>
          {isUndefined(swappedEventId) && (
            <Row justify="center" className="mb-3">
              <Col span={8} className="text-center">
                <HasText content={i18n.t(IncidentTypeLabel[event.type])} strong />
              </Col>
              <Col span={8} className="text-center">
                {swapInProgress ? (
                  <SyncOutlined spin className="align-middle" style={{ fontSize: 18 }} />
                ) : (
                  <RightOutlined className="align-middle" style={{ fontSize: 18 }} />
                )}
              </Col>
              <Col span={8} className="text-center">
                {getTypeSelector()}
              </Col>
            </Row>
          )}
          {selectedType ? (
            <HasStructureDiff event={event} fromType={event.type} toType={selectedType} startSwap={saveSwappedEvent} />
          ) : (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
              {isUndefined(swappedEventId) ? (
                <>
                  <SwapOutlined style={{ fontSize: 64, color: COLORS.GRAY_ACCENT_LIGHT }} className="mb-4" />
                  <HasText
                    strong
                    type="secondary"
                    content={i18n.t('typeSwap.emptyState')}
                    className="mb-4 text-center"
                    style={{ maxWidth: '500px' }}
                  />
                </>
              ) : (
                <Result
                  status="success"
                  title={<HasText content={i18n.t('typeSwap.swapComplete')} />}
                  extra={[
                    <Link key={'swap-event-redirect'} to={`/events/event/${swappedEventId}`}>
                      <HasButton type="primary">{i18n.t('typeSwap.redirectToSwappedEvent')}</HasButton>
                    </Link>,
                  ]}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export { HasEventTypeSwap };
