import { isEqual } from 'lodash';
import React from 'react';
import { CHART_COLORS, COLORS } from '../../../shared';
import { HasChart } from '../../atoms';

interface RadialChartProps {
  labels: string[];
  series: number[];
  title: string;
  subtitle?: string;
  clickCallback?: Function;
}

class HasRadialChart extends React.PureComponent<RadialChartProps, any> {
  constructor(props: Readonly<RadialChartProps>) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: 'radialBar',
          toolbar: { show: false },
          events: {
            dataPointSelection: this.onClick,
          },
          redrawOnParentResize: false,
        },
        colors: CHART_COLORS,
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 5,
              size: '10%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              show: false,
              name: { show: false },
              value: { show: false },
              total: { show: false },
            },
          },
        },
        tooltip: {
          enabled: true,
          position: 'topRight',
          theme: 'dark',
          y: { formatter: (value: any) => `${value} %` },
        },
        labels: [],
        title: {
          text: this.props.title,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '700',
            fontFamily: 'Montserrat',
            color: COLORS.NEW_ORANGE,
          },
        },
        subtitle: {
          text: this.props.subtitle,
          align: 'right',
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: COLORS.GRAY_ACCENT_DARK,
          },
        },
      },
      series: [],
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps: Readonly<RadialChartProps>, prevState: any) {
    if (!isEqual(this.props, prevProps)) {
      this.updateChartData();
    }
    if (this.props.title !== prevProps.title || this.props.subtitle !== prevProps.subtitle) {
      this.setState({
        ...this.state,
        options: {
          ...this.state.options,
          title: { text: this.props.title },
          subtitle: { text: this.props.subtitle },
        },
      });
    }
  }

  updateChartData = () => {
    const { labels, series } = this.props;
    this.setState({ ...this.state.options, options: { labels: labels }, series: series });
  };

  onClick = (event: any, chartContext: any, config: any) => {
    const clickedLabel = this.state.options.labels[config.dataPointIndex];
    if (this.props.clickCallback) {
      this.props.clickCallback(clickedLabel);
    }
  };

  render() {
    return (
      <HasChart
        options={this.state.options}
        series={this.state.series}
        type="radialBar"
        height={this.isFirefox() ? '90%' : '100%'}
      />
    );
  }

  isFirefox() {
    return navigator.userAgent.indexOf('Firefox') !== -1;
  }
}

export default HasRadialChart;
