import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import i18n from '../../../i18n/config';
import { ErrorMessages, ModalBaseProps, PASSWORD_REGEX } from '../../../shared';
import { HasModal, HasParagraph, HasPasswordInput } from '../../atoms';

interface UserPasswordProps extends ModalBaseProps<string> {}

interface UserPasswordState {
  updateInProgress: boolean;
}

class HasChangePasswordModal extends React.Component<UserPasswordProps, UserPasswordState> {
  state = { updateInProgress: false };

  private formRef = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<UserPasswordProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ updateInProgress: false });
    }
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    this.props.onOk(values.password).then(
      () => {
        this.setState({ updateInProgress: false });
        this.formRef.current?.resetFields();
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  passwordFieldChanged = (rule: any, value: any, callback: any) => {
    if (this.formRef.current) {
      if (value && this.formRef.current.isFieldTouched('confirmPassword')) {
        this.formRef.current.validateFields(['confirmPassword']);
      }
      return Promise.reject();
    }
  };

  passwordMatchValidator = (rule: any, value: any, callback: any) => {
    if (this.formRef.current) {
      try {
        const password = this.formRef.current.getFieldValue('password');
        if (value && password !== value) {
          throw new Error(rule.message);
        } else {
          return Promise.reject();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };

  render() {
    const { visible } = this.props;
    return (
      <HasModal
        title={
          <React.Fragment>
            <HasParagraph content={i18n.t('shared.changePassword')} style={{ marginBottom: '10px' }} />
            <HasParagraph
              content={i18n.t('signup.newPassword')}
              style={{ fontSize: '12px', margin: 0, fontWeight: 400 }}
            />
          </React.Fragment>
        }
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'password-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
      >
        <Form
          id="password-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
        >
          <Form.Item
            name="password"
            label={i18n.t('shared.password')}
            colon={false}
            hasFeedback
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()) },
              { pattern: PASSWORD_REGEX, message: ErrorMessages.PASSWORD_INVALID },
              { validator: this.passwordFieldChanged },
            ]}
          >
            <HasPasswordInput key="password" size="large" visibilityToggle />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={i18n.t('signup.confirmPassword')}
            colon={false}
            hasFeedback
            rules={[
              { required: true, message: ErrorMessages.INPUT_CONFIRM(i18n.t('shared.password').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_CONFIRM(i18n.t('shared.password').toLowerCase()) },
              { validator: this.passwordMatchValidator, message: ErrorMessages.PASSWORDS_NOT_MATCH },
            ]}
          >
            <HasPasswordInput key="confirmPassword" size="large" type="password" visibilityToggle />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasChangePasswordModal;
