import Select, { SelectValue } from 'antd/lib/select';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import i18n from '../../../i18n/config';
import { DEFAULT_LANGUAGE, Language, LANGUAGES } from '../../../shared';
import { HasDropdown } from '../../atoms';

interface LanguageDropdownState {
  selectedLanguage: Language | null;
}

class HasLanguageDropdown extends React.Component<{}, LanguageDropdownState> {
  state: LanguageDropdownState = { selectedLanguage: null };

  componentDidMount() {
    this.setState({ selectedLanguage: this.getSelectedLanguage() });
  }

  getSelectedLanguage = () => {
    const selectedLanguage = LANGUAGES.find((langObject) => langObject.id === i18n.language);
    return selectedLanguage ? selectedLanguage : DEFAULT_LANGUAGE;
  };

  languageChanged = (value: SelectValue, option: any) => {
    if (option) {
      this.setState({ selectedLanguage: option });
      i18n.changeLanguage(String(value));
    }
  };

  render() {
    const { Option } = Select;
    const { selectedLanguage } = this.state;
    return (
      selectedLanguage && (
        <HasDropdown<Language>
          onChange={this.languageChanged}
          defaultValue={selectedLanguage.id}
          style={{ minWidth: '180px' }}
        >
          {LANGUAGES.map(({ id, name, countryCode }) => (
            <Option key={id} value={id} label={name}>
              <div className="d-flex flex-row align-items-center">
                <ReactCountryFlag countryCode={countryCode} svg style={{ marginRight: '5px' }} />
                {name}
              </div>
            </Option>
          ))}
        </HasDropdown>
      )
    );
  }
}

export default HasLanguageDropdown;
