import { List } from 'antd';
import { ListProps } from 'antd/lib/list';
import { isEqual } from 'lodash';
import React from 'react';
import { ListItemWithMetaProps } from '../../../shared';
import { HasListItem } from '../../atoms';

interface OwnListProps<T> {
  listConfig?: ListProps<T>;
  listItemsConfig: ListItemWithMetaProps[];
  interactive?: boolean;
  selectedIndex?: number;
}

class HasList<T> extends React.Component<OwnListProps<T>, {}> {
  render() {
    const { listConfig, listItemsConfig, interactive, selectedIndex } = this.props;
    return (
      <List {...listConfig}>
        {listItemsConfig.map((config, index) => (
          <HasListItem
            key={index}
            itemConfig={config}
            interactive={interactive}
            active={isEqual(selectedIndex, index)}
          />
        ))}
      </List>
    );
  }
}

export default HasList;
