import { MenuProps } from 'antd/lib/menu';
import React from 'react';
import { MenuItemWithKey } from '../../../shared';
import { HasMenu } from '../../molecules';

interface HorizontalMenuProps {
  menuItems: MenuItemWithKey[];
}

class HasHorizontalMenu extends React.PureComponent<MenuProps & HorizontalMenuProps, {}> {
  render() {
    return <HasMenu mode="horizontal" {...this.props} />;
  }
}

export default HasHorizontalMenu;
