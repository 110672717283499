import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { httpClient, PaginationService } from '.';
import {
  Incident,
  IncidentFilters,
  IncidentGlance,
  IncidentRollingYearFilters,
  IncidentStatisticsWrapper,
  IncidentType,
  InvestigationStatus,
  NewIncident,
  Page,
} from '../models';
import { ACCEPT_LANGUAGE, EventCSVFilters, EventFilters, Location, PDF_EXPORT, SubscriberEvent } from '../shared';
import { EventUtils, buildUrl } from '../utils';
import { EntityAction, RestService, URL } from './rest.service';

class IncidentService implements RestService<Incident> {
  private incidentReferenceSubject = new Subject<string>();
  private incidentListChangedSubject = new Subject<null>();
  private eventTypeChangedSubject = new Subject<IncidentType>();
  private incidentChangedSubject = new Subject<SubscriberEvent<Incident>>();

  get(params?: any): Promise<AxiosResponse<Incident>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Incident[]>> {
    return httpClient.get<Incident[]>(buildUrl(URL.INCIDENT, ['all']));
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Incident>> {
    return httpClient.get<Incident>(buildUrl(URL.INCIDENT, [resourceId]));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Incident>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Incident>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Incident): Promise<AxiosResponse<Incident>> {
    throw new Error('Method not implemented.');
  }

  update(incident: Incident): Promise<AxiosResponse<Incident>> {
    return httpClient.put<Incident>(buildUrl(URL.INCIDENT, ['updateFormResult']), incident);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Incident>> {
    throw new Error('Method not implemented.');
  }

  divisionHasAnyIncidents(divisionId: number): Promise<AxiosResponse<boolean>> {
    return httpClient.get<boolean>(buildUrl(URL.INCIDENT, ['hasAny', divisionId]));
  }

  getAllByCompanyAndDivision(getHidden: boolean, loggedUserId: number | null): Promise<AxiosResponse<Incident[]>> {
    let params = {};
    params = { ...params, getHidden, loggedUserId };
    return httpClient.get<Incident[]>(buildUrl(URL.INCIDENT, ['all']), { params: params });
  }

  getAllPaginatedAndFiltered(filters: EventFilters): Promise<AxiosResponse<Page<Incident>>> {
    const createdFilters = PaginationService.createFilters(filters);
    createdFilters['hidden'] = { values: filters.showHidden ? ['0', '1'] : ['0'] };
    if (filters.loggedInUserId !== undefined) createdFilters['loggedInUserId'] = { values: [filters.loggedInUserId] };
    if (filters.dateRange) createdFilters['dateRange'] = { stringValues: filters.dateRange };
    return httpClient.post<Page<Incident>>(buildUrl(URL.INCIDENT, ['paginated']), createdFilters, {
      params: { ...PaginationService.createPagination(filters), forNewExport: false },
    });
  }

  getAllPaginatedCSV(filters: EventCSVFilters): Promise<AxiosResponse<Page<Incident>>> {
    const createdFilters = PaginationService.createFilters(filters);
    createdFilters['hidden'] = { values: filters.showHidden ? ['0', '1'] : ['0'] };
    createdFilters['investigationRequired'] = {
      values: filters.requiresInvestigation.map((value) => (value ? '1' : '0')),
    };
    createdFilters['hasOpenActions'] = { values: filters.hasActionsOpen.map((value) => (value ? '1' : '0')) };
    createdFilters['status'] = {
      values: filters.investigationStatus.map((value) => (value === InvestigationStatus.OPEN ? '0' : '1')),
    };
    createdFilters['type'] = { values: filters.types.map((type) => EventUtils.getIndexByIncidentType(type)) };
    if (filters.loggedUserId) createdFilters['loggedInUserId'] = { values: [filters.loggedUserId] };
    return httpClient.post<Page<Incident>>(buildUrl(URL.INCIDENT, ['paginated']), createdFilters, {
      params: { ...PaginationService.createPagination(filters), forNewExport: true },
    });
  }

  findLatestByCompanyAndDivision(): Promise<AxiosResponse<Incident[]>> {
    return httpClient.get<Incident[]>(buildUrl(URL.INCIDENT, ['latest']));
  }

  countByType(): Promise<AxiosResponse<IncidentGlance[]>> {
    return httpClient.get<IncidentGlance[]>(buildUrl(URL.INCIDENT, ['countByType']));
  }

  getRollingYear(
    incidentFilters?: IncidentRollingYearFilters
  ): Promise<AxiosResponse<{ [K: string]: IncidentGlance[] }>> {
    return httpClient.post<{ [K: string]: IncidentGlance[] }>(buildUrl(URL.INCIDENT, ['rollingYear']), incidentFilters);
  }

  getIncidentsStatistics(formType: IncidentType): Promise<AxiosResponse<IncidentStatisticsWrapper>> {
    let params = {};
    params = { formType: IncidentType[formType] };
    return httpClient.post<IncidentStatisticsWrapper>(
      buildUrl(URL.INCIDENT, ['incidentStatistics']),
      {},
      { params: params }
    );
  }

  getIncidentsHeatmap(): Promise<AxiosResponse<Location[]>> {
    return httpClient.get<Location[]>(buildUrl(URL.INCIDENT, ['heatmap']));
  }

  createNewIncident(formData: FormData): Promise<AxiosResponse<Incident>> {
    return httpClient.post<Incident>(buildUrl(URL.INCIDENT, ['uploadIncident']), formData);
  }

  hideIncident(incidentId: number): Promise<AxiosResponse<Incident>> {
    return httpClient.post<Incident>(buildUrl(URL.INCIDENT, ['hide', incidentId]));
  }

  getCsv(columns: string[], filters: IncidentFilters): Promise<AxiosResponse<string>> {
    return httpClient.post<string>(buildUrl(URL.INCIDENT, ['allextendedcsv']), JSON.stringify({ columns, filters }), {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getDirectCsv(filters: EventFilters): Promise<AxiosResponse<string>> {
    const createdFilters = PaginationService.createFilters(filters);
    if (filters.showHidden !== undefined) createdFilters['hidden'] = { values: [filters.showHidden ? '1' : '0'] };
    if (filters.loggedInUserId !== undefined) createdFilters['loggedInUserId'] = { values: [filters.loggedInUserId] };
    if (filters.dateRange) createdFilters['dateRange'] = { stringValues: filters.dateRange };
    return httpClient.post<string>(buildUrl(URL.INCIDENT, ['allextendedcsv2']), createdFilters, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  exportPdf(id: string, entitiesToInclude: PDF_EXPORT[], language?: string): Promise<AxiosResponse<any>> {
    let params = null;
    if (language) {
      params = { language };
    }
    return httpClient.post(buildUrl(URL.INCIDENT, ['pdf', id]), entitiesToInclude, { responseType: 'blob', params });
  }

  getCsvTemplate(language: string): Promise<AxiosResponse<any>> {
    return httpClient.get<any>(buildUrl(URL.INCIDENT, ['csv-template']), { headers: { [ACCEPT_LANGUAGE]: language } });
  }

  swapEventType(id: string | number, toType: IncidentType, newEvent: NewIncident): Promise<AxiosResponse<any>> {
    return httpClient.post<any>(buildUrl(URL.INCIDENT, ['swapType', id, toType]), newEvent);
  }

  // SUBSCRIPTIONS

  getIncidentReferenceSubject(): Observable<string> {
    return this.incidentReferenceSubject.asObservable();
  }

  incidentReferenceChanged(reference: string) {
    this.incidentReferenceSubject.next(reference);
  }

  getIncidentListChanged(): Observable<null> {
    return this.incidentListChangedSubject.asObservable();
  }

  incidentListChanged() {
    this.incidentListChangedSubject.next();
  }

  getEventTypeChangedListener(): Observable<IncidentType> {
    return this.eventTypeChangedSubject.asObservable();
  }

  eventTypeChanged(eventType: IncidentType) {
    this.eventTypeChangedSubject.next(eventType);
  }

  getIncidentChangedListener(): Observable<SubscriberEvent<Incident>> {
    return this.incidentChangedSubject.asObservable();
  }

  incidentChanged(event: EntityAction, incident: Incident) {
    this.incidentChangedSubject.next({ event: event, entity: incident });
  }
}

export default new IncidentService();
