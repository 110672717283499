import { Attachment } from './attachment.model';
import { LogEntry } from './log.model';

export interface Investigation {
  id: number;
  incidentId: number;
  formElements: InvestigationForm;
  attachments: Attachment[];
  status: InvestigationStatus;
  private: boolean;
  riskScore?: number;
  riskLevel?: string;
}

export interface InvestigationForm {
  incidentType: LogEntry[];
  areaSafe: LogEntry[];
  insuranceCompanyInformed: LogEntry[];
  activity: LogEntry[];
  howDidItHappen: LogEntry[];
  riskAssessmentConducted: LogEntry[];
  riskOfHazardIdentified: LogEntry[];
  hazardsAndRisks: LogEntry[];
  similarHazardsAndRisksExist: LogEntry[];
  victimsLengthInRole: LogEntry[];
  victimsLengthOfEmployment: LogEntry[];
  victimHasWorkRelatedIssues: LogEntry[];
  drugsAndAlcoholInvolved: LogEntry[];
  unusualWorkConditions: LogEntry[];
  organisationInfluencedTheEvent: LogEntry[];
  workingAreaMaintained: LogEntry[];
  werePeopleInvolvedCompetent: LogEntry[];
  peopleAuthorizedToUndertakeTask: LogEntry[];
  workplaceLayoutContributeToEvent: LogEntry[];
  materialsContributeToEvent: LogEntry[];
  plantContributeToEvent: LogEntry[];
  safetyEquipmentContributeToEvent: LogEntry[];
  otherInfluenceToEvent: LogEntry[];
  finalReason: LogEntry[];
  primaryRootCause: LogEntry[];
  secondaryRootCause: LogEntry[];
  incidentRequiresCommunicationToWiderAudience: LogEntry[];
  riskMatrix: LogEntry[];
}

export enum FormEntryType {
  RADIO,
  RADIO_FREE_TEXT,
  RADIO_SUB_QUESTION,
}

export enum InvestigationStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
