import React from 'react';
import { HasSwitch, HasText } from '../..';
import { COLORS } from '../../../shared';

interface StatusToggleProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  leftLabel: string;
  rightLabel: string;
  disabled?: boolean;
}

class HasStatusToggle extends React.Component<StatusToggleProps, {}> {
  render() {
    const { isChecked, leftLabel, rightLabel, onChange, disabled } = this.props;
    return (
      <div>
        {!isChecked ? (
          <HasText
            content={leftLabel}
            strong={!isChecked}
            style={{ color: !isChecked ? COLORS.BLACK_RGBA(0.45) : 'inherit' }}
          />
        ) : (
          <HasText
            content={rightLabel}
            strong={isChecked}
            style={{ color: isChecked ? COLORS.PRIMARY_BLUE : 'inherit' }}
          />
        )}
        <HasSwitch
          size="default"
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
          style={{ margin: '0 8px', backgroundColor: isChecked ? COLORS.PRIMARY_BLUE : COLORS.BLACK_RGBA(0.45) }}
        />
      </div>
    );
  }
}

export default HasStatusToggle;
