import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import i18n from '../../../i18n/config';
import { HasModal, HasText, HasTextInput } from '../../atoms';

interface AddPanelModalProps {
  visible: boolean;
  existingTitle?: string;
  existingTitleFr?: string;
  onOk: (title: string, titleFr: string) => void;
  onCancel: any;
}

interface AddPanelModalState {
  title: string;
  titleFr: string;
}

class HasAddPanelModal extends React.Component<AddPanelModalProps, AddPanelModalState> {
  private formRef = React.createRef<FormInstance>();

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.props.onOk(values.title, values.titleFr ? values.titleFr : values.title);
    this.formRef.current?.resetFields();
  };

  render() {
    const { visible, existingTitle, existingTitleFr } = this.props;
    return (
      <HasModal
        title={existingTitle ? i18n.t('charts.panelAction.edit') : i18n.t('charts.tooltip.addPanel')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: false, htmlType: 'submit', form: 'add-panel-form' }}
        cancelButtonProps={{ disabled: false }}
        destroyOnClose
      >
        <Form
          id="add-panel-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{ title: existingTitle ? existingTitle : '', titleFr: existingTitleFr ? existingTitleFr : '' }}
        >
          <Form.Item name="title" label={i18n.t('charts.panelTitle')} colon={false} style={{ textAlign: 'center' }}>
            <div className="d-flex" style={{ alignItems: 'baseline' }}>
              <HasText content="En:" style={{ width: '30px' }}></HasText>
              <HasTextInput key="title" className="mb-2" style={{ maxWidth: '300px', marginLeft: '10px' }} />
            </div>
          </Form.Item>
          <Form.Item name="titleFr" colon={false} style={{ textAlign: 'center' }}>
            <div className="d-flex" style={{ alignItems: 'baseline' }}>
              <HasText content="Fr:" style={{ width: '30px' }}></HasText>
              <HasTextInput key="titleFr" className="mb-2" style={{ maxWidth: '300px', marginLeft: '10px' }} />
            </div>
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasAddPanelModal;
