import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HasButton, HasCheckbox, HasTitle } from '../../components';
import { Notification } from '../../components/atoms';
import i18n from '../../i18n/config';
import { NotificationTriggerType, User, UserRole, notificationLabels } from '../../models';
import { UserService } from '../../services';
import { COLORS, ErrorMessages, SuccessMessages } from '../../shared';
import { isUserRoleEqualsTo } from '../../utils';

interface UserEmailNotificationsProps extends WithTranslation {
  user: User;
  setUpdatedUser: (user: User) => void;
}

interface UserEmailNotificationsState {
  notifications: NotificationTriggerType[];
  initialNotifications: NotificationTriggerType[];
}

class UserEmailNotifications extends React.Component<UserEmailNotificationsProps, UserEmailNotificationsState> {
  allNotifications = Object.values(NotificationTriggerType);

  healthAndSafetyNotifications = [] as NotificationTriggerType[];

  riskAssessmentNotifications = [
    NotificationTriggerType.RISK_ASSESSMENTS_REMINDER,
    NotificationTriggerType.RISK_ASSESSMENTS_ONE_OFF_REMINDER,
  ];

  equipmentNotifications = [
    NotificationTriggerType.MAINTENANCE_LOGS_CREATE,
    NotificationTriggerType.MAINTENANCE_LOGS_EDIT,
  ];

  state = {
    notifications: [] as NotificationTriggerType[],
    initialNotifications: [] as NotificationTriggerType[],
  };

  componentDidMount() {
    this.setState({
      notifications: this.props.user.notificationTriggers,
      initialNotifications: this.props.user.notificationTriggers,
    });
    this.getHealthAndSafetyNotificationsByUserRole();
  }

  handleSave = () => {
    const updatedUser = { ...this.props.user, notificationTriggers: this.state.notifications };
    UserService.updateOwnProfile(updatedUser)
      .then(() => {
        Notification.success(SuccessMessages.EMAIL_NOTIFICATIONS_UPDATED);
        this.props.setUpdatedUser(updatedUser);
        this.setState({
          initialNotifications: [...this.state.notifications],
        });
      })
      .catch(() => {
        Notification.error(ErrorMessages.EMAIL_NOTIFICATIONS_UPDATED);
      });
  };

  handleAssignedNotification = (assignedNotification: NotificationTriggerType) => {
    const notification = this.allNotifications.find((notification) => notification === assignedNotification);
    if (notification) {
      this.setState({
        notifications: [...this.state.notifications, notification],
      });
    }
  };

  handleRemoveNotification = (removedNotification: NotificationTriggerType) => {
    const notification = this.state.notifications.find((notification) => notification === removedNotification);
    if (notification) {
      this.setState((prevState) => ({
        notifications: prevState.notifications.filter((p) => p !== notification),
      }));
    }
  };

  handleCancel = () => {
    this.setState({ notifications: this.state.initialNotifications });
  };

  isCheckboxDisabled = () => {
    const { notifications, initialNotifications } = this.state;
    return (
      initialNotifications.every((initialNotification) =>
        notifications.some((notification) => notification === initialNotification)
      ) && initialNotifications.length === notifications.length
    );
  };

  private getHealthAndSafetyNotificationsByUserRole() {
    if (
      isUserRoleEqualsTo(UserRole.EMPLOYEE) ||
      isUserRoleEqualsTo(UserRole.LIMITED_EMPLOYEE_DIVISION) ||
      isUserRoleEqualsTo(UserRole.LIMITED_EMPLOYEE_PERSONAL)
    ) {
      this.healthAndSafetyNotifications = [NotificationTriggerType.ACTIONS_ASSIGN];
    } else {
      this.healthAndSafetyNotifications = [
        NotificationTriggerType.UPLOAD_INCIDENT_OR_NEAR_HIT,
        NotificationTriggerType.ACTIONS_ASSIGN,
      ];
    }
  }

  getNotificationOptions = (notification: NotificationTriggerType[]) => {
    return (
      <div className="d-flex flex-column mt-2" style={{ gap: '8px' }}>
        {Object.values(notification).map((notificationType, index) => (
          <div className="d-flex" style={{ gap: '8px' }} key={index}>
            <HasCheckbox
              style={{ color: COLORS.BLACK }}
              checked={this.state.notifications.some((notification) => notification === notificationType)}
              content={notificationLabels[notificationType](i18n.t.bind(i18n))}
              onChange={(e) => {
                if (e.target.checked) {
                  this.handleAssignedNotification(notificationType);
                } else {
                  this.handleRemoveNotification(notificationType);
                }
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          backgroundColor: COLORS.WHITE,
          padding: '16px',
          border: `1px solid ${COLORS.GRAY_ACCENT_LIGHT}`,
          marginLeft: '5px',
        }}
        className="d-flex flex-column flex-grow-1 shadow-sm overflow-auto"
      >
        <HasTitle
          content={t('user.emailNotifications', 'Email notifications')}
          level={3}
          style={{ margin: 0, fontWeight: 700, color: COLORS.NEW_ORANGE }}
        />
        <div className="d-flex flex-column" style={{ margin: '16px 0', paddingLeft: '10px', gap: '24px' }}>
          <div>
            <HasTitle content={t('permissions.healthAndSafety', 'Health and Safety')} style={{ fontSize: '16px' }} />
            {this.getNotificationOptions(this.healthAndSafetyNotifications)}
          </div>
          <div>
            <HasTitle content={t('shared.riskAssessment', 'Risk assessment')} style={{ fontSize: '16px' }} />
            {this.getNotificationOptions(this.riskAssessmentNotifications)}
          </div>
          <div>
            <HasTitle content={t('shared.equipment', 'Equipment')} style={{ fontSize: '16px' }} />
            {this.getNotificationOptions(this.equipmentNotifications)}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <HasButton type="primary" onClick={this.handleSave} disabled={this.isCheckboxDisabled()}>
            {t('shared.save', 'Save')}
          </HasButton>
          <HasButton ghost type="primary" onClick={this.handleCancel}>
            {t('shared.cancel', 'Cancel')}
          </HasButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UserEmailNotifications);
