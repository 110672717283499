import { Switch } from 'antd';
import React from 'react';
import { SwitchProps } from 'antd/lib/switch';

interface OwnSwitchProps extends SwitchProps {}

class HasSwitch extends React.Component<OwnSwitchProps, {}> {
  render() {
    return <Switch {...this.props} />;
  }
}

export default HasSwitch;
