import { ReactText } from 'react';
import { ChatBotStepValue } from '../shared';

export interface FormResult {
  id?: number;
  key: string;
  type: string;
  extra?: string;
  value: string;
  incidentId?: number;
  userId?: number | null;
  userName?: string;
  investigationRequired: boolean;
  seqNo: number;
  createdAt: string;
}

export interface NewFormResult {
  id?: number;
  key: ReactText;
  type: string;
  extra?: ChatBotStepValue;
  value: ChatBotStepValue | undefined;
  incidentId?: number;
  investigationRequired?: boolean;
  seqNo: number;
  createdAt?: string;
}

export enum FormResultType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  OPTION = 'OPTION',
  REASON = 'REASON',
  SUB_REASON = 'SUB_REASON',
  CAUSE = 'CAUSE',
  SUB_CAUSE = 'SUB_CAUSE',
  OPTION_DESCRIPTION = 'OPTION_DESCRIPTION',
}
