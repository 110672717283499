import { CopyFilled, DeleteFilled, PlusCircleFilled, SaveFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import 'react-resizable/css/styles.css';
import { RouteComponentProps } from 'react-router-dom';
import {
  HasAddDashboardModal,
  HasAddPanelModal,
  HasButton,
  HasDropdown,
  HasRemovePanelModal,
  HasSpinner,
  HasText,
} from '../../components';
import { HasSwitch, Notification } from '../../components/atoms';
import i18n from '../../i18n/config';
import {
  ChartDashboard,
  ChartDashboardMinimized,
  ChartEventsDataFilter,
  ChartInstance,
  Company,
  UserRole,
} from '../../models';
import { ChartService, CompanyService } from '../../services';
import { CHART_PAGE_STATE, ErrorMessages, Option, SuccessMessages, TimeIntervals } from '../../shared';
import { isTokenExpired, isUserRoleEqualsTo } from '../../utils';
import ChartEvents from './charts.events';
import DashboardPanel from './dashboard.panel';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface ChartsProps extends RouteComponentProps, WithTranslation {
  className: string;
  cols: { lg: number; md: number; sm: number; xs: number; xxs: number };
  rowHeight: number;
}

interface ChartsState {
  loading: boolean;
  breakpoint: any;
  cols: { lg: number; md: number; sm: number; xs: number; xxs: number };
  showAddPanelModal: boolean;
  showAddDashboardModal: boolean;
  dashboard?: ChartDashboard;
  minimizedDashboards: ChartDashboardMinimized[];
  chartsInstances: Map<number, ChartInstance>;
  userCompany?: Company;
  userVisibleCompanies?: Company[];
  showDeleteLayoutModal?: boolean;
  isDuplicateDashboardAction?: boolean;
  editMode: boolean;
  showChartEvents?: boolean;
  chartEventsDataFilter?: ChartEventsDataFilter;
  language: string;
}

class ChartsPage extends React.Component<ChartsProps, ChartsState> {
  static defaultProps = {
    className: 'layout no-borders',
    cols: { lg: 18, md: 12, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 5,
  };

  showFullScreen = !isTokenExpired();

  constructor(props: ChartsProps) {
    super(props);

    this.state = {
      language: 'en',
      loading: true,
      breakpoint: undefined,
      cols: { lg: 18, md: 12, sm: 6, xs: 4, xxs: 2 },
      showAddPanelModal: false,
      showAddDashboardModal: false,
      minimizedDashboards: [],
      chartsInstances: new Map(),
      showDeleteLayoutModal: false,
      isDuplicateDashboardAction: false,
      editMode: false,
    };
  }

  componentDidMount() {
    const initialLocale = localStorage.getItem('i18nextLng');
    this.languageChanged(initialLocale ? initialLocale : 'en');
    i18n.on('languageChanged', (lng: string) => this.languageChanged(lng));
    const savedState = localStorage.getItem(CHART_PAGE_STATE);
    localStorage.removeItem(CHART_PAGE_STATE);
    if (savedState) {
      const parsedState: ChartsState = JSON.parse(savedState, this.reviver);
      this.setState(parsedState);
    } else {
      this.getAllVisibleDashboardsMinimized();
      this.getUserVisibleCompanies();
      this.getUserCompany();
    }
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
    i18n.off('languageChanged', (lng: string) => this.languageChanged(lng));
  }

  onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (this.state.showChartEvents) {
      this.onBackToDashboard();
    }
  };

  languageChanged = (lng: string) => {
    if (this.state.language !== lng) {
      this.setState((previousState) => {
        return { ...previousState, language: lng };
      });
    }
  };

  getUserCompany = async () => {
    const { data: userCompany } = await CompanyService.getCompanyForLoggedInUser();
    this.setState((previousState) => {
      return { ...previousState, userCompany };
    });
  };

  getUserVisibleCompanies = async () => {
    const { data: userVisibleCompanies } = await CompanyService.getUserVisibleCompanies();
    this.setState((previousState) => {
      return { ...previousState, userVisibleCompanies };
    });
  };

  getAllVisibleDashboardsMinimized = async () => {
    const { data: minimizedDashboards } = await ChartService.getAllVisibleMinimized();
    this.setState((previousState) => {
      return { ...previousState, loading: false, minimizedDashboards };
    });
    if (minimizedDashboards && !isEmpty(minimizedDashboards)) {
      this.loadSelectedDashboard(this.getSelectedEditableDashboard(minimizedDashboards));
    } else {
      this.setState((previousState) => {
        return { ...previousState, dashboard: undefined };
      });
    }
  };

  getAllEditableDashboardsMinimized = async () => {
    const { data: minimizedDashboards } = await ChartService.getAllEditableMinimized();
    this.setState((previousState) => {
      return { ...previousState, minimizedDashboards };
    });
    if (minimizedDashboards && !isEmpty(minimizedDashboards)) {
      this.loadSelectedDashboard(this.getSelectedEditableDashboard(minimizedDashboards));
    } else {
      this.setState((previousState) => {
        return { ...previousState, dashboard: undefined };
      });
    }
  };

  getSelectedEditableDashboard = (minimizedDashboards: ChartDashboardMinimized[]) => {
    let selectedDashboard = minimizedDashboards[0];
    if (this.state.dashboard?.id) {
      const searchedDashboard = minimizedDashboards.find(
        (minimizedDashboard) => minimizedDashboard.id === this.state.dashboard?.id
      );
      if (searchedDashboard) {
        selectedDashboard = searchedDashboard;
      }
    }
    return selectedDashboard;
  };

  dashboardChanged = (_value: SelectValue, option: Option<ChartDashboardMinimized>) => {
    const selectedDashboard = option.forObject;
    this.loadSelectedDashboard(selectedDashboard);
  };

  loadSelectedDashboard = (selectedDashboard: ChartDashboardMinimized) => {
    if (selectedDashboard.id && selectedDashboard.id !== -1) {
      this.getDashboard(selectedDashboard.id);
    }
  };

  onEditModeChanged = (editMode: boolean) => {
    this.setState((previousState) => {
      return { ...previousState, editMode };
    });
    if (editMode) {
      this.getAllEditableDashboardsMinimized();
    } else {
      this.getAllVisibleDashboardsMinimized();
    }
  };

  onDeleteDashboard = async () => {
    if (this.state.dashboard?.id) {
      await ChartService.deleteDashboard(this.state.dashboard.id).then(
        () => {
          Notification.success(SuccessMessages.LAYOUT_DELETE);
          this.setState((previousState) => {
            return { ...previousState, dashboard: undefined, showDeleteLayoutModal: false, loading: true };
          });
          this.getAllVisibleDashboardsMinimized();
        },
        (error) => Notification.error({ message: error.response.data.message })
      );
    }
  };

  getDashboard = async (dashboardId: number) => {
    const { data: dashboard } = await ChartService.getById(dashboardId);
    this.setState((previousState) => {
      dashboard.panels.forEach((panel) =>
        panel.charts.forEach((chartContainer) => {
          this.getChartInstance(chartContainer.chartInstanceId);
        })
      );
      return { ...previousState, loading: false, dashboard };
    });
  };

  getChartInstance = async (chartInstanceId: number) => {
    const { data: chartInstance } = await ChartService.getChartInstanceLayout(chartInstanceId);
    this.setState((previousState) => {
      const { chartsInstances } = previousState;
      if (chartInstance.id) {
        chartsInstances.set(chartInstance.id, chartInstance);
      }
      return { ...previousState, loading: false, chartsInstances };
    });
  };

  saveDashboard = async () => {
    if (this.state.dashboard) {
      try {
        if (this.state.dashboard.id) {
          ChartService.update(this.state.dashboard);
          Notification.success(SuccessMessages.LAYOUT_UPDATE);
        } else {
          const { data: createdDashboard } = await ChartService.create(this.state.dashboard);
          if (this.state.editMode) {
            await this.getAllEditableDashboardsMinimized();
          } else {
            await this.getAllVisibleDashboardsMinimized();
          }
          Notification.success(SuccessMessages.LAYOUT_SAVE);
          this.setState((previousState) => {
            return { ...previousState, dashboard: createdDashboard };
          });
        }
      } catch (error) {
        Notification.error(ErrorMessages.LAYOUT_ERROR(error.response?.data.message));
      }
    }
  };

  onAddDashboard = (title: string, titleFr: string, visibleForCreator: boolean, companyId: number) => {
    const newDashboard: ChartDashboard = {
      title,
      titleFr,
      visibleForCreator,
      panels: [],
      layouts: {},
      panelsCounter: 0,
      companyId,
    };
    this.setState((previousState) => {
      return { ...previousState, dashboard: newDashboard, showAddDashboardModal: false, editMode: true };
    });
  };

  onDuplicateDashboard = (title: string, titleFr: string, visibleForCreator: boolean, companyId: number) => {
    const oldDashboard = this.state.dashboard;
    if (oldDashboard) {
      const newDashboard: ChartDashboard = {
        ...oldDashboard.layouts,
        panels: oldDashboard.panels,
        id: undefined,
        title,
        titleFr,
        visibleForCreator,
        layouts: oldDashboard.layouts,
        panelsCounter: oldDashboard.panelsCounter,
        companyId,
      };
      this.setState((previousState) => {
        return {
          ...previousState,
          dashboard: newDashboard,
          showAddDashboardModal: false,
          isDuplicate: false,
          editMode: true,
        };
      });
    }
  };

  onBreakpointChange = (breakpoint: any, cols: any) => {
    this.setState((previousState) => {
      return { ...previousState, breakpoint, cols };
    });
  };

  onPanelsLayoutChange = (layout: any, layouts: any) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.layouts = layouts;
      }
      return {
        ...previousState,
        dashboard,
        showAddPanelModal: false,
      };
    });
  };

  chartsLibrary = () => {
    this.props.history.push(`charts/library`);
  };

  onAddPanel = (title: string, titleFr: string) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels.push({
          i: dashboard.panelsCounter.toString(),
          h: 3,
          w: 18,
          x: 0,
          y: dashboard.panelsCounter,
          title,
          titleFr,
          charts: [],
          layouts: {},
          chartsCounter: 0,
          expanded: true,
          timeInterval: TimeIntervals.THIS_MONTH,
        });
        dashboard.panelsCounter = dashboard.panelsCounter + 1;
      }
      return {
        ...previousState,
        dashboard,
        showAddPanelModal: false,
      };
    });
  };

  removePanel = (panelIndex: number) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels.splice(panelIndex, 1);
      }
      return { ...previousState, dashboard };
    });
  };

  onResizePanelGrid = (index: number, height: number | undefined) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels[index] = {
          ...dashboard.panels[index],
          h: height ? Math.round(height / ChartsPage.defaultProps.rowHeight) : 3,
        };
        const layouts = JSON.parse(JSON.stringify(dashboard.layouts));
        Object.keys(layouts).forEach((key) =>
          layouts[key][index]
            ? (layouts[key][index].h = height ? Math.round(height / ChartsPage.defaultProps.rowHeight) : 3)
            : ''
        );
        dashboard.layouts = layouts;
      }
      return { ...previousState, dashboard };
    });
  };

  onPanelExpanded = (index: number, expanded: boolean) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels[index] = { ...dashboard.panels[index], expanded };
      }
      return { ...previousState, dashboard };
    });
  };

  onEditPanel = (index: number, title: string, titleFr: string) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels[index] = { ...dashboard.panels[index], title, titleFr };
      }
      return { ...previousState, dashboard };
    });
  };

  onTimeIntervalChanged(
    timeInterval: string,
    startDate: string | undefined,
    endDate: string | undefined,
    index: number
  ) {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels[index] = { ...dashboard.panels[index], timeInterval, startDate, endDate };
      }
      return { ...previousState, dashboard };
    });
  }

  // CHARTS logic
  onAddChart = async (panelIndex: number, chartInstanceId: number, currentColsNumber: number | undefined) => {
    this.setState((previousState) => {
      const { dashboard, chartsInstances } = previousState;
      if (dashboard) {
        const currentPanel = dashboard.panels[panelIndex];
        currentPanel.charts.push({
          i: 'n' + currentPanel.chartsCounter,
          x: (currentPanel.charts.length * 6) % (currentColsNumber || 18),
          y: currentPanel.charts.length,
          w: 6,
          h: 7,
          chartInstanceId,
        });
        currentPanel.chartsCounter = currentPanel.chartsCounter + 1;
      }

      if (!chartsInstances.get(chartInstanceId)) {
        this.getChartInstance(chartInstanceId);
      }
      return { ...previousState, dashboard };
    });
  };

  onShowChartEvents = (chartEventsDataFilter: ChartEventsDataFilter) => {
    window.history.pushState(null, '', window.location.pathname);
    this.setState((previousState) => {
      return { ...previousState, showChartEvents: true, chartEventsDataFilter };
    });
  };

  onPanelChartLayoutChange = (panelIndex: number, layouts: any) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        dashboard.panels[panelIndex].layouts = layouts;
      }
      return { ...previousState, dashboard };
    });
  };

  onRemoveChart = (chartIndex: number, panelIndex: number) => {
    this.setState((previousState) => {
      const { dashboard } = previousState;
      if (dashboard) {
        const currentPanel = dashboard.panels[panelIndex];
        currentPanel.charts.splice(chartIndex, 1);
      }
      return { ...previousState, dashboard };
    });
  };

  navigateToIncident = (incidentId: number) => {
    localStorage.setItem(CHART_PAGE_STATE, JSON.stringify(this.state, this.replacer));
    this.props.history.push(`events/event/${incidentId}`);
  };

  replacer = (key: any, value: any) => {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    } else {
      return value;
    }
  };

  reviver = (key: any, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  };

  onBackToDashboard = () => {
    this.setState((previousState) => {
      return { ...previousState, chartEventsDataFilter: undefined, showChartEvents: undefined };
    });
  };

  getEditButtonsBar = () => {
    const { dashboard, editMode } = this.state;

    return editMode ? (
      <>
        <div className="d-flex my-2" style={{ justifyContent: 'space-between' }}>
          <div className="d-flex mt-2">
            {!dashboard || dashboard.id ? (
              <Tooltip title={i18n.t('charts.tooltip.addDashboard')}>
                <HasButton
                  className="mr-4"
                  type="primary"
                  size="middle"
                  style={{ minWidth: 'unset' }}
                  onClick={() => this.setState({ showAddDashboardModal: true, isDuplicateDashboardAction: false })}
                >
                  <PlusCircleFilled />
                  {i18n.t('charts.addDashboard')}
                </HasButton>
              </Tooltip>
            ) : (
              ''
            )}
            {dashboard?.id ? (
              <Tooltip title={i18n.t('charts.tooltip.duplicate')}>
                <HasButton
                  className="mr-4"
                  type="primary"
                  style={{ minWidth: 'unset' }}
                  onClick={() => this.setState({ showAddDashboardModal: true, isDuplicateDashboardAction: true })}
                >
                  <CopyFilled />
                </HasButton>
              </Tooltip>
            ) : (
              ''
            )}
            {dashboard ? (
              <Tooltip title={i18n.t('charts.tooltip.save')}>
                <HasButton
                  type="primary"
                  className="mr-4"
                  size="middle"
                  disabled={!dashboard}
                  style={{ minWidth: 'unset' }}
                  onClick={() => {
                    this.saveDashboard();
                  }}
                >
                  <SaveFilled />
                </HasButton>
              </Tooltip>
            ) : (
              ''
            )}
            {dashboard?.id ? (
              <Tooltip title={i18n.t('charts.tooltip.delete')}>
                <HasButton
                  className="mr-4"
                  type="primary"
                  style={{ background: 'red', border: 'unset', minWidth: 'unset' }}
                  onClick={() => this.setState({ showDeleteLayoutModal: true })}
                >
                  <DeleteFilled />
                </HasButton>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          {isUserRoleEqualsTo(UserRole.OWNER) ? (
            <HasButton
              className="mr-4"
              type="primary"
              size="middle"
              style={{ minWidth: 'unset' }}
              onClick={() => this.chartsLibrary()}
            >
              {i18n.t('charts.library')}
            </HasButton>
          ) : (
            ''
          )}
        </div>
        <Tooltip title={i18n.t('charts.tooltip.addPanel')}>
          <HasButton
            className="mr-4"
            type="primary"
            size="middle"
            style={{ minWidth: 'unset' }}
            disabled={!dashboard}
            onClick={() => this.setState({ showAddPanelModal: true })}
          >
            <PlusCircleFilled />
            {i18n.t('charts.panelAction.addPanel')}
          </HasButton>
        </Tooltip>
      </>
    ) : (
      ''
    );
  };

  getDashboardPage = () => {
    const { t } = this.props;
    const {
      showAddPanelModal,
      showAddDashboardModal,
      dashboard,
      loading,
      minimizedDashboards,
      chartsInstances,
      editMode,
      userCompany,
      userVisibleCompanies,
      showDeleteLayoutModal,
      isDuplicateDashboardAction,
      language,
    } = this.state;

    return (
      <>
        {loading ? (
          <HasSpinner size="large" />
        ) : (
          <React.Fragment>
            <div className="d-flex my-2" style={{ justifyContent: 'space-between' }}>
              {dashboard === undefined || dashboard.id ? (
                <HasDropdown<ChartDashboardMinimized>
                  onChange={this.dashboardChanged}
                  style={{ width: '200px', marginRight: '10px' }}
                  value={dashboard && dashboard.id ? dashboard.id : undefined}
                  options={minimizedDashboards.map((minDash) => ({
                    value: minDash.id ? minDash.id : -1,
                    label: language === 'fr' && minDash.titleFr ? minDash.titleFr : minDash.title,
                    forObject: minDash,
                  }))}
                />
              ) : (
                ''
              )}
              <HasSwitch
                checkedChildren={i18n.t('charts.editMode')}
                unCheckedChildren={i18n.t('charts.viewMode')}
                size="default"
                className="mr-4 mb-2 float-right"
                checked={editMode}
                onChange={this.onEditModeChanged}
              />
            </div>
            {this.getEditButtonsBar()}
            {dashboard ? (
              <ResponsiveReactGridLayout
                draggableHandle=".dragable-icon"
                onLayoutChange={(layout, layouts) => this.onPanelsLayoutChange(layout, layouts)}
                onBreakpointChange={this.onBreakpointChange}
                {...ChartsPage.defaultProps}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                layouts={dashboard.layouts}
                margin={[10, 0]}
                isResizable={false}
              >
                {dashboard.panels.map((panel, panelIndex) => (
                  <div key={panel.i} data-grid={panel}>
                    <DashboardPanel
                      key={'dp' + panel.title}
                      panel={panel}
                      chartsInstances={chartsInstances}
                      editMode={editMode}
                      userCompany={userCompany}
                      companyList={userVisibleCompanies}
                      language={language}
                      dashboardId={dashboard.id}
                      panelColapseEvent={(expanded) => this.onPanelExpanded(panelIndex, expanded)}
                      resizePanelGrid={(height) => this.onResizePanelGrid(panelIndex, height)}
                      removePanel={() => this.removePanel(panelIndex)}
                      editPanel={(title, titleFr) => this.onEditPanel(panelIndex, title, titleFr)}
                      layoutsChanged={(newLayout) => this.onPanelChartLayoutChange(panelIndex, newLayout)}
                      addChartToPanel={(chartInstanceId, currentColsNumber) =>
                        this.onAddChart(panelIndex, chartInstanceId, currentColsNumber)
                      }
                      removeChartFromPanel={(chartIndex) => this.onRemoveChart(chartIndex, panelIndex)}
                      timeIntervalChanged={(timeInterval, startDate, endDate) =>
                        this.onTimeIntervalChanged(timeInterval, startDate, endDate, panelIndex)
                      }
                      showChartEvents={(chartInstanceDataFilter) => this.onShowChartEvents(chartInstanceDataFilter)}
                    />
                  </div>
                ))}
              </ResponsiveReactGridLayout>
            ) : (
              <HasText
                type="secondary"
                content={t('charts.selectDashboard')}
                className="mb-4 text-center"
                style={{ fontSize: '18px' }}
              />
            )}
          </React.Fragment>
        )}
        <HasAddPanelModal
          visible={showAddPanelModal}
          onOk={this.onAddPanel}
          onCancel={() => this.setState({ showAddPanelModal: false })}
        />
        <HasAddDashboardModal
          isDuplicateDashboard={isDuplicateDashboardAction}
          usersCompany={userCompany}
          usersVisibleCompany={userVisibleCompanies}
          visible={showAddDashboardModal}
          onOk={(title, titleFr, visibleForCreator, companyId) =>
            isDuplicateDashboardAction
              ? this.onDuplicateDashboard(title, titleFr, visibleForCreator, companyId)
              : this.onAddDashboard(title, titleFr, visibleForCreator, companyId)
          }
          onCancel={() => this.setState({ showAddDashboardModal: false })}
        />
        <HasRemovePanelModal
          visible={showDeleteLayoutModal ? showDeleteLayoutModal : false}
          onOk={this.onDeleteDashboard}
          onCancel={() => this.setState({ showDeleteLayoutModal: false })}
          deleteLayout={true}
        />
      </>
    );
  };

  getChartEvents = () => {
    if (this.state.chartEventsDataFilter) {
      return (
        <ChartEvents
          chartEventsDataFilter={this.state.chartEventsDataFilter}
          navigationCallback={this.navigateToIncident}
        />
      );
    }
  };

  render() {
    return (
      <div
        style={{ width: this.showFullScreen ? '100%' : '70%', height: this.showFullScreen ? '100%' : 'auto' }}
        className={'has-charts-container has-container-tile overflow-y-auto'}
      >
        {!this.state.showChartEvents ? this.getDashboardPage() : this.getChartEvents()}
      </div>
    );
  }
}

export const HasChartsPage = withTranslation()(ChartsPage);
