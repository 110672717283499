import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { HasButton, HasParagraph, HasRadioGroup } from '../../..';
import i18n from '../../../../i18n/config';
import { CONTACT_WITH } from '../../../../models';
import {
  DELIMITER,
  i18nRadioGroupWrapper,
  IncidentReasonsMap,
  IncidentReasonsType,
  IncidentSubReasonsMap,
  RadioGroupOption,
  ReasonsMap,
  ReasonsType,
  SubReasonsMap,
  TEXT_AREA_MAX_LENGTH,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
} from '../../../../shared';
import { HasTextArea } from '../../../atoms';

interface ReasonRadioGroupProps {
  save: any;
  changeCallback: any;
  closeEditModeCallback?: any;
  isInvestigationReason: boolean;
}

interface ReasonRadioGroupState {
  radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>>[];
  subRadioOptionsConfig: RadioGroupOption<string>[];
  selectedReason?: i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>;
  selectedSubReason: string;
  subReasonDescription: string;
}

class HasReasonRadioGroup extends React.Component<ReasonRadioGroupProps, ReasonRadioGroupState> {
  private selectedReason: ReasonsType | IncidentReasonsType = CONTACT_WITH;

  private radioGroupConfig: TypedRadioGroupProps<i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>>;
  private subRadioGroupConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<ReasonRadioGroupProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onReasonChange,
    };

    this.subRadioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      style: { marginBottom: '8px' },
      onChange: this.onSubReasonChange,
    };

    const radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>>[] = [];

    if (this.props.isInvestigationReason) {
      ReasonsMap.forEach(({ value, label }) => {
        const radioValue = { enumValue: value, i18nKey: label };
        radioOptionsConfig.push({
          checked: this.state?.selectedReason ? isEqual(this.state.selectedReason, radioValue) : false,
          value: radioValue,
          label: i18n.t(label),
        });
      });
    } else {
      IncidentReasonsMap.forEach(({ value, label }) => {
        const radioValue = { enumValue: value, i18nKey: label };
        radioOptionsConfig.push({
          checked: this.state?.selectedReason ? isEqual(this.state.selectedReason, radioValue) : false,
          value: radioValue,
          label: i18n.t(label),
        });
      });
    }

    this.state = {
      selectedReason: {} as i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>,
      selectedSubReason: '',
      subReasonDescription: '',
      radioOptionsConfig,
      subRadioOptionsConfig: [],
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () => this.handleLanguageChanged());
  }

  handleLanguageChanged = () => {
    const { selectedReason } = this.state;
    this.buildRadioOptionsConfig();
    if (selectedReason) {
      this.buildSubRadioOptionsConfig();
    }
  };

  buildRadioOptionsConfig = (): void => {
    this.setState((prevState: Readonly<ReasonRadioGroupState>) => {
      let radioOptionsConfig = prevState.radioOptionsConfig;
      radioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value.i18nKey)));
      return { ...prevState, radioOptionsConfig };
    });
  };

  buildSubRadioOptionsConfig = (): void => {
    this.setState((prevState: Readonly<ReasonRadioGroupState>) => {
      let subRadioOptionsConfig = prevState.subRadioOptionsConfig;
      subRadioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value)));
      return { ...prevState, subRadioOptionsConfig };
    });
  };

  onReasonChange = (event: TypedRadioChangeEvent<i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>>) => {
    this.selectedReason = event.value.enumValue;
    let subReasons = Object.keys(event.value.enumValue).filter((key) => !+key && key !== '0');
    const subRadioOptionsConfig = subReasons.map((subReason) => {
      let subReasonData;
      if (this.props.isInvestigationReason) {
        subReasonData = SubReasonsMap.get(subReason);
      } else {
        subReasonData = IncidentSubReasonsMap.get(subReason);
      }
      if (subReasonData) {
        return {
          checked: false,
          defaultChecked: false,
          value: subReasonData,
          label: i18n.t(subReasonData),
        };
      } else {
        return { checked: false, defaultChecked: false, value: 'Unknown', label: i18n.t('dataDisplay.unknownValue') };
      }
    });

    this.props.changeCallback();
    this.setState({
      selectedReason: event.value,
      selectedSubReason: '',
      subRadioOptionsConfig,
    });
  };

  onSubReasonChange = (event: TypedRadioChangeEvent<string>) => {
    this.props.changeCallback();
    this.setState({ selectedSubReason: event.value });
  };

  canSaveData = () => {
    const { selectedReason, selectedSubReason, subReasonDescription } = this.state;
    if (selectedReason && selectedSubReason) {
      return selectedSubReason === 'reasons.other.other' ? subReasonDescription.length > 0 : true;
    }
    return false;
  };

  saveData = () => {
    const { selectedReason, selectedSubReason, subReasonDescription } = this.state;
    if (selectedReason) {
      let answer = selectedReason?.i18nKey;
      if (selectedSubReason === 'reasons.other.other') {
        answer = answer.concat(DELIMITER).concat(',').concat(DELIMITER).concat(subReasonDescription);
      } else {
        answer = answer.concat(DELIMITER).concat(',').concat(DELIMITER).concat(selectedSubReason);
      }
      this.props.save(answer);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  componentWillUnmount() {
    i18n.off('languageChanged', () => this.handleLanguageChanged());
  }

  render() {
    const { selectedReason, selectedSubReason, radioOptionsConfig, subRadioOptionsConfig } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup<i18nRadioGroupWrapper<ReasonsType | IncidentReasonsType>>
          {...this.radioGroupConfig}
          groupOptions={radioOptionsConfig}
        />
        {!isEmpty(selectedReason) && (
          <React.Fragment>
            <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
            <HasRadioGroup<string>
              {...this.subRadioGroupConfig}
              groupOptions={subRadioOptionsConfig}
              value={selectedSubReason}
            />
          </React.Fragment>
        )}
        {selectedSubReason === 'reasons.other.other' && (
          <HasTextArea
            rows={2}
            maxLength={TEXT_AREA_MAX_LENGTH}
            placeholder={i18n.t('investigation.reasonInput')}
            onChange={(event) => this.setState({ subReasonDescription: event.target.value })}
          />
        )}
        {this.canSaveData() && (
          <HasButton
            type="primary"
            size="small"
            htmlType="button"
            onClick={this.saveData}
            style={{ marginTop: '8px', alignSelf: 'flex-end' }}
          >
            {i18n.t('shared.save')}
          </HasButton>
        )}
      </div>
    );
  }
}

export default HasReasonRadioGroup;
