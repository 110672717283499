import React from 'react';
import { HasText } from '../..';
import { Incident } from '../../../models';
import { DescriptionProps, ErrorMessages, FORM_RESULT_KEYS } from '../../../shared';
import { displayPlaceholderIfEmpty, getFormResultValue, getLatestFormResultValue, isObjectEmpty } from '../../../utils';
import { HasAlert } from '../../atoms';
import { HasDescription } from '../../molecules';
import { HasMap } from '../map';
import i18n from '../../../i18n/config';

interface IncidentLocationProps {
  incident: Incident;
}

class HasIncidentLocationDescription extends React.Component<IncidentLocationProps, {}> {
  private getIncidentLocationConfig = (incident: Incident): DescriptionProps => {
    const { company } = incident;
    const divisionName = getLatestFormResultValue(incident.formResult, FORM_RESULT_KEYS.ORG_SELECTION);
    return {
      title: i18n.t('incidents.incidentLocation'),
      className: isObjectEmpty(this.getIncidentLocation()) ? 'd-flex flex-column flex-grow-1' : '',
      descriptionItems: [
        {
          label: i18n.t('shared.company'),
          content: (
            <HasText
              className={!company.name ? 'italic-text' : ''}
              content={company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`}
              strong
            />
          ),
        },
        {
          label: i18n.t('shared.division'),
          content: <HasText content={divisionName} strong />,
        },
        {
          label: i18n.t('incidents.additionalInfo'),
          content: (
            <HasText content={getLatestFormResultValue(incident.formResult, FORM_RESULT_KEYS.LOCATION_INFO)} strong />
          ),
        },
      ],
    };
  };

  getIncidentLocation = () => ({ lat: this.props.incident.lat, lng: this.props.incident.lng });

  render() {
    const validCoordinates = !isObjectEmpty(this.getIncidentLocation());
    return (
      <React.Fragment>
        <HasDescription {...this.getIncidentLocationConfig(this.props.incident)} />
        <div className={validCoordinates ? 'd-flex flex-column flex-grow-1 mt-2' : 'd-flex flex-column mt-2'}>
          {validCoordinates ? (
            <HasMap markerLocations={[this.getIncidentLocation()]} center={this.getIncidentLocation()} />
          ) : (
            <HasAlert {...ErrorMessages.INCIDENT_INVALID_COORDINATES} type="warning" showIcon />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HasIncidentLocationDescription;
