import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HasButton, HasDropdown, HasSpinner, HasText } from '../../components';
import i18n from '../../i18n/config';
import { Company } from '../../models';
import { CompanyService } from '../../services';
import { SelectValue } from 'antd/lib/select';

interface CompanySelectionProps {
  afterSelect: (company: Company) => void;
}

const HasCompanySelection: React.FC<CompanySelectionProps> = ({ afterSelect }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    CompanyService.getUserVisibleCompanies().then(({ data }) => {
      if (data.length > 0) {
        setCompany(data[0]);
      }
      setCompanyList(data);
      setLoading(false);
    });
  }, []);

  const saveCompany = () => {
    !isUndefined(company) && afterSelect(company);
  };

  return loading ? (
    <HasSpinner size="large" />
  ) : companyList.length > 0 ? (
    <>
      <HasText
        type="secondary"
        content={i18n.t('historicalEvents.companySelectInstruction')}
        className="mb-4 text-center"
        style={{ fontSize: '18px' }}
      />
      <HasDropdown<Company>
        options={companyList.map((company) => ({
          value: company.id,
          label: company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`,
          forObject: company,
          className: !company.name ? 'italic-text' : '',
        }))}
        defaultValue={companyList[0].id}
        onChange={(_, value) => setCompany(value.forObject)}
        style={{ minWidth: '200px' }}
        className="mb-4"
      />
      <HasButton type="primary" onClick={saveCompany} disabled={isUndefined(company)} style={{ minWidth: '200px' }}>
        {i18n.t('shared.done')}
      </HasButton>
    </>
  ) : (
    <HasText
      type="secondary"
      content={i18n.t('report.noAvailableCompanies')}
      className="mb-4 text-center"
      style={{ fontSize: '18px' }}
    />
  );
};

export { HasCompanySelection };
