import React, { ReactNode } from 'react';
import i18n from '../../../i18n/config';
import {
  BulletListParagraph,
  SectionedListParagraph,
  TermsAndConditionsSection,
  TERMS_AND_CONDITIONS,
  TextParagraph,
} from '../../../shared';
import { HasModal, HasParagraph, HasSpinner, HasTitle } from '../../atoms';

interface TermsAndConditionsProps {
  visible: boolean;
  onAccept: () => void;
  onReject: () => void;
}

interface TermsAndConditionsState {
  termsAndConditions: TermsAndConditionsSection[] | null;
}

class HasTermsAndConditions extends React.Component<TermsAndConditionsProps, TermsAndConditionsState> {
  state: TermsAndConditionsState = { termsAndConditions: null };

  componentDidMount() {
    const language = i18n.language;
    let termsAndConditions = TERMS_AND_CONDITIONS[language];
    if (!termsAndConditions) {
      termsAndConditions = TERMS_AND_CONDITIONS['en'];
    }
    this.setState({ termsAndConditions });
  }

  renderParagraphBulletList = (listItems: (TextParagraph | BulletListParagraph)[], depth: number): ReactNode => {
    return listItems.map((listItem, index) => {
      switch (typeof listItem) {
        case 'string':
          return (
            <li key={index} style={{ paddingLeft: `${depth * 2}` }}>
              {listItem}
            </li>
          );
        case 'object':
          return (
            <li key={index}>
              {listItem.header}
              <ul>{this.renderParagraphBulletList(listItem.listItems, depth + 1)}</ul>
            </li>
          );
        default:
          return null;
      }
    });
  };

  renderSectionList = (
    listItems: (TextParagraph | BulletListParagraph)[],
    number: number,
    depth: number
  ): ReactNode => {
    return listItems.map((listItem, index) => {
      const subSectionNumber = number + '.' + (index + 1);
      switch (typeof listItem) {
        case 'string':
          return <HasParagraph key={index} content={subSectionNumber.concat(' ').concat(listItem)} />;
        case 'object':
          return (
            <div key={index} style={{ marginBottom: '14px' }}>
              <HasParagraph content={subSectionNumber.concat(' ').concat(listItem.header)} />
              <ul>{this.renderParagraphBulletList(listItem.listItems, depth + 1)}</ul>
            </div>
          );
        default:
          return null;
      }
    });
  };

  renderSectionParagraphs = (section: TermsAndConditionsSection): ReactNode => {
    const renderedSections = section.paragraphs.map((paragraph, index) => {
      switch (typeof paragraph) {
        case 'string':
          return <HasParagraph key={index} content={paragraph} />;
        case 'object':
          if ((paragraph as SectionedListParagraph).number) {
            const section = paragraph as SectionedListParagraph;
            return (
              <li key={index}>
                <HasParagraph content={section.header} style={{ fontWeight: 600 }} />
                {this.renderSectionList(section.listItems, section.number, 0)}
              </li>
            );
          } else {
            return (
              <div key={index}>
                <HasParagraph content={paragraph.header} />
                <ul>{this.renderParagraphBulletList(paragraph.listItems, 0)}</ul>
              </div>
            );
          }
        default:
          return null;
      }
    });
    if (this.doesHaveOrderedSections(section)) {
      return <ol style={{ listStyleType: 'upper-roman' }}>{renderedSections}</ol>;
    } else {
      return renderedSections;
    }
  };

  doesHaveOrderedSections = (section: TermsAndConditionsSection) => {
    return section.paragraphs.some((paragraph) => (paragraph as SectionedListParagraph).number !== undefined || null);
  };

  renderTermsAndConditions = (termsAndConditions: TermsAndConditionsSection[]) => {
    return termsAndConditions.map((section, index) => (
      <div key={index}>
        <HasTitle content={section.title} level={4} style={{ fontSize: '18px' }} />
        {this.renderSectionParagraphs(section)}
      </div>
    ));
  };

  render() {
    const { visible, onAccept, onReject } = this.props;
    const { termsAndConditions } = this.state;
    return (
      <HasModal
        title={i18n.t('termsConditions.termsAndConditions')}
        okText={i18n.t('termsConditions.accept')}
        cancelText={i18n.t('termsConditions.reject')}
        onOk={onAccept}
        onCancel={onReject}
        visible={visible}
        bodyStyle={{ maxHeight: '60vh', overflowY: 'scroll' }}
        width={'50vw'}
      >
        <div style={{ padding: '40px' }}>
          {termsAndConditions ? this.renderTermsAndConditions(termsAndConditions) : <HasSpinner size="large" />}
        </div>
      </HasModal>
    );
  }
}

export default HasTermsAndConditions;
