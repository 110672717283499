import { AuthService, CompanyService } from '../services';
import { ErrorMessages, GuardPromise, RouteConfig } from '../shared';
import { getTokenFromCookie, getUserRole, isTokenExpired } from '../utils';

export const authGuard = (): Promise<GuardPromise> => {
  return new Promise<GuardPromise>(async (resolve, reject) => {
    if (isTokenExpired()) {
      if (getTokenFromCookie()) {
        await AuthService.logout();
      }
      sessionStorage.clear();
      CompanyService.clearSelectedCompanyListener();
      reject({ redirectToLogin: true, message: ErrorMessages.SESSION_INVALID() });
    } else {
      resolve();
    }
  });
};

export const roleGuard = (route: RouteConfig): Promise<GuardPromise> => {
  return new Promise<GuardPromise>((resolve, reject) => {
    if (route.forRole) {
      const userRole = getUserRole();
      if (!userRole || !route.forRole.includes(userRole)) {
        reject({ redirectToLogin: false, message: ErrorMessages.ROLE_NO_ACCESS });
      }
    }
    resolve();
  });
};
