import { Language } from '../shared';
import { UserService } from '.';

class LanguageService {
  languageChanged(language: Language) {
    UserService.languageChanged(language.id);
  }
}

export default new LanguageService();
