import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { CSSProperties } from 'react';
import { COLORS } from '../../../shared';

notification.config({
  placement: 'topRight',
  duration: 5,
});

const infoStyle: CSSProperties = { backgroundColor: COLORS.NOTIFICATION_INFO, border: '1px solid #91d5ff' };
const successStyle: CSSProperties = { backgroundColor: COLORS.NOTIFICATION_SUCCESS, border: '1px solid #b7eb8f' };
const warningStyle: CSSProperties = { backgroundColor: COLORS.NOTIFICATION_WARNING, border: '1px solid #ffe58f' };
const errorStyle: CSSProperties = { backgroundColor: COLORS.NOTIFICATION_ERROR, border: '1px solid #ffa39e' };

export const Notification = {
  info: (args: ArgsProps) => notification.info({ ...args, style: { ...args.style, ...infoStyle } }),
  success: (args: ArgsProps) => notification.success({ ...args, style: { ...args.style, ...successStyle } }),
  warning: (args: ArgsProps) => notification.warning({ ...args, style: { ...args.style, ...warningStyle } }),
  error: (args: ArgsProps) => notification.error({ ...args, duration: 10, style: { ...args.style, ...errorStyle } }),
};
