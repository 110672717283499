import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Row, Tabs } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdPersonAdd } from 'react-icons/md';
import { Link, RouteComponentProps } from 'react-router-dom';
import { HasButton, HasText, HasTextInput } from '../..';
import { UserRole } from '../../../models';
import { AuthService, UserService } from '../../../services';
import { AUTHORIZATION, COLORS, ErrorMessages, SuccessMessages, WEB_ACCEPTED_ROLES } from '../../../shared';
import { getUserRole, isObjectEmpty, setCookie } from '../../../utils';
import { HasAlert, HasImage } from '../../atoms';
import HasLanguageFlagPopover from '../../molecules/data-entry/language-flag.popover';
import HasSignUp from './signup';
import { PermissionsContext } from '../../../context';

interface LoginProps extends RouteComponentProps<{}, {}, { sessionError: string }>, WithTranslation {}

interface LoginState {
  loginError: string | null;
  forgotPasswordOutcome: AlertProps;
  sessionError: string | null;
  loading: boolean;
  activeTabKey: string;
}

const LOGIN_PAGE_KEY = 'login';
const FROGOT_PASSWORD_PAGE_KEY = 'forgotPassword';
const SIGN_UP_PAGE_KEY = 'register';

class HasLogin extends React.Component<LoginProps, LoginState> {
  state = {
    loginError: null,
    forgotPasswordOutcome: {} as AlertProps,
    sessionError: null,
    loading: false,
    activeTabKey: LOGIN_PAGE_KEY,
  };

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.sessionError) {
      this.setState({ sessionError: location.state.sessionError });
      window.history.pushState(null, '');
    }
    this.setState({ activeTabKey: this.getActiveTab() });
  }

  componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<{}, {}, { sessionError: string }>>,
    prevState: LoginState
  ) {
    const newActiveTab = this.getActiveTab();
    if (prevState.activeTabKey !== newActiveTab) {
      this.setState({ activeTabKey: newActiveTab });
    }
  }

  getActiveTab = (): string => {
    const { pathname } = window.location;
    switch (true) {
      case pathname.includes('/login'):
        return LOGIN_PAGE_KEY;
      case pathname.includes('/recover'):
        return FROGOT_PASSWORD_PAGE_KEY;
      case pathname.includes('/register'):
        return SIGN_UP_PAGE_KEY;
      default:
        return LOGIN_PAGE_KEY;
    }
  };

  loginFailed = (loginError: string) => this.setState({ loginError, loading: false });

  checkUserRole = (token: string): boolean => {
    const role = getUserRole(token);
    if (!role) {
      return false;
    }
    return WEB_ACCEPTED_ROLES.includes(role as UserRole);
  };

  handleLogin = (values: any) => {
    const { t } = this.props;
    const { setPermissionsForUser } = this.context;
    this.setState({ loading: true });
    AuthService.login({ ...values })
      .then(
        (response) => {
          this.setState({ loading: false });
          setPermissionsForUser();
          const token = response.headers[AUTHORIZATION] || response.headers.Authorization;
          if (token) {
            setCookie(token);
            if (this.checkUserRole(token)) {
              this.props.history.push('/home');
            } else {
              this.loginFailed(ErrorMessages.LOGIN_ROLE_NOT_PERMITTED);
            }
          } else {
            this.loginFailed(ErrorMessages.LOGIN_MISSING_TOKEN);
          }
        },
        (error) => {
          if (!error.response) {
            this.loginFailed(ErrorMessages.LOGIN_SERVER_OFFLINE);
          } else {
            this.loginFailed(error.response?.data.message);
          }
        }
      )
      .catch((reason) => {
        this.loginFailed(`${t('fallback.errorOccurred')}: ${reason}`);
      });
  };

  handlePasswordRecovery = (values: any) => {
    this.setState({ loading: true });
    UserService.forgotPassword(values.email)
      .then(
        () =>
          this.setState({
            loading: false,
            forgotPasswordOutcome: {
              type: 'success',
              ...SuccessMessages.FORGOT_PASSWORD,
            },
          }),
        (error) =>
          this.setState({
            loading: false,
            forgotPasswordOutcome: {
              type: 'error',
              ...ErrorMessages.FORGOT_PASSWORD(error.response?.data.message),
            },
          })
      )
      .catch((error) =>
        this.setState({
          loading: false,
          forgotPasswordOutcome: {
            type: 'error',
            ...ErrorMessages.FORGOT_PASSWORD_UNEXPECTED(error.response?.data.message),
          },
        })
      );
  };

  loginSelected = () => {
    window.history.pushState(null, '', '/login');
    this.setState({ activeTabKey: LOGIN_PAGE_KEY, loginError: null });
  };

  passwordRecoverySelected = () => {
    window.history.pushState(null, '', '/recover');
    this.setState({
      activeTabKey: FROGOT_PASSWORD_PAGE_KEY,
      forgotPasswordOutcome: {} as AlertProps,
    });
  };

  signUpSelected = () => {
    window.history.pushState(null, '', '/register');
    this.setState({ activeTabKey: SIGN_UP_PAGE_KEY });
  };

  render() {
    const { t } = this.props;
    const { loading, loginError, forgotPasswordOutcome, sessionError, activeTabKey } = this.state;
    return (
      <Tabs
        renderTabBar={() => (
          <Row align={'middle'} justify={'center'} className="has-login-header">
            <Col span={24} style={{ textAlign: 'center' }}>
              <HasImage
                src={require('../../../assets/images/logo_h_boxed.svg')}
                width={'60%'}
                style={{ maxWidth: '300px', marginBottom: '10px' }}
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <HasText type="secondary" content={t('shared.slogan')} style={{ fontSize: '20px' }} />
            </Col>
            <Col span={24} style={{ width: '100%' }}>
              <Divider style={{ marginBottom: '10px' }} />
            </Col>
            {sessionError && !loginError && window.location.pathname.includes('/login') && (
              <Col span={24} className="has-session-error">
                <HasAlert type="error" {...sessionError} closable />
              </Col>
            )}
            {loginError && (
              <Col span={24} className="has-session-error">
                <HasAlert type="error" message={loginError} />
              </Col>
            )}
          </Row>
        )}
        activeKey={activeTabKey}
        className="has-login-tabs"
      >
        <Tabs.TabPane tab={LOGIN_PAGE_KEY} key={LOGIN_PAGE_KEY} className="d-flex justify-content-center">
          <Form onFinish={this.handleLogin} hideRequiredMark={true} layout={'vertical'} className="has-login-form">
            <Form.Item
              name="email"
              label={t('shared.email')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.email').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.email').toLowerCase()),
                },
              ]}
            >
              <HasTextInput
                size="large"
                prefix={<UserOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />}
                autoComplete="username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t('shared.password')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.password').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.password').toLowerCase()),
                },
              ]}
            >
              <HasTextInput
                type="password"
                size="large"
                prefix={<LockOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />}
                autoComplete="current-password"
              />
            </Form.Item>
            <Form.Item style={{ paddingTop: '20px' }}>
              <HasButton type="primary" block htmlType="submit" size="large" icon={<LoginOutlined />} loading={loading}>
                {t('shared.login')}
              </HasButton>
              <HasButton
                block
                type="link"
                htmlType="button"
                size="large"
                onClick={this.passwordRecoverySelected}
                className="float-right pr-0"
                style={{ fontSize: '14px' }}
              >
                {t('login.forgotPassword')}
              </HasButton>
            </Form.Item>
            <Divider style={{ fontSize: '14px', fontWeight: 'normal' }}>
              <HasText content={t('login.noAccount')} />
            </Divider>
            <Form.Item className="text-center">
              <HasButton
                block
                type="link"
                size="large"
                icon={<MdPersonAdd style={{ marginRight: '5px', fontSize: '18px' }} />}
                onClick={this.signUpSelected}
              >
                {t('shared.register')}
              </HasButton>
            </Form.Item>
            <div className="d-flex flex-row justify-content-center mb-3">
              <HasLanguageFlagPopover />
            </div>
            <div className="text-center">
              <HasText content={t('login.anyQuestions')}>
                <Link to="/contact">
                  <HasButton
                    type="link"
                    size="large"
                    style={{
                      fontSize: '14px',
                      paddingLeft: '5px',
                      minWidth: 0,
                    }}
                  >
                    {t('login.contactUs')}
                  </HasButton>
                </Link>
              </HasText>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={FROGOT_PASSWORD_PAGE_KEY}
          key={FROGOT_PASSWORD_PAGE_KEY}
          className="d-flex justify-content-center"
        >
          <Form
            onFinish={this.handlePasswordRecovery}
            hideRequiredMark={true}
            layout={'vertical'}
            className="has-login-form"
          >
            {!isObjectEmpty(forgotPasswordOutcome) && (
              <Form.Item>
                <HasAlert {...forgotPasswordOutcome} className="has-session-error" />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label={t('shared.email')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.email').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(t('shared.email').toLowerCase()),
                },
                { type: 'email', message: ErrorMessages.EMAIL_INVALID },
              ]}
              validateTrigger="onBlur"
            >
              <HasTextInput size="large" prefix={<UserOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />} />
            </Form.Item>
            <Form.Item>
              <HasButton type="primary" block htmlType="submit" size="large" loading={loading}>
                {t('login.submit')}
              </HasButton>
            </Form.Item>
            <Form.Item>
              <Divider style={{ fontSize: '14px', fontWeight: 'normal' }}>
                <HasText content={t('shared.hasAccount')} />
              </Divider>
              <HasButton
                block
                type="link"
                htmlType="button"
                size="large"
                icon={<LoginOutlined />}
                onClick={this.loginSelected}
                className="float-right pr-0"
              >
                {t('shared.login')}
              </HasButton>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={SIGN_UP_PAGE_KEY} key={SIGN_UP_PAGE_KEY} className="d-flex justify-content-center">
          <HasSignUp backToLoginCallback={this.loginSelected} />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

HasLogin.contextType = PermissionsContext;

export default withTranslation()(HasLogin);
