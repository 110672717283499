import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { httpClient } from '.';
import { Photo } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { EntityAction, RestService, URL } from './rest.service';
import { FileIsFor } from '../models/file.model';

class PhotoService implements RestService<Photo> {
  private photoChangedSubject = new Subject<SubscriberEvent<Photo>>();

  get(params?: any): Promise<AxiosResponse<Photo>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Photo[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Photo>> {
    return httpClient.get<Photo>(buildUrl(URL.PHOTO, [resourceId]));
  }

  getByIds(photoIds: number[]): Promise<AxiosResponse<Photo[]>> {
    let params = { photoIds };
    return httpClient.post<Photo[]>(buildUrl(URL.PHOTO, ['byIds']), photoIds);
  }

  getPreviewById(resourceId: string | number, params?: any): Promise<AxiosResponse<Photo>> {
    return httpClient.get<Photo>(buildUrl(URL.PHOTO, [resourceId, 'preview']));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Photo>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Photo>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Photo): Promise<AxiosResponse<Photo>> {
    throw new Error('Method not implemented.');
  }

  update(resource: Photo): Promise<AxiosResponse<Photo>> {
    throw new Error('Method not implemented.');
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Photo>> {
    return httpClient.delete<Photo>(buildUrl(URL.PHOTO, [resourceId]));
  }

  uploadPhoto(
    isFor: FileIsFor,
    incidentId: number | string,
    isPrivate: boolean,
    formData: FormData
  ): Promise<AxiosResponse<null>> {
    const baseRoute = 'addPhotos' + (isPrivate ? '/private' : '');
    switch (isFor) {
      case FileIsFor.INVESTIGATION:
        return httpClient.post<null>(buildUrl(URL.PHOTO, [baseRoute, 'forInvestigation', incidentId]), formData);
      case FileIsFor.REVIEW:
        return httpClient.post<null>(buildUrl(URL.PHOTO, [baseRoute, 'forReview', incidentId]), formData);
      case FileIsFor.ACTION:
        return httpClient.post<null>(buildUrl(URL.PHOTO, [baseRoute, 'forActions', incidentId]), formData);
      case FileIsFor.INCIDENT:
      default:
        return httpClient.post<null>(buildUrl(URL.PHOTO, [baseRoute, incidentId]), formData);
    }
  }

  setPrivate(id: number, newStatus: boolean): Promise<AxiosResponse<null>> {
    return httpClient.post<null>(buildUrl(URL.PHOTO, ['private', newStatus ? 'true' : 'false', id]));
  }

  // SUBSCRIPTIONS

  getPhotoChangedListener(): Observable<SubscriberEvent<Photo>> {
    return this.photoChangedSubject.asObservable();
  }

  photoChanged(event: EntityAction, attachment: Photo) {
    this.photoChangedSubject.next({ event: event, entity: attachment });
  }
}

export default new PhotoService();
