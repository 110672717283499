import { Col, Divider, Row } from 'antd';
import React from 'react';
import { DescriptionProps } from '../../../shared';
import { HasText } from '../../atoms';

interface OwnDescriptionProps extends DescriptionProps {}

class HasDescription extends React.Component<OwnDescriptionProps, {}> {
  render() {
    const { title, className, descriptionItems } = this.props;
    return (
      <Col span={24} className={className} style={{ flex: '0 1 auto' }}>
        <div className={'ant-descriptions-title'}>{title}</div>
        {descriptionItems.map((config, index) => (
          <React.Fragment key={index}>
            <Row className="flex-grow-1 align-items-center has-row-max-height">
              <Col span={12}>
                <HasText content={config.label} style={{ fontSize: 12 }} />
              </Col>
              <Col span={12} style={{ flexGrow: 0 }}>
                <HasText content={config.content} style={{ fontSize: 12 }} />
              </Col>
            </Row>
            {index !== descriptionItems.length - 1 && <Divider style={{ margin: '0px' }} />}
          </React.Fragment>
        ))}
      </Col>
    );
  }
}

export default HasDescription;
