import { AppstoreOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionsContext, PermissionsContextType } from '../../../context';
import { Module, UserRole } from '../../../models';
import { getUserRole } from '../../../utils';
import './app-switch.scss';

const AppSwitch = () => {
  const { t } = useTranslation();

  const { companySubscriptionMap, getCompanySubscriptions } =
    React.useContext<PermissionsContextType>(PermissionsContext);

  const userRole = getUserRole();

  useEffect(() => {
    getCompanySubscriptions();
  }, []);

  const hasAccessToModule = (targetSubscription: Module) => {
    if (!companySubscriptionMap) {
      return false;
    }

    return Object.values(companySubscriptionMap).some((companySubscriptions) =>
      companySubscriptions.some((subscription) => subscription.module === targetSubscription)
    );
  };

  const handleRouteClick = (param: ClickParam) => {
    const { key } = param;
    if (!key) {
      return;
    }
    window.location.href = key;
  };

  return (
    <Menu theme="dark" onClick={handleRouteClick}>
      <SubMenu title={<AppstoreOutlined className="icon" />}>
        <Menu.ItemGroup title={t('sideNav.switchTo', 'Switch to')}>
          <Menu.Item key={'home'} className="menu-item">
            {t('sideNav.healthAndSafety', 'Health and Safety')}
          </Menu.Item>
          {hasAccessToModule(Module.EQUIPMENTS) && (
            <Menu.Item key={process.env.REACT_APP_EQUIPMENT_URL} className="menu-item">
              {t('sideNav.equipmentManagement', 'Equipment management')}
            </Menu.Item>
          )}
          {hasAccessToModule(Module.RISK_ASSESSMENTS) && (
            <Menu.Item key={process.env.REACT_APP_RISK_ASSESSMENTS_URL} className="menu-item">
              {t('sideNav.riskAssessments', 'Risk assessments')}
            </Menu.Item>
          )}
          {(hasAccessToModule(Module.RISK_ASSESSMENTS) || hasAccessToModule(Module.EQUIPMENTS)) && (
            <Menu.Item key={process.env.REACT_APP_CHATBOT_URL} className="menu-item">
              {t('sideNav.chatbotBuilder', 'Chatbot builder')}
            </Menu.Item>
          )}
          {userRole && [UserRole.OWNER, UserRole.SUPER_ADMIN].includes(userRole as UserRole) && (
            <Menu.Item key={process.env.REACT_APP_PERMISSIONS_URL} className="menu-item">
              {t('sideNav.permissions', 'Permissions')}
            </Menu.Item>
          )}
          <Menu.Item key={process.env.REACT_APP_DOCUMENTS_URL} className="menu-item">
            {t('sideNav.documents', 'Document library')}
          </Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>
    </Menu>
  );
};

// const Logo = () => (
//   <div style={{ height: '64px' }} className="d-flex align-items-center justify-content-center">
//     <div
//       style={{
//         backgroundColor: COLORS.WHITE,
//         borderRadius: '10px',
//         width: '48px',
//         height: '48px',
//       }}
//     >
//       <HasImage
//         src={require('../../../assets/images/logo_v_unboxed.svg')}
//         style={{ width: '48px', height: '48px', padding: '6px' }}
//       />
//     </div>
//   </div>
// );

export { AppSwitch };
