import { AxiosResponse } from 'axios';
import { ActionComplianceForm } from '../models';
import { URL } from './rest.service';
import { httpClient } from './http.client';
import { buildUrl } from '../utils';

class ActionComplianceFormService {
  getByActionId(actionId: string | number): Promise<AxiosResponse<ActionComplianceForm>> {
    return httpClient.get<ActionComplianceForm>(buildUrl(URL.ACTION_COMPLIANCE_FORM, ['byAction', actionId]));
  }

  create(resource: ActionComplianceForm, actionId: string | number): Promise<AxiosResponse<ActionComplianceForm>> {
    return httpClient.post<ActionComplianceForm>(buildUrl(URL.ACTION_COMPLIANCE_FORM, [actionId]), resource);
  }

  update(resource: ActionComplianceForm, actionId: string | number): Promise<AxiosResponse<ActionComplianceForm>> {
    return httpClient.put<ActionComplianceForm>(buildUrl(URL.ACTION_COMPLIANCE_FORM, [actionId]), resource);
  }
}

export default new ActionComplianceFormService();
