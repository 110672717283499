import { User, UserRole } from '../models';
import { TOKEN_KEY } from '../shared';

var jwtDecode = require('jwt-decode');

export const buildUrl = (url: string, subRoutes: (string | number | undefined)[]): string => {
  return url
    .concat('/')
    .concat(
      ...subRoutes.map((route, index) =>
        route
          ? index === subRoutes.length - 1
            ? route.toString()
            : subRoutes[index + 1]
            ? route.toString().concat('/')
            : route.toString()
          : ''
      )
    );
};

export const getTokenFromCookie = (): string => {
  return document.cookie
    .split('; ')
    .find((r) => r.startsWith('token='))
    ?.split('=')[1]!;
};

export const clearToken = () => {
  document.cookie = `${TOKEN_KEY}=; expires=${new Date(+0)}; path=/;  ${
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? 'SameSite=None; Secure' : ''
  }`;
};

export const isTokenExpired = (): boolean | void => {
  const token = getTokenFromCookie();
  if (token) {
    const decoded = decodeToken();
    return !decoded ? true : decoded.exp.valueOf() > new Date().valueOf();
  } else {
    return true;
  }
};

export const getUserRole = (token?: string): string | null => {
  const decoded = decodeToken(token);
  return decoded ? decoded.RoleKy : null;
};

export const getUserId = (token?: string): number | null => {
  const decoded = decodeToken(token);
  return decoded ? decoded.IdKy : null;
};

export const getUserDivisionId = (token?: string): number | null => {
  const decoded = decodeToken(token);
  return decoded ? decoded.DivisionKy : null;
};

export const isUserRoleEqualsTo = (userRole: UserRole, user?: User) => {
  if (user) {
    return user.role === userRole;
  } else {
    const role = getUserRole();
    return !role ? false : role === userRole;
  }
};

const decodeToken = (token?: string): any | null => {
  let decoded;
  try {
    decoded = jwtDecode(token ? token : getTokenFromCookie());
  } catch (error) {
    return null;
  }
  return decoded;
};

export const getTokenExpirationTime = (token: string) => {
  const decoded = decodeToken(token);
  return decoded.exp.valueOf();
};

export const setCookie = (token: string) => {
  const time = getTokenExpirationTime(token);
  var cookieExpirationTime = new Date(0);
  cookieExpirationTime.setUTCSeconds(time);
  document.cookie = `${TOKEN_KEY}=${token}; expires=${cookieExpirationTime}; path=/; ${
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? 'SameSite=None; Secure' : ''
  }`;
};
