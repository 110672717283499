export interface Permission {
  id: number;
  permissionType: PermissionType;
}

export enum PermissionType {
  INCIDENTS_TYPE_SWAP = 'INCIDENTS_TYPE_SWAP',
  INCIDENTS_CHANGE_ANSWERS = 'INCIDENTS_CHANGE_ANSWERS',
  INVESTIGATIONS_CREATE = 'INVESTIGATIONS_CREATE',
  INVESTIGATIONS_EDIT = 'INVESTIGATIONS_EDIT',
  INVESTIGATIONS_CLOSE = 'INVESTIGATIONS_CLOSE',
  INVESTIGATIONS_MARK_AS_PRIVATE_OR_PUBLIC = 'INVESTIGATIONS_MARK_AS_PRIVATE_OR_PUBLIC',
  ACTIONS_CREATE = 'ACTIONS_CREATE',
  ACTIONS_EDIT = 'ACTIONS_EDIT',
  ACTIONS_ASSIGN = 'ACTIONS_ASSIGN',
  ACTIONS_CHANGE_STATUS = 'ACTIONS_CHANGE_STATUS',
}
