import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import Table, { ColumnProps } from 'antd/lib/table';
import { get, isEqual } from 'lodash';
import React from 'react';
import { TABLE_PAGE_SIZE } from '../../../utils';

interface OwnTableProps<T> extends Omit<TableProps<T>, 'columns' | 'pagination' | 'scroll' | 'dataSource'> {
  columns: ColumnProps<T>[] | ColumnsType<T>;
  data: T[];
  tableItemHeight: number;
  tableHeaderHeight: number;
  tablePaginationHeight?: number;
  useScroll?: boolean;
  horizontalScroll?: true | number | string;
  defaultPageNumber?: number;
  total?: number;
  onPageSizeChange?: (size: number) => void;
  isApiPaginated?: boolean;
}

class HasTable<T extends object> extends React.Component<OwnTableProps<T>> {
  private tableContainerRef = React.createRef<HTMLDivElement>();

  getPaginationConfig = (): false | TablePaginationConfig => {
    const { useScroll, total, isApiPaginated } = this.props;
    if (useScroll) {
      return false;
    } else {
      const basePaginationConfig = { pageSize: TABLE_PAGE_SIZE, total };
      if (isApiPaginated) {
        return {
          ...basePaginationConfig,
          current: get(this.props, 'defaultPageNumber', 1),
          position: 'both',
        };
      } else {
        return {
          ...basePaginationConfig,
          defaultCurrent: get(this.props, 'defaultPageNumber', 1),
          position: 'both',
        };
      }
    }
  };

  getScrollConfig = (): undefined | { x?: number | true | string; y?: number | string } => {
    const { useScroll, horizontalScroll, tableItemHeight } = this.props;
    if (!useScroll) {
      return horizontalScroll ? { x: horizontalScroll } : undefined;
    } else {
      return { y: TABLE_PAGE_SIZE * tableItemHeight, x: horizontalScroll };
    }
  };

  render() {
    const { columns, data, tableItemHeight, useScroll, ...rest } = this.props;
    return (
      <div ref={this.tableContainerRef} className="d-flex flex-column flex-grow-1 mh-0">
        <Table
          {...rest}
          columns={columns}
          dataSource={data}
          pagination={this.getPaginationConfig()}
          scroll={{ ...this.getScrollConfig() }}
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'scroll' }}
        />
      </div>
    );
  }
}

export default HasTable;
