import { Col } from 'antd';
import React from 'react';
import { Incident } from '../../../models';
import { getLatestFormResultValue } from '../../../utils';
import { HasText } from '../../atoms';
import i18n from '../../../i18n/config';
import { FORM_RESULT_KEYS } from '../../../shared';

interface InvolvedPartyProps {
  incident: Incident;
}

class HasInvolvedPartiesDescription extends React.Component<InvolvedPartyProps, {}> {
  getInvolvedParties = (): string => {
    const { incident } = this.props;
    return getLatestFormResultValue(incident.formResult, FORM_RESULT_KEYS.PARTIES_INVOLVED);
  };

  render() {
    return (
      <Col span={24} className="d-flex flex-column flex-grow-1" style={{ flex: '0 1 auto' }}>
        <div className="ant-descriptions-title">{i18n.t('incidents.involvedParties')}</div>
        <div>
          <HasText content={this.getInvolvedParties()} style={{ fontSize: '12px' }} strong />
        </div>
      </Col>
    );
  }
}

export default HasInvolvedPartiesDescription;
