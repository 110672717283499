import { Menu, Tooltip } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MenuItemWithKey, SubMenuType } from '../../../shared';

interface ItemProps {
  config: MenuItemWithKey;
}

class HasMenuItem extends React.Component<ItemProps, {}> {
  getMenuItemLink = (path: string): ReactNode =>
    !['/logout', '/new-report', '/equipment', '/permissions'].includes(path) ? <Link to={path} /> : null;

  buildSubMenu = (icon: ReactNode, subMenuConfig: SubMenuType): ReactNode => {
    const { config, ...menuItemProps } = this.props;
    return (
      <SubMenu
        {...menuItemProps}
        title={
          <>
            {icon}
            <span>{subMenuConfig.title}</span>
          </>
        }
      >
        {subMenuConfig.subMenuItems.map((child) => (
          <Tooltip title={child.tooltip}>
            <Menu.Item {...menuItemProps} {...child}>
              {child.icon}
              <span>{child.title}</span>
              {this.getMenuItemLink(child.key)}
            </Menu.Item>
          </Tooltip>
        ))}
      </SubMenu>
    );
  };

  render() {
    const {
      config: { icon, title, key, subMenu, disabled, tooltip },
      ...menuItemProps
    } = this.props;
    return subMenu ? (
      this.buildSubMenu(icon, subMenu)
    ) : (
      <Tooltip title={tooltip}>
        <Menu.Item {...menuItemProps} disabled={disabled}>
          {icon}
          <span>{title}</span>
          {this.getMenuItemLink(key)}
        </Menu.Item>
      </Tooltip>
    );
  }
}

export default HasMenuItem;
