import { List } from 'antd';
import React from 'react';
import { ListItemWithMetaProps } from '../../../shared';

interface OwnListItemProps {
  itemConfig: ListItemWithMetaProps;
  interactive?: boolean;
  active?: boolean;
}

class HasListItem extends React.Component<OwnListItemProps, {}> {
  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.itemConfig.onRowClick) {
      this.props.itemConfig.onRowClick(event);
    }
  };

  getClassName = (): string | undefined => {
    const {
      interactive,
      active,
      itemConfig: { item },
    } = this.props;
    let className = item.className;
    if (interactive) {
      className += ' list-item-interactive ';
    }
    if (active) {
      className += ' active ';
    }
    return className;
  };

  render() {
    const {
      itemConfig: { item, meta },
    } = this.props;
    return (
      <List.Item
        extra={item.extra}
        actions={item.actions}
        style={item.style}
        className={this.getClassName()}
        onClick={this.handleClick}
      >
        <List.Item.Meta
          avatar={meta.avatar}
          title={meta.title}
          description={meta.description}
          style={{ alignItems: 'center' }}
        />
        {this.props.children}
      </List.Item>
    );
  }
}

export default HasListItem;
