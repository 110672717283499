import { Tag, Tooltip } from 'antd';
import React from 'react';
import { RiskLevel, RiskLevelLabel } from '../../../models';
import i18n from '../../../i18n/config';
import { COLORS, RISK_LEVEL_COLOR } from '../../../shared';
import { useTranslation } from 'react-i18next';
interface RiskScoreTagProps {
  riskScore: number | undefined;
  riskLevel: string | undefined;
}

const RiskScoreTag: React.FC<RiskScoreTagProps> = ({ riskScore, riskLevel }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        riskScore
          ? RiskLevelLabel?.[riskLevel as RiskLevel](t)
          : i18n.t('riskAssessment.noRiskAssigned', 'No risk level assigned')
      }
      overlayStyle={{ fontSize: 12, fontWeight: 500 }}
    >
      {riskScore ? (
        <Tag
          color={RISK_LEVEL_COLOR[riskLevel as RiskLevel]}
          style={{
            borderRadius: '4px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ color: COLORS.WHITE, fontWeight: 600 }}>{riskScore}</div>
        </Tag>
      ) : (
        <div>-</div>
      )}
    </Tooltip>
  );
};

export { RiskScoreTag };
