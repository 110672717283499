import React from 'react';
import { User, UserRole, UserStatus } from '../../../models';
import { isUserRoleEqualsTo } from '../../../utils';
import { MenuItemWithKey, SETTINGS_ACTIONS } from '../../../shared';
import i18n from '../../../i18n/config';
import { Dropdown, Menu } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { MoreOutlined } from '@ant-design/icons';

interface DropdownMenuProps {
  user: User;
  actionSelected: (clickParam: ClickParam, user: User) => void;
}

interface DropdownMenuState {}

class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
  constructor(props: DropdownMenuProps) {
    super(props);
  }

  getActionsMenu = () => {
    const { user } = this.props;
    const isGuest = isUserRoleEqualsTo(UserRole.GUEST, user);
    const dropdownMenuConfig: MenuItemWithKey[] = [];

    if (!user.deleted) {
      dropdownMenuConfig.push({ key: SETTINGS_ACTIONS.USER_CHANGE_PASS, title: i18n.t('shared.changePassword') });
      if (!isUserRoleEqualsTo(UserRole.ADMIN)) {
        dropdownMenuConfig.push({ key: SETTINGS_ACTIONS.USER_REMOVE, title: i18n.t('settings.remove') });
      }
      if (isUserRoleEqualsTo(UserRole.OWNER)) {
        dropdownMenuConfig.push({
          key: SETTINGS_ACTIONS.USER_REMOVE_ANON,
          title: i18n.t('settings.removeAndAnonymize.menuItem', 'Remove and anonymize'),
        });
      }

      if (!isGuest) {
        dropdownMenuConfig.splice(0, 0, { key: SETTINGS_ACTIONS.USER_EDIT, title: i18n.t('shared.edit') });
        dropdownMenuConfig.splice(2, 0, {
          key: SETTINGS_ACTIONS.USER_CHANGE_STATUS,
          title:
            user.status.toString() === UserStatus[UserStatus.ACTIVE]
              ? i18n.t('shared.inactivate')
              : i18n.t('shared.activate'),
        });
      }
    } else {
      dropdownMenuConfig.push({ key: SETTINGS_ACTIONS.USER_UNDELETE, title: i18n.t('shared.undelete') });
    }

    return (
      <Menu
        onClick={(clickParam) => {
          this.props.actionSelected(clickParam, user);
        }}
      >
        {dropdownMenuConfig.map((config) => (
          <Menu.Item key={config.key} disabled={config.disabled}>
            {config.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  render() {
    return (
      <Dropdown overlay={this.getActionsMenu()}>
        <MoreOutlined style={{ transform: 'rotate(90deg)', padding: '5px' }} />
      </Dropdown>
    );
  }
}

export default DropdownMenu;
