import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import React from 'react';

class HasAlert extends React.Component<AlertProps, {}> {
  render() {
    return <Alert {...this.props} />;
  }
}

export default HasAlert;
