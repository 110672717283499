import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { RadioGroupOption, TypedRadioGroupProps } from '../../../shared';
import { HasRadioButton } from '../../atoms';

interface OwnRadioGroupProps<T> extends TypedRadioGroupProps<T> {
  groupOptions: RadioGroupOption<T>[];
}

class HasRadioGroup<T> extends React.Component<OwnRadioGroupProps<T> & {}, {}> {
  onChangeWrapper = (event: RadioChangeEvent) => {
    const typedValue: T = event.target.value as T;
    this.props.onChange && this.props.onChange({ ...event, value: typedValue });
  };

  render() {
    const { options, groupOptions, ...rest } = this.props;
    return (
      <Radio.Group {...rest} onChange={this.onChangeWrapper}>
        {groupOptions.map((config, index) => (
          <HasRadioButton key={index} {...config} />
        ))}
      </Radio.Group>
    );
  }
}

export default HasRadioGroup;
