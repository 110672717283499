import React from 'react';
import i18n from '../../../i18n/config';
import { HasModal } from '../../atoms';

interface RemovePanelModalProps {
  visible: boolean;
  deleteChartInstance?: boolean;
  onOk: () => void;
  onCancel: any;
}

class HasRemoveChartModal extends React.Component<RemovePanelModalProps> {
  onCancel = () => {
    this.props.onCancel();
  };

  onOk = () => {
    this.props.onOk();
  };

  render() {
    const { visible, deleteChartInstance } = this.props;

    return (
      <HasModal
        title={deleteChartInstance ? i18n.t('charts.deleteChart') : i18n.t('charts.approveRemoveChart')}
        okText={i18n.t('shared.yes')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        onOk={this.onOk}
        closable={false}
        visible={visible}
        destroyOnClose
      ></HasModal>
    );
  }
}

export default HasRemoveChartModal;
