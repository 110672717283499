import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import i18n from '../../../i18n/config';
import { UserRole, UserSignUpData } from '../../../models';
import {
  ErrorMessages,
  INVITE_SIGN_UP_EMAIL_REGEX,
  INVITE_SIGN_UP_EMAIL_WITH_DOMAIN_REGEX,
  ModalBaseProps,
  Option,
} from '../../../shared';
import { formatTextToTitlecase, formatUserRole, getUserRoles } from '../../../utils';
import { HasDropdown, HasModal, HasParagraph, HasTextInput } from '../../atoms';

interface UserInviteProps extends ModalBaseProps<UserSignUpData> {
  domain: string;
}

interface UserInviteState {
  updateInProgress: boolean;
  userRole: string;
}

class HasInviteUserModal extends React.Component<UserInviteProps, UserInviteState> {
  private roles = getUserRoles();

  private userRole: string = this.roles[0];

  private formRef = React.createRef<FormInstance>();

  state = { updateInProgress: false, userRole: this.roles[0] };

  componentDidUpdate(prevProps: Readonly<UserInviteProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ updateInProgress: false });
    }
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    let userEmail = values.email;
    if (this.state.userRole !== UserRole.GUEST) {
      userEmail = userEmail.concat(this.props.domain);
    }
    const user: UserSignUpData = { email: userEmail, userRole: this.userRole };
    this.props.onOk(user).then(
      () => {
        this.setState({ updateInProgress: false });
        this.formRef.current?.resetFields();
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  render() {
    const { visible } = this.props;
    return (
      <HasModal
        title={
          <React.Fragment>
            <HasParagraph content={i18n.t('permission.inviteUser.message')} style={{ marginBottom: '10px' }} />
            <HasParagraph
              content={i18n.t('permission.inviteUser.description')}
              style={{ fontSize: '12px', margin: 0, fontWeight: 400 }}
            />
          </React.Fragment>
        }
        okText={i18n.t('permission.invite')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'invite-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
      >
        <Form
          id="invite-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{ role: this.userRole }}
        >
          <Form.Item
            name="email"
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.emailAddress').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.emailAddress').toLowerCase()) },
              {
                pattern:
                  this.state.userRole === UserRole.GUEST
                    ? INVITE_SIGN_UP_EMAIL_WITH_DOMAIN_REGEX
                    : INVITE_SIGN_UP_EMAIL_REGEX,
                message: ErrorMessages.USER_INVITE_EMAIL_INPUT,
              },
            ]}
          >
            <HasTextInput
              key="email"
              size="large"
              addonAfter={this.state.userRole === UserRole.GUEST ? null : this.props.domain}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label={i18n.t('shared.role')}
            colon={false}
            getValueFromEvent={(value: any, option: Option<string>) => {
              this.userRole = option.forObject;
              this.setState({ userRole: option.forObject });
              return formatUserRole(this.userRole);
            }}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.roleForTheUser').toLowerCase()) },
            ]}
          >
            <HasDropdown<string>
              size="large"
              style={{ fontSize: '14px' }}
              options={getUserRoles().map<Option<string>>((role) => ({
                value: role,
                label: formatUserRole(role),
                forObject: role,
              }))}
            />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasInviteUserModal;
