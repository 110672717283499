import { Col, Row } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { HasLogin } from '../../components';
import './login.scss';

class Login extends React.Component<RouteComponentProps<{}, {}, { sessionError: string }>, {}> {
  render() {
    return (
      <Row justify="center" align="middle" className="has-login-wrapper">
        <Col className="shadow-sm has-login-container">
          <HasLogin {...this.props} />
        </Col>
      </Row>
    );
  }
}

export default Login;
