import { Radio } from 'antd';
import React from 'react';
import { RadioGroupOption } from '../../../shared';

class HasRadioButton<T> extends React.Component<RadioGroupOption<T>, {}> {
  render() {
    const onlyButtonProps = { ...this.props };
    delete onlyButtonProps.label;
    return (
      <Radio.Button {...onlyButtonProps} style={{ minHeight: '24px', height: 'auto' }}>
        {this.props.label}
      </Radio.Button>
    );
  }
}

export default HasRadioButton;
