import { ButtonProps } from 'antd/lib/button';
import { isEqual, isUndefined } from 'lodash';
import React, { useState } from 'react';
import i18n from '../../../i18n/config';
import { RadioGroupOption, TypedRadioChangeEvent, TypedRadioGroupProps } from '../../../shared';
import { HasButton } from '../../atoms';
import HasRadioGroup from './radio.group';

interface CoordinatedRadioGroupProps<T> {
  config: Map<RadioGroupOption<T>, Array<RadioGroupOption<T>>>;
  parentRadioGroupProps?: Omit<TypedRadioGroupProps<T>, 'onChange'>;
  childRadioGroupProps?: Omit<TypedRadioGroupProps<T>, 'onChange'>;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  onSave: (parentValue: T, childValue: T) => void;
}

const HasCoordinatedRadioGroup = <T,>({
  config,
  parentRadioGroupProps,
  childRadioGroupProps,
  buttonProps,
  onSave,
}: CoordinatedRadioGroupProps<T>) => {
  const [parentValue, setParentValue] = useState<T>();
  const [childValue, setChildValue] = useState<T>();
  const [childGroupOptions, setChildGroupOptions] = useState<Array<RadioGroupOption<T>>>();
  const [canSave, setCanSave] = useState<boolean>(false);

  const onParentChange = (event: TypedRadioChangeEvent<T>) => {
    const childGroupValues = getChildGroupOptions(event.value);
    setParentValue(event.value);
    setChildValue(undefined);
    setChildGroupOptions(childGroupValues);
    setCanSave(false);
  };

  const onChildChange = (event: TypedRadioChangeEvent<T>) => {
    setChildValue(event.value);
    setCanSave(true);
  };

  const getParentGroupOptions = (): Array<RadioGroupOption<T>> => {
    const options: Array<RadioGroupOption<T>> = [];
    config.forEach((_, key) => options.push({ ...key, label: i18n.t(key.label) }));
    return options;
  };

  const getChildGroupOptions = (value: T): Array<RadioGroupOption<T>> => {
    const parentKey = Array.from(config.keys()).find((radioOption) => isEqual(radioOption.value, value));
    if (!isUndefined(parentKey)) {
      const parentOptions = config.get(parentKey);
      return parentOptions || [];
    }
    return [];
  };

  const save = () => {
    if (!isUndefined(parentValue) && !isUndefined(childValue)) {
      onSave(parentValue, childValue);
    }
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <HasRadioGroup<T>
        groupOptions={getParentGroupOptions()}
        onChange={onParentChange}
        value={parentValue}
        {...parentRadioGroupProps}
      />
      {!isUndefined(childGroupOptions) && (
        <HasRadioGroup<T>
          groupOptions={childGroupOptions.map((radioOption) => ({ ...radioOption, label: i18n.t(radioOption.label) }))}
          onChange={onChildChange}
          value={childValue}
          {...childRadioGroupProps}
        />
      )}
      {canSave && (
        <HasButton {...buttonProps} onClick={() => save()}>
          {i18n.t('shared.save')}
        </HasButton>
      )}
    </div>
  );
};

export { HasCoordinatedRadioGroup };
