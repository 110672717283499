import { LogEntry } from '.';
import { IncidentType, RiskImpact, RiskProbability } from './incident.model';

export interface Action extends ActionForm {
  id?: number;
  status?: ActionStatus;
  incidentId: number;
  incidentType?: IncidentType;
  createdByUserName?: string;
  createdByUserId?: number;
  createdOn?: string;
  companyId: number;
  company: string;
  division: string;
  history: ActionFormAttributes;
  sourceType: ActionSourceType;
  riskAssessmentResult?: {
    id: number | string;
  };
  complianceFormCompleted?: boolean;
  riskScore?: number;
  riskLevel?: string;
}

export interface ActionForm {
  dueDate?: string;
  requiredAction?: string;
  responsibleUserName?: string;
  responsibleUserId?: number;
  importance?: ActionImportance;
  otherInformation?: string;
}

export interface ActionFormAttributes {
  requiredAction: LogEntry[];
  responsibleUserId: LogEntry[];
  responsibleUserName: LogEntry[];
  dueDate: LogEntry[];
  importance: LogEntry[];
  otherInformation: LogEntry[];
}

export type ActionFormKey = keyof ActionForm & keyof ActionFormAttributes;

export const REQUIRED_ACTION: ActionFormKey = 'requiredAction';
export const RESPONSIBLE_USER_ID: ActionFormKey = 'responsibleUserId';
export const RESPONSIBLE_USER_NAME: ActionFormKey = 'responsibleUserName';
export const DUE_DATE: ActionFormKey = 'dueDate';
export const IMPORTANCE: ActionFormKey = 'importance';
export const OTHER_INFO: ActionFormKey = 'otherInformation';

export enum ActionStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  OVERDUE = 'OVERDUE',
}

export enum ActionImportance {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum ActionSourceType {
  EVENT = 'EVENT',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
}

export interface OutstandingActionsWrapper {
  dueNextMonth: Action[];
  dueOther: Action[];
}

export interface ActionComplianceForm {
  id?: number;
  investigatedAndActedUpon?: boolean;
  preventiveActionsEfficient?: boolean;
  additionalRisks?: boolean;
  classification?: ActionClassification;
  probability?: RiskProbability;
  impact?: RiskImpact;
  riskScore?: number;
  riskLevel?: string;
}

export enum ActionRegulatoryQuestions {
  INVESTIGATED_AND_ACTED_UPON = 'INVESTIGATED_AND_ACTED_UPON',
  PREVENTIVE_ACTIONS_EFFICENT = 'PREVENTIVE_ACTIONS_EFFICENT',
  ADDITIONAL_RISKS = 'ADDITIONAL_RISKS',
  CLASSIFICATION = 'CLASSIFICATION',
  RISK_MATRIX = 'RISK_MATRIX',
}

export enum ActionRiskMatrix {
  IMPACT = 'IMPACT',
  PROBABILITY = 'PROBABILITY',
}

export enum YesNoOption {
  YES = 'YES',
  NO = 'NO',
}

export enum ActionClassification {
  PERSONAL_PROTECTIVE_EQUIPMENT = 'PERSONAL_PROTECTIVE_EQUIPMENT',
  ADMINISTRATIVE_CONTROL = 'ADMINISTRATIVE_CONTROL',
  ENGINEER_CONTROL = 'ENGINEER_CONTROL',
  SUBSTITUTION = 'SUBSTITUTION',
  ELIMINATION = 'ELIMINATION',
}
