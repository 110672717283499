import Input, { PasswordProps } from 'antd/lib/input';
import React from 'react';

interface PasswordInputProps extends PasswordProps {}

class HasPasswordInput extends React.Component<PasswordInputProps, {}> {
  render() {
    return <Input.Password {...this.props} />;
  }
}

export default HasPasswordInput;
