import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { HasHorizontalMenu } from '../../components';
import i18n from '../../i18n/config';
import { Company, Division, PhoneNumber, User } from '../../models';
import { CompanyService, DivisionService, EntityAction, PhoneService, UserService } from '../../services';
import { COLORS, MAIN_URLS, MenuItemWithKey } from '../../shared';
import { HasCompanySettings, HasDivisionsSettings, HasPermissionsSettings, HasUsersSettings } from './';
import { HasMyPermissionInvitations } from './my-permissions';
import { HasPhonesSettings } from './phones';
import './settings.scss';

interface SettingsWrapperProps extends RouteComponentProps<{ view: string }>, WithTranslation {}

interface SettingsWrapperState {
  companyId: number;
  users: User[];
  usersWithInvites: User[];
  myDataViewInvitations: User[];
  phoneNumbers: PhoneNumber[];
  company: Company;
  loading: boolean;
  includeDeletedUsers: boolean;
  includeDeletedDivisions: boolean;
}

class HasSettingsWrapper extends React.Component<SettingsWrapperProps, SettingsWrapperState> {
  state = {
    companyId: NaN,
    users: [],
    usersWithInvites: [],
    myDataViewInvitations: [],
    phoneNumbers: [],
    company: {} as Company,
    loading: true,
    includeDeletedUsers: false,
    includeDeletedDivisions: false,
  };

  private subscriptions: Subscription[] = [];

  async componentDidMount() {
    this.subscriptions.push(
      CompanyService.getCompanyListener().subscribe(
        (companyId) =>
          !isNaN(companyId) &&
          this.getData(companyId, this.state.includeDeletedUsers, this.state.includeDeletedDivisions)
      ),
      UserService.getUsersChangedListener().subscribe(async (action) => {
        const { companyId } = this.state;
        if (!isNaN(companyId)) {
          this.setState({ loading: true });
          switch (action.event) {
            case EntityAction.REFRESH:
              const users = await UserService.getAllUsersForCompany(
                this.state.companyId,
                NaN,
                this.state.includeDeletedUsers
              );
              const usersWithInvites = await UserService.getInvitedUsersForMyCompany(this.state.companyId);
              const myDataViewInvitations = await UserService.getMyInvitationsForDataSharing();
              this.setState({
                users: users.data,
                usersWithInvites: usersWithInvites.data,
                myDataViewInvitations: myDataViewInvitations.data,
                loading: false,
              });
              break;
            default:
              break;
          }
        }
      }),
      CompanyService.getCompanyChangedListener().subscribe((_) => {
        const { companyId } = this.state;
        !isNaN(companyId) &&
          CompanyService.getCompanyWithDivisionInformation(companyId).then((response) =>
            this.setState({ company: response.data })
          );
      }),
      DivisionService.getDivisonChangedListener().subscribe((_) => {
        const { companyId } = this.state;
        !isNaN(companyId) &&
          CompanyService.getCompanyWithDivisionInformation(companyId, this.state.includeDeletedDivisions).then(
            (response) => this.setState({ company: response.data })
          );
      }),
      PhoneService.getPhoneNumbersChangedListener().subscribe((_) => {
        const { companyId } = this.state;
        !isNaN(companyId) &&
          PhoneService.getByCompany(companyId).then((response) => this.setState({ phoneNumbers: response.data }));
      })
    );
  }

  getData = async (companyId: number, includeDeletedUsers: boolean, includeDeletedDivisions: boolean) => {
    const { data: company } = await CompanyService.getCompanyWithDivisionInformation(
      companyId,
      includeDeletedDivisions
    );
    const { data: users } = await UserService.getAllUsersForCompany(company.id, NaN, includeDeletedUsers);
    const { data: usersWithInvites } = await UserService.getInvitedUsersForMyCompany(companyId);
    const { data: myDataViewInvitations } = await UserService.getMyInvitationsForDataSharing();
    const { data: phoneNumbers } = await PhoneService.getByCompany(company.id);

    this.setState({
      companyId,
      users,
      usersWithInvites,
      myDataViewInvitations,
      phoneNumbers,
      company,
      loading: false,
    });
  };

  getMenuConfig = (t: TFunction): MenuItemWithKey[] => [
    {
      key: `/settings/users`,
      title: i18n.t('settings.users'),
    },
    {
      key: `/settings/contacts`,
      title: i18n.t('settings.contacts'),
    },
    {
      key: `/settings/my-invitations`,
      title: i18n.t('settings.contactsOf'),
    },
    {
      key: `/settings/phone-numbers`,
      title: i18n.t('settings.allowedPhones'),
    },
  ];

  getViewComponent = (): ReactNode => {
    const {
      match: {
        params: { view },
      },
    } = this.props;
    const { company, users, loading, usersWithInvites, phoneNumbers, myDataViewInvitations } = this.state;
    switch (true) {
      case MAIN_URLS.SETTINGS_USERS.includes(view):
        return (
          <HasUsersSettings
            companyId={company.id}
            companyDomain={company.domain}
            users={users}
            loading={loading}
            showOrHideDeletedUsersCallback={this.handleShowOrHideDeletedUsersCallback}
          />
        );
      case MAIN_URLS.SETTINGS_CONTACTS.includes(view):
        return <HasPermissionsSettings users={usersWithInvites} loading={loading} />;
      case MAIN_URLS.SETTINGS_INVITATIONS.includes(view):
        return <HasMyPermissionInvitations users={myDataViewInvitations} loading={loading} />;
      case MAIN_URLS.SETTINGS_PHONE_NUMBERS.includes(view):
        return <HasPhonesSettings company={company} phoneNumbers={phoneNumbers} loading={loading} />;
    }
  };

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  handleShowOrHideDeletedUsersCallback = (includeDeletedUsers: boolean) => {
    const companyId = this.state.companyId;
    this.setState({ includeDeletedUsers: includeDeletedUsers });
    this.getData(companyId, includeDeletedUsers, this.state.includeDeletedDivisions);
  };

  handleShowOrHideDeletedDivisionsCallback = (includeDeletedDivisions: boolean) => {
    const companyId = this.state.companyId;
    this.setState({ includeDeletedDivisions });
    this.getData(companyId, this.state.includeDeletedUsers, includeDeletedDivisions);
  };

  render() {
    const {
      location: { pathname },
      t,
    } = this.props;
    const { company } = this.state;
    return (
      <div
        style={{ backgroundColor: COLORS.WHITE, padding: '20px', border: `1px solid ${COLORS.GRAY_ACCENT_LIGHT}` }}
        className="shadow-sm grid-settings-container overflow-auto"
      >
        <div className="company-details">
          <HasCompanySettings company={company} />
        </div>
        <div className="divisions-list">
          <HasDivisionsSettings
            company={company}
            showOrHideDeletedDivisionsCallback={this.handleShowOrHideDeletedDivisionsCallback}
          />
        </div>
        <div className="tabbed-settings">
          <HasHorizontalMenu
            menuItems={this.getMenuConfig(t)}
            selectedKeys={[pathname]}
            style={{ backgroundColor: 'transparent', marginBottom: '24px' }}
          />
          {this.getViewComponent()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(HasSettingsWrapper);
