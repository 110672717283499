import { get, includes, isUndefined, uniqBy } from 'lodash';
import moment from 'moment';
import { ReactText } from 'react';
import i18n from '../i18n/config';
import { Company, Division, FormResultType, IncidentType, IncidentTypeLabel, NewIncident } from '../models';
import {
  ChatBotStep,
  DATE_TIME_FORMAT,
  EventEnumLabels,
  FORM_RESULT_KEYS,
  LANGUAGES,
  OptionsStep,
  TRUTHFUL_VALUES,
} from '../shared';

const {
  EVENT_TYPE,
  REPORT_DATE,
  WHERE_DID_IT_HAPPEN,
  ORG_SELECTION,
  WHAT_HAPPENED_DETAILS,
  HAZARD_DESCRIPTION,
  SUBJECT,
  REPORTING_PERSON_IS_VICTIM,
  VICTIM_CONTACT_FIRST_NAME,
  VICTIM_CONTACT_LAST_NAME,
  VICTIM_CONTACT_EMAIL,
  VICTIM_CONTACT_PHONE,
  WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED,
  EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER,
} = FORM_RESULT_KEYS;

class HistoricalEventsUtils {
  private AVAILABLE_LANGUAGES = LANGUAGES.map((language) => language.id);
  private T_FUNCTIONS = this.AVAILABLE_LANGUAGES.map((lang) => i18n.getFixedT(lang));

  getEventType = (event: Object): IncidentType | undefined => {
    const type = get(event, EVENT_TYPE, undefined);
    if (type) {
      let types = Object.values(IncidentType);
      const typesList = types.map((type) => ({ key: type, name: IncidentTypeLabel[type] }));
      const typeTranslations = typesList.map(({ key, name }) => ({
        key,
        translations: this.T_FUNCTIONS.map((t) => t(name).toLowerCase()),
      }));

      return typeTranslations.find((typeTranslation) => typeTranslation.translations.includes(type.toLowerCase()))?.key;
    }
    return undefined;
  };

  getReportDate = (event: Object): string | undefined => {
    const date = get(event, REPORT_DATE, undefined);
    if (date) {
      return moment(date, DATE_TIME_FORMAT).toISOString();
    }
    return undefined;
  };

  getDivision = (event: Object): string | undefined => {
    return get(event, ORG_SELECTION, undefined);
  };

  getDescription = (event: Object): string | undefined => {
    return (
      get(event, WHAT_HAPPENED_DETAILS, undefined) ||
      get(event, HAZARD_DESCRIPTION, undefined) ||
      get(event, SUBJECT, undefined)
    );
  };

  getFormattedValue = (value: string | undefined, step: ChatBotStep): ReactText | boolean | undefined => {
    switch (step.metadata.type) {
      case FormResultType.OPTION:
      case FormResultType.OPTION_DESCRIPTION:
        if (isUndefined(value)) {
          return undefined;
        }
        const options = (step as OptionsStep).options;
        if (options && options.length) {
          let i18nKey: ReactText | boolean | undefined;
          if (value === 'true') {
            i18nKey = 'shared.yes';
          } else if (value === 'false') {
            i18nKey = 'shared.no';
          } else {
            this.T_FUNCTIONS.forEach((t) =>
              options.forEach(({ value: optionValue, label }) => {
                if (step.metadata.key === WHERE_DID_IT_HAPPEN) {
                  const split = value.split(',');
                  split.forEach((item) => {
                    if (t(optionValue.toString()) === item || optionValue === item) {
                      i18nKey = optionValue;
                    } else if (t(label) === item || label === item) {
                      i18nKey = optionValue;
                    }
                  });
                } else if (
                  !['true', 'false'].includes(optionValue.toString()) &&
                  (t(optionValue.toString()) === value || optionValue === value)
                ) {
                  i18nKey = optionValue;
                } else if (t(label) === value || label === value) {
                  i18nKey = optionValue;
                }
              })
            );
          }
          return !isUndefined(i18nKey) ? i18nKey : value;
        } else {
          return value;
        }
      case FormResultType.DATE:
        return value ? moment(value, DATE_TIME_FORMAT).toISOString() : undefined;
      case FormResultType.REASON:
      case FormResultType.SUB_REASON:
      case FormResultType.CAUSE:
      case FormResultType.SUB_CAUSE:
        if (isUndefined(value)) {
          return value;
        }
        let i18nKey: string | undefined = undefined;
        EventEnumLabels.forEach((mapValue, key) => {
          const translations = this.T_FUNCTIONS.map((t) => t(mapValue).toLowerCase());
          if (translations.includes(value.toLowerCase())) {
            i18nKey = key;
          }
        });
        return !isUndefined(i18nKey) ? i18nKey : value;
      default:
        return value;
    }
  };

  getEventStructureIssues = (
    event: Object,
    steps: ChatBotStep[],
    ignoredKeys: string[]
  ): { key: string; type: FormResultType }[] => {
    const keysWithErrors: { key: string; type: FormResultType }[] = [];
    steps.forEach((step) => {
      const { metadata } = step;
      if (metadata?.key && !ignoredKeys.includes(metadata.key)) {
        const value = get(event, metadata.key, undefined);
        if (!value) {
          keysWithErrors.push({ key: metadata.key, type: step.metadata.type });
        } else {
          if (isUndefined(this.getFormattedValue(value, step))) {
            keysWithErrors.push({ key: metadata.key, type: step.metadata.type });
          }
        }
      }
    });
    return keysWithErrors;
  };

  getIgnoredKeys = (event: Object): string[] => {
    let ignoredKeys: string[] = [];

    const reportingIsVictim: string = get(event, REPORTING_PERSON_IS_VICTIM, undefined);
    if (reportingIsVictim && TRUTHFUL_VALUES.includes(reportingIsVictim.toLowerCase())) {
      ignoredKeys.push(VICTIM_CONTACT_FIRST_NAME, VICTIM_CONTACT_LAST_NAME, VICTIM_CONTACT_EMAIL, VICTIM_CONTACT_PHONE);
    }

    const machinesInvolved: string = get(event, WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED, undefined);
    if (machinesInvolved && !TRUTHFUL_VALUES.includes(machinesInvolved.toLowerCase())) {
      ignoredKeys.push(EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER);
    }

    return ignoredKeys;
  };

  mapEventObjectToIncidentModel = (
    steps: ChatBotStep[],
    company: Company,
    event: Object,
    type: string
  ): NewIncident => {
    const reportedAt = this.getReportDate(event);
    const division = this.getDivision(event);
    const description = this.getDescription(event);
    const ignoredKeys: string[] = this.getIgnoredKeys(event);
    const invalidEntryKeys = this.getEventStructureIssues(event, steps, ignoredKeys);

    let eventDivision: Division | undefined = undefined;
    if (
      isUndefined(division) ||
      (division && !company.divisions.map((division) => division.name.toLowerCase()).includes(division.toLowerCase()))
    ) {
      invalidEntryKeys.push({ key: ORG_SELECTION, type: FormResultType.OPTION });
    } else {
      eventDivision = company.divisions.find(({ name }) => name.toLowerCase() === division.toLowerCase());
    }

    let incident: NewIncident = {
      type,
      reportedAt,
      description,
      invalidEntryKeys,
      company,
      division: eventDivision,
      formResult: [],
      historicalUpload: true,
    };

    let mainSteps = steps.filter((step) => step.metadata?.key);
    uniqBy<ChatBotStep>(mainSteps, (step) => step.metadata.key).forEach((step) => {
      if (
        !ignoredKeys.includes(step.metadata.key) &&
        !includes(
          incident.formResult.map((fr) => fr.key),
          step.metadata.key
        )
      ) {
        incident.formResult.push({
          key: step.metadata.key,
          type: step.metadata.type,
          value: this.getFormattedValue(get(event, step.metadata.key, undefined), step),
          seqNo: 0,
        });
      }
    });

    return incident;
  };
}

export default new HistoricalEventsUtils();
