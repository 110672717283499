import { TimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
import React from 'react';

class HasTimePicker extends React.Component<TimePickerProps, {}> {
  render() {
    return <TimePicker {...this.props} />;
  }
}

export default HasTimePicker;
