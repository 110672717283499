import React from 'react';
import { HasButton, HasParagraph, HasRadioGroup, HasText } from '../../..';
import i18n from '../../../../i18n/config';
import {
  ComplexRadioOption,
  DELIMITER,
  ErrorMessages,
  RadioGroupOption,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
} from '../../../../shared';
import { getElementKey, i18nLabelParser } from '../../../../utils';

interface ComplexRadioGroupProps {
  radioOptions: ComplexRadioOption[];
  onChange: () => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
  questionTitle?: string;
}

interface ComplexRadioGroupState {
  error: boolean;
  selectedOption: ComplexRadioOption | null;
  radioOptionsConfig: RadioGroupOption<ComplexRadioOption>[];
}

class HasComplexRadioGroup extends React.Component<ComplexRadioGroupProps, ComplexRadioGroupState> {
  private radioGroupConfig: TypedRadioGroupProps<ComplexRadioOption>;

  constructor(props: Readonly<ComplexRadioGroupProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onChange,
    };

    const radioOptionsConfig = this.buildRadioOptions();

    this.state = {
      error: false,
      selectedOption: null,
      radioOptionsConfig,
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () => this.setState({ radioOptionsConfig: this.buildRadioOptions() }));
  }

  buildRadioOptions = (): RadioGroupOption<ComplexRadioOption>[] => {
    return this.props.radioOptions.map((option, index) => ({
      key: getElementKey('simple_radio_group_option', index),
      value: option,
      forObject: option,
      label: i18nLabelParser(option.label),
    }));
  };

  onChange = (event: TypedRadioChangeEvent<ComplexRadioOption>) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.setState({ selectedOption: event.value });
    this.props.onChange();
  };

  saveData = (extraData?: any) => {
    const { selectedOption } = this.state;
    if (!selectedOption) {
      this.setState({ error: true });
    } else {
      const result = selectedOption
        ? extraData
          ? selectedOption.label
              .concat(DELIMITER)
              .concat(',')
              .concat(DELIMITER)
              .concat(
                selectedOption.answerLabel ? selectedOption.answerLabel.concat(DELIMITER).concat(extraData) : extraData
              )
          : selectedOption.label
        : null;
      this.props.onSave(result);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  useSubComponentSaveButton = (): boolean =>
    this.state.selectedOption &&
    !this.state.selectedOption.withoutSaveFunctionality &&
    this.state.selectedOption.subComponent
      ? true
      : false;

  componentWillUnmount() {
    i18n.on('languageChanged', () => this.setState({ radioOptionsConfig: this.buildRadioOptions() }));
  }

  render() {
    const { selectedOption, radioOptionsConfig } = this.state;
    const { questionTitle } = this.props;
    return (
      <React.Fragment>
        <HasParagraph
          content={questionTitle ? questionTitle : i18n.t('investigation.pickOne')}
          style={{ fontSize: '12px', marginBottom: '8px' }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <HasRadioGroup {...this.radioGroupConfig} groupOptions={radioOptionsConfig} />
          {!this.useSubComponentSaveButton() && (
            <HasButton type="primary" size="small" htmlType="button" onClick={() => this.saveData()}>
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {selectedOption &&
          selectedOption.subComponent &&
          React.cloneElement(selectedOption.subComponent, {
            onSave: (data: any) => this.saveData(data),
            closeEditModeCallback: this.props.closeEditModeCallback,
          })}
        {this.state.error && (
          <HasText
            content={ErrorMessages.INVESTIGATION_ENTRY_NO_OPTION_SELECTED}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HasComplexRadioGroup;
