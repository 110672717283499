import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { Investigation, InvestigationStatus } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class InvestigationSerivce implements RestService<Investigation> {
  private investigationChangedSubject = new Subject<SubscriberEvent<Investigation>>();

  get(params?: any): Promise<AxiosResponse<Investigation>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Investigation[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Investigation>> {
    throw new Error('Method not implemented.');
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Investigation>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Investigation>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Investigation): Promise<AxiosResponse<Investigation>> {
    return httpClient.post<Investigation>(URL.INVESTIGATION, resource);
  }

  update(resource: Investigation): Promise<AxiosResponse<Investigation>> {
    return httpClient.put<Investigation>(URL.INVESTIGATION, resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Investigation>> {
    throw new Error('Method not implemented.');
  }

  getByIncidentId(incidentId: string | number): Promise<AxiosResponse<Investigation>> {
    return httpClient.get<Investigation>(buildUrl(URL.INVESTIGATION, ['byincident']), {
      params: { incidentId: incidentId },
    });
  }

  createEmptyInvestigation(resource: {
    incidentId: number;
    status: InvestigationStatus;
  }): Promise<AxiosResponse<Investigation>> {
    return httpClient.post(URL.INVESTIGATION, resource);
  }

  // SUBSCRIPTIONS

  getInvestigationChangedListener(): Observable<SubscriberEvent<Investigation>> {
    return this.investigationChangedSubject.asObservable();
  }

  investigationChanged(event: EntityAction, investigation: Investigation) {
    this.investigationChangedSubject.next({ event: event, entity: investigation });
  }
}

export default new InvestigationSerivce();
