import React, { useEffect, useState } from 'react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import { ChartCollapsablePanel, ChartInstance, ChartInstanceDataFilter } from '../../../models';
import { ChartService } from '../../../services';
import { DASHBOARD_CHART_COLORS, TimeIntervals } from '../../../shared';
import { escapeRegExp, getDatesFromInterval } from '../../../utils';

interface RadarChartProps {
  chartInstance: ChartInstance;
  panel: ChartCollapsablePanel;
  selectedCompanies: number[] | undefined;
  language: string;
}

const COLORS = DASHBOARD_CHART_COLORS;

const AbstractRadarChart: React.FC<RadarChartProps> = ({ chartInstance, panel, selectedCompanies, language }) => {
  const [values, setValues] = useState<any[]>([]);
  const [sqlMappings, setSqlMappings] = useState<string[]>([]);
  const [angle, setAngle] = useState<string>();

  useEffect(() => {
    if (chartInstance.sqlMapping) {
      const splittedSqlMappings = chartInstance.sqlMapping.split('$');
      setAngle(splittedSqlMappings[0]);
      setSqlMappings(splittedSqlMappings.slice(1));
    }
  }, [chartInstance.sqlMapping]);

  useEffect(() => {
    if (chartInstance.sqlMappingFr && chartInstance.sqlMapping) {
      const splittedSqlMappingsEn = chartInstance.sqlMapping.split('$');
      const splittedSqlMappingsFr = chartInstance.sqlMappingFr.split('$');
      let oldValues = JSON.stringify(values);
      if (language === 'en') {
        splittedSqlMappingsFr.forEach(
          (sqlMappingFr, index) =>
            (oldValues = oldValues.replace(new RegExp(escapeRegExp(sqlMappingFr), 'g'), splittedSqlMappingsEn[index]))
        );
        setValues(JSON.parse(oldValues));
        setAngle(splittedSqlMappingsEn[0]);
        setSqlMappings(splittedSqlMappingsEn.slice(1));
      } else {
        splittedSqlMappingsEn.forEach(
          (sqlMappingEn, index) =>
            (oldValues = oldValues.replace(new RegExp(escapeRegExp(sqlMappingEn), 'g'), splittedSqlMappingsFr[index]))
        );
        setValues(JSON.parse(oldValues));
        setAngle(splittedSqlMappingsFr[0]);
        setSqlMappings(splittedSqlMappingsFr.slice(1));
      }
    }
  }, [language]);

  useEffect(() => {
    if (chartInstance.id && selectedCompanies) {
      let chartInstanceDataFilter: ChartInstanceDataFilter = { companies: selectedCompanies };
      if (panel.timeInterval !== TimeIntervals.CUSTOM) {
        const timeInterval = getDatesFromInterval(panel.timeInterval);
        chartInstanceDataFilter.startDate = timeInterval?.startDate;
        chartInstanceDataFilter.endDate = timeInterval?.endDate;
      } else if (panel.startDate && panel.endDate) {
        chartInstanceDataFilter.startDate = panel.startDate;
        chartInstanceDataFilter.endDate = panel.endDate;
      }
      loadChartInstanceData(chartInstance.id, chartInstanceDataFilter);
    }
  }, [selectedCompanies, panel.timeInterval, chartInstance.id, panel.startDate, panel.endDate]);

  const loadChartInstanceData = async (chartInstanceId: number, chartInstanceDataFilter: ChartInstanceDataFilter) => {
    await ChartService.getChartInstanceData(chartInstanceId, chartInstanceDataFilter).then(({ data }) => {
      setValues(language === 'en' ? data : translateValues(data));
    });
  };

  const translateValues = (oldValues: any[]) => {
    if (chartInstance.sqlMappingFr && chartInstance.sqlMapping) {
      const splittedSqlMappingsEn = chartInstance.sqlMapping.split('$');
      const splittedSqlMappingsFr = chartInstance.sqlMappingFr.split('$');
      let newValues = JSON.stringify(oldValues);
      splittedSqlMappingsEn.forEach(
        (sqlMappingEn, index) =>
          (newValues = newValues.replace(new RegExp(escapeRegExp(sqlMappingEn), 'g'), splittedSqlMappingsFr[index]))
      );
      setAngle(splittedSqlMappingsFr[0]);
      setSqlMappings(splittedSqlMappingsFr.slice(1));
      return JSON.parse(newValues);
    }
    return oldValues;
  };

  return (
    <ResponsiveContainer minWidth={100}>
      <RadarChart data={values}>
        <PolarGrid />
        <PolarAngleAxis dataKey={angle} />
        <PolarRadiusAxis angle={30} domain={[0, 0]} />
        {sqlMappings.map((mapping, index) => (
          <Radar
            key={mapping}
            name={mapping}
            dataKey={mapping}
            stroke={COLORS[index % COLORS.length]}
            fill={COLORS[index % COLORS.length]}
            fillOpacity={0.6}
          />
        ))}
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default AbstractRadarChart;
