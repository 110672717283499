import { MAX_FILES_PER_UPLOAD, TEXT_AREA_MAX_LENGTH } from '.';
import i18n from '../i18n/config';

const getError = (error?: string): string =>
  error ? `${i18n.t('errors.errorOccurred')} ` + error : `${i18n.t('fallback.errorOccurred')}.`;

const getFileName = (fileName: string): string =>
  fileName.length > 20 ? fileName.substring(0, 20).concat('...') : fileName;

let ErrorMessages: any = {};
let SuccessMessages: any = {};
let ConfirmMessages: any = {};

function setErrorMessages() {
  ErrorMessages = {
    NETWORK_ERROR: {
      message: i18n.t('errors.networkError.message'),
      description: i18n.t('errors.networkError.description'),
    },
    UNEXPECTED_ERROR: (error: string) => ({ message: i18n.t('errors.unexpectedError'), description: error }),
    SESSION_INVALID: () => ({
      message: i18n.t('errors.sessionInvalid.message'),
      description: i18n.t('errors.sessionInvalid.description'),
    }),
    ROLE_NO_ACCESS: {
      message: i18n.t('errors.roleNoAccess.message'),
      description: i18n.t('errors.roleNoAccess.description'),
    },
    PERSONAL_INPUT_REQUIRED: (inputName: string) => i18n.t('errors.personalInputRequired', { inputName }),
    TERMS_AND_CONDITIONS_ACCEPT: i18n.t('errors.termsAndConditions'),
    INPUT_REQUIRED: (inputName: string) => i18n.t('errors.inputRequired', { inputName }),
    SELECT_REQUIRED: (inputName: string) => i18n.t('errors.selectRequired', { inputName }),
    GENERIC_INPUT_REQUIRED: i18n.t('errors.genericInputRequired'),
    INPUT_CONFIRM: (inputName: string) => i18n.t('errors.inputConfirm', { inputName }),
    LOGIN_ROLE_NOT_PERMITTED: i18n.t('errors.loginRoleNotPermitted'),
    LOGIN_MISSING_TOKEN: i18n.t('errors.loginMissingToken'),
    LOGIN_SERVER_OFFLINE: i18n.t('errors.loginServerOffline'),
    FORGOT_PASSWORD: (error: string) => ({ message: i18n.t('errors.error'), description: error }),
    FORGOT_PASSWORD_UNEXPECTED: (error?: string) => ({
      message: i18n.t('errors.unexpectedError'),
      description: `${i18n.t('errors.passwordRecoveryFailed')} ${getError(error)}`,
    }),
    REGISTER_INVALID_EMAIL: i18n.t('errors.registrationInvalidEmail'),
    REGISTER: (error?: string) => ({ message: i18n.t('errors.registrationFailed'), description: getError(error) }),
    INCIDENT_INVALID_COORDINATES: {
      message: i18n.t('errors.invalidCoordinates.message'),
      description: i18n.t('errors.invalidCoordinates.description'),
    },
    INCIDENT_REPORT_FILE_SIZE: (fileName: string) => ({
      message: i18n.t('errors.fileSize'),
      description: i18n.t('errors.reportFileSize', { filename: getFileName(fileName) }),
    }),
    INCIDENT_REPORT_FILE_TYPE: (fileName: string) => ({
      message: i18n.t('errors.fileType'),
      description: i18n.t('errors.reportFileType', { filename: getFileName(fileName) }),
    }),
    INCIDENT_REPORT_FILE_PREVIEW: (fileName: string) => ({
      message: i18n.t('errors.reportFilePreview.message'),
      description: i18n.t('errors.reportFilePreview.description', { filename: getFileName(fileName) }),
    }),
    INCIDENT_REPORT_LOCATION_ALREADY_SAVED: i18n.t('errors.locationAlreadySaved'),
    INCIDENT_REPORT_GET_FORM_TYPE: (error: string) => ({
      message: i18n.t('errors.formNotFound.message'),
      description: `${i18n.t('errors.formNotFound.description')} ${getError(error)}`,
    }),
    INCIDENT_REPORT_CREATE: (error: string) => ({
      message: i18n.t('errors.creationFailed'),
      description: `${i18n.t('errors.reportCreation')} ${getError(error)}`,
    }),
    INCIDENT_REPORT_UPDATE: (error: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.reportUpdate')} ${getError(error)}`,
    }),
    INVESTIGATION_UNSAVED_PAGE_LEAVE: i18n.t('errors.unsavedPageLeave'),
    INVESTIGATION_UNSAVED_FORM_EDIT_TOGGLE: i18n.t('errors.unsavedFormLeave'),
    INVESTIGATION_ENTRY_NO_OPTION_SELECTED: i18n.t('errors.noOptionSelected'),
    INVESTIGATION_ENTRY_FORM_NOT_COMPLETE: i18n.t('errors.incompleteInvestigationForm'),
    INVESTIGATION_ENRTY_OPTIONS_EMPTY: i18n.t('errors.optionsEmpty'),
    INVESTIGATION_FREE_TEXT_LENGTH: i18n.t('errors.textLength', { maxLength: TEXT_AREA_MAX_LENGTH }),
    ATTACHMENT_FILES_LIMIT_EXCEEDED: {
      message: i18n.t('errors.attachmentFilesLimit.message'),
      description: i18n.t('errors.attachmentFilesLimit.description', { maxFiles: MAX_FILES_PER_UPLOAD }),
    },
    ATTACHMENT_FILE_SIZE: (maxSize: number) => ({
      message: i18n.t('errors.fileSize'),
      description: i18n.t('errors.attachmentFileSize', { maxSize: maxSize }),
    }),
    ATTACHMENT_FILE_TYPE: (fileName: string) => ({
      message: i18n.t('errors.fileType'),
      description: i18n.t('errors.attachmentFileType', { fileName: getFileName(fileName) }),
    }),
    ATTACHMENT_UPLOAD: (error: string) => ({
      message: i18n.t('errors.uploadFailed.message'),
      description: `${i18n.t('errors.uploadFailed.description')}. ${error}`,
    }),
    ATTACHMENT_DELETE: (error: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.attachmentDeletion')} ${getError(error)}`,
    }),
    ACTION_NOT_FOUND: {
      message: i18n.t('errors.actionNotFound.message'),
      description: i18n.t('errors.actionNotFound.description'),
    },
    ACTION_UNSAVED_CHANGES: (isCreate: boolean) => ({
      message: i18n.t('errors.actionUnsaved.message'),
      description: i18n.t('errors.actionUnsaved.description'),
    }),
    ACTION_INVALID_FORM: {
      message: i18n.t('errors.actionInvalid.message'),
      description: i18n.t('errors.actionInvalid.description'),
    },
    USER_ACTIVATE: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.userActivationFailed.message'),
      description: `${i18n.t('errors.userActivationFailed.description', { name, surname })} ${getError(error)}`,
    }),
    USER_INACTIVATE: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.userInactivationFailed.message'),
      description: `${i18n.t('errors.userInactivationFailed.description', { name, surname })} ${getError(error)}`,
    }),
    INCOMPLETE_FORM_GENERIC: {
      message: i18n.t('errors.incompleteForm'),
      description: i18n.t('errors.incompleteFormGeneric'),
    },
    INCOMPLETE_FORM_FOR_ENTITY: (entity: string) => ({
      message: i18n.t('errors.incompleteForm'),
      description: i18n.t('errors.incompleteFormFor', { entity }),
    }),
    USER_DELETE: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.userDeletion', { name, surname })} ${getError(error)}`,
    }),
    USER_UNDELETE: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.undeletionFailed'),
      description: `${i18n.t('errors.userUndeletion', { name, surname })} ${getError(error)}`,
    }),
    USER_UPDATE: (error?: string) => ({ message: i18n.t('errors.updateFailed'), description: `${getError(error)}` }),
    USER_PASSWORD_CHANGE: (error?: string) => ({
      message: i18n.t('errors.passwordChange'),
      description: `${getError(error)}`,
    }),
    USER_INVITE_SIGN_UP: (email: string, error?: string) => ({
      message: i18n.t('errors.invitationFailed.message'),
      description: `${i18n.t('errors.invitationFailed.description', { email })} ${getError(error)}`,
    }),
    USER_INVITE_EMAIL_INPUT: i18n.t('errors.invitationInvalidEmail'),
    PASSWORD_INVALID: i18n.t('errors.passwordInvalid'),
    PASSWORDS_NOT_MATCH: i18n.t('errors.passwordsDoNotMatch'),
    EMAIL_INVALID: i18n.t('errors.emailInvalid'),
    COMPANY_UPDATE: (error?: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.companyUpdate')} ${getError(error)}`,
    }),
    COMPANY_STATUS_UPDATE: (error: string) => ({
      message: i18n.t('errors.companyStatusUpdate.message'),
      description: `${i18n.t('errors.companyStatusUpdate.description')} ${getError(error)}`,
    }),
    COMPANY_DELETE: (error: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.companyDeletion')} ${getError(error)}`,
    }),
    SUBSCRIPTIONS_UPDATE: (error: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.subscriptionsUpdate')} ${getError(error)}`,
    }),
    DIVISION_UPDATE: (error?: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.divisionUpdate')} ${getError(error)}`,
    }),
    DIVISION_CREATE: (error?: string) => ({
      message: i18n.t('errors.creationFailed'),
      description: `${i18n.t('errors.divisionCreation')} ${getError(error)}`,
    }),
    DIVISION_DELETE_RESTRICTION: (error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: i18n.t('errors.divisionNotDeletable'),
    }),
    DIVISION_DELETE_ERROR: (divisionName: string, error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.divisionDeletion', { divisionName })} ${getError(error)}`,
    }),
    DIVISION_UNDELETE_ERROR: (divisionName: string) => ({
      message: i18n.t('success.undeletionFailed'),
      description: i18n.t('success.divisionUndeletion', { divisionName: divisionName }),
    }),
    PERMISSION_REVOKED: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.permissionRevokation.message'),
      description: `${i18n.t('errors.permissionRevokation', { name, surname })} ${getError(error)}`,
    }),
    OWN_PERMISSION_REVOKED: (error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.ownPermissionRevokation')} ${getError(error)}`,
    }),
    PERMISSION_REVOKED_INVALID: (name: string, surname: string, error?: string) => ({
      message: i18n.t('errors.contactCorrupted.message'),
      description: i18n.t('errors.contactCorrupted.description', { name, surname }),
    }),
    PERMISSION_ADDED: (error?: string) => ({
      message: i18n.t('errors.contactCreation.message'),
      description: `${i18n.t('errors.contactCreation.description')} ${getError(error)}`,
    }),
    PHONE_ADDED: (error?: string) => ({
      message: i18n.t('errors.creationFailed'),
      description: `${i18n.t('errors.phoneCreation')} ${getError(error)}`,
    }),
    PHONE_DELETE: (error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.phoneDeletion')} ${getError(error)}`,
    }),
    PHOTO_DELETE: (error?: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.photoDeletion')} ${getError(error)}`,
    }),
    AT_LEAST_ONE_SELECTED: (entity: string) => i18n.t('errors.atLeastOneSelected', { entityName: entity }),
    MAPS_PARTIAL_GEOCODER_RESULTS: (address: string) => ({
      message: i18n.t('errors.mapsPartialResult.message'),
      description: i18n.t('errors.mapsPartialResult.description', { address: address }),
    }),
    MAPS_INVALID_GEOCODER_ADDRESS: (address: string) => ({
      message: i18n.t('errors.mapsPlaceNotFound'),
      description: i18n.t('errors.mapsInvalidAddress'),
    }),
    MAPS_GEOCODER_ERROR: (error: string) => ({
      message: i18n.t('errors.mapsPlaceNotFound'),
      description: i18n.t('errors.mapsGeocoderError'),
    }),
    ACCOUNT_ACTIVATION: (error?: string) => ({
      title: i18n.t('errors.accountActivation.message'),
      subTitle: `${i18n.t('errors.accountActivation.descriptionGeneric')} ${
        !error && i18n.t('errors.accountActivation.descriptionNoError')
      } ${error ? getError(error) : ''}`,
    }),
    ACCOUNT_ACTIVATION_INVALID_LINK: {
      title: i18n.t('errors.errorOccurred'),
      subTitle: i18n.t('errors.invalidActivationLink'),
    },
    INCIDENT_HIDING: (error: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.hideIncident')} ${getError(error)}`,
    }),
    REVIEW_UPDATE: (error: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.reviewUpdate')} ${getError(error)}`,
    }),
    CSV_FAIL: (error: string) => ({ message: i18n.t('errors.csvFailed'), description: getError(error) }),
    CSV_COLUMNS: (error: string) => ({ message: i18n.t('errors.csvColumns'), description: getError(error) }),
    CSV_FILTER_ONE_OPTION_SELECTED: i18n.t('errors.csvAtLeastOneSelected'),
    PDF_FAIL: (error: string) => ({ message: i18n.t('errors.pdfFailed'), description: getError(error) }),
    CSV_EMPTY: i18n.t('errors.emptyCsv'),
    CSV_PARSE: i18n.t('errors.csvParse'),
    EVENT_SWAP: (error: string) => ({
      message: i18n.t('errors.typeSwap.message'),
      description: `${i18n.t('errors.typeSwap.description')} ${getError(error)}`,
    }),
    CHART_ERROR: (error?: string) => ({
      message: i18n.t('errors.chart.message'),
      description: `${i18n.t('errors.chart.description')} ${getError(error)}`,
    }),
    LAYOUT_ERROR: (error?: string) => ({
      message: i18n.t('errors.layout.message'),
      description: `${i18n.t('errors.layout.description')} ${getError(error)}`,
    }),
    TEAM_DELETE: (error: string) => ({
      message: i18n.t('errors.deletionFailed'),
      description: `${i18n.t('errors.teamDeleted')} ${getError(error)}`,
    }),
    TEAM_UPDATE: (error: string) => ({
      message: i18n.t('errors.updateFailed'),
      description: `${i18n.t('errors.teamUpdated')} ${getError(error)}`,
    }),
    TEAM_CREATE: (error: string) => ({
      message: i18n.t('errors.creationFailed'),
      description: `${i18n.t('errors.teamCreated')} ${getError(error)}`,
    }),
    EMAIL_NOTIFICATIONS_UPDATED: {
      message: i18n.t('errors.updateFailed'),
      description: i18n.t('errors.emailNotification'),
    },
  };
}

function setSuccessMessages() {
  SuccessMessages = {
    REGISTER: { message: i18n.t('success.registered.message'), description: i18n.t('success.registered.description') },
    ATTACHMENT_UPLOAD: (fileCount: number) => ({
      message: i18n.t('success.uploaded.message'),
      description: i18n.t('success.uploaded.description', { count: fileCount }),
    }),
    ATTACHMENT_DELETE: (fileName: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.attachmentDeleted', { fileName: getFileName(fileName) }),
    }),
    ATTACHMENT_PRIVATE: (newStatus: boolean) => ({
      message: i18n.t('success.updated'),
      description: i18n.t(`success.attachmentUpdated.${newStatus ? 'private' : 'public'}`),
    }),
    USER_ACTIVATE: (name: string, surname: string) => ({
      message: i18n.t('success.activated'),
      description: i18n.t('success.userActivated', { name, surname }),
    }),
    USER_INACTIVATE: (name: string, surname: string) => ({
      message: i18n.t('success.userInactivated.message'),
      description: i18n.t('success.userInactivated.description', { name, surname }),
    }),
    USER_DELETE: (name: string, surname: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.userDeleted', { name, surname }),
    }),
    USER_UNDELETE: (name: string, surname: string) => ({
      message: i18n.t('success.undeleted'),
      description: i18n.t('success.userUndeleted', { name, surname }),
    }),
    USER_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.userUpdated') },
    USER_PASSWORD_CHANGE: {
      message: i18n.t('success.passwordChanged.message'),
      description: i18n.t('success.passwordChanged.description'),
    },
    USER_INVITE_SIGN_UP: (email: string) => ({
      message: i18n.t('success.userInvited.message'),
      description: i18n.t('success.userInvited.description', { email: email }),
    }),
    COMPANY_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.companyUpdated') },
    COMPANY_STATUS_UPDATE: (previousStatus: boolean, companyName: string) => ({
      message: i18n.t(`success.${previousStatus ? `inactivated` : `activated`}`),
      description: i18n.t(`success.companyStatusUpdated.${previousStatus ? 'inactive' : 'active'}`, { companyName }),
    }),
    COMPANY_DELETE: (companyName: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.companyDeleted', { companyName }),
    }),
    SUBSCRIPTIONS_UPDATE: (companyName: string) => ({
      message: i18n.t('success.updated'),
      description: i18n.t('success.subscriptionsUpdated', { companyName }),
    }),
    DIVISION_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.divisionUpdated') },
    DIVISION_CREATE: (divisionName: string) => ({
      message: i18n.t('success.created'),
      description: i18n.t('success.divisionCreated', { divisionName: divisionName }),
    }),
    DIVISION_DELETE: (divisionName: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.divisionDeleted', { divisionName: divisionName }),
    }),
    DIVISION_UNDELETE: (divisionName: string) => ({
      message: i18n.t('success.undeleted'),
      description: i18n.t('success.divisionUndeleted', { divisionName: divisionName }),
    }),
    PERMISSION_REVOKED: (name: string, surname: string, companyName: string) => ({
      message: i18n.t('success.permissionRevoked.message'),
      description: i18n.t('success.permissionRevoked.description', { name, surname, companyName }),
    }),
    OWN_PERMISSION_REVOKED: (companyName: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.ownPermissionRevoked', { companyName }),
    }),
    PERMISSION_ADDED: (email: string, companyName?: string) => ({
      message: i18n.t('success.created'),
      description: i18n.t('success.permissionAdded', { email, companyName: companyName ? companyName : 'company' }),
    }),
    PHONE_ADDED: (phoneNumber: string) => ({
      message: i18n.t('success.created'),
      description: i18n.t('success.phoneAdded', { phoneNumber }),
    }),
    PHONE_DELETE: (phoneNumber: string) => ({
      message: i18n.t('success.deleted'),
      description: i18n.t('success.phoneDeleted', { phoneNumber }),
    }),
    PHOTO_DELETE: { message: i18n.t('success.deleted'), description: i18n.t('success.photoDeleted') },
    PHOTO_PRIVATE: (newStatus: boolean) => ({
      message: i18n.t('success.updated'),
      description: i18n.t(`success.photoUpdated.${newStatus ? 'private' : 'public'}`),
    }),
    INCIDENT_REPORT_CREATE: { message: i18n.t('success.created'), description: i18n.t('success.reportCreated') },
    INCIDENT_REPORT_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.reportUpdated') },
    ACCOUNT_ACTIVATION: (email: string) => ({
      title: i18n.t('success.activated'),
      subTitle: i18n.t('success.accountActivated', { email }),
    }),
    FORGOT_PASSWORD: {
      message: i18n.t('success.passwordReset.message'),
      description: i18n.t('success.passwordReset.description'),
    },
    INCIDENT_HIDING: { message: i18n.t('success.updated'), description: i18n.t('success.incidentHidden') },
    REVIEW_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.reviewUpdated') },
    ACTION_DELETE: { message: i18n.t('success.deleted'), description: i18n.t('success.actionDeleted') },
    ACTION_CREATE: { message: i18n.t('success.created'), description: i18n.t('success.actionCreated') },
    ACTION_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.actionUpdated') },
    CHART_CREATE: { message: i18n.t('success.chart.message'), description: i18n.t('success.chart.description') },
    CHART_EDIT: { message: i18n.t('success.chartEdit.message'), description: i18n.t('success.chartEdit.description') },
    LAYOUT_SAVE: { message: i18n.t('success.layout.message'), description: i18n.t('success.layout.description') },
    LAYOUT_UPDATE: {
      message: i18n.t('success.layoutUpdate.message'),
      description: i18n.t('success.layoutUpdate.description'),
    },
    LAYOUT_DELETE: {
      message: i18n.t('success.layoutDelete.message'),
      description: i18n.t('success.layoutDelete.description'),
    },
    CHART_DELETE: {
      message: i18n.t('success.chartDelete.message'),
      description: i18n.t('success.chartDelete.description'),
    },
    TEAM_DELETE: { message: i18n.t('success.deleted'), description: i18n.t('success.teamDeleted') },
    TEAM_CREATE: { message: i18n.t('success.created'), description: i18n.t('success.teamCreated') },
    TEAM_UPDATE: { message: i18n.t('success.updated'), description: i18n.t('success.teamUpdated') },
    EMAIL_NOTIFICATIONS_UPDATED: {
      message: i18n.t('success.updated'),
      description: i18n.t('success.emailNotification'),
    },
  };
}

function setConfirmationMessages() {
  ConfirmMessages = {
    DELETE_ENTITY: (entity: string) => i18n.t('confirmation.deletion', { entity }),
    UNDELETE_ENTITY: (entity: string) => i18n.t('confirmation.undeletion', { entity }),
    REVOKE_PERMISSION: (name: string, surname: string) =>
      i18n.t('confirmation.permissionRevokation', { name, surname }),
    REVOKE_OWN_PERMISSION: (companyName: string) => i18n.t('confirmation.permissionRevokation', { companyName }),
    PROMPT_CHAT_BOT_TYPE_CHANGE: {
      message: i18n.t('confirmation.promptChatBotTypeChange.message'),
      description: i18n.t('confirmation.promptChatBotTypeChange.description'),
    },
  };
}

setErrorMessages();
setSuccessMessages();
setConfirmationMessages();

function handleLanguageChanged() {
  setErrorMessages();
  setSuccessMessages();
  setConfirmationMessages();
}

i18n.on('languageChanged', () => handleLanguageChanged());

export { ErrorMessages, SuccessMessages, ConfirmMessages };
