import { TermsAndConditionsSection } from '..';

export const TERMS_AND_CONDITIONS_FR: TermsAndConditionsSection[] = [
  {
    title: `Clause de non-responsabilité`,
    paragraphs: [
      `Ne vous fiez pas à HighVizz, ni aux informations qu'il contient, ni à sa continuation. Nous fournissons la plateforme HighVizz et toutes les informations et services sur une base "en l'état" et "selon disponibilité". HighVizz ne contrôle pas et ne vérifie pas l'exactitude du contenu généré par les utilisateurs. Nous ne fournissons aucune garantie ou représentation expresse.`,
      `Dans toute la mesure permise par la loi applicable, nous déclinons toute garantie et représentation implicite, y compris, sans limitation, toute garantie de qualité marchande, d'adéquation à un usage particulier, de titre, d'exactitude des données et de non-contrefaçon. Si vous êtes insatisfait ou lésé par HighVizz ou tout ce qui est lié à HighVizz, vous pouvez fermer votre compte HighVizZ et résilier ce contrat conformément à la section VI ("terme et résiliation" des termes et conditions) et cette résiliation sera votre seul et unique recours.`,
      `Toutes les informations partagées sur HighVizz restent la propriété de la partie qui les partage et sont soumises à l'accord de non-divulgation implicite à l'utilisation du service. Pour que les informations soient partagées en dehors de la plateforme, le propriétaire des informations devra fournir une autorisation. HighVizz n'est donc pas responsable des informations qui sont partagées en dehors de la plateforme sans l'accord préalable de leur propriétaire. HighVizz n'est pas non plus responsable du vol d'informations sur la plateforme par une partie quelconque.`,
      `HighVizz n'est pas responsable, et ne fait aucune représentation ou garantie pour la livraison de tout message envoyé par HighVizz à quiconque. En outre, nous ne garantissons pas que votre utilisation du service ne portera pas atteinte aux droits de tiers. Tout matériel, service ou technologie décrit ou utilisé sur le site web peut être soumis à des droits de propriété intellectuelle détenus par des tiers qui nous ont concédé une licence pour ce matériel, ce service ou cette technologie.`,
      `HighVizz n'a aucune obligation de vérifier l'identité des utilisateurs qui utilisent le service, ni de surveiller l'utilisation du service par les autres utilisateurs ; par conséquent, HighVizz décline toute responsabilité en cas d'usurpation d'identité ou de toute autre utilisation abusive de votre identité ou de vos informations. HighVizz ne garantit pas que le service fonctionnera sans interruption ni erreur de fonctionnement. En particulier, le fonctionnement du service peut être interrompu en raison d'opérations de maintenance, de mises à jour ou de défaillances du système ou du réseau. HighVizz décline toute responsabilité pour les dommages causés par de telles interruptions ou erreurs de fonctionnement. De plus, HighVizz décline toute responsabilité pour tout dysfonctionnement, impossibilité d'accès ou mauvaises conditions d'utilisation du site dus à un équipement inapproprié, à des perturbations liées aux fournisseurs d'accès à Internet, à la saturation du réseau Internet, et pour toute autre raison.`,
    ],
  },
  {
    title: `Politique de confidentialité et de droits d'auteur`,
    paragraphs: [
      `Cette politique de confidentialité s'applique à tous les produits, services et sites web offerts par HighVizz Inc. ou ses filiales ou sociétés affiliées.`,
      `Si vous avez des questions concernant cette politique de confidentialité, n'hésitez pas à nous contacter par courrier électronique à l'adresse suivante : info@HighVizz.com.`,
      {
        header: `Nous pouvons recueillir les types d'informations suivants :`,
        listItems: [
          `Informations que vous fournissez - Lorsque vous vous inscrivez pour un compte HighVizz, nous vous demandons des informations personnelles. Nous pouvons combiner les informations que vous soumettez sous votre compte avec des informations provenant d'autres services HighVizz ou de tiers afin de vous fournir une meilleure expérience et d'améliorer la qualité de nos services. Pour certains services, nous pouvons vous donner la possibilité de refuser de combiner ces informations.`,
          `Cookies - Lorsque vous visitez HighVizz, nous envoyons un ou plusieurs cookies à votre ordinateur ou autre appareil. Nous utilisons des cookies pour améliorer la qualité de notre service, y compris pour stocker les préférences des utilisateurs, améliorer les résultats de recherche et la sélection des annonces, et suivre les tendances des utilisateurs, comme la façon dont les gens font leurs recherches. HighVizz utilise également des cookies dans ses services de publicité pour aider les annonceurs et les éditeurs à diffuser et à gérer les annonces sur le web et sur les services HighVizz.`,
          `Informations de connexion - Lorsque vous accédez aux services HighVizz via un navigateur, une application ou un autre client, nos serveurs enregistrent automatiquement certaines informations. Ces journaux de serveur peuvent inclure des informations telles que votre requête web, votre interaction avec un service, l'adresse IP, le type de navigateur, la langue du navigateur, la date et l'heure de votre requête et un ou plusieurs cookies qui peuvent identifier de manière unique votre navigateur ou votre compte.`,
          `Communications avec les utilisateurs - Lorsque vous envoyez des e-mails ou d'autres communications à HighVizz, nous pouvons conserver ces communications afin de traiter vos demandes, de répondre à vos demandes et d'améliorer nos services. Nous pouvons utiliser votre adresse électronique pour communiquer avec vous au sujet de nos services.`,
          `Services affiliés à HighVizz sur d'autres sites - Nous offrons certains de nos services sur ou via d'autres sites web. Les informations personnelles que vous fournissez à ces sites peuvent être envoyées à HighVizz afin de fournir le service. Nous traitons ces informations dans le cadre de la présente politique de confidentialité.`,
          `Applications tierces - HighVizz peut mettre à disposition des applications tierces, telles que des gadgets ou des extensions, par le biais de ses services. Les informations collectées par HighVizz lorsque vous activez une application tierce sont traitées dans le cadre de la présente politique de confidentialité. Les informations collectées par le fournisseur d'applications tierces sont régies par leur politique de confidentialité`,
          `Numéro d'application unique - Certains services comprennent un numéro d'application unique qui n'est pas associé à votre compte ou à vous. Ce numéro et des informations sur votre installation (par exemple, le type de système d'exploitation, le numéro de version) peuvent être envoyés à HighVizz lorsque vous installez ou désinstallez ce service ou lorsque ce service contacte périodiquement nos serveurs (par exemple, pour demander des mises à jour automatiques du logiciel).`,
          `Autres sites - Cette politique de confidentialité s'applique uniquement aux services de HighVizz. Nous n'exerçons aucun contrôle sur les sites affichés comme résultats de recherche, les sites qui incluent des applications, produits ou services HighVizz, ou des liens à l'intérieur de nos différents services. Ces autres sites peuvent placer leurs propres cookies ou autres fichiers sur votre ordinateur, collecter des données ou vous demander des informations personnelles.`,
        ],
      },
      {
        header: `En plus de ce qui précède, nous pouvons utiliser les informations que nous recueillons pour :`,
        listItems: [
          `Fournir, maintenir, protéger et améliorer nos services et développer de nouveaux services`,
          `Protéger les droits ou la propriété de HighVizz ou de nos utilisateurs`,
          `Si nous utilisons ces informations d'une manière différente de celle pour laquelle elles ont été collectées, nous vous demanderons votre consentement avant de les utiliser.`,
          `HighVizz traite les informations personnelles sur ses serveurs au Royaume-Uni et dans d'autres pays. Dans certains cas, nous traitons des informations personnelles en dehors de votre propre pays.`,
        ],
      },
    ],
  },
  {
    title: `Conditions d'utilisation de HighVizz :`,
    paragraphs: [
      `Cet accord contient tous les termes et conditions de HighVizz Ltd. applicables à l'utilisation des produits et services de HighVizz.`,
      {
        header: `Introduction`,
        number: 1,
        listItems: [
          `Cet accord contient tous les termes et conditions de HighVizz Ltd. applicables à l'utilisation des produits et services de HighVizz Ltd. et du site web www. HighVizz.com (le "Site") et à travers toute application mobile de HighVizz (collectivement, "HighVizz"). Veuillez lire cet accord avant d'utiliser le Site. L'utilisation du Site constitue un accord avec les Conditions d'Utilisation (le présent "Accord"), que vous vous inscriviez ou non en tant qu'utilisateur HighVizz (un "Utilisateur"). Si vous souhaitez devenir un Utilisateur et utiliser les produits et services HighVizz, y compris mais non limité à l'application (collectivement, le "Service"), alors veuillez lire cet Accord. En utilisant HighVizz, tous les Utilisateurs déclarent, garantissent, comprennent, acceptent et acceptent tous les termes et conditions contenus dans ce document. Si vous vous opposez à quoi que ce soit dans ce contrat ou dans la politique de confidentialité de HighVizz, n'utilisez pas HighVizz. Cet accord est sujet à des modifications par HighVizz à tout moment, en vigueur dès la publication sur le Service, et votre utilisation du Service après cette publication constituera une acceptation de votre part de ces modifications.`,
        ],
      },
      {
        header: `Objectif et définitions`,
        number: 2,
        listItems: [
          `Mission : La mission de HighVizz est de permettre à ses utilisateurs de partager et de gérer les informations relatives à la santé et à la sécurité. Pour accomplir notre mission, nous mettons à disposition des services par le biais de notre site web et de nos applications mobiles pour aider les entreprises et les gestionnaires à signaler, surveiller et analyser les incidents de santé et de sécurité ainsi qu'à partager les meilleures pratiques.`,
          `Définition d'un utilisateur : Sont définis comme utilisateurs toutes les entreprises ou individus ayant accédé à HighVizz par le biais de son application ou de son site web.`,
          `Implication de HighVizz dans les activités de la plateforme : HighVizz ne sera en aucune façon impliqué dans les activités de ses utilisateurs sur ou par HighVizz, financièrement ou autrement. En acceptant les présentes Conditions d'Utilisation, l'Utilisateur accepte implicitement de garder confidentielle toute information ou contenu trouvé sur ou reçu par HighVizz, sauf accord contraire avec le propriétaire du contenu. HighVizz ne partagera aucune des informations ou contenus utilisés sur son service avec un tiers autre que les sociétés affiliées, ou les entités qui sont directement nécessaires pour le service.`,
          `Informations ou contenus confidentiels : Est défini comme information ou contenu confidentiel, toute information dans n'importe quel format inclus mais non limité à l'information partagée sur ou par HighVizz. Sont également incluses toutes les informations concernant l'adhésion de tout membre. En bref, toute information trouvée ou obtenue par le biais de la plateforme doit être considérée comme confidentielle et ne doit pas être partagée, distribuée, diffusée ou mentionnée en dehors de HighVizz sans l'approbation requise de son propriétaire.`,
          `Capacité : Vous avez le droit, l'autorité et la capacité de conclure le présent accord en votre nom propre et au nom de toute entité pour laquelle vous agissez et de respecter toutes les conditions contenues dans le présent document, et que si un aspect de votre participation à HighVizz viole les dispositions de la loi locale à laquelle vous êtes soumis, vous cesserez d'utiliser le service et fermerez votre compte.`,
          `Service : Est défini comme le Service, tous les produits et services incluant mais non limité au site, à l'infrastructure du site, à tout système de messagerie et tout autre produit mis à disposition de l'Utilisateur par HighVizz, par le biais de cet accord.`,
          `Tiers et ou affiliés`,
        ],
      },
      {
        header: `Vos obligations`,
        number: 3,
        listItems: [
          `Soumissions : Cette clause définit la licence et la garantie pour vos soumissions à HighVizz`,
          `Vous êtes propriétaire des informations, textes ou graphiques ("Contenu") que vous fournissez à HighVizz dans le cadre de cet accord, et pouvez demander leur suppression à tout moment, sauf si vous avez partagé des informations ou du contenu avec d'autres et qu'ils ne l'ont pas supprimé, ou s'il a été copié ou stocké par d'autres utilisateurs. Toute information que vous soumettez à HighVizz est libre de droits, ne fait l'objet d'aucune demande d'indemnisation et est à vos propres risques de perte.`,
          `En nous fournissant des informations, vous déclarez et garantissez que vous avez le droit de soumettre les informations et que celles-ci sont exactes, non confidentielles et ne violent pas les restrictions contractuelles ou les droits de tiers. Il est de votre responsabilité de maintenir les informations de votre profil HighVizz exactes et à jour.`,
          `Vous ne fournirez à HighVizz, au site, au service et aux autres utilisateurs que le contenu que vous avez le droit de fournir à HighVizz et de permettre à HighVizz d'afficher par le biais du service - ce qui signifie que vous avez des droits adéquats sur toutes les données personnelles et les informations connexes fournies par vous pour l'affichage par HighVizz, et que vous comprenez que tout autre contenu que vous trouvez sur ou par HighVizz est de la seule responsabilité de la personne qui a créé ce contenu.`,
          `HighVizz ne sera pas responsable de l'utilisation de votre Contenu par HighVizz conformément à cet accord. Vous ne vous fiez pas à HighVizz et vous comprenez que nous n'approuvons pas, ne soutenons pas, ne représentons pas ou ne garantissons pas l'exhaustivité, la véracité, l'exactitude ou la fiabilité de tout Contenu ou communications postées via le Service ou n'approuvons pas les opinions exprimées via le Service.`,
          `Vous comprenez qu'en utilisant le Service, vous pouvez être exposé à un Contenu qui pourrait être offensant, nuisible, inexact ou autrement inapproprié, et que vous n'avez aucune réclamation contre HighVizz pour un tel matériel. Vous comprenez que vous n'avez aucune réclamation contre HighVizz pour le placement de publicité ou de contenu similaire sur le Service ou en relation avec l'affichage de Contenu ou d'informations du Service qu'ils soient soumis par vous ou par d'autres.`,
        ],
      },
      {
        header: `Accord de Confidentialité`,
        number: 4,
        listItems: [
          `En tant qu'utilisateur de HighVizz, vous acceptez de garder confidentielles toutes les informations, y compris mais non limité aux données personnelles et informations connexes définies ci-dessus comme "Informations Confidentielles" soumises par tout autre utilisateur de HighVizz, à moins que vous n'ayez obtenu l'approbation écrite de l'utilisateur qui a soumis le contenu à l'origine, pour utiliser le contenu en dehors de HighVizz.`,
        ],
      },
      {
        header: `Admissibilité au service`,
        number: 5,
        listItems: [
          `Pour pouvoir utiliser le service, vous devez répondre aux critères suivants et déclarer et garantir que vous (1) êtes âgé de 18 ans ou plus ; (2) n'êtes pas actuellement restreint du Service, ou n'êtes pas autrement interdit d'avoir un compte HighVizz, (3) n'êtes pas un concurrent de HighVizz ou n'utilisez pas le Service pour des raisons qui sont en concurrence avec HighVizz ; (4) ne maintiendrez qu'un seul compte HighVizz à tout moment ; (5) ont le plein pouvoir et l'autorité de conclure cet accord et ne violeront pas tout autre accord auquel vous êtes partie ; (6) ne violeront aucun droit de HighVizz, y compris les droits de propriété intellectuelle tels que les droits d'auteur ou les droits de marque.`,
        ],
      },
      {
        header: `Pouvoirs de connexion`,
        number: 6,
        listItems: [
          `Vous acceptez : (1) garder votre mot de passe en sécurité et confidentiel ; (2) ne pas permettre à d'autres personnes d'utiliser votre compte ; (3) vous abstenir d'utiliser les comptes d'autres utilisateurs ; (4) vous abstenir de vendre, négocier ou transférer votre compte HighVizz à une autre partie ; et (5) vous abstenir de faire payer à quiconque l'accès à une partie de HighVizz ou à toute information qu'il contient. En outre, vous êtes responsable de tout ce qui se passe sur votre compte jusqu'à ce que vous fermiez votre compte. Si vous avez connaissance d'une utilisation non autorisée des informations de votre compte, vous acceptez d'en informer HighVizz immédiatement. Pour fermer votre compte, veuillez nous contacter par courrier électronique à l'adresse suivante : info@HighVizz.com.`,
        ],
      },
      {
        header: `Indemnisation`,
        number: 7,
        listItems: [
          `Vous nous indemnisez et nous dégagez de toute responsabilité pour tous les dommages, pertes et coûts (y compris, mais sans s'y limiter, les honoraires et coûts d'avocats raisonnables) liés à toutes les réclamations, charges et enquêtes de tiers, causés par (1) votre manquement au présent accord, y compris, sans limitation, votre soumission de contenu qui viole les droits des tiers ou les lois applicables, (2) tout contenu que vous soumettez à d'autres utilisateurs, HighVizz et / ou le Service, et (3) toute activité dans laquelle vous vous engagez sur ou par HighVizz.`,
          `En particulier, vous acceptez d'indemniser et de tenir HighVizz (et tout employé, dirigeant, directeur ou filiale ou affilié de HighVizz, chacun étant une "personne morale") à l'abri (y compris les coûts et frais d'avocats) de toute réclamation ou demande faite par un tiers en raison de ou découlant de votre accès ou utilisation du Service, la violation du présent accord par vous, la violation par vous, ou par tout tiers utilisant votre compte, de toute donnée personnelle ou de tout autre droit de toute personne ou entité, ou pour tout Contenu publié par vous via le Service (y compris les réclamations liées à la diffamation, à l'atteinte à la vie privée ou à toute autre violation des droits d'une personne). Vos obligations en vertu de l'indemnité ci-dessus ne peuvent pas être compensées par toute autre réclamation que vous pourriez avoir contre HighVizz ou toute personne de la société. Vous restez seul responsable de tout le Contenu que vous téléchargez, postez, envoyez par e-mail, transmettez ou diffusez de toute autre manière en utilisant ou en relation avec le Service. Vous acceptez que les dispositions de ce paragraphe survivront à toute résiliation de votre (vos) compte(s) ou du Service.`,
          `En outre, vous renoncez par les présentes à toute réclamation que vous pourriez avoir à l'encontre de HighVizz et de toute personne morale qui est de quelque façon liée au Service ou à votre utilisation du Contenu offert par le biais du Service, y compris toute recommandation ou référence que vous pourriez recevoir à la suite de votre inscription sur HighVizz. Vous êtes seul responsable de votre utilisation du Service, de tout Contenu que vous fournissez, et de toutes les conséquences qui en découlent, y compris l'utilisation de votre Contenu par d'autres utilisateurs et des tiers partenaires.`,
          `Vous comprenez que les employés de HighVizz et ses partenaires peuvent participer au Service et que HighVizz n'est pas responsable de leurs activités, y compris les déclarations ou autres informations dans les emails ou autres communications que ces personnes font en cette qualité.`,
        ],
      },
      {
        header: `Renouvellement et annulation de l'abonnement`,
        number: 8,
        listItems: [
          `Les abonnements et certaines fonctions payantes (collectivement, les "fonctions") peuvent être automatiquement prolongés pour des périodes de renouvellement successives de la même durée que l'abonnement et/ou la fonction choisie à l'origine. HighVizz peut continuer à renouveler les abonnements et/ou les fonctionnalités jusqu'à ce que l'utilisateur demande une annulation. Pour annuler un abonnement et/ou une fonctionnalité, l'utilisateur peut contacter HighVizz par e-mail à [info@HighVizz.com], ou en modifiant les paramètres du compte de l'utilisateur via le site. Si l'utilisateur annule son adhésion et/ou sa fonction, il aura accès aux avantages de l'adhésion jusqu'à la fin de la période d'adhésion et/ou de fonction en cours ; l'adhésion et/ou la fonction ne sera pas renouvelée après l'expiration de cette période. L'utilisateur n'aura droit à aucun remboursement d'une quelconque partie des frais d'adhésion et/ou de fonctionnalité payés pour la période d'adhésion et/ou de fonctionnalité en cours à ce moment-là.`,
          `L'adhésion commence le 1er juillet pour une période de 12 mois. L'adhésion permet à tous les employés d'une même entreprise d'accéder à la plate-forme par le biais de leurs identifiants personnels. Une cotisation annuelle unique doit être payée dans les 30 jours suivant le début de l'adhésion. Une fois payée, la cotisation ne peut être remboursée.`,
        ],
      },
      {
        header: `Obligations de paiement`,
        number: 9,
        listItems: [
          `Vous acceptez de payer pour le service selon les termes convenus dans le contrat d'abonnement. Les frais dus dépendent du type et de la quantité spécifiques des fonctions commandées de temps à autre. Le paiement des frais ne sera pas subordonné à d'autres événements que la livraison du service et des éléments commandés.`,
          `Vous acceptez de payer les frais actuellement en vigueur pour le Service et les Éléments que vous commandez. HighVizz vous informera par voie électronique 30 jours à l'avance avant de procéder à toute modification de ces frais. Il est de votre responsabilité de fournir rapidement à HighVizz tout changement ou mise à jour des coordonnées ou des informations de facturation (y compris le numéro de téléphone, les numéros de carte de crédit, les adresses e-mail, etc.) ). Les mises à jour de compte peuvent être effectuées sur le site. Votre abonnement est destiné à votre usage personnel et ne peut être cédé, sous-licencié, distribué, partagé, consulté, accédé ou transféré de toute autre manière à quiconque. Les privilèges de service peuvent être modifiés de temps en temps et peuvent être soumis à des restrictions d'inscription et de recherche.`,
        ],
      },
      {
        header: `Notifications et messages de service`,
        number: 10,
        listItems: [
          `Pour les besoins des messages de service et des avis concernant les services qui vous sont destinés, HighVizz peut placer une bannière de notification sur ses pages pour vous avertir de certains changements tels que les modifications du présent accord. Alternativement, la notification peut consister en un email de HighVizz à une adresse email associée à votre compte, même si nous avons d'autres informations de contact. Vous acceptez également que HighVizz puisse communiquer avec vous par le biais de votre compte HighVizz ou par d'autres moyens, y compris par e-mail, numéro de téléphone portable, téléphone ou services de livraison, y compris le service postal, à propos de votre compte HighVizz ou des services associés à HighVizz. Veuillez vérifier les paramètres de votre compte pour contrôler le type de messages que vous recevez de HighVizz. Vous reconnaissez et acceptez que nous n'aurons aucune responsabilité associée ou découlant de votre incapacité à maintenir un contact précis ou d'autres informations, y compris, mais sans s'y limiter, votre incapacité à recevoir des informations critiques sur le Service.`,
        ],
      },
      {
        header: `Vie privée et protection des informations personnelles`,
        number: 11,
        listItems: [
          `Vous devez lire attentivement notre politique de confidentialité complète avant de décider de devenir un utilisateur, car elle est intégrée par référence au présent accord et régit notre traitement de toute information, y compris les informations personnelles identifiables que vous nous soumettez. Veuillez noter que certaines informations, déclarations, données et contenus (tels que des photographies) que vous pouvez soumettre à HighVizz, au site, au service et/ou à d'autres utilisateurs, révèlent votre sexe, votre origine ethnique, votre nationalité, votre âge et/ou d'autres informations personnelles vous concernant ou concernant d'autres personnes. Vous reconnaissez que la soumission de toute information, déclaration, donnée et contenu est volontaire de votre part.`,
        ],
      },
      {
        header: `Contributions à HighVizz`,
        number: 12,
        listItems: [
          `En soumettant toute information ou donnée ("Contributions") à HighVizz, vous reconnaissez et acceptez que (a) vos Contributions ne contiennent pas d'informations confidentielles ou propriétaires pour lesquelles vous ne détenez pas les droits de partage pertinents ; (b) HighVizz gardera confidentielles toutes les Contributions ; (c) vous n'avez droit à aucune compensation ou remboursement de quelque nature que ce soit de la part de HighVizz en aucune circonstance.`,
        ],
      },
      {
        header: `Conditions d'adhésion`,
        number: 13,
        listItems: [
          `Vous avez le droit, l'autorité et la capacité de conclure cet accord en votre propre nom et au nom de toute entité pour laquelle vous agissez et de respecter tous les termes et conditions contenus dans ce document, et que si un aspect de votre participation à HighVizz viole les dispositions de la loi locale à laquelle vous êtes soumis, vous cesserez d'utiliser le Service et fermerez votre compte.`,
        ],
      },
      {
        header: `Autres engagements nécessaires pour nous permettre de fournir le service`,
        number: 14,
        listItems: [
          `Vous reconnaissez que HighVizz n'est pas obligé de vous payer ou de faire en sorte qu'une autre partie vous paie quoi que ce soit en rapport avec vos activités sur HighVizz, ou de présenter ou d'afficher votre Contenu ou vos Contributions sur le Site.`,
          `Vous reconnaissez que vous ne comptez pas sur HighVizz pour surveiller ou modifier le Service (y compris les e-mails initiés par des individus, indépendamment du fait que ces individus soient ou non associés à HighVizz) et que le Service peut contenir du Contenu ou des Contributions que vous trouvez offensant ou qui sont fausses ou trompeuses et vous renoncez par les présentes à toute objection que vous pourriez avoir en ce qui concerne la visualisation de ce Contenu ou de ces Contributions.`,
          `Vous acceptez que le présent contrat ne vous donne droit à aucun support, mise à niveau, mise à jour, correctif, amélioration ou correction pour les Services (collectivement, "Mises à jour"). HighVizz, cependant, peut occasionnellement fournir des Mises à jour automatiques du Service à sa seule discrétion (et sans aucune notification préalable). De telles Mises à jour pour le Service feront partie du Service et seront soumises au présent Accord.`,
          `Nous pouvons mettre à disposition une ou plusieurs API pour interagir avec le Service. Votre utilisation de toute API HighVizz est soumise au présent contrat et aux règles de l'API HighVizz, qui seront publiées avant que nous mettions ces API à disposition (et feront partie du présent contrat dès leur publication).`,
        ],
      },
      {
        header: `Règles de la communauté HighVizz`,
        number: 15,
        listItems: [
          `Comme condition d'accès à HighVizz, vous acceptez le présent accord et vous vous engagez à respecter strictement les points suivants`,
          {
            header: `Les participants à la communauté HighVizz devront :`,
            listItems: [
              `Se conformer à toutes les lois applicables, y compris, sans s'y limiter, les lois sur la protection de la vie privée, les lois sur la protection des données personnelles et les exigences réglementaires;`,
              `Nous fournir des informations exactes et les mettre à jour si nécessaire;`,
              `Examiner et respecter notre politique de confidentialité;`,
              `Revoir et se conformer aux avis envoyés par HighVizz concernant le Service; et`,
              `Utilisez le service de manière professionnelle.`,
            ],
          },
          {
            header: `Les participants à la communauté HighVizz ne devront pas :`,
            listItems: [
              `Publier des informations inexactes dans les champs prévus à cet effet sur le profil/formulaire d'inscription. Veuillez également protéger les informations personnelles sensibles telles que votre adresse électronique, votre numéro de téléphone, votre adresse postale ou toute autre information de nature confidentielle.`,
              `Créer un profil d'utilisateur pour toute personne autre qu'une personne physique ;`,
              `Divulguer toute information mise à disposition sur HighVizz sans l'accord préalable de son propriétaire;`,
              `Harceler, abuser ou nuire à une autre personne, y compris en envoyant des communications importunes à d'autres personnes utilisant HighVizz;`,
              `Utiliser ou tenter d'utiliser le compte d'une autre personne sans l'autorisation de HighVizz, ou créer une fausse identité sur HighVizz;`,
              {
                header: `Télécharger, poster, envoyer par e-mail, transmettre ou rendre disponible ou initier tout contenu qui :`,
                listItems: [
                  `déclare faussement, se fait passer pour vous ou déforme votre identité de toute autre manière, y compris mais sans s'y limiter, l'utilisation d'un pseudonyme, ou déforme vos positions et qualifications actuelles ou antérieures, ou vos affiliations avec une personne ou une entité, passée ou présente;`,
                  `est illégal, diffamatoire, abusif, obscène, discriminatoire ou autrement répréhensible;`,
                  `Ajoute à un champ de contenu un contenu qui n'est pas destiné à un tel champ (c'est-à-dire en soumettant un numéro de téléphone dans le "titre" ou tout autre champ, ou en incluant des numéros de téléphone, des adresses électroniques, des adresses postales ou toute information personnelle identifiable pour laquelle il n'existe pas de champ fourni par HighVizz);`,
                  `comprend les informations que vous n'avez pas le droit de divulguer ou de rendre disponibles en vertu d'une loi ou d'un règlement (comme les informations divulguées en violation des lois et règlements sur la protection des données personnelles). Cette interdiction inclut, sans s'y limiter, l'envoi de messages contenant des virus logiciels, des vers ou tout autre code, fichier ou programme informatique qui interrompent, détruisent ou limitent la fonctionnalité de tout logiciel ou matériel informatique ou de tout équipement de télécommunication de HighVizz ou de tout Utilisateur de HighVizz ; et/ou Forge des en-têtes ou manipule autrement les identifiants afin de dissimuler l'origine de toute communication transmise par le biais des Services.`,
                ],
              },
              `Reverse engineer, décompiler, désassembler, déchiffrer ou tenter de toute autre manière de dériver le code source de toute propriété intellectuelle sous-jacente utilisée pour fournir les Services, ou toute partie de ceux-ci;`,
              `Utiliser ou copier les informations, le contenu ou toute donnée que vous visualisez et/ou obtenez de HighVizz pour fournir tout service qui est en concurrence, à la seule discrétion de HighVizz, avec HighVizz;`,
              `Impliquer ou déclarer, directement ou indirectement, que vous êtes affilié ou approuvé par HighVizz à moins que vous n'ayez conclu un accord écrit avec HighVizz (ceci inclut, mais n'est pas HighVizz à, vous représenter comme un formateur accrédité HighVizz si vous n'avez pas été certifié par HighVizz en tant que tel);`,
              `Adapter, modifier ou créer des travaux dérivés basés sur HighVizz ou la technologie sous-jacente au Service, ou le Contenu d'autres utilisateurs, en tout ou en partie;`,
              `Faire un lien profond vers le Site pour quelque raison que ce soit, (c'est-à-dire inclure un lien vers une page web HighVizz autre que la page d'accueil de HighVizz) sauf autorisation écrite expresse de HighVizz ou dans le but de promouvoir votre profil;`,
              `Supprimer tout avis de droit d'auteur, de marque commerciale ou de tout autre droit de propriété contenu dans ou sur HighVizz, y compris ceux de HighVizz et de ses concédants de licence;`,
              `Supprimer, couvrir ou masquer toute forme de publicité incluse dans HighVizz;`,
              `Collecter, utiliser, copier ou transférer toute information, y compris, mais sans s'y limiter, les données personnelles obtenues de HighVizz, sauf si cela est expressément autorisé dans le présent accord ou si le propriétaire de ces informations le permet expressément;`,
              `Partager des informations de non-utilisateurs sans leur consentement exprès;`,
              `Violer ou utiliser la marque, les logos et/ou les marques de HighVizz, y compris, sans limitation, l'utilisation du mot " HighVizz " dans tout nom commercial, email, ou URL ou y compris les marques et logos de HighVizz sauf si expressément autorisé par HighVizz;`,
              `Utiliser des logiciels manuels ou automatisés, des dispositifs, des scripts, des robots, d'autres moyens ou procédés pour accéder, "gratter", "crawler" ou "spider" toute page web ou autres services contenus dans le site;`,
              `Utiliser des robots ou d'autres méthodes automatisées pour accéder à HighVizz, ajouter ou télécharger des contacts, envoyer ou rediriger des messages, ou effectuer d'autres activités par l'intermédiaire de HighVizz, sauf autorisation explicite de HighVizz;`,
              `Accéder, par des moyens ou des processus automatisés ou manuels, à HighVizz dans le but de surveiller la disponibilité, la performance ou la fonctionnalité de HighVizz à des fins de concurrence;`,
              `S'engager dans le "framing", le "mirroring" ou simuler de toute autre manière l'apparence ou la fonction du site internet de HighVizz;`,
              `Tenter d'accéder ou accéder effectivement à HighVizz par tout autre moyen que les interfaces fournies par HighVizz, comme son application mobile ou en naviguant sur http://www.HighVizz.com à l'aide d'un navigateur web. Cette interdiction inclut l'accès ou la tentative d'accès à HighVizz en utilisant tout service tiers, y compris les plateformes de type "software-as-a-service" qui regroupent l'accès à plusieurs services, dont HighVizz;`,
              `Tenter ou passer outre tout composant de sécurité inclus dans ou sous-jacent à HighVizz;`,
              `S'engager dans toute action qui interfère directement ou indirectement avec le bon fonctionnement de l'infrastructure de HighVizz ou qui lui impose une charge déraisonnable, y compris, mais sans s'y limiter, l'envoi de communications non sollicitées à d'autres utilisateurs ou au personnel de HighVizz, la tentative d'accès non autorisé à HighVizz, ou la transmission ou l'activation de virus informatiques par ou sur HighVizz;`,
              `Interférer avec ou perturber ou jouer à HighVizz ou au Service, y compris, mais sans s'y limiter, tout serveur ou réseau connecté à HighVizz, en particulier les algorithmes de recherche de HighVizz.`,
              `Réclamer tout droit de propriété sur tout matériel, logiciel ou autre propriété intellectuelle affiché, publié ou autrement disponible sur HighVizz, autre que les informations et données personnelles que vous possédez ou sur lesquelles vous avez des droits sans égard à leur apparence sur HighVizz.`,
              {
                header: `Poster ou soumettre tout contenu ou contribution qui :`,
                listItems: [
                  `contiennent des commentaires offensants liés à la race, l'origine nationale, le sexe, la préférence sexuelle ou le handicap physique;`,
                  `comprend un contenu ou un langage haineux, profane, obscène, indécent, pornographique, sexuel ou autrement répréhensible;`,
                  `diffamer, calomnier, ridiculiser, railler, dénigrer, menacer, harceler, intimider ou abuser de quiconque;`,
                  `promouvoir la violence, la consommation de drogues illégales ou l'abus de substances ou décrire comment accomplir un acte violent, utiliser des drogues illégales ou abuser d'autres substances;`,
                  `violer les droits contractuels, personnels, de propriété intellectuelle ou autres de toute personne, ou promouvoir ou constituer une activité illégale;`,
                  `révéler toute information personnelle concernant une autre personne, y compris l'adresse, le numéro de téléphone, l'adresse électronique, le numéro de carte de crédit ou toute information pouvant être utilisée pour suivre, contacter ou se faire passer pour cette personne, sans le consentement éclairé de cette personne en vertu des lois et règlements applicables en matière de protection des données personnelles;`,
                  `tromper, escroquer, tromper ou induire en erreur HighVizz ou d'autres utilisateurs, en particulier dans toute tentative d'apprendre des informations sensibles;`,
                  `faire un usage impropre des services de support de HighVizz ou soumettre de faux rapports d'abus ou de mauvaise conduite;`,
                  `falsifier l'en-tête d'un paquet TCP/IP ou une partie de l'information de l'en-tête dans un courriel ou un message, ou utiliser de quelque façon que ce soit le Service pour envoyer des informations altérées, trompeuses ou fausses permettant d'identifier la source;`,
                  `couvrir ou obscurcir tout avis, bannière, publicité ou autre marque sur le Service;`,
                  `déguiser la source des documents ou autres informations que vous soumettez au Service ou utiliser des outils qui anonymisent votre adresse de protocole Internet (par exemple, proxy anonyme) pour accéder au Service; ou`,
                  `interférer avec ou contourner tout dispositif de sécurité du Service ou tout dispositif qui restreint ou fait respecter les limitations d'utilisation ou d'accès au Service ou au Contenu.`,
                ],
              },
            ],
          },
        ],
      },
      {
        header: `Disponibilité du service`,
        number: 16,
        listItems: [
          `Tant que HighVizz continue à offrir le service, HighVizz peut fournir et chercher à mettre à jour, améliorer et étendre le service. En conséquence, nous vous permettons d'accéder à HighVizz tel qu'il peut exister et être disponible à tout moment et n'avons pas d'autres obligations, sauf celles expressément énoncées dans le présent accord. Nous pouvons modifier, remplacer, refuser l'accès, suspendre ou interrompre HighVizz, partiellement ou entièrement, ou changer et modifier les prix de tout ou partie des Services pour vous ou pour tous nos utilisateurs à notre seule discrétion. Tous ces changements seront effectifs dès leur publication sur notre site ou par communication directe avec vous, sauf indication contraire. HighVizz se réserve en outre le droit de retenir, de supprimer ou de jeter tout contenu disponible dans le cadre de votre compte, avec ou sans préavis si HighVizz juge que cela est contraire au présent accord. Pour éviter tout doute, HighVizz n'a aucune obligation de stocker, maintenir ou vous fournir une copie de tout contenu que vous ou d'autres utilisateurs fournissent lors de l'utilisation des services, sauf si cela concerne des données personnelles.`,
        ],
      },
      {
        header: `Connexions et interactions avec les autres Utilisateurs`,
        number: 17,
        listItems: [
          `Vous êtes seul responsable de vos interactions avec les autres Utilisateurs. HighVizz peut limiter le nombre de connexions que vous pouvez avoir avec d'autres Utilisateurs et peut, dans certaines circonstances, vous interdire de contacter d'autres Utilisateurs par l'utilisation du Service ou limiter votre utilisation du Service de toute autre manière.`,
          `HighVizz se réserve le droit, mais n'a aucune obligation, de surveiller les litiges entre vous et les autres Utilisateurs et de restreindre, suspendre ou fermer votre compte si HighVizz le détermine, à notre seule discrétion.`,
          `Si vous avez un litige avec d'autres utilisateurs, vous libérez HighVizz et acceptez par la présente d'indemniser HighVizz des réclamations, demandes et dommages (réels et consécutifs) de toute nature, connus et inconnus, découlant ou liés de quelque façon que ce soit à ce litige.`,
        ],
      },
      {
        header: `Décharge de responsabilité`,
        number: 18,
        listItems: [
          `Ne vous fiez pas à HighVizz, ni aux informations qu'il contient, ni à sa continuation. Nous fournissons la plateforme HighVizz et toutes les informations et services sur une base "en l'état" et "selon disponibilité". HighVizz ne contrôle pas et ne vérifie pas l'exactitude du contenu généré par les utilisateurs. Nous ne fournissons aucune garantie ou représentation expresse.`,
          `Dans toute la mesure permise par la loi applicable, nous déclinons toute garantie et représentation implicite, y compris, sans limitation, toute garantie de qualité marchande, d'adéquation à un usage particulier, de titre, d'exactitude des données et de non-contrefaçon. Si vous êtes insatisfait ou lésé par HighVizz ou tout ce qui est lié à HighVizz, vous pouvez fermer votre compte HighVizz et résilier ce contrat conformément à la section vi ("terme et résiliation" des termes et conditions) et cette résiliation sera votre seul et unique recours.`,
          `Toute information partagée sur HighVizz reste la propriété de la partie qui la partage et est soumise à l'accord de non-divulgation implicite à l'utilisation du service. Pour que les informations soient partagées en dehors de la plateforme, le propriétaire des informations devra fournir une autorisation. HighVizz n'est donc pas responsable des informations qui sont partagées en dehors de la plateforme sans l'accord préalable de leur propriétaire. HighVizz n'est pas non plus responsable du vol d'informations sur la plateforme par une partie quelconque.`,
          `HighVizz n'est pas responsable, et ne fait aucune représentation ou garantie pour la livraison de tout message envoyé par HighVizz à quiconque. En outre, nous ne garantissons pas que votre utilisation du service ne portera pas atteinte aux droits de tiers. Tout matériel, service ou technologie décrit ou utilisé sur le site web peut être soumis à des droits de propriété intellectuelle détenus par des tiers qui nous ont concédé une licence pour ce matériel, ce service ou cette technologie`,
          `HighVizz n'a aucune obligation de vérifier l'identité des utilisateurs du service, ni de surveiller l'utilisation du service par d'autres utilisateurs ; par conséquent, HighVizz décline toute responsabilité en cas de vol d'identité ou de toute autre utilisation abusive de votre identité ou de vos informations. HighVizz ne garantit pas que le service fonctionnera sans interruption ni erreur de fonctionnement. En particulier, le fonctionnement du service peut être interrompu en raison d'opérations de maintenance, de mises à jour ou de défaillances du système ou du réseau. HighVizz décline toute responsabilité pour les dommages causés par de telles interruptions ou erreurs de fonctionnement. De plus, HighVizz décline toute responsabilité pour tout dysfonctionnement, impossibilité d'accès ou mauvaises conditions d'utilisation du site dus à un équipement inapproprié, à des perturbations liées aux fournisseurs d'accès à Internet, à la saturation du réseau Internet, et pour toute autre raison.`,
        ],
      },
      {
        header: `Limitation de la responsabilité`,
        number: 19,
        listItems: [
          `Ni HighVizz ni aucune de nos filiales, sociétés affiliées, employés, actionnaires ou directeurs (collectivement, "HighVizz Affiliés") ne seront cumulativement responsables (a) de tout dommage supérieur au montant que vous avez payé à HighVizz dans les quatre-vingt-dix (90) jours précédant immédiatement la date à laquelle vous avez fait valoir une telle réclamation pour la première fois, le cas échéant, ou 100 GBP, selon le montant le plus élevé, ou (b) tout dommage spécial, accessoire, indirect, punitif ou consécutif ou perte d'utilisation, de profit, de revenu ou de données pour vous ou toute autre personne résultant de votre utilisation du Service, de toute application de la plate-forme ou de tout contenu ou autre matériel sur, accessible par ou téléchargé à partir de HighVizz. Cette limitation de responsabilité fait partie de la base de l'accord entre les parties et sans elle, les termes et les prix facturés seraient différents. Cette limitation de responsabilité s'applique indépendamment du fait que (1) vous basiez votre réclamation sur un contrat, un délit, une loi ou toute autre théorie juridique, (2) nous connaissions ou aurions dû connaître la possibilité de tels dommages, ou (3) les recours limités prévus dans cette section ne remplissent pas leur objectif essentiel.`,
        ],
      },
      {
        header: `Durée et résiliation`,
        number: 20,
        listItems: [
          `Sauf résiliation par HighVizz, le présent accord restera en vigueur pendant que vous utilisez l'un des services. Vous pouvez résilier le présent accord à tout moment en supprimant tout le contenu que vous avez fourni à HighVizz et en cessant d'utiliser le service. HighVizz peut mettre fin à cet accord à tout moment, en particulier si vous violez une disposition de cet accord. Lors de la résiliation de cet accord pour une raison quelconque, vous devez détruire et retirer de tous les ordinateurs et autres supports de stockage toutes les copies de la propriété intellectuelle appartenant à HighVizz ou à tout autre utilisateur que vous avez acquis en utilisant le service. Vos déclarations dans le présent accord et les dispositions des sections II et toute autre disposition qui, par leur nature, sont conçues pour survivre à la résiliation, survivront à toute résiliation ou expiration du présent accord.`,
        ],
      },
      {
        header: `Règlement des litiges`,
        number: 21,
        listItems: [
          `Cet accord ou toute réclamation, cause d'action ou litige ("réclamation") découlant de ou liée à cet accord sera régi par les lois du Royaume-Uni, quel que soit votre pays d'origine ou le lieu où vous accédez à HighVizz, et nonobstant tout conflit de principes de droit et la Convention des Nations Unies sur la vente internationale de marchandises. Vous et HighVizz acceptez que toutes les réclamations découlant de ou liées à cet accord doivent être résolues exclusivement par un tribunal situé à Londres, Royaume-Uni. Vous et HighVizz acceptez de vous soumettre à la juridiction personnelle des tribunaux situés à Londres, au Royaume-Uni, afin de plaider toutes ces réclamations. Nonobstant ce qui précède, vous acceptez que HighVizz soit toujours autorisé à demander des mesures d'injonction (ou un type équivalent de mesure juridique urgente) dans n'importe quelle juridiction.`,
        ],
      },
      {
        header: `Renonciation / Divisibilité`,
        number: 22,
        listItems: [
          `L'échec de HighVizz à exiger ou à faire respecter la stricte exécution par vous de toute disposition du présent accord ou à exercer tout droit en vertu du présent accord ne doit pas être interprété comme une renonciation ou un abandon du droit de HighVizz à faire valoir ou à se fonder sur une telle disposition ou droit dans ce cas ou dans tout autre cas. En fait, HighVizz peut choisir d'appliquer certaines parties du présent accord de manière plus stricte ou d'interpréter certaines dispositions de manière plus stricte à l'égard de certains utilisateurs qu'à l'égard des utilisateurs en général, et un tel traitement disparate ne constitue pas un motif de non-respect du présent accord tel qu'il est interprété. Si une partie du présent accord est jugée illégale, nulle ou exécutoire par un tribunal compétent, le présent accord dans son ensemble ne sera pas jugé illégal, nul ou inapplicable, mais seule la partie du présent accord qui est illégale, nulle ou inapplicable sera supprimée de l'accord.`,
        ],
      },
      {
        header: `Prescription`,
        number: 23,
        listItems: [
          `Vous et HighVizz acceptez tous les deux que, indépendamment de tout statut ou loi contraire, toute réclamation ou cause d'action découlant de ou liée à l'utilisation du Service, cet accord doit être déposé dans un délai d'UN (1) AN après que cette réclamation ou cause d'action soit survenue ou soit à jamais prescrite.`,
          `Aucun tiers bénéficiaire. Le présent accord est conclu entre vous et HighVizz. Aucun utilisateur n'a le droit de forcer HighVizz à faire valoir ses droits contre vous ou tout autre utilisateur.`,
        ],
      },
      {
        header: `Politique de confidentialité et de droits d'auteur`,
        number: 24,
        listItems: [
          {
            header: `Si vous pensez qu'un élément du service viole le présent accord ou vos droits en matière de données personnelles, veuillez en informer HighVizz dès que possible en envoyant un courriel ou une lettre au responsable de la protection des données de HighVizz (voir liste ci-dessous). Cette politique de confidentialité s'applique à tous les produits, services et sites web offerts par HighVizz Inc. ou ses filiales ou sociétés affiliées. Si vous avez des questions concernant cette politique de confidentialité, n'hésitez pas à nous contacter par courrier électronique à l'adresse suivante : info@HighVizz.com. Nous pouvons recueillir les types d'informations suivants :`,
            listItems: [
              `Informations que vous fournissez - Lorsque vous vous inscrivez pour un compte HighVizz, nous vous demandons des informations personnelles. Nous pouvons combiner les informations que vous soumettez dans le cadre de votre compte avec des informations provenant d'autres services HighVizz ou de tiers afin de vous offrir une meilleure expérience et d'améliorer la qualité de nos services. Pour certains services, nous pouvons vous donner la possibilité de refuser de combiner ces informations`,
              `Cookies - Lorsque vous visitez HighVizz, nous envoyons un ou plusieurs cookies à votre ordinateur ou autre appareil. Nous utilisons des cookies pour améliorer la qualité de notre service, notamment pour stocker les préférences des utilisateurs, améliorer les résultats de recherche et la sélection des annonces, et suivre les tendances des utilisateurs, comme la façon dont les gens font leurs recherches. HighVizz utilise également des cookies dans ses services de publicité pour aider les annonceurs et les éditeurs à diffuser et à gérer les annonces sur le web et sur les services HighVizz.`,
              `Informations de connexion - Lorsque vous accédez aux services HighVizz via un navigateur, une application ou un autre client, nos serveurs enregistrent automatiquement certaines informations. Ces journaux de serveur peuvent inclure des informations telles que votre requête web, votre interaction avec un service, l'adresse IP, le type de navigateur, la langue du navigateur, la date et l'heure de votre requête et un ou plusieurs cookies qui peuvent identifier de manière unique votre navigateur ou votre compte.`,
              `Communications des utilisateurs - Lorsque vous envoyez des e-mails ou d'autres communications à HighVizz, nous pouvons conserver ces communications afin de traiter vos demandes, de répondre à vos demandes et d'améliorer nos services. Nous pouvons utiliser votre adresse électronique pour communiquer avec vous au sujet de nos services.`,
              `Services HighVizz affiliés sur d'autres sites - Nous offrons certains de nos services sur ou via d'autres sites web. Les informations personnelles que vous fournissez à ces sites peuvent être envoyées à HighVizz afin de fournir le service. Nous traitons ces informations dans le cadre de la présente politique de confidentialité.`,
              `Applications tierces - HighVizz peut mettre à disposition des applications tierces, telles que des gadgets ou des extensions, par le biais de ses services. Les informations collectées par HighVizz lorsque vous activez une application tierce sont traitées dans le cadre de la présente politique de confidentialité. Les informations collectées par le fournisseur d'applications tierces sont régies par leur politique de confidentialité.`,
              `Numéro d'application unique - Certains services comprennent un numéro d'application unique qui n'est pas associé à votre compte ou à vous. Ce numéro et des informations sur votre installation (par exemple, le type de système d'exploitation, le numéro de version) peuvent être envoyés à HighVizz lorsque vous installez ou désinstallez ce service ou lorsque ce service contacte périodiquement nos serveurs (par exemple, pour demander des mises à jour automatiques du logiciel).`,
              `Autres sites - Cette politique de confidentialité s'applique uniquement aux services de HighVizz. Nous n'exerçons aucun contrôle sur les sites affichés comme résultats de recherche, les sites qui incluent des applications, produits ou services HighVizz, ou des liens à l'intérieur de nos différents services. Ces autres sites peuvent placer leurs propres cookies ou autres fichiers sur votre ordinateur, collecter des données ou vous demander des informations personnelles.`,
            ],
          },
          {
            header: `En plus de ce qui précède, nous pouvons utiliser les informations que nous recueillons pour:`,
            listItems: [
              `Fournir, maintenir, protéger et améliorer nos services et développer de nouveaux service`,
              `Protéger les droits ou la propriété de HighVizz ou de nos utilisateurs`,
              `Si nous utilisons ces informations d'une manière différente de celle pour laquelle elles ont été collectées, nous vous demanderons votre consentement avant de les utiliser.`,
              `HighVizz traite les informations personnelles sur ses serveurs au Royaume-Uni et dans d'autres pays. Dans certains cas, nous traitons des informations personnelles en dehors de votre propre pays.`,
            ],
          },
        ],
      },
      {
        header: `Choix`,
        number: 25,
        listItems: [
          `La plupart des navigateurs sont initialement configurés pour accepter les cookies, mais vous pouvez réinitialiser votre navigateur pour qu'il refuse tous les cookies ou pour qu'il vous indique quand un cookie est envoyé. Cependant, certaines fonctions et services de HighVizz peuvent ne pas fonctionner correctement si vos cookies sont désactivés.`,
        ],
      },
      {
        header: `Partage d'informations`,
        number: 26,
        listItems: [
          {
            header: `HighVizz ne partage les données personnelles avec d'autres sociétés ou individus en dehors de HighVizz que dans les circonstances limitées suivantes:`,
            listItems: [
              `Nous avons votre consentement. Nous avons besoin de votre consentement. Nous avons besoin de votre consentement pour le partage de toute donnée personnelle sensible.`,
              `Nous fournissons ces informations à nos filiales, sociétés affiliées ou autres entreprises ou personnes de confiance dans le but de traiter les données personnelles en notre nom. Nous exigeons que ces parties acceptent de traiter ces informations sur la base de nos instructions et en conformité avec la présente politique de confidentialité et toute autre mesure de confidentialité et de sécurité appropriée.`,
              `Nous croyons de bonne foi que l'accès, l'utilisation, la conservation ou la divulgation de ces informations est raisonnablement nécessaire pour (a) satisfaire à toute loi, réglementation, procédure légale ou demande gouvernementale applicable, (b) faire respecter les conditions d'utilisation applicables, y compris les enquêtes sur les violations potentielles de celles-ci, (c) détecter, prévenir ou traiter autrement la fraude, la sécurité ou les problèmes techniques, ou (d) protéger contre les atteintes aux droits, à la propriété ou à la sécurité de HighVizz, de ses utilisateurs ou du public, comme requis ou autorisé par la loi.`,
              `Si HighVizz est impliqué dans une fusion, une acquisition, ou toute forme de vente de tout ou partie de ses actifs, nous assurerons la confidentialité de toute information personnelle impliquée dans de telles transactions et fournirons un avis avant que les informations personnelles ne soient transférées et ne soient soumises à une politique de confidentialité différente.`,
            ],
          },
        ],
      },
      {
        header: `Protection des données`,
        number: 27,
        listItems: [
          {
            header: `DÉFINITIONS`,
            listItems: [
              `Contrôleur, responsable du traitement, personne concernée, données personnelles, violation de données personnelles, traitement et mesures techniques et organisationnelles appropriées : tels que définis dans la législation sur la protection des données ;`,
              `Législation sur la protection des données : la législation britannique sur la protection des données et toute autre législation de l'Union européenne relative aux données personnelles et toutes les autres lois et exigences réglementaires en vigueur de temps à autre qui s'appliquent à une partie en ce qui concerne l'utilisation des données personnelles (y compris, sans s'y limiter, la confidentialité des communications électroniques) ;`,
              `Législation britannique sur la protection des données : toute la législation applicable en matière de protection des données et de la vie privée en vigueur de temps à autre au Royaume-Uni, y compris le règlement général sur la protection des données ((UE) 2016/679) ; la loi sur la protection des données 2018 ; la directive 2002/58/CE sur la vie privée et les communications électroniques (mise à jour par la directive 2009/136/CE) et le règlement de 2003 sur la vie privée et les communications électroniques (SI 2003/2426) tel que modifié.`,
            ],
          },
          {
            header: `PROTECTION DES DONNÉES`,
            listItems: [
              `27.02.1 Les deux parties se conformeront à toutes les exigences applicables de la législation sur la protection des données. La présente clause 27.02.1 s'ajoute à la législation sur la protection des données et ne libère, ni ne supprime ou ne remplace les obligations ou les droits d'une partie en vertu de cette législation. Dans la présente clause 27.02, les lois applicables signifient (pour autant et dans la mesure où elles s'appliquent au fournisseur) la loi de l'Union européenne, la loi de tout État membre de l'Union européenne et/ou la loi nationale du Royaume-Uni ; et la loi nationale du Royaume-Uni signifie la législation sur la protection des données du Royaume-Uni et toute autre loi qui s'applique au Royaume-Uni.`,
              `27.02.02 Les parties reconnaissent qu'aux fins de la législation sur la protection des données, le client est le contrôleur et le prestataire est le sous-traitant. L'annexe 1 définit la portée, la nature et l'objectif du traitement par le prestataire, la durée du traitement et les types de données personnelles et les catégories de personnes concernées.`,
              `Sans préjudice de la généralité de la clause 27.02.01, le client s'assurera qu'il dispose de tous les consentements et avis appropriés nécessaires pour permettre le transfert légal des données à caractère personnel au fournisseur et/ou la collecte légale des données à caractère personnel par le fournisseur au nom du client pour la durée et les objectifs du présent accord.`,
              {
                header: `27.02.03 Sans préjudice de la généralité de la clause 27.02.01, le fournisseur doit, en ce qui concerne toutes les données à caractère personnel traitées dans le cadre de l'exécution par le fournisseur de ses obligations en vertu du présent accord :`,
                listItems: [
                  `traiter ces données personnelles uniquement sur la base des instructions écrites documentées du client qui sont énoncées dans l'annexe 1, sauf si le fournisseur est tenu par les lois applicables de traiter autrement ces données personnelles. Lorsque le fournisseur se fonde sur les lois applicables pour traiter des données à caractère personnel, il doit en informer le client dans les plus brefs délais avant d'effectuer le traitement requis par les lois applicables, à moins que ces dernières n'interdisent au fournisseur d'en informer le client ;`,
                  `s'assurer qu'il a mis en place des mesures techniques et organisationnelles appropriées, examinées et approuvées par le client, pour protéger contre le traitement non autorisé ou illégal des données à caractère personnel et contre la perte, la destruction ou l'endommagement accidentel des données à caractère personnel, en fonction du préjudice qui pourrait résulter du traitement non autorisé ou illégal ou de la perte, de la destruction ou de l'endommagement accidentel et de la nature des données à protéger, compte tenu de l'état de développement technologique et du coût de la mise en œuvre de toute mesure (ces mesures peuvent inclure, le cas échéant, la pseudonymisation et le cryptage des données à caractère personnel, la garantie de la confidentialité, de l'intégrité, de la disponibilité et de la résilience de ses systèmes et services, la garantie que la disponibilité et l'accès aux données à caractère personnel peuvent être rétablis en temps utile après un incident, et l'évaluation régulière de l'efficacité des mesures techniques et organisationnelles adoptées par elle) ; `,
                  `veiller à ce que tout le personnel qui a accès à des données à caractère personnel et/ou qui les traite soit tenu de préserver la confidentialité de ces données ; `,
                  `ne pas transférer de données personnelles en dehors de l'Espace économique européen sans avoir obtenu le consentement écrit préalable du client et que les conditions suivantes soient remplies :`,
                  `le client ou le fournisseur a fourni des garanties appropriées en ce qui concerne le transfert ;`,
                  `la personne concernée dispose de droits exécutoires et de recours juridiques efficaces ;`,
                  `le fournisseur respecte ses obligations en vertu de la législation sur la protection des données en assurant un niveau de protection adéquat à toutes les données à caractère personnel qui sont transférées ; `,
                  `le Prestataire se conforme aux instructions raisonnables qui lui ont été préalablement notifiées par le Client en ce qui concerne le traitement des données à caractère personnel ;`,
                  `il aide le client, à ses frais, à répondre à toute demande d'une personne concernée et à assurer le respect de ses obligations au titre de la législation sur la protection des données en ce qui concerne la sécurité, les notifications de violation, les évaluations d'impact et les consultations avec les autorités de contrôle ou les régulateurs ;`,
                  `informer le client sans délai excessif dès qu'il a connaissance d'une violation de données à caractère personnel ;`,
                  `sur instruction écrite du client, supprimer ou renvoyer les données personnelles et leurs copies au client à la fin du contrat, sauf si la loi applicable exige de stocker les données personnelles ; et`,
                  `tenir des registres et des informations complets et précis pour démontrer sa conformité avec la présente clause 27.02.03.`,
                ],
              },
              `27.02.04 Le Client consent à ce que le Prestataire désigne des tiers pour traiter les données personnelles dans le cadre du présent contrat. Le fournisseur confirme qu'il a conclu ou (selon le cas) qu'il conclura avec les sous-traitants tiers un accord écrit comprenant des conditions qui sont substantiellement similaires à celles énoncées dans la présente clause 27.02 et, dans les deux cas, le fournisseur confirme qu'elles reflètent et continueront à refléter les exigences de la législation sur la protection des données. `,
              `27.02.05 Le Client reconnaît que le Fournisseur conservera les données du Client, telles que définies à l'Annexe 1, paragraphe 1.2, pas plus longtemps que nécessaire pour l'objectif pour lequel elles ont été traitées pendant la durée du présent contrat, à moins qu'une période plus longue ne soit requise pour se conformer aux lois applicables ou aux exigences imposées par la police d'assurance du Fournisseur.`,
              `27.02.06 Le Fournisseur peut, à tout moment, moyennant un préavis d'au moins 30 jours, réviser la clause 27.02 en la remplaçant par toute clause standard adéquate du contrôleur au sous-traitant ou par des termes similaires faisant partie d'un système de certification adéquat (qui s'appliquent lorsqu'ils sont remplacés par une annexe au présent contrat).`,
              {
                header: `Annexe 1 Traitement, données personnelles et personnes concernées`,
                listItems: [
                  `1. Traitement par le Fournisseur`,
                  `1.1	Champ d'application - Le Fournisseur traitera uniquement les données soumises par le Client, qui reste responsable du respect de la Législation sur la protection des données à tout moment. Les données traitées engloberont uniquement les informations relatives aux incidents de santé et de sécurité, conformément à la finalité du traitement telle que définie ci-dessous.`,
                  `1.2	Nature - Les données traitées comprennent : le nom des personnes impliquées dans un incident de santé et de sécurité, le type de travail, le nom de l'employeur, le type de blessure, la date de la blessure.`,
                  `1.3	Finalité du traitement - La finalité du traitement des données à caractère personnel est le suivi des incidents en matière de santé et de sécurité, afin de garantir le respect intégral et l'amélioration continue des mesures de santé et de sécurité.`,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
