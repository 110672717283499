import { Badge, Tooltip } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table/Column';
import React, { useEffect, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import Highlighter from 'react-highlight-words';
import 'react-resizable/css/styles.css';
import { HasTable } from '../../components';
import i18n from '../../i18n/config';
import {
  ChartEventsDataFilter,
  Incident,
  IncidentSeverity,
  IncidentTypeLabel,
  InvestigationStatus,
} from '../../models';
import { ChartService } from '../../services';
import {
  getDateTime,
  getHighlighterProps,
  getUserFullName,
  TABLE_HEADER_HEIGHT,
  TABLE_ITEM_HEIGHT,
  TABLE_PAGINATION_HEIGHT,
} from '../../utils';

interface ChartsEventsProps {
  chartEventsDataFilter: ChartEventsDataFilter;
  navigationCallback: (incidentId: number) => void;
}

const ChartEvents: React.FC<ChartsEventsProps> = ({ chartEventsDataFilter, navigationCallback }) => {
  const [events, setEvents] = useState<Incident[]>([]);
  const [total, setTotal] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getEventsCount();
    loadChartInstanceEventsData();
  }, []);

  const getEventsCount = async () => {
    await ChartService.getChartInstanceEventsCount(chartEventsDataFilter).then(({ data }) => {
      setTotal(data);
    });
  };

  const loadChartInstanceEventsData = async () => {
    await ChartService.getChartInstanceEventsData(chartEventsDataFilter).then(({ data }) => {
      setEvents(data);
      setLoading(false);
    });
  };

  const getColumns = (): ColumnProps<Incident>[] => [
    {
      title: i18n.t('incidents.eventType'),
      dataIndex: 'type',
      key: 'type',
      render: (text, incident) => (
        <React.Fragment>
          {incident.severity && getSeverityBadge(incident.severity)}
          <Highlighter {...getHighlighterProps(i18n.t(IncidentTypeLabel[text]))} />
        </React.Fragment>
      ),
      ellipsis: true,
    },
    {
      title: i18n.t('incidents.reportDate'),
      dataIndex: 'reportedAt',
      key: 'reportedAt',
      ellipsis: true,
      width: 200,
      render: (text) => getDateTime(text),
    },
    {
      title: i18n.t('incidents.reference'),
      dataIndex: 'id',
      key: 'id',
      render: (_, { id }) => <Highlighter {...getHighlighterProps('#' + id)} />,
    },
    {
      title: i18n.t('shared.company'),
      dataIndex: ['company', 'name'],
      key: 'company',
      ellipsis: true,
    },
    {
      title: i18n.t('shared.division'),
      dataIndex: ['division', 'name'],
      key: 'division',
      ellipsis: true,
    },
    {
      title: i18n.t('incidents.reportedBy'),
      dataIndex: 'reportingUser',
      key: 'reportingUser',
      ellipsis: true,
      render: (_, { reportingUser }) => <Highlighter {...getHighlighterProps(getUserFullName(reportingUser))} />,
    },
    {
      title: i18n.t('incidents.subject'),
      dataIndex: 'description',
      key: 'subject',
      ellipsis: true,
    },
    {
      title: i18n.t('incidents.investigation'),
      dataIndex: 'isInvestigationRequired',
      key: 'isInvestigationRequired',
      align: 'center',
      render: (_, record: Incident) => (record.investigationRequired ? i18n.t('shared.yes') : i18n.t('shared.no')),
      ellipsis: true,
    },
    {
      title: i18n.t('shared.status'),
      key: 'status',
      align: 'center',
      ellipsis: true,
      render: (_, record: Incident) =>
        record.status !== null ? (
          record.status === InvestigationStatus.OPEN ? (
            <>
              <Badge color={'green'} /> {i18n.t('incidents.open')}
            </>
          ) : (
            <>
              <Badge color={'red'} /> {i18n.t('incidents.closed')}
            </>
          )
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
    },
    {
      title: i18n.t('incidents.action'),
      key: 'hasActions',
      align: 'center',
      render: (_, record: Incident) =>
        record.hasActions !== null ? (
          record.hasActions ? (
            <>
              <Badge color={'green'} /> {i18n.t('shared.yes')}
            </>
          ) : (
            <>
              <Badge color={'black'} /> {i18n.t('shared.no')}
            </>
          )
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
    },
  ];

  const getSeverityBadge = (severity: IncidentSeverity) => {
    if (severity) {
      const stringKey = severity === IncidentSeverity.SEVERE ? 'incidents.severe' : 'incidents.moderate';
      const color = severity === IncidentSeverity.SEVERE ? 'red' : 'yellow';
      return (
        <Tooltip title={i18n.t(stringKey)}>
          <Badge color={color} />
        </Tooltip>
      );
    }
  };

  const rowInteraction = (record: Incident) => ({
    onClick: (event: React.MouseEvent) => navigationCallback(record.id),
  });

  const onTableChange = (pagination: PaginationConfig) => {
    chartEventsDataFilter.pageNumber = pagination.current ? pagination.current : 1;
    loadChartInstanceEventsData();
  };

  return (
    <div className="has-chart-events-table-height d-flex flex-column">
      <HasTable<Incident>
        className="mr-2 ml-2 mt-2"
        rowKey="id"
        loading={loading}
        onRow={(record) => rowInteraction(record)}
        onChange={onTableChange}
        columns={getColumns()}
        onPageSizeChange={(size: number) => (chartEventsDataFilter.pageSize = size)}
        data={events}
        tableItemHeight={TABLE_ITEM_HEIGHT}
        tableHeaderHeight={TABLE_HEADER_HEIGHT}
        tablePaginationHeight={TABLE_PAGINATION_HEIGHT}
        defaultPageNumber={chartEventsDataFilter.pageNumber}
        total={total}
        isApiPaginated={true}
      />
    </div>
  );
};

export default ChartEvents;
