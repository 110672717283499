import { CheckOutlined, QuestionOutlined, WarningFilled } from '@ant-design/icons';
import React from 'react';
import { HasAvatar, HasImage, HasList } from '../../components';
import i18n from '../../i18n/config';
import { IncidentTypeLabel, NewIncident } from '../../models';
import { COLORS, ListItemWithMetaProps } from '../../shared';
import { getDate, getTime, IncidentIcons } from '../../utils';

interface HistoricalEventsListProps {
  events: NewIncident[];
  onRowClick: (index: number) => void;
  selectedIndex?: number;
}

const HasHistoricalEventsList: React.FC<HistoricalEventsListProps> = ({
  events,
  onRowClick,
  selectedIndex,
}: HistoricalEventsListProps) => {
  const getListItem = (event: NewIncident, index: number): ListItemWithMetaProps => {
    const extra = event.hasErrors ? (
      <WarningFilled style={{ color: COLORS.ERROR, fontSize: 20 }} className="ml-2" />
    ) : event.invalidEntryKeys?.length ? (
      <WarningFilled style={{ color: COLORS.WARNING, fontSize: 20 }} className="ml-2" />
    ) : (
      <CheckOutlined style={{ color: COLORS.SECONDARY_GREEN, fontSize: 20 }} className="ml-2" />
    );
    return {
      item: { extra, className: 'px-3' },
      meta: {
        title: (
          <span style={{ fontSize: 12, margin: 0, fontWeight: 600, color: COLORS.GRAY_DARK }}>
            {i18n.t('incidents.eventFrom', {
              time: event.reportedAt ? getTime(event.reportedAt, { hour: 'numeric', minute: 'numeric' }) : '-',
              date: event.reportedAt ? getDate(event.reportedAt) : '-',
            })}
          </span>
        ),
        description: event.description,
        avatar: event.type ? (
          <HasImage
            src={IncidentIcons[event.type]}
            alt={IncidentTypeLabel[event.type]}
            style={{ width: '40px', height: '40px', objectFit: 'contain', marginTop: '4px' }}
          />
        ) : (
          <HasAvatar
            icon={<QuestionOutlined style={{ verticalAlign: 'middle', color: COLORS.NEW_ORANGE }} />}
            size="default"
            shape="square"
            style={{ backgroundColor: 'transparent' }}
          />
        ),
      },
      onRowClick: () => onRowClick(index),
    };
  };

  return (
    <HasList<NewIncident>
      listItemsConfig={events.map((event, index) => getListItem(event, index))}
      interactive
      selectedIndex={selectedIndex}
    />
  );
};

export { HasHistoricalEventsList };
