import Spin, { SpinProps } from 'antd/lib/spin';
import React from 'react';

interface OwnSpinnerProps extends SpinProps {}

class HasSpinner extends React.Component<OwnSpinnerProps, {}> {
  render() {
    return (
      <div style={{ display: 'flex', height: '100%', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Spin {...this.props} />
      </div>
    );
  }
}

export default HasSpinner;
