import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '..';
import { Notification } from '../components';
import i18n from '../i18n/config';
import { ACCEPT_LANGUAGE, COMPANY_FILTER, ErrorMessages } from '../shared';
import { clearToken, getTokenFromCookie } from '../utils';

export const requestHandler = (config: AxiosRequestConfig) => {
  const token = getTokenFromCookie();
  if (token) {
    config.headers.Authorization = token;
  }
  config.url = `${process.env.REACT_APP_API_URL}${config.url}`;

  const companyFilter = localStorage.getItem(COMPANY_FILTER);
  if (companyFilter) {
    config.headers[COMPANY_FILTER] = companyFilter;
  }

  if (!config.headers[ACCEPT_LANGUAGE]) {
    config.headers[ACCEPT_LANGUAGE] = i18n.language;
  }

  return config;
};

export const successResponseHandler = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const errorResponseHandler = (error: any) => {
  if (error.response) {
    const isLoginError = error.response.config.url.includes('login');
    if (!isLoginError) {
      switch (error.response.status) {
        case 401:
        case 403:
          clearToken();
          history.push('/login', {
            sessionError: ErrorMessages.SESSION_INVALID(),
          });
          break;
        // case 500:
        // 	history.push('/error', { forUrl: error.response.config.url, errorMessage: error.response.data.message });
        // 	break;
      }
    }
  } else {
    Notification.error(ErrorMessages.NETWORK_ERROR);
  }
  return Promise.reject(error);
};
