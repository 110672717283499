import en_US from 'antd/lib/calendar/locale/en_US';
import moment from 'moment';
import React from 'react';
import { HasButton, HasDatePicker, HasText } from '../../../atoms';
import { DATE_TIME_FORMAT, ErrorMessages } from '../../../../shared';
import i18n from '../../../../i18n/config';
import { EventUtils } from '../../../../utils';
import { isUndefined } from 'lodash';

interface DatePickerFormProps {
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
}

interface DatePickerFormState {
  selectedDateTime: string | undefined;
  selectedMoment: moment.Moment | undefined;
  error: boolean;
}

class HasDatePickerForm extends React.Component<DatePickerFormProps, DatePickerFormState> {
  state = {
    selectedDateTime: undefined,
    selectedMoment: undefined,
    error: false,
  };

  handleSave = () => {
    const { onSave, closeEditModeCallback } = this.props;
    const { selectedDateTime } = this.state;
    if (isUndefined(selectedDateTime)) {
      this.setState({ error: true });
    } else {
      onSave(selectedDateTime);
      closeEditModeCallback && closeEditModeCallback();
    }
  };

  handleOnChange = (dateMoment: moment.Moment | null) => {
    this.setState({ error: false });
    dateMoment && this.setState({ selectedDateTime: dateMoment.format(), selectedMoment: dateMoment });
  };

  render() {
    const { error, selectedMoment } = this.state;
    return (
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex flex-column">
          <HasDatePicker
            showTime
            locale={{ ...en_US, lang: { ...en_US.lang, now: i18n.t('shared.now') } }}
            format={DATE_TIME_FORMAT}
            minuteStep={5}
            disabledDate={(date) => !date || date.valueOf() > Date.now()}
            disabledTime={EventUtils.getDisabledTime}
            onChange={this.handleOnChange}
            placeholder={i18n.t('chatbot.selectDateTime')}
            defaultValue={selectedMoment}
          />
          {error && (
            <HasText
              content={ErrorMessages.INVESTIGATION_ENTRY_FORM_NOT_COMPLETE}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          )}
        </div>
        <HasButton type="primary" htmlType="button" size="small" className="align-self-end" onClick={this.handleSave}>
          {i18n.t('shared.save')}
        </HasButton>
      </div>
    );
  }
}

export default HasDatePickerForm;
