import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { RestService, httpClient, EntityAction } from '..';
import { SubscriberEvent } from '../../shared';
import { buildUrl } from '../../utils';
import { Attachment } from '../../models';

abstract class AttachmentService implements RestService<Attachment> {
  protected attachmentChangedSubject = new Subject<SubscriberEvent<Attachment>>();

  protected abstract entityURL: string;
  protected abstract uploadSubURL: string;
  protected abstract attachmentURL: string;

  get(params?: any): Promise<AxiosResponse<Attachment>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Attachment[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Attachment>> {
    return httpClient.get<Attachment>(buildUrl(this.attachmentURL, [resourceId]));
  }

  getForDownload(id: number): Promise<AxiosResponse<Attachment>> {
    return httpClient.get<Attachment>(buildUrl(this.attachmentURL, [id, 'download']));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Attachment>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Attachment>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Attachment): Promise<AxiosResponse<Attachment>> {
    throw new Error('Method not implemented.');
  }

  update(resource: Attachment): Promise<AxiosResponse<Attachment>> {
    throw new Error('Method not implemented.');
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Attachment>> {
    return httpClient.delete<Attachment>(buildUrl(this.attachmentURL, [resourceId]));
  }

  upload(id: number | string, isPrivate: boolean, formData: FormData): Promise<AxiosResponse<null>> {
    const uploadURL = this.uploadSubURL + (isPrivate ? '/private' : '');
    return httpClient.post<null>(buildUrl(this.entityURL, [uploadURL, id]), formData);
  }

  setPrivate(id: number, newStatus: boolean): Promise<AxiosResponse<null>> {
    return httpClient.post<null>(buildUrl(this.attachmentURL, ['private', newStatus ? 'true' : 'false', id]));
  }

  // SUBSCRIPTIONS

  getAttachmentChangedListener(): Observable<SubscriberEvent<Attachment>> {
    return this.attachmentChangedSubject.asObservable();
  }

  attachmentChanged(event: EntityAction, attachment: Attachment) {
    this.attachmentChangedSubject.next({ event: event, entity: attachment });
  }
}

export default AttachmentService;
