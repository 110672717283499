import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Progress, Result, Steps } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, Prompt, RouteComponentProps } from 'react-router-dom';
import { HasButton, HasText, HasTitle, Toast } from '../../components';
import { Company, FormLabel, IncidentType, NewIncident } from '../../models';
import { FormService, IncidentService } from '../../services';
import { ChatBotStep, COLORS } from '../../shared';
import { HasCompanySelection } from './company.selection';
import { HasEventsOverview } from './events.overview';
import { HasEventTemplate } from './events.template';
import { HasEventUpload } from './events.upload';

interface HistoricalEventsProps extends WithTranslation, RouteComponentProps {}

interface HistoricalEventsState {
  currentStep: number;
  formSteps: { formType: IncidentType; steps: ChatBotStep[] }[];
  formLabels: FormLabel[];
  events: Array<Object>;
  company?: Company;
  uploadedEvents?: NewIncident[];
  uploadProgress: number;
  uploadDone: boolean;
}

const { Step } = Steps;
const { confirm } = Modal;

const DOWNLOAD_KEY = 'DOWNLOAD';
const COMPANY_SELECTION = 'COMPANY';
const UPLOAD_KEY = 'UPLOAD';
const OVERVIEW_KEY = 'OVERVIEW';
const FINISH_KEY = 'FINISH';

const steps = [
  { key: DOWNLOAD_KEY, title: 'historicalEvents.steps.download' },
  { key: COMPANY_SELECTION, title: 'historicalEvents.steps.company' },
  { key: UPLOAD_KEY, title: 'historicalEvents.steps.upload' },
  { key: OVERVIEW_KEY, title: 'historicalEvents.steps.overview' },
  { key: FINISH_KEY, title: 'historicalEvents.steps.finish' },
];

class HasHistoricalEvents extends React.Component<HistoricalEventsProps, HistoricalEventsState> {
  state: HistoricalEventsState = {
    currentStep: 0,
    formSteps: [],
    formLabels: [],
    events: [],
    uploadProgress: 0,
    uploadDone: false,
  };

  private transitions: Array<
    {
      from: string;
      to: string;
      validation?: () => boolean;
      onTransition?: () => void;
      errorMessage?: string;
      requireConfirmation?: boolean;
    }[]
  > = [
    [{ from: DOWNLOAD_KEY, to: COMPANY_SELECTION }],
    [
      {
        from: COMPANY_SELECTION,
        to: UPLOAD_KEY,
        validation: () => !isUndefined(this.state.company),
        errorMessage: 'historicalEvents.noCompanySelected',
      },
      { from: COMPANY_SELECTION, to: DOWNLOAD_KEY },
    ],
    [
      {
        from: UPLOAD_KEY,
        to: OVERVIEW_KEY,
        validation: () => !isUndefined(this.state.events) && !isEmpty(this.state.events),
        errorMessage: 'historicalEvents.fileNotUploaded',
      },
      { from: UPLOAD_KEY, to: COMPANY_SELECTION, onTransition: () => this.setState({ company: undefined }) },
    ],
    [
      {
        from: OVERVIEW_KEY,
        to: UPLOAD_KEY,
        requireConfirmation: true,
        onTransition: () => this.setState({ events: [] }),
      },
    ],
  ];

  componentDidMount() {
    let types = Object.values(IncidentType);
    types.forEach((key) =>
      FormService.getByFormType(key).then(({ data }) => {
        this.setState({ formSteps: [...this.state.formSteps, { formType: key, steps: data.mappedSteps }] });
      })
    );
    FormService.getAllFormLabels().then(({ data: formLabels }) => this.setState({ formLabels }));
  }

  componentDidUpdate() {
    if (this.state.events.length > 0) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleEventsUpload = async (events: NewIncident[]) => {
    this.setState(({ currentStep }) => {
      return { currentStep: currentStep + 1, uploadedEvents: events };
    });
    events.forEach(async (event, index) => {
      if (!event.hasErrors && isEmpty(event.invalidEntryKeys)) {
        let formData = new FormData();
        formData.append('incident', new Blob([JSON.stringify(event)], { type: 'application/json' }));
        try {
          await IncidentService.createNewIncident(formData);
          this.setState(({ uploadProgress }) => ({
            uploadProgress: uploadProgress + 1,
            uploadDone: uploadProgress + 1 === events.length,
          }));
        } catch (error) {}
      }
    });
  };

  resetWizard = () => {
    this.setState({
      currentStep: 1,
      events: [],
      company: undefined,
      uploadedEvents: undefined,
      uploadProgress: 0,
      uploadDone: false,
    });
  };

  getCurrentStepComponent = (): JSX.Element => {
    const { t } = this.props;
    const { currentStep, formSteps, formLabels, events, company, uploadedEvents, uploadProgress, uploadDone } =
      this.state;

    if (currentStep > 1 && isUndefined(company)) {
      return (
        <>
          <HasTitle
            level={4}
            type="secondary"
            content={
              <ExclamationCircleOutlined style={{ color: COLORS.ERROR, fontSize: 24 }} className="align-middle mr-2" />
            }
            className="mb-4"
          >
            {t('historicalEvents.noCompanySelected')}
          </HasTitle>
          <HasButton onClick={() => this.setState(({ currentStep }) => ({ currentStep: currentStep - 1 }))}>
            {t('historicalEvents.steps.company')}
          </HasButton>
        </>
      );
    } else {
      switch (steps[currentStep].key) {
        case DOWNLOAD_KEY:
          return (
            <HasEventTemplate next={() => this.setState(({ currentStep }) => ({ currentStep: currentStep + 1 }))} />
          );
        case COMPANY_SELECTION:
          return (
            <HasCompanySelection afterSelect={(company) => this.setState({ company, currentStep: currentStep + 1 })} />
          );
        case UPLOAD_KEY:
          return <HasEventUpload afterLoad={(events) => this.setState({ events, currentStep: currentStep + 1 })} />;
        case OVERVIEW_KEY:
          return (
            <HasEventsOverview
              formLabels={formLabels}
              formSteps={formSteps}
              events={events}
              company={company!}
              onUpload={this.handleEventsUpload}
            />
          );
        case FINISH_KEY:
          const extra = [
            <Link key={'/home'} to="/home">
              <HasButton type="primary" size="large">
                {t('sidebar.home')}
              </HasButton>
            </Link>,
            <Link key={'/events'} to="/events">
              <HasButton type="primary" size="large">
                {t('sidebar.incidents')}
              </HasButton>
            </Link>,
            <HasButton key={'uploadAgain'} type="primary" size="large" onClick={() => this.resetWizard()}>
              {t('historicalEvents.uploadAnother')}
            </HasButton>,
          ];
          if (!isUndefined(uploadedEvents) && uploadedEvents.length > 0) {
            const percentage = (uploadProgress * 100) / uploadedEvents?.length;
            const title = uploadDone
              ? t('historicalEvents.uploadSuccess', { count: uploadedEvents?.length })
              : t('historicalEvents.uploadInProgress', { count: uploadProgress });
            return (
              <Result status="success" title={title} extra={extra}>
                <Progress percent={Math.floor(percentage)} status={!uploadDone ? 'active' : 'success'} />
              </Result>
            );
          } else {
            return <Result status="error" title={t('historicalEvents.uploadError')} extra={extra} />;
          }
        default:
          this.setState({ currentStep: 0 });
          return (
            <HasEventTemplate next={() => this.setState(({ currentStep }) => ({ currentStep: currentStep + 1 }))} />
          );
      }
    }
  };

  computeNextStep = (nextStepIndex: number) => {
    const { t } = this.props;
    const { currentStep } = this.state;
    const currentStepKey = steps[currentStep].key;
    const nextStepKey = steps[nextStepIndex].key;
    let transition = this.transitions[currentStep].find(
      ({ from, to }) => from === currentStepKey && to === nextStepKey
    );
    if (!isUndefined(transition)) {
      const { validation, errorMessage, onTransition, requireConfirmation } = transition;
      const canTransition = isUndefined(validation) || (!isUndefined(validation) && validation());
      if (canTransition) {
        const okCallback = () => {
          if (onTransition) onTransition();
          this.setState({ currentStep: nextStepIndex });
        };
        requireConfirmation
          ? confirm({
              title: t('historicalEvents.steps.modalTitle'),
              content: t('historicalEvents.steps.modalContent'),
              onOk: okCallback,
            })
          : okCallback();
      } else {
        Toast.error(errorMessage ? t(errorMessage) : t('historicalEvents.steps.error'));
      }
    } else {
      Toast.error(t('historicalEvents.steps.error'));
    }
  };

  getStepDisabledState = (key: string): boolean => {
    const { currentStep } = this.state;
    if (steps[currentStep].key === FINISH_KEY) {
      return true;
    } else {
      const availableTransitions = this.transitions[currentStep];
      return !availableTransitions.some(({ to }) => to === key);
    }
  };

  render() {
    const { t } = this.props;
    const { currentStep, events, uploadDone } = this.state;
    return (
      <>
        <Prompt
          when={events.length > 0 && !uploadDone}
          message={`${t('historicalEvents.steps.modalTitle')} ${t('historicalEvents.steps.modalContent')}`}
        />
        <div className="shadow-sm has-container-tile d-flex flex-column h-100 p-4">
          <Steps progressDot current={currentStep} onChange={this.computeNextStep} className="mb-4">
            {steps.map(({ key, title }) => (
              <Step
                key={key}
                disabled={this.getStepDisabledState(key)}
                title={<HasText content={t(title)} style={{ fontSize: '14px' }} />}
              />
            ))}
          </Steps>
          <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-auto">
            {this.getCurrentStepComponent()}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(HasHistoricalEvents);
