import { FileExcelTwoTone } from '@ant-design/icons';
import { Card, Dropdown, Menu } from 'antd';
import React from 'react';
import { IoMdGlobe } from 'react-icons/io';
import { HasText, HasButton } from '../../components';
import i18n from '../../i18n/config';
import { IncidentService } from '../../services';
import { COLORS, LANGUAGES } from '../../shared';
import { catchClickEvent } from '../../utils';

interface EventTemplateProps {
  next: () => void;
}

const HasEventTemplate: React.FC<EventTemplateProps> = ({ next }): JSX.Element => {
  const { Meta } = Card;

  const currentLanguage = LANGUAGES.find((language) => language.id === i18n.language);

  const otherLanguages = LANGUAGES.filter((language) => language.id !== currentLanguage?.id && language.id === 'fr');

  const getActions = () => {
    const menu = (
      <Menu
        onClick={({ domEvent, key }) => {
          catchClickEvent(domEvent);
          getTemplate(key);
        }}
      >
        {otherLanguages.map((language) => (
          <Menu.Item key={language.id}>{i18n.t('historicalEvents.template', { lang: language.name })}</Menu.Item>
        ))}
      </Menu>
    );

    return [
      <Dropdown overlay={menu} placement="bottomCenter">
        <IoMdGlobe className="anticon" />
      </Dropdown>,
    ];
  };

  const getMeta = () => {
    return (
      <Meta title={i18n.t('historicalEvents.template', { lang: currentLanguage?.name })} className="text-center" />
    );
  };

  const getTemplate = (language: string) => {
    return IncidentService.getCsvTemplate(language).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', i18n.t(`Events_template_${language}.csv`, { language }));
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    });
  };

  return (
    <>
      <HasText
        type="secondary"
        content={i18n.t('historicalEvents.downloadInstruction')}
        className="mb-4 text-center"
        style={{ fontSize: '18px' }}
      />
      <Card
        cover={<FileExcelTwoTone twoToneColor={COLORS.NEW_ORANGE} className="p-5" style={{ fontSize: '32px' }} />}
        actions={getActions()}
        onClick={() => getTemplate(currentLanguage ? currentLanguage.id : 'en')}
        style={{ width: '240px' }}
        className="has-document-card mb-4"
      >
        {getMeta()}
      </Card>
      <HasButton type="primary" onClick={() => next()} style={{ width: '240px' }}>
        {i18n.t('shared.next')}
      </HasButton>
    </>
  );
};

export { HasEventTemplate };
