import React, { CSSProperties, ReactNode } from 'react';
import { HasButton, HasParagraph, HasRadioGroup, HasText } from '../../..';
import {
  ErrorMessages,
  InvestigationPlantAnswer,
  RadioGroupOption,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
  COLORS,
  TEXT_AREA_MAX_LENGTH,
  DELIMITER,
  InvestigationPlantQuestions,
} from '../../../../shared';
import { displayPlaceholderIfEmpty } from '../../../../utils';
import { HasCheckableTag, HasTextArea } from '../../../atoms';
import i18n from '../../../../i18n/config';

interface PlantFormProps {
  onChange: () => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
}

interface PlantFormState {
  selectedOption: string | null;
  textAreaVisible: boolean;
  error: boolean;
  subQuestions: InvestigationPlantQuestions;
}

class HasPlantContributionForm extends React.Component<PlantFormProps, PlantFormState> {
  private radioGroupConfig: TypedRadioGroupProps<string>;
  private radioOptionsConfig: RadioGroupOption<string>[] = [];

  private questionsStyle: CSSProperties = { fontSize: '12px', fontWeight: 500, marginBottom: '8px' };

  private result: InvestigationPlantAnswer = {} as InvestigationPlantAnswer;

  constructor(props: Readonly<PlantFormProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onChange,
    };

    this.radioOptionsConfig = [
      { value: 'shared.yes', label: i18n.t('shared.yes') },
      { value: 'shared.no', label: i18n.t('shared.no') },
    ];

    const subQuestions = this.getSubQuestions();

    this.result = {
      option: '',
      equipment: { question: subQuestions.equipment, answer: '' },
      check: { question: subQuestions.check, answer: '' },
      records: { question: subQuestions.records, answer: '' },
      defects: { question: subQuestions.defects, answer: '' },
      plant: { question: subQuestions.plant, answer: '' },
    };

    this.state = {
      error: false,
      selectedOption: null,
      textAreaVisible: true,
      subQuestions,
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () => this.setState({ subQuestions: this.getSubQuestions() }));
  }

  getSubQuestions = (): InvestigationPlantQuestions => ({
    plant: i18n.t('investigation.form.whatPlantQ'),
    equipment: i18n.t('investigation.form.oldEquipmentQ'),
    check: i18n.t('investigation.form.preStartCheckQ'),
    defects: i18n.t('investigation.form.reportedDefectsQ'),
    records: i18n.t('investigation.form.anyRecords'),
  });

  onChange = (event: TypedRadioChangeEvent<string>) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.setState({ selectedOption: event.value });
    this.props.onChange();
  };

  saveData = () => {
    const { selectedOption } = this.state;
    if (!selectedOption || (this.state.selectedOption === 'Yes' && !this.isFormCompleted())) {
      this.setState({ error: true });
    } else {
      this.result.option = displayPlaceholderIfEmpty(this.state.selectedOption, '-');
      if (typeof this.result.defects.answer === 'boolean') {
        this.result.defects.answer = 'shared.no';
      } else {
        this.result.defects.answer = `shared.yes${DELIMITER}`.concat(this.result.defects.answer);
      }
      const result =
        this.state.selectedOption === 'shared.no'
          ? 'shared.no'
          : JSON.stringify({
              answer: this.result.option,
              equipment: this.result.equipment.answer,
              check: this.result.check.answer,
              records: this.result.records.answer,
              defects: this.result.defects.answer,
              plant: this.result.plant.answer,
            });
      this.props.onSave(result);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  isFormCompleted = (): boolean => {
    return !Object.values(this.result).some(
      (subValue) =>
        typeof subValue === 'object' &&
        (subValue.answer === '' || subValue.answer === null || subValue.answer === undefined)
    );
  };

  showSubQuestions = (): ReactNode => {
    const { subQuestions } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <HasParagraph content={subQuestions.plant} style={this.questionsStyle} />
        <HasRadioGroup
          size="small"
          buttonStyle="solid"
          onChange={(event: TypedRadioChangeEvent<string>) => (this.result.plant.answer = event.value)}
          groupOptions={[
            { value: 'investigation.form.fixed', label: i18n.t('investigation.form.fixed') },
            { value: 'investigation.form.mobile', label: i18n.t('investigation.form.mobile') },
            { value: 'investigation.form.hand', label: i18n.t('investigation.form.hand') },
          ]}
        />
        <HasParagraph content={subQuestions.equipment} style={this.questionsStyle} />
        <HasRadioGroup
          size="small"
          buttonStyle="solid"
          onChange={(event: TypedRadioChangeEvent<string>) => (this.result.equipment.answer = event.value)}
          groupOptions={[
            { value: 'investigation.form.new', label: i18n.t('investigation.form.new') },
            { value: 'investigation.form.average', label: i18n.t('investigation.form.average') },
            { value: 'investigation.form.old', label: i18n.t('investigation.form.old') },
          ]}
        />
        <HasParagraph content={subQuestions.check} style={this.questionsStyle} />
        <HasRadioGroup<string>
          size="small"
          buttonStyle="solid"
          onChange={(event: TypedRadioChangeEvent<string>) => (this.result.check.answer = event.value)}
          groupOptions={[
            { value: 'shared.yes', label: i18n.t('shared.yes') },
            { value: 'shared.no', label: i18n.t('shared.no') },
            { value: 'investigation.form.notApplicable', label: i18n.t('investigation.form.notApplicable') },
          ]}
        />
        <HasParagraph content={subQuestions.records} style={this.questionsStyle} />
        <HasRadioGroup<string>
          size="small"
          buttonStyle="solid"
          onChange={(event: TypedRadioChangeEvent<string>) => (this.result.records.answer = event.value)}
          groupOptions={[
            { value: 'shared.yes', label: i18n.t('shared.yes') },
            { value: 'shared.no', label: i18n.t('shared.no') },
            { value: 'investigation.form.notApplicable', label: i18n.t('investigation.form.notApplicable') },
          ]}
        />
        <HasParagraph content={subQuestions.defects} style={this.questionsStyle} />
        <div className="d-flex flex-column">
          <HasTextArea
            rows={2}
            maxLength={TEXT_AREA_MAX_LENGTH}
            disabled={!this.state.textAreaVisible}
            onChange={(event: any) => (this.result.defects.answer = event.target.value)}
            placeholder={i18n.t('investigation.form.recordedDefectsInput')}
          />
          <HasText
            content={i18n.t('investigation.form.maxCharacters', { max: TEXT_AREA_MAX_LENGTH })}
            style={{ fontSize: '12px', marginBottom: '8px' }}
            type="secondary"
          />
          <HasCheckableTag
            content={i18n.t('investigation.form.noneRecorded')}
            checked={!this.state.textAreaVisible}
            onChange={(checked: boolean) => {
              this.setState({ textAreaVisible: !checked });
              this.result.defects.answer = checked ? !checked : '';
            }}
            style={{
              userSelect: 'none',
              cursor: 'pointer',
              alignSelf: 'flex-start',
              fontSize: '14px',
              border: `1px solid ${COLORS.PRIMARY_BLUE}`,
              borderRadius: '5px',
              padding: '2px 7px',
            }}
          />
        </div>
        <HasButton
          type="primary"
          size="small"
          htmlType="button"
          onClick={this.saveData}
          style={{ alignSelf: 'flex-end' }}
        >
          {i18n.t('shared.save')}
        </HasButton>
      </div>
    );
  };

  componentWillUnmount() {
    i18n.on('languageChanged', () => this.setState({ subQuestions: this.getSubQuestions() }));
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <React.Fragment>
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <HasRadioGroup {...this.radioGroupConfig} groupOptions={this.radioOptionsConfig} />
          {(!selectedOption || selectedOption === 'shared.no') && (
            <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData}>
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {selectedOption === 'shared.yes' && this.showSubQuestions()}
        {this.state.error && (
          <HasText
            content={
              selectedOption === 'shared.yes'
                ? ErrorMessages.INVESTIGATION_ENTRY_FORM_NOT_COMPLETE
                : ErrorMessages.INVESTIGATION_ENTRY_NO_OPTION_SELECTED
            }
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HasPlantContributionForm;
