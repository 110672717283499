import { SearchOutlined } from '@ant-design/icons';
import { Popconfirm, Skeleton } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { HasTable } from '../../../components';
import { HasText, HasTextInput, Notification } from '../../../components/atoms';
import i18n from '../../../i18n/config';
import { User } from '../../../models';
import { EntityAction, UserService } from '../../../services';
import { ConfirmMessages, ErrorMessages, SORT_DIRECTIONS, SuccessMessages } from '../../../shared';
import { comparatorFn, getUserFullName } from '../../../utils';

interface MyPermissionInvitations {
  users: User[];
  loading: boolean;
}

interface PermissionsSettingsState {
  searchTerm: string;
  users: User[];
}

const TABLE_ITEM_HEIGHT = 55;
const TABLE_HEADER_HEIGHT = 55;

class HasMyPermissionInvitations extends React.Component<MyPermissionInvitations, {}> {
  state: PermissionsSettingsState = {
    users: this.props.users,
    searchTerm: '',
  };

  componentDidUpdate(prevProps: Readonly<MyPermissionInvitations>, prevState: PermissionsSettingsState) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ users: this.props.users });
    }
  }

  getColumns = (): ColumnProps<User>[] => [
    {
      title: i18n.t('settings.invitedBy'),
      key: 'invitedBy',
      align: 'center',
      render: (record: User) => getUserFullName(record),
    },
    {
      title: i18n.t('shared.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      sorter: (a: User, b: User) => comparatorFn<string>(a.email, b.email),
    },
    {
      title: i18n.t('shared.company'),
      dataIndex: ['company', 'name'],
      key: 'company',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: ({ company: aCompany }: User, { company: bCompany }: User) =>
        comparatorFn<string>(aCompany.name, bCompany.name),
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      align: 'center',
      render: (text: any, record: User) => (
        <Popconfirm
          title={ConfirmMessages.REVOKE_OWN_PERMISSION(record.company?.name)}
          okText={i18n.t('shared.yes')}
          cancelText={i18n.t('shared.no')}
          onConfirm={() => this.revokePermission(record)}
          placement="topLeft"
          style={{ maxWidth: '200px' }}
        >
          <MdDelete style={{ fontSize: '18px' }} className="icon-hover danger" />
        </Popconfirm>
      ),
    },
  ];

  revokePermission = (user: User) => {
    if (user) {
      UserService.revokeMyDataViewPermission(user.id).then(
        () => {
          Notification.success(SuccessMessages.OWN_PERMISSION_REVOKED(user.company?.name));
          UserService.usersChanged(EntityAction.REFRESH, {} as User);
          if (!this.checkIfUserStillHasAccessToCompany(user.id, user.company.id)) {
            UserService.companyDataViewChanged(user.company.id);
          }
        },
        (error) =>
          Notification.error(ErrorMessages.PERMISSION_REVOKED(user.name, user.surname, error.response?.data.message))
      );
    } else {
      Notification.error(ErrorMessages.OWN_PERMISSION_REVOKED());
    }
  };

  checkIfUserStillHasAccessToCompany = (userId: number, companyId: number): boolean => {
    return this.props.users.filter((user) => user.id !== userId).some((user) => user.company.id === companyId);
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLocaleLowerCase();
    if (searchTerm === '') {
      this.setState({ searchTerm: '', users: this.props.users });
    } else {
      let filteredUsers = [] as User[];
      this.props.users.forEach((user) => {
        if (
          user.name.toLocaleLowerCase().includes(searchTerm) ||
          user.surname.toLocaleLowerCase().includes(searchTerm) ||
          user.phone?.includes(searchTerm) ||
          user.email?.includes(searchTerm) ||
          user.company.name.toLocaleLowerCase().includes(searchTerm) ||
          user.division.name.toLocaleLowerCase().includes(searchTerm) ||
          user.role.toString().toLocaleLowerCase().includes(searchTerm)
        ) {
          filteredUsers.push(user);
        }
      });
      this.setState({ searchTerm: searchTerm, users: filteredUsers });
    }
  };

  render() {
    const { loading } = this.props;
    const { users } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HasTextInput
              prefix={<SearchOutlined />}
              placeholder={i18n.t('shared.search')}
              onChange={(e) => this.handleSearch(e)}
              style={{ maxWidth: '180px', marginRight: '20px' }}
              size="large"
            />
            <Skeleton loading={loading} active paragraph={false} title={{ width: '150px' }}>
              <HasText
                content={i18n.t('dataDisplay.displayingResults', { count: users.length })}
                style={{ fontSize: '12px' }}
              />
            </Skeleton>
          </div>
        </div>
        <HasTable<User>
          rowKey={(record: User) => `${record.id} ${record.invitedBy ? record.invitedBy.id : '-'}`}
          data={users}
          columns={this.getColumns()}
          loading={loading}
          style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          useScroll
        />
      </div>
    );
  }
}

export default HasMyPermissionInvitations;
