import { SelectValue } from 'antd/lib/select';
import { isEqual } from 'lodash';
import React, { ReactNode } from 'react';
import { FaRegBuilding } from 'react-icons/fa';
import { Company, Division, UserRole } from '../../../models';
import { CompanyService } from '../../../services';
import { CompanyFilterHeader, Option } from '../../../shared';
import { getUserRole } from '../../../utils';
import { HasDropdown, HasText } from '../../atoms';

interface SingleDropdownGroupProps {
  companies: Company[];
  hideDivisionsDropdown?: boolean;
}

interface SingleDropdownGroupState {
  selectedCompany: Company;
  companyDropdownOptions: Option<Company>[];
  disivionDropdownOptions?: Option<Division>[];
}

class HasSingleDropdownGroup extends React.Component<SingleDropdownGroupProps, SingleDropdownGroupState> {
  private companyFilter: CompanyFilterHeader = { companies: [] };

  constructor(props: Readonly<SingleDropdownGroupProps>) {
    super(props);

    const { companies } = this.props;
    const userRole = getUserRole();

    let selectedCompany;
    let ownCompany;
    if (userRole) {
      switch (userRole) {
        case UserRole.ADMIN:
        case UserRole.SUPER_ADMIN:
        case UserRole.OWNER:
          ownCompany = companies.find((company) => company.isPartnerCompany === false);
          selectedCompany = ownCompany ? ownCompany : companies[0];
          break;
        default:
          ownCompany = companies.find((company) => company.isPartnerCompany === false);
          selectedCompany = ownCompany ? ownCompany : companies[0];
          break;
      }
    } else {
      selectedCompany = companies[0];
    }

    CompanyService.selectedCompanyChanged(selectedCompany.id);

    this.state = {
      selectedCompany,
      companyDropdownOptions: this.buildCompanyOptions(),
    };
  }

  componentDidMount() {
    const { hideDivisionsDropdown } = this.props;
    if (!hideDivisionsDropdown) {
      const divisionOptions = this.buildDivisionOptions();
      this.setState({ disivionDropdownOptions: divisionOptions });
    }
  }

  componentDidUpdate(prevProps: Readonly<SingleDropdownGroupProps>) {
    if (!isEqual(prevProps.companies, this.props.companies)) {
      const selectedCompany = this.props.companies.filter((company) => company.id === this.state.selectedCompany.id)[0];
      this.setState({ selectedCompany, companyDropdownOptions: this.buildCompanyOptions() });
    }
  }

  buildCompanyOptions = (): Option<Company>[] => {
    const { companies } = this.props;
    return companies.map<Option<Company>>((company) => ({
      value: company.id,
      label: company.name,
      forObject: company,
    }));
  };

  buildDivisionOptions = (): Option<Division>[] => {
    const { selectedCompany: userCompany } = this.state;
    return userCompany.divisions.map<Option<Division>>((division) => ({
      value: division.id,
      label: division.name,
      forObject: division,
    }));
  };

  selectedCompanyChanged = (value: SelectValue, option: Option<Company>) => {
    const { hideDivisionsDropdown } = this.props;

    const selectedCompany = option.forObject;

    CompanyService.selectedCompanyChanged(selectedCompany.id);

    let disivionDropdownOptions = undefined;
    if (!hideDivisionsDropdown) {
      disivionDropdownOptions = this.buildDivisionOptions();
    }
    this.setState({ selectedCompany, disivionDropdownOptions });
  };

  getCompanyDropdown = (): ReactNode => {
    const { selectedCompany, companyDropdownOptions } = this.state;
    const userRole = getUserRole();
    switch (userRole) {
      case UserRole.OWNER:
        return (
          <HasDropdown<Company>
            value={selectedCompany.id}
            options={companyDropdownOptions}
            onChange={this.selectedCompanyChanged}
            style={{ width: '200px' }}
          />
        );
      case UserRole.ADMIN:
      default:
        return (
          <div>
            <FaRegBuilding style={{ fontSize: '18px', marginRight: '5px', opacity: 0.6 }} />
            <HasText content={selectedCompany.name} strong />
          </div>
        );
    }
  };

  render() {
    const { hideDivisionsDropdown } = this.props;
    return (
      <React.Fragment>
        {this.getCompanyDropdown()}
        {!hideDivisionsDropdown && <HasDropdown<Division> style={{ marginLeft: '20px', width: '200px' }} />}
      </React.Fragment>
    );
  }
}

export default HasSingleDropdownGroup;
