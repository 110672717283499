import AttachmentService from './attachment.service';
import { URL } from '../rest.service';

class InvestigationAttachmentService extends AttachmentService {
  protected entityURL: string = URL.INVESTIGATION;
  protected uploadSubURL: string = 'attachDocument';
  protected attachmentURL: string = URL.INVESTIGATION_ATTACHMENT;
}

export default new InvestigationAttachmentService();
