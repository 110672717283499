import React from 'react';
import { HasButton, HasParagraph, HasRadioGroup } from '../..';
import i18n from '../../../i18n/config';
import { HIRE_CATEGORIES, HIRE_CATEGORIES_LABELS } from '../../../models';
import {
  DELIMITER,
  ErrorMessages,
  HireSubTypesMap,
  HireTypes,
  i18nRadioGroupWrapper,
  RadioGroupOption,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
} from '../../../shared';
import { HasText } from '../../atoms';

interface HireTypeRadioGroupProps {
  save: (result: string) => void;
  previousValues: string;
}

interface HireTypeRadioGroupState {
  radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<HireTypes>>[];
  subRadioOptionsConfig: RadioGroupOption<string>[];
  selectedType: string;
  selectedSubType: string;
  error?: boolean;
}

class HasHireTypeRadioGroup extends React.Component<HireTypeRadioGroupProps, HireTypeRadioGroupState> {
  private radioGroupConfig: TypedRadioGroupProps<i18nRadioGroupWrapper<HireTypes>>;
  private subRadioGroupConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<HireTypeRadioGroupProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onCauseChange,
    };

    this.subRadioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      style: { marginBottom: '8px' },
      onChange: this.onSubCauseChange,
    };

    const radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<HireTypes>>[] = HIRE_CATEGORIES.map(
      (cause, index) => ({
        checked: false,
        value: { enumValue: HIRE_CATEGORIES[index], i18nKey: HIRE_CATEGORIES_LABELS[index] },
        label: i18n.t(HIRE_CATEGORIES_LABELS[index]),
      })
    );

    this.state = {
      radioOptionsConfig,
      subRadioOptionsConfig: [],
      selectedType: '',
      selectedSubType: '',
    };
  }

  componentDidMount() {
    // const { previousValues } = this.props;
    // if (previousValues !== '') {
    // 	const splitValues = previousValues.split(',').map(element => element.trim());
    // 	const type = HIRE_CATEGORIES[HIRE_CATEGORIES_LABELS.indexOf(splitValues[0])];
    // 	const subType = Object.keys(type).find((key: any) => type[key] === splitValues[1]);
    // 	if (type && subType) {
    // 		this.subRadioOptionsConfig = Object.keys(type).map((subCause: any, index) => ({
    // 			key: `${getElementKey('hire_sub_type', subCause)}`,
    // 			checked: false,
    // 			value: subCause,
    // 			forObject: subCause,
    // 			label: type[subCause]
    // 		}))
    // 		this.selectedType = type;
    // 		this.setState({ selectedType: splitValues[0], selectedSubType: subType });
    // 	}
    // }
    i18n.on('languageChanged', () => this.handleLanguageChange());
  }

  handleLanguageChange = () => {
    const { selectedType } = this.state;
    this.buildRadioGroupOptions();
    if (selectedType) {
      this.buildSubRadioGroupOptions();
    }
  };

  buildRadioGroupOptions = (): void => {
    this.setState((prevState: Readonly<HireTypeRadioGroupState>) => {
      let radioOptionsConfig = prevState.radioOptionsConfig;
      radioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value.i18nKey)));
      return { ...prevState, radioOptionsConfig };
    });
  };

  buildSubRadioGroupOptions = (): void => {
    this.setState((prevState: Readonly<HireTypeRadioGroupState>) => {
      let subRadioOptionsConfig = prevState.subRadioOptionsConfig;
      subRadioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value)));
      return { ...prevState, subRadioOptionsConfig };
    });
  };

  onCauseChange = (event: TypedRadioChangeEvent<i18nRadioGroupWrapper<HireTypes>>) => {
    let subReasons = Object.keys(event.value.enumValue).filter((key) => !+key && key !== '0');

    const subRadioOptionsConfig = subReasons.map((subType) => {
      const subReasonData = HireSubTypesMap.get(subType);
      if (subReasonData) {
        return {
          checked: false,
          defaultChecked: false,
          value: subReasonData,
          label: i18n.t(subReasonData),
        };
      } else {
        return { checked: false, defaultChecked: false, value: 'Unknown', label: i18n.t('dataDisplay.unknownValue') };
      }
    });

    this.setState({
      selectedType: event.value.i18nKey,
      selectedSubType: '',
      subRadioOptionsConfig,
    });
  };

  onSubCauseChange = (event: any) => {
    this.setState({ selectedSubType: event.value });
  };

  saveData = () => {
    const { selectedType, selectedSubType } = this.state;
    if (!selectedType || !selectedSubType) {
      this.setState({ error: true });
    } else {
      const answer = selectedType.concat(DELIMITER).concat(`,`).concat(DELIMITER).concat(selectedSubType);
      this.props.save(answer);
    }
  };

  componentWillUnmount() {
    i18n.off('languageChanged', () => this.handleLanguageChange());
  }

  render() {
    const { radioOptionsConfig, subRadioOptionsConfig, selectedType, selectedSubType, error } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup {...this.radioGroupConfig} groupOptions={radioOptionsConfig} />
        {selectedType ? (
          <React.Fragment>
            <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
            <HasRadioGroup {...this.subRadioGroupConfig} groupOptions={subRadioOptionsConfig} value={selectedSubType} />
          </React.Fragment>
        ) : null}
        {selectedType && selectedSubType && (
          <HasButton
            type="primary"
            size="small"
            htmlType="button"
            onClick={this.saveData}
            style={{ marginTop: '8px', alignSelf: 'flex-end' }}
          >
            {i18n.t('shared.save')}
          </HasButton>
        )}
        {error && (
          <HasText
            content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.riskType').toLowerCase())}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </div>
    );
  }
}

export default HasHireTypeRadioGroup;
