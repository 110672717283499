import { AxiosResponse } from 'axios';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Division } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class DivisionService implements RestService<Division> {
  private selectedDivision = new ReplaySubject<number>(1);

  private divisionChangedSubject = new Subject<SubscriberEvent<Division>>();

  get(params?: any): Promise<AxiosResponse<Division>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Division[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Division>> {
    return httpClient.get<Division>(buildUrl(URL.DIVISION, [resourceId]));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Division>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Division>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Division): Promise<AxiosResponse<Division>> {
    return httpClient.post<Division>(URL.DIVISION, resource);
  }

  update(resource: Division): Promise<AxiosResponse<Division>> {
    return httpClient.put<Division>(URL.DIVISION, resource);
  }

  delete(resourceId: string | number, divisionToMoveIncidents?: number): Promise<AxiosResponse<Division>> {
    let params = {};
    if (divisionToMoveIncidents) {
      params = { divisionToMoveIncidents: divisionToMoveIncidents };
    }
    return httpClient.delete<Division>(buildUrl(URL.DIVISION, ['delete', resourceId]), { params: params });
  }

  undelete(resourceId: string | number): Promise<AxiosResponse<Division>> {
    return httpClient.put<Division>(buildUrl(URL.DIVISION, ['undelete', resourceId]));
  }

  getDivisionForLoggedInUser(): Promise<AxiosResponse<Division[]>> {
    return httpClient.get(buildUrl(URL.DIVISION, ['all', 'me']));
  }

  getLoggedInUserDivision(): Promise<AxiosResponse<Division[]>> {
    return httpClient.get(buildUrl(URL.DIVISION, ['me']));
  }

  getDivisionsForCompany(companyId: number): Promise<AxiosResponse<Division[]>> {
    return httpClient.get<Division[]>(buildUrl(URL.DIVISION, ['all', 'company', companyId]));
  }

  // SUBSCRIPTIONS

  selectedDivisionChanged(divisionId: number) {
    this.selectedDivision.next(divisionId);
  }

  getDivisionListener(): Observable<number> {
    return this.selectedDivision.asObservable();
  }

  getDivisonChangedListener(): Observable<SubscriberEvent<Division>> {
    return this.divisionChangedSubject.asObservable();
  }

  divisionChanged(event: EntityAction, division: Division) {
    this.divisionChangedSubject.next({ event: event, entity: division });
  }
}

export default new DivisionService();
