import { GlobalOutlined, RollbackOutlined } from '@ant-design/icons';
import { Divider, Form as AntdForm, Tooltip } from 'antd';
import en_US from 'antd/lib/calendar/locale/en_US';
import { difference, isUndefined, noop, uniqBy } from 'lodash';
import React, { ReactNode, ReactText } from 'react';
import { getFormattedValue } from '.';
import {
  HasButton,
  HasCoordinatedRadioGroup,
  HasDatePicker,
  HasDropdown,
  HasInputsForm,
  HasRadioGroup,
  HasText,
  HasTextArea,
  HasTextInput,
} from '../components';
import i18n from '../i18n/config';
import {
  Company,
  Division,
  Form,
  FormResult,
  FormResultType,
  Incident,
  IncidentType,
  NewFormResult,
  NewIncident,
  RiskLevel,
  RiskImpact,
  RiskProbability,
} from '../models';
import {
  ChatBotStep,
  COLORS,
  DATE_TIME_FORMAT,
  ErrorMessages,
  EventEnumLabels,
  FORM_RESULT_KEYS,
  HistoricalDataColumns,
  OptionsStep,
  RadioGroupForEventEntry,
  RISK_LEVEL_COLOR,
  RISK_SCORE_COLOR,
  RISK_SCORE_MATRIX,
  RISK_SCORE_VALUES,
  TextStep,
} from '../shared';
import { isEqualIgnoreCase } from './functions';

type EntryValueComponentParams = {
  event: NewIncident | Incident;
  key: ReactText;
  type: string;
  formSteps: ChatBotStep[];
  onChange: (key: ReactText, value: string) => void;
  company?: Company;
};

const {
  REASON,
  CAUSE,
  SUB_REASON,
  SUB_CAUSE,
  REPORTING_PERSON_IS_VICTIM,
  VICTIM_CONTACT_FIRST_NAME,
  VICTIM_CONTACT_LAST_NAME,
  VICTIM_CONTACT_EMAIL,
  VICTIM_CONTACT_PHONE,
  WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED,
  EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER,
  SUBJECT,
  HAZARD_DESCRIPTION,
  WHAT_HAPPENED_DETAILS,
} = FORM_RESULT_KEYS;

type QuestionCategory = Record<IncidentType, string | null>;
const NEEDS_ESCALATING: QuestionCategory = {
  [IncidentType.NEAR_HIT]: 'DOES_NEAR_HIT_INVESTIGATION_ESCALATING_TO_LINE_MANAGER',
  [IncidentType.INCIDENT]: 'DOES_INCIDENT_INVESTIGATION_ESCALATING_TO_LINE_MANAGER',
  [IncidentType.HOUSE_KEEPING]: 'DOES_IT_NEED_ESCALATING',
  [IncidentType.AUDIT]: null,
  [IncidentType.SAFETY_CONV]: null,
  [IncidentType.TBOX]: null,
};
const IS_SAFE: QuestionCategory = {
  [IncidentType.NEAR_HIT]: 'IS_STILL_UNSAFE',
  [IncidentType.INCIDENT]: 'IS_STILL_UNSAFE',
  [IncidentType.HOUSE_KEEPING]: 'IS_SITUATION_SAFE',
  [IncidentType.AUDIT]: null,
  [IncidentType.SAFETY_CONV]: null,
  [IncidentType.TBOX]: null,
};
const IS_CONCERNED: QuestionCategory = {
  [IncidentType.NEAR_HIT]: 'ARE_YOU_VERY_CONCERNED',
  [IncidentType.INCIDENT]: 'ARE_YOU_VERY_CONCERNED',
  [IncidentType.HOUSE_KEEPING]: 'ARE_YOU_CONCERNED',
  [IncidentType.AUDIT]: null,
  [IncidentType.SAFETY_CONV]: null,
  [IncidentType.TBOX]: null,
};

const keyToCategory: Record<string, QuestionCategory> = {
  DOES_NEAR_HIT_INVESTIGATION_ESCALATING_TO_LINE_MANAGER: NEEDS_ESCALATING,
  DOES_INCIDENT_INVESTIGATION_ESCALATING_TO_LINE_MANAGER: NEEDS_ESCALATING,
  DOES_IT_NEED_ESCALATING: NEEDS_ESCALATING,
  IS_STILL_UNSAFE: IS_SAFE,
  IS_SITUATION_SAFE: IS_SAFE,
  ARE_YOU_VERY_CONCERNED: IS_CONCERNED,
  ARE_YOU_CONCERNED: IS_CONCERNED,
};

class EventUtils {
  keysToNotTranslate = [
    FORM_RESULT_KEYS.WHERE_DID_IT_HAPPEN,
    FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER,
  ];

  getEquivalentFormResults = (
    formResults: FormResult[] | NewFormResult[],
    newType: IncidentType
  ): Record<string, string> => {
    const equivalentQuestions: Record<string, string> = {};
    formResults.forEach((formResult) => {
      const category: QuestionCategory = keyToCategory[formResult.key];
      if (category) {
        const newKey: string | null = category[newType];
        if (newKey) {
          equivalentQuestions[formResult.key] = newKey;
        }
      }
    });
    return equivalentQuestions;
  };

  ignoreReportingIsVictim(formResult: FormResult[] | NewFormResult[]) {
    const ignoredKeys: string[] = [];
    const reportingIsVictim = this.getLatestNewFormResultByKey(formResult, REPORTING_PERSON_IS_VICTIM).maxItem;
    if (
      reportingIsVictim &&
      !isUndefined(reportingIsVictim?.value) &&
      (isEqualIgnoreCase(reportingIsVictim.value.toString(), 'shared.yes') ||
        isEqualIgnoreCase(reportingIsVictim.value.toString(), 'true'))
    ) {
      ignoredKeys.push(VICTIM_CONTACT_FIRST_NAME, VICTIM_CONTACT_LAST_NAME, VICTIM_CONTACT_EMAIL, VICTIM_CONTACT_PHONE);
    }
    return ignoredKeys;
  }

  ignoreMachinesInvolved(formResult: FormResult[] | NewFormResult[]) {
    const ignoredKeys: string[] = [];
    const machinesInvolved = this.getLatestNewFormResultByKey(
      formResult,
      WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED
    ).maxItem;
    if (
      machinesInvolved &&
      !isUndefined(machinesInvolved?.value) &&
      (isEqualIgnoreCase(machinesInvolved.value.toString(), 'shared.no') ||
        isEqualIgnoreCase(machinesInvolved.value.toString(), 'false'))
    ) {
      ignoredKeys.push(EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER);
    }
    return ignoredKeys;
  }

  getIgnoredFormResults = (formResult: FormResult[] | NewFormResult[]): string[] => {
    let ignoredKeys: string[] = [];

    ignoredKeys = [...ignoredKeys, ...this.ignoreReportingIsVictim(formResult)];
    ignoredKeys = [...ignoredKeys, ...this.ignoreMachinesInvolved(formResult)];

    return ignoredKeys;
  };

  filterOutIntermediateSteps = (mappedSteps: ChatBotStep[]): ChatBotStep[] => {
    return uniqBy<ChatBotStep>(
      mappedSteps.filter((step) => step.metadata && step.metadata.key),
      (step) => step.metadata.key
    );
  };

  extractStepKeys = (mappedSteps: ChatBotStep[]): string[] => {
    return this.filterOutIntermediateSteps(mappedSteps).map((step) => step.metadata.key);
  };

  getFormStructureDiff = (
    { mappedSteps: fromSteps }: Form,
    { mappedSteps: toSteps }: Form
  ): { toAdd: ChatBotStep[]; toDelete: ChatBotStep[] } => {
    const fromKeys = this.extractStepKeys(fromSteps);
    const toKeys = this.extractStepKeys(toSteps);

    const toDeleteKeys = difference(fromKeys, toKeys);
    const toAddKeys = difference(toKeys, fromKeys);

    const toDelete = this.filterOutIntermediateSteps(fromSteps).filter((step) =>
      toDeleteKeys.includes(step.metadata.key)
    );
    const toAdd = this.filterOutIntermediateSteps(toSteps).filter((step) => toAddKeys.includes(step.metadata.key));

    return { toAdd, toDelete };
  };

  getDescriptionFromFormResult = (event: NewIncident): string | undefined => {
    const { formResult } = event;
    switch (event.type) {
      case IncidentType.AUDIT.toString():
      case IncidentType.SAFETY_CONV.toString():
      case IncidentType.TBOX.toString():
        return formResult.find((result) => result.key === SUBJECT)?.value?.toString();
      case IncidentType.HOUSE_KEEPING.toString():
        return formResult.find((result) => result.key === HAZARD_DESCRIPTION)?.value?.toString();
      case IncidentType.INCIDENT.toString():
      case IncidentType.NEAR_HIT.toString():
        return formResult.find((result) => result.key === WHAT_HAPPENED_DETAILS)?.value?.toString();
    }
  };

  getOptionsForQuestion = (formSteps: ChatBotStep[], key: ReactText): RadioGroupForEventEntry[] => {
    return formSteps
      .filter((step) => step.metadata?.key && step.metadata.key === key)
      .reduce<RadioGroupForEventEntry[]>((acc, step) => acc.concat((step as OptionsStep).options), []);
  };

  getEventReasonOrCauseOptionsMap = (
    formSteps: ChatBotStep[],
    resultType: FormResultType.REASON | FormResultType.CAUSE
  ): Map<RadioGroupForEventEntry, Array<RadioGroupForEventEntry>> => {
    const parentNode = formSteps.find((step) => step.metadata && step.metadata.type === FormResultType[resultType]);
    const parentOptions = (parentNode as OptionsStep).options;

    const map = new Map<RadioGroupForEventEntry, Array<RadioGroupForEventEntry>>();
    parentOptions.forEach((option) => {
      const intermediaryNode = formSteps.find((step) => step.id === option.trigger);
      if (!isUndefined(intermediaryNode)) {
        const childNode = formSteps.find((step) => step.id === (intermediaryNode as TextStep).trigger);
        if (!isUndefined(childNode)) {
          const subReasons = (childNode as OptionsStep).options;
          map.set(option, subReasons);
        }
      }
    });
    return map;
  };

  getFullReasonOrCause = (
    formResult: FormResult[] | NewFormResult[],
    resultType: FormResultType.REASON | FormResultType.CAUSE,
    seqNo?: number
  ): string[] | undefined => {
    let key: string;
    let subKey: string;
    if (resultType === FormResultType.REASON) {
      key = REASON;
      subKey = SUB_REASON;
    } else {
      key = CAUSE;
      subKey = SUB_CAUSE;
    }
    const parent = formResult.find(
      ({ seqNo: formResultSequenceNumber, key: formResultKey }) =>
        formResultSequenceNumber === seqNo && formResultKey === key
    )?.value;
    const child = formResult.find(
      ({ seqNo: formResultSequenceNumber, key: formResultKey }) =>
        formResultSequenceNumber === seqNo && formResultKey === subKey
    )?.value;
    if (!isUndefined(parent) && !isUndefined(child)) {
      const parentValue = EventEnumLabels.get(parent.toString());
      const childValue = EventEnumLabels.get(child.toString());
      if (typeof child === 'string')
        if (parentValue) {
          return childValue ? [parentValue, childValue] : [parentValue, child];
        } else {
          return parentValue && childValue ? [parentValue, childValue] : undefined;
        }
      return undefined;
    } else {
      return undefined;
    }
  };

  getEntryTitle = (formLabels: { key: string; question: string }[], key: string | number): string => {
    const i18nKey = formLabels.find(({ key: formKey }) => formKey === key)?.question || HistoricalDataColumns[key];
    return i18nKey;
  };

  getEntryValueComponent = ({
    event,
    formSteps,
    key,
    type,
    onChange,
    company,
  }: EntryValueComponentParams): JSX.Element | null => {
    let component: JSX.Element;
    if (event.type) {
      if (formSteps) {
        switch (type) {
          case FormResultType.TEXT:
            component = <HasTextArea maxLength={255} />;
            break;
          case FormResultType.DATE:
            component = (
              <HasDatePicker
                showTime
                format={DATE_TIME_FORMAT}
                locale={{ ...en_US, lang: { ...en_US.lang, now: i18n.t('shared.now') } }}
                minuteStep={5}
              />
            );
            break;
          case FormResultType.BOOLEAN:
          case FormResultType.OPTION_DESCRIPTION:
          case FormResultType.OPTION:
            if (key === 'ORG_SELECTION' && company) {
              component = (
                <HasDropdown<Division>
                  options={company.divisions.map((division) => ({
                    value: division.id,
                    label: division.name,
                    forObject: division,
                  }))}
                  style={{ minWidth: 200 }}
                />
              );
            } else if (key === WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED) {
              return (
                <HasInputsForm
                  firstInputQuestion={i18n.t('questionnaire.pleaseProvideDescription', 'Please provide a description')}
                  secondInputQuestion={i18n.t(
                    'questionnaire.pleaseProvideSerialNumber',
                    'Please provide the serial number'
                  )}
                  onChange={noop}
                  onSave={(data: any) => onChange(WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED, data)}
                  selectedOptionRequiredValue={true}
                  textareaWidth={'195px'}
                  hideParagraph
                  hideTextareaPlaceholder
                />
              );
            } else {
              const availableOptions = this.getOptionsForQuestion(formSteps, key);
              component = (
                <HasRadioGroup
                  buttonStyle="solid"
                  groupOptions={availableOptions.map((option) => ({
                    value: option.value,
                    label: i18n.t(option.label),
                  }))}
                  onChange={noop}
                  size="small"
                />
              );
            }
            break;
          case FormResultType.REASON:
          case FormResultType.SUB_REASON:
            const reasonsMap = this.getEventReasonOrCauseOptionsMap(formSteps, FormResultType.REASON);
            return (
              <HasCoordinatedRadioGroup<ReactText | boolean>
                parentRadioGroupProps={{ size: 'small', buttonStyle: 'solid', className: 'mb-2' }}
                childRadioGroupProps={{ size: 'small', buttonStyle: 'solid', className: 'mb-2' }}
                buttonProps={{ type: 'primary', size: 'small' }}
                config={reasonsMap}
                onSave={(reason, subReason) => {
                  onChange(REASON, reason.toString());
                  onChange(SUB_REASON, subReason.toString());
                }}
              />
            );
          case FormResultType.CAUSE:
          case FormResultType.SUB_CAUSE:
            const causeMap = this.getEventReasonOrCauseOptionsMap(formSteps, FormResultType.CAUSE);
            return (
              <HasCoordinatedRadioGroup<ReactText | boolean>
                parentRadioGroupProps={{ size: 'small', buttonStyle: 'solid', className: 'mb-2' }}
                childRadioGroupProps={{ size: 'small', buttonStyle: 'solid', className: 'mb-2' }}
                buttonProps={{ type: 'primary', size: 'small' }}
                config={causeMap}
                onSave={(cause, subCause) => {
                  onChange(CAUSE, cause.toString());
                  onChange(SUB_CAUSE, subCause.toString());
                }}
              />
            );
          default:
            component = <HasTextInput size="small" />;
            break;
        }
        return (
          <>
            <AntdForm
              onFinish={(store) => onChange(key, store[key.toString()])}
              layout="inline"
              className="d-flex flex-row justify-content-between"
            >
              <AntdForm.Item
                name={key.toString()}
                rules={[{ required: true, message: ErrorMessages.GENERIC_INPUT_REQUIRED }]}
                style={{ maxWidth: '70%' }}
              >
                {component}
              </AntdForm.Item>
              <AntdForm.Item className="m-0">
                <HasButton htmlType="submit" type="primary" size="small">
                  {i18n.t('shared.save')}
                </HasButton>
              </AntdForm.Item>
            </AntdForm>
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
      }
    }
    return null;
  };

  getIndexByIncidentType(incidentType: string): number {
    switch (incidentType) {
      case IncidentType.NEAR_HIT:
        return 0;
      case IncidentType.INCIDENT:
        return 1;
      case IncidentType.AUDIT:
        return 2;
      case IncidentType.SAFETY_CONV:
        return 3;
      case IncidentType.HOUSE_KEEPING:
        return 4;
      case IncidentType.TBOX:
        return 5;
      default:
        throw new Error('Invalid incident type');
    }
  }

  // this was moved for incident report but it is also used in structure.diff
  getEntryDescription = (
    formResults: FormResult[] | NewFormResult[],
    result: FormResult | NewFormResult | undefined,
    translateOptions?: {
      onShowTranslation?: (id?: number) => void;
      translationResult: { [k: number]: string };
      resultIdsTranslated: number[];
    }
  ): ReactNode => {
    if (isUndefined(result)) {
      return (
        <>
          <div className="d-flex flex-row justify-content-end">
            <HasText
              content={'-'}
              style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
              className="text-right"
            />
          </div>
          <Divider style={{ margin: '16px 0 8px 0' }} />
        </>
      );
    }
    switch (result.type) {
      case FormResultType.REASON:
        const reasonAndSubReason = this.getFullReasonOrCause(formResults, FormResultType.REASON, result.seqNo);
        const reasonContent = reasonAndSubReason ? reasonAndSubReason.map((item) => i18n.t(item)).join(', ') : '-';
        return (
          <>
            <div className="d-flex flex-row justify-content-end">
              <HasText
                content={reasonContent}
                style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
                className="text-right"
              />
            </div>
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
      case FormResultType.CAUSE:
        const causeAndSubCause = this.getFullReasonOrCause(formResults, FormResultType.CAUSE, result.seqNo);
        const causeContent = causeAndSubCause ? causeAndSubCause.map((item) => i18n.t(item)).join(', ') : '-';
        return (
          <>
            <div className="d-flex flex-row justify-content-end">
              <HasText
                content={causeContent}
                style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
              />
            </div>
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
      case FormResultType.TEXT:
        let showTranslateButton = true;
        if (!result.value?.toString() || !result.id) {
          showTranslateButton = false;
        } else {
          showTranslateButton = !i18n.exists(result.value?.toString() || '');
        }

        let isEntryTranslated = false;
        if (result.id && translateOptions) {
          isEntryTranslated = translateOptions.resultIdsTranslated.includes(result.id);
        }

        return (
          <>
            {!this.keysToNotTranslate.includes(result.key.toString()) && (
              <div style={{ display: 'grid', gridTemplateColumns: showTranslateButton ? '95% 5%' : '100%' }}>
                <div className="d-flex flex-row justify-content-end">
                  <HasText
                    content={
                      isEntryTranslated
                        ? result?.id
                          ? isEntryTranslated === true
                            ? translateOptions?.translationResult[result.id]
                            : 'false'
                          : result.value!.toString()
                        : result.value!.toString()
                    }
                    style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE, wordBreak: 'break-all' }}
                  />
                </div>
                {showTranslateButton && (
                  <div className="d-flex flex-row justify-content-end">
                    {!translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.translate')}>
                        <GlobalOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                    {translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.showOriginal')}>
                        <RollbackOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            )}

            {this.keysToNotTranslate.includes(result.key.toString()) && (
              <div className="d-flex flex-row justify-content-end">
                <HasText
                  content={result.value!.toString()}
                  style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
                />
              </div>
            )}

            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
      case FormResultType.DATE:
        return (
          <>
            <div className="d-flex flex-row justify-content-end">
              <HasText
                content={getFormattedValue(result.type, result.value!.toString())}
                style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
              />
            </div>
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
      case FormResultType.BOOLEAN:
      case FormResultType.OPTION:
      case FormResultType.OPTION_DESCRIPTION:
        let showTranslateButtonForOptionDescription = true;

        if (!result.value?.toString() || !result.id) {
          showTranslateButtonForOptionDescription = false;
        } else {
          showTranslateButtonForOptionDescription = !i18n.exists(result.value?.toString() || '');
        }

        let isEntryTranslatedForOptionDescription = false;
        if (result.id && translateOptions) {
          isEntryTranslatedForOptionDescription = translateOptions.resultIdsTranslated.includes(result.id);
        }
        return (
          <>
            {result.extra && !this.keysToNotTranslate.includes(result.key.toString()) && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: showTranslateButtonForOptionDescription ? '70% 25% 5%' : '100%',
                }}
              >
                <HasText
                  content={
                    isEntryTranslatedForOptionDescription
                      ? result?.id
                        ? isEntryTranslatedForOptionDescription === true
                          ? translateOptions?.translationResult[result.id]
                          : 'false'
                        : result.extra!.toString()
                      : result.extra!.toString()
                  }
                  style={{ fontSize: '12px' }}
                />
                <div className="d-flex flex-row justify-content-end">
                  <HasText
                    content={i18n.t(getFormattedValue(result.type, result.value!.toString()).trim())}
                    style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
                  />
                </div>
                {showTranslateButtonForOptionDescription && (
                  <div className="d-flex flex-row justify-content-end">
                    {!translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.translate')}>
                        <GlobalOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                    {translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.showOriginal')}>
                        <RollbackOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            )}

            {(!result.extra || this.keysToNotTranslate.includes(result.key.toString())) && (
              <div className="d-flex justify-content-between">
                <HasText content={result.extra} style={{ fontSize: '12px' }} />
                <HasText
                  content={i18n.t(getFormattedValue(result.type, result.value!.toString()).trim())}
                  style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
                />
              </div>
            )}
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </>
        );
    }
  };

  getLatestFormResultByKey = (formResults: FormResult[], key: string) => {
    return formResults.reduce(
      (acc, item, index) => {
        if (item.key === key && item.seqNo > acc.maxItem.seqNo) {
          return { maxItem: item, maxIndex: index };
        }
        return acc;
      },
      { maxItem: { seqNo: -1, key: '', value: '' } as FormResult, maxIndex: -1 }
    );
  };

  getLatestNewFormResultByKey = (formResults: NewFormResult[], key: string) => {
    return formResults.reduce(
      (acc, item, index) => {
        if (item.key === key && item.seqNo > acc.maxItem.seqNo) {
          return { maxItem: item, maxIndex: index };
        }
        return acc;
      },
      { maxItem: { seqNo: -1, key: '', value: undefined } as NewFormResult, maxIndex: -1 }
    );
  };

  getLatestAndOldestIncidentEntries = (formResult: FormResult[]) => {
    const groupedResults = formResult.reduce((acc, current) => {
      const { key } = current;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(current);
      return acc;
    }, {} as Record<string, FormResult[]>);

    const oldestEntries: FormResult[] = [];
    const latestEntries: FormResult[] = [];

    Object.keys(groupedResults).forEach((key) => {
      const entries = groupedResults[key];
      const maxSeqNo = Math.max(...entries.map((entry) => entry.seqNo));
      entries.forEach((entry) => {
        if (entry.seqNo < maxSeqNo) {
          oldestEntries.push(entry);
        } else {
          latestEntries.push(entry);
        }
      });
    });
    return { oldestEntries, latestEntries };
  };

  isSameDay = (date: Date): boolean => {
    const now = new Date();
    return (
      date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
    );
  };

  getDisabledTime = (time: any) => {
    const hours = Array.from(Array(24).keys());
    const minutes = Array.from(Array(12).keys()).map((min) => min * 5);
    return {
      disabledHours: () =>
        time && this.isSameDay(time.toDate()) ? hours.filter((hour) => time && time.get('h') < hour) : [],
      disabledMinutes: () =>
        time && this.isSameDay(time.toDate()) ? minutes.filter((minute) => time && time.get('m') < minute) : [],
    };
  };

  // Risk matrix for investigation & action
  // The matrix consists of a probability & impact value
  // Based on them, a risk score and level is generated

  getRiskMatrixValues = (impact: number, probability: number) => {
    const riskColor = RISK_SCORE_COLOR[impact - 1][probability - 1];
    const riskLevel: RiskLevel = (Object.keys(RISK_LEVEL_COLOR) as RiskLevel[]).find(
      (key) => RISK_LEVEL_COLOR[key as RiskLevel] === riskColor
    )!;
    return { riskColor, riskLevel };
  };

  getRiskImpactIndex = (value: string | undefined) => {
    const keys = Object.keys(RiskImpact) as (keyof typeof RiskImpact)[];
    const index = keys.findIndex((key) => RiskImpact[key] === value);
    return index !== -1 ? index + 1 : undefined;
  };

  getRiskProbabilityIndex = (value: string | undefined) => {
    const keys = Object.keys(RiskProbability) as (keyof typeof RiskProbability)[];
    const index = keys.findIndex((key) => RiskProbability[key] === value);
    return index !== -1 ? index + 1 : undefined;
  };

  mapToClosestRiskMatrixValue = (number: number) => {
    return RISK_SCORE_VALUES.reduce((prev, curr) => (Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev));
  };

  getRiskColor = (riskScore: number) => {
    const rows = RISK_SCORE_MATRIX.length;
    const columns = RISK_SCORE_MATRIX[0].length;

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        if (RISK_SCORE_MATRIX[i][j] === riskScore) {
          return RISK_SCORE_COLOR[i][j];
        }
      }
    }
  };
}

export default new EventUtils();
