import { isEqual } from 'lodash';
import React from 'react';
import { HasIncidentAttachments } from '.';
import { Action, Attachment, Incident, IncidentType, Investigation, Photo, Review } from '../../../models';
import { MAIN_URLS } from '../../../shared';
import { HasSpinner } from '../../atoms';
import HasActionAttachments from '../actions/attachments';
import { HasInvestigationAttachments } from '../investigation';
import { HasReviewAttachments } from '../review';
import HasIncidentPhotos from './photos';

interface IncidentMediaProps {
  incident: Incident;
  investigation: Investigation;
  review: Review;
  actions: Action[];
  photos: Photo[];
  incidentAttachments: Attachment[];
  investigationAttachments: Attachment[];
  actionAttachments: Attachment[];
  reviewAttachments: Attachment[];
  loading: boolean;
  currentView: string;
}

interface IncidentMediaState {
  showAttachments: boolean;
}

const SHOW_ATTACHMENTS_FOR = [MAIN_URLS.INVESTIGATION, MAIN_URLS.ACTION, MAIN_URLS.REVIEW, MAIN_URLS.INCIDENT];

class HasIncidentMedia extends React.PureComponent<IncidentMediaProps, IncidentMediaState> {
  state: IncidentMediaState = {
    showAttachments: SHOW_ATTACHMENTS_FOR.some((url) => url.includes(this.props.currentView)),
  };

  componentDidUpdate(prevProps: Readonly<IncidentMediaProps>) {
    if (!isEqual(prevProps.currentView, this.props.currentView)) {
      this.setState({ showAttachments: this.shouldShowAttachments() });
    }
  }

  shouldShowAttachments = () => {
    const { currentView } = this.props;
    return SHOW_ATTACHMENTS_FOR.some((url) => url.includes(currentView));
  };

  getAttachmentsComponent = (): JSX.Element | null => {
    const {
      currentView,
      incidentAttachments,
      investigationAttachments,
      investigation,
      incident,
      actionAttachments,
      reviewAttachments,
      review,
      actions,
    } = this.props;
    switch (true) {
      case MAIN_URLS.INVESTIGATION.includes(currentView):
        return (
          <HasInvestigationAttachments
            attachments={investigationAttachments}
            entity={investigation}
            photoUploadTargetId={incident.id}
            attachmentUploadTargetId={investigation.id}
          />
        );
      case MAIN_URLS.ACTION.includes(currentView):
        return (
          <HasActionAttachments attachments={actionAttachments} entity={actions} photoUploadTargetId={incident.id} />
        );
      case MAIN_URLS.REVIEW.includes(currentView):
        return (
          <HasReviewAttachments
            attachments={reviewAttachments}
            entity={review}
            photoUploadTargetId={incident.id}
            attachmentUploadTargetId={review.id}
          />
        );
      case MAIN_URLS.INCIDENT.includes(currentView):
        return (
          <HasIncidentAttachments
            attachments={incidentAttachments}
            entity={incident}
            photoUploadTargetId={incident.id}
          />
        );
      default:
        return null;
    }
  };

  hasInvestigation = (): boolean => {
    const { incident } = this.props;
    switch (incident.type) {
      case IncidentType.NEAR_HIT:
      case IncidentType.INCIDENT:
        return true;
      default:
        return false;
    }
  };

  render() {
    const { photos, loading } = this.props;
    const { showAttachments } = this.state;
    return (
      <React.Fragment>
        <div
          className="shadow-sm has-container-tile d-flex flex-column overflow-auto"
          style={{ height: showAttachments ? '50%' : '100%', marginBottom: '5px' }}
        >
          {loading ? (
            <HasSpinner size="large" />
          ) : (
            <HasIncidentPhotos
              photos={photos}
              hideInvestigationPhotos={!this.hasInvestigation()}
              hideReviewPhotos={this.hasInvestigation()}
            />
          )}
        </div>
        {showAttachments && (
          <div className="shadow-sm has-container-tile d-flex flex-column" style={{ height: '50%' }}>
            {loading ? <HasSpinner size="large" /> : this.getAttachmentsComponent()}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default HasIncidentMedia;
