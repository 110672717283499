import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  FileImageTwoTone,
  FileTextTwoTone,
  FileZipTwoTone,
  InfoCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { gt, isEqual, isNull } from 'lodash';
import moment from 'moment';
import React, { CSSProperties, ReactNode } from 'react';
import { EventUtils, getUserRole, isUserRoleEqualsTo } from '.';
import { HasCollapse, HasText } from '../components';
import i18n from '../i18n/config';
import {
  ActionClassification,
  ActionRegulatoryQuestions,
  CONTACT_WITH,
  Company,
  Division,
  FormResult,
  IncidentType,
  OTHER,
  Permission,
  SLIPS_TRIPS_FALLS,
  STRUCK_BY,
  User,
  UserRole,
  YesNoOption,
} from '../models';
import {
  ALLOWED_EXTENSTIONS,
  ALLOWED_FILE_TYPES,
  ActionImportanceLabel,
  COMPANY_FILTER,
  CompanyFilterHeader,
  CompanyFilterHeaderContent,
  DATE_LOCALE,
  DELIMITER,
  ErrorMessages,
  IncidentCausesType,
  IncidentReasonsType,
  REGULAR_FILE_LIMIT,
  RW_ROLES,
  RadioGroupOption,
  TimeIntervals,
  VIDEO_FILE_LIMIT,
} from '../shared';

export function getQueryParamValue(queryParam: string): string {
  return queryParam.split('=')[1];
}

export function getUrlQueryParam(queryParams: string, key: string): string | undefined {
  const urlQueryParam = new URLSearchParams(queryParams);
  const param = urlQueryParam.get(key);
  if (!isNull(param)) {
    return param;
  }
  return undefined;
}

export function getElementKey(elementType: string, elementKey: string | number | undefined): string {
  return elementType
    .concat('_')
    .concat(elementKey !== undefined ? elementKey.toString() : i18n.t('dataDisplay.unknownValue'));
}

export function formatTextToTitlecase(text: any): string {
  const cleanedText = text.toString().split('_');
  let formattedText = '';
  cleanedText.forEach((piece: string, index: number) => {
    formattedText =
      index === 0
        ? formattedText.concat(piece.toLowerCase()[0].toUpperCase() + piece.toLowerCase().substring(1))
        : formattedText.concat(' ', piece.toLowerCase());
  });
  return formattedText;
}

export function formatUserRole(role: string): string {
  if (role === UserRole.LIMITED_EMPLOYEE_DIVISION) {
    return 'Limited employee (Division)';
  }
  if (role === UserRole.LIMITED_EMPLOYEE_PERSONAL) {
    return 'Limited employee (Personal)';
  }

  return formatTextToTitlecase(role);
}

export function toUpperCaseAndRemoveUnderscore(text: any): string {
  const cleanedText = text.toString().split('_');
  let formattedText = '';
  cleanedText.forEach((piece: string, index: number) => {
    formattedText = formattedText.concat(' ', piece.toUpperCase());
  });
  return formattedText;
}

export function isEqualIgnoreCase(s1: string, s2: string): boolean {
  return s1.toLowerCase() === s2.toLowerCase();
}

export function isObject(param: any): boolean {
  return !!param && param.constructor === Object;
}

export function displayPlaceholderIfEmpty(stringToTest: string | undefined | null, placeholder?: string): string {
  if (!stringToTest || stringToTest === '' || stringToTest === null || stringToTest === undefined) {
    return placeholder ? placeholder : '-';
  }
  return stringToTest;
}

export function getDate(date: string, dateFormat?: Intl.DateTimeFormatOptions): string {
  return new Date(date).toLocaleDateString(DATE_LOCALE, dateFormat).replace(',', '');
}

export function getTime(date: string, timeFormat?: Intl.DateTimeFormatOptions): string {
  return new Date(date).toLocaleTimeString(DATE_LOCALE, timeFormat).replace(',', '');
}

export function getDateTime(date: string, format?: Intl.DateTimeFormatOptions): string {
  return new Date(date).toLocaleString(DATE_LOCALE, format).replace(',', '');
}

export function getUserFullName({ name, surname }: User): string {
  return `${name} ${surname}`;
}

export function getObjectValueByKey<T, K extends keyof T>(obj: T, key: K): any {
  return obj[key];
}

export function getObjectKeyByValue<T extends Object>(obj: T, value: any): any {
  const index = Object.values(obj).indexOf(value);
  return index !== -1 ? Object.keys(obj)[index] : '';
}

export function isObjectEmpty(object: any): boolean {
  return (
    object !== undefined &&
    object !== null &&
    (Object.keys(object).length === 0 || Object.values(object).every((x) => x === null || x === '' || x === undefined))
  );
}

export function startsWithVowel(value: string): RegExpMatchArray | null {
  return value.match('^[aieouAIEOU].*');
}

export function wordPluralBasedOnAmount(amount: number, singular: string, plural: string): string {
  return amount > 1 || amount === 0 ? plural : singular;
}

export function i18nLabelParser(value: string): string {
  let values = value.split(DELIMITER);
  return values.reduce((acc, val) => acc.concat(i18n.exists(val) ? i18n.t(val) : val.concat(' ')), '');
}

export function getUserRoles(): string[] {
  return Object.values(UserRole).filter(
    (role) =>
      ![UserRole.OWNER, UserRole.SUPER_ADMIN].includes(role) ||
      (isUserRoleEqualsTo(UserRole.SUPER_ADMIN) && ![UserRole.OWNER].includes(role))
  );
}

export function getAssignableUserRoles(): string[] {
  const excludedRoles: UserRole[] = [UserRole.OWNER, UserRole.GUEST];
  if (isUserRoleEqualsTo(UserRole.ADMIN)) {
    excludedRoles.push(UserRole.SUPER_ADMIN);
  }
  return Object.values(UserRole).filter((role) => !excludedRoles.includes(role));
}

export function getImageSrcFromBase64(base64: string, fileType?: string) {
  return `data:${fileType ? fileType : 'image/png'};base64,${base64}`;
}

//
// Incident utils
//

export function getFormResultValue(formResults: FormResult[], key: string): string {
  const formResult = formResults.find((result) => result.key === key);
  return formResult ? IncidentAnswerFormatter[formResult.type](formResult.value) : '-';
}
export function getLatestFormResultValue(formResults: FormResult[], key: string): string {
  const formResult = EventUtils.getLatestFormResultByKey(formResults, key).maxItem;
  return formResult.key ? IncidentAnswerFormatter[formResult.type](formResult.value) : '-';
}

export function getIncidentReason(type: string) {
  switch (type) {
    case 'CONTACT_WITH':
      return CONTACT_WITH;
    case 'STRUCK_BY':
      return STRUCK_BY;
    case 'SLIPS_TRIPS_FALLS':
      return SLIPS_TRIPS_FALLS;
    case 'OTHER':
      return OTHER;
    default:
      return CONTACT_WITH;
  }
}

export const IncidentTrendIcon: Record<string, (isSecondaryEventType: boolean) => ReactNode> = Object.freeze({
  up: (isForSecondaryEventType: boolean) => (
    <CaretUpOutlined
      style={{ fontSize: '26px' }}
      className={isForSecondaryEventType ? 'text-success' : 'text-danger'}
    />
  ),
  down: (isForSecondaryEventType: boolean) => (
    <CaretDownOutlined
      style={{ fontSize: '26px' }}
      className={isForSecondaryEventType ? 'text-danger' : 'text-success'}
    />
  ),
  side: (_: boolean) => <CaretRightOutlined style={{ fontSize: '26px' }} className="text-secondary" />,
});

export const IncidentIcons: Record<string, any> = Object.freeze({
  [IncidentType.NEAR_HIT]: require('../assets/images/ic_nearhit.svg'),
  [IncidentType.INCIDENT]: require('../assets/images/ic_incident.svg'),
  [IncidentType.AUDIT]: require('../assets/images/ic_audit.svg'),
  [IncidentType.SAFETY_CONV]: require('../assets/images/ic_safety.svg'),
  [IncidentType.HOUSE_KEEPING]: require('../assets/images/ic_hire.svg'),
  [IncidentType.TBOX]: require('../assets/images/ic_tbox.svg'),
});

const IncidentAnswerFormatter: Record<string, Function> = Object.freeze({
  DATE: (value: string) => getDateTime(value),
  TEXT: (value: string) => value,
  BOOLEAN: (value: string) => (value === 'true' ? 'shared.yes' : 'shared.no'),
  OPTION: (value: string) => (value === 'true' ? 'shared.yes' : value === 'false' ? 'shared.no' : value),
  OPTION_DESCRIPTION: (value: string) => (value === 'true' ? 'shared.yes' : value === 'false' ? 'shared.no' : value),
});

export function getFormattedValue(type: string, value: string): string {
  return IncidentAnswerFormatter[type] ? IncidentAnswerFormatter[type](value) : formatTextToTitlecase(value);
}

export const getFullReasonOrCauseKeys = (
  data: string,
  map: Map<string, RadioGroupOption<IncidentReasonsType | IncidentCausesType>>,
  subMap: Map<string, string>
) => {
  let key: string | undefined, subKey: string | undefined;
  const splitData = data.split(DELIMITER);
  const label = splitData[0];
  const subLabel = splitData[2];
  map.forEach((object, objectKey) => {
    if (object.label === label) {
      key = objectKey;
    }
  });
  const entry = Array.from(subMap.entries()).find(([_, value]) => value === subLabel);
  if (entry) {
    subKey = entry[0];
  } else {
    // If subKey is of type textArea and not radio option
    subKey = subLabel;
  }
  return { key, subKey };
};

//
// Investigation utils
//

export const InvestigationQuestions: Record<string, string> = Object.freeze({
  incidentType: 'investigation.incidentType',
  areaSafe: 'investigation.areaSafe',
  insuranceCompanyInformed: 'investigation.insuranceCompanyInformed',
  activity: 'investigation.activity',
  howDidItHappen: 'investigation.howDidItHappen',
  riskAssessmentConducted: 'investigation.riskAssessmentConducted',
  riskOfHazardIdentified: 'investigation.riskOfHazardIdentified',
  hazardsAndRisks: 'investigation.hazardsAndRisks',
  similarHazardsAndRisksExist: 'investigation.similarHazardsAndRisksExist',
  victimsLengthInRole: 'investigation.victimsLengthInRole',
  victimsLengthOfEmployment: 'investigation.victimsLengthOfEmployment',
  victimHasWorkRelatedIssues: 'investigation.victimHasWorkRelatedIssues',
  drugsAndAlcoholInvolved: 'investigation.drugsAndAlcoholInvolved',
  unusualWorkConditions: 'investigation.unusualWorkConditions',
  organisationInfluencedTheEvent: 'investigation.organisationInfluencedTheEvent',
  workingAreaMaintained: 'investigation.workingAreaMaintained',
  werePeopleInvolvedCompetent: 'investigation.werePeopleInvolvedCompetent',
  peopleAuthorizedToUndertakeTask: 'investigation.peopleAuthorizedToUndertakeTask',
  workplaceLayoutContributeToEvent: 'investigation.workplaceLayoutContributeToEvent',
  materialsContributeToEvent: 'investigation.materialsContributeToEvent',
  plantContributeToEvent: 'investigation.plantContributeToEvent',
  safetyEquipmentContributeToEvent: 'investigation.safetyEquipmentContributeToEvent',
  otherInfluenceToEvent: 'investigation.otherInfluenceToEvent',
  finalReason: 'investigation.finalReason',
  primaryRootCause: 'investigation.primaryRootCause',
  secondaryRootCause: 'investigation.secondaryRootCause',
  incidentRequiresCommunicationToWiderAudience: 'investigation.incidentRequiresCommunicationToWiderAudience',
  riskMatrix: 'shared.riskMatrix.title',
});

//
// Review utils
//

export const ReviewEntryFormatter: Record<string, Function> = Object.freeze({
  reviewDate: (value: string) => new Date(value).toLocaleDateString(DATE_LOCALE),
  reviewBy: (user: User) => getUserFullName(user),
  execSummary: (value: string) => value,
  comment: (value: string) => value,
  riskType: (value: string) => value,
});

export const ReviewQuestions: Record<string, string> = Object.freeze({
  reviewDate: 'review.reviewDate',
  reviewBy: 'review.reviewBy',
  execSummary: 'review.execSummary',
  comment: 'review.comments',
  riskType: 'review.riskType',
});

//
// Action utils
//

export const ActionEntryFormatter: Record<string, Function> = Object.freeze({
  requiredAction: (value: string) => value,
  responsibleUserName: (value: string) => value,
  dueDate: (value: string) => new Date(value).toLocaleDateString(DATE_LOCALE),
  importance: (value: string) => ActionImportanceLabel[value],
  otherInformation: (value: string) => value,
});

export const ActionQuestions: Record<string, string> = Object.freeze({
  requiredAction: 'action.requiredAction',
  responsibleUserName: 'action.responsibleUserName',
  dueDate: 'action.dueDate',
  importance: 'action.importance',
  otherInformation: 'action.otherInformation',
});

export const ActionRegulatoryQuestionsLabel: Record<ActionRegulatoryQuestions, (t: TFunction) => string> =
  Object.freeze({
    [ActionRegulatoryQuestions.INVESTIGATED_AND_ACTED_UPON]: (t: TFunction) =>
      t(
        'action.regulatoryQuestions.investigatedAndActedUpon',
        'HAS THIS OCCURRENCE BEEN SUCCESSFULLY INVESTIGATED AND ACTED UPON?'
      ),
    [ActionRegulatoryQuestions.PREVENTIVE_ACTIONS_EFFICENT]: (t: TFunction) =>
      t('action.regulatoryQuestions.preventiveActionsEfficient', 'ARE THE PREVENTIVE ACTIONS EFFICIENT?'),
    [ActionRegulatoryQuestions.ADDITIONAL_RISKS]: (t: TFunction) =>
      t('action.regulatoryQuestions.additionalRisks', 'PREVENTIVE ACTION/S DO NOT CAUSE ADDITIONAL RISK/S?'),
    [ActionRegulatoryQuestions.CLASSIFICATION]: (t: TFunction) =>
      t('action.regulatoryQuestions.classification', 'ACTIONS CLASSIFICATION'),
    [ActionRegulatoryQuestions.RISK_MATRIX]: (t: TFunction) => t('shared.riskMatrix.title', 'RISK MATRIX'),
  });

export const YesNoOptionLabel: Record<YesNoOption, (t: TFunction) => string> = Object.freeze({
  [YesNoOption.YES]: (t: TFunction) => t('shared.yes', 'Yes'),
  [YesNoOption.NO]: (t: TFunction) => t('shared.no', 'No'),
});

export const ActionClassificationLabel: Record<ActionClassification, (t: TFunction) => string> = Object.freeze({
  [ActionClassification.PERSONAL_PROTECTIVE_EQUIPMENT]: (t: TFunction) =>
    t('action.regulatoryQuestions.personalProtectiveEquipment', 'Personal Protective Equipment (PPE)'),
  [ActionClassification.ADMINISTRATIVE_CONTROL]: (t: TFunction) =>
    t('action.regulatoryQuestions.administrativeControl', 'Administrative control (signs, warnings, procedures)'),
  [ActionClassification.ENGINEER_CONTROL]: (t: TFunction) =>
    t('action.regulatoryQuestions.engineerControl', 'Engineer control (technical actions)'),
  [ActionClassification.SUBSTITUTION]: (t: TFunction) =>
    t('action.regulatoryQuestions.substitution', 'Substitution (change of method, process)'),
  [ActionClassification.ELIMINATION]: (t: TFunction) =>
    t('action.regulatoryQuestions.elimination', 'Elimination (hazard eliminated)'),
});

//
// Upload utils
//

function getMediaInfoContent() {
  const supportedTypesContent = ALLOWED_EXTENSTIONS.reduce((acc, value) => acc.concat(' ').concat(value), '');
  return (
    <Row gutter={[5, 0]}>
      <Col span={16}>
        <HasText content={i18n.t('attachment.supportedTypes')} strong style={{ fontSize: '12px' }} />
        <HasText content={supportedTypesContent} className="text-break" style={{ fontSize: '12px' }} />
      </Col>
      <Col span={8} style={{ display: 'grid' }}>
        <HasText content={i18n.t('attachment.sizeRestrictions')} strong style={{ fontSize: '12px' }} />
        <HasText
          content={i18n.t('attachment.videoMaxSize', { maxMB: VIDEO_FILE_LIMIT.size })}
          className="text-break"
          style={{ fontSize: '12px' }}
        />
        <HasText
          content={i18n.t('attachment.otherMaxSize', { maxMB: REGULAR_FILE_LIMIT.size })}
          className="text-break"
          style={{ fontSize: '12px' }}
        />
      </Col>
    </Row>
  );
}

export function getMediaInfo() {
  return (
    <div style={{ paddingTop: '10px' }}>
      <HasCollapse
        bordered={false}
        expandIcon={(panelProps: any) =>
          panelProps.isActive ? (
            <InfoCircleFilled style={{ fontSize: '18px' }} />
          ) : (
            <InfoCircleOutlined style={{ fontSize: '18px' }} />
          )
        }
        className="has-collapse"
        panelConfigs={[
          {
            key: 'media_info_panel',
            header: <HasText content={i18n.t('attachment.uploadRestrictions')} strong />,
            content: getMediaInfoContent(),
            className: 'has-collapse has-collapse-panel',
          },
        ]}
      />
    </div>
  );
}

export function base64ToFile(base64Data: any, fileName: string, contentType: string): File {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new File(byteArrays, fileName, { type: contentType });
}

export function checkFileSize(file: File): boolean {
  let limitSizeInBytes: number;
  if (ALLOWED_FILE_TYPES.Video.includes(file.type)) {
    limitSizeInBytes = VIDEO_FILE_LIMIT.size * 1024 ** VIDEO_FILE_LIMIT.unit;
  } else {
    limitSizeInBytes = REGULAR_FILE_LIMIT.size * 1024 ** REGULAR_FILE_LIMIT.unit;
  }
  return file.size <= limitSizeInBytes;
}

export function checkFileType(fileType: string): boolean {
  return Object.values(ALLOWED_FILE_TYPES).some((typeArray) => typeArray.includes(fileType));
}

export function getFileSizeError(file: File) {
  return ErrorMessages.ATTACHMENT_FILE_SIZE(
    ALLOWED_FILE_TYPES.Video.includes(file.type) ? VIDEO_FILE_LIMIT.size : REGULAR_FILE_LIMIT.size
  );
}

export function getFileIconByType(fileType: string, style: CSSProperties): ReactNode {
  switch (true) {
    case ALLOWED_FILE_TYPES.Document.includes(fileType):
      return <FileTextTwoTone style={{ ...style }} />;
    case ALLOWED_FILE_TYPES.Archive.includes(fileType):
      return <FileZipTwoTone style={{ ...style }} />;
    case ALLOWED_FILE_TYPES.Image.includes(fileType):
    case ALLOWED_FILE_TYPES.Video.includes(fileType):
      return <FileImageTwoTone style={{ ...style }} />;
    case ALLOWED_FILE_TYPES.Audio.includes(fileType):
    default:
      return 'file';
  }
}

// Session utils

export function hasPermission(): boolean {
  const userRole = getUserRole();
  if (userRole) {
    return RW_ROLES.includes(userRole as UserRole);
  }
  return false;
}

export function isLimitedEmployeePersonal(): boolean {
  return getUserRole() === UserRole.LIMITED_EMPLOYEE_PERSONAL;
}

export function hasPermissionType(permissionType: string, permissions?: Permission[]): boolean {
  return permissions ? permissions.some((permission) => permission.permissionType === permissionType) : true;
}

// Local utils

export function updateCompaniesInLocalStorage(
  selectedCompanies: Company[],
  selectedDivisions: Division[],
  currentFiler: CompanyFilterHeader,
  currentUserId?: string | number
) {
  const companyIdsByDivisions = selectedDivisions.map((division) => division.companyId);
  currentFiler.companies = selectedCompanies
    .filter((company) => companyIdsByDivisions.indexOf(company.id) > -1)
    .map<CompanyFilterHeaderContent>((company) => ({
      cId: company.id,
      dIds: selectedDivisions
        .filter((division) => division.companyId === company.id && !division.deleted)
        .map((division) => division.id),
    }));
  localStorage.setItem(COMPANY_FILTER, JSON.stringify({ ...currentFiler, userId: currentUserId }));
}

//
// Table utils
//

export function comparatorFn<T>(a: T | undefined, b: T | undefined) {
  if (isEqual(a, b)) {
    return 0;
  } else if (gt(a, b)) {
    return 1;
  } else {
    return -1;
  }
}

export function catchClickEvent(clickEvent: Event | React.MouseEvent) {
  clickEvent.preventDefault();
  clickEvent.stopPropagation();
}

export function getDatesFromInterval(interval: string) {
  switch (interval) {
    case TimeIntervals.TODAY:
      return {
        startDate: moment().subtract(1, 'day').toISOString(),
        endDate: moment().toISOString(),
      };
    case TimeIntervals.LAST_7_DAYS:
      return {
        startDate: moment().subtract(7, 'days').toISOString(),
        endDate: moment().toISOString(),
      };
    case TimeIntervals.THIS_MONTH:
      return {
        startDate: moment().subtract(1, 'month').toISOString(),
        endDate: moment().toISOString(),
      };
    case TimeIntervals.LAST_6_MONTH:
      return {
        startDate: moment().subtract(6, 'months').toISOString(),
        endDate: moment().toISOString(),
      };
    case TimeIntervals.THIS_YEAR:
      return {
        startDate: moment().subtract(1, 'year').toISOString(),
        endDate: moment().toISOString(),
      };
    case TimeIntervals.ALL_TIME:
      return {
        startDate: moment().subtract(10, 'year').toISOString(),
        endDate: moment().toISOString(),
      };
  }
}

export function escapeRegExp(mapping: string) {
  return mapping.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

export function hidePhoneNumber(recievedNumber: string) {
  let lengthNumber = recievedNumber.length - 3;
  let numberToReplace = recievedNumber.substr(0, lengthNumber);
  const hiddenPhoneNumber =
    numberToReplace.replace(new RegExp('[0-9]', 'g'), '*') + recievedNumber.substr(lengthNumber);
  return (
    <Tooltip title={i18n.t('phoneNumber.tooltip')}>
      <HasText content={hiddenPhoneNumber}></HasText>
    </Tooltip>
  );
}
