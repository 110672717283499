import { LeftOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Collapse, Popconfirm } from 'antd';
import { isUndefined } from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  HasActionComplianceForm,
  HasActionForm,
  HasActionsList,
  HasButton,
  HasStatusToggle,
  HasText,
  Notification,
} from '../../components';
import { PermissionsContext } from '../../context';
import i18n from '../../i18n/config';
import {
  Action,
  ActionForm,
  ActionFormKey,
  ActionSourceType,
  ActionStatus,
  Incident,
  RiskLevel,
  RiskLevelLabel,
  User,
} from '../../models';
import { ActionEditService, ActionService, EntityAction, UserService } from '../../services';
import { COLORS, ErrorMessages } from '../../shared';
import {
  getActionPermissions,
  getActionStatusText,
  getNewActionHistory,
  getQueryParamValue,
  getUserId,
  isDateOverdueToday,
  isLoggedInUserCreatorOrSuperAdminOrOwner,
} from '../../utils';

interface ActionDetailsProps {
  incident: Incident;
  actions: Action[];
  users: User[];
  loading: boolean;
  queryParam?: string;
}

interface ActionDetailsState {
  editMode: boolean;
  isCreate: boolean;
  selectedAction?: Action;
  formInterface: Readonly<ActionForm>;
  currentUser?: User;
  riskColor?: string;
  collapseKey?: string | string[];
}

class HasActionDetails extends React.PureComponent<ActionDetailsProps & RouteComponentProps, ActionDetailsState> {
  state: ActionDetailsState = {
    editMode: false,
    isCreate: false,
    formInterface: {},
  };

  componentDidMount() {
    const { queryParam } = this.props;
    const { setPermissionsForUser } = this.context;
    setPermissionsForUser();
    if (queryParam) {
      this.getActionByQueryParams(Number(queryParam));
    }
  }

  componentDidUpdate(prevProps: Readonly<ActionDetailsProps & RouteComponentProps>, prevState: ActionDetailsState) {
    if (prevProps.location.search !== this.props.location.search) {
      const {
        location: { search: queryParam },
      } = this.props;
      if (queryParam) {
        const id = getQueryParamValue(queryParam);
        this.getActionByQueryParams(Number(id));
      } else if (queryParam === '' || !queryParam) {
        this.props.history.push(`${this.props.incident.id}`);
        this.setState({ selectedAction: undefined, editMode: false });
      }
    }
  }

  getCurrentUser = async () => {
    const { data: user } = await UserService.getMyUser();
    this.setState({
      currentUser: user,
    });
  };

  getActionByQueryParams = (actionId: number) => {
    const selectedAction = this.props.actions.find((action) => action.id === actionId);
    if (selectedAction) {
      this.setState({
        selectedAction: selectedAction,
        collapseKey:
          selectedAction.status !== ActionStatus.CLOSED && !selectedAction.complianceFormCompleted
            ? ''
            : 'regulatoryQuestions',
      });
      this.getCurrentUser();
    } else {
      this.props.history.push(`${this.props.incident.id}`);
      Notification.error(ErrorMessages.ACTION_NOT_FOUND);
      this.setState({ selectedAction: undefined });
    }
  };

  actionSelected = (action: Action) => {
    if (!this.state.editMode) {
      this.props.history.push(`?id=${action.id}`);
      this.setState({ selectedAction: action });
    } else {
      Notification.error(ErrorMessages.ACTION_UNSAVED_CHANGES(this.state.isCreate));
    }
  };

  actionStatusChanged = (checked: boolean) => {
    const { selectedAction } = this.state;
    if (!isUndefined(selectedAction)) {
      selectedAction.status = checked ? ActionStatus.OPEN : ActionStatus.CLOSED;
      ActionService.actionChanged(EntityAction.CHANGE_STATUS, selectedAction);
      this.setState({ selectedAction: selectedAction, collapseKey: '' });
    }
  };

  actionFieldChanged = (field: ActionFormKey, value: any) => {
    this.setState(({ formInterface }) => {
      ActionEditService.fieldChanged(formInterface, field, value);
      return { formInterface };
    });
  };

  addNewAction = () => {
    const { incident } = this.props;
    if (!this.state.editMode) {
      const selectedAction: Action = {
        incidentId: incident.id,
        companyId: incident.company.id,
        company: incident.company.name,
        division: incident.division.name,
        history: getNewActionHistory(),
        sourceType: ActionSourceType.EVENT,
      };
      const formInterface: Readonly<ActionForm> = ActionEditService.getFormInterface(selectedAction);
      this.setState({
        selectedAction,
        formInterface,
        isCreate: true,
        editMode: true,
      });
    } else {
      Notification.error(ErrorMessages.ACTION_UNSAVED_CHANGES(this.state.isCreate));
    }
  };

  editAction = () => {
    const formInterface: Readonly<ActionForm> = ActionEditService.getFormInterface(this.state.selectedAction!);
    this.setState({ editMode: true, formInterface });
  };

  deleteAction = () => {
    const { selectedAction } = this.state;
    if (!isUndefined(selectedAction)) {
      ActionService.actionChanged(EntityAction.DELETE, selectedAction);
      this.setState({ selectedAction: undefined });
    }
  };

  saveAction = () => {
    const { selectedAction, isCreate, formInterface } = this.state;
    if (!isUndefined(selectedAction) && ActionEditService.isValid(formInterface)) {
      const updatedAction: Action = ActionEditService.applyInterfaceAndReturn(formInterface, selectedAction);
      if (isCreate) {
        updatedAction.createdByUserId = +getUserId()!;
        ActionService.actionChanged(EntityAction.CREATE, updatedAction);
      } else {
        ActionService.actionChanged(EntityAction.UPDATE, updatedAction);
      }
      this.setState({ editMode: false, isCreate: false });
      this.navigateToActionsList();
    } else {
      Notification.error(ErrorMessages.ACTION_INVALID_FORM);
    }
  };

  cancel = () => {
    const { selectedAction } = this.state;
    let originalAction: Action | undefined = undefined;
    if (selectedAction?.id) {
      originalAction = selectedAction;
    }
    this.setState({ editMode: false, isCreate: false, selectedAction: originalAction });
  };

  navigateToActionsList = () => {
    this.props.history.push(this.props.incident.id.toString());
    this.setState({ selectedAction: undefined });
  };

  disableComplianceForm = () => {
    const { selectedAction, currentUser } = this.state;
    if (selectedAction && !selectedAction.complianceFormCompleted) {
      if (
        !isLoggedInUserCreatorOrSuperAdminOrOwner(selectedAction, currentUser) ||
        selectedAction?.status !== ActionStatus.CLOSED
      ) {
        return true;
      }
    }
    return false;
  };

  getBanner = (): JSX.Element[] => {
    let banner: JSX.Element[] = [];
    const { selectedAction, isCreate, currentUser, riskColor } = this.state;

    const getAlertMessage = () => {
      if (selectedAction?.status !== ActionStatus.CLOSED && !selectedAction?.complianceFormCompleted) {
        return i18n.t(
          'action.regulatoryQuestion.emptyState',
          'Regulatory questions will be available after the action is closed.'
        );
      } else {
        return selectedAction?.complianceFormCompleted
          ? i18n.t('action.regulatoryQuestion.completed', 'Regulatory questions completed.')
          : i18n.t('action.regulatoryQuestion.notCompletedYet', 'Regulatory questions not completed yet.');
      }
    };

    if (!isCreate && !isLoggedInUserCreatorOrSuperAdminOrOwner(selectedAction, currentUser)) {
      banner.push(
        <Alert
          key={'regulatoryQuestions'}
          message={
            <div className="d-flex-inline align-items-center p-1">
              <WarningOutlined className="align-middle mr-2 mb-1" style={{ color: COLORS.GRAY_DARK, fontSize: 14 }} />
              <HasText content={getAlertMessage()} />
            </div>
          }
          type="info"
          closable
          className="mb-2"
        />
      );
    }

    if (selectedAction?.complianceFormCompleted && selectedAction?.riskScore && selectedAction?.riskLevel) {
      banner.push(
        <Alert
          key={'riskMatrix'}
          message={
            <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
              <HasText
                content={
                  <div>
                    <HasText
                      content={i18n.t('shared.riskMatrix.scoreMessage', `This event's event has a risk score of`)}
                    />
                    <HasText
                      style={{ color: riskColor, fontWeight: 600 }}
                      content={`${' ' + selectedAction.riskScore}`}
                    />
                    <span>{', '}</span>
                    <HasText content={i18n.t('shared.riskMatrix.level', 'level')} />
                    <HasText
                      style={{ color: riskColor, fontWeight: 600 }}
                      content={`${' ' + RiskLevelLabel?.[selectedAction.riskLevel as RiskLevel]?.(i18n.t.bind(i18n))}`}
                    />
                    <span>{'. '}</span>
                    <HasText content={i18n.t('shared.riskMatrix.infoMessage', 'See the Risk Matrix entry below.')} />
                  </div>
                }
              />
            </div>
          }
          type="info"
          closable
          className="mb-2"
        />
      );
    }
    if (banner.length > 0) {
      return banner;
    } else {
      return [];
    }
  };

  handleRiskColor = (riskColor: string) => {
    this.setState({ riskColor });
  };

  render() {
    const { actions, loading } = this.props;
    const { selectedAction, editMode, isCreate, currentUser, collapseKey } = this.state;
    const { permissions: userPermissions } = this.context;
    const permissions = getActionPermissions(selectedAction, userPermissions);
    return (
      <>
        {isUndefined(selectedAction) && !editMode && !isCreate ? (
          <>
            <div className="d-flex flex-row justify-content-between align-items-end mb-2">
              <div className={'ant-descriptions-title'}>{i18n.t('shared.actions').toUpperCase()}</div>
              {permissions.create && (
                <HasButton type="primary" onClick={this.addNewAction}>
                  {i18n.t('incidents.addAction')}
                </HasButton>
              )}
            </div>
            <HasActionsList actions={actions} loading={loading} actionSelected={this.actionSelected} />
          </>
        ) : (
          <>
            {!editMode && !isCreate && (
              <HasButton
                type="link"
                shape="round"
                onClick={this.navigateToActionsList}
                style={{ padding: '0 5px 0 0', marginBottom: '15px', alignSelf: 'flex-start' }}
              >
                <LeftOutlined
                  style={{ color: COLORS.GRAY_BLACK, verticalAlign: 'middle', marginBottom: '3px', fontSize: '18px' }}
                />
                <HasText content={i18n.t('shared.actions').toUpperCase()} strong style={{ color: COLORS.GRAY_BLACK }} />
              </HasButton>
            )}
            {this.getBanner()}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="ant-descriptions-title">
                {isCreate ? i18n.t('incidents.addAction').toUpperCase() : i18n.t('incidents.actionDetails')}
              </div>
              {!isCreate && selectedAction && (
                <div>
                  {permissions.changeStatus ? (
                    <HasStatusToggle
                      isChecked={selectedAction?.status === ActionStatus.OPEN}
                      onChange={this.actionStatusChanged}
                      leftLabel={i18n.t('incidents.closed')}
                      rightLabel={i18n.t('incidents.open')}
                    />
                  ) : (
                    <span>{getActionStatusText(selectedAction)}</span>
                  )}
                </div>
              )}
            </div>
            <HasActionForm
              action={this.state.selectedAction!}
              users={this.props.users}
              editMode={editMode}
              isCreate={isCreate}
              fieldChangedCallback={this.actionFieldChanged}
            />
            {!isCreate && (
              <Collapse
                activeKey={collapseKey}
                onChange={(key) => this.setState({ collapseKey: key })}
                bordered={false}
                className="bg-transparent"
              >
                <Collapse.Panel
                  disabled={this.disableComplianceForm()}
                  className="border"
                  header={i18n.t('regulatoryQuestions.title', 'Regulatory questions')}
                  key="regulatoryQuestions"
                >
                  <HasActionComplianceForm
                    action={selectedAction!}
                    currentUser={currentUser}
                    handleRiskColor={this.handleRiskColor}
                  />
                </Collapse.Panel>
              </Collapse>
            )}
            {(editMode || permissions.edit || permissions.delete) && (
              <div style={{ marginTop: '16px', alignSelf: 'flex-end' }}>
                {editMode ? (
                  <>
                    <HasButton type="default" onClick={this.cancel} style={{ marginRight: '8px' }}>
                      {i18n.t('shared.cancel')}
                    </HasButton>
                    <HasButton type="primary" onClick={this.saveAction}>
                      {i18n.t('shared.save')}
                    </HasButton>
                  </>
                ) : (
                  <>
                    {permissions.edit && (
                      <HasButton
                        type="primary"
                        onClick={this.editAction}
                        style={{ marginRight: '8px' }}
                        disabled={selectedAction?.status === ActionStatus.CLOSED}
                      >
                        {i18n.t('shared.edit')}
                      </HasButton>
                    )}
                    {permissions.delete && (
                      <Popconfirm
                        title={i18n.t('action.deleteTeamConfirm', 'Are you sure you want to delete this action?')}
                        okText={i18n.t('shared.yes', 'Yes')}
                        cancelText={i18n.t('shared.no', 'No')}
                        onConfirm={this.deleteAction}
                        placement="bottom"
                        style={{ maxWidth: '200px' }}
                      >
                        <HasButton type="danger">{i18n.t('shared.delete')}</HasButton>
                      </Popconfirm>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

HasActionDetails.contextType = PermissionsContext;

export default HasActionDetails;
