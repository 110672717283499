import { Divider } from 'antd';
import React from 'react';
import { HasButton, HasCheckbox, HasText, Notification, Toast } from '../../components';
import i18n from '../../i18n/config';
import { IncidentType, IncidentTypeLabel } from '../../models';
import { IncidentService } from '../../services';
import { ErrorMessages, MAIN_REPORT_TYPES, PDF_EXPORT } from '../../shared';

interface EventPdfExportProps {
  id: string;
  eventType: IncidentType;
  hasActions: boolean;
  hasOpenInvestigation: boolean;
  hasPhotos: boolean;
}

interface EventPdfExportState {
  checkBoxList: Map<PDF_EXPORT, CheckBoxList>;
  shouldTranslate?: boolean;
}

type CheckBoxList = { title: string; checked: boolean; disabled?: boolean };

class HasEventPdfExport extends React.Component<EventPdfExportProps, EventPdfExportState> {
  state: EventPdfExportState = {
    checkBoxList: new Map([
      [PDF_EXPORT.REPORT, { title: 'pdfExport.includeReport', checked: true }],
      [
        PDF_EXPORT.INVESTIGATION,
        { title: 'pdfExport.includeInvestigation', checked: false, disabled: !this.props.hasOpenInvestigation },
      ],
      [PDF_EXPORT.REVIEW, { title: 'pdfExport.includeReview', checked: false }],
      [PDF_EXPORT.ACTIONS, { title: 'pdfExport.includeActions', checked: false, disabled: !this.props.hasActions }],
      [PDF_EXPORT.IMAGES, { title: 'pdfExport.includeImages', checked: false, disabled: !this.props.hasPhotos }],
    ]),
    shouldTranslate: false,
  };

  componentDidMount() {
    this.setState((prevState) => {
      const list = prevState.checkBoxList;
      list.delete(this.showInvestigationBox() ? PDF_EXPORT.REVIEW : PDF_EXPORT.INVESTIGATION);
    });
  }

  generateCheckbox = (key: PDF_EXPORT, entry: CheckBoxList): JSX.Element => (
    <HasCheckbox
      key={key}
      content={i18n.t(entry.title)}
      disabled={entry.disabled}
      checked={entry.checked}
      onChange={(event) => this.toggleExportElement(event, key)}
      className="d-block ml-2"
    />
  );

  getCheckboxes = () => {
    const { checkBoxList } = this.state;
    let nodes: JSX.Element[] = [];
    checkBoxList.forEach((entry, key) => {
      nodes.push(this.generateCheckbox(key, entry));
    });
    return <div className="mt-2">{nodes}</div>;
  };

  toggleExportElement = (event: any, key: PDF_EXPORT) => {
    const { checkBoxList } = this.state;
    if (
      Array.from(checkBoxList)
        .filter((value) => value[0] !== key)
        .every((value) => !value[1].checked)
    ) {
      Toast.error(i18n.t('errors.atLeastOneSelected', { entityName: i18n.t('shared.option').toLowerCase() }));
    } else {
      this.setState((prevState) => {
        const list = prevState.checkBoxList;
        let entry = list.get(key);
        if (entry) {
          list.set(key, { ...entry, checked: event.target.checked });
        }
        return { ...prevState, checkBoxList: list };
      });
    }
  };

  generatePdf = () => {
    const { id, eventType } = this.props;
    const { checkBoxList, shouldTranslate } = this.state;
    let toInclude: any[] = [];
    checkBoxList.forEach((value, key) => value.checked && toInclude.push(PDF_EXPORT[key]));
    IncidentService.exportPdf(id, toInclude, shouldTranslate ? i18n.language : undefined)
      .then(
        (response) => {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `${i18n.t(IncidentTypeLabel[eventType])} #${id}.pdf`);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(link);
        },
        (error) => Notification.error(ErrorMessages.PDF_FAIL(error))
      )
      .catch((error) => Notification.error(ErrorMessages.PDF_FAIL(error)));
  };

  showInvestigationBox = (): boolean => MAIN_REPORT_TYPES.includes(this.props.eventType);

  render() {
    return (
      <div className="d-flex flex-column flex-grow-1 flex-shrink-0">
        <div className="ant-descriptions-title">{i18n.t('pdfExport.title').toUpperCase()}</div>
        <div>
          <HasText content={i18n.t('pdfExport.exportOptions')} strong />
          {this.getCheckboxes()}
          <Divider style={{ margin: '16px 0 8px 0' }} />
          <HasCheckbox
            key={'pdfExportTranslate'}
            content={i18n.t('pdfExport.shouldTranslate')}
            checked={this.state.shouldTranslate}
            onChange={(event) =>
              this.setState((prevState) => ({ ...prevState, shouldTranslate: event.target.checked }))
            }
            className="d-block ml-2"
          />
          <HasButton type="primary" onClick={() => this.generatePdf()} className="mt-2">
            {i18n.t('pdfExport.export')}
          </HasButton>
        </div>
      </div>
    );
  }
}

export default HasEventPdfExport;
