import { HighlighterProps } from 'react-highlight-words';
import { COLORS } from '../shared';

export const TABLE_ITEM_HEIGHT = 55;
export const TABLE_HEADER_HEIGHT = 55;
export const TABLE_PAGINATION_HEIGHT = 32 + 16 * 2;
export const TABLE_PAGE_SIZE = 10;

export enum ACTION_KEYS {
  ID = 'id',
  INCIDENT = 'incident',
  STATUS = 'status',
  IMPORTANCE = 'importance',
  CREATED_ON = 'createdOn',
  CREATED_BY = 'createdByUserName',
  DUE_DATE = 'dueDate',
  COMPANY = 'company',
  DIVISON = 'division',
  COMPANY_DIVISION = 'companyAndDivision',
  REQUIRED_ACTION = 'requiredAction',
  RESPONSIBLE_USER = 'responsibleUserName',
  SOURCE_TYPE = 'sourceType',
  RISK_SCORE = 'riskScore',
  COMPLIANCE_FORM_STATUS = 'complianceFormStatus',
}

export function getHighlighterProps(text?: string, searchTerm?: string): HighlighterProps {
  return {
    highlightStyle: { backgroundColor: COLORS.HIGHLIGHTER, padding: 0 },
    searchWords: [searchTerm ? searchTerm : ''],
    autoEscape: true,
    textToHighlight: text ? text : '',
  };
}
