import { EditOutlined, GlobalOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons';
import React, { CSSProperties, ReactNode } from 'react';
import {
  LogEntry,
  PermissionType,
  RiskImpact,
  RiskImpactLabel,
  RiskLevel,
  RiskLevelLabel,
  RiskProbability,
  RiskProbabilityLabel,
} from '../../../models';
import { CollapsePanelWithContent, COLORS, DELIMITER, RISK_LEVEL_COLOR, RISK_SCORE_COLOR } from '../../../shared';
import { EventUtils, getDate, getTime, hasPermissionType, i18nLabelParser } from '../../../utils';
import { HasCollapse, HasParagraph, HasTag, HasText, HasTitle } from '../../atoms';
import i18n from '../../../i18n/config';
import { isEqual } from 'lodash';
import { TranslationService } from '../../../services';
import { Tooltip } from 'antd';
import { PermissionsContext } from '../../../context';

interface InvestigationFormEntriesProps {
  entries: LogEntry[];
  addEntryComponent: any;
  editToggleCallback: any;
}

interface InvestigationFormEntriesState {
  editMode: boolean;
  showAll: boolean;
  translationResult: { [k: string]: string };
  resultIdsTranslated: string[];
}

class HasInvestigationFormEntries extends React.Component<
  InvestigationFormEntriesProps,
  InvestigationFormEntriesState
> {
  answersToNotShowTranslateButton = [
    'victimsLengthInRole',
    'victimsLengthOfEmployment',
    'victimHasWorkRelatedIssues',
    'primaryRootCause',
    'secondaryRootCause',
    'incidentType',
    'riskOfHazardIdentified',
  ];

  state: InvestigationFormEntriesState = {
    editMode: this.props.entries.length === 0,
    showAll: false,
    translationResult: {},
    resultIdsTranslated: [],
  };

  shouldComponentUpdate(nextProps: Readonly<InvestigationFormEntriesProps>, nextState: InvestigationFormEntriesState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  getEditIcon = (): ReactNode => (
    <EditOutlined
      className={!this.state.editMode ? 'investigation-entry-edit' : 'investigation-entry-edit active'}
      style={{ cursor: 'pointer', float: 'right', top: '-22px', position: 'relative' }}
      onClick={this.toggleEditMode}
    />
  );

  toggleEditMode = () => {
    if (this.props.editToggleCallback(this.state.editMode)) {
      this.setState({ editMode: !this.state.editMode });
    }
  };

  closeEditMode = () => this.setState({ editMode: false });

  parseAnswer = (entry: LogEntry, style: CSSProperties): string | ReactNode => {
    const value = entry.value;
    if (!value) {
      return '';
    }
    try {
      // For now, only the answer for a specific question is saved as JSON, and its structure is known
      // 		answer: string
      // 		equipment: string
      // 		check: string
      // 		records: string
      // 		defects: string$$$extra values
      // If any other answers will be saved as JSONs this method has to be changed/generified as this is just a quick fix
      const data = JSON.parse(value);
      if (data.plant) {
        const defectsExtra = data.defects.split(DELIMITER);
        const mainAnswer = <HasText content={i18n.t(data.answer)} strong style={style} />;
        entry.extra = defectsExtra && defectsExtra[1];
        return (
          <React.Fragment>
            {mainAnswer}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                marginTop: '8px',
                paddingLeft: '20px',
              }}
            >
              <HasText content={`${i18n.t('investigation.form.equipment')}: ${i18n.t(data.equipment)}`} />
              <HasText content={`${i18n.t('investigation.form.preStartCheck')}: ${i18n.t(data.check)}`} />
              <HasText content={`${i18n.t('investigation.form.records')}: ${i18n.t(data.records)}`} />
              <HasText content={`${i18n.t('investigation.form.defects')}: ${i18n.t(defectsExtra[0])}`} />
              {entry.extra && (
                <HasText
                  content={this.state.translationResult[entry.extra + entry.seqNo] || entry.extra}
                  style={{ paddingLeft: '20px' }}
                />
              )}
            </div>
          </React.Fragment>
        );
      }
      // New specific question added that is also saved as JSON (risk matrix)
      //    impactValue: string
      // 		probabilityValue: string
      // 		riskScore: number
      // 		riskLevel: string
      if (data.riskScore) {
        const { impactValue, probabilityValue, riskScore, riskLevel } = data;

        const impactIndex = EventUtils.getRiskImpactIndex(impactValue);
        const probabilityIndex = EventUtils.getRiskProbabilityIndex(probabilityValue);
        const { riskColor } = EventUtils.getRiskMatrixValues(impactIndex!, probabilityIndex!);

        return (
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <HasText
                content={`${i18n.t('shared.impact')}: ${i18n.t(
                  RiskImpactLabel[impactValue as RiskImpact]
                )} (${impactIndex})`}
              />
              <HasText
                content={`${i18n.t('shared.probability')}: ${i18n.t(
                  RiskProbabilityLabel[probabilityValue as RiskProbability] as any
                )} (${probabilityIndex})`}
              />
              <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
                <HasText content={`${i18n.t('shared.riskScore')}: `} />
                <Tooltip title={RiskLevelLabel?.[riskLevel as RiskLevel]?.(i18n.t.bind(i18n))}>
                  <div style={{ color: riskColor, fontWeight: 600 }}>{riskScore}</div>
                </Tooltip>
              </div>
            </div>
          </React.Fragment>
        );
      }
    } catch {
      let valueToShow = this.state.translationResult[entry.value! + entry.seqNo]
        ? this.state.translationResult[entry.value! + entry.seqNo]
        : entry.value;
      return i18nLabelParser(valueToShow || '');
    }
  };

  handleTranslationForEntry = (entry: LogEntry) => {
    if (entry.extra) {
      entry = { ...entry, value: entry.extra };
    }
    if (entry && entry.value) {
      if (this.state.resultIdsTranslated.includes(entry.value + entry.seqNo)) {
        this.setState((previousState) => {
          delete previousState.translationResult[entry.value! + entry.seqNo];
          return {
            ...previousState,
            resultIdsTranslated: previousState.resultIdsTranslated.filter(
              (existingId) => existingId !== entry.value! + entry.seqNo
            ),
          };
        });
      } else {
        let translation = '';
        let textToTranslateParts = entry.value.split(DELIMITER).join(' ').split(' ');
        let textToTranslate = '';

        ///Check if all the parts are parseable
        let allPartsTranslateable = true;
        textToTranslateParts.forEach((part) => {
          if (!i18n.exists(part) && part !== ',') {
            allPartsTranslateable = false;
          }
        });

        if (allPartsTranslateable) {
          textToTranslate = i18nLabelParser(entry.value);
        } else {
          /// Check if first part is parseable
          if (textToTranslateParts.length > 0 && i18n.exists(textToTranslateParts[0])) {
            translation += i18nLabelParser(textToTranslateParts[0]);
            for (let index = 1; index < textToTranslateParts.length; index++) {
              textToTranslate += textToTranslateParts[index] + ' ';
            }
          } else {
            textToTranslate = i18nLabelParser(entry.value);
          }
        }

        TranslationService.translate({ textToTranslate: textToTranslate }).then((result) => {
          translation += result.data.translatedText!;

          this.setState((previousState) => {
            previousState.resultIdsTranslated.push(entry.value! + entry.seqNo);

            return {
              ...previousState,
              translationResult: { ...previousState.translationResult, [entry.value! + entry.seqNo]: translation },
            };
          });
        });
      }
    }
  };

  getEntryValueNode = (entry: LogEntry, latest?: boolean): ReactNode => {
    const latestStyle: CSSProperties = latest ? { color: COLORS.PRIMARY_BLUE, fontWeight: 600, marginTop: '5px' } : {};
    return (
      <HasParagraph
        content={this.parseAnswer(entry, { fontSize: '12px', marginBottom: '14px', fontWeight: 500, ...latestStyle })}
        className="text-break"
        style={{ fontSize: '12px', marginBottom: '14px', fontWeight: 500, ...latestStyle }}
      />
    );
  };

  buildEntry = (
    entry: LogEntry,
    index: number,
    latest?: boolean,
    showEdit?: boolean,
    translateOptions?: {
      onShowTranslation?: (entryValue: LogEntry) => void;
      translationResult: { [k: string]: string };
      resultIdsTranslated: string[];
    }
  ): ReactNode => {
    const date = getDate(entry.date);
    const time = getTime(entry.date);

    let value = this.getEntryValueNode(entry, latest);

    const content = (
      <div style={{ fontSize: '12px', color: COLORS.GRAY }}>
        <b>{entry.userName}</b> on <b>{date}</b> at <b>{time}</b>
      </div>
    );

    let showTranslateButton = !i18n.exists(entry.value || '');

    return (
      <div key={index}>
        <HasParagraph content={content} style={{ color: COLORS.PRIMARY_BLUE, margin: 0 }} />
        {!this.answersToNotShowTranslateButton.includes(entry.fieldKey!) && (
          <div style={{ display: 'grid', gridTemplateColumns: '95% 5%' }}>
            <div>{value}</div>
            <div className="d-flex flex-row justify-content-end">
              {showTranslateButton && !translateOptions?.resultIdsTranslated.includes(entry.value! + entry.seqNo) && (
                <Tooltip title={i18n.t('incidents.translate')}>
                  <GlobalOutlined
                    onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(entry)}
                    className="align-middle"
                    style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                  />
                </Tooltip>
              )}
              {showTranslateButton && translateOptions?.resultIdsTranslated.includes(entry.value! + entry.seqNo) && (
                <Tooltip title={i18n.t('incidents.showOriginal')}>
                  <RollbackOutlined
                    onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(entry)}
                    className="align-middle"
                    style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )}
        {this.answersToNotShowTranslateButton.includes(entry.fieldKey!) && <div>{value}</div>}
      </div>
    );
  };

  buildOlderEntries = (entries: LogEntry[]): ReactNode => {
    const entryNodes = entries.map((entry, index) =>
      this.buildEntry(entry, index, false, index === 0, {
        onShowTranslation: this.handleTranslationForEntry,
        translationResult: this.state.translationResult,
        resultIdsTranslated: this.state.resultIdsTranslated,
      })
    );

    const collapsePanelConfigs: CollapsePanelWithContent[] = [
      {
        key: 'investigation_old_entries',
        header: (
          <HasText
            content={i18n.t('dataDisplay.moreEntries', { count: entries.length })}
            strong
            style={{ fontSize: '12px' }}
          />
        ),
        content: entryNodes,
        className: 'has-collapse has-collapse-panel',
      },
    ];

    return (
      <HasCollapse
        bordered={false}
        expandIcon={(panelProps: any) => (
          <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ verticalAlign: 'middle' }} />
        )}
        panelConfigs={collapsePanelConfigs}
        className="has-collapse"
      />
    );
  };

  buildLatestEntry = (entry: LogEntry | undefined): ReactNode =>
    entry
      ? this.buildEntry(entry, -1, true, false, {
          onShowTranslation: this.handleTranslationForEntry,
          translationResult: this.state.translationResult,
          resultIdsTranslated: this.state.resultIdsTranslated,
        })
      : false;

  render() {
    const entries = [...this.props.entries];
    const latestEntry = this.buildLatestEntry(entries.pop());
    const { permissions } = this.context;
    const olderEntries = entries.length > 0 && this.buildOlderEntries(entries);
    const newEntryComponent = this.props.addEntryComponent
      ? React.cloneElement(this.props.addEntryComponent, { closeEditModeCallback: this.closeEditMode })
      : null;
    return (
      <React.Fragment>
        {hasPermissionType(PermissionType.INVESTIGATIONS_EDIT, permissions) && this.getEditIcon()}
        {olderEntries}
        {latestEntry}
        {hasPermissionType(PermissionType.INVESTIGATIONS_EDIT, permissions) && this.state.editMode && newEntryComponent}
      </React.Fragment>
    );
  }
}

HasInvestigationFormEntries.contextType = PermissionsContext;

export default HasInvestigationFormEntries;
