import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HasCompaniesTable, HasEmptyState, HasSpinner, HasText, Notification } from '../../components';
import { Company, Subscription } from '../../models';
import { CompanyService, EntityAction } from '../../services';
import { ErrorMessages, SuccessMessages } from '../../shared';
import HasCompaniesHeader from './companies.header';
import './companies.scss';
import HasCompanyDetails from './company.details';
import { AxiosError } from 'axios';
import i18n from '../../i18n/config';

interface CompaniesPageState {
  loading: boolean;
  companies: Company[];
  searchTerm: string;
  filteredCompanies: Company[];
  companyDetailsVisible: boolean;
  selectedCompany?: Company;
  deleteLoading?: boolean;
}

class HasCompaniesPage extends React.Component<WithTranslation, CompaniesPageState> {
  state: CompaniesPageState = {
    loading: true,
    searchTerm: '',
    companies: [],
    filteredCompanies: [],
    companyDetailsVisible: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { data: companies } = await CompanyService.getAll();
    this.updateSelectedCompany(companies);
    this.setState({ loading: false, companies, filteredCompanies: companies });
  };

  toggleCompanyStatus = (company: Company) => {
    CompanyService.toggleActiveStatus(company.id)
      .then(
        () => {
          this.getData();
          CompanyService.companyChanged(EntityAction.UPDATE, {} as Company);
          Notification.success(SuccessMessages.COMPANY_STATUS_UPDATE(company.activated, company.name));
        },
        (error) => Notification.error(ErrorMessages.COMPANY_STATUS_UPDATE(error.response?.data.message))
      )
      .catch((error) => Notification.error(ErrorMessages.COMPANY_STATUS_UPDATE(error.response?.data.message)));
  };

  deleteCompany = (company: Company) => {
    this.setState({ deleteLoading: true });
    const errorCallback = (error: AxiosError) => {
      Notification.error(ErrorMessages.COMPANY_DELETE(error.response?.data.message));
      this.setState({ deleteLoading: false });
    };
    CompanyService.delete(company.id)
      .then(
        () => {
          this.getData();
          CompanyService.companyChanged(EntityAction.UPDATE, {} as Company);
          Notification.success(SuccessMessages.COMPANY_DELETE(company.name));
          this.closeCompanyDetails();
          this.setState({ deleteLoading: false });
        },
        (error) => errorCallback(error)
      )
      .catch((error) => errorCallback(error));
  };
  updateSubscriptions = (company: Company, subscriptions: Subscription[]) => {
    CompanyService.updateCompanySubscriptions(company.id, subscriptions)
      .then(() => {
        this.getData();
        CompanyService.companyChanged(EntityAction.UPDATE, {} as Company);
        Notification.success(SuccessMessages.SUBSCRIPTIONS_UPDATE(company.name));
      })
      .catch((error) => Notification.error(ErrorMessages.SUBSCRIPTIONS_UPDATE(error.response?.data.message)));
  };
  updateSelectedCompany = (companies: Company[]) => {
    const { selectedCompany } = this.state;
    if (selectedCompany) {
      const newCompanyData = companies.find((company) => company.id === selectedCompany.id);
      this.setState({ selectedCompany: newCompanyData });
    }
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchTerm = event.target.value.toLocaleLowerCase();
    if (searchTerm === '') {
      this.setState({ searchTerm: '', filteredCompanies: this.state.companies });
    } else {
      let filteredCompanies = [] as Company[];
      this.state.companies.forEach((company) => {
        if (
          company.name.toLocaleLowerCase().includes(searchTerm) ||
          company.phoneNumber?.toLocaleLowerCase().includes(searchTerm) ||
          company.address?.toLocaleLowerCase().includes(searchTerm) ||
          company.domain.toLocaleLowerCase().includes(searchTerm)
        ) {
          filteredCompanies.push(company);
        }
      });
      this.setState({ searchTerm: searchTerm, filteredCompanies });
    }
  };

  handleRowClick = (company: Company) => {
    this.setState({ companyDetailsVisible: true, selectedCompany: company });
  };

  closeCompanyDetails = () => {
    this.setState({ selectedCompany: undefined, companyDetailsVisible: false });
  };

  render() {
    const { t } = this.props;
    const { loading, companies, filteredCompanies, companyDetailsVisible, selectedCompany, deleteLoading } = this.state;
    return (
      <div className="shadow-sm has-container-tile d-flex h-100">
        {loading ? (
          <HasSpinner size="large" />
        ) : companies.length > 0 ? (
          <React.Fragment>
            <div className={`companies-list ${companyDetailsVisible ? 'hidden' : ''}`}>
              <HasCompaniesHeader
                onSearch={this.handleSearch}
                loading={loading}
                dataLength={filteredCompanies.length}
              />
              <HasCompaniesTable
                companies={filteredCompanies}
                statusToggleCallback={this.toggleCompanyStatus}
                onRowClick={this.handleRowClick}
              />
            </div>
            <div className={`company-details-panel ${companyDetailsVisible ? 'active' : ''}`}>
              <HasCompanyDetails
                company={selectedCompany}
                onClose={this.closeCompanyDetails}
                onStatusToggle={this.toggleCompanyStatus}
                onDelete={this.deleteCompany}
                deleteLoading={deleteLoading}
                onUpdateSubscriptions={this.updateSubscriptions}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <HasEmptyState description={null}>
              <HasText content={t('company.noCompanies')} strong type="secondary" />
            </HasEmptyState>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(HasCompaniesPage);
