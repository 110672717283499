import { Badge } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import i18n from '../../../i18n/config';
import { Action, ActionImportance } from '../../../models';
import { getActionStatusColor, getActionStatusText, getDate } from '../../../utils';
import { HasTable } from '../../molecules';

interface ActionsListProps {
  actions: Action[];
  actionSelected: (action: Action) => void;
  loading: boolean;
}

const TABLE_HEADER_HEIGHT = 52;
const TABLE_ITEM_HEIGHT = 55;

class HasActionsList extends React.Component<ActionsListProps, {}> {
  rowInteraction = (record: Action) => {
    return {
      onClick: (_: React.MouseEvent) => {
        this.props.actionSelected(record);
      },
    };
  };

  render() {
    const { actions, loading } = this.props;
    return (
      <HasTable<Action>
        rowKey="id"
        data={actions}
        columns={getColumns()}
        loading={loading}
        onRow={this.rowInteraction}
        useScroll
        tableHeaderHeight={TABLE_HEADER_HEIGHT}
        tableItemHeight={TABLE_ITEM_HEIGHT}
      />
    );
  }
}
export default HasActionsList;

const getColumns = (): ColumnProps<Action>[] => [
  {
    title: i18n.t('shared.status'),
    key: 'status',
    render: (_, record: Action) =>
      record.status !== null && record.dueDate !== null ? (
        <>
          <Badge color={getActionStatusColor(record)} />
          {getActionStatusText(record)}
        </>
      ) : (
        i18n.t('dataDisplay.unknownValue')
      ),
  },
  {
    title: i18n.t('shared.assignee'),
    dataIndex: 'responsibleUserName',
    key: 'responsibleUserName',
  },
  {
    title: i18n.t('shared.dueDate'),
    key: 'dueDate',
    dataIndex: 'dueDate',
    render: (dueDate: string) => (dueDate ? getDate(dueDate) : undefined),
  },
  {
    title: i18n.t('shared.priority'),
    key: 'importance',
    render: (_, record: Action) => {
      if (record.importance !== null) {
        switch (record.importance) {
          case ActionImportance.LOW:
            return (
              <>
                <Badge color={'green'} />
                {i18n.t('shared.lowPriority')}
              </>
            );
          case ActionImportance.MEDIUM:
            return (
              <>
                <Badge color={'yellow'} />
                {i18n.t('shared.mediumPriority')}
              </>
            );
          case ActionImportance.HIGH:
            return (
              <>
                <Badge color={'red'} />
                {i18n.t('shared.highPriority')}
              </>
            );
        }
      } else {
        return i18n.t('dataDisplay.unknownValue');
      }
    },
  },
];
