import { LeftCircleOutlined } from '@ant-design/icons';
import { Alert, Col, Menu, Popconfirm, Result, Row } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { SelectValue } from 'antd/lib/select';
import { isUndefined } from 'lodash';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  HasButton,
  HasChatBotWrapper,
  HasDropdown,
  HasImage,
  HasModal,
  HasParagraph,
  HasSpinner,
  HasText,
  Notification,
} from '../../components';
import { Company, Form, IncidentType, IncidentTypeLabel } from '../../models';
import { CompanyService, FormService, IncidentService } from '../../services';
import { ConfirmMessages, ErrorMessages, Option } from '../../shared';
import { IncidentIcons, isObjectEmpty, catchClickEvent } from '../../utils';

interface NewReportProps extends WithTranslation {
  visible: boolean;
  onCancel: () => void;
}

interface NewReportState {
  selectedMenuKeys: string[];
  incidentTypesList: { key: IncidentType; typeName: string }[];
  selectedIncidentType?: IncidentType;
  form: Form;
  reportDone: boolean;
  error?: string;
  shouldChooseCompany: boolean;
  userCompanies: Company[];
  selectedCompany?: Company;
  showCompanyWithNoDivisionsWarning: boolean;
  loading: boolean;
}

class HasNewReport extends React.Component<NewReportProps, NewReportState> {
  state: NewReportState = {
    selectedMenuKeys: [],
    incidentTypesList: [] as { key: IncidentType; typeName: string }[],
    form: {} as Form,
    reportDone: false,
    error: undefined,
    shouldChooseCompany: false,
    userCompanies: [],
    showCompanyWithNoDivisionsWarning: false,
    loading: true,
  };

  componentDidUpdate(prevProps: Readonly<NewReportProps>) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.setState({ loading: true });
      this.getCompaniesForUser().finally(() => this.setState({ loading: false }));
      this.getIncidentTypes();
    }
    if (prevProps.visible === true && this.props.visible === false) {
      this.setState({
        selectedMenuKeys: [] as string[],
        selectedIncidentType: undefined,
        form: {} as Form,
        reportDone: false,
        error: undefined,
        loading: true,
      });
    }
  }

  getCompaniesForUser = async () => {
    const { data: userCompanies } = await CompanyService.getUserVisibleCompanies();
    let selectedCompany = undefined;
    if (userCompanies.length < 2) {
      selectedCompany = userCompanies[0];
    }
    this.setState({ shouldChooseCompany: userCompanies.length > 1, userCompanies, selectedCompany });
  };

  getIncidentTypes = () => {
    let types = Object.values(IncidentType);
    this.setState({ incidentTypesList: types.map((type) => ({ key: type, typeName: IncidentTypeLabel[type] })) });
  };

  getIncidentForm = (incidentType: IncidentType) => {
    this.setState({ selectedMenuKeys: [incidentType], selectedIncidentType: incidentType });
    FormService.getByFormType(IncidentType[incidentType])
      .then(
        (response) => this.setState({ form: response.data }),
        (error) => {
          Notification.error(ErrorMessages.INCIDENT_REPORT_GET_FORM_TYPE(error.response.data.message));
          this.setState({ selectedMenuKeys: [] as string[], selectedIncidentType: undefined });
        }
      )
      .catch((error) => {
        Notification.error(ErrorMessages.INCIDENT_REPORT_GET_FORM_TYPE(error.response.data.message));
        this.setState({ selectedMenuKeys: [] as string[], selectedIncidentType: undefined });
      });
  };

  incidentTypeSelected = (param: ClickParam) => {
    if (!this.state.selectedMenuKeys.length) {
      this.getIncidentForm(param.key as any);
    } else if (param.key === this.state.selectedIncidentType) {
      return;
    } else {
      catchClickEvent(param.domEvent);
    }
  };

  reportDone = (error?: any) => {
    this.setState({
      reportDone: true,
      error: error?.response?.data.message,
      selectedMenuKeys: [],
      selectedIncidentType: undefined,
      form: {} as Form,
      selectedCompany: undefined,
    });
    if (!error) {
      IncidentService.incidentListChanged();
    }
  };

  resetState = () => {
    this.setState({
      ...this.state,
      selectedMenuKeys: [],
      selectedIncidentType: undefined,
      form: {} as Form,
      reportDone: false,
      error: undefined,
      selectedCompany: undefined,
    });
    this.props.onCancel();
  };

  resetModal = () => {
    this.setState({
      ...this.state,
      selectedMenuKeys: [],
      selectedIncidentType: undefined,
      form: {} as Form,
      reportDone: false,
      error: undefined,
      selectedCompany: undefined,
    });
  };

  getSideMenu = (): JSX.Element => {
    const { t } = this.props;
    const { incidentTypesList, selectedMenuKeys, shouldChooseCompany, selectedCompany, loading } = this.state;
    return incidentTypesList.length ? (
      <Menu selectedKeys={selectedMenuKeys} onClick={this.incidentTypeSelected} mode="inline">
        {incidentTypesList.map((type) => (
          <Menu.Item key={type.key} disabled={loading || (shouldChooseCompany && !selectedCompany)}>
            <Popconfirm
              title={(() => {
                const warningMessage = ConfirmMessages.PROMPT_CHAT_BOT_TYPE_CHANGE;
                return (
                  <div className="d-flex flex-column">
                    <HasParagraph content={warningMessage.message} style={{ marginBottom: 5 }} strong />
                    <HasParagraph content={warningMessage.description} style={{ margin: 0 }} />
                  </div>
                );
              })()}
              okText={t('shared.yes')}
              cancelText={t('shared.no')}
              trigger={
                selectedMenuKeys.length > 0 && !selectedMenuKeys.includes(type.key.toString()) ? 'click' : 'none'
              }
              onConfirm={() => this.getIncidentForm(type.key as any)}
            >
              <div className="d-flex align-items-center">
                <HasImage
                  src={IncidentIcons[type.key]}
                  alt={type.typeName}
                  style={{ height: '32px', marginRight: '10px', objectFit: 'contain' }}
                  className="has-avatar"
                />
                <HasText
                  content={t(type.typeName)}
                  strong={selectedMenuKeys.includes(type.key.toString())}
                  style={{ color: 'inherit' }}
                />
              </div>
            </Popconfirm>
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <HasSpinner size="default" />
    );
  };

  getEmptyState = (): JSX.Element => {
    const { t } = this.props;
    return (
      <div className="d-flex flex-grow-1 align-items-center justify-content-center">
        <LeftCircleOutlined style={{ fontSize: '22px', marginRight: '10px', opacity: 0.7 }} />
        <HasText content={t('report.selectReportType')} />
      </div>
    );
  };

  getReportResult = (): JSX.Element => {
    const { error } = this.state;
    const { onCancel, t } = this.props;
    return error ? (
      <Result
        status="error"
        title={t('report.fail.submissionFailed')}
        subTitle={(() => (
          <div className="d-block">
            <HasParagraph content={t('report.fail.reportNotSubmitted')} style={{ marginBottom: '5px' }} />
            <HasText content={`${t('report.youCan')} ${t('report.fail.tryAgain')} ${t('report.orYouCan')}`} />
            <HasButton onClick={onCancel} type="link" style={{ paddingLeft: '4px', fontWeight: 500 }}>
              {t('report.returnToApp')}
            </HasButton>
            <Alert message={null} description={error} type="error" showIcon={false} className="text-left" />
          </div>
        ))()}
        className="overflow-y-auto"
      />
    ) : (
      <Result
        status="success"
        title={t('shared.done')}
        subTitle={(() => (
          <div className="d-block">
            <HasParagraph content={t('report.success.submissionSuccess')} style={{ marginBottom: '5px' }} />
            <HasText content={t('report.youCan')} />
            <HasButton onClick={this.resetModal} type="link" style={{ fontWeight: 500 }} className="px-1">
              {t('report.success.createNew')}
            </HasButton>
            <HasText content={t('report.orYouCan')} />
            <HasButton onClick={onCancel} type="link" style={{ fontWeight: 500 }} className="px-1">
              {t('report.returnToApp')}
            </HasButton>
          </div>
        ))()}
        className="overflow-y-auto"
      />
    );
  };

  getCompanySelector = (): JSX.Element => {
    const { t } = this.props;
    const { userCompanies, showCompanyWithNoDivisionsWarning } = this.state;
    return (
      <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
        <HasText content={t('shared.company')} strong type="secondary" className="mb-2" />
        <HasDropdown<Company>
          onChange={(_: SelectValue, option: Option<Company>) => {
            if (option.forObject.divisions.length < 1) {
              this.setState({ showCompanyWithNoDivisionsWarning: true });
            } else {
              this.setState({ selectedCompany: option.forObject, showCompanyWithNoDivisionsWarning: false });
            }
          }}
          placeholder={t('report.selectCompany')}
          style={{ minWidth: '180px' }}
          options={userCompanies.map<Option<Company>>((company) => ({
            value: company.id,
            label: company.name ? company.name : `(${t('shared.noName', 'no name')})`,
            forObject: company,
            className: !company.name ? 'italic-text' : '',
          }))}
        />
        {showCompanyWithNoDivisionsWarning ? (
          <HasText content={t('company.noDivisions')} strong type="secondary" className="mb-2" />
        ) : (
          <></>
        )}
      </div>
    );
  };

  render() {
    const { visible, t } = this.props;
    const { selectedIncidentType, form, reportDone, shouldChooseCompany, selectedCompany, loading } = this.state;
    return (
      <HasModal
        visible={visible}
        onCancel={this.resetState}
        maskClosable={false}
        title={t('shared.newReport')}
        footer={null}
        width={'720px'}
        bodyStyle={{ height: '384px' }}
        className="has-new-report"
      >
        <Row className="h-100">
          <Col span={8} className="h-100">
            {this.getSideMenu()}
          </Col>
          <Col span={16} className="d-flex h-100">
            {!isUndefined(selectedIncidentType) ? (
              !isObjectEmpty(form) ? (
                <HasChatBotWrapper form={form} doneCallback={this.reportDone} forCompany={selectedCompany} />
              ) : (
                <HasSpinner size="large" />
              )
            ) : !reportDone ? (
              loading || (shouldChooseCompany && !selectedCompany) ? (
                this.getCompanySelector()
              ) : (
                this.getEmptyState()
              )
            ) : (
              this.getReportResult()
            )}
          </Col>
        </Row>
      </HasModal>
    );
  }
}

export default withTranslation()(HasNewReport);
