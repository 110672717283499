import { Form } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormItemProps } from 'antd/lib/form';
import { FormProps } from 'antd/lib/form/Form';
import { SelectValue } from 'antd/lib/select';
import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { DropdownProps, Option } from '../../../shared';
import { HasButton, HasDropdown } from '../../atoms';

type CoordinatedDropdownProps<T, K> =
  | {
      parentDropdownProps: DropdownProps<T>;
      childDropdownProps: DropdownProps<K>;
      containerStyle?: CSSProperties;
      containerClassName?: string;
      asForm?: false;
      submitButtonText: string;
    }
  | {
      parentDropdownProps: Omit<DropdownProps<T>, 'value'>;
      childDropdownProps: Omit<DropdownProps<K>, 'value'>;
      submitButtonProps?: ButtonProps;
      submitButtonText: string;
      asForm: true;
      formProps: FormProps;
      parentFromItemProps: Omit<FormItemProps, 'children'>;
      childFormItemProps: Omit<FormItemProps, 'children'>;
      submitButtonFormItemProps?: Omit<FormItemProps, 'children'>;
    };

class HasCoordinatedDropdowns<T, K> extends React.Component<CoordinatedDropdownProps<T, K>> {
  getDropdownsAsForm = (): ReactNode => {
    if (this.props.asForm === true) {
      const {
        formProps,
        submitButtonProps,
        submitButtonText,
        parentFromItemProps: { getValueFromEvent: pGetValueFromEvent, ...pFormItemRest },
        childFormItemProps: { getValueFromEvent: cGetValueFromEvent, ...cFormItemRest },
        parentDropdownProps: { onChange: pChange, onSelect: pSelect, onDeselect: pDeselect, ...pRest },
        childDropdownProps: { onChange: cChange, onSelect: cSelect, onDeselect: cDeselect, ...cRest },
        submitButtonFormItemProps,
      } = this.props;
      return (
        <Form {...formProps}>
          <Form.Item
            {...pFormItemRest}
            getValueFromEvent={(value: SelectValue, option: Option<T>) => {
              pChange !== undefined && pChange(value, option);
              return option.value;
            }}
          >
            <HasDropdown<T> key={'company'} {...pRest} />
          </Form.Item>
          <Form.Item
            {...cFormItemRest}
            getValueFromEvent={(value: SelectValue, option: Option<K>) => {
              cChange !== undefined && cChange(value, option);
              return option.value;
            }}
          >
            <HasDropdown<K> key={'division'} {...cRest} />
          </Form.Item>
          <Form.Item {...submitButtonFormItemProps}>
            <HasButton {...submitButtonProps}>{submitButtonText}</HasButton>
          </Form.Item>
        </Form>
      );
    }
  };

  getStandaloneDropdowns = (): ReactNode => {
    if (!this.props.asForm) {
      const { parentDropdownProps, childDropdownProps, containerStyle, containerClassName } = this.props;
      return (
        <div style={containerStyle} className={containerClassName}>
          <HasDropdown<T> {...parentDropdownProps} />
          <HasDropdown<K> {...childDropdownProps} />
        </div>
      );
    }
  };

  render() {
    const { asForm } = this.props;
    return asForm ? this.getDropdownsAsForm() : this.getStandaloneDropdowns();
  }
}

export default HasCoordinatedDropdowns;
