import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import React from 'react';

interface OwnModalProps extends ModalProps {}

class HasModal extends React.Component<OwnModalProps, {}> {
  render() {
    const { okButtonProps, bodyStyle, ...rest } = this.props;
    return (
      <Modal
        {...rest}
        okButtonProps={{ ...okButtonProps, style: { marginRight: '10px', marginLeft: '0px' } }}
        bodyStyle={{ ...bodyStyle, padding: 0 }}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default HasModal;
