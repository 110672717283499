import React from 'react';
import { HasIncidentReport } from '../../components/organisms';
import i18n from '../../i18n/config';
import { FormLabel, FormResult, Incident, IncidentTypeLabel } from '../../models';
import { EntityAction, IncidentService } from '../../services';

interface IncidentDetailsProps {
  incident: Incident;
  formLabels: FormLabel;
}

class HasIncidentDetails extends React.Component<IncidentDetailsProps, {}> {
  parseFormLabels = (): Record<string, string> => {
    const { formLabels } = this.props;
    let dictionary: Record<string, string> = {};
    formLabels.questionLabels.map(({ key, question }) => (dictionary[key] = question));
    return dictionary;
  };

  render() {
    const { incident } = this.props;
    const questionLabels = this.parseFormLabels();
    return (
      <React.Fragment>
        <div className={'ant-descriptions-title'}>
          {i18n.t(IncidentTypeLabel[incident.type]).toLocaleUpperCase()} REPORT
        </div>
        <HasIncidentReport
          incident={incident}
          questionLabels={questionLabels}
          fieldChangedCallback={(incident: Incident) => IncidentService.incidentChanged(EntityAction.UPDATE, incident)}
        />
      </React.Fragment>
    );
  }
}

export default HasIncidentDetails;
