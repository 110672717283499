import { message } from 'antd';
import React, { ReactNode } from 'react';
import { MdClose } from 'react-icons/md';
import { HasText } from '../data-display';

message.config({
  duration: 5,
  maxCount: 5,
});

function getMessageContent(content: string): ReactNode {
  return (
    <div className="d-flex flex-row align-items-center">
      <HasText content={content} style={{ marginRight: '10px' }} />
      <MdClose onClick={() => message.destroy()} style={{ cursor: 'pointer', color: 'inherit', fontSize: '16px' }} />
    </div>
  );
}

export const Toast = {
  info: (content: string) => message.success({ content: getMessageContent(content) }),
  success: (content: string) => message.success({ content: getMessageContent(content) }),
  warning: (content: string) => message.warning({ content: getMessageContent(content) }),
  error: (content: string) => message.error({ content: getMessageContent(content), duration: 10 }),
};
