import React from 'react';
import { HasDropdown, HasText } from '../..';
import { COLORS, ChatBotCustomComponentProps, Option } from '../../../shared';
import { SelectValue } from 'antd/lib/select';

interface ChatBotDropdownProps<T> extends ChatBotCustomComponentProps {
  options: Option<T>[];
  placeholder: string;
}

interface ChatBotDropdownState {
  canChange: boolean;
  value: string | undefined;
}

class HasChatBotDropdown<T> extends React.Component<ChatBotDropdownProps<T>, ChatBotDropdownState> {
  state = {
    canChange: true,
    value: this.props.value || undefined,
  };

  handleChange = (value: SelectValue, option: Option<T>) => {
    const { step, triggerNextStep } = this.props;
    if (step && triggerNextStep) {
      const stepValue = value.toString();
      this.setState({ canChange: false, value: stepValue });
      triggerNextStep({ value: stepValue, trigger: step.metadata.trigger });
    }
  };

  render() {
    const { placeholder, options, value: propsValue } = this.props;
    const { canChange, value } = this.state;
    return (
      <div
        style={{ backgroundColor: canChange ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
        className="d-flex flex-row justify-content-end has-chat-bot-component"
      >
        {canChange ? (
          <HasDropdown<T>
            showSearch
            placeholder={placeholder}
            defaultValue={propsValue}
            options={options}
            onSelect={this.handleChange}
            style={{ width: '200px' }}
          />
        ) : (
          <HasText content={value} style={{ color: COLORS.WHITE, fontSize: '12px', fontWeight: 500 }} />
        )}
      </div>
    );
  }
}

export default HasChatBotDropdown;
