import { AxiosResponse } from 'axios';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Team } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class TeamService implements RestService<Team> {
  private selectedTeam = new ReplaySubject<Team[]>();
  private teamChangedSubject = new Subject<SubscriberEvent<Team>>();

  get(params?: any): Promise<AxiosResponse<Team>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Team[]>> {
    return httpClient.get<Team[]>(buildUrl(URL.TEAM, ['all']));
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Team>> {
    return httpClient.get<Team>(buildUrl(URL.TEAM, [resourceId]));
  }

  getAllByCompanyId(companyId: number): Promise<AxiosResponse<Team[]>> {
    return httpClient.get<Team[]>(buildUrl(URL.TEAM, ['company', companyId]));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Team>> {
    return httpClient.post<any>(buildUrl(URL.TEAM, []), body);
  }

  put(body: any, teamId: number): Promise<AxiosResponse<Team>> {
    return httpClient.put<any>(buildUrl(URL.TEAM, [teamId]), body);
  }

  create(resource: Team): Promise<AxiosResponse<Team>> {
    return httpClient.post<Team>(URL.TEAM, resource);
  }

  update(resource: Team): Promise<AxiosResponse<Team>> {
    return httpClient.put<Team>(URL.TEAM, resource);
  }

  editById(teamId: number, resource: Team): Promise<AxiosResponse<Team>> {
    return httpClient.put<Team>(buildUrl(URL.TEAM, [teamId]), resource);
  }

  delete(teamId: number): Promise<AxiosResponse<Team>> {
    return httpClient.delete<Team>(buildUrl(URL.TEAM, [teamId]));
  }

  // SUBSCRIPTIONS

  getTeamChangedListener(): Observable<Team[]> {
    return this.selectedTeam.asObservable();
  }

  getTeamListener(): Observable<SubscriberEvent<Team>> {
    return this.teamChangedSubject.asObservable();
  }

  teamChanged(event: EntityAction, team: Team) {
    this.teamChangedSubject.next({
      event: event,
      entity: team,
    });
  }

  refreshTeams() {
    this.teamChangedSubject.next({
      event: EntityAction.REFRESH,
      entity: {} as Team,
    });
  }
}

export default new TeamService();
