import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import i18n from '../../../i18n/config';
import { DEFAULT_LANGUAGE, Language, LANGUAGES } from '../../../shared';
import { LanguageService } from '../../../services';

interface LanguageBannerPickerState {
  selectedLanguage: Language | null;
}

class HasLanguageBannerPicker extends React.Component<{}, LanguageBannerPickerState> {
  state: LanguageBannerPickerState = { selectedLanguage: null };

  componentDidMount() {
    this.setState({ selectedLanguage: this.getSelectedLanguage() });
  }

  getSelectedLanguage = () => {
    const selectedLanguage = LANGUAGES.find((langObject) => langObject.id === i18n.language);
    return selectedLanguage ? selectedLanguage : DEFAULT_LANGUAGE;
  };

  languageChanged = (selectedLanguage: Language) => {
    this.setState({ selectedLanguage });
    i18n.changeLanguage(selectedLanguage.id);
    LanguageService.languageChanged(selectedLanguage);
  };

  render() {
    const { selectedLanguage } = this.state;
    return (
      selectedLanguage &&
      LANGUAGES.map(({ id, name, countryCode }, index) => (
        <div
          key={id}
          onClick={() => this.languageChanged({ id, name, countryCode })}
          className={`cursor pointer language-picker-option ${selectedLanguage.id === id ? 'active' : ''}`}
        >
          <ReactCountryFlag
            svg
            countryCode={countryCode}
            title={name}
            aria-label={name}
            className={`flag ${selectedLanguage.id === id ? 'active' : ''}`}
          />
        </div>
      ))
    );
  }
}

export default HasLanguageBannerPicker;
