import {
  BarChartOutlined,
  BarsOutlined,
  CheckCircleOutlined,
  ExceptionOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  PhoneOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { SiderProps } from 'antd/lib/layout';
import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { FaFileUpload, FaRegBuilding } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { PermissionsContext } from '../../../context';
import { UserRole } from '../../../models';
import { AuthService, CompanyService, FiltersService } from '../../../services';
import { COLORS, MenuItemWithKey } from '../../../shared';
import { clearToken, hasPermission, isLimitedEmployeePersonal, isUserRoleEqualsTo } from '../../../utils';
import { AppSwitch } from '../../molecules';
import { HasSideMenu } from '../index';
import './side.bar.scss';

interface SideBarProps extends RouteComponentProps, WithTranslation {
  sider: React.ComponentClass<SiderProps>;
  newReportCallback: () => void;
}

interface SideBarState {
  isCollapsed: boolean;
  isBelowBreakpoint: boolean;
}

class HasSideBar extends React.PureComponent<SideBarProps, SideBarState> {
  state: SideBarState = { isCollapsed: true, isBelowBreakpoint: false };

  private siderRef = React.createRef<Component<SiderProps, any, any>>();

  logout = () => {
    const { clearData } = this.context;
    AuthService.logout().then(() => {
      CompanyService.clearSelectedCompanyListener();
      FiltersService.deleteEventTableFilters();
      FiltersService.deleteActionTableFilters();
      sessionStorage.clear();
      clearToken();
      clearData && clearData();
      this.props.history.push('/login');
    });
  };

  getSelectedKeys = (): string[] => {
    let selectedKeys = [this.props.location.pathname];
    this.props.location.pathname.includes('settings') && selectedKeys.push('/settings/users');
    this.props.location.pathname.includes('events') && selectedKeys.push('/events');
    // this.props.location.pathname.includes('charts') && selectedKeys.push('/charts');
    return selectedKeys;
  };

  getTopMenuItems = (): MenuItemWithKey[] => {
    const { t } = this.props;

    const menuItems: MenuItemWithKey[] = [
      {
        key: '/home',
        title: t('sidebar.home'),
        icon: <HomeOutlined />,
      },
      {
        key: '/events',
        icon: <ExceptionOutlined />,
        title: t('sidebar.incidents'),
      },
      {
        key: '/actions',
        title: t('shared.actions'),
        icon: <CheckCircleOutlined />,
      },
    ];

    // if (!isLimitedEmployeePersonal()) {
    //   menuItems.push({
    //     key: '/charts',
    //     title: 'Charts',
    //     icon: <BarChartOutlined />,
    //   });
    // }

    menuItems.push({
      key: '/new-report',
      icon: <MessageOutlined />,
      title: t('shared.newReport'),
    });

    if (hasPermission()) {
      menuItems.push({
        key: '/historical-upload',
        icon: <FaFileUpload className="anticon align-middle" />,
        title: t('sidebar.historicalEvents'),
      });
    }

    return menuItems;
  };

  getBottomMenuItems = (): MenuItemWithKey[] => {
    const { t } = this.props;
    let menuItems = [
      {
        key: '/contact',
        icon: <PhoneOutlined />,
        title: t('shared.contact'),
      },
      {
        key: '/logout',
        icon: <LogoutOutlined style={{ color: COLORS.ORANGE }} />,
        title: t('sidebar.logout'),
      },
    ];
    if (hasPermission()) {
      menuItems.unshift({
        key: '/settings/users',
        title: t('sidebar.settings'),
        icon: <SettingOutlined className="has-side-bar-menu-item" />,
      });
    }
    if (hasPermission()) {
      menuItems.unshift({
        key: '/teams',
        icon: <TeamOutlined />,
        title: t('sidebar.teams'),
      });
    }
    if (isUserRoleEqualsTo(UserRole.OWNER)) {
      menuItems.splice(1, 0, {
        key: '/companies',
        title: t('sidebar.companies'),
        icon: <FaRegBuilding className="anticon has-side-bar-menu-item" />,
      });
    }

    return menuItems;
  };

  render() {
    const { sider: Sider, newReportCallback } = this.props;
    const { isCollapsed, isBelowBreakpoint } = this.state;
    const selectedKeys = this.getSelectedKeys();
    return (
      <Sider
        ref={this.siderRef}
        breakpoint={'lg'}
        onBreakpoint={(broken) => this.setState({ isBelowBreakpoint: broken })}
        collapsed={isBelowBreakpoint ? isCollapsed : true}
        onCollapse={(collapsed) => this.setState({ isCollapsed: collapsed })}
        collapsible={isBelowBreakpoint}
        collapsedWidth={isBelowBreakpoint ? 0 : 64}
        trigger={null}
        className={'shadow-sm has-side-bar'.concat(isBelowBreakpoint ? ' mobile' : '')}
      >
        {isBelowBreakpoint && (
          <span className={'ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left'}>
            <BarsOutlined
              onClick={() =>
                this.setState(({ isCollapsed }) => ({
                  isCollapsed: !isCollapsed,
                }))
              }
              className="align-middle"
            />
          </span>
        )}
        <div className="d-flex flex-column h-100 justify-content-between overflow-y-auto">
          <div>
            <AppSwitch />
            <HasSideMenu
              menuItems={this.getTopMenuItems()}
              selectedKeys={selectedKeys}
              onClick={({ key }) => key === '/new-report' && newReportCallback()}
              mode={'vertical-left'}
              theme={'dark'}
            />
          </div>
          <HasSideMenu
            menuItems={this.getBottomMenuItems()}
            selectedKeys={selectedKeys}
            onClick={({ key }) => key === '/logout' && this.logout()}
            mode={'vertical-left'}
            theme={'dark'}
          />
        </div>
      </Sider>
    );
  }
}

HasSideBar.contextType = PermissionsContext;

export default HasSideBar;
