import { AxiosResponse } from 'axios';

export interface RestService<T> {
  get(params?: any): Promise<AxiosResponse<T>>;

  getAll(): Promise<AxiosResponse<T[]>>;

  getById(resourceId: number | string, params?: any): Promise<AxiosResponse<T>>;

  post(body?: any, params?: any): Promise<AxiosResponse<T>>;

  put(body?: any, params?: any): Promise<AxiosResponse<T>>;

  create(resource: T): Promise<AxiosResponse<T>>;

  update(resource: T): Promise<AxiosResponse<T>>;

  delete(resourceId: number | string): Promise<AxiosResponse<T>>;
}

export const URL = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  USER: 'user',
  COMPANY: 'company',
  DIVISION: 'division',
  INCIDENT: 'incident',
  INVESTIGATION: 'investigation',
  REVIEW: 'review',
  ACTION: 'action',
  ACTION_COMPLIANCE_FORM: 'action-compliance-form',
  ACTION_ATTACHMENT: 'actionAttachment',
  REVIEW_ATTACHMENT: 'reviewAttachment',
  PHOTO: 'photo',
  INVESTIGATION_ATTACHMENT: 'attachment',
  PHONE: 'approvedphn',
  FORM: 'form',
  INCIDENT_ATTACHMENT: 'incidentAttachment',
  CHART: 'chart',
  TRANSLATE: 'translate',
  TEAM: 'teams',
  COUNTRY: 'country',
  PERMISSIONS: 'permissions',
};

export enum EntityAction {
  CREATE,
  UPDATE,
  DELETE,
  REFRESH,
  CHANGE_STATUS,
  ASSIGN,
}
