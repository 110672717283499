import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { User, UserRole, UserSignUpData, UserStatus } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class UserService implements RestService<User> {
  private usersChangedSubject = new Subject<SubscriberEvent<User>>();
  private companyPermissionsChangedSubject = new Subject<number>();

  get(params?: any): Promise<AxiosResponse<User>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<User[]>> {
    return httpClient.get<User[]>(buildUrl(URL.USER, ['all']));
  }

  getAllUsersForCompany(
    companyId: number,
    divisionId: number,
    includeDeleted: boolean
  ): Promise<AxiosResponse<User[]>> {
    let params = {};
    params = { companyId: companyId };
    if (!isNaN(divisionId)) {
      params = { ...params, divisionId: divisionId };
    }
    params = { ...params, includeDeleted: includeDeleted };
    return httpClient.get<User[]>(buildUrl(URL.USER, ['bycd']), { params: params });
  }

  getAllUsersSmallForCompany(companyId: number, divisionId: number): Promise<AxiosResponse<User[]>> {
    let params = {};
    params = { companyId: companyId };
    if (!isNaN(divisionId)) {
      params = { ...params, divisionId: divisionId };
    }
    return httpClient.get<User[]>(buildUrl(URL.USER, ['bycd', 'small']), { params: params });
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<User>> {
    return httpClient.get<User>(buildUrl(URL.USER, [resourceId]));
  }

  getMyUser(): Promise<AxiosResponse<User>> {
    return httpClient.get<User>(buildUrl(URL.USER, []));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<User>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<User>> {
    throw new Error('Method not implemented.');
  }

  create(resource: User): Promise<AxiosResponse<User>> {
    return httpClient.post<User>(URL.USER, resource);
  }

  update(resource: User): Promise<AxiosResponse<User>> {
    return httpClient.put<User>(URL.USER, resource);
  }

  updateOwnProfile(resource: User): Promise<AxiosResponse<User>> {
    return httpClient.put<User>(buildUrl(URL.USER, ['updateOwnProfile']), resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<User>> {
    return httpClient.delete<User>(buildUrl(URL.USER, [resourceId]));
  }

  deleteAndAnonymize(userId: string | number, companyId?: number): Promise<AxiosResponse<User>> {
    return httpClient.delete<User>(buildUrl(URL.USER, ['anonymize', userId]), { params: { companyId } });
  }

  deleteByCompany(resourceId: string | number, companyId?: number): Promise<AxiosResponse<User>> {
    return httpClient.delete<User>(buildUrl(URL.USER, ['delete', resourceId]), { params: { companyId } });
  }

  undeleteByCompany(resourceId: string | number, companyId?: number): Promise<AxiosResponse<User>> {
    return httpClient.put<User>(buildUrl(URL.USER, ['undelete', resourceId]), {}, { params: { companyId } });
  }

  updateUserStatus(userId: number, status: UserStatus | string): Promise<AxiosResponse<User>> {
    return httpClient.post<User>(buildUrl(URL.USER, [userId, 'status', status]));
  }

  changePassword(userId: number, password: string): Promise<AxiosResponse<User>> {
    return httpClient.post<User>(buildUrl(URL.USER, [userId, 'password']), password, {
      headers: { 'Content-Type': 'text/plain' },
    });
  }

  getInvitedUsersForMyCompany(companyId: number): Promise<AxiosResponse<User[]>> {
    return httpClient.get<User[]>(buildUrl(URL.USER, ['invitedUsersForMyCompany', companyId]));
  }

  getMyInvitationsForDataSharing(): Promise<AxiosResponse<User[]>> {
    return httpClient.get<User[]>(buildUrl(URL.USER, ['myInvitationsForDataSharing']));
  }

  getAllUsersWhichHaveCompanyDataInvitations(): Promise<AxiosResponse<User[]>> {
    return httpClient.get<User[]>(buildUrl(URL.USER, ['allInvitedUsersForCompanies']));
  }

  inviteUserToSignUp(user: UserSignUpData): Promise<AxiosResponse<string>> {
    return httpClient.post<string>(buildUrl(URL.USER, ['invite']), user);
  }

  inviteUserToViewCompany(formData: FormData): Promise<AxiosResponse<string>> {
    return httpClient.post<string>(buildUrl(URL.USER, ['inviteUsersToViewMyCompany']), formData);
  }

  revokeDataViewPermission(userId: number, invitingUserId: number): Promise<AxiosResponse<null>> {
    return httpClient.delete<null>(buildUrl(URL.USER, [userId, invitingUserId, 'removeUserInvitations']));
  }

  revokeMyDataViewPermission(invitingUserId: number): Promise<AxiosResponse<null>> {
    return httpClient.delete<null>(buildUrl(URL.USER, ['myInvitation', invitingUserId]));
  }

  activateAccount(token: string, emailAddress: string): Promise<AxiosResponse<null>> {
    return httpClient.get<null>(buildUrl(URL.USER, ['confirm']), { params: { token, emailAddress } });
  }

  forgotPassword(emailAddress: string): Promise<AxiosResponse<null>> {
    return httpClient.get<null>(buildUrl(URL.USER, ['forgotpwd']), { params: { emailAddress } });
  }

  checkEmail(emailOrPhone: string): Promise<AxiosResponse<null>> {
    return httpClient.get<null>(buildUrl(URL.USER, ['checkEmailOrPhone']), { params: { emailOrPhone } });
  }

  getInvitedUserRoleByEmail(email: string): Promise<AxiosResponse<UserRole>> {
    return httpClient.get<UserRole>(buildUrl(URL.USER, ['invitation', 'role']), { params: { email } });
  }

  signUp(user: any): Promise<AxiosResponse<null>> {
    return httpClient.post<null>(buildUrl(URL.USER, ['signUp']), user);
  }

  getByRoleForCompany(role: string, companyId: number): Promise<AxiosResponse<User[]>> {
    return httpClient.get<User[]>(buildUrl(URL.USER, [role, companyId]));
  }

  languageChanged(language: string) {
    return httpClient.post<null>(buildUrl(URL.USER, ['language', language]));
  }

  // SUBSCRIPTIONS

  getUsersChangedListener(): Observable<SubscriberEvent<User>> {
    return this.usersChangedSubject.asObservable();
  }

  usersChanged(event: EntityAction, user: User) {
    this.usersChangedSubject.next({ event: event, entity: user });
  }

  companyDataViewChanged(companyId: number) {
    this.companyPermissionsChangedSubject.next(companyId);
  }

  getCompanyDataViewChangedListener(): Observable<number> {
    return this.companyPermissionsChangedSubject.asObservable();
  }
}

export default new UserService();
