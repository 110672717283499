import React from 'react';
import { RouteComponentProps } from 'react-router';
import { IncidentGlance, IncidentType } from '../../../models';
import { HasSpinner } from '../../atoms';
import { HasIncidentCard } from '../../molecules';

interface IncidentBarProps {
  incidents: IncidentGlance[];
}

class HasIncidentBar extends React.Component<IncidentBarProps & RouteComponentProps, {}> {
  onCardClick = (type: IncidentType) => {
    const typeValue = Object.keys(IncidentType).indexOf(type);
    this.props.history.push(`events?type=${typeValue.toString()}`);
  };

  render() {
    const { incidents } = this.props;
    return !incidents.length ? (
      <HasSpinner size="large" />
    ) : (
      <div className="grid-tiles-container">
        {incidents.map((incident, index) => (
          <HasIncidentCard
            key={index}
            incident={incident}
            className={`has-incident-card t${index + 1}`}
            onClick={this.onCardClick}
          />
        ))}
      </div>
    );
  }
}

export default HasIncidentBar;
