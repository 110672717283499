import { CloseOutlined, ExclamationCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { FiMapPin } from 'react-icons/fi';
import {
  HasButton,
  HasCheckbox,
  HasCollapse,
  HasList,
  HasMap,
  HasParagraph,
  HasSpinner,
  HasText,
  HasTitle,
} from '../../components';
import i18n from '../../i18n/config';
import { Company, Division, Module, Subscription, User, UserRole, moduleLabels } from '../../models';
import { CompanyService, UserService } from '../../services';
import { ConfirmMessages } from '../../shared';
import { displayPlaceholderIfEmpty, getUserFullName } from '../../utils';

interface CompanyDetailsProps {
  company?: Company;
  onClose: () => void;
  onStatusToggle: (company: Company) => void;
  onDelete: (company: Company) => void;
  onUpdateSubscriptions: (company: Company, subscriptions: Subscription[]) => void;
  deleteLoading?: boolean;
}

interface CompanyDetailsState {
  loading: boolean;
  users: User[];
  deleteLoading: boolean;
  subscriptions: Subscription[];
  initialSubscriptionsState: Subscription[];
}

class HasCompanyDetails extends React.Component<CompanyDetailsProps, CompanyDetailsState> {
  state: CompanyDetailsState = {
    loading: true,
    users: [],
    deleteLoading: false,
    subscriptions: [],
    initialSubscriptionsState: [],
  };

  async componentDidMount() {
    const { company } = this.props;
    if (company) {
      const { data: users } = await UserService.getByRoleForCompany(UserRole.ADMIN, company.id);
      const subscriptions = company.subscriptions ? company.subscriptions : [];
      this.setState({ loading: false, users, subscriptions: subscriptions, initialSubscriptionsState: subscriptions });
    }
  }

  async componentDidUpdate(prevProps: Readonly<CompanyDetailsProps>) {
    if (!isEqual(prevProps.company, this.props.company)) {
      this.setState({ loading: true });
      const { company } = this.props;
      if (company) {
        const { data: users } = await UserService.getByRoleForCompany(UserRole.ADMIN, company.id);
        const subscriptions = company.subscriptions ? company.subscriptions : [];
        this.setState({
          loading: false,
          users,
          subscriptions: subscriptions,
          initialSubscriptionsState: subscriptions,
        });
      }
    }
  }

  showDeleteConfirmation = (company: Company) => {
    const { confirm } = Modal;
    const { onDelete } = this.props;
    confirm({
      title: ConfirmMessages.DELETE_ENTITY(i18n.t('nounsWithArticle.company')),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('company.deleteModalDescription'),
      okText: i18n.t('shared.yes'),
      okType: 'danger',
      cancelText: i18n.t('shared.cancel'),
      onOk() {
        onDelete && onDelete(company);
      },
    });
  };

  getCompanyAddress = (): JSX.Element => {
    const { company } = this.props;
    return (
      <HasParagraph
        content={
          <div className="d-flex flex-row align-items-center">
            <FiMapPin className="align-middle flex-shrink-0" style={{ fontSize: '24px' }} />
            <div className="d-flex flex-grow-1 ml-4">
              <Row className="flex-column">
                <Col>
                  <HasText content={i18n.t('shared.address')} />
                </Col>
                <Col>
                  <HasText content={company?.address} strong />
                </Col>
              </Row>
            </div>
          </div>
        }
        className="my-2"
      />
    );
  };

  getCompanyPhoneNumber = (): JSX.Element => {
    const { company } = this.props;
    return (
      <HasParagraph
        content={
          <div className="d-flex flex-row align-items-center">
            <PhoneOutlined className="align-middle flex-shrink-0" style={{ fontSize: '24px' }} />
            <div className="d-flex flex-grow-1 ml-4">
              <Row className="flex-column">
                <Col>
                  <HasText content={i18n.t('shared.phoneNumber')} />
                </Col>
                <Col>
                  <HasText content={company?.phoneNumber} strong />
                </Col>
              </Row>
            </div>
          </div>
        }
        className="my-2"
      />
    );
  };

  getCompanyContactInformation = (): JSX.Element => {
    const { company } = this.props;
    if (!company?.address) {
      return (
        <Row>
          {company?.phoneNumber ? (
            <Col span={24}>{this.getCompanyPhoneNumber()}</Col>
          ) : (
            <Col span={24} className="d-flex flex-row justify-content-center my-4">
              <HasText content={i18n.t('company.noContact')} strong type="secondary" />
            </Col>
          )}
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={12} className="d-flex flex-column justify-content-center pr-2">
            {this.getCompanyAddress()}
            {this.getCompanyPhoneNumber()}
          </Col>
          <Col span={12} style={{ minHeight: '200px' }}>
            <HasMap address={company?.address} />
          </Col>
        </Row>
      );
    }
  };

  getDivisionsList = (): JSX.Element => {
    const { company } = this.props;
    return company?.divisions.length ? (
      <HasList<Division>
        listConfig={{ size: 'large', style: { width: '100%' } }}
        listItemsConfig={company?.divisions.map((division) => ({
          item: {},
          meta: {
            title: division.name,
            description: division.userCount,
          },
        }))}
      />
    ) : (
      <div className="d-flex flex-row justify-content-center my-4">
        <HasText content={i18n.t('company.noDivisions')} strong type="secondary" />
      </div>
    );
  };

  getSubscriptionsList = (): JSX.Element => {
    const { company } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', marginTop: '16px' }}>
        {Object.keys(Module).map((module) => (
          <div key={module} style={{ display: 'flex', gap: '8px' }}>
            <HasCheckbox
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({
                    subscriptions: [
                      ...this.state.subscriptions,
                      { id: null, module: Module[module as keyof typeof Module] },
                    ],
                  });
                } else {
                  this.handleRemoveSubscription(Module[module as keyof typeof Module]);
                }
              }}
              checked={this.state.subscriptions.some((subscription) => subscription.module === module)}
            />
            <HasText content={moduleLabels[module](i18n.t.bind(i18n))} />
          </div>
        ))}
        <div style={{ display: 'flex', gap: '8px' }}>
          <HasButton
            type="primary"
            onClick={this.handleSave}
            disabled={this.state.subscriptions === this.state.initialSubscriptionsState}
          >
            {i18n.t('shared.save')}
          </HasButton>
          <HasButton ghost type="primary" onClick={this.handleCancel}>
            {i18n.t('shared.cancel')}
          </HasButton>
        </div>
      </div>
    );
  };

  handleSave = () => {
    const { company, onUpdateSubscriptions } = this.props;
    if (company) {
      onUpdateSubscriptions(company, this.state.subscriptions);
    }
  };

  handleCancel = () => {
    this.setState({ subscriptions: this.state.initialSubscriptionsState });
  };

  handleRemoveSubscription = (module: Module) => {
    this.setState((prevState) => ({
      subscriptions: prevState.subscriptions.filter((subscription) => subscription.module !== module),
    }));
  };

  getAdminsList = (): JSX.Element => {
    const { users, loading } = this.state;
    return loading ? (
      <HasSpinner size="large" />
    ) : users.length ? (
      <HasList<User>
        listConfig={{ size: 'large', style: { width: '100%' } }}
        listItemsConfig={users.map((user) => ({
          item: {},
          meta: {
            title: <HasText content={getUserFullName(user)} strong />,
            description: (
              <div className="d-flex flex-column">
                <HasButton
                  type="link"
                  href={`mailto:${user.email}`}
                  style={{ fontWeight: 600, padding: 0, textAlign: 'left' }}
                >
                  {user.email}
                </HasButton>
                <HasText content={displayPlaceholderIfEmpty(user.phone)} className="py-1" />
              </div>
            ),
          },
        }))}
      />
    ) : (
      <div className="d-flex flex-row justify-content-center my-4">
        <HasText content={i18n.t('company.noAdmin')} strong type="secondary" />
      </div>
    );
  };

  render() {
    const { company, onClose, onStatusToggle, deleteLoading } = this.props;
    return (
      <div className="d-flex flex-column flex-grow-1 px-3 py-2">
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <HasTitle content={company && company.name} level={3} className="mb-0 text-color primary" />
          <CloseOutlined onClick={() => onClose()} style={{ fontSize: '16px' }} />
        </div>
        <div
          className="d-flex flex-column flex-grow-1 overflow-y-auto mb-3"
          style={{ marginRight: '-10px', paddingRight: '10px' }}
        >
          <HasCollapse
            bordered={false}
            className="has-collapse"
            defaultActiveKey={['contact_info']}
            panelConfigs={[
              {
                key: 'contact_info',
                header: <HasText content={i18n.t('shared.contactInfo')} strong />,
                content: this.getCompanyContactInformation(),
                className: 'has-collapse has-collapse-panel p-2',
              },
              {
                key: 'subscriptions',
                header: <HasText content={i18n.t('shared.subscriptions', 'Subscriptions')} strong />,
                content: this.getSubscriptionsList(),
                className: 'has-collapse has-collapse-panel p-2',
              },
              {
                key: 'divisions',
                header: <HasText content={i18n.t('shared.divisions')} strong />,
                content: this.getDivisionsList(),
                className: 'has-collapse has-collapse-panel p-2',
              },
              {
                key: 'admins',
                header: <HasText content={i18n.t('company.admins')} strong />,
                content: this.getAdminsList(),
                className: 'has-collapse has-collapse-panel p-2',
              },
            ]}
          />
        </div>
        <div className="d-flex flex-row flex-shrink-0 justify-content-end">
          {company && (
            <React.Fragment>
              {company?.activated ? (
                <HasButton ghost type="danger" onClick={() => onStatusToggle(company)}>
                  {i18n.t('shared.inactivate')}
                </HasButton>
              ) : (
                <HasButton ghost type="primary" onClick={() => onStatusToggle(company)}>
                  {i18n.t('shared.activate')}
                </HasButton>
              )}
              <HasButton
                type="danger"
                loading={deleteLoading}
                onClick={() => this.showDeleteConfirmation(company)}
                className="ml-2"
              >
                {i18n.t('shared.delete')}
              </HasButton>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default HasCompanyDetails;
