import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import { HasModal, HasParagraph } from '../..';
import i18n from '../../../i18n/config';
import { NewPhoneNumber } from '../../../models';
import { ErrorMessages, ModalBaseProps } from '../../../shared';
import { HasTextInput } from '../../atoms';

interface PhoneModalProps extends ModalBaseProps<NewPhoneNumber> {}

interface PhoneModalState {
  updateInProgress: boolean;
}

class HasAddPhoneModal extends React.Component<PhoneModalProps, PhoneModalState> {
  state = { updateInProgress: false };

  private formRef = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<PhoneModalProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ updateInProgress: false });
    }
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    const newPhoneNumber: NewPhoneNumber = { ...values };
    this.props.onOk(newPhoneNumber).then(
      () => {
        this.setState({ updateInProgress: false });
        this.formRef.current?.resetFields();
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  render() {
    const { visible } = this.props;
    return (
      <HasModal
        title={
          <React.Fragment>
            <HasParagraph content={i18n.t('phone.addPhone.message')} style={{ marginBottom: '10px' }} />
            <HasParagraph
              content={i18n.t('phone.addPhone.description')}
              style={{ fontSize: '12px', margin: 0, fontWeight: 400 }}
            />
          </React.Fragment>
        }
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'phone-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
      >
        <Form
          id="phone-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
        >
          <Form.Item
            name="phoneNumber"
            label={i18n.t('shared.phoneNumber')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()) },
            ]}
          >
            <HasTextInput key="phoneNumber" size="large" />
          </Form.Item>
          <Form.Item
            name="name"
            label={i18n.t('shared.name')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumberName').toLowerCase()) },
              {
                whitespace: true,
                message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumberName').toLowerCase()),
              },
            ]}
          >
            <HasTextInput key="name" size="large" />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasAddPhoneModal;
