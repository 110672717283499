import { AxiosResponse } from 'axios';
import { UserLoginData } from '../models';
import { httpClient } from './http.client';

const LOGIN_URL = 'login';
const LOGOUT_URL = 'logout';

export class AuthService {
  login = (loginData: UserLoginData): Promise<any> => {
    return httpClient.post(LOGIN_URL, loginData);
  };

  logout = (): Promise<AxiosResponse<null>> => {
    return httpClient.post(LOGOUT_URL);
  };
}

export default new AuthService();
