import { FilterOutlined } from '@ant-design/icons';
import { Col, Dropdown, Empty, Menu, Row } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { isEqual } from 'lodash';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HasButton, HasEmptyState, HasPieChart, HasRadialChart, HasText } from '../..';
import { IncidentCauseStatistics, IncidentType, IncidentTypeLabel } from '../../../models';
import { formatTextToTitlecase } from '../../../utils';

interface IncidentCauseChartProps extends WithTranslation {
  data: IncidentCauseStatistics[];
  toggleCallback: any;
  currentIncidentType: IncidentType;
}

interface IncidentCauseChartState {
  noRegisteredData: boolean;
  causeChart: {
    labels: string[];
    series: number[];
  };
  subCauseChart: {
    labels: string[];
    series: number[];
  };
}

class HasIncidentCauseChart extends React.Component<IncidentCauseChartProps, IncidentCauseChartState> {
  state: IncidentCauseChartState = {
    noRegisteredData: false,
    causeChart: { labels: [], series: [] },
    subCauseChart: { labels: [], series: [] },
  };

  emptyStateRef = React.createRef<HTMLDivElement>();

  private labelValues: { [k: string]: string } = {
    PEOPLE: 'dashboard.charts.people',
    ENVIRONMENT: 'dashboard.charts.environment',
    EQUIPMENT_MATERIAL: 'dashboard.charts.equipmentMaterial',
    PROCESS_AND_PROCEDURE: 'dashboard.charts.processAndProcedure',
  };

  private selectedIncidentType: IncidentType = IncidentType.INCIDENT;

  componentDidMount() {
    this.generateChartData();
  }

  componentDidUpdate(prevProps: Readonly<IncidentCauseChartProps>, prevState: IncidentCauseChartState) {
    if (!isEqual(this.props.data, prevProps.data)) {
      this.generateChartData();
    }
  }

  generateChartData = () => {
    const { data } = this.props;
    if (!data.some((data) => data.causeTotal > 0)) {
      this.setState({ noRegisteredData: true });
    } else {
      this.setState({
        causeChart: {
          labels: data.map((stat) => this.labelValues[stat.cause]),
          series: data.map((stat) => stat.causeTotal),
        },
      });
      this.setState({ noRegisteredData: false });
      const highestStat = this.getHighestStat(data);
      const labelValue = Object.keys(this.labelValues).indexOf(highestStat.cause);
      this.causeChartClick(labelValue);
    }
  };

  getHighestStat = (data: IncidentCauseStatistics[]): IncidentCauseStatistics => {
    return data.reduce((prev, current) => {
      return prev.causeTotal > current.causeTotal ? prev : current;
    });
  };

  causeChartClick = (selectedLabel: any) => {
    const selectedCause = Object.keys(this.labelValues)[selectedLabel];
    const cause = this.props.data.find((item) => item.cause === selectedCause);
    if (cause) {
      const total = cause.data.map((cause) => cause.count).reduce((sum, current) => sum + current, 0);
      this.setState({
        subCauseChart: {
          labels: cause.data.map((cause) => formatTextToTitlecase(cause.subCause)),
          series: cause.data.map((cause) => Math.floor((cause.count / total) * 100)),
        },
      });
    }
  };

  incidentTypeChanged = (clickParam: ClickParam) => {
    this.selectedIncidentType =
      clickParam.key === IncidentType.NEAR_HIT.toString() ? IncidentType.NEAR_HIT : IncidentType.INCIDENT;
    this.props.toggleCallback(clickParam);
  };

  getEmptyState = () => {
    const { t } = this.props;
    const text = <HasText content={t('dataDisplay.noData')} type="secondary" strong />;
    if (this.emptyStateRef && this.emptyStateRef.current) {
      if (this.emptyStateRef.current.offsetHeight > 100) {
        return (
          <HasEmptyState
            description={false}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="h-100 d-flex flex-column justify-content-center"
          >
            {text}
          </HasEmptyState>
        );
      }
    }
    return text;
  };

  render() {
    const {
      causeChart,
      subCauseChart: { labels, series },
    } = this.state;
    const { currentIncidentType, t } = this.props;
    const menu = (
      <Menu onClick={this.incidentTypeChanged} selectedKeys={[this.selectedIncidentType.toString()]}>
        <Menu.Item key={IncidentType.NEAR_HIT}>{t(IncidentTypeLabel[IncidentType.NEAR_HIT])}</Menu.Item>
        <Menu.Item key={IncidentType.INCIDENT}>{t(IncidentTypeLabel[IncidentType.INCIDENT])}</Menu.Item>
      </Menu>
    );
    return (
      <Row className="h-100 flex-column position-relative">
        <Dropdown overlay={menu} className="position-absolute right-0 z-index-1">
          <div className="float-right">
            <HasButton style={{ minWidth: '0' }}>
              <FilterOutlined style={{ verticalAlign: 'middle' }} />
            </HasButton>
          </div>
        </Dropdown>
        {this.state.noRegisteredData ? (
          <Col span={24} className="d-flex flex-column flex-grow-1">
            <div className={'ant-descriptions-title'}>{t('dashboard.charts.casualFactor')}</div>
            <div
              ref={this.emptyStateRef}
              className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden"
            >
              {this.getEmptyState()}
            </div>
          </Col>
        ) : (
          <React.Fragment>
            <Col span={24} className="flex-grow-1 d-flex">
              <Row className="flex-grow-1 d-flex">
                <Col span={13} className="flex-grow-1 h-100">
                  {causeChart.labels && causeChart.series && (
                    <HasPieChart
                      title={`${t(IncidentTypeLabel[currentIncidentType]).toUpperCase()} ${t(
                        'dashboard.charts.casualFactor'
                      )}`}
                      clickCallback={this.causeChartClick}
                      series={causeChart.series}
                      labels={causeChart.labels.map((label) => t(label))}
                    />
                  )}
                </Col>
                <Col span={11} className="flex-grow-1 h-100">
                  {labels.length && series.length ? (
                    <HasRadialChart title={t('dashboard.charts.breakdown')} {...this.state.subCauseChart} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  }
}

export default withTranslation()(HasIncidentCauseChart);
