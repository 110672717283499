import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import i18n from '../../../i18n/config';
import { Team } from '../../../models/team.model';
import { TABLE_HEADER_HEIGHT, TABLE_ITEM_HEIGHT } from '../../../utils';
import { HasTable } from '../../molecules';

interface HasTeamsTableProps {
  teams: Team[];
  onRowClick: (team: Team) => void;
  onEditClick: (team: Team) => void;
  onDeleteClick: (team: Team) => void;
}

const HasTeamsTable: React.FC<HasTeamsTableProps> = ({ teams, onRowClick, onEditClick, onDeleteClick }) => {
  const teamsColumns: ColumnProps<Team>[] = [
    {
      title: i18n.t('team.code', 'Code'),
      key: 'id',
      dataIndex: 'id',
      width: '10%',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'ascend',
    },
    {
      title: i18n.t('team.teamName', 'Team name'),
      key: 'name',
      dataIndex: 'name',
      align: 'left',
      width: '40%',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: i18n.t('shared.actions', 'Actions'),
      key: 'actions',
      align: 'left',
      width: '10%',
      render: (_, record: any) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'flexStart',
          }}
        >
          <EditOutlined
            style={{ fontSize: '18px' }}
            className="icon-hover primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEditClick(record);
            }}
          />
          <Popconfirm
            title={i18n.t('team.deleteTeamConfirm', 'Are you sure you want to delete the team?')}
            okText={i18n.t('shared.yes', 'Yes')}
            cancelText={i18n.t('shared.no', 'No')}
            onConfirm={() => onDeleteClick(record)}
            placement="topLeft"
            style={{ maxWidth: '200px' }}
          >
            <DeleteFilled
              style={{ fontSize: '18px' }}
              className="icon-hover danger"
              onClick={(e) => e.stopPropagation()}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <HasTable<Team>
      rowKey="id"
      onRow={(record: Team) => ({
        onClick: () => onRowClick(record),
      })}
      columns={teamsColumns}
      sortDirections={['ascend', 'descend']}
      data={teams}
      tableHeaderHeight={TABLE_HEADER_HEIGHT}
      tableItemHeight={TABLE_ITEM_HEIGHT}
    />
  );
};

export { HasTeamsTable };
