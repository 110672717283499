import { FilterOutlined } from '@ant-design/icons';
import { Col, Dropdown, Empty, Menu, Row } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { isEqual } from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HasEmptyState, HasPieChart, HasRadialChart } from '../..';
import { IncidentReasonStatistics, IncidentType, IncidentTypeLabel } from '../../../models';
import { formatTextToTitlecase } from '../../../utils';
import { HasButton, HasText } from '../../atoms';

interface IncidentReasonChartProps extends WithTranslation {
  data: IncidentReasonStatistics[];
  toggleCallback: any;
  currentIncidentType: IncidentType;
}

interface IncidentReasonChartState {
  noRegisteredData: boolean;
  reasonChart: {
    labels: string[];
    series: number[];
  };
  subReasonChart: {
    labels: string[];
    series: number[];
  };
}

class HasIncidentReasonChart extends React.Component<IncidentReasonChartProps, IncidentReasonChartState> {
  state = {
    noRegisteredData: false,
    reasonChart: { labels: [], series: [] },
    subReasonChart: { labels: [], series: [] },
  };

  emptyStateRef = React.createRef<HTMLDivElement>();

  private labelValues: { [k: string]: string } = {
    CONTACT_WITH: 'dashboard.charts.contactWith',
    STRUCK_BY: 'dashboard.charts.struckBy',
    SLIPS_TRIPS_FALLS: 'dashboard.charts.slipsTripsFalls',
    OTHER: 'dashboard.charts.other',
  };

  private selectedIncidentType: IncidentType = IncidentType.INCIDENT;

  componentDidMount() {
    this.generateChartData();
  }

  componentDidUpdate(prevProps: Readonly<IncidentReasonChartProps>, prevState: IncidentReasonChartState) {
    if (!isEqual(this.props.data, prevProps.data)) {
      this.generateChartData();
    }
  }

  generateChartData = () => {
    const { data } = this.props;
    if (!data.some((data) => data.reasonTotal > 0)) {
      this.setState({ noRegisteredData: true });
    } else {
      this.setState({
        reasonChart: {
          labels: data.map((stat) => this.labelValues[stat.reason]),
          series: data.map((stat) => stat.reasonTotal),
        },
      });
      this.setState({ noRegisteredData: false });
      const highestStat = this.getHighestStat(data);
      const labelValue = Object.keys(this.labelValues).indexOf(highestStat.reason);
      this.reasonChartClick(labelValue);
    }
  };

  getHighestStat = (data: IncidentReasonStatistics[]): IncidentReasonStatistics => {
    return data.reduce((prev, current) => {
      return prev.reasonTotal > current.reasonTotal ? prev : current;
    });
  };
  reasonChartClick = (selectedLabel: any) => {
    const selectedReason = Object.keys(this.labelValues)[selectedLabel];
    let reason = this.props.data.find((item) => item.reason === selectedReason);
    if (reason) {
      const total = reason.data.map((reason) => reason.count).reduce((sum, current) => sum + current, 0);
      this.setState({
        subReasonChart: {
          labels: reason.data.map((reason) => formatTextToTitlecase(reason.subReason)),
          series: reason.data.map((reason) => Math.floor((reason.count / total) * 100)),
        },
      });
    }
  };

  incidentTypeChanged = (clickParam: ClickParam) => {
    this.selectedIncidentType =
      clickParam.key === IncidentType.NEAR_HIT.toString() ? IncidentType.NEAR_HIT : IncidentType.INCIDENT;
    this.props.toggleCallback(clickParam);
  };

  getEmptyState = () => {
    const { t } = this.props;
    const text = <HasText content={t('dataDisplay.noData')} type="secondary" strong />;
    if (this.emptyStateRef && this.emptyStateRef.current) {
      if (this.emptyStateRef.current.offsetHeight > 100) {
        return (
          <HasEmptyState
            description={false}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="h-100 d-flex flex-column justify-content-center"
          >
            {text}
          </HasEmptyState>
        );
      }
    }
    return text;
  };

  render() {
    const {
      reasonChart,
      subReasonChart: { labels, series },
    } = this.state;
    const { currentIncidentType, t } = this.props;
    const menu = (
      <Menu onClick={this.incidentTypeChanged} selectedKeys={[this.selectedIncidentType.toString()]}>
        <Menu.Item key={IncidentType.NEAR_HIT}>{t(IncidentTypeLabel[IncidentType.NEAR_HIT])}</Menu.Item>
        <Menu.Item key={IncidentType.INCIDENT}>{t(IncidentTypeLabel[IncidentType.INCIDENT])}</Menu.Item>
      </Menu>
    );
    const selectedType = t('dashboard.charts.incidentEvent', {
      incidentType: t(IncidentTypeLabel[currentIncidentType]),
    }).toUpperCase();
    return (
      <Row className="h-100 flex-column position-relative">
        <Dropdown overlay={menu} className="position-absolute right-0 z-index-1">
          <div className="float-right">
            <HasButton style={{ minWidth: '0' }}>
              <FilterOutlined style={{ verticalAlign: 'middle' }} />
            </HasButton>
          </div>
        </Dropdown>
        {this.state.noRegisteredData ? (
          <Col span={24} className="d-flex flex-column flex-grow-1">
            <div className={'ant-descriptions-title'}>{selectedType}</div>
            <div
              ref={this.emptyStateRef}
              className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden"
            >
              {this.getEmptyState()}
            </div>
          </Col>
        ) : (
          <React.Fragment>
            <Col span={24} className="flex-grow-1 d-flex">
              <Row className="flex-grow-1 d-flex">
                <Col span={13} className="flex-grow-1 h-100">
                  {reasonChart.labels && reasonChart.series && (
                    <HasPieChart
                      title={selectedType}
                      clickCallback={this.reasonChartClick}
                      series={reasonChart.series}
                      labels={reasonChart.labels.map((label) => t(label))}
                    />
                  )}
                </Col>
                <Col span={11} className="flex-grow-1 h-100">
                  {labels.length && series.length ? (
                    <HasRadialChart title={t('dashboard.charts.breakdown')} {...this.state.subReasonChart} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  }
}

export default withTranslation()(HasIncidentReasonChart);
