import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import React from 'react';
import { MenuItemWithKey } from '../../../shared';
import { HasMenuItem } from '../../atoms';

interface HasMenuProps {
  menuItems: MenuItemWithKey[];
}

class HasMenu extends React.PureComponent<HasMenuProps & MenuProps, {}> {
  render() {
    const { menuItems, ...menuProps } = this.props;
    return (
      <Menu {...menuProps}>
        {menuItems.map((menuItem) => (
          <HasMenuItem key={menuItem.key} config={menuItem} />
        ))}
      </Menu>
    );
  }
}

export default HasMenu;
