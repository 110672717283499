import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import React from 'react';

interface OwnAvatarProps extends AvatarProps {}

interface AvatarState {
  validSource: boolean;
}

class HasAvatar extends React.Component<OwnAvatarProps, AvatarState> {
  state = { validSource: true };

  imageErrorFallback = () => {
    this.setState({ validSource: false });
    return true;
  };

  render() {
    return this.state.validSource ? (
      <Avatar {...this.props} onError={this.imageErrorFallback} />
    ) : (
      <Avatar {...this.props} onError={this.imageErrorFallback}>
        {this.props.alt}
      </Avatar>
    );
  }
}

export default HasAvatar;
