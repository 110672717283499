import { Badge } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ClickParam } from 'antd/lib/menu';
import { TFunction } from 'i18next';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Subscription } from 'rxjs';
import {
  HasBarChart,
  HasIncidentBar,
  HasIncidentCauseChart,
  HasIncidentReasonChart,
  HasMap,
  HasRecentIncidentsList,
  HasSpinner,
  HasTable,
  HasText,
} from '../../components';
import {
  Action,
  ActionImportance,
  Incident,
  IncidentCauseStatistics,
  IncidentGlance,
  IncidentMonthlyCount,
  IncidentReasonStatistics,
  IncidentType,
} from '../../models';
import { ActionService, CompanyService, DivisionService, IncidentService } from '../../services';
import { Location } from '../../shared';
import { TABLE_PAGE_SIZE, getDate } from '../../utils';
import './dashboard.scss';
import { PaginationConfig } from 'antd/lib/pagination';
import { Pagination } from '../../services/pagination.service';

type EntitiesStatuses = {
  primaryEventsChart: boolean;
  secondaryEventsChart: boolean;
  pieCharts: boolean;
  cards: boolean;
  heatmap: boolean;
  recentEvents: boolean;
  actions: boolean;
};

interface DashboardState {
  incidents: Incident[];
  incidentsGlance: IncidentGlance[];
  incidentsRollingYearPrimary: IncidentMonthlyCount[];
  incidentsRollingYearSecondary: IncidentMonthlyCount[];
  incidentsReasonStatistics: IncidentReasonStatistics[];
  incidentsCauseStatistics: IncidentCauseStatistics[];
  incidentsHeatmap: Location[];
  actions: Action[];
  totalActions?: number;
  currentReasonIncidentType: IncidentType;
  currentCauseIncidentType: IncidentType;
  entitiesStatus: EntitiesStatuses;
}

const TABLE_ITEM_HEIGHT = 39;
const TABLE_HEADER_HEIGHT = 35;
const TABLE_PAGINATION_HEIGHT = 25 + 16 * 2;

class HasDashboardPage extends React.Component<RouteComponentProps & WithTranslation, DashboardState> {
  state = {
    incidents: Array<Incident>(),
    incidentsGlance: Array<IncidentGlance>(),
    incidentsRollingYearPrimary: Array<IncidentMonthlyCount>(),
    incidentsRollingYearSecondary: Array<IncidentMonthlyCount>(),
    incidentsReasonStatistics: Array<IncidentReasonStatistics>(),
    incidentsCauseStatistics: Array<IncidentCauseStatistics>(),
    incidentsHeatmap: Array<Location>(),
    actions: Array<Action>(),
    totalActions: 0,
    currentReasonIncidentType: IncidentType.INCIDENT,
    currentCauseIncidentType: IncidentType.INCIDENT,
    entitiesStatus: {
      primaryEventsChart: true,
      secondaryEventsChart: true,
      pieCharts: true,
      cards: true,
      heatmap: true,
      recentEvents: true,
      actions: true,
    },
  };

  private subscriptions: Subscription[] = [];
  private companyId: number = NaN;
  private divisionId: number = NaN;
  private dataLoadedOnCompanySubscribe = false;
  private actionPagination: Pagination = { pageNumber: 1, pageSize: TABLE_PAGE_SIZE };

  private primaryIncidentTypes = [IncidentType.INCIDENT, IncidentType.NEAR_HIT];
  private secondaryIncidentTypes = [
    IncidentType.AUDIT,
    IncidentType.HOUSE_KEEPING,
    IncidentType.SAFETY_CONV,
    IncidentType.TBOX,
  ];

  async componentDidMount() {
    this.subscriptions.push(
      CompanyService.getCompanyListener().subscribe((companyId) => {
        if (!isNaN(companyId)) {
          this.actionPagination.pageNumber = 1;
          this.actionPagination.pageSize = TABLE_PAGE_SIZE;
          this.companyId = companyId;
          this.divisionId = NaN;
          this.getDashboardData();
          this.dataLoadedOnCompanySubscribe = true;
        }
      }),
      DivisionService.getDivisionListener().subscribe((divisionId) => {
        if (!isNaN(divisionId)) {
          this.divisionId = divisionId;
          this.dataLoadedOnCompanySubscribe ? (this.dataLoadedOnCompanySubscribe = false) : this.getDashboardData();
        }
      })
    );
  }

  getNewLoadingState = (entity: keyof EntitiesStatuses, status: boolean): EntitiesStatuses => {
    return { ...this.state.entitiesStatus, [entity]: status };
  };

  getCompanyActions = () => {
    ActionService.getCompanyActions(this.actionPagination).then(({ data }) =>
      this.setState({
        actions: data.content,
        totalActions: data.totalElements,
        entitiesStatus: this.getNewLoadingState('actions', false),
      })
    );
  };

  getDashboardData = async () => {
    IncidentService.findLatestByCompanyAndDivision().then(({ data }) =>
      this.setState({ incidents: data, entitiesStatus: this.getNewLoadingState('recentEvents', false) })
    );
    IncidentService.countByType().then(({ data }) =>
      this.setState({ incidentsGlance: data, entitiesStatus: this.getNewLoadingState('cards', false) })
    );
    IncidentService.getRollingYear({ incidentTypes: this.primaryIncidentTypes }).then(({ data }) =>
      this.setState({
        incidentsRollingYearPrimary: this.buildRollingYearChartData(data),
        entitiesStatus: this.getNewLoadingState('primaryEventsChart', false),
      })
    );
    IncidentService.getRollingYear({
      incidentTypes: this.secondaryIncidentTypes,
    }).then(({ data }) =>
      this.setState({
        incidentsRollingYearSecondary: this.buildRollingYearChartData(data),
        entitiesStatus: this.getNewLoadingState('secondaryEventsChart', false),
      })
    );
    IncidentService.getIncidentsStatistics(this.state.currentCauseIncidentType).then(({ data }) =>
      this.setState({
        incidentsReasonStatistics: data.incidentReasonStatistics,
        incidentsCauseStatistics: data.incidentCauseStatistics,
        entitiesStatus: this.getNewLoadingState('pieCharts', false),
      })
    );
    IncidentService.getIncidentsHeatmap().then(({ data }) =>
      this.setState({ incidentsHeatmap: data, entitiesStatus: this.getNewLoadingState('heatmap', false) })
    );
    this.getCompanyActions();
  };

  buildRollingYearChartData = (data: { [K: string]: IncidentGlance[] }) => {
    const rollingIncidents: IncidentMonthlyCount[] = [];
    Object.keys(data).forEach((key) => {
      rollingIncidents.push({ month: key, incidents: data[key] });
    });
    return rollingIncidents;
  };

  reasonIncidentTypeChanged = (clickParam: ClickParam) => {
    const newIncidentType =
      clickParam.key === IncidentType.NEAR_HIT.toString() ? IncidentType.NEAR_HIT : IncidentType.INCIDENT;
    IncidentService.getIncidentsStatistics(newIncidentType).then((response) =>
      this.setState({
        incidentsReasonStatistics: response.data.incidentReasonStatistics,
        currentReasonIncidentType: newIncidentType,
      })
    );
  };

  causeIncidentTypeChanged = (clickParam: ClickParam) => {
    const newIncidentType =
      clickParam.key === IncidentType.NEAR_HIT.toString() ? IncidentType.NEAR_HIT : IncidentType.INCIDENT;
    IncidentService.getIncidentsStatistics(newIncidentType).then((response) =>
      this.setState({
        incidentsCauseStatistics: response.data.incidentCauseStatistics,
        currentCauseIncidentType: newIncidentType,
      })
    );
  };

  rowInteraction = ({ incidentId, id }: Action) => {
    return {
      onClick: (event: React.MouseEvent) => {
        this.props.history.push({
          pathname: `/events/action/${incidentId}`,
          search: `?id=${id}`,
        });
      },
    };
  };

  onTableChange = (pagination: PaginationConfig) => {
    this.actionPagination.pageNumber = pagination.current;
    this.actionPagination.pageSize = pagination.pageSize;
    this.getCompanyActions();
  };

  navigateToIncident = (incident: Incident) => {
    this.props.history.push(`events/event/${incident.id}`);
  };

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  render() {
    const {
      entitiesStatus: { primaryEventsChart, secondaryEventsChart, pieCharts, heatmap, recentEvents, actions },
      incidentsRollingYearPrimary,
      incidentsRollingYearSecondary,
      currentReasonIncidentType,
      currentCauseIncidentType,
      incidentsGlance,
      incidentsHeatmap,
      incidents,
      actions: outstandingActions,
      totalActions,
    } = this.state;
    const className = 'flex-grow-1 h-100 d-flex flex-column has-container-tile';
    return (
      <div className="grid-container">
        <div className="barChart1">
          <div className={className}>
            {primaryEventsChart ? (
              <HasSpinner size="large" />
            ) : (
              <HasBarChart
                title={this.props.t('dashboard.primaryEvent')}
                subtitle={this.props.t('dataDisplay.rollingLast6Months')}
                data={incidentsRollingYearPrimary}
              />
            )}
          </div>
        </div>
        <div className="pieChart1">
          <div className={className}>
            {pieCharts ? (
              <HasSpinner size="large" />
            ) : (
              <HasIncidentReasonChart
                data={this.state.incidentsReasonStatistics}
                toggleCallback={this.reasonIncidentTypeChanged}
                currentIncidentType={currentReasonIncidentType}
              />
            )}
          </div>
        </div>
        <div className="pieChart2">
          <div className={className}>
            {pieCharts ? (
              <HasSpinner size="large" />
            ) : (
              <HasIncidentCauseChart
                data={this.state.incidentsCauseStatistics}
                toggleCallback={this.causeIncidentTypeChanged}
                currentIncidentType={currentCauseIncidentType}
              />
            )}
          </div>
        </div>
        <div className="barChart2">
          <div className={className}>
            {secondaryEventsChart ? (
              <HasSpinner size="large" />
            ) : (
              <HasBarChart
                title={this.props.t('dashboard.secondaryEvent')}
                subtitle={this.props.t('dataDisplay.rollingLast6Months')}
                data={incidentsRollingYearSecondary}
              />
            )}
          </div>
        </div>
        <div className="tiles">
          <div className={'flex-grow-1'}>
            <HasIncidentBar incidents={incidentsGlance} {...this.props} />
          </div>
        </div>
        <div className="heatmap">
          <div className={className}>
            <h3 className={'ant-descriptions-title'}>{this.props.t('dashboard.heatMap')}</h3>
            {heatmap ? <HasSpinner size="large" /> : <HasMap heatmapLocations={incidentsHeatmap} />}
          </div>
        </div>
        <div className="recentActivity">
          <div className={className}>
            <h3 className={'ant-descriptions-title'}>{this.props.t('dashboard.recentActivity')}</h3>
            {recentEvents ? (
              <HasSpinner size="large" />
            ) : (
              <HasRecentIncidentsList
                incidents={incidents}
                onRowClick={(incident: Incident) => this.navigateToIncident(incident)}
              />
            )}
          </div>
        </div>
        <div className="actionsTable">
          <div className={className}>
            <h3 className={'ant-descriptions-title'}>{this.props.t('dashboard.otherActions')}</h3>
            <HasTable<Action>
              rowKey="id"
              data={outstandingActions}
              onChange={this.onTableChange}
              onPageSizeChange={(size: number) => (this.actionPagination.pageSize = size)}
              total={totalActions}
              defaultPageNumber={this.actionPagination.pageNumber}
              columns={actionTableColumns(this.props.t)}
              loading={actions}
              size="small"
              onRow={(record: Action) => this.rowInteraction(record)}
              tableHeaderHeight={TABLE_HEADER_HEIGHT}
              tableItemHeight={TABLE_ITEM_HEIGHT}
              tablePaginationHeight={TABLE_PAGINATION_HEIGHT}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HasDashboardPage);

const actionTableColumns = (t: TFunction): ColumnProps<Action>[] => [
  {
    title: t('shared.assignee'),
    dataIndex: 'responsibleUserName',
    key: 'responsibleUserName',
    ellipsis: true,
  },
  {
    title: t('shared.company'),
    dataIndex: 'company',
    key: 'company',
    ellipsis: true,
    render: (companyName) => {
      return (
        <HasText
          className={!companyName ? 'italic-text' : ''}
          content={companyName ? companyName : `(${t('shared.noName', 'no name')})`}
        />
      );
    },
  },
  {
    title: t('shared.division'),
    dataIndex: 'division',
    key: 'division',
    ellipsis: true,
  },
  {
    title: t('shared.dueDate'),
    dataIndex: 'dueDate',
    ellipsis: true,
    render: (dueDate: string) => (dueDate ? getDate(dueDate) : undefined),
  },
  {
    title: t('shared.priority'),
    key: 'importance',
    dataIndex: 'importance',
    ellipsis: true,
    render: (importance: ActionImportance) => {
      if (importance !== null) {
        switch (importance) {
          case ActionImportance.LOW:
            return (
              <>
                <Badge color={'green'} /> {t('shared.lowPriority')}
              </>
            );
          case ActionImportance.MEDIUM:
            return (
              <>
                <Badge color={'yellow'} /> {t('shared.mediumPriority')}
              </>
            );
          case ActionImportance.HIGH:
            return (
              <>
                <Badge color={'red'} /> {t('shared.highPriority')}
              </>
            );
        }
      } else {
        return t('dataDisplay.unknownValue');
      }
    },
  },
];
