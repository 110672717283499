import React from 'react';
import { HasParagraph, HasRadioGroup } from '../../..';
import i18n from '../../../../i18n/config';
import { ErrorMessages, RadioGroupOption, TypedRadioGroupProps } from '../../../../shared';
import { HasButton, HasText, HasTitle } from '../../../atoms';
import { RiskImpact, RiskImpactLabel, RiskProbability, RiskProbabilityLabel } from '../../../../models';
import { EventUtils, i18nLabelParser } from '../../../../utils';
import { RadioChangeEvent } from 'antd/lib/radio';

interface RiskMatrixRadioGroupProps {
  onChange: (data?: any) => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
}

interface RiskMatrixRadioGroupState {
  error: boolean;
  probabilityRadioOptionsConfig: RadioGroupOption<string>[];
  impactRadioOptionsConfig: RadioGroupOption<string>[];
}

class HasRiskMatrixRatioGroup extends React.Component<RiskMatrixRadioGroupProps, RiskMatrixRadioGroupState> {
  private selectedProbability: string | null = null;
  private selectedImpact: string | null = null;
  private probabilityRadioConfig: TypedRadioGroupProps<string>;
  private impactRadioConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<RiskMatrixRadioGroupProps>) {
    super(props);

    this.probabilityRadioConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onProbabilityChange,
    };

    this.impactRadioConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onImpactChange,
    };
    const probabilityRadioOptionsConfig = this.buildRadioOptions('probability');
    const impactRadioOptionsConfig = this.buildRadioOptions('impact');

    this.state = {
      error: false,
      probabilityRadioOptionsConfig,
      impactRadioOptionsConfig,
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () =>
      this.setState({
        probabilityRadioOptionsConfig: this.buildRadioOptions('probability'),
        impactRadioOptionsConfig: this.buildRadioOptions('impact'),
      })
    );
  }

  buildRadioOptions = (type: string): RadioGroupOption<string>[] => {
    switch (type) {
      case 'probability':
        return Object.values(RiskProbability).map((value, index) => ({
          key: index,
          value: value,
          forObject: index,
          label: `${index + 1} - ` + i18nLabelParser(RiskProbabilityLabel[value]),
        }));
      case 'impact':
        return Object.values(RiskImpact).map((value, index) => ({
          key: index,
          value: value,
          forObject: index,
          label: `${index + 1} - ` + i18nLabelParser(RiskImpactLabel[value]),
        }));
      default:
        this.setState({ error: false });
        return [];
    }
  };

  onProbabilityChange = (event: RadioChangeEvent) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.selectedProbability = event.target.value;
    this.props.onChange(event.target.value);
  };

  onImpactChange = (event: RadioChangeEvent) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.selectedImpact = event.target.value;
    this.props.onChange(event.target.value);
  };

  saveData = () => {
    if (this.selectedImpact && this.selectedProbability) {
      const impactValue = EventUtils.getRiskImpactIndex(this.selectedImpact);
      const probabilityValue = EventUtils.getRiskProbabilityIndex(this.selectedProbability);
      if (impactValue && probabilityValue) {
        const { riskLevel } = EventUtils.getRiskMatrixValues(impactValue, probabilityValue);
        const riskScore = impactValue * probabilityValue;
        const answer = JSON.stringify({
          impactValue: this.selectedImpact,
          probabilityValue: this.selectedProbability,
          riskScore,
          riskLevel,
        });
        this.props.onSave(answer);
        this.props.closeEditModeCallback && this.props.closeEditModeCallback();
      }
    } else {
      this.setState({ error: true });
    }
  };

  componentWillUnmount() {
    i18n.on('languageChanged', () =>
      this.setState({
        probabilityRadioOptionsConfig: this.buildRadioOptions('probability'),
        impactRadioOptionsConfig: this.buildRadioOptions('impact'),
      })
    );
  }

  render() {
    const { probabilityRadioOptionsConfig, impactRadioOptionsConfig } = this.state;
    return (
      <React.Fragment>
        <HasTitle
          content={i18n.t('shared.impactQuestion', 'HOW SEVERE WOULD THE OUTCOMES BE IF THE EVENT OCCURRED?')}
          style={{ fontSize: '12px', marginTop: '8px' }}
        />
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup {...this.impactRadioConfig} groupOptions={impactRadioOptionsConfig} />
        <HasTitle
          content={i18n.t('shared.probabilityQuestion', 'WHAT IS THE PROBABILITY THAT THIS EVENT WILL HAPPEN?')}
          style={{ fontSize: '12px', marginTop: '8px' }}
        />
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <HasRadioGroup {...this.probabilityRadioConfig} groupOptions={probabilityRadioOptionsConfig} />
          <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData}>
            {i18n.t('shared.save')}
          </HasButton>
        </div>
        {this.state.error && (
          <HasText
            content={ErrorMessages.INVESTIGATION_ENTRY_NO_OPTION_SELECTED}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HasRiskMatrixRatioGroup;
