import { Spin } from 'antd';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import './i18n/config';
import './index.scss';
import * as serviceWorker from './serviceWorker';

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

ReactDOM.render(
  <Router history={history}>
    <Suspense
      fallback={
        <div style={{ height: '100vh', width: '100vw', display: 'grid', placeContent: 'center' }}>
          <Spin size="large" />
        </div>
      }
    >
      <App />
    </Suspense>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
