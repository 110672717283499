import React from 'react';
import ReactApexChart, { Props } from 'react-apexcharts';

class HasChart extends React.Component<Props, {}> {
  render() {
    const { options, series, type, height } = this.props;
    return <ReactApexChart options={options} series={series} type={type} height={height} />;
  }
}

export default HasChart;
