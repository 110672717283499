import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';

interface OwnInputProps extends InputProps {}

class HasTextInput extends React.Component<OwnInputProps, {}> {
  render() {
    return <Input {...this.props} />;
  }
}

export default HasTextInput;
