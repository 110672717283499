import { Empty } from 'antd';
import { isEqual } from 'lodash';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IncidentMonthlyCount, IncidentTypeLabel } from '../../../models';
import { CHART_COLORS, COLORS } from '../../../shared';
import { HasChart, HasText } from '../../atoms';
import HasEmptyState from './empty.state';

interface BarChartProps extends WithTranslation {
  data: IncidentMonthlyCount[];
  title: string;
  subtitle: string;
}

class HasBarChart extends React.PureComponent<BarChartProps, any> {
  emptyStateRef = React.createRef<HTMLDivElement>();

  getTickAmount(data: IncidentMonthlyCount[]): number {
    const monthlyMax: number[] = data.map(
      (monthlyCount) =>
        monthlyCount.incidents.reduce((prev, current) => (prev.count > current.count ? prev : current)).count
    );
    return Math.max(...monthlyMax) > 10 ? 10 : Math.max(...monthlyMax);
  }

  constructor(props: Readonly<BarChartProps>) {
    super(props);
    this.state = {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: { show: false },
          stacked: false,
        },
        colors: CHART_COLORS,
        xaxis: {
          categories: [],
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val: any, index: any) {
              return val.toFixed(0);
            },
          },
          tickAmount: this.getTickAmount(props.data),
        },
        title: {
          text: this.props.title,
          floating: true,
          margin: 10,
          offsetY: -5,
          style: {
            fontSize: '14px',
            fontWeight: '700',
            fontFamily: 'Montserrat',
            color: COLORS.NEW_ORANGE,
          },
        },
        legend: {
          show: false,
        },
        subtitle: {
          text: this.props.subtitle,
          align: 'right',
          floating: true,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: COLORS.GRAY_ACCENT_DARK,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'flat',
            columnWidth: '100%',
            barHeight: '100%',
            distributed: false,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
            },
            dataLabels: {
              maxItems: 100,
              hideOverflowingLabels: false,
              orientation: 'horizontal',
            },
          },
        },
      },
      series: [],
    };
  }

  componentDidMount() {
    this.generateChartData(this.props.data);
  }

  componentDidUpdate(prevProps: Readonly<BarChartProps>) {
    if (!isEqual(this.props.data, prevProps.data)) {
      this.generateChartData(this.props.data);
    }
    if (this.props.title !== prevProps.title || this.props.subtitle !== prevProps.subtitle) {
      this.setState({
        ...this.state,
        options: {
          ...this.state.options,
          title: { text: this.props.title },
          subtitle: { text: this.props.subtitle },
        },
      });
    }
  }

  generateChartData = (data: IncidentMonthlyCount[]) => {
    const months = data.map((data) => data.month);
    const series: { name: string; data: Array<number> }[] = [];
    data.forEach((incidentMonthlyCount) => {
      incidentMonthlyCount.incidents.forEach((incidentCount) => {
        const serieName = IncidentTypeLabel[incidentCount.type];
        let serieForType = series.find((serie) => serie.name === serieName);
        if (serieForType) {
          serieForType.data.push(incidentCount.count);
        } else {
          let dataArray = new Array<number>();
          dataArray.push(incidentCount.count);
          series.push({
            name: serieName,
            data: dataArray,
          });
        }
      });
    });
    this.setState({ options: { ...this.state.options, xaxis: { categories: months } }, series: series });
  };

  hasSeriesData = (): boolean => {
    const { data } = this.props;
    if (data.length > 0) {
      const counts = data
        .map((item) => item.incidents.map((subItem) => subItem.count))
        .reduce((acc, subArray) => acc.concat(subArray), []);
      return !counts.every((count) => count === 0);
    } else {
      return false;
    }
  };

  getEmptyState = () => {
    const { t } = this.props;
    const text = <HasText content={t('dataDisplay.noData')} type="secondary" strong />;
    if (this.emptyStateRef && this.emptyStateRef.current) {
      if (this.emptyStateRef.current.offsetHeight > 100) {
        return (
          <HasEmptyState
            description={false}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="h-100 d-flex flex-column justify-content-center"
          >
            {text}
          </HasEmptyState>
        );
      }
    }
    return text;
  };

  render() {
    const { t } = this.props;
    const { options, series } = this.state;
    return this.hasSeriesData() ? (
      <HasChart
        options={options}
        series={series.map((serie: any) => ({ ...serie, name: t(serie.name) }))}
        type="bar"
        height={'100%'}
      />
    ) : (
      <React.Fragment>
        <div className="ant-descriptions-title">{this.props.title}</div>
        <div
          ref={this.emptyStateRef}
          className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden"
        >
          {this.getEmptyState()}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(HasBarChart);
