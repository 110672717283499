import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HasParagraph, HasSpinner, HasTitle } from '../..';
import i18n from '../../../i18n/config';
import {
  BulletListParagraph,
  SectionedListParagraph,
  TermsAndConditionsSection,
  TERMS_AND_CONDITIONS,
  TextParagraph,
} from '../../../shared';

interface TermsAndConditionsState {
  termsAndConditions: TermsAndConditionsSection[] | null;
}

class HasTermsAndConditionsPage extends React.Component<WithTranslation, TermsAndConditionsState> {
  state: TermsAndConditionsState = { termsAndConditions: null };

  componentDidMount() {
    this.setState({ termsAndConditions: this.getTermsAndConditions() });
    i18n.on('languageChanged', () => this.setState({ termsAndConditions: this.getTermsAndConditions() }));
  }

  getTermsAndConditions = (): TermsAndConditionsSection[] => {
    const language = i18n.language;
    let termsAndConditions = TERMS_AND_CONDITIONS[language];
    if (!termsAndConditions) {
      termsAndConditions = TERMS_AND_CONDITIONS['en'];
    }
    return termsAndConditions;
  };

  renderParagraphBulletList = (listItems: (TextParagraph | BulletListParagraph)[], depth: number): ReactNode => {
    return listItems.map((listItem, index) => {
      switch (typeof listItem) {
        case 'string':
          return (
            <li key={index} style={{ paddingLeft: `${depth * 2}` }}>
              {listItem}
            </li>
          );
        case 'object':
          return (
            <li key={index}>
              {listItem.header}
              <ul>{this.renderParagraphBulletList(listItem.listItems, depth + 1)}</ul>
            </li>
          );
        default:
          return null;
      }
    });
  };

  renderSectionList = (
    listItems: (TextParagraph | BulletListParagraph)[],
    number: number,
    depth: number
  ): ReactNode => {
    return listItems.map((listItem, index) => {
      const subSectionNumber = number + '.' + (index + 1);
      switch (typeof listItem) {
        case 'string':
          return <HasParagraph key={index} content={subSectionNumber.concat(' ').concat(listItem)} />;
        case 'object':
          return (
            <div key={index} style={{ marginBottom: '14px' }}>
              <HasParagraph content={subSectionNumber.concat(' ').concat(listItem.header)} />
              <ul>{this.renderParagraphBulletList(listItem.listItems, depth + 1)}</ul>
            </div>
          );
        default:
          return null;
      }
    });
  };

  renderSectionParagraphs = (section: TermsAndConditionsSection): ReactNode => {
    const renderedSections = section.paragraphs.map((paragraph, index) => {
      switch (typeof paragraph) {
        case 'string':
          return <HasParagraph key={index} content={paragraph} />;
        case 'object':
          if ((paragraph as SectionedListParagraph).number) {
            const section = paragraph as SectionedListParagraph;
            return (
              <li key={index}>
                <HasParagraph content={section.header} style={{ fontWeight: 600 }} />
                {this.renderSectionList(section.listItems, section.number, 0)}
              </li>
            );
          } else {
            return (
              <div key={index}>
                <HasParagraph content={paragraph.header} />
                <ul>{this.renderParagraphBulletList(paragraph.listItems, 0)}</ul>
              </div>
            );
          }
        default:
          return null;
      }
    });
    if (this.doesHaveOrderedSections(section)) {
      return <ol style={{ listStyleType: 'upper-roman' }}>{renderedSections}</ol>;
    } else {
      return renderedSections;
    }
  };

  doesHaveOrderedSections = (section: TermsAndConditionsSection) => {
    return section.paragraphs.some((paragraph) => (paragraph as SectionedListParagraph).number !== undefined || null);
  };

  renderTermsAndConditions = (termsAndConditions: TermsAndConditionsSection[]) => {
    return termsAndConditions.map((section, index) => (
      <div key={index}>
        <HasTitle content={section.title} level={4} style={{ fontSize: '18px' }} />
        {this.renderSectionParagraphs(section)}
      </div>
    ));
  };

  componentWillUnmount() {
    i18n.off('languageChanged', () => this.setState({ termsAndConditions: this.getTermsAndConditions() }));
  }

  render() {
    const { termsAndConditions } = this.state;
    return (
      <div className="h-100 has-container-tile overflow-y-auto">
        {termsAndConditions ? this.renderTermsAndConditions(termsAndConditions) : <HasSpinner size="large" />}
      </div>
    );
  }
}

export default withTranslation()(HasTermsAndConditionsPage);
