import { AxiosResponse } from 'axios';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Company, Subscription } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class CompanyService implements RestService<Company> {
  private selectedCompany = new ReplaySubject<number>(1);

  private companyChangedSubject = new Subject<SubscriberEvent<Company>>();

  get(params?: any): Promise<AxiosResponse<Company>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Company[]>> {
    return httpClient.get<Company[]>(buildUrl(URL.COMPANY, ['all']));
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Company>> {
    return httpClient.get<Company>(buildUrl(URL.COMPANY, [resourceId]));
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Company>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Company>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Company): Promise<AxiosResponse<Company>> {
    throw new Error('Method not implemented.');
  }

  update(resource: Company): Promise<AxiosResponse<Company>> {
    return httpClient.put<Company>(URL.COMPANY, resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Company>> {
    return httpClient.delete<Company>(buildUrl(URL.COMPANY, [resourceId]));
  }

  getCompanyForLoggedInUser(): Promise<AxiosResponse<Company>> {
    return httpClient.get<Company>(buildUrl(URL.COMPANY, ['forUser']));
  }

  getUserVisibleCompanies(): Promise<AxiosResponse<Company[]>> {
    return httpClient.get<Company[]>(buildUrl(URL.COMPANY, ['allVisibleCompanies']));
  }

  getCompanyWithDivisionInformation(
    companyId: number,
    includeDeletedDivisions?: boolean
  ): Promise<AxiosResponse<Company>> {
    return httpClient.get<Company>(buildUrl(URL.COMPANY, [companyId, 'withusercounts']), {
      params: { includeDeletedDivisions },
    });
  }

  getCompanyBySignUpInvitation(email: string): Promise<AxiosResponse<Company>> {
    return httpClient.get<Company>(buildUrl(URL.COMPANY, ['email', 'invitation']), { params: { email } });
  }

  getCompanyByEmail(email: string): Promise<AxiosResponse<Company>> {
    return httpClient.get<Company>(buildUrl(URL.COMPANY, ['email']), { params: { email } });
  }

  toggleActiveStatus(companyId: number): Promise<AxiosResponse<Company>> {
    return httpClient.post<Company>(buildUrl(URL.COMPANY, ['toggleActiveStatus', companyId]));
  }

  updateCompanySubscriptions(companyId: number, subscriptions: Subscription[]): Promise<AxiosResponse<Company>> {
    return httpClient.put<Company>(buildUrl(URL.COMPANY, ['subscriptions', companyId]), subscriptions);
  }

  // SUBSCRIPTIONS

  selectedCompanyChanged(companyId: number) {
    this.selectedCompany.next(companyId);
  }

  getCompanyListener(): Observable<number> {
    return this.selectedCompany.asObservable();
  }

  clearSelectedCompanyListener() {
    this.selectedCompany.complete();
    this.selectedCompany.unsubscribe();
    this.selectedCompany = new ReplaySubject<number>(1);
  }

  getCompanyChangedListener(): Observable<SubscriberEvent<Company>> {
    return this.companyChangedSubject.asObservable();
  }

  companyChanged(event: EntityAction, company: Company) {
    this.companyChangedSubject.next({ event: event, entity: company });
  }
}

export default new CompanyService();
