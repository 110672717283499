import { Col, Descriptions, Row, Skeleton } from 'antd';
import React from 'react';
import { HasButton, HasEditCompanyModal, HasMap, HasTitle, Notification } from '../../../components';
import { Company, UserRole } from '../../../models';
import { CompanyService, EntityAction } from '../../../services';
import { ErrorMessages, SuccessMessages, COLORS } from '../../../shared';
import { hidePhoneNumber, isObjectEmpty, isUserRoleEqualsTo } from '../../../utils';
import i18n from '../../../i18n/config';

interface CompanySettingsProps {
  company: Company;
}

interface CompanySettingsState {
  showEdit: boolean;
}

class HasCompanySettings extends React.Component<CompanySettingsProps, CompanySettingsState> {
  state = { showEdit: false, companyAddress: '' };

  openCompanyEditModal = () => this.setState({ showEdit: true });

  saveCompany = async (company: Company): Promise<null> => {
    try {
      await CompanyService.update(company);
      Notification.success(SuccessMessages.COMPANY_UPDATE);
      this.setState({ showEdit: false });
      CompanyService.companyChanged(EntityAction.REFRESH, company);
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.COMPANY_UPDATE(error.response?.data.message));
      return new Promise((_, reject) => reject());
    }
  };

  render() {
    const { company } = this.props;
    const { showEdit } = this.state;
    const loading = !company || isObjectEmpty(company) || false;
    let hiddenPhoneNumber;
    if (company.phoneNumber) {
      hiddenPhoneNumber = hidePhoneNumber(company.phoneNumber);
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Skeleton loading={loading} active paragraph={false} title={{ width: 200 }}>
            <HasTitle
              content={company.name}
              level={3}
              style={{ margin: 0, fontWeight: 700, color: COLORS.NEW_ORANGE }}
              className="text-break"
            />
          </Skeleton>
          <HasButton type="primary" onClick={this.openCompanyEditModal} disabled={loading}>
            {i18n.t('shared.edit')}
          </HasButton>
        </div>
        <Row style={{ display: 'flex', flexGrow: 1 }} gutter={[10, 0]}>
          <Col span={12}>
            <Skeleton
              loading={loading}
              active
              paragraph={{ rows: 6, width: [150, 100, 150, 100, 150, 100] }}
              title={false}
            >
              <Descriptions layout="vertical" colon={false} column={1} size="small" style={{ paddingLeft: '10px' }}>
                <Descriptions.Item label={i18n.t('shared.domain')} className="text-break">
                  {company.domain}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('shared.phoneNumber')} className="text-break">
                  {isUserRoleEqualsTo(UserRole.OWNER) || isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
                    ? company.phoneNumber
                    : hiddenPhoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('shared.address')} className="text-break">
                  {company.address}
                </Descriptions.Item>
              </Descriptions>
            </Skeleton>
          </Col>
          <Col span={12}>
            <HasMap address={company.address} />
          </Col>
        </Row>
        <HasEditCompanyModal
          company={company}
          visible={showEdit}
          onOk={this.saveCompany}
          onCancel={() => this.setState({ showEdit: false })}
        />
      </div>
    );
  }
}

export default HasCompanySettings;
