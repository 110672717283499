import { User } from '.';
import { Attachment } from './attachment.model';
import { File } from './file';

export interface Review {
  id: number;
  incidentId: number;
  status: ReviewStatus;
  reviewDate: string;
  reviewBy: User;
  reviewContent: string;
  attachments: Attachment[];
  history: string;
}

export type ReviewHistory = {
  reviewDate?: ReviewHistoryEntry<string>;
  reviewBy?: ReviewHistoryEntry<string>;
  execSummary?: ReviewHistoryEntry<string>;
  comment?: ReviewHistoryEntry<string>;
  riskType?: ReviewHistoryEntry<string>;
};

export type ReviewHistoryEntry<T> = Array<{
  value: T;
  createdBy?: User;
  createdAt?: Date;
}>;

export type ReviewContent = {
  execSummary: string;
  comment: string;
  riskType?: string;
};

export enum ReviewStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface ReviewAttachments {
  id: number;
  file: File;
  uploadedAt: string;
  incidentId: number;
}

// HiRE main type

export enum HIRE_PEOPLE {
  ACCESS_AND_EGRESS = 'Access & Egress',
  BURNS = 'Burns',
  ELECTROCUTION = 'Electrocution',
  FALLS_FROM_HEIGHT = 'Falls from Height',
  SUFFOCATION_OR_ASPHYXIATION = 'Suffocation / Asphyxiation',
  MANUAL_HANDLING = 'Manual handling',
  SLIP_TRIP_FALL = 'Slip Trip Fall',
  LONE_WORKING = 'Lone working',
  TRESPASS = 'Trespass',
  CLOSE_WORKING_TO_OTHERS = 'Close working to others',
}

export enum HIRE_ENVIRONMENT {
  INADEQUATE_LIGHTING = 'Inadequate lighting',
  NOISE = 'Noise',
  CONFINED_SPACE = 'Confined space',
  DEEP_WATER = 'Deep water',
  EXPLOSION = 'Explosion',
  GROUND_COLLAPSE_OR_FAILURE = 'Ground Collapse / Failure',
  STRUCTURAL_FAILURE = 'Structural failure',
  NEARBY_OPERATIONS_OR_COMMUNITIES = 'Nearby operations / communities',
  WEATHER_CONDITIONS = 'Weather conditions',
}

export enum HIRE_MATERIALS {
  DUST = 'Dust',
  FIRE_HOT_WORK_FLAMMABLE = 'Fire / Hot Work / Flammable',
  VIBRATION = 'Vibration',
  UNDERGROUND_OVERGROUND_SERVICES = 'Underground / Over ground services',
  HAZARDOUS_SUBSTANCES = 'Hazardous Substances',
  STORED_ENERGY = 'Stored energy',
}

export enum HIRE_EQUIPMENT {
  FAILURE_OF_TOOLS_EQUIPMENT = 'Failure of tools / Equipment',
  FALLING_OBJECTS = 'Falling objects',
  FLYING_OJBECTS = 'Flying objects',
  HIT_BY_VEHICLE = 'Hit by vehicle',
  FAILURE_OF_LIFTING_EQUIPMENT = 'Failure of lifting equipment',
  ENTRAPMENT = 'Entrapment',
  ACCIDENTAL_START_UP = 'Accidental start up',
  STORED_ENERGY = 'Stored energy',
  CLOSE_WORKING_MACHINES = 'Close working Machines',
}

export const HIRE_CATEGORIES = [HIRE_PEOPLE, HIRE_ENVIRONMENT, HIRE_MATERIALS, HIRE_EQUIPMENT];

export const HIRE_CATEGORIES_LABELS = [
  'hire.people.title',
  'hire.environment.title',
  'hire.materials.title',
  'hire.equipment.title',
];
