import { RiskLevel } from '../models';

export const COLORS = {
  PRIMARY_BLUE: '#3b79b6',
  SECONDARY_GREEN: '#429e9d',
  SECONDARY_GREEN_50: '#a0cece',
  //COMPONENTS
  NOTIFICATION_INFO: '#e6f7ff',
  NOTIFICATION_SUCCESS: '#f6ffed',
  NOTIFICATION_WARNING: '#fffbe6',
  NOTIFICATION_ERROR: '#fff1f0',
  SIDE_BAR: '#485465',
  WHITE: '#ffffff',
  BLACK: '#000000',
  BLACK_RGBA: (percent: number) => `rgba(0, 0, 0, ${percent})`,
  GRAY_ACCENT_LIGHT: '#dfdfdf',
  GRAY_ACCENT_DARK: '#9699a2',
  GRAY_WHITE: '#f0f2f5',
  GRAY_LIGHT: '#f9f9f9',
  GRAY: '#666666',
  GRAY_DARKISH: '#888888',
  GRAY_DARK: '#333333',
  GRAY_BLACK: '#35393c',
  GREEN_DARK: '#245c5b',
  ORANGE: '#ff5533',
  BLUE_LIGHT: '#82b1ff',
  BLUE: '#0a47a1',
  ERROR: '#ff4d4f',
  WARNING: '#faad14',
  HIGHLIGHTER: '#ffc069',
  // NEW PALETTE
  NEW_ORANGE: '#FB9630',
  // SVG ICONS DARKER FILL COLOR, NOT USED IN THE APP
  SVG_DARK: '#b5620e',
  GREEN: '#52C41A',
  YELLOW: '#EBB802',
  LIGHT_RED: '#F5222D',
};

export const CHART_COLORS = ['#225079', '#4284c8', '#999999', '#48acf0', '#1d3461', '#493548', '#222222'];
export const DASHBOARD_CHART_COLORS = ['#225079', '#48ACF0', '#4284C8', '#999999', '#6C757D'];
export const LINE_CHART_COLORS = ['#3274d9', '#ff780a', '#7eb26d', '#e24d42', '#705da0'];

export const RISK_SCORE_COLOR = [
  ['#40c057', '#40c057', '#52c41a', '#ffd43b', '#ffd43b'],
  ['#40c057', '#52c41a', '#ffd43b', '#ffd43b', '#faad14'],
  ['#52c41a', '#ffd43b', '#ffd43b', '#faad14', '#ff5533'],
  ['#ffd43b', '#ffd43b', '#faad14', '#ff5533', '#fa5252'],
  ['#ffd43b', '#faad14', '#ff5533', '#fa5252', '#fa5252'],
];

export const RISK_LEVEL_COLOR: Record<RiskLevel, string> = {
  [RiskLevel.VERY_LOW]: '#40c057',
  [RiskLevel.LOW]: '#52c41a',
  [RiskLevel.MEDIUM]: '#ffd43b',
  [RiskLevel.HIGH]: '#faad14',
  [RiskLevel.VERY_HIGH]: '#ff5533',
  [RiskLevel.EXTREME]: '#fa5252',
};

export const COLOR_RISK_LEVEL: Record<string, RiskLevel> = {
  '#40c057': RiskLevel.VERY_LOW,
  '#52c41a': RiskLevel.LOW,
  '#ffd43b': RiskLevel.MEDIUM,
  '#faad14': RiskLevel.HIGH,
  '#ff5533': RiskLevel.VERY_HIGH,
  '#fa5252': RiskLevel.EXTREME,
};
