import { Popover } from 'antd';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { AiOutlineDown } from 'react-icons/ai';
import i18n from '../../../i18n/config';
import { DEFAULT_LANGUAGE, Language, LANGUAGES } from '../../../shared';

interface LanguageFlagPopoverState {
  selectedLanguage: Language;
}

class HasLanguageFlagPopover extends React.Component<{}, LanguageFlagPopoverState> {
  state: LanguageFlagPopoverState = {
    selectedLanguage: DEFAULT_LANGUAGE,
  };

  componentDidMount() {
    this.setState({ selectedLanguage: this.getSelectedLanguage() });
  }

  getSelectedLanguage = () => {
    const selectedLanguage = LANGUAGES.find((langObject) => langObject.id === i18n.language);
    return selectedLanguage ? selectedLanguage : DEFAULT_LANGUAGE;
  };

  languageChanged = (language: Language) => {
    if (language) {
      this.setState({ selectedLanguage: language });
      i18n.changeLanguage(language.id);
    }
  };

  render() {
    const { selectedLanguage } = this.state;
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '12px',
            }}
          >
            {LANGUAGES.map(({ id, name, countryCode }) => (
              <ReactCountryFlag
                countryCode={countryCode}
                key={id}
                onClick={() =>
                  this.languageChanged({
                    id: id,
                    name: name,
                    countryCode: countryCode,
                  })
                }
                svg
                style={{
                  cursor: 'pointer',
                  opacity: selectedLanguage?.countryCode === countryCode ? '1' : '0.3',
                  margin: '2px',
                  fontSize: '20px',
                  outline: '1px solid rgba(0,0,0,0.25)',
                  height: 'unset',
                }}
              />
            ))}
          </div>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <ReactCountryFlag
            countryCode={selectedLanguage?.countryCode}
            svg
            style={{ fontSize: '20px', outline: '1px solid rgba(0,0,0,0.25)', height: 'unset' }}
          />
          <AiOutlineDown style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
        </div>
      </Popover>
    );
  }
}

export default HasLanguageFlagPopover;
