import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Dropdown, Menu } from 'antd';
import { RcCustomRequestOptions, RcFile } from 'antd/lib/upload/interface';
import FileSaver from 'file-saver';
import React, { ReactElement } from 'react';
import i18n from '../../../i18n/config';
import { Attachment, FileIsFor } from '../../../models';
import { EntityAction, PhotoService } from '../../../services';
import { ALLOWED_FILE_TYPES, ErrorMessages, MAX_FILES_PER_UPLOAD, SuccessMessages } from '../../../shared';
import {
  base64ToFile,
  checkFileSize,
  checkFileType,
  getFileSizeError,
  getMediaInfo,
  isObjectEmpty,
} from '../../../utils';
import { HasButton, HasText, Notification } from '../../atoms';
import { HasEmptyState } from '../../molecules';
import { HasAttachmentsList } from '..';

interface AttachmentsProps<T> {
  entity: T;
  photoUploadTargetId: number;
  attachmentUploadTargetId?: number;
  attachments: Attachment[];
}

interface AttachmentsState {
  uploading: boolean;
}

abstract class HasAttachments<T> extends React.Component<AttachmentsProps<T>, AttachmentsState> {
  state = { uploading: false };

  private filesToUpload: File[] = [];

  protected abstract photosAreFor: FileIsFor;
  protected abstract titleStringKey: string;
  protected abstract emptyStateStringKey: string | null;

  protected attachmentUploadTargetId = this.props.attachmentUploadTargetId
    ? this.props.attachmentUploadTargetId
    : this.props.photoUploadTargetId;

  protected abstract getForDownload(id: number): Promise<any>;

  protected abstract uploadAttachment(isPrivate: boolean, formData: FormData): Promise<any>;

  protected abstract setPrivate(attachment: Attachment): Promise<any>;

  protected abstract attachmentsChanged(action: EntityAction, attachment: Attachment): void;

  uploadButtonLabel = () => (this.state.uploading ? i18n.t('attachment.uploading') : i18n.t('attachment.upload'));

  uploadPrivatelyButtonLabel = () =>
    this.state.uploading ? i18n.t('attachment.uploading') : i18n.t('attachment.uploadPrivate');

  checkFile = (file: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > MAX_FILES_PER_UPLOAD) {
      if (!this.filesToUpload.length) {
        Notification.error(ErrorMessages.ATTACHMENT_FILES_LIMIT_EXCEEDED);
        this.filesToUpload = fileList;
      } else {
        if (file === this.filesToUpload.slice(-1)[0]) {
          this.filesToUpload = [];
        }
      }
      return false;
    } else {
      if (!checkFileType(file.type)) {
        Notification.error(ErrorMessages.ATTACHMENT_FILE_TYPE(file.name));
        this.filesToUpload = this.filesToUpload.filter((listFile) => listFile !== file);
        return false;
      } else if (!checkFileSize(file)) {
        Notification.error(getFileSizeError(file));
        this.filesToUpload = this.filesToUpload.filter((listFile) => listFile !== file);
        return false;
      }
      if (!this.filesToUpload.length) {
        this.filesToUpload = fileList;
      }
      return true;
    }
  };

  uploadFile = (options: RcCustomRequestOptions, isPrivate: boolean) => {
    this.attachmentUploadTargetId = this.props.attachmentUploadTargetId
      ? this.props.attachmentUploadTargetId
      : this.props.photoUploadTargetId;
    let formData = new FormData();
    formData.append('files', options.file);
    if (!this.state.uploading) {
      this.setState({ uploading: true });
    }
    if (ALLOWED_FILE_TYPES.Image.includes(options.file.type)) {
      PhotoService.uploadPhoto(this.photosAreFor, this.props.photoUploadTargetId, isPrivate, formData).then(
        (response) => {
          options.onSuccess(response, options.file);
          this.handleUploadSuccessOrError(options.file);
        },
        (error) => {
          options.onError(error);
          this.handleUploadSuccessOrError(options.file, error);
        }
      );
    } else {
      this.uploadAttachment(isPrivate, formData).then(
        (response) => {
          options.onSuccess(response, options.file);
          this.handleUploadSuccessOrError(options.file);
        },
        (error) => {
          options.onError(error);
          this.handleUploadSuccessOrError(options.file, error);
        }
      );
    }
  };

  uploadPublicFile = (options: RcCustomRequestOptions) => {
    this.uploadFile(options, false);
  };

  uploadPrivateFile = (options: RcCustomRequestOptions) => {
    this.uploadFile(options, true);
  };

  handleUploadSuccessOrError = (file: File, error?: any) => {
    if (file === this.filesToUpload.slice(-1)[0]) {
      if (error) {
        Notification.error(ErrorMessages.ATTACHMENT_UPLOAD(error.response.data.message));
      } else {
        this.attachmentsChanged(EntityAction.REFRESH, {} as Attachment);
        Notification.success(SuccessMessages.ATTACHMENT_UPLOAD(this.filesToUpload.length));
      }
      this.setState({ uploading: false });
      this.filesToUpload = [];
    }
  };

  downloadFile = async (attachment: Attachment) => {
    if (attachment.own || !attachment.private) {
      const newFile = (await this.getForDownload(attachment.id)).data;
      const fileToDownload = base64ToFile(newFile.file.fileContent, newFile.file.fileName, newFile.file.fileType);
      FileSaver.saveAs(fileToDownload, newFile.file.fileName);
    }
  };

  deleteFile = (attachment: Attachment) => {
    this.attachmentsChanged(EntityAction.DELETE, attachment);
  };

  changePrivateStatus = (attachment: Attachment) => {
    this.setPrivate(attachment).then(() => {
      Notification.success(SuccessMessages.ATTACHMENT_PRIVATE(!attachment.private));
      this.attachmentsChanged(EntityAction.REFRESH, attachment);
    });
  };

  getPrivateUploadMenu = (buttonType: 'primary' | 'default'): ReactElement => (
    <Menu style={{ padding: '0' }}>
      <Menu.Item style={{ padding: '0' }}>
        <Upload multiple showUploadList={false} beforeUpload={this.checkFile} customRequest={this.uploadPrivateFile}>
          <HasButton loading={this.state.uploading} type={buttonType}>
            {this.uploadPrivatelyButtonLabel()}
          </HasButton>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  render() {
    const { attachments, entity } = this.props;
    const { uploading } = this.state;
    const canUpload = !isObjectEmpty(entity);
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-between align-items-baseline" style={{ paddingBottom: '10px' }}>
          <div className={'ant-descriptions-title'}>{i18n.t(this.titleStringKey)}</div>
          {attachments.length > 0 && (
            <Dropdown overlay={this.getPrivateUploadMenu('default')}>
              <div>
                <Upload
                  multiple
                  showUploadList={false}
                  beforeUpload={this.checkFile}
                  customRequest={this.uploadPublicFile}
                >
                  <HasButton type="default" loading={uploading}>
                    <UploadOutlined
                      style={{ verticalAlign: 'middle', marginBottom: '3px', display: uploading ? 'none' : 'inherit' }}
                    />
                    {this.uploadButtonLabel()}
                  </HasButton>
                </Upload>
              </div>
            </Dropdown>
          )}
        </div>
        <div className="overflow-auto d-flex flex-column flex-grow-1 align-items-center" style={{ margin: '0 -10px' }}>
          {attachments.length > 0 ? (
            <HasAttachmentsList
              attachments={attachments}
              onDelete={this.deleteFile}
              onDownload={this.downloadFile}
              onPrivateChange={this.changePrivateStatus}
            />
          ) : (
            <div className="d-flex h-100 flex-column justify-content-center">
              <HasEmptyState description={false}>
                {canUpload ? (
                  <div className="d-flex flex-column align-items-center">
                    <HasText
                      content={i18n.t('attachment.noFiles')}
                      strong
                      type="secondary"
                      style={{ marginBottom: '8px', fontSize: '14px' }}
                    />
                    <Dropdown overlay={this.getPrivateUploadMenu('primary')}>
                      <div>
                        <Upload
                          multiple
                          showUploadList={false}
                          beforeUpload={this.checkFile}
                          customRequest={this.uploadPublicFile}
                        >
                          <HasButton type="primary" loading={uploading}>
                            <CloudUploadOutlined
                              style={{
                                verticalAlign: 'middle',
                                display: uploading ? 'none' : 'inherit',
                              }}
                            />
                            {this.uploadButtonLabel()}
                          </HasButton>
                        </Upload>
                      </div>
                    </Dropdown>
                  </div>
                ) : (
                  <div style={{ maxWidth: '250px' }}>
                    {this.emptyStateStringKey && (
                      <HasText
                        content={i18n.t(this.emptyStateStringKey)}
                        strong
                        type="secondary"
                        style={{ marginBottom: '8px', fontSize: '14px' }}
                        className="text-break"
                      />
                    )}
                  </div>
                )}
              </HasEmptyState>
            </div>
          )}
        </div>
        {canUpload && getMediaInfo()}
      </React.Fragment>
    );
  }
}

export default HasAttachments;
