import { Layout } from 'antd';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { HasHeader, HasSideBar } from '../components';
import { Routes } from '../routes';
import { isTokenExpired } from '../utils';
import { HasNewReport } from './report';

interface ContainerState {
  isLoggedIn: boolean;
  newReportVisible: boolean;
}

class HasContainer extends React.Component<RouteComponentProps & WithTranslation, ContainerState> {
  state = { isLoggedIn: false, newReportVisible: false };

  componentDidMount() {
    if (this.shouldShowHeader()) {
      this.setState({ isLoggedIn: true });
    }
  }

  componentDidUpdate(prevProps: RouteComponentProps, prevState: ContainerState) {
    if (!prevState.isLoggedIn && this.shouldShowHeader()) {
      this.setState({ isLoggedIn: true });
    }
    if (prevState.isLoggedIn && !this.shouldShowHeader()) {
      this.setState({ isLoggedIn: false, newReportVisible: false });
    }
  }

  shouldShowHeader = () =>
    !['/login', '/user/confirm', '/recover'].includes(this.props.location.pathname) && !isTokenExpired();

  render() {
    const { Content } = Layout;
    const { isLoggedIn, newReportVisible } = this.state;
    return (
      <Layout className="h-100" hasSider={true}>
        {isLoggedIn && (
          <HasSideBar
            sider={Layout.Sider}
            newReportCallback={() => this.setState({ newReportVisible: true })}
            {...this.props}
          />
        )}
        <Layout>
          {isLoggedIn && <HasHeader header={Layout.Header} {...this.props} />}
          <Content style={{ padding: '5px', height: '100%' }}>
            <Routes />
          </Content>
          {isLoggedIn && (
            <HasNewReport onCancel={() => this.setState({ newReportVisible: false })} visible={newReportVisible} />
          )}
        </Layout>
      </Layout>
    );
  }
}

export default HasContainer;
