import { Form } from 'antd';
import en_US from 'antd/lib/calendar/locale/en_US';
import moment from 'moment';
import React from 'react';
import { MdCheck } from 'react-icons/md';
import i18n from '../../../i18n/config';
import { ChatBotCustomComponentProps, COLORS, DATE_TIME_FORMAT, ErrorMessages } from '../../../shared';
import { EventUtils, getDateTime } from '../../../utils';
import { HasButton, HasDatePicker, HasText } from '../../atoms';

interface ChatBotDatePickerState {
  selectedDateTime: string;
  selectedMoment: moment.Moment | undefined;
  canChangeData: boolean;
}

class HasChatBotDatePicker extends React.Component<ChatBotCustomComponentProps, ChatBotDatePickerState> {
  state = {
    selectedDateTime: this.props.value || '',
    selectedMoment: this.props.value ? moment(this.props.value) : undefined,
    canChangeData: true,
  };

  private readonly formItemName = 'date';

  saveDateTime = () => {
    const { step, triggerNextStep } = this.props;
    const { selectedDateTime } = this.state;
    this.setState({ canChangeData: false });
    if (step && triggerNextStep) {
      triggerNextStep({ value: selectedDateTime, trigger: step.metadata.trigger });
    }
  };

  dateChanged = (dateMoment: moment.Moment | null, dateString: string) => {
    dateMoment && this.setState({ selectedDateTime: dateMoment.format(), selectedMoment: dateMoment });
  };

  render() {
    const { selectedDateTime, canChangeData, selectedMoment } = this.state;
    return (
      <div
        style={{ backgroundColor: canChangeData ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
        className="d-flex flex-row justify-content-end has-chat-bot-component"
      >
        {canChangeData ? (
          <Form
            layout="inline"
            hideRequiredMark
            onFinish={() => this.saveDateTime()}
            initialValues={{ [this.formItemName]: selectedMoment }}
          >
            <Form.Item
              name={this.formItemName}
              colon={false}
              rules={[
                {
                  required: true,
                  message: (
                    <HasText
                      content={ErrorMessages.GENERIC_INPUT_REQUIRED}
                      style={{ fontSize: '12px', color: 'inherit' }}
                    />
                  ),
                },
              ]}
            >
              <HasDatePicker
                showTime
                locale={{ ...en_US, lang: { ...en_US.lang, now: i18n.t('shared.now') } }}
                format={DATE_TIME_FORMAT}
                minuteStep={5}
                disabledDate={(date) => !date || date.valueOf() > Date.now()}
                disabledTime={EventUtils.getDisabledTime}
                onChange={this.dateChanged}
                placeholder={i18n.t('chatbot.selectDateTime')}
                style={{ width: '100%' }}
                defaultValue={selectedMoment}
              />
            </Form.Item>
            <Form.Item>
              <HasButton
                type="link"
                htmlType="submit"
                icon={<MdCheck style={{ color: COLORS.SECONDARY_GREEN, fontSize: '20px' }} />}
                style={{ minWidth: 0 }}
              />
            </Form.Item>
          </Form>
        ) : (
          <HasText
            content={getDateTime(selectedDateTime, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
            style={{ color: COLORS.WHITE, fontSize: '12px', fontWeight: 500 }}
          />
        )}
      </div>
    );
  }
}

export default HasChatBotDatePicker;
