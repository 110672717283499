import React from 'react';
import i18n from '../../../i18n/config';
import { HasModal } from '../../atoms';

interface RemovePanelModalProps {
  visible: boolean;
  onOk: () => void;
  onCancel: any;
  deleteLayout?: boolean;
}

class HasRemovePanelModal extends React.Component<RemovePanelModalProps> {
  onCancel = () => {
    this.props.onCancel();
  };

  onOk = () => {
    this.props.onOk();
  };

  render() {
    const { visible, deleteLayout } = this.props;

    return (
      <HasModal
        title={deleteLayout ? i18n.t('charts.deleteDashboard') : i18n.t('charts.approveRemovePanel')}
        okText={i18n.t('shared.yes')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        onOk={this.onOk}
        closable={false}
        visible={visible}
        destroyOnClose
      ></HasModal>
    );
  }
}

export default HasRemovePanelModal;
