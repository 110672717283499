import { GoogleMap, GoogleMapProps, HeatmapLayer, Marker } from '@react-google-maps/api';
import { isEqual } from 'lodash';
import React, { ReactNode } from 'react';
import { MapsContext } from '../../../App';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, ErrorMessages, Location, MAX_ZOOM, MIN_ZOOM } from '../../../shared';
import { isObjectEmpty } from '../../../utils';
import { HasSpinner, Notification } from '../../atoms';

interface HasMapProps extends GoogleMapProps {
  markerLocations?: Location[];
  heatmapLocations?: Location[];
  zoom?: number;
  width?: string;
  height?: string;
  center?: google.maps.LatLngLiteral;
  address?: string;
  onMapClick?: (e: google.maps.MouseEvent) => void;
}

interface HasMapState {
  mapRef: google.maps.Map | null;
  mapLoaded: boolean;
  addressCoordinates: google.maps.LatLng;
}

class HasMap extends React.PureComponent<HasMapProps, HasMapState> {
  state = { mapRef: {} as google.maps.Map, mapLoaded: false, addressCoordinates: {} as google.maps.LatLng };

  private geocoder: google.maps.Geocoder = new google.maps.Geocoder();

  componentDidMount() {
    if (this.props.address) {
      this.getAddressCoordinates(this.props.address);
    }
  }

  componentDidUpdate(prevProps: Readonly<HasMapProps>, prevState: HasMapState) {
    const fitBoundsCallback = () => {
      if (this.state.mapRef !== null) {
        this.fitBounds(this.state.mapRef);
      }
    };
    if (!isEqual(prevProps, this.props)) {
      if (this.props.address) {
        this.getAddressCoordinates(this.props.address);
      }
      fitBoundsCallback();
    }
    if (prevState.addressCoordinates !== this.state.addressCoordinates) {
      fitBoundsCallback();
    }
  }

  handleGeocodeResults = (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
    const { OK, ZERO_RESULTS, OVER_QUERY_LIMIT, REQUEST_DENIED, INVALID_REQUEST, UNKNOWN_ERROR, ERROR } =
      google.maps.GeocoderStatus;
    switch (status) {
      case OK:
        if (results.every((result) => result.partial_match)) {
          this.setState({ addressCoordinates: results[0].geometry.location });
          Notification.warning(ErrorMessages.MAPS_PARTIAL_GEOCODER_RESULTS('' + this.props.address));
        } else {
          this.setState({ addressCoordinates: results[0].geometry.location });
        }
        break;
      case ZERO_RESULTS:
        Notification.error(ErrorMessages.MAPS_INVALID_GEOCODER_ADDRESS('' + this.props.address));
        this.setState({ addressCoordinates: {} as google.maps.LatLng });
        break;
      case INVALID_REQUEST:
        Notification.error(ErrorMessages.MAPS_INVALID_GEOCODER_ADDRESS('' + this.props.address));
        break;
      case OVER_QUERY_LIMIT:
      case REQUEST_DENIED:
      case UNKNOWN_ERROR:
      case ERROR:
        Notification.error(ErrorMessages.MAPS_GEOCODER_ERROR(''));
        break;
      default:
        break;
    }
  };

  getAddressCoordinates = (address: string) => {
    this.geocoder.geocode({ address: address, region: 'GB' }, (results, status) =>
      this.handleGeocodeResults(results, status)
    );
  };

  fitBounds = (map: google.maps.Map) => {
    const { heatmapLocations, markerLocations, address } = this.props;
    const bounds = new window.google.maps.LatLngBounds();
    let shouldFit = false;
    if (heatmapLocations && heatmapLocations.length) {
      heatmapLocations.forEach((location) => bounds.extend(location));
      shouldFit = true;
    }
    if (markerLocations) {
      markerLocations.forEach((location) => bounds.extend(location));
      shouldFit = true;
    }
    if (address) {
      if (!isObjectEmpty(this.state.addressCoordinates)) {
        bounds.extend(this.state.addressCoordinates);
        shouldFit = true;
      }
    }
    shouldFit && map.fitBounds(bounds);
  };

  mapLoadHandler = (map: google.maps.Map) => {
    this.setState({ mapRef: map });
    this.fitBounds(map);
  };

  getGoogleMapsLocations = (locations: Location[]): google.maps.LatLng[] =>
    locations.map((location) => new google.maps.LatLng(location));

  buildMapMarkers = (): ReactNode[] | null => {
    if (this.props.markerLocations) {
      return this.props.markerLocations.map((location, index) => <Marker key={index} position={location} />);
    } else {
      return null;
    }
  };

  buildAddressMarker = (): ReactNode | null => {
    return !isObjectEmpty(this.state.addressCoordinates) ? <Marker position={this.state.addressCoordinates} /> : null;
  };

  buildHeatmapLayer = (): ReactNode | null => {
    if (this.props.heatmapLocations) {
      return <HeatmapLayer data={this.getGoogleMapsLocations(this.props.heatmapLocations)} />;
    } else {
      return null;
    }
  };

  handleZoom = () => {
    if (this.state.mapRef.getZoom && this.state.mapRef.getZoom() > MAX_ZOOM) {
      this.state.mapRef.setZoom(this.props.zoom ? this.props.zoom : MAX_ZOOM);
    }
  };

  handleMapClick = (event: google.maps.MouseEvent) => {
    const { onMapClick } = this.props;
    if (onMapClick) {
      onMapClick(event);
    }
  };

  render() {
    const { width, height, zoom, center } = this.props;
    return (
      <MapsContext.Consumer>
        {(scriptLoaded) =>
          scriptLoaded ? (
            <div
              className="d-flex"
              style={{ width: width ? width : '100%', height: height ? height : '100%', borderRadius: '10px' }}
            >
              <GoogleMap
                onLoad={this.mapLoadHandler}
                options={{
                  minZoom: MIN_ZOOM,
                  maxZoom: MAX_ZOOM,
                }}
                mapContainerStyle={{
                  width: width ? width : '100%',
                  height: height ? height : '100%',
                }}
                onZoomChanged={this.handleZoom}
                onClick={this.handleMapClick}
                zoom={zoom ? zoom : DEFAULT_MAP_ZOOM}
                center={center ? center : DEFAULT_MAP_CENTER}
              >
                {this.buildMapMarkers()}
                {this.buildAddressMarker()}
                {scriptLoaded && this.buildHeatmapLayer()}
              </GoogleMap>
            </div>
          ) : (
            <HasSpinner size="large" />
          )
        }
      </MapsContext.Consumer>
    );
  }
}

export default HasMap;
