import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import i18n from '../../../i18n/config';
import { Company, Division } from '../../../models';
import divisionService from '../../../services/division.service';
import { ErrorMessages, ModalBaseProps } from '../../../shared';
import { HasDropdown, HasModal, HasTextInput } from '../../atoms';

interface DivisionModalProps extends ModalBaseProps<Division> {
  isCreate: boolean;
  division: Division;
  company: Company;
}

interface DivisionModalState {
  updateInProgress: boolean;
  allDivisions: Array<Division>;
}

class HasDivisionModal extends React.Component<DivisionModalProps, DivisionModalState> {
  state = { updateInProgress: false, allDivisions: [] };

  private formRef = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<DivisionModalProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ updateInProgress: false });
    }
    if (this.props.company && !isEqual(prevProps, this.props)) {
      divisionService.getDivisionsForCompany(this.props.company.id).then((response) => {
        this.setState({ allDivisions: response.data });
      });
    }
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    const { name, parentId } = values;
    const updatedDivision: Division = { ...this.props.division, name };

    if (parentId !== undefined) {
      updatedDivision.parentId = parentId;
    } else {
      updatedDivision.parentId = this.props.division.parentId;
    }
    this.props.onOk(updatedDivision).then(
      () => {
        this.setState({ updateInProgress: false });
        this.formRef.current?.resetFields();
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  filteredDivisions = (division: Division, arrayId: any[] = []) => {
    if (!division) {
      return null;
    }

    arrayId.push(division.id);

    if (division.subDivisions) {
      division.subDivisions.forEach((subDivision) => this.filteredDivisions(subDivision, arrayId));
    }

    return this.state.allDivisions.filter(
      (div: Division) => !arrayId.includes(div.id) && !division.deleted && div.id !== division.id
    );
  };

  render() {
    const { division, isCreate, visible } = this.props;
    return (
      <HasModal
        title={isCreate ? i18n.t('company.addDivision') : i18n.t('company.editDivision')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'division-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
        destroyOnClose
      >
        <Form
          id="division-form"
          ref={this.formRef}
          onFinish={this.onOk}
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{
            name: isCreate ? null : division.name,
            parent: !isCreate && division.parentId ? division.parentId : null,
          }}
        >
          <Form.Item
            name="name"
            label={i18n.t('company.divisionName')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('company.divisionName').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('company.divisionName').toLowerCase()) },
            ]}
          >
            <HasTextInput key="name" size="large" />
          </Form.Item>
          <Form.Item
            name="parentId"
            label={i18n.t('division.parentDivision', 'Parent division (optional)')}
            colon={false}
            getValueFromEvent={(value) => (value === undefined ? null : value)}
          >
            <HasDropdown<Division>
              allowClear
              size="large"
              style={{ fontSize: '14px' }}
              placeholder={i18n.t('division.selectParentDivision', 'Select a parent division')}
              defaultValue={division?.parentId ? division?.parentId : undefined}
              options={this.filteredDivisions(division)?.map((division: Division) => ({
                value: division.id,
                label: division.name,
                forObject: division,
              }))}
            />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasDivisionModal;
