import { Tabs } from 'antd';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { HasIncidentsTable } from '../../components';
import i18n from '../../i18n/config';
import { formatTextToTitlecase, getUrlQueryParam } from '../../utils';
import HasIncidentCsvExportWizard from './incident.csv';

interface IncidentListPageProps extends RouteComponentProps<{ csv: string }>, WithTranslation {}

interface IncidentListPageState {
  activeTab: string;
}

const TABLE_KEY = 'table';
const CSV_TABLE_KEY = 'csv_table';
const TYPE_QUERY_PARAM = 'type';

class HasIncidentListPage extends React.Component<IncidentListPageProps, IncidentListPageState> {
  state: IncidentListPageState = { activeTab: TABLE_KEY };

  navigateToIncident = (incidentId: number) => this.props.history.push(`events/event/${incidentId}`);
  navigateToAction = (incidentId: number) => this.props.history.push(`events/action/${incidentId}`);
  navigateToInvestigation = (incidentId: number) => this.props.history.push(`events/investigation/${incidentId}`);

  render() {
    const {
      location: { search },
    } = this.props;
    const { activeTab } = this.state;
    const { TabPane } = Tabs;
    return (
      <div className="shadow-sm has-incidents-table-height overflow-auto has-container-tile d-flex flex-column">
        <Tabs activeKey={activeTab} renderTabBar={() => <></>} className="d-flex flex-column flex-grow-1">
          <TabPane
            tab={formatTextToTitlecase(i18n.t('incidents.event_plural'))}
            key={TABLE_KEY}
            className="d-flex flex-column flex-grow-1"
          >
            <HasIncidentsTable
              exportCsvCallback={() => this.setState({ activeTab: CSV_TABLE_KEY })}
              navigateToAction={this.navigateToAction}
              navigateToIncident={this.navigateToIncident}
              navigateToInvestigation={this.navigateToInvestigation}
              urlQueryParam={getUrlQueryParam(search, TYPE_QUERY_PARAM)}
            />
          </TabPane>
          <TabPane
            tab={i18n.t('incidents.eventsExport')}
            key={CSV_TABLE_KEY}
            className="d-flex flex-column flex-grow-1"
          >
            <HasIncidentCsvExportWizard backToTableCallback={() => this.setState({ activeTab: TABLE_KEY })} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(HasIncidentListPage);
