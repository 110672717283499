import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';

class HasButton extends React.Component<ButtonProps, {}> {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>;
  }
}

export default HasButton;
