import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { Action } from '../models/action.model';
import { SubscriberEvent, TableFilters } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';
import { PaginationService } from '.';
import { Pagination } from './pagination.service';
import { Page } from '../models';

class ActionService implements RestService<Action> {
  private actionChangedSubject = new Subject<SubscriberEvent<Action>>();

  get(params?: any): Promise<AxiosResponse<Action>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<Action[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Action>> {
    throw new Error('Method not implemented.');
  }

  post(body?: any, params?: any): Promise<AxiosResponse<Action>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<Action>> {
    throw new Error('Method not implemented.');
  }

  create(resource: Action): Promise<AxiosResponse<Action>> {
    return httpClient.post<Action>(URL.ACTION, resource);
  }

  update(resource: Action): Promise<AxiosResponse<Action>> {
    return httpClient.put<Action>(URL.ACTION, resource);
  }

  assign(resource: Action): Promise<AxiosResponse<Action>> {
    return httpClient.put<Action>(buildUrl(URL.ACTION, ['assign']), resource);
  }

  changeStatus(resource: Action): Promise<AxiosResponse<Action>> {
    return httpClient.put<Action>(buildUrl(URL.ACTION, ['changeStatus']), resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<Action>> {
    return httpClient.delete<Action>(buildUrl(URL.ACTION, [resourceId]));
  }

  getCompanyActions(pagination: Pagination): Promise<AxiosResponse<Page<Action>>> {
    let params = {};
    params = {
      pageNumber: pagination.pageNumber ? pagination.pageNumber - 1 : 0,
      pageSize: pagination.pageSize,
    };
    return httpClient.get<Page<Action>>(buildUrl(URL.ACTION, ['allc']), { params: params });
  }

  getByIncidentId(incidentId: string): Promise<AxiosResponse<Action[]>> {
    return httpClient.get<Action[]>(buildUrl(URL.ACTION, ['byIncident', incidentId]));
  }

  getAllForCompanyAndDivision(): Promise<AxiosResponse<Action[]>> {
    return httpClient.get<Action[]>(buildUrl(URL.ACTION, ['allForCompanyAndDivision']));
  }

  getAllPaginatedAndFiltered(filters: TableFilters): Promise<AxiosResponse<Page<Action>>> {
    return httpClient.post<Page<Action>>(
      buildUrl(URL.ACTION, ['paginated']),
      PaginationService.createFilters(filters),
      {
        params: PaginationService.createPagination(filters),
      }
    );
  }

  getDirectCsv(filters: TableFilters): Promise<AxiosResponse<string>> {
    const createdFilters = PaginationService.createFilters(filters);
    return httpClient.post<string>(buildUrl(URL.ACTION, ['export']), createdFilters, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  // SUBSCRIPTIONS

  actionChanged(event: EntityAction, action: Action) {
    this.actionChangedSubject.next({ event: event, entity: action });
  }

  getActionChangedListener(): Observable<SubscriberEvent<Action>> {
    return this.actionChangedSubject.asObservable();
  }
}

export default new ActionService();
