import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Popconfirm, Skeleton } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { HasAddPhoneModal, HasButton, HasTable, HasText, HasTextInput, Notification } from '../../../components';
import i18n from '../../../i18n/config';
import { Company, NewPhoneNumber, PhoneNumber, UserRole } from '../../../models';
import { EntityAction, PhoneService } from '../../../services';
import { ConfirmMessages, ErrorMessages, SORT_DIRECTIONS, SuccessMessages } from '../../../shared';
import { comparatorFn, getDate, getUserFullName, hidePhoneNumber, isUserRoleEqualsTo } from '../../../utils';

interface PhonesSettingsProps {
  phoneNumbers: PhoneNumber[];
  company: Company;
  loading: boolean;
}

interface PhonesSettingsState {
  searchTerm: string;
  showAddModal: boolean;
  phoneNumbers: PhoneNumber[];
}

const TABLE_ITEM_HEIGHT = 55;
const TABLE_HEADER_HEIGHT = 55;

class HasPhonesSettings extends React.Component<PhonesSettingsProps, PhonesSettingsState> {
  state: PhonesSettingsState = { searchTerm: '', phoneNumbers: this.props.phoneNumbers, showAddModal: false };

  componentDidUpdate(prevProps: Readonly<PhonesSettingsProps>, prevState: PhonesSettingsState) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ phoneNumbers: this.props.phoneNumbers });
    }
  }

  getColumns = (): ColumnProps<PhoneNumber>[] => [
    {
      title: i18n.t('shared.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: (a: PhoneNumber, b: PhoneNumber) => comparatorFn<string>(a.phoneNumber, b.phoneNumber),
      render: (_: any, { phoneNumber }: PhoneNumber) => this.formatPhoneNumber(phoneNumber),
    },
    {
      title: i18n.t('shared.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      sorter: (a: PhoneNumber, b: PhoneNumber) => comparatorFn<string>(a.name, b.name),
    },
    {
      title: i18n.t('settings.allowedOn'),
      key: 'addedOn',
      align: 'center',
      render: (_, record: PhoneNumber) => getDate(record.addedOn),
    },
    {
      title: i18n.t('settings.allowedBy'),
      key: 'invitedBy',
      align: 'center',
      render: (_, { invitedBy }: PhoneNumber) => getUserFullName(invitedBy),
    },
    {
      title: i18n.t('settings.usedBy'),
      key: 'usedBy',
      align: 'center',
      render: (_, { usedBy }: PhoneNumber) => (usedBy ? getUserFullName(usedBy) : '-'),
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      align: 'center',
      render: (_, record: PhoneNumber) => (
        <Popconfirm
          title={ConfirmMessages.DELETE_ENTITY(i18n.t('nounsWithArticle.phoneNumber'))}
          okText={i18n.t('shared.yes')}
          cancelText={i18n.t('shared.no')}
          onConfirm={() => this.deletePhoneNumber(record)}
          placement="topLeft"
          style={{ maxWidth: '200px' }}
        >
          <MdDelete style={{ fontSize: '18px' }} className="icon-hover danger" />
        </Popconfirm>
      ),
    },
  ];

  addPhoneNumber = async (phoneNumber: NewPhoneNumber): Promise<null> => {
    try {
      phoneNumber.company = this.props.company;
      await PhoneService.newPhoneNumber(phoneNumber);
      Notification.success(SuccessMessages.PHONE_ADDED(phoneNumber.phoneNumber));
      this.setState({ showAddModal: false });
      PhoneService.phoneNumbersChanged(EntityAction.REFRESH, {} as PhoneNumber);
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.PHONE_ADDED(error.response?.data.message));
      return new Promise((_, reject) => reject());
    }
  };

  deletePhoneNumber = (phone: PhoneNumber) => {
    PhoneService.delete(phone.id).then(
      () => {
        Notification.success(SuccessMessages.PHONE_DELETE(phone.phoneNumber));
        PhoneService.phoneNumbersChanged(EntityAction.REFRESH, {} as PhoneNumber);
      },
      (error) => Notification.error(ErrorMessages.PHONE_DELETE(error.response?.data.message))
    );
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLocaleLowerCase();
    if (searchTerm === '') {
      this.setState({ searchTerm: '', phoneNumbers: this.props.phoneNumbers });
    } else {
      let filteredPhones = [] as PhoneNumber[];
      this.props.phoneNumbers.forEach((phone) => {
        if (
          phone.phoneNumber.toLocaleLowerCase().includes(searchTerm) ||
          phone.name.toLocaleLowerCase().includes(searchTerm) ||
          getUserFullName(phone.invitedBy).toLocaleLowerCase().includes(searchTerm) ||
          (phone.usedBy && getUserFullName(phone.usedBy).toLocaleLowerCase().includes(searchTerm))
        ) {
          filteredPhones.push(phone);
        }
      });
      this.setState({ searchTerm: searchTerm, phoneNumbers: filteredPhones });
    }
  };

  formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) {
      return '-';
    }
    return !isUserRoleEqualsTo(UserRole.OWNER) && !isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
      ? hidePhoneNumber(phoneNumber)
      : phoneNumber;
  };

  render() {
    const { loading } = this.props;
    const { showAddModal, phoneNumbers } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HasTextInput
              prefix={<SearchOutlined />}
              placeholder={i18n.t('shared.search')}
              onChange={(e) => this.handleSearch(e)}
              style={{ maxWidth: '180px', marginRight: '20px' }}
              size="large"
            />
            <Skeleton loading={false} active paragraph={false} title={{ width: '150px' }}>
              <HasText
                content={i18n.t('dataDisplay.displayingResults', { count: phoneNumbers.length })}
                style={{ fontSize: '12px' }}
              />
            </Skeleton>
          </div>
          <HasButton
            onClick={() => this.setState({ showAddModal: true })}
            type="primary"
            disabled={loading}
            icon={<PlusOutlined />}
            style={{ minWidth: 0 }}
          />
        </div>
        <HasTable<PhoneNumber>
          rowKey="id"
          data={phoneNumbers}
          columns={this.getColumns()}
          loading={loading}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          useScroll
        />
        <HasAddPhoneModal
          visible={showAddModal}
          onOk={this.addPhoneNumber}
          onCancel={() => this.setState({ showAddModal: false })}
        />
      </div>
    );
  }
}

export default HasPhonesSettings;
