import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { HasButton, HasParagraph, HasRadioGroup } from '../../..';
import i18n from '../../../../i18n/config';
import {
  CausesMap,
  CausesType,
  DELIMITER,
  i18nRadioGroupWrapper,
  IncidentCausesMap,
  IncidentCausesType,
  IncidentSubCausesMap,
  RadioGroupOption,
  SubCausesMap,
  TypedRadioChangeEvent,
  TypedRadioGroupProps,
} from '../../../../shared';

interface CauseRadioGroupProps {
  save: any;
  changeCallback: any;
  closeEditModeCallback?: any;
  isInvestigationCause: boolean;
}

interface CauseRadioGroupState {
  radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<CausesType | IncidentCausesType>>[];
  subRadioOptionsConfig: RadioGroupOption<string>[];
  selectedCause?: i18nRadioGroupWrapper<CausesType | IncidentCausesType>;
  selectedSubCause: string;
}

class HasCauseRadioGroup extends React.Component<CauseRadioGroupProps, CauseRadioGroupState> {
  private radioGroupConfig: TypedRadioGroupProps<i18nRadioGroupWrapper<CausesType | IncidentCausesType>>;
  private subRadioGroupConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<CauseRadioGroupProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onCauseChange,
    };

    this.subRadioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      style: { marginBottom: '8px' },
      onChange: this.onSubCauseChange,
    };

    const radioOptionsConfig: RadioGroupOption<i18nRadioGroupWrapper<CausesType | IncidentCausesType>>[] = [];
    if (this.props.isInvestigationCause) {
      CausesMap.forEach(({ value, label }) => {
        const radioValue = { enumValue: value, i18nKey: label };
        radioOptionsConfig.push({
          checked:
            this.state && !isEmpty(this.state.selectedCause) ? isEqual(this.state.selectedCause, radioValue) : false,
          value: radioValue,
          label: i18n.t(label),
        });
      });
    } else {
      IncidentCausesMap.forEach(({ value, label }) => {
        const radioValue = { enumValue: value, i18nKey: label };
        radioOptionsConfig.push({
          checked:
            this.state && !isEmpty(this.state.selectedCause) ? isEqual(this.state.selectedCause, radioValue) : false,
          value: radioValue,
          label: i18n.t(label),
        });
      });
    }

    this.state = {
      selectedCause: {} as i18nRadioGroupWrapper<CausesType | IncidentCausesType>,
      selectedSubCause: '',
      radioOptionsConfig,
      subRadioOptionsConfig: [],
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () => this.handleLanguageChanged());
  }

  handleLanguageChanged = () => {
    const { selectedCause } = this.state;
    this.buildRadioOptionsConfig();
    if (selectedCause) {
      this.buildSubRadioOptionsConfig();
    }
  };

  buildRadioOptionsConfig = (): void => {
    this.setState((prevState: Readonly<CauseRadioGroupState>) => {
      let radioOptionsConfig = prevState.radioOptionsConfig;
      radioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value.i18nKey)));
      return { ...prevState, radioOptionsConfig };
    });
  };

  buildSubRadioOptionsConfig = (): void => {
    this.setState((prevState: Readonly<CauseRadioGroupState>) => {
      let subRadioOptionsConfig = prevState.subRadioOptionsConfig;
      subRadioOptionsConfig.forEach((config) => (config.label = i18n.t(config.value)));
      return { ...prevState, subRadioOptionsConfig };
    });
  };

  onCauseChange = (event: TypedRadioChangeEvent<i18nRadioGroupWrapper<CausesType | IncidentCausesType>>) => {
    let subCauses = Object.keys(event.value.enumValue).filter((key) => !+key && key !== '0');
    const subRadioOptionsConfig = subCauses.map((subCause) => {
      let subCauseData;
      if (this.props.isInvestigationCause) {
        subCauseData = SubCausesMap.get(subCause);
      } else {
        subCauseData = IncidentSubCausesMap.get(subCause);
      }
      if (subCauseData) {
        return {
          checked: false,
          defaultChecked: false,
          value: subCauseData,
          label: i18n.t(subCauseData),
        };
      } else {
        return { checked: false, defaultChecked: false, value: 'Unknown', label: i18n.t('dataDisplay.unknownValue') };
      }
    });

    this.props.changeCallback();
    this.setState({
      selectedCause: event.value,
      selectedSubCause: '',
      subRadioOptionsConfig,
    });
  };

  onSubCauseChange = (event: any) => {
    this.props.changeCallback();
    this.setState({ selectedSubCause: event.target.value });
  };

  saveData = () => {
    const { selectedCause, selectedSubCause } = this.state;
    if (selectedCause) {
      let answer = selectedCause?.i18nKey;
      answer = answer.concat(DELIMITER).concat(',').concat(DELIMITER).concat(selectedSubCause);
      this.props.save(answer);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  componentWillUnmount() {
    i18n.off('languageChanged', () => this.handleLanguageChanged());
  }

  render() {
    const { selectedCause, selectedSubCause, radioOptionsConfig, subRadioOptionsConfig } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup {...this.radioGroupConfig} groupOptions={radioOptionsConfig} />
        {!isEmpty(selectedCause) ? (
          <React.Fragment>
            <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
            <HasRadioGroup
              {...this.subRadioGroupConfig}
              groupOptions={subRadioOptionsConfig}
              value={selectedSubCause}
            />
          </React.Fragment>
        ) : null}
        {selectedCause && selectedSubCause && (
          <HasButton
            type="primary"
            size="small"
            htmlType="button"
            onClick={this.saveData}
            style={{ marginTop: '8px', alignSelf: 'flex-end' }}
          >
            {i18n.t('shared.save')}
          </HasButton>
        )}
      </div>
    );
  }
}

export default HasCauseRadioGroup;
