import { SolutionOutlined } from '@ant-design/icons';
import { Divider, Steps } from 'antd';
import { StepProps } from 'antd/lib/steps';
import React, { ReactNode } from 'react';
import {
  HasAvatar,
  HasCauseRadioGroup,
  HasComment,
  HasComplexRadioGroup,
  HasDatePickerForm,
  HasDivisionPickerForm,
  HasIncidentFormEntries,
  HasInputsForm,
  HasLocationRadioGroup,
  HasReasonRadioGroup,
  HasSimpleRadioGroup,
  HasText,
  HasTitle,
  Notification,
} from '../..';
import i18n from '../../../i18n/config';
import {
  AuditType,
  AuditTypeLabel,
  FormResult,
  FormResultType,
  Incident,
  IncidentHarmOrPropertyDamage,
  IncidentHarmOrPropertyDamageLabel,
  IncidentHighPotentialScenarioType,
  IncidentHighPotentialScenarioTypeLabel,
  IncidentInvolvedPersonType,
  IncidentInvolvedPersonTypeLabel,
} from '../../../models';
import { TranslationService } from '../../../services';
import {
  COLORS,
  ComplexRadioOption,
  DELIMITER,
  ErrorMessages,
  FORM_RESULT_KEYS,
  IGNORED_KEYS,
  IGNORED_TYPES,
  IncidentCausesMap,
  IncidentReasonsMap,
  IncidentSubCausesMap,
  IncidentSubReasonsMap,
} from '../../../shared';
import { Prompt } from 'react-router-dom';
import { EventUtils, getFullReasonOrCauseKeys, getUserId } from '../../../utils';
import moment from 'moment';
import { isUndefined } from 'lodash';

interface IncidentReportProps {
  incident: Incident;
  questionLabels: Record<string, string>;
  fieldChangedCallback: (incident: Incident) => void;
}

interface IncidentReportState {
  translationResult: { [k: number]: string };
  resultIdsTranslated: number[];
  error: boolean;
}

class HasIncidentReport extends React.Component<IncidentReportProps, IncidentReportState> {
  state: IncidentReportState = {
    translationResult: {},
    resultIdsTranslated: [],
    error: false,
  };

  private newBaseEntry = {
    userId: getUserId(),
  };

  private hasUnsavedData: Record<string, boolean> = {
    // Incident & NearHit
    WHEN_HAPPEN: false,
    ORG_SELECTION: false,
    WHERE_DID_IT_HAPPEN: false,
    LOCATION_INFO: false,
    PARTIES_INVOLVED: false,
    REPORTING_PERSON_IS_VICTIM: false,
    INVOLVED_PERSON_TYPE: false,
    WHAT_HAPPENED_DETAILS: false,
    HIGH_POTENTIAL_SCENARIO: false,
    COULD_RESULT_IN_THREAT_OF_LIFE_OR_LIFE_CHANGING_INJURIES: false,
    WAS_THERE_POTENTIAL_HARM_OR_PROPERTY_DAMAGE: false,
    IS_STILL_UNSAFE: false,
    COULD_RESULT_IN_TIME_OFF_WORK_OR_DOCTOR_TREATMENT: false,
    WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED: false,
    PRELIMINARY_REASON: false,
    WHAT_DO_YOU_BELIEVE_IS_THE_INITIAL_ROOT_CAUSE_THOUGHT: false,
    // Audit
    TYPE: false,
    // TBox & SafetyConv
    SUBJECT: false,
    DISCUSSION_TRANSCRIPT: false,
    // HiRE
    HAZARD_DESCRIPTION: false,
    WHAT_HAVE_YOU_DONE: false,
    IS_SITUATION_SAFE: false,
  };

  getQuestionLabel = (result: FormResult): string => {
    const { questionLabels } = this.props;
    const label = questionLabels[result.key] ? questionLabels[result.key] : result.key;
    return i18n.t(label).toLocaleUpperCase('en-GB');
  };

  handleTranslationForEntry = (id?: number) => {
    const { formResult } = this.props.incident;
    if (id) {
      if (this.state.resultIdsTranslated.includes(id)) {
        this.setState((previousState) => {
          delete previousState.translationResult[id];
          return {
            ...previousState,
            resultIdsTranslated: previousState.resultIdsTranslated.filter((existingId) => existingId !== id),
          };
        });
      } else {
        let translation = '';
        let textToTranslate = formResult.find((result) => result.id === id)?.value;
        if (formResult.find((result) => result.id === id)?.type === FormResultType.OPTION_DESCRIPTION) {
          textToTranslate = formResult.find((result) => result.id === id)?.extra;
        }

        TranslationService.translate({ textToTranslate: textToTranslate }).then((result) => {
          translation = result.data.translatedText!;
          this.setState((previousState) => {
            previousState.resultIdsTranslated.push(id);
            return {
              ...previousState,
              translationResult: { ...previousState.translationResult, [id]: translation },
            };
          });
        });
      }
    }
  };

  editToggledOnEntry = (entry: string, wasEditActive: boolean) => {
    if (this.hasUnsavedData.hasOwnProperty(entry)) {
      if (wasEditActive && this.hasUnsavedData[entry]) {
        const response = window.confirm(ErrorMessages.INVESTIGATION_UNSAVED_FORM_EDIT_TOGGLE);
        if (response) {
          this.hasUnsavedData[entry] = false;
        }
        return response;
      } else {
        return true;
      }
    }
  };

  getErrorMessage = (data: any) => {
    if (isUndefined(data)) {
      Notification.error(
        ErrorMessages.UNEXPECTED_ERROR(i18n.t('errors.formResultInvalid', 'Invalid form result value'))
      );
      return;
    }
  };

  onEntrySave = (field: string, data: any) => {
    if (isUndefined(data)) {
      Notification.error(
        ErrorMessages.UNEXPECTED_ERROR(i18n.t('errors.formResultInvalid', 'Invalid form result value'))
      );
      return;
    }
    let incident = { ...this.props.incident };
    const { formResult } = this.props.incident;
    const newFormResults = [...formResult];
    switch (field) {
      case FORM_RESULT_KEYS.REPORTING_PERSON_IS_VICTIM:
        const latestReportingPerson = EventUtils.getLatestFormResultByKey(formResult, field);
        const newReportingPerson: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestReportingPerson.maxItem.seqNo + 1,
          key: field,
          value: data.option ? 'true' : 'false',
          type: latestReportingPerson.maxItem.type,
          investigationRequired: latestReportingPerson.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestReportingPerson.maxIndex + 1, 0, newReportingPerson);
        const latestVictimFirstName = EventUtils.getLatestFormResultByKey(
          newFormResults,
          FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME
        );
        const newVictimFirstName: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestVictimFirstName.maxItem.seqNo + 1,
          key: FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME,
          value: data.firstInput || incident.reportingUser.name,
          type: FormResultType.TEXT,
          investigationRequired: latestReportingPerson.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(
          latestVictimFirstName.maxIndex !== -1
            ? latestVictimFirstName.maxIndex + 1
            : latestReportingPerson.maxIndex + 2,
          0,
          newVictimFirstName
        );
        const latestVictimLastName = EventUtils.getLatestFormResultByKey(
          newFormResults,
          FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME
        );
        const newVictimLastName: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestVictimLastName.maxItem.seqNo + 1,
          key: FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME,
          value: data.secondInput || incident.reportingUser.name,
          type: FormResultType.TEXT,
          investigationRequired: latestReportingPerson.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(
          latestVictimLastName.maxIndex !== -1 ? latestVictimLastName.maxIndex + 1 : latestReportingPerson.maxIndex + 3,
          0,
          newVictimLastName
        );
        break;
      case FORM_RESULT_KEYS.REASON:
        const reasonKeys = getFullReasonOrCauseKeys(data, IncidentReasonsMap, IncidentSubReasonsMap);
        if (isUndefined(reasonKeys.key) || isUndefined(reasonKeys.subKey)) {
          Notification.error(
            ErrorMessages.UNEXPECTED_ERROR(i18n.t('errors.formResultInvalid', 'Invalid form result value'))
          );
          return;
        }
        const latestReason = EventUtils.getLatestFormResultByKey(formResult, field);
        const newReason: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestReason.maxItem.seqNo + 1,
          key: field,
          value: reasonKeys.key,
          type: latestReason.maxItem.type,
          investigationRequired: latestReason.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestReason.maxIndex + 1, 0, newReason);
        const latestSubReason = EventUtils.getLatestFormResultByKey(formResult, FORM_RESULT_KEYS.SUB_REASON);
        const newSubReason: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestSubReason.maxItem.seqNo + 1,
          key: FORM_RESULT_KEYS.SUB_REASON,
          value: reasonKeys.subKey,
          type: latestSubReason.maxItem.type,
          investigationRequired: latestSubReason.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestSubReason.maxIndex + 2, 0, newSubReason);
        break;
      case FORM_RESULT_KEYS.CAUSE:
        const causeKeys = getFullReasonOrCauseKeys(data, IncidentCausesMap, IncidentSubCausesMap);
        if (isUndefined(causeKeys.key) || isUndefined(causeKeys.subKey)) {
          Notification.error(
            ErrorMessages.UNEXPECTED_ERROR(i18n.t('errors.formResultInvalid', 'Invalid form result value'))
          );
          return;
        }
        const latestCause = EventUtils.getLatestFormResultByKey(formResult, field);
        const newCause: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestCause.maxItem.seqNo + 1,
          key: field,
          value: causeKeys!.key,
          type: latestCause.maxItem.type,
          investigationRequired: latestCause.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestCause.maxIndex + 1, 0, newCause);
        const latestSubCause = EventUtils.getLatestFormResultByKey(formResult, FORM_RESULT_KEYS.SUB_CAUSE);
        const newSubCause: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestSubCause.maxItem.seqNo + 1,
          key: FORM_RESULT_KEYS.SUB_CAUSE,
          value: causeKeys!.subKey,
          type: latestSubCause.maxItem.type,
          investigationRequired: latestSubCause.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestSubCause.maxIndex + 2, 0, newSubCause);
        break;
      case FORM_RESULT_KEYS.WHERE_DID_IT_HAPPEN:
        const latestLocation = EventUtils.getLatestFormResultByKey(formResult, field);
        const newLocation: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestLocation.maxItem.seqNo + 1,
          key: field,
          value: data.selectedLocationType,
          extra: data.address,
          type: latestLocation.maxItem.type,
          investigationRequired: latestLocation.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestLocation.maxIndex + 1, 0, newLocation);
        incident.lat = data.addressCoordinates.lat;
        incident.lng = data.addressCoordinates.lng;
        break;
      case FORM_RESULT_KEYS.ORG_SELECTION:
        const latestDivision = EventUtils.getLatestFormResultByKey(formResult, field);
        const newDivision: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestDivision.maxItem.seqNo + 1,
          key: field,
          value: data.name,
          type: latestDivision.maxItem.type,
          investigationRequired: latestDivision.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestDivision.maxIndex + 1, 0, newDivision);
        incident.division = data;
        break;
      case FORM_RESULT_KEYS.WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED:
        const latestEquipmentMachineryTools = EventUtils.getLatestFormResultByKey(formResult, field);
        const newEquipmentMachineryTools: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestEquipmentMachineryTools.maxItem.seqNo + 1,
          key: field,
          value: data.option ? 'true' : 'false',
          extra: data.firstInput || undefined,
          type: latestEquipmentMachineryTools.maxItem.type,
          investigationRequired: latestEquipmentMachineryTools.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestEquipmentMachineryTools.maxIndex + 1, 0, newEquipmentMachineryTools);
        const latestSerialNumber = EventUtils.getLatestFormResultByKey(
          newFormResults,
          FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER
        );
        const newSerialNumber: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestSerialNumber.maxItem.seqNo + 1,
          key: FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER,
          value: data.secondInput || '-',
          type: FormResultType.TEXT,
          investigationRequired: latestSerialNumber.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(
          latestSerialNumber.maxIndex !== -1
            ? latestSerialNumber.maxIndex + 1
            : latestEquipmentMachineryTools.maxIndex + 2,
          0,
          newSerialNumber
        );
        break;
      default:
        const splitData = data.split(DELIMITER);
        let value, extra;
        value = splitData[0];
        if (splitData[0] === 'shared.yes') {
          value = 'true';
        }
        if (splitData[0] === 'shared.no') {
          value = 'false';
        }
        if (splitData.length > 1) {
          extra = splitData[2];
        }
        const latestEntry = EventUtils.getLatestFormResultByKey(formResult, field);
        const newEntry: FormResult = {
          ...this.newBaseEntry,
          seqNo: latestEntry.maxItem.seqNo + 1,
          key: field,
          value,
          extra,
          type: latestEntry.maxItem.type,
          investigationRequired: latestEntry.maxItem.investigationRequired,
          createdAt: moment().toISOString(),
        };
        newFormResults.splice(latestEntry.maxIndex + 1, 0, newEntry);
        if (
          [
            FORM_RESULT_KEYS.WHAT_HAPPENED_DETAILS,
            FORM_RESULT_KEYS.HAZARD_DESCRIPTION,
            FORM_RESULT_KEYS.SUBJECT,
          ].includes(field)
        ) {
          incident.description = value;
        }
        break;
    }
    incident.formResult = newFormResults;
    this.props.fieldChangedCallback(incident);
    this.hasUnsavedData[field] = false;
  };

  getSimpleRadioGroupComponent = (entry: string, options: string[], title?: string): ReactNode => (
    <HasSimpleRadioGroup
      radioOptions={options}
      onChange={() => this.onEntryChange(entry)}
      onSave={(data: any) => this.onEntrySave(entry, data)}
      questionTitle={title}
    />
  );

  getLocationRadioGroupComponent = (entry: string): ReactNode => (
    <HasLocationRadioGroup
      onChange={() => this.onEntryChange(entry)}
      onSave={(data: any) => this.onEntrySave(entry, data)}
    />
  );

  getFreeTextComponent = (entry: string): ReactNode => (
    <HasComment forEntity={entry} onSave={(data: any) => this.onEntrySave(entry, data)} />
  );

  getComplexRadioGroup = (entry: string, options: ComplexRadioOption[]) => (
    <HasComplexRadioGroup
      radioOptions={options}
      onChange={() => this.onEntryChange(entry)}
      onSave={(data: any) => this.onEntrySave(entry, data)}
    />
  );

  getReasonRadioGroup = (entry: string): ReactNode => (
    <HasReasonRadioGroup
      save={(data: any) => this.onEntrySave(entry, data)}
      changeCallback={() => this.onEntryChange(entry)}
      isInvestigationReason={false}
    />
  );

  getCauseRadioGroup = (entry: string): ReactNode => (
    <HasCauseRadioGroup
      save={(data: any) => this.onEntrySave(entry, data)}
      changeCallback={() => this.onEntryChange(entry)}
      isInvestigationCause={false}
    />
  );

  onEntryChange = (field: string) => {
    this.hasUnsavedData[field] = true;
  };

  private newEntryComponent: Record<string, ReactNode> = {
    WHEN_HAPPEN: <HasDatePickerForm onSave={(data: any) => this.onEntrySave(FORM_RESULT_KEYS.WHEN_HAPPEN, data)} />,
    ORG_SELECTION: (
      <HasDivisionPickerForm
        company={this.props.incident.company}
        onSave={(data: any) => this.onEntrySave(FORM_RESULT_KEYS.ORG_SELECTION, data)}
      />
    ),
    WHERE_DID_IT_HAPPEN: this.getLocationRadioGroupComponent(FORM_RESULT_KEYS.WHERE_DID_IT_HAPPEN),
    LOCATION_INFO: this.getFreeTextComponent(FORM_RESULT_KEYS.LOCATION_INFO),
    PARTIES_INVOLVED: this.getFreeTextComponent(FORM_RESULT_KEYS.PARTIES_INVOLVED),
    REPORTING_PERSON_IS_VICTIM: (
      <HasInputsForm
        firstInputQuestion={i18n.t('questionnaire.whatIsFirstName', 'What is his/her first name?')}
        secondInputQuestion={i18n.t('questionnaire.whatIsLastName', 'What is his/her last name?')}
        onChange={() => this.onEntryChange(FORM_RESULT_KEYS.REPORTING_PERSON_IS_VICTIM)}
        onSave={(data: any) => this.onEntrySave(FORM_RESULT_KEYS.REPORTING_PERSON_IS_VICTIM, data)}
        selectedOptionRequiredValue={false}
      />
    ),
    INVOLVED_PERSON_TYPE: this.getSimpleRadioGroupComponent(FORM_RESULT_KEYS.INVOLVED_PERSON_TYPE, [
      IncidentInvolvedPersonTypeLabel[IncidentInvolvedPersonType.EMPLOYEE],
      IncidentInvolvedPersonTypeLabel[IncidentInvolvedPersonType.AGENCY],
      IncidentInvolvedPersonTypeLabel[IncidentInvolvedPersonType.CONTRACTOR],
      IncidentInvolvedPersonTypeLabel[IncidentInvolvedPersonType.VISITOR],
      IncidentInvolvedPersonTypeLabel[IncidentInvolvedPersonType.MEMBER_OF_PUBLIC],
    ]),
    WHAT_HAPPENED_DETAILS: this.getFreeTextComponent(FORM_RESULT_KEYS.WHAT_HAPPENED_DETAILS),
    IS_STILL_UNSAFE: this.getComplexRadioGroup(FORM_RESULT_KEYS.IS_STILL_UNSAFE, [
      { label: 'shared.yes' },
      { label: 'shared.no', subComponent: this.getFreeTextComponent(FORM_RESULT_KEYS.IS_STILL_UNSAFE) },
    ]),
    HIGH_POTENTIAL_SCENARIO: this.getSimpleRadioGroupComponent(FORM_RESULT_KEYS.HIGH_POTENTIAL_SCENARIO, [
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.LOTOTO_AND_MACHINE_GUARDING],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.WORKING_AT_HEIGHT],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.CONTACT_WITH_MOVING_VEHICLE],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.BEING_TRAPPED],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.SUSPENDED_LOADS],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.CONFINED_SPACE],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.FIRE_OR_EXPLOSION],
      IncidentHighPotentialScenarioTypeLabel[IncidentHighPotentialScenarioType.NO],
    ]),
    COULD_RESULT_IN_THREAT_OF_LIFE_OR_LIFE_CHANGING_INJURIES: this.getSimpleRadioGroupComponent(
      FORM_RESULT_KEYS.COULD_RESULT_IN_THREAT_OF_LIFE_OR_LIFE_CHANGING_INJURIES,
      ['shared.yes', 'shared.no']
    ),
    WAS_THERE_POTENTIAL_HARM_OR_PROPERTY_DAMAGE: this.getSimpleRadioGroupComponent(
      FORM_RESULT_KEYS.WAS_THERE_POTENTIAL_HARM_OR_PROPERTY_DAMAGE,
      [
        IncidentHarmOrPropertyDamageLabel[IncidentHarmOrPropertyDamage.NONE],
        IncidentHarmOrPropertyDamageLabel[IncidentHarmOrPropertyDamage.FIRST_AID_STAYED_AT_WORK],
        IncidentHarmOrPropertyDamageLabel[IncidentHarmOrPropertyDamage.FIRST_AID_SENT_HOME],
        IncidentHarmOrPropertyDamageLabel[IncidentHarmOrPropertyDamage.HOSPITAL],
        IncidentHarmOrPropertyDamageLabel[IncidentHarmOrPropertyDamage.PROPERTY_DAMAGE],
      ]
    ),
    COULD_RESULT_IN_TIME_OFF_WORK_OR_DOCTOR_TREATMENT: this.getSimpleRadioGroupComponent(
      FORM_RESULT_KEYS.COULD_RESULT_IN_TIME_OFF_WORK_OR_DOCTOR_TREATMENT,
      ['shared.yes', 'shared.no']
    ),
    WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED: (
      <HasInputsForm
        firstInputQuestion={i18n.t('questionnaire.pleaseProvideDescription', 'Please provide a description')}
        secondInputQuestion={i18n.t('questionnaire.pleaseProvideSerialNumber', 'Please provide the serial number')}
        onChange={() => this.onEntryChange(FORM_RESULT_KEYS.WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED)}
        onSave={(data: any) => this.onEntrySave(FORM_RESULT_KEYS.WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED, data)}
        selectedOptionRequiredValue={true}
      />
    ),
    PRELIMINARY_REASON: this.getReasonRadioGroup(FORM_RESULT_KEYS.REASON),
    WHAT_DO_YOU_BELIEVE_IS_THE_INITIAL_ROOT_CAUSE_THOUGHT: this.getCauseRadioGroup(FORM_RESULT_KEYS.CAUSE),
    TYPE: this.getSimpleRadioGroupComponent(FORM_RESULT_KEYS.TYPE, [
      AuditTypeLabel[AuditType.INTERNAL],
      AuditTypeLabel[AuditType.EXTERNAL],
      AuditTypeLabel[AuditType.HOUSEKEEPING],
      AuditTypeLabel[AuditType.ENFORCEMENT],
    ]),
    SUBJECT: this.getFreeTextComponent(FORM_RESULT_KEYS.SUBJECT),
    DISCUSSION_TRANSCRIPT: this.getFreeTextComponent(FORM_RESULT_KEYS.DISCUSSION_TRANSCRIPT),
    HAZARD_DESCRIPTION: this.getFreeTextComponent(FORM_RESULT_KEYS.HAZARD_DESCRIPTION),
    WHAT_HAVE_YOU_DONE: this.getFreeTextComponent(FORM_RESULT_KEYS.WHAT_HAVE_YOU_DONE),
    IS_SITUATION_SAFE: this.getSimpleRadioGroupComponent(FORM_RESULT_KEYS.IS_SITUATION_SAFE, [
      'shared.yes',
      'shared.no',
    ]),
  };

  getFormStepConfig = (): StepProps[] => {
    const { formResult } = this.props.incident;
    const { latestEntries, oldestEntries } = EventUtils.getLatestAndOldestIncidentEntries(formResult);
    const icon = (
      <HasAvatar
        icon={<SolutionOutlined style={{ verticalAlign: 'middle' }} />}
        size="default"
        style={{ backgroundColor: COLORS.NEW_ORANGE }}
      />
    );
    return latestEntries
      .filter((result) => !IGNORED_TYPES.includes(result.type as FormResultType) && !IGNORED_KEYS.includes(result.key))
      .map((entry) => {
        const filteredOldestEntries = oldestEntries.filter((old) => {
          if (
            ([FORM_RESULT_KEYS.REASON].includes(entry.key) && [FORM_RESULT_KEYS.SUB_REASON].includes(old.key)) ||
            ([FORM_RESULT_KEYS.CAUSE].includes(entry.key) && [FORM_RESULT_KEYS.SUB_CAUSE].includes(old.key))
          ) {
            return true;
          } else {
            return old.key === entry.key;
          }
        });
        const title = <HasTitle content={this.getQuestionLabel(entry)} level={4} style={{ fontSize: '12px' }} />;
        const description = isUndefined(entry) ? (
          <>
            <div className="d-flex flex-row justify-content-end">
              <HasText
                content={'-'}
                style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
                className="text-right"
              />
            </div>
          </>
        ) : (
          <React.Fragment>
            <HasIncidentFormEntries
              latestEntries={latestEntries}
              oldestEntries={filteredOldestEntries}
              entry={entry}
              translateOptions={
                [FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME].includes(entry.key) ||
                [FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME].includes(entry.key)
                  ? undefined
                  : {
                      onShowTranslation: this.handleTranslationForEntry,
                      translationResult: this.state.translationResult,
                      resultIdsTranslated: this.state.resultIdsTranslated,
                    }
              }
              addEntryComponent={this.newEntryComponent[entry.key]}
              editToggleCallback={(wasEditActive: boolean) => this.editToggledOnEntry(entry.key, wasEditActive)}
            />
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </React.Fragment>
        );
        return {
          title: title,
          description: description,
          icon: icon,
        };
      });
  };

  render() {
    const { formResult } = this.props.incident;
    const { Step } = Steps;
    return (
      <React.Fragment>
        <Prompt
          when={Object.values(this.hasUnsavedData).some((value) => value)}
          message={ErrorMessages.INVESTIGATION_UNSAVED_PAGE_LEAVE}
        />
        <Steps
          type="default"
          current={formResult.length}
          size="default"
          direction="vertical"
          className="position-relative"
        >
          {this.getFormStepConfig().map((config, index) => (
            <Step key={index} {...config} />
          ))}
        </Steps>
      </React.Fragment>
    );
  }
}

export default HasIncidentReport;
