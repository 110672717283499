import { DeleteOutlined, ExclamationCircleTwoTone, SolutionOutlined } from '@ant-design/icons';
import { Result, Skeleton, Steps, Tooltip } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import React, { ReactText, useCallback, useEffect, useState } from 'react';
import i18n from '../../../i18n/config';
import { Form, FormLabel, FormResultType, Incident, IncidentType, NewFormResult, NewIncident } from '../../../models';
import { FormService, TranslationService } from '../../../services';
import { COLORS, DELIMITER, FORM_RESULT_KEYS } from '../../../shared';
import { EventUtils } from '../../../utils';
import { HasAvatar, HasButton, HasText, HasTitle } from '../../atoms';
import moment from 'moment';

interface StructureDiffProps {
  event: Incident;
  fromType: IncidentType;
  toType: IncidentType;
  startSwap: (newEvent?: NewIncident) => void;
}

interface StructureDiffState {
  fromForm?: Form;
  toForm?: Form;
  formLabels: FormLabel[];
  newEvent?: NewIncident;
  translationResult: { [k: number]: string };
  resultIdsTranslated: number[];
}

const { Step } = Steps;

const HasStructureDiff: React.FC<StructureDiffProps> = ({ event, fromType, toType, startSwap }): JSX.Element => {
  const [structureDiffData, setStructureDiffData] = useState<StructureDiffState>({
    formLabels: [],
    translationResult: {},
    resultIdsTranslated: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { fromForm, toForm, formLabels, newEvent } = structureDiffData;

  const handleTranslationForEntry = (id?: number) => {
    const { formResult } = event;
    if (id) {
      if (structureDiffData.resultIdsTranslated.includes(id)) {
        setStructureDiffData((previousState) => {
          delete previousState.translationResult[id];
          return {
            ...previousState,
            resultIdsTranslated: previousState.resultIdsTranslated.filter((existingId) => existingId !== id),
          };
        });
      } else {
        let translation = '';
        let textToTranslate = formResult.find((result) => result.id === id)?.value;
        if (formResult.find((result) => result.id === id)?.type === FormResultType.OPTION_DESCRIPTION) {
          textToTranslate = formResult.find((result) => result.id === id)?.extra;
        }

        TranslationService.translate({ textToTranslate: textToTranslate }).then((result) => {
          translation = result.data.translatedText!;
          setStructureDiffData((previousState) => {
            previousState.resultIdsTranslated.push(id);
            return {
              ...previousState,
              translationResult: { ...previousState.translationResult, [id]: translation },
            };
          });
        });
      }
    }
  };

  const initEventCopy = useCallback(
    (toForm: Form): NewIncident => {
      let newEvent: NewIncident = {
        description: event.description,
        company: event.company,
        division: event.division,
        lat: event.lat,
        lng: event.lng,
        type: toType,
        formResult: [],
        photos: [],
        historicalUpload: event.historicalUpload,
        isInvestigationRequired: event.investigationRequired,
      };
      let ignoredQuestions: string[] = [];
      if (
        event.formResult.every(
          (formResult) =>
            ![FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME, FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME].includes(
              formResult.key
            )
        )
      ) {
        ignoredQuestions = [...ignoredQuestions, ...EventUtils.ignoreReportingIsVictim(event.formResult)];
      }
      if (
        event.formResult.every(
          (formResult) => ![FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER].includes(formResult.key)
        )
      ) {
        ignoredQuestions = [...ignoredQuestions, ...EventUtils.ignoreMachinesInvolved(event.formResult)];
      }
      const equivalentQuestions = EventUtils.getEquivalentFormResults(event.formResult, toType);

      let newFormResult: NewFormResult[] = [];
      newFormResult = EventUtils.filterOutIntermediateSteps(toForm.mappedSteps)
        .filter(({ metadata: { key } }) => !ignoredQuestions.includes(key))
        .flatMap((step) => {
          const oldFormResults = event.formResult.filter(
            (formResultStep) =>
              formResultStep.key === step.metadata.key || equivalentQuestions[formResultStep.key] === step.metadata.key
          );
          if (!oldFormResults.length) {
            return [
              {
                key: step.metadata.key,
                type: step.metadata.type,
                seqNo: 0,
                value: undefined,
                extra: undefined,
                createdAt: moment().toISOString(),
              } as NewFormResult,
            ];
          }
          return oldFormResults.map((oldFormResult) => ({
            key: step.metadata.key,
            type: step.metadata.type,
            seqNo: oldFormResult.seqNo,
            value: oldFormResult.value || '',
            extra: oldFormResult.extra,
            createdAt: oldFormResult.createdAt || moment().toISOString(),
          }));
        });
      newEvent.formResult = newFormResult;
      return newEvent;
    },
    [event, toType]
  );

  useEffect(() => {
    setLoading(true);
    const getRelevantForms = async () => {
      const { data: fromForm } = await FormService.getByFormType(fromType);
      const { data: toForm } = await FormService.getByFormType(toType);
      const { data: formLabels } = await FormService.getAllFormLabels();
      const newEvent = initEventCopy(toForm);
      setStructureDiffData({ ...structureDiffData, fromForm, toForm, formLabels, newEvent });
      setLoading(false);
    };
    getRelevantForms();
  }, [fromType, toType, initEventCopy]);

  const newFormResultValueChanged = (key: ReactText, value: any) => {
    setStructureDiffData((data) => {
      let newFormResults: NewFormResult[] = [];
      if (!isUndefined(data.newEvent)) {
        newFormResults = [...data.newEvent.formResult];
        switch (key) {
          case FORM_RESULT_KEYS.WAS_THERE_EQUIPMENT_MACHINERY_OR_TOOLS_INVOLVED:
            const machineryEntry = newFormResults.find(({ key: formResultKey }) => formResultKey === key);
            if (machineryEntry) {
              machineryEntry.value = value.option;
              machineryEntry.extra = value.firstInput;
            }
            const serialNumberEntry = newFormResults.find(
              ({ key: formResultKey }) => formResultKey === FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER
            );
            if (serialNumberEntry) {
              serialNumberEntry.value = value.secondInput || '-';
            }
            break;
          default:
            const searchedFormResultIndex = newFormResults.findIndex(({ key: formResultKey }) => formResultKey === key);
            if (searchedFormResultIndex > -1) {
              newFormResults[searchedFormResultIndex].value = value;
            }
            break;
        }
      }
      return { ...data, newEvent: { ...data.newEvent, formResult: newFormResults } };
    });
  };

  const getStepsConfig = (): { toAdd: JSX.Element[]; toDelete: JSX.Element[] } => {
    const toAdd: JSX.Element[] = [];
    const toDelete: JSX.Element[] = [];
    if (fromForm && toForm && formLabels && newEvent) {
      const { toDelete: stepsToDelete, toAdd: stepsToAdd } = EventUtils.getFormStructureDiff(fromForm, toForm);

      const toFormLabels = formLabels.find(({ formType }) => formType === toType)?.questionLabels || [];
      const fromFormLabels = formLabels.find(({ formType }) => formType === fromType)?.questionLabels || [];
      const answeredQuestions = newEvent.formResult
        .filter((result) => !isUndefined(result.value) && result.value !== '')
        .map((result) => result.key);
      const equivalentFrom = EventUtils.getEquivalentFormResults(event.formResult, toType);
      const equivalentTo = EventUtils.getEquivalentFormResults(newEvent.formResult, event.type);
      stepsToAdd.forEach(({ id, metadata: { key, type } }) => {
        if (!equivalentTo[key] && ![FORM_RESULT_KEYS.SUB_REASON, FORM_RESULT_KEYS.SUB_CAUSE].includes(key)) {
          const title = (
            <HasTitle
              content={i18n.t(EventUtils.getEntryTitle(toFormLabels, key))}
              level={4}
              style={{ fontSize: '12px' }}
            />
          );
          const icon = DEFAULT_ICON;
          const description = answeredQuestions.includes(key)
            ? EventUtils.getEntryDescription(
                newEvent.formResult,
                newEvent.formResult.find((formResultStep) => formResultStep.key === key)
              )
            : key === FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER
            ? null
            : EventUtils.getEntryValueComponent({
                event: newEvent,
                formSteps: toForm.mappedSteps,
                key,
                type,
                onChange: newFormResultValueChanged,
              });
          toAdd.push(<Step key={id} title={title} description={description} icon={icon} />);
        }
      });
      stepsToDelete.forEach((step) => {
        if (
          !equivalentFrom[step.metadata.key] &&
          ![FORM_RESULT_KEYS.SUB_REASON, FORM_RESULT_KEYS.SUB_CAUSE].includes(step.metadata.key)
        ) {
          const title = (
            <HasTitle
              content={i18n.t(EventUtils.getEntryTitle(fromFormLabels, step.metadata.key))}
              level={4}
              style={{ fontSize: '12px' }}
            />
          );
          const icon = DELETE_ICON;
          const description = EventUtils.getEntryDescription(
            event.formResult,
            EventUtils.getLatestFormResultByKey(event.formResult, step.metadata.key).maxItem,
            {
              onShowTranslation: handleTranslationForEntry,
              translationResult: structureDiffData.translationResult,
              resultIdsTranslated: structureDiffData.resultIdsTranslated,
            }
          );
          toDelete.push(<Step key={step.id} title={title} description={description} icon={icon} />);
        }
      });
    }

    return { toAdd, toDelete };
  };

  const canStartSwap = (): boolean => {
    return !isUndefined(newEvent) && newEvent.formResult.every((result) => !isUndefined(result.value));
  };

  const { toAdd, toDelete } = getStepsConfig();

  return (
    <>
      <div className="d-flex flex-column flex-grow-1 overflow-auto" style={{ margin: '0 -10px', padding: '0 10px' }}>
        <Skeleton active loading={loading}>
          {!isEmpty(toAdd) && (
            <>
              <HasText strong content={i18n.t('typeSwap.requiredQuestions')} className="mb-4 text-center">
                {!canStartSwap() && (
                  <Tooltip title={i18n.t('typeSwap.unansweredQuestions')}>
                    <ExclamationCircleTwoTone
                      twoToneColor={COLORS.ERROR}
                      className="ml-2 align-middle"
                      style={{ fontSize: 16 }}
                    />
                  </Tooltip>
                )}
              </HasText>
              <Steps direction="vertical">{toAdd}</Steps>
            </>
          )}
          {!isEmpty(toDelete) && (
            <>
              <HasText strong content={i18n.t('typeSwap.removedQuestions')} className="mb-4 text-center" />
              <Steps direction="vertical">{toDelete}</Steps>
            </>
          )}
          {isEmpty(toAdd) && isEmpty(toDelete) && (
            <Result status="success" title={<HasText content={i18n.t('typeSwap.noStructuralDifferences')} />} />
          )}
        </Skeleton>
      </div>
      <div className="d-flex flex-row justify-content-end mt-3">
        {/* <HasButton type='default' disabled={loading}>{i18n.t('typeSwap.viewSideBySide')}</HasButton> */}
        <HasButton type="primary" disabled={loading || !canStartSwap()} onClick={() => startSwap(newEvent)}>
          {i18n.t('typeSwap.swap')}
        </HasButton>
      </div>
    </>
  );
};

export { HasStructureDiff };

const DEFAULT_ICON = (
  <HasAvatar icon={<SolutionOutlined className="align-middle" />} style={{ backgroundColor: COLORS.NEW_ORANGE }} />
);

const DELETE_ICON = (
  <HasAvatar icon={<DeleteOutlined className="align-middle" />} style={{ backgroundColor: COLORS.ERROR }} />
);
