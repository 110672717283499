import { isNull } from 'lodash';
import { EventFilters, EVENT_TABLE_FILTERS, ACTION_TABLE_FILTERS, TableFilters } from '../shared';

class FiltersService {
  // event table filters

  public saveEventTableFilters(filters: EventFilters) {
    localStorage.setItem(EVENT_TABLE_FILTERS, JSON.stringify(filters));
  }

  public getEventTableFilters(): EventFilters | null {
    const filters = localStorage.getItem(EVENT_TABLE_FILTERS);
    return !isNull(filters) ? JSON.parse(filters) : null;
  }

  public deleteEventTableFilters() {
    localStorage.removeItem(EVENT_TABLE_FILTERS);
  }

  // action table filters
  public saveActionTableFilters(filters: TableFilters) {
    localStorage.setItem(ACTION_TABLE_FILTERS, JSON.stringify(filters));
  }

  public getActionTableFilters(): TableFilters | null {
    const filters = localStorage.getItem(ACTION_TABLE_FILTERS);
    return !isNull(filters) ? JSON.parse(filters) : null;
  }

  public deleteActionTableFilters() {
    localStorage.removeItem(ACTION_TABLE_FILTERS);
  }
}

export default new FiltersService();
