import React, { CSSProperties, ReactNode } from 'react';
import { HasButton, HasParagraph, HasRadioGroup, HasText } from '../../..';
import { ErrorMessages, RadioGroupOption, TypedRadioChangeEvent, TypedRadioGroupProps } from '../../../../shared';
import { HasTextArea, HasTextInput } from '../../../atoms';
import i18n from '../../../../i18n/config';
import { isUndefined } from 'lodash';

interface ReportingPersonFormProps {
  firstInputQuestion: string;
  secondInputQuestion: string;
  selectedOptionRequiredValue: boolean;
  onChange: () => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
  textareaWidth?: string;
  hideParagraph?: boolean;
  hideTextareaPlaceholder?: boolean;
}

interface ReportingPersonFormState {
  selectedOption: boolean | undefined;
  error: boolean;
}

class HasInputsForm extends React.Component<ReportingPersonFormProps, ReportingPersonFormState> {
  private radioGroupConfig: TypedRadioGroupProps<boolean>;
  private radioOptionsConfig: RadioGroupOption<boolean>[] = [];

  private result: { option: boolean | undefined; firstInput: string | undefined; secondInput: string | undefined };

  constructor(props: Readonly<ReportingPersonFormProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onChange,
    };

    this.radioOptionsConfig = [
      { value: true, label: i18n.t('shared.yes') },
      { value: false, label: i18n.t('shared.no') },
    ];

    this.result = {
      option: undefined,
      firstInput: undefined,
      secondInput: undefined,
    };

    this.state = {
      error: false,
      selectedOption: undefined,
    };
  }

  onChange = (event: TypedRadioChangeEvent<boolean>) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.setState({ selectedOption: event.value });
    this.result.firstInput = undefined;
    this.result.secondInput = undefined;
    this.props.onChange();
  };

  saveData = () => {
    const { selectedOption } = this.state;
    const { selectedOptionRequiredValue } = this.props;
    if (
      isUndefined(selectedOption) ||
      (this.state.selectedOption === selectedOptionRequiredValue && !this.isFormCompleted())
    ) {
      this.setState({ error: true });
    } else {
      const result = {
        option: this.state.selectedOption,
        firstInput: this.result.firstInput,
        secondInput: this.result.secondInput,
      };
      this.props.onSave(result);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  isFormCompleted = (): boolean => {
    const { selectedOption } = this.state;
    const { selectedOptionRequiredValue } = this.props;
    const { firstInput, secondInput } = this.result;
    if (selectedOption === selectedOptionRequiredValue) {
      return (
        firstInput !== null &&
        !isUndefined(firstInput) &&
        firstInput !== '' &&
        secondInput !== null &&
        !isUndefined(secondInput) &&
        secondInput !== ''
      );
    }
    if (isUndefined(selectedOption)) {
      return false;
    }
    return true;
  };

  showSubQuestions = (): ReactNode => {
    const { firstInputQuestion, secondInputQuestion, textareaWidth, hideTextareaPlaceholder } = this.props;
    const questionsStyle: CSSProperties = {
      fontSize: '12px',
      fontWeight: 500,
      marginBottom: '8px',
      textTransform: 'uppercase',
    };
    return (
      <React.Fragment>
        <div className="w-50 mb-2">
          <HasParagraph content={firstInputQuestion} style={questionsStyle} />
          <HasTextArea
            style={{ width: textareaWidth }}
            placeholder={
              !hideTextareaPlaceholder ? i18n.t('shared.textAreaPlaceholder', 'Write your answer here...') : undefined
            }
            onChange={(e) => (this.result.firstInput = e.target.value)}
          />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="w-50">
            <HasParagraph content={secondInputQuestion} style={questionsStyle} />
            <HasTextArea
              style={{ width: textareaWidth }}
              placeholder={
                !hideTextareaPlaceholder ? i18n.t('shared.textAreaPlaceholder', 'Write your answer here...') : undefined
              }
              onChange={(e) => (this.result.secondInput = e.target.value)}
            />
          </div>
          <HasButton
            type="primary"
            size="small"
            htmlType="button"
            onClick={this.saveData}
            style={{ alignSelf: 'flex-end' }}
          >
            {i18n.t('shared.save')}
          </HasButton>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { selectedOption } = this.state;
    const { selectedOptionRequiredValue, hideParagraph } = this.props;
    return (
      <React.Fragment>
        {!hideParagraph && (
          <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        )}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <HasRadioGroup {...this.radioGroupConfig} groupOptions={this.radioOptionsConfig} />
          {selectedOption === !selectedOptionRequiredValue && (
            <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData}>
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {selectedOption === selectedOptionRequiredValue && this.showSubQuestions()}
        {this.state.error && (
          <HasText
            content={
              selectedOption === selectedOptionRequiredValue
                ? ErrorMessages.INVESTIGATION_ENTRY_FORM_NOT_COMPLETE
                : ErrorMessages.INVESTIGATION_ENTRY_NO_OPTION_SELECTED
            }
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HasInputsForm;
