import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { HasButton, HasParagraph, HasRadioGroup } from '../../..';
import i18n from '../../../../i18n/config';
import { ErrorMessages, RadioGroupOption, TypedRadioGroupProps } from '../../../../shared';
import { getElementKey, i18nLabelParser } from '../../../../utils';
import { HasText } from '../../../atoms';

interface SimpleRadioGroupProps {
  radioOptions: string[];
  onChange: (data?: any) => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
  questionTitle?: string;
  useGlobalSave?: boolean;
}

interface SimpleRadioGroupState {
  error: boolean;
  radioOptionsConfig: RadioGroupOption<string>[];
}

class HasSimpleRadioGroup extends React.Component<SimpleRadioGroupProps, SimpleRadioGroupState> {
  private newValue: string | null = null;

  private radioGroupConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<SimpleRadioGroupProps>) {
    super(props);

    this.radioGroupConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onChange,
    };

    const radioOptionsConfig = this.buildRadioOptions();

    this.state = { error: false, radioOptionsConfig };
  }

  componentDidMount() {
    i18n.on('languageChanged', () => this.setState({ radioOptionsConfig: this.buildRadioOptions() }));
  }

  buildRadioOptions = (): RadioGroupOption<string>[] => {
    return this.props.radioOptions.map((option, index) => ({
      key: getElementKey('simple_radio_group_option', index),
      value: option,
      forObject: option,
      label: i18nLabelParser(option),
    }));
  };

  onChange = (event: RadioChangeEvent) => {
    if (this.state.error) {
      this.setState({ error: false });
    }
    this.newValue = event.target.value;
    this.props.onChange(event.target.value);
  };

  saveData = () => {
    if (!this.newValue) {
      this.setState({ error: true });
    } else {
      this.props.onSave(this.newValue);
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  componentWillUnmount() {
    i18n.on('languageChanged', () => this.setState({ radioOptionsConfig: this.buildRadioOptions() }));
  }

  render() {
    const { questionTitle, useGlobalSave } = this.props;
    const { radioOptionsConfig } = this.state;
    return (
      <React.Fragment>
        <HasParagraph
          content={questionTitle ? i18n.t(questionTitle) : i18n.t('investigation.pickOne')}
          style={{ fontSize: '12px', marginBottom: '8px' }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <HasRadioGroup {...this.radioGroupConfig} groupOptions={radioOptionsConfig} />
          {!useGlobalSave && (
            <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData}>
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {this.state.error && (
          <HasText
            content={ErrorMessages.INVESTIGATION_ENTRY_NO_OPTION_SELECTED}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HasSimpleRadioGroup;
