import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import React from 'react';
import { MdCheck } from 'react-icons/md';
import {
  ALLOWED_FILE_TYPES,
  ChatBotCustomComponentProps,
  ErrorMessages,
  MAX_FILES_PER_UPLOAD,
  COLORS,
} from '../../../shared';
import { checkFileSize, displayPlaceholderIfEmpty } from '../../../utils';
import { HasButton, HasImage, HasModal, Notification } from '../../atoms';
import i18n from '../../../i18n/config';

interface ChatBotImagePickerState {
  canChangePhotos: boolean;
  images: UploadFile[];
  files: File[];
  previewImage: string;
  showPreviewModal: boolean;
}

class HasChatBotImagePicker extends React.Component<ChatBotCustomComponentProps, ChatBotImagePickerState> {
  state = {
    canChangePhotos: true,
    images: [] as UploadFile[],
    files: (this.props.value || []) as RcFile[],
    previewImage: '',
    showPreviewModal: false,
  };

  componentDidMount() {
    const { images, files } = this.state;
    files.forEach((file) => images.push(this.fileToImage(file)));
    this.setState({ images });
  }

  private filesToUpload: RcFile[] = [];

  checkFile = (file: File): boolean => {
    if (checkFileSize(file)) {
      if (ALLOWED_FILE_TYPES.Image.includes(file.type)) {
        return true;
      } else {
        Notification.error(ErrorMessages.INCIDENT_REPORT_FILE_TYPE(file.name));
        return false;
      }
    } else {
      Notification.error(ErrorMessages.INCIDENT_REPORT_FILE_SIZE(file.name));
      return false;
    }
  };

  beforeUpload = (file: RcFile, FileList: RcFile[]): boolean => {
    if (FileList.length > MAX_FILES_PER_UPLOAD) {
      if (!this.filesToUpload.length) {
        Notification.error(ErrorMessages.ATTACHMENT_FILES_LIMIT_EXCEEDED);
        this.filesToUpload = FileList;
      } else {
        if (file === this.filesToUpload.slice(-1)[0]) {
          this.filesToUpload = [];
        }
      }
      return false;
    } else {
      if (this.checkFile(file)) {
        this.setState((prevState) => {
          const prevImages = prevState.images;
          prevImages.push(this.fileToImage(file));

          const prevFiles = prevState.files;
          prevFiles.push(file);

          return { ...prevState, images: prevImages, files: prevFiles };
        });
      }
      if (!this.filesToUpload.length) {
        this.filesToUpload = FileList;
      }
      if (file === FileList.splice(-1)[0]) {
        this.filesToUpload = [];
      }
      return false;
    }
  };

  fileToImage = (file: RcFile) => {
    const status: UploadFileStatus = 'done';
    return { ...file, originFileObj: file as File, status, url: '' };
  };

  removeImage = (file: UploadFile) => {
    if (!this.state.canChangePhotos) {
      return false;
    }
    this.setState((prevState) => {
      let prevImages = prevState.images;
      let prevFiles = prevState.files;

      prevFiles = prevFiles.filter((_, index) => prevImages[index].uid !== file.uid);
      prevImages = prevImages.filter((image) => image.uid !== file.uid);

      return { ...prevState, images: prevImages, files: prevFiles };
    });
  };

  getBase64 = (file: UploadFile): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (file.originFileObj) {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        Notification.error(
          ErrorMessages.INCIDENT_REPORT_FILE_PREVIEW(
            displayPlaceholderIfEmpty(file.name, i18n.t('dataDisplay.unknownValue'))
          )
        );
      }
    });
  };

  previewImage = async (file: UploadFile) => {
    file.preview = await this.getBase64(file);
    if (file.preview) {
      this.setState({ previewImage: file.preview, showPreviewModal: true });
    }
  };

  saveImages = () => {
    const { step, triggerNextStep } = this.props;
    const { images } = this.state;
    this.setState({ canChangePhotos: false });
    if (step && triggerNextStep) {
      triggerNextStep({ value: images.map((image) => image.originFileObj as File), trigger: step.metadata.trigger });
    }
  };

  render() {
    const { canChangePhotos, images, previewImage, showPreviewModal } = this.state;
    return (
      <div
        style={{ backgroundColor: canChangePhotos ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
        className="d-flex flex-row has-chat-bot-component"
      >
        <Upload
          multiple
          accept={'.png, .jpg, .jpeg, .tiff'}
          beforeUpload={this.beforeUpload}
          customRequest={(_) => _}
          onRemove={canChangePhotos ? this.removeImage : undefined}
          onPreview={this.previewImage}
          fileList={images}
          showUploadList={true}
          listType="picture-card"
          style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}
        >
          {canChangePhotos ? (
            <React.Fragment>
              <div>
                <PlusOutlined />
                <div className="ant-upload-text">{i18n.t('attachment.upload')}</div>
              </div>
            </React.Fragment>
          ) : null}
        </Upload>
        {canChangePhotos && images.length > 0 && (
          <HasButton
            type="link"
            onClick={this.saveImages}
            disabled={!images.length}
            icon={<MdCheck style={{ color: COLORS.SECONDARY_GREEN, fontSize: '20px' }} />}
            style={{ minWidth: 0, alignSelf: 'center' }}
          />
        )}
        <HasModal
          footer={null}
          visible={showPreviewModal}
          onCancel={() => this.setState({ showPreviewModal: false })}
          closable={false}
          centered
          style={{ padding: 0 }}
          className="img-thumbnail"
        >
          <HasImage alt={i18n.t('gallery.imagePreview')} src={previewImage} style={{ width: '100%', padding: 5 }} />
        </HasModal>
      </div>
    );
  }
}

export default HasChatBotImagePicker;
