import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import React from 'react';
import { CollapsePanelWithContent } from '../../../shared';

interface OwnCollapseProps extends CollapseProps {
  panelConfigs: CollapsePanelWithContent[];
}

class HasCollapse extends React.Component<OwnCollapseProps, {}> {
  render() {
    const { panelConfigs, ...collapseProps } = this.props;
    return (
      <Collapse {...collapseProps}>
        {panelConfigs.map((panelConfig) => {
          const onlyPanelProps = { ...panelConfig };
          delete onlyPanelProps.content;
          return <Collapse.Panel {...onlyPanelProps}>{panelConfig.content}</Collapse.Panel>;
        })}
      </Collapse>
    );
  }
}

export default HasCollapse;
