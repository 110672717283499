import { RcFile } from 'antd/lib/upload';
import { ReactText } from 'react';
import { Attachment } from './attachment.model';
import { Company, Division } from './company.model';
import { FormResult, FormResultType, NewFormResult } from './form.result.model';
import { InvestigationStatus } from './investigation.model';
import { Photo } from './photo.model';
import { User } from './user.model';
import { TFunction } from 'i18next';

export interface Incident {
  id: number;
  lat: number;
  lng: number;
  description?: string;
  reportedAt: string;
  type: IncidentType;
  investigationRequired: boolean;
  formId?: number;
  formResult: FormResult[];
  photos?: Photo[];
  reportingUser: User;
  status: InvestigationStatus;
  hasActions: boolean;
  hidden: boolean;
  company: Company;
  division: Division;
  attachments: Attachment[];
  historicalUpload?: boolean;
  swappedByName?: string;
  swappedFromId?: number;
  severity?: IncidentSeverity;
  subType?: IncidentSubType;
  highPotentialEvent: boolean;
  investigationRiskLevel?: RiskLevel;
  investigationRiskScore?: number;
  actionsRiskScoreAverage?: number;
  areAllComplianceFormsCompleted: boolean;
  areAllActionsClosed: boolean;
}

export enum IncidentSeverity {
  MODERATE = 'MODERATE',
  SEVERE = 'SEVERE',
}

export interface IncidentFilters {
  types: string[];
  showHidden: boolean;
  requiresInvestigation: boolean[];
  investigationStatus: string[];
  hasActionsOpen: boolean[];
  loggedUserId: number | null;
}

export interface NewIncident {
  lat?: number;
  lng?: number;
  type?: string;
  description?: string;
  formResult: NewFormResult[];
  photos?: RcFile[] | File[];
  company?: Company;
  division?: Division;
  reportedAt?: string;
  reportingUser?: string;
  hasErrors?: boolean;
  invalidEntryKeys?: { key: ReactText; type: FormResultType }[];
  historicalUpload?: boolean;
  isInvestigationRequired?: boolean;
  uuid?: string;
}

export interface IncidentGlance {
  type: IncidentType;
  count: number;
  trend: any;
}

export interface IncidentMonthlyCount {
  month: string;
  incidents: IncidentGlance[];
}

export interface IncidentStatisticsWrapper {
  incidentReasonStatistics: IncidentReasonStatistics[];
  incidentCauseStatistics: IncidentCauseStatistics[];
}

export interface IncidentSubReasonStatistics {
  subReason: string;
  count: number;
}

export interface IncidentReasonStatistics {
  reason: 'CONTACT_WITH' | 'STRUCK_BY' | 'SLIPS_TRIPS_FALLS' | 'OTHER';
  reasonTotal: number;
  data: IncidentSubReasonStatistics[];
}

export interface IncidentSubCauseStatistics {
  subCause: string;
  count: number;
}

export interface IncidentCauseStatistics {
  cause: 'PEOPLE' | 'ENVIRONMENT' | 'EQUIPMENT_MATERIAL' | 'PROCESS_AND_PROCEDURE';
  causeTotal: number;
  data: IncidentSubCauseStatistics[];
}

export interface IncidentRollingYearFilters {
  incidentTypes: IncidentType[];
}

export type IncidentKey = keyof Incident;

export const TYPE: IncidentKey = 'type';
export const SUB_TYPE: IncidentKey = 'subType';
export const REPORTED_AT: IncidentKey = 'reportedAt';
export const IS_INVESTIGATION_REQUIRED: IncidentKey = 'investigationRequired';
export const STATUS: IncidentKey = 'status';
export const HAS_ACTIONS: IncidentKey = 'hasActions';
export const SIF: IncidentKey = 'highPotentialEvent';

// Incident Type

export enum IncidentType {
  NEAR_HIT = 'NEAR_HIT',
  INCIDENT = 'INCIDENT',
  AUDIT = 'AUDIT',
  SAFETY_CONV = 'SAFETY_CONV',
  HOUSE_KEEPING = 'HOUSE_KEEPING',
  TBOX = 'TBOX',
}

export const IncidentTypeLabel: Record<number | string, string> = {
  0: 'incidentTypes.nearHit',
  NEAR_HIT: 'incidentTypes.nearHit',
  1: 'incidentTypes.incident',
  INCIDENT: 'incidentTypes.incident',
  2: 'incidentTypes.audit',
  AUDIT: 'incidentTypes.audit',
  3: 'incidentTypes.safetyConv',
  SAFETY_CONV: 'incidentTypes.safetyConv',
  4: 'incidentTypes.hire',
  HOUSE_KEEPING: 'incidentTypes.hire',
  5: 'incidentTypes.tbox',
  TBOX: 'incidentTypes.tbox',
};

// Incident Subtype

export enum IncidentSubType {
  LTI = 'LTI',
  MTI = 'MTI',
  FIRST_AID = 'FIRST_AID',
  NON_INJURY = 'NON_INJURY',
  PROPERTY_DAMAGE = 'PROPERTY_DAMAGE',
}

export const IncidentSubTypeLabel: Record<IncidentSubType, string> = {
  [IncidentSubType.LTI]: 'investigation.lti',
  [IncidentSubType.MTI]: 'investigation.mti',
  [IncidentSubType.FIRST_AID]: 'investigation.firstAid',
  [IncidentSubType.NON_INJURY]: 'investigation.noneInjury',
  [IncidentSubType.PROPERTY_DAMAGE]: 'investigation.propertyDamage',
};

// Investigation Reasons

export enum CONTACT_WITH {
  UTILITIES_CHEMICALS_FIRE,
  MOVING_VEHICLE,
  MOVING_MACHINERY,
  FIXED_OR_STATIONARY_OBJECT,
}

export enum STRUCK_BY {
  MOVING_OBJECT,
  FLYING_OBJECT,
  FALLING_OBJECT,
}

export enum SLIPS_TRIPS_FALLS {
  STF,
  FALLS_FROM_HEIGHT,
}

export enum OTHER {
  MANUAL_HANDLING_OR_ERGONOMICS,
  TRAPPED_OR_CRUSHED,
  MISS_FIRE,
  OTHER,
}

export const InvestigationReason = [CONTACT_WITH, STRUCK_BY, SLIPS_TRIPS_FALLS, OTHER];

// Investigation Cause

export enum PEOPLE {
  BEHAVIOUR,
  CAPABILITIES_OR_COMPETENCE,
  COMMUNICATION,
  SUPERVISION,
  HEALTH_OR_FATIGUE,
  DISTRACTION,
}

export enum ENVIRONMENT {
  ENVIRONMENTAL_CONDITIONS,
  LAYOUT_OR_SPACE,
  WORKING_CONDITIONS,
  HOUSEKEEPING,
  VANDALISM_OR_SABOTAGE,
}

export enum EQUIPMENT_MATERIAL {
  SUITABILITY_OF,
  USABILITY_OF,
  CONDITION_OF,
  EXPOSURE_TO,
}

export enum WORKING_SCHEDULE {
  WORKING_SCHEDULE,
  EXISTENCE_OF,
  SUITABILITY_OF,
  EXECUTION_OF,
}

export const InvestigationCause = [PEOPLE, ENVIRONMENT, EQUIPMENT_MATERIAL, WORKING_SCHEDULE];

// Incident Reason

export enum INCIDENT_SLIPS_TRIPS_FALLS {
  SLIP,
  TRIP,
  FALLS_FROM_HEIGHT,
  FALLS,
}

export const IncidentReason = [CONTACT_WITH, STRUCK_BY, INCIDENT_SLIPS_TRIPS_FALLS, OTHER];

// Incident Cause

export enum INCIDENT_PEOPLE {
  BEHAVIOUR,
  CAPABILITIES_OR_COMPETENCE,
  COMMUNICATION,
  SUPERVISION,
  HEALTH_OR_FATIGUE,
  DISTRACTION_LACK_OF_CONCENTRATION,
}
export enum INCIDENT_ENVIRONMENT {
  SITE_ENVIRONMENTAL_CONDITIONS,
  LAYOUT_SPACE,
  WORKING_ENVIRONMENT,
  HOUSEKEEPING,
  VANDALISM_OR_SABOTAGE,
}

export enum INCIDENT_EQUIPMENT_MATERIAL {
  SUITABILITY_MATERIALS_EQUIPMENT,
  USABILITY_MATERIALS_EQUIPMENT,
  CONDITION_MATERIALS_EQUIPMENT,
  EXPOSURE_TO_EQUIPMENT_MATERIALS,
}

export enum PROCESS_AND_PROCEDURE {
  SCHEDULE_OF_PROCESS_AND_PROCEDURES,
  EXISTENCE_OF_PROCESS_AND_PROCEDURES,
  SUITABILITY_OF_PROCESS_AND_PROCEDURES,
  EXECUTION_OF_PROCESS_AND_PROCEDURES,
}

export const IncidentCause = [
  INCIDENT_PEOPLE,
  INCIDENT_ENVIRONMENT,
  INCIDENT_EQUIPMENT_MATERIAL,
  PROCESS_AND_PROCEDURE,
];

// Incident Risk Matrix

export enum RiskProbability {
  RARE = 'RARE',
  UNLIKELY = 'UNLIKELY',
  MODERATE = 'MODERATE',
  LIKELY = 'LIKELY',
  ALMOST_CERTAIN = 'ALMOST_CERTAIN',
}

export const RiskProbabilityLabel: Record<RiskProbability, string> = {
  [RiskProbability.RARE]: 'chatbot.riskAssessment.probability.rare',
  [RiskProbability.UNLIKELY]: 'chatbot.riskAssessment.probability.unlikely',
  [RiskProbability.MODERATE]: 'chatbot.riskAssessment.probability.moderate',
  [RiskProbability.LIKELY]: 'chatbot.riskAssessment.probability.likely',
  [RiskProbability.ALMOST_CERTAIN]: 'chatbot.riskAssessment.probability.almostCertain',
};

export const RiskProbabilityLabelWithTranslation: Record<RiskProbability, (t: TFunction) => string> = {
  [RiskProbability.RARE]: (t) => t('chatbot.riskAssessment.probability.rare'),
  [RiskProbability.UNLIKELY]: (t) => t('chatbot.riskAssessment.probability.unlikely'),
  [RiskProbability.MODERATE]: (t) => t('chatbot.riskAssessment.probability.moderate'),
  [RiskProbability.LIKELY]: (t) => t('chatbot.riskAssessment.probability.likely'),
  [RiskProbability.ALMOST_CERTAIN]: (t) => t('chatbot.riskAssessment.probability.almostCertain'),
};

export enum RiskImpact {
  INSIGNIFICANT = 'INSIGNIFICANT',
  MINOR = 'MINOR',
  SIGNIFICANT = 'SIGNIFICANT',
  MAJOR = 'MAJOR',
  SEVERE = 'SEVERE',
}

export const RiskImpactLabel: Record<RiskImpact, string> = {
  [RiskImpact.INSIGNIFICANT]: 'chatbot.riskAssessment.impact.insignificant',
  [RiskImpact.MINOR]: 'chatbot.riskAssessment.impact.minor',
  [RiskImpact.SIGNIFICANT]: 'chatbot.riskAssessment.impact.significant',
  [RiskImpact.MAJOR]: 'chatbot.riskAssessment.impact.major',
  [RiskImpact.SEVERE]: 'chatbot.riskAssessment.impact.severe',
};

export const RiskImpactLabelWithTranslation: Record<RiskImpact, (t: TFunction) => string> = {
  [RiskImpact.INSIGNIFICANT]: (t) => t('chatbot.riskAssessment.impact.insignificant'),
  [RiskImpact.MINOR]: (t) => t('chatbot.riskAssessment.impact.minor'),
  [RiskImpact.SIGNIFICANT]: (t) => t('chatbot.riskAssessment.impact.significant'),
  [RiskImpact.MAJOR]: (t) => t('chatbot.riskAssessment.impact.major'),
  [RiskImpact.SEVERE]: (t) => t('chatbot.riskAssessment.impact.severe'),
};

export enum RiskLevel {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
  EXTREME = 'EXTREME',
}

export const RiskLevelLabel: Record<RiskLevel, (t: TFunction) => string> = {
  VERY_LOW: (t) => t('shared.veryLow', 'Very low'),
  LOW: (t) => t('shared.low', 'Low'),
  MEDIUM: (t) => t('shared.medium', 'Medium'),
  HIGH: (t) => t('shared.high', 'High'),
  VERY_HIGH: (t) => t('shared.veryHigh', 'Very high'),
  EXTREME: (t) => t('shared.extreme', 'Extreme'),
};

// Incident location
export enum LocationType {
  MAP = 'MAP',
  ADDRESS = 'ADDRESS',
}

export const LocationTypeLabel: Record<LocationType, string> = {
  [LocationType.MAP]: 'questionnaire.mapSelection',
  [LocationType.ADDRESS]: 'shared.address',
};

// Incident involved person type
export enum IncidentInvolvedPersonType {
  EMPLOYEE = 'EMPLOYEE',
  AGENCY = 'AGENCY',
  CONTRACTOR = 'CONTRACTOR',
  VISITOR = 'VISITOR',
  MEMBER_OF_PUBLIC = 'MEMBER_OF_PUBLIC',
}

export const IncidentInvolvedPersonTypeLabel: Record<IncidentInvolvedPersonType, string> = {
  [IncidentInvolvedPersonType.EMPLOYEE]: 'questionnaire.employee',
  [IncidentInvolvedPersonType.AGENCY]: 'questionnaire.agency',
  [IncidentInvolvedPersonType.CONTRACTOR]: 'questionnaire.contractor',
  [IncidentInvolvedPersonType.VISITOR]: 'questionnaire.visitor',
  [IncidentInvolvedPersonType.MEMBER_OF_PUBLIC]: 'questionnaire.memberOfPublic',
};

export enum IncidentHighPotentialScenarioType {
  LOTOTO_AND_MACHINE_GUARDING = 'LOTOTO AND/OR MACHINE GUARDING',
  WORKING_AT_HEIGHT = 'WORKING AT HEIGHT',
  CONTACT_WITH_MOVING_VEHICLE = 'CONTACT WITH MOVING VEHICLE OR VEHICLE COLLISION',
  BEING_TRAPPED = 'BEING TRAPPED BETWEEN OR IN FRONT OF A SIGNIFICANT MASS OR ENERGY',
  SUSPENDED_LOADS = 'SUSPENDED LOADS, MOVING OR FALLING OBJECTS',
  CONFINED_SPACE = 'CONFINED SPACE',
  FIRE_OR_EXPLOSION = 'FIRE OR EXPLOSION',
  NO = 'NO',
}

export const IncidentHighPotentialScenarioTypeLabel: Record<IncidentHighPotentialScenarioType, string> = {
  [IncidentHighPotentialScenarioType.LOTOTO_AND_MACHINE_GUARDING]: 'questionnaire.lototoMachineGuarding',
  [IncidentHighPotentialScenarioType.WORKING_AT_HEIGHT]: 'questionnaire.workingAtHeight',
  [IncidentHighPotentialScenarioType.CONTACT_WITH_MOVING_VEHICLE]: 'questionnaire.contactWithMovingVehicleOrCollision',
  [IncidentHighPotentialScenarioType.BEING_TRAPPED]: 'questionnaire.trappedBetweenMassOrEnergy',
  [IncidentHighPotentialScenarioType.SUSPENDED_LOADS]: 'questionnaire.suspendedLoadsMovingAndFallingObjects',
  [IncidentHighPotentialScenarioType.CONFINED_SPACE]: 'questionnaire.confinedSpace',
  [IncidentHighPotentialScenarioType.FIRE_OR_EXPLOSION]: 'questionnaire.fireOrExplosion',
  [IncidentHighPotentialScenarioType.NO]: 'shared.no',
};

export enum IncidentHarmOrPropertyDamage {
  NONE = 'NONE',
  FIRST_AID_STAYED_AT_WORK = 'FIRST AID (STAYED AT WORK)',
  FIRST_AID_SENT_HOME = 'FIRST AID (SENT HOME)',
  HOSPITAL = 'HOSPITAL',
  PROPERTY_DAMAGE = 'PROPERTY DAMAGE',
}

export const IncidentHarmOrPropertyDamageLabel: Record<IncidentHarmOrPropertyDamage, string> = {
  [IncidentHarmOrPropertyDamage.NONE]: 'shared.none',
  [IncidentHarmOrPropertyDamage.FIRST_AID_STAYED_AT_WORK]: 'questionnaire.firstAidAtWork',
  [IncidentHarmOrPropertyDamage.FIRST_AID_SENT_HOME]: 'questionnaire.firstAidAtHome',
  [IncidentHarmOrPropertyDamage.HOSPITAL]: 'questionnaire.hospital',
  [IncidentHarmOrPropertyDamage.PROPERTY_DAMAGE]: 'questionnaire.propertyDamage',
};

export enum AuditType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  HOUSEKEEPING = 'HOUSEKEEPING',
  ENFORCEMENT = 'ENFORCEMENT',
}

export const AuditTypeLabel: Record<AuditType, string> = {
  [AuditType.INTERNAL]: 'questionnaire.internal',
  [AuditType.EXTERNAL]: 'questionnaire.external',
  [AuditType.HOUSEKEEPING]: 'questionnaire.housekeeping',
  [AuditType.ENFORCEMENT]: 'questionnaire.enforcement',
};
