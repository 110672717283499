import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { DropdownProps } from '../../../shared';

class HasDropdown<T> extends React.Component<DropdownProps<T>, {}> {
  onChangeWrapper = (value: SelectValue, option: any) => {
    this.props.onChange && this.props.onChange(value, option);
  };

  onSelectWrapper = (value: SelectValue, option: any) => {
    this.props.onSelect && this.props.onSelect(value, option);
  };

  onDeselectWrapper = (value: SelectValue, option: any) => {
    this.props.onDeselect && this.props.onDeselect(value, option);
  };

  render() {
    const { onChange, onSelect, onDeselect, children, ...restProps } = this.props;
    return (
      <Select
        showSearch
        optionFilterProp="label"
        {...restProps}
        onChange={this.onChangeWrapper}
        onSelect={this.onSelectWrapper}
        onDeselect={this.onDeselectWrapper}
      >
        {children}
      </Select>
    );
  }
}

export default HasDropdown;
