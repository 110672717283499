import { CaretRightOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Tabs } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import React from 'react';
import { Subscription } from 'rxjs';
import {
  HasButton,
  HasCheckbox,
  HasCollapse,
  HasSpinner,
  HasTable,
  HasText,
  Notification,
  Toast,
} from '../../components';
import i18n from '../../i18n/config';
import { FormLabel, Incident, IncidentType, IncidentTypeLabel, InvestigationStatus } from '../../models';
import { CompanyService, DivisionService, FormService, IncidentService } from '../../services';
import { DATE_LOCALE, DELIMITER, ErrorMessages, EventCSVFilters, InvestigationStatusLabel } from '../../shared';
import {
  EventUtils,
  TABLE_PAGE_SIZE,
  formatTextToTitlecase,
  getDateTime,
  getFormattedValue,
  getUserFullName,
  getUserId,
  i18nLabelParser,
  isLimitedEmployeePersonal,
} from '../../utils';
import { PaginationConfig } from 'antd/lib/pagination';

interface IncidentCsvExportWizardProps {
  backToTableCallback: () => void;
}

interface IncidentCsvExportWizardState {
  formLabels: FormLabel[];
  loading: boolean;
  exportLoading: boolean;
  extraColumns: ColumnProps<Incident>[];
  filters: EventCSVFilters;
  incidents: Incident[];
  total?: number;
  activeTab: string;
}

const TABLE_ITEM_HEIGHT = 55;
const TABLE_HEADER_HEIGHT = 55;
const TABLE_PAGINATION_HEIGHT = 32 + 16 * 2;

const COLUMN_SELECTION_TAB = 'columnSelection';
const FILTERS_TAB = 'filters';

class HasIncidentCsvExportWizard extends React.Component<IncidentCsvExportWizardProps, IncidentCsvExportWizardState> {
  state: IncidentCsvExportWizardState = {
    formLabels: [],
    loading: true,
    exportLoading: false,
    filters: {
      types: [],
      showHidden: false,
      requiresInvestigation: [true, false],
      investigationStatus: [],
      hasActionsOpen: [true, false],
      loggedUserId: null,
      columns: {},
      pageNumber: 1,
      pageSize: TABLE_PAGE_SIZE,
    },
    activeTab: COLUMN_SELECTION_TAB,
    extraColumns: [],
    incidents: [],
  };

  private subscriptions: Subscription[] = [];
  private dataLoadedOnCompanySubscribe = false;

  getColumns = (): ColumnProps<Incident>[] => [
    {
      title: i18n.t('incidents.incidentType'),
      key: 'type',
      fixed: 'left',
      render: (_, record: Incident) => i18n.t(IncidentTypeLabel[record.type]),
      ellipsis: true,
    },
    {
      title: i18n.t('incidents.reportDate'),
      key: 'reportedAt',
      render: (_, record: Incident) => getDateTime(record.reportedAt),
      ellipsis: true,
    },
    {
      title: i18n.t('shared.company'),
      dataIndex: ['reportingUser', 'company', 'name'],
      key: 'company',
      ellipsis: true,
    },
    {
      title: i18n.t('shared.division'),
      dataIndex: ['reportingUser', 'division', 'name'],
      key: 'department',
      ellipsis: true,
    },
    {
      title: i18n.t('incidents.reportedBy'),
      render: (_, { reportingUser }: Incident) => getUserFullName(reportingUser),
      ellipsis: true,
      key: 'reportingUser',
    },
    {
      title: i18n.t('incidents.investigation'),
      key: 'investigation',
      align: 'center',
      render: (_, record) => (record.investigationRequired ? i18n.t('shared.yes') : i18n.t('shared.no')),
      ellipsis: true,
    },
    {
      title: i18n.t('shared.status'),
      key: 'status',
      align: 'center',
      render: (_, record: Incident) =>
        record.status !== null ? i18n.t(InvestigationStatusLabel[record.status]) : 'Unknown',
    },
    {
      title: i18n.t('incidents.action'),
      key: 'action',
      align: 'center',
      render: (_, record) => (record.hasActions ? i18n.t('shared.yes') : i18n.t('shared.no')),
    },
    {
      title: i18n.t('incidents.hidden'),
      align: 'center',
      render: (_, record) => (record.hidden ? i18n.t('shared.yes') : i18n.t('shared.no')),
    },
  ];

  componentDidMount() {
    this.subscriptions.push(
      CompanyService.getCompanyListener().subscribe(() => {
        this.getIncidentsList();
        this.dataLoadedOnCompanySubscribe = true;
      }),
      DivisionService.getDivisionListener().subscribe((divisionId) => {
        this.dataLoadedOnCompanySubscribe ? (this.dataLoadedOnCompanySubscribe = false) : this.getIncidentsList();
      }),
      IncidentService.getIncidentListChanged().subscribe(() => this.getIncidentsList())
    );
    FormService.getAllFormLabels().then(
      (response) => {
        const types = this.getTypeFilter().map((f) => f.value);
        const investigationStatus = this.getInvestigationStatusFilter().map((f) => f.value);
        const filters = { ...this.state.filters, types, investigationStatus };
        this.setState({ formLabels: response.data, filters });
      },
      (error) => Notification.error(ErrorMessages.CSV_COLUMNS(error.response?.data.message))
    );
  }

  componentDidUpdate(prevProps: Readonly<IncidentCsvExportWizardProps>, prevState: IncidentCsvExportWizardState) {
    if (!isEqual(prevProps, this.props)) {
      if (!isEqual(prevState, this.state)) {
        this.setState({ extraColumns: this.buildExtraColumns(this.state.formLabels) });
      }
    }
    if (!isEqual(prevState.filters, this.state.filters)) {
      this.getIncidentsList();
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getIncidentsList = () => {
    this.setState({ loading: true });
    const loggedUserId = isLimitedEmployeePersonal() ? getUserId() : null;
    const { filters } = this.state;
    filters.loggedUserId = loggedUserId;
    IncidentService.getAllPaginatedCSV(filters)
      .then(
        ({ data }) => {
          this.setState({ incidents: data.content, loading: false, total: data.totalElements });
        },
        (error) => Notification.error({ message: error.response.data.message })
      )
      .catch((error) => Notification.error({ message: error.response.data.message }));
  };

  // Column Selection

  getColumnKey = (formType: IncidentType, key: string): string => formType.toString().concat(DELIMITER).concat(key);

  buildExtraColumns = (formLabels: FormLabel[]): ColumnProps<Incident>[] => {
    return formLabels
      .map((formLabel) => this.generateColumnsForLabelSet(formLabel))
      .reduce((acc, val) => acc.concat(val), []);
  };

  generateColumnsForLabelSet = (formLabel: FormLabel): ColumnProps<Incident>[] => {
    return formLabel.questionLabels.map((questionLabel) => this.generateColumn(formLabel.formType, questionLabel));
  };

  generateColumn = (
    formType: IncidentType,
    questionLabel: { key: string; question: string }
  ): ColumnProps<Incident> => ({
    title: (_) => i18n.t(questionLabel.question),
    key: this.getColumnKey(formType, questionLabel.key),
    align: 'center',
    ellipsis: true,
    render: (_, record: Incident) => {
      const formResultEntry = EventUtils.getLatestFormResultByKey(record.formResult, questionLabel.key).maxItem;
      return formResultEntry && formResultEntry.value !== ''
        ? i18nLabelParser(getFormattedValue(formResultEntry.type, formResultEntry.value))
        : '-';
    },
  });

  allQuestionsForTypeSelected = (formLabel: FormLabel) => {
    const extraColumns = [...this.state.extraColumns].filter(
      (col) => col.key && col.key.toString().split(DELIMITER)[0] === formLabel.formType.toString()
    );
    const columnKeys = formLabel.questionLabels.map((label) => this.getColumnKey(formLabel.formType, label.key));
    return (
      extraColumns.length > 0 &&
      extraColumns.every((column) => column.key && columnKeys.includes(column.key.toString()))
    );
  };

  formQuestionsBatchSelect = (event: CheckboxChangeEvent, formLabel: FormLabel) => {
    let extraColumns = [...this.state.extraColumns];
    if (event.target.checked) {
      const newColumns = this.generateColumnsForLabelSet(formLabel);
      extraColumns = extraColumns.concat(newColumns);
    } else {
      const columnKeys = formLabel.questionLabels.map((label) => this.getColumnKey(formLabel.formType, label.key));
      extraColumns = extraColumns.filter((val) => val.key && !columnKeys.includes(val.key.toString()));
    }
    this.setState({ extraColumns });
  };

  toggleColumn = (event: CheckboxChangeEvent, formType: IncidentType, label: { key: string; question: string }) => {
    let extraColumns = [...this.state.extraColumns];
    if (event.target.checked) {
      extraColumns.push(this.generateColumn(formType, label));
    } else {
      extraColumns = extraColumns.filter((val) => val.key !== this.getColumnKey(formType, label.key));
    }
    this.setState({ extraColumns });
  };

  // Filters

  getTypeFilter = (): { value: string; label: string }[] => {
    let keys = Object.values(IncidentType);
    return keys.map((key) => ({ value: key, label: i18n.t(IncidentTypeLabel[key]) }));
  };

  getRequiresInvestigationFilter = (): { value: boolean; label: string }[] => [
    { value: true, label: i18n.t('shared.yes') },
    { value: false, label: i18n.t('shared.no') },
  ];

  getInvestigationStatusFilter = (): { value: string; label: string }[] => {
    let keys = Object.values(InvestigationStatus);
    return keys.map((key) => ({ value: key, label: i18n.t(InvestigationStatusLabel[key]) }));
  };

  getHasActionsOpenFilter = (): { value: boolean; label: string }[] => [
    { value: true, label: i18n.t('shared.yes') },
    { value: false, label: i18n.t('shared.no') },
  ];

  canModifyFilter = (values: CheckboxValueType[]): boolean => {
    if (values.length < 1) {
      Toast.error(ErrorMessages.CSV_FILTER_ONE_OPTION_SELECTED);
      return false;
    }
    return true;
  };

  hiddenEventsChanged = (event: CheckboxChangeEvent) => {
    const showHidden = event.target.checked;
    const filters = { ...this.state.filters, showHidden };
    this.setState({ filters });
  };

  typeFilterChanged = (checkedValues: CheckboxValueType[]) => {
    if (!this.canModifyFilter(checkedValues)) {
      return;
    }
    const types = checkedValues.map((val) => val.toString());
    const filters = { ...this.state.filters, types };
    const extraColumns = this.state.extraColumns.filter(
      (val) => val.key && types.includes(val.key.toString().split(DELIMITER)[0])
    );
    this.setState({ filters, extraColumns });
  };

  requiresInvestigationChanged = (checkedValues: CheckboxValueType[]) => {
    if (!this.canModifyFilter(checkedValues)) {
      return;
    }
    const requiresInvestigation = checkedValues as boolean[];
    const filters = { ...this.state.filters, requiresInvestigation };
    this.setState({ filters });
  };

  investigationStatusChanged = (checkedValues: CheckboxValueType[]) => {
    if (!this.canModifyFilter(checkedValues)) {
      return;
    }
    const investigationStatus = checkedValues as string[];
    const filters = { ...this.state.filters, investigationStatus };
    this.setState({ filters });
  };

  hasActionsOpenChanged = (checkedValues: CheckboxValueType[]) => {
    if (!this.canModifyFilter(checkedValues)) {
      return;
    }
    const hasActionsOpen = checkedValues as boolean[];
    const filters = { ...this.state.filters, hasActionsOpen };

    this.setState({ filters });
  };

  // Other functions

  downloadCsv = () => {
    this.setState({ exportLoading: true });
    const { filters, extraColumns } = this.state;
    let columns: string[] = [];
    if (isLimitedEmployeePersonal()) {
      filters.loggedUserId = getUserId();
    }
    extraColumns.forEach((val) => val.key && columns.push(val.key?.toString().split(DELIMITER)[1]));
    IncidentService.getCsv(columns, filters).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${formatTextToTitlecase(i18n.t('incidents.event_plural'))}-${new Date().toLocaleDateString(DATE_LOCALE)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        this.setState({ exportLoading: false });
      },
      (error) => {
        this.setState({ exportLoading: false });
        Notification.error(ErrorMessages.CSV_FAIL(error.response?.data.message));
      }
    );
  };

  onTableChange = (pagination: PaginationConfig) => {
    this.setState((prevState) => ({ ...prevState, filters: { ...prevState.filters, pageNumber: pagination.current } }));
  };

  handleTabChange = (key: string) => {
    this.setState({ activeTab: key });
  };

  render() {
    const { TabPane } = Tabs;
    const { backToTableCallback } = this.props;
    const { formLabels, filters, extraColumns, loading, exportLoading, total, incidents, activeTab } = this.state;
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <HasButton type="link" onClick={backToTableCallback} style={{ display: 'flex', alignItems: 'center' }}>
            <LeftOutlined style={{ verticalAlign: 'middle' }} />
            {i18n.t('incidents.backToEventsTable')}
          </HasButton>
          <HasButton type="primary" disabled={loading} onClick={this.downloadCsv} loading={exportLoading}>
            {i18n.t('incidents.exportEvents', { count: total })}
          </HasButton>
        </div>
        <div className="d-flex flex-row flex-grow-1 overflow-y-auto">
          {loading ? (
            <HasSpinner size="large" />
          ) : (
            <React.Fragment>
              <Tabs
                onChange={this.handleTabChange}
                activeKey={activeTab}
                style={{ marginRight: '20px', width: '20%', paddingLeft: '10px', maxHeight: '100%' }}
                className="overflow-y-auto"
              >
                <TabPane tab={i18n.t('incidents.columnSelection')} key={COLUMN_SELECTION_TAB}>
                  {formLabels.map((formLabel, index) => (
                    <HasCollapse
                      key={index}
                      bordered={false}
                      expandIcon={(panelProps) => <CaretRightOutlined rotate={panelProps.isActive ? 90 : 0} />}
                      panelConfigs={[
                        {
                          key: formLabel.formType,
                          header: (
                            <div className="d-flex flex-row">
                              <HasCheckbox
                                content={''}
                                checked={this.allQuestionsForTypeSelected(formLabel)}
                                disabled={!filters.types.includes(formLabel.formType.toString())}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                onChange={(event) => this.formQuestionsBatchSelect(event, formLabel)}
                              />
                              <HasText
                                content={i18n.t(IncidentTypeLabel[formLabel.formType])}
                                strong
                                className="d-block"
                              />
                            </div>
                          ),
                          content: formLabel.questionLabels.map((label, index) => (
                            <HasCheckbox
                              key={index}
                              disabled={!filters.types.includes(formLabel.formType.toString())}
                              checked={extraColumns
                                .map((val) => val.key)
                                .includes(this.getColumnKey(formLabel.formType, label.key))}
                              content={i18n.t(label.question)}
                              value={label.question}
                              onChange={(event) => this.toggleColumn(event, formLabel.formType, label)}
                              style={{ marginLeft: '10px' }}
                              className="d-block"
                            />
                          )),
                          className: 'has-collapse has-collapse-panel',
                        },
                      ]}
                      className="has-collapse"
                    />
                  ))}
                </TabPane>
                <TabPane tab={'Filters'} key={FILTERS_TAB} className="overflow-y-auto">
                  <HasCheckbox
                    content={i18n.t('incidents.includeHidden')}
                    checked={filters.showHidden}
                    onChange={this.hiddenEventsChanged}
                  />
                  <Divider style={{ margin: '10px 0' }} />
                  <HasText content={i18n.t('incidents.eventType')} strong />
                  <Checkbox.Group
                    onChange={this.typeFilterChanged}
                    value={filters.types}
                    style={{ marginTop: '10px' }}
                    className="d-block"
                  >
                    <Row>
                      {this.getTypeFilter().map((filter, index) => (
                        <Col key={index} span={24}>
                          <Checkbox value={filter.value}>{filter.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                  <Divider style={{ margin: '10px 0' }} />
                  <HasText content={i18n.t('incidents.requiresInvestigation')} strong />
                  <Checkbox.Group
                    onChange={this.requiresInvestigationChanged}
                    value={filters.requiresInvestigation}
                    style={{ marginTop: '10px' }}
                    className="d-block"
                  >
                    <Row>
                      {this.getRequiresInvestigationFilter().map((filter, index) => (
                        <Col key={index} span={24}>
                          <Checkbox value={filter.value}>{filter.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                  <Divider style={{ margin: '10px 0' }} />
                  <HasText content={i18n.t('incidents.investigationStatus')} strong />
                  <Checkbox.Group
                    onChange={this.investigationStatusChanged}
                    value={filters.investigationStatus}
                    style={{ marginTop: '10px' }}
                    className="d-block"
                  >
                    <Row>
                      {this.getInvestigationStatusFilter().map((filter, index) => (
                        <Col key={index} span={24}>
                          <Checkbox value={filter.value}>{filter.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                  <Divider style={{ margin: '10px 0' }} />
                  <HasText content={i18n.t('incidents.hasActionsOpen')} strong />
                  <Checkbox.Group
                    onChange={this.hasActionsOpenChanged}
                    value={filters.hasActionsOpen}
                    style={{ marginTop: '10px' }}
                    className="d-block"
                  >
                    <Row>
                      {this.getHasActionsOpenFilter().map((filter, index) => (
                        <Col key={index} span={24}>
                          <Checkbox value={filter.value}>{filter.label}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </TabPane>
              </Tabs>
              <div className="d-flex flex-column" style={{ width: '80%' }}>
                <HasTable<Incident>
                  rowKey="id"
                  loading={loading}
                  onChange={this.onTableChange}
                  columns={this.getColumns().concat(extraColumns)}
                  data={incidents}
                  total={total}
                  horizontalScroll={true}
                  defaultPageNumber={this.state.filters.pageNumber}
                  style={{ overflowX: 'auto' }}
                  rowClassName={(record: Incident) => (record.hidden ? 'has-hidden-incident' : '')}
                  tableHeaderHeight={TABLE_HEADER_HEIGHT}
                  tableItemHeight={TABLE_ITEM_HEIGHT}
                  tablePaginationHeight={TABLE_PAGINATION_HEIGHT}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HasIncidentCsvExportWizard;
