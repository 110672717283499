import { URL } from '../rest.service';
import { AttachmentService } from '../attachments';

class ReviewAttachmentService extends AttachmentService {
  protected entityURL: string = URL.REVIEW_ATTACHMENT;
  protected uploadSubURL: string = 'upload';
  protected attachmentURL: string = URL.REVIEW_ATTACHMENT;
}

export default new ReviewAttachmentService();
