import { isEqual } from 'lodash';
import React from 'react';
import { FaRegBuilding } from 'react-icons/fa';
import { Company, Division, UserRole } from '../../../models';
import { COMPANY_FILTER, CompanyFilterHeader } from '../../../shared';
import { CompanyService, DivisionService } from '../../../services';
import {
  getUserId,
  getUserDivisionId,
  getUserRole,
  isLimitedEmployeePersonal,
  updateCompaniesInLocalStorage,
} from '../../../utils';
import { HasText } from '../../atoms';

interface HeaderCompanyInfoProps {
  companies: Company[];
}

interface HeaderCompanyInfoState {
  userCompany: Company;
  userDivision: Division | null;
}

class HasHeaderCompanyInfo extends React.Component<HeaderCompanyInfoProps, HeaderCompanyInfoState> {
  private companyFilter: CompanyFilterHeader = { companies: [] };

  getDivisionTree(division: Division): Division[] {
    const divisions: Division[] = [division];

    if (division.subDivisions) {
      division.subDivisions.forEach((subDivision) => {
        divisions.push(...this.getDivisionTree(subDivision));
      });
    }
    return divisions;
  }

  constructor(props: Readonly<HeaderCompanyInfoProps>) {
    super(props);
    const { companies } = this.props;
    const userCompany = companies.filter((company) => company.isPartnerCompany === false)[0];
    this.state = { userCompany, userDivision: null };
  }

  componentDidMount() {
    this.setUserDivision();
  }

  componentDidUpdate(prevProps: Readonly<HeaderCompanyInfoProps>) {
    if (!isEqual(this.props.companies, prevProps.companies)) {
      const userCompany = this.props.companies.filter((company) => company.isPartnerCompany === false)[0];
      this.setState({ userCompany });
    }
  }

  setUserDivision() {
    const { userCompany } = this.state;
    const userDivisionId = getUserDivisionId();
    if (!userDivisionId) {
      return;
    }
    DivisionService.getById(userDivisionId).then(({ data: userDivision }) => {
      let divisionList: Division[] = [];
      let userId;
      switch (getUserRole()) {
        case UserRole.LIMITED_EMPLOYEE_DIVISION:
          divisionList = userDivision ? this.getDivisionTree(userDivision) : [];
          break;
        case UserRole.LIMITED_EMPLOYEE_PERSONAL:
          divisionList = userDivision ? [userDivision] : [];
          break;
        default:
          divisionList = userCompany.divisions;
      }
      const loggedInUserId = getUserId();
      const filter = localStorage.getItem(COMPANY_FILTER);
      if (filter) {
        userId = JSON.parse(filter) as CompanyFilterHeader;
      }
      if (loggedInUserId) {
        if ((userId && +userId !== loggedInUserId) || !userId) {
          updateCompaniesInLocalStorage([userCompany], divisionList, this.companyFilter, loggedInUserId);
        } else {
          updateCompaniesInLocalStorage([userCompany], divisionList, this.companyFilter, +userId);
        }
      }
      this.setState({ userDivision });
      // DO NOT REMOVE
      CompanyService.selectedCompanyChanged(777);
    });
  }

  render() {
    const { userCompany, userDivision } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FaRegBuilding style={{ fontSize: '18px', marginRight: '8px', opacity: 0.6 }} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <HasText style={{ lineHeight: '1' }} content={userCompany.name} strong />
          {(getUserRole() === UserRole.LIMITED_EMPLOYEE_DIVISION || isLimitedEmployeePersonal()) && (
            <HasText style={{ fontSize: '12px', lineHeight: '1' }} content={userDivision?.name} />
          )}
        </div>
      </div>
    );
  }
}

export default HasHeaderCompanyInfo;
