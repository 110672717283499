import { CloseCircleFilled } from '@ant-design/icons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { HasAlert, HasParagraph, HasText, HasTitle } from '../../components';
import { COLORS } from '../../shared';

class HasServerErrorPage extends React.Component<
  RouteComponentProps<{}, {}, { forUrl: string; errorMessage: string }> & WithTranslation,
  {}
> {
  render() {
    const {
      location: { state },
      t,
    } = this.props;
    return (
      <div className="shadow-sm has-container-tile h-100">
        <div className="has-fallback-page">
          <CloseCircleFilled style={{ fontSize: '120px', color: COLORS.ERROR, marginBottom: '20px' }} />
          <HasTitle content={t('fallback.fallback500Title')} level={1} type="secondary" />
          <HasParagraph content={t('fallback.fallback500Text')} style={{ fontSize: '16px', marginBottom: '20px' }} />
          {state && (state.forUrl || state.errorMessage) && (
            <HasAlert
              type="error"
              message={t('fallback.errorInformation')}
              description={
                <div className="d-flex flex-column">
                  <HasText content={t('fallback.errorOccurredOn')} />
                  <HasText content={state.forUrl} strong style={{ marginLeft: '20px' }} />
                  <HasText content={` ${t('fallback.withMessage')} `} />
                  <HasText content={state.errorMessage} style={{ marginLeft: '20px' }} className="text-break" />
                </div>
              }
              style={{ maxWidth: '700px', minWidth: '400px', marginBottom: '40px' }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(HasServerErrorPage);
