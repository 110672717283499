import { MenuProps } from 'antd/lib/menu';
import React from 'react';
import { MenuItemWithKey } from '../../../shared';
import { HasMenu } from '../../molecules';

interface SideMenuProps {
  menuItems: MenuItemWithKey[];
}

class HasSideMenu extends React.Component<MenuProps & SideMenuProps, {}> {
  render() {
    return <HasMenu {...this.props} />;
  }
}

export default HasSideMenu;
