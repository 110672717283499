import { Skeleton } from 'antd';
import React from 'react';
import { HasSearchInput, HasText } from '../../components';
import i18n from '../../i18n/config';

interface CompaniesHeaderProps {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  dataLength: number;
}

class HasCompaniesHeader extends React.Component<CompaniesHeaderProps, {}> {
  render() {
    const { onSearch, loading, dataLength } = this.props;
    return (
      <div style={{ marginBottom: '16px' }}>
        <HasSearchInput onSearch={onSearch} disabled={loading} />
        <Skeleton loading={loading} active paragraph={false} title={{ width: '150px' }}>
          <HasText
            content={i18n.t('dataDisplay.displayingResults', { count: dataLength })}
            style={{ fontSize: '12px' }}
          />
        </Skeleton>
      </div>
    );
  }
}

export default HasCompaniesHeader;
