import { Action, ActionFormAttributes, ActionStatus, Permission, PermissionType, User, UserRole } from '../models';
import { getUserId, hasPermissionType, isUserRoleEqualsTo } from '.';
import moment from 'moment';
import { ActionStatusColor, ActionStatusCssClass, ActionStatusLabel } from '../shared';
import i18n from '../i18n/config';

export interface ActionPermissions {
  create: boolean;
  write: boolean;
  changeStatus: boolean;
  delete: boolean;
  edit: boolean;
  reassign: boolean;
}

export function getActionPermissions(action?: Action, permissions?: Permission[]): ActionPermissions {
  return {
    create: canCreateAction(permissions),
    write: canWriteAction(),
    changeStatus: canChangeActionStatus(permissions),
    delete: canDeleteAction(),
    edit: canEditActionDetails(permissions),
    reassign: canReassignAction(permissions),
  };
}

export function canCreateAction(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_CREATE, permissions);
}

export function canWriteAction(permissions?: Permission[]): boolean {
  const writePermissionTypes = [
    PermissionType.ACTIONS_EDIT,
    PermissionType.ACTIONS_ASSIGN,
    PermissionType.ACTIONS_CHANGE_STATUS,
  ];
  for (const type of writePermissionTypes) {
    return (
      hasPermissionType(type, permissions) ||
      isUserRoleEqualsTo(UserRole.SUPER_ADMIN) ||
      isUserRoleEqualsTo(UserRole.OWNER)
    );
  }
  return false;
}

export function canChangeActionStatus(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_CHANGE_STATUS, permissions);
}

export function canDeleteAction(): boolean {
  return isUserRoleEqualsTo(UserRole.SUPER_ADMIN) || isUserRoleEqualsTo(UserRole.OWNER);
}

export function canEditActionDetails(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_EDIT, permissions);
}

export function canReassignAction(permissions?: Permission[]): boolean {
  return hasPermissionType(PermissionType.ACTIONS_ASSIGN, permissions);
}

export function getNewActionHistory(): ActionFormAttributes {
  return {
    dueDate: [],
    importance: [],
    otherInformation: [],
    requiredAction: [],
    responsibleUserId: [],
    responsibleUserName: [],
  };
}

export const isLoggedInUserCreatorOrSuperAdminOrOwner = (action?: Action, user?: User) => {
  return user?.id === action?.createdByUserId || user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.OWNER;
};

export const isDateOverdueToday = (dueDate: Date | string) => {
  return moment(dueDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
};

export const getActionStatusText = (action: Action): string => {
  if (!action.status || !action.dueDate) {
    return '';
  }
  return isActionOverdue(action.status, action.dueDate)
    ? i18n.t(ActionStatusLabel[ActionStatus.OVERDUE])
    : i18n.t(ActionStatusLabel[action.status]);
};

export const isActionOverdue = (status: ActionStatus, dueDate: Date | string) =>
  status === ActionStatus.OPEN && isDateOverdueToday(dueDate);

export const getActionStatusColor = (action: Action): string => {
  if (!action.status || !action.dueDate) {
    return '';
  }
  return isActionOverdue(action.status, action.dueDate) ? 'red' : ActionStatusColor[action.status];
};

export const getActionStatusCssClass = (action: Action): string => {
  if (!action.status || !action.dueDate) {
    return '';
  }
  return isActionOverdue(action.status, action.dueDate) ? 'red-dot' : ActionStatusCssClass[action.status];
};
