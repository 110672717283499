import { TableFilters } from '../shared';
import { ReactText } from 'react';

export interface Pagination {
  pageNumber?: number;
  pageSize?: number;
  searchTerm?: string;
}

type JavaSortDirection = 'ASC' | 'DESC';
const javaSortOrder: Record<string, JavaSortDirection> = {
  ascend: 'ASC',
  descend: 'DESC',
};

interface ColumnFilters {
  [key: string]: {
    sortDirection?: JavaSortDirection;
    values?: ReactText[];
    stringValues?: ReactText[];
  };
}

class PaginationService {
  createPagination(filters: TableFilters): Pagination {
    return {
      pageNumber: filters.pageNumber ? filters.pageNumber - 1 : 0,
      pageSize: filters.pageSize,
      searchTerm: filters.searchTerm?.trim().toLocaleLowerCase(),
    };
  }

  createFilters(filters: TableFilters): ColumnFilters {
    let returnFilters: ColumnFilters = {};
    Object.entries(filters.columns).forEach((column) => {
      const field = column[0];
      returnFilters[field] = {};
      if (column[1].defaultFilteredValue) {
        const { values, stringValues } = this.splitValues(column[1].defaultFilteredValue);
        returnFilters[field].values = values;
        returnFilters[field].stringValues = stringValues;
      }
      if (column[1].defaultSortOrder) {
        returnFilters[field].sortDirection = javaSortOrder[column[1].defaultSortOrder];
      }
    });
    return returnFilters;
  }

  private splitValues(inputValues: ReactText[]): { values: ReactText[]; stringValues: ReactText[] } {
    const values: ReactText[] = [];
    const stringValues: ReactText[] = [];
    inputValues.forEach((value) => {
      (isNaN(Number.parseInt(value as string)) ? stringValues : values).push(value);
    });
    return { values, stringValues };
  }
}

export default new PaginationService();
