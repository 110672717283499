import Text from 'antd/lib/typography/Text';
import React from 'react';
import i18n from '../../../i18n/config';
import { Incident, IncidentTypeLabel } from '../../../models';
import { COLORS, ListItemWithMetaProps } from '../../../shared';
import { displayPlaceholderIfEmpty, getDate, getTime, getUserFullName, IncidentIcons } from '../../../utils';
import { HasImage } from '../../atoms';
import { HasList } from '../../molecules';

interface ListProps {
  incidents: Incident[];
  onRowClick?: (incident: Incident) => void;
}

class HasRecentIncidentsList extends React.Component<ListProps, any> {
  getListItemConfig = (incident: Incident): ListItemWithMetaProps => ({
    item: {
      style: { paddingLeft: '10px', paddingRight: '10px', marginLeft: '-10px', marginRight: '-10px' },
    },
    meta: {
      title: (
        <span style={{ fontSize: 12, margin: 0, fontWeight: 600, color: COLORS.GRAY_DARK }}>
          {i18n.t('incidents.reportedTimeStamped', {
            name: getUserFullName(incident.reportingUser),
            time: getTime(incident.reportedAt, { hour: 'numeric', minute: 'numeric' }),
            date: getDate(incident.reportedAt),
          })}
        </span>
      ),
      description: <Text style={{ fontSize: 12 }}>{displayPlaceholderIfEmpty(incident?.description)}</Text>,
      avatar: (
        <HasImage
          src={IncidentIcons[incident.type]}
          alt={IncidentTypeLabel[incident.type]}
          style={{ width: '40px', height: '40px', objectFit: 'contain', marginTop: '4px' }}
        />
      ),
    },
    onRowClick: () => this.props.onRowClick && this.props.onRowClick(incident),
  });

  render() {
    const { incidents } = this.props;
    const listItemsConfig = incidents.map((incident) => this.getListItemConfig(incident));
    return (
      <HasList<Incident>
        listConfig={{ style: { margin: 'auto -10px', padding: '0 10px' }, className: 'overflow-auto flex-grow-1' }}
        listItemsConfig={listItemsConfig}
        interactive
      />
    );
  }
}

export default HasRecentIncidentsList;
