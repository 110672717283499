import { Col, Divider, Row } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import Result, { ResultProps } from 'antd/lib/result';
import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { HasButton, HasSpinner, HasText, HasTitle } from '../../components';
import { UserService } from '../../services';
import { ErrorMessages, SuccessMessages } from '../../shared';
import { getTokenFromCookie, isObjectEmpty } from '../../utils';
import './account.validation.scss';

interface AccountValidationState {
  cookieToken: string | null;
  searchParams: URLSearchParams;
  loading: boolean;
  activated: boolean;
  outcome: ResultProps;
}

const TOKEN_KEY = 'token';
const EMAIL_KEY = 'email';

class HasAccountValidation extends React.Component<RouteComponentProps<any> & WithTranslation, AccountValidationState> {
  state = {
    cookieToken: getTokenFromCookie(),
    searchParams: new URLSearchParams(this.props.location.search),
    loading: true,
    activated: false,
    outcome: {} as AlertProps,
  };

  componentDidMount() {
    const { searchParams } = this.state;
    const { t } = this.props;
    const token = searchParams.get(TOKEN_KEY);
    const email = searchParams.get(EMAIL_KEY);
    if (!token || !email) {
      this.setState({
        activated: true,
        loading: false,
        outcome: {
          status: 'error',
          ...ErrorMessages.ACCOUNT_ACTIVATION_INVALID_LINK,
        },
      });
    } else {
      UserService.activateAccount(token, email)
        .then(
          () =>
            this.setState({
              activated: true,
              loading: false,
              outcome: {
                status: 'success',
                ...SuccessMessages.ACCOUNT_ACTIVATION(email),
                extra: (
                  <Link to={this.state.cookieToken ? '/home' : '/login'}>
                    <HasButton type="primary">{`${t('accountValidation.goTo')} ${
                      this.state.cookieToken ? t('accountValidation.dashboardTitle') : t('shared.login')
                    }`}</HasButton>
                  </Link>
                ),
              },
            }),
          () =>
            this.setState({
              activated: false,
              loading: false,
              outcome: {
                status: 'error',
                ...ErrorMessages.ACCOUNT_ACTIVATION(),
              },
            })
        )
        .catch((error) =>
          this.setState({
            activated: false,
            loading: false,
            outcome: {
              status: 'error',
              ...ErrorMessages.ACCOUNT_ACTIVATION(error.response?.data.message),
            },
          })
        );
    }
  }

  getPageContent = (): ReactNode => {
    const { loading, outcome } = this.state;
    const { t } = this.props;
    return loading ? (
      <React.Fragment>
        <HasText content={t('accountValidation.wait')} type="secondary" strong />
        <HasSpinner size="large" />
      </React.Fragment>
    ) : (
      <React.Fragment>
        {!isObjectEmpty(outcome) && <Result {...outcome} style={{ maxWidth: '600px', textAlign: 'left' }} />}
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { cookieToken, loading, activated } = this.state;
    return (
      <div className="has-account-validation-container">
        <Row className="shadow-sm has-account-validation-wrapper">
          <Col>
            <HasTitle
              type="secondary"
              content={t('accountValidation.welcome')}
              level={2}
              style={{ letterSpacing: '4px' }}
            />
          </Col>
          <Col>
            <HasTitle
              type="secondary"
              content={t('shared.slogan')}
              level={4}
              style={{ marginBottom: 0, marginTop: 0 }}
            />
          </Col>
          <Col>
            <Divider />
          </Col>
          <Col className="d-flex flex-column flex-grow-1">{this.getPageContent()}</Col>
          {cookieToken && !loading && !activated && (
            <Col>
              <Link to="/home">
                <HasButton type="primary">{`${t('accountValidation.goTo')} ${t(
                  'accountValidation.dashboardTitle'
                )}`}</HasButton>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default withTranslation()(HasAccountValidation);
