import { Company, Division } from './company.model';
import { Country } from './country.model';
import { NotificationTriggerType } from './notification-trigger.model';

export interface User {
  id: number;
  name: string;
  surname: string;
  email?: string;
  phone?: string;
  role: UserRole;
  company: Company;
  division: Division;
  activationDate: string;
  status: UserStatus;
  invitedBy?: User;
  invitedUsers: User[];
  dataViewUsers: User[];
  deleted: boolean;
  country: Country;
  notificationTriggers: NotificationTriggerType[];
  fullName: string;
}

export interface UserLoginData {
  email: string;
  password: string;
}

export interface UserSignUpData {
  email: string;
  userRole: string;
}

export enum UserRole {
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  GUEST = 'GUEST',
  EMPLOYEE = 'EMPLOYEE',
  SUPER_ADMIN = 'SUPER_ADMIN',
  LIMITED_EMPLOYEE_DIVISION = 'LIMITED_EMPLOYEE_DIVISION',
  LIMITED_EMPLOYEE_PERSONAL = 'LIMITED_EMPLOYEE_PERSONAL',
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
