import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { HasEmptyState, HasText } from '../../components';
import HasTeamsList from '../../components/organisms/teams/teams-list.table';
import i18n from '../../i18n/config';

import { Team } from '../../models/team.model';
import { CompanyService, EntityAction, TeamService } from '../../services';
import './teams.scss';
import { withTranslation } from 'react-i18next';

const HasTeams: React.FC = () => {
  const [teamDetailsVisible, setTeamDetailsVisilbe] = useState<boolean>(false);
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [companyId, setCompanyId] = useState<number>();

  const subscriptions: Subscription[] = [];

  useEffect(() => {
    if (companyId) {
      TeamService.getAllByCompanyId(companyId).then((response: any) => {
        setAllTeams(response.data);
      });
    }
    subscriptions.push(
      CompanyService.getCompanyListener().subscribe((companyId) => !isNaN(companyId) && setCompanyId(companyId)),

      TeamService.getTeamListener().subscribe(({ event }) => {
        switch (event) {
          case EntityAction.REFRESH:
            if (companyId) {
              TeamService.getAllByCompanyId(companyId).then((response: any) => {
                setAllTeams(response.data);
              });
            }
            break;
        }
      }),
      CompanyService.getCompanyChangedListener().subscribe((_) => {
        companyId &&
          CompanyService.getCompanyWithDivisionInformation(companyId).then((response) =>
            setCompanyId(response.data.id)
          );
      })
    );

    return () => {
      subscriptions.forEach((subsciption) => subsciption.unsubscribe());
    };
  }, [companyId]);

  return (
    <div className="shadow-sm has-container-tile d-flex flex-column h-100">
      {allTeams != null ? (
        <HasTeamsList
          teams={allTeams}
          teamDetailsVisible={teamDetailsVisible}
          setTeamDetailsVisible={setTeamDetailsVisilbe}
        />
      ) : (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <HasEmptyState>
            <HasText content={i18n.t('team.noTeams')} strong type="secondary" />
          </HasEmptyState>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(HasTeams);
