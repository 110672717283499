import axios from 'axios';
import { errorResponseHandler, requestHandler, successResponseHandler } from './index';

export const CANCEL_TOKENS = {
  DASHBOARD: axios.CancelToken.source(),
  EVENT_DETAILS: axios.CancelToken.source(),
  EVENT_LIST: axios.CancelToken.source(),
  SETTINGS: axios.CancelToken.source(),
  NEW_REPORT: axios.CancelToken.source(),
  ACTION_LIST: axios.CancelToken.source(),
};
export const wasRequestCancelled = (error: any) => axios.isCancel(error);

export const httpClient = axios.create();
httpClient.interceptors.request.use(
  (config) => requestHandler(config),
  (error) => {
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  (response) => successResponseHandler(response),
  (error) => errorResponseHandler(error)
);
