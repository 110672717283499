import { Attachment, Action } from '../../../models';
import { ActionAttachmentService, EntityAction } from '../../../services';
import { HasAttachments } from '../attachments';
import { FileIsFor } from '../../../models';

class HasActionAttachments extends HasAttachments<Action[]> {
  protected photosAreFor: FileIsFor = FileIsFor.ACTION;
  protected titleStringKey: string = 'attachment.actionsAttachments';
  protected emptyStateStringKey: string | null = 'attachment.noActions';

  protected getForDownload(id: number): Promise<any> {
    return ActionAttachmentService.getForDownload(id);
  }

  protected uploadAttachment(isPrivate: boolean, formData: FormData): Promise<any> {
    return ActionAttachmentService.upload(this.props.photoUploadTargetId, isPrivate, formData);
  }

  protected setPrivate(attachment: Attachment): Promise<any> {
    return ActionAttachmentService.setPrivate(attachment.id, !attachment.private);
  }

  protected attachmentsChanged(action: EntityAction, attachment: Attachment): void {
    ActionAttachmentService.attachmentChanged(action, attachment);
  }
}

export default HasActionAttachments;
