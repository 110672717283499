import { AxiosResponse } from 'axios';
import { Country } from '../models/country.model';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { RestService, URL } from './rest.service';

class CountryService implements RestService<Country> {
  get(params?: any): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  getAll(): Promise<AxiosResponse<Country[]>> {
    return httpClient.get<Country[]>(buildUrl(URL.COUNTRY, ['all']));
  }
  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  post(body?: any, params?: any): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  put(body?: any, params?: any): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  create(resource: Country): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  update(resource: Country): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
  delete(resourceId: string | number): Promise<AxiosResponse<Country>> {
    throw new Error('Method not implemented.');
  }
}

export default new CountryService();
