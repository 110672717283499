import { TFunction } from 'i18next';

export interface NotificationTrigger {
  id: number;
  notificationType: NotificationTriggerType;
}

export enum NotificationTriggerType {
  UPLOAD_INCIDENT_OR_NEAR_HIT = 'UPLOAD_INCIDENT_OR_NEAR_HIT',
  ACTIONS_ASSIGN = 'ACTIONS_ASSIGN',
  RISK_ASSESSMENTS_REMINDER = 'RISK_ASSESSMENTS_REMINDER',
  RISK_ASSESSMENTS_ONE_OFF_REMINDER = 'RISK_ASSESSMENTS_ONE_OFF_REMINDER',
  MAINTENANCE_LOGS_CREATE = 'MAINTENANCE_LOGS_CREATE',
  MAINTENANCE_LOGS_EDIT = 'MAINTENANCE_LOGS_EDIT',
}

export type NotificationTriggerLabelFunction = (t: TFunction) => string;

export const notificationLabels: Record<string, NotificationTriggerLabelFunction> = {
  [NotificationTriggerType.UPLOAD_INCIDENT_OR_NEAR_HIT]: (t) =>
    t('permissions.uploadIncidentOrNearHit', 'Upload Incident or Near Hit'),
  [NotificationTriggerType.ACTIONS_ASSIGN]: (t) => t('permissions.assignUserToAction', 'Assign user to action'),
  [NotificationTriggerType.RISK_ASSESSMENTS_REMINDER]: (t) => t('riskAssessment.header.reminder', 'Reminder'),
  [NotificationTriggerType.RISK_ASSESSMENTS_ONE_OFF_REMINDER]: (t) =>
    t('riskAssessment.header.oneOffReminder', 'One off reminder'),
  [NotificationTriggerType.MAINTENANCE_LOGS_CREATE]: (t) =>
    t('permissions.createMaintenanceLog', 'Create maintenance log'),
  [NotificationTriggerType.MAINTENANCE_LOGS_EDIT]: (t) => t('permissions.editMaintenanceLog', 'Edit maintenance log'),
};
