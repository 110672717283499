import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Incident } from '../../../models';
import HasRecentIncidentsList from './recent.list';

interface VirtuialListState {
  incidents: Incident[];
  loading: boolean;
  hasMore: boolean;
}

class HasVirtualList extends React.Component<{}, VirtuialListState> {
  state = { incidents: [], loading: false, hasMore: true };

  componentDidMount() {
    // TODO InitializeBatches & total element count to know how to set hasMore
  }

  loadMore = () => {
    // TODO Make another paginated call to get next page
  };

  render() {
    return (
      <div style={{ overflow: 'auto', height: '300px' }}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <HasRecentIncidentsList incidents={this.state.incidents} />
        </InfiniteScroll>
      </div>
    );
  }
}

export default HasVirtualList;
