import { ExceptionOutlined, MoreOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Collapse, DatePicker, Dropdown, Menu, Select } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { isEmpty } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ReactResizeDetector from 'react-resize-detector';
import {
  HasAddChartModal,
  HasAddPanelModal,
  HasDropdown,
  HasRemoveChartModal,
  HasRemovePanelModal,
  HasSpinner,
  HasText,
} from '../../components';
import i18n from '../../i18n/config';
import { ChartCollapsablePanel, ChartContainer, ChartEventsDataFilter, ChartInstance, Company } from '../../models';
import { COLORS, Option, TimeIntervals, TimeIntervalsLabel } from '../../shared';
import { catchClickEvent, getDatesFromInterval } from '../../utils/functions';
import {
  AbstractBarChart,
  AbstractLineChart,
  AbstractMapChart,
  AbstractPieChart,
  AbstractRadarChart,
} from './abstract-charts';

const { Panel } = Collapse;
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { RangePicker } = DatePicker;
const DASHBOARD_COMPANIES = 'dashboard-companies';

interface DashboardPanelsSelectedCompanies {
  dashboardId: number;
  panels: PanelSelectedCompanies[];
}

interface PanelSelectedCompanies {
  panelId: string;
  selectedCompanies: number[];
}

enum PanelActions {
  ADD_CHART = 'ADD_CHART',
  EDIT_PANEL = 'EDIT_PANEL',
  REMOVE_PANEL = 'REMOVE_PANEL',
}

const PanelActionsLabel: Record<number | string, string> = {
  0: 'charts.panelAction.addChart',
  ADD_CHART: 'charts.panelAction.addChart',
  1: 'charts.panelAction.remove',
  REMOVE_PANEL: 'charts.panelAction.remove',
  2: 'charts.panelAction.edit',
  EDIT_PANEL: 'charts.panelAction.edit',
};

interface DashboardPanelProps {
  panel: ChartCollapsablePanel;
  chartsInstances: Map<number, ChartInstance>;
  editMode: boolean;
  userCompany?: Company;
  companyList?: Company[];
  language: string;
  dashboardId: number | undefined;
  panelColapseEvent: (expanded: boolean) => void;
  resizePanelGrid: (height: number | undefined) => void;
  removePanel: () => void;
  editPanel: (title: string, titleFr: string) => void;
  layoutsChanged: (layouts: any) => void;
  addChartToPanel: (chartInstanceId: number, cols: number | undefined) => void;
  removeChartFromPanel: (chartIndex: number) => void;
  timeIntervalChanged: (timeInterval: string, startDate: string | undefined, endDate: string | undefined) => void;
  showChartEvents: (chartEventDataFilter: ChartEventsDataFilter) => void;
}

const DashboardPanel: React.FC<DashboardPanelProps> = ({
  panel,
  chartsInstances,
  editMode,
  userCompany,
  companyList,
  language,
  dashboardId,
  panelColapseEvent,
  resizePanelGrid,
  removePanel,
  editPanel,
  layoutsChanged,
  addChartToPanel,
  removeChartFromPanel,
  timeIntervalChanged,
  showChartEvents,
}) => {
  const [showAddChartModal, setShowAddChartModal] = useState<boolean>(false);
  const [showRemovePanelModal, setShowRemovePanelModal] = useState<boolean>(false);
  const [showRemoveChartModal, setShowRemoveChartModal] = useState<boolean>(false);
  const [showEditPanelModal, setShowEditPanelModal] = useState<boolean>(false);
  const [chartToRemoveIndex, setChartToRemoveIndex] = useState<number>();
  const [companies, setCompanies] = useState<Company[]>();
  const [cols, setCols] = useState<number>();

  const [selectedCompanies, setSelectedCompanies] = useState<number[]>();

  useEffect(() => {
    if (companyList && !isEmpty(companyList)) {
      const companyListCopy = [...companyList];
      companyListCopy.unshift({
        id: -1,
        name: 'Select all',
        phoneNumber: '',
        address: '',
        domain: '',
        divisions: [],
        activated: true,
      });
      setCompanies(companyListCopy);
    }
  }, [companyList]);

  useEffect(() => {
    if (userCompany) {
      setSelectedCompanies([userCompany.id]);
    }
    if (dashboardId) {
      const sessionSelectedCompanies = localStorage.getItem(DASHBOARD_COMPANIES);
      if (sessionSelectedCompanies) {
        const parsedSessionSelectedCompanies: DashboardPanelsSelectedCompanies[] = JSON.parse(sessionSelectedCompanies);
        const dashboardSessionSelectedCompanies: DashboardPanelsSelectedCompanies | undefined =
          parsedSessionSelectedCompanies.find((dashboard) => dashboard.dashboardId === dashboardId);
        if (dashboardSessionSelectedCompanies) {
          const panelSessionSelectedCompanies: PanelSelectedCompanies | undefined =
            dashboardSessionSelectedCompanies.panels.find((panelCompanies) => panelCompanies.panelId === panel.i);
          if (panelSessionSelectedCompanies) {
            setSelectedCompanies(panelSessionSelectedCompanies.selectedCompanies);
          }
        }
      }
    }
  }, [userCompany]);

  const defaultProps = {
    className: 'layout',
    cols: { lg: 18, md: 12, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 10,
  };

  const getChartEvents = (chartInstanceId: number | undefined) => {
    if (chartInstanceId && selectedCompanies) {
      let chartEventsDataFilter: ChartEventsDataFilter = {
        companies: selectedCompanies,
        chartInstanceId,
        pageNumber: 1,
        pageSize: 10,
      };
      if (panel.timeInterval !== TimeIntervals.CUSTOM) {
        const timeInterval = getDatesFromInterval(panel.timeInterval);
        chartEventsDataFilter.startDate = timeInterval?.startDate;
        chartEventsDataFilter.endDate = timeInterval?.endDate;
      } else if (panel.startDate && panel.endDate) {
        chartEventsDataFilter.startDate = panel.startDate;
        chartEventsDataFilter.endDate = panel.endDate;
      }
      showChartEvents(chartEventsDataFilter);
    }
  };

  const createChart = (chartContainer: ChartContainer, index: number) => {
    const removeStyle: CSSProperties = {
      position: 'absolute',
      right: '2px',
      top: -4,
      cursor: 'pointer',
      color: 'black',
    };
    let elementChart;
    const chartInstance = chartsInstances.get(chartContainer.chartInstanceId);
    if (chartInstance) {
      switch (chartInstance.chartType?.name) {
        case 'line':
          elementChart = (
            <AbstractLineChart
              language={language}
              chartInstance={chartInstance}
              panel={panel}
              selectedCompanies={selectedCompanies}
            />
          );
          break;
        case 'bar':
          elementChart = (
            <AbstractBarChart
              language={language}
              chartInstance={chartInstance}
              panel={panel}
              selectedCompanies={selectedCompanies}
            />
          );
          break;
        case 'pie':
          elementChart = (
            <AbstractPieChart
              language={language}
              chartInstance={chartInstance}
              panel={panel}
              selectedCompanies={selectedCompanies}
            />
          );
          break;
        case 'radar':
          elementChart = (
            <AbstractRadarChart
              language={language}
              chartInstance={chartInstance}
              panel={panel}
              selectedCompanies={selectedCompanies}
            />
          );
          break;
        case 'map':
          elementChart = (
            <AbstractMapChart chartInstance={chartInstance} panel={panel} selectedCompanies={selectedCompanies} />
          );
          break;
      }
    } else {
      elementChart = <HasSpinner size="small" />;
    }

    return (
      <div
        key={'chart' + chartContainer.i}
        data-grid={chartContainer}
        style={{ background: 'white', border: '1px solid #dce1e6' }}
      >
        <div
          style={{
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '14px',
            fontFamily: 'Montserrat',
            paddingTop: '5px',
          }}
        >
          <span className="mr-2" style={{ color: COLORS.NEW_ORANGE, textTransform: 'uppercase' }}>
            {language === 'fr' && chartInstance?.titleFr ? chartInstance.titleFr : chartInstance?.title}
            {editMode ? (
              ''
            ) : (
              <ExceptionOutlined
                style={{
                  position: 'absolute',
                  right: 1,
                  top: 3,
                  cursor: 'pointer',
                  color: 'black',
                }}
                onClick={() => getChartEvents(chartInstance?.id)}
              />
            )}
          </span>
        </div>
        <div style={{ height: 'calc(100% - 30px)' }}>{elementChart}</div>
        {editMode ? (
          <span className="remove" style={removeStyle} onClick={() => onClickChart(index)}>
            x
          </span>
        ) : (
          ''
        )}
      </div>
    );
  };

  const onAddChart = (chartInstanceId: number) => {
    addChartToPanel(chartInstanceId, cols);
    setShowAddChartModal(false);
  };

  const onRemovePanel = () => {
    removePanel();
    setShowRemovePanelModal(false);
  };

  const onClickChart = (chartIndex: number) => {
    setChartToRemoveIndex(chartIndex);
    setShowRemoveChartModal(true);
  };

  const onRemoveChart = () => {
    if (chartToRemoveIndex !== undefined) {
      removeChartFromPanel(chartToRemoveIndex);
    }
    setShowRemoveChartModal(false);
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    layoutsChanged(layouts);
  };

  const onBreakpointChange = (breakpoint: any, cols: any) => {
    setCols(cols);
  };

  const panelCollapseAction = (expandedPanels: string | string[]) => {
    panelColapseEvent(expandedPanels.length > 0);
  };

  const panelActionSelected = (clickParam: ClickParam) => {
    catchClickEvent(clickParam.domEvent);
    if (clickParam.key === PanelActions.ADD_CHART.toString()) {
      setShowAddChartModal(true);
    } else if (clickParam.key === PanelActions.EDIT_PANEL.toString()) {
      setShowEditPanelModal(true);
    } else {
      setShowRemovePanelModal(true);
    }
  };

  const onResize = (height: number | undefined) => {
    resizePanelGrid(height);
  };

  const onEditPanel = (title: string, titleFr: string) => {
    editPanel(title, titleFr);
    setShowEditPanelModal(false);
  };

  const onCompaniesSelection = (selectedCompanies: number[]) => {
    if (companies && selectedCompanies.includes(-1)) {
      selectedCompanies = companies.filter((company) => company.id !== -1).map((company) => company.id);
    }
    setSelectedCompanies(selectedCompanies);

    if (dashboardId) {
      let parsedSessionSelectedCompanies: DashboardPanelsSelectedCompanies[];
      const currentPanelCompanies: PanelSelectedCompanies = { panelId: panel.i, selectedCompanies };
      const sessionSelectedCompanies = localStorage.getItem(DASHBOARD_COMPANIES);
      if (sessionSelectedCompanies) {
        parsedSessionSelectedCompanies = JSON.parse(sessionSelectedCompanies);
        const indexOfDashboard = parsedSessionSelectedCompanies.findIndex(
          (dashboard) => dashboard.dashboardId === dashboardId
        );
        if (indexOfDashboard !== -1) {
          const indexOfPanelCompanies = parsedSessionSelectedCompanies[indexOfDashboard].panels.findIndex(
            (panelCompanies) => panelCompanies.panelId === panel.i
          );
          if (indexOfPanelCompanies !== -1) {
            parsedSessionSelectedCompanies[indexOfDashboard].panels[indexOfPanelCompanies] = currentPanelCompanies;
          } else {
            parsedSessionSelectedCompanies[indexOfDashboard].panels.push(currentPanelCompanies);
          }
        } else {
          parsedSessionSelectedCompanies.push({ dashboardId, panels: [currentPanelCompanies] });
        }
      } else {
        parsedSessionSelectedCompanies = [{ dashboardId, panels: [currentPanelCompanies] }];
      }
      localStorage.setItem(DASHBOARD_COMPANIES, JSON.stringify(parsedSessionSelectedCompanies));
    }
  };

  const menu = (
    <Menu onClick={panelActionSelected}>
      <Menu.Item key={PanelActions.ADD_CHART}>{i18n.t(PanelActionsLabel[PanelActions.ADD_CHART])}</Menu.Item>
      <Menu.Item key={PanelActions.EDIT_PANEL}>{i18n.t(PanelActionsLabel[PanelActions.EDIT_PANEL])}</Menu.Item>
      <Menu.Item key={PanelActions.REMOVE_PANEL}>{i18n.t(PanelActionsLabel[PanelActions.REMOVE_PANEL])}</Menu.Item>
    </Menu>
  );

  const header = (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <HasText
        content={language === 'fr' && panel.titleFr ? panel.titleFr : panel.title}
        strong
        style={{ textTransform: 'uppercase' }}
      />
      <div className="d-flex" onClick={(e) => catchClickEvent(e)}>
        <HasDropdown<string>
          options={Object.keys(TimeIntervals).map<Option<string>>((interval) => ({
            value: interval,
            label: i18n.t(TimeIntervalsLabel[interval]).toString(),
            forObject: interval,
          }))}
          defaultValue={i18n.t(TimeIntervalsLabel[panel.timeInterval]).toString()}
          onChange={(_, value) => timeIntervalChanged(value.forObject, undefined, undefined)}
          style={{ minWidth: '200px' }}
          className="mr-3"
        />
        {panel.timeInterval === TimeIntervals.CUSTOM ? (
          <RangePicker
            className="mb-1"
            onChange={(range: [any, any] | null) => {
              if (range) {
                timeIntervalChanged(TimeIntervals.CUSTOM, range[0].toISOString(), range[1].toISOString());
              }
            }}
          />
        ) : (
          ''
        )}
        {companies === undefined || isEmpty(companies) ? (
          <HasSpinner size="small" />
        ) : (
          <Select
            mode="multiple"
            allowClear
            className="mr-3 ml-3"
            style={{ minWidth: '200px' }}
            placeholder={i18n.t('charts.dashboardCompanyPlaceholder')}
            options={companies.map((company) => ({ value: company.id, label: company.name, forObject: company }))}
            value={selectedCompanies}
            onChange={onCompaniesSelection}
            removeIcon={''}
          ></Select>
        )}
        {editMode ? (
          <>
            <Dropdown overlay={menu}>
              <MoreOutlined style={{ fontSize: 24 }} />
            </Dropdown>
            <VerticalAlignMiddleOutlined style={{ fontSize: 24 }} className="dragable-icon" />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  return (
    <>
      <ReactResizeDetector
        handleHeight
        handleWidth
        refreshMode="debounce"
        refreshRate={200}
        onResize={(width, height) => onResize(height)}
      >
        <Collapse
          defaultActiveKey={[panel.expanded ? 'panel' + panel.title : '']}
          onChange={(key) => panelCollapseAction(key)}
        >
          <Panel header={header} key={'panel' + panel.title}>
            <div>
              {panel.expanded ? (
                <ResponsiveReactGridLayout
                  margin={[5, 5]}
                  onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
                  onBreakpointChange={onBreakpointChange}
                  {...defaultProps}
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  layouts={panel.layouts}
                  isResizable={editMode}
                  isDraggable={editMode}
                >
                  {panel.charts.map((chart, index) => createChart(chart, index))}
                </ResponsiveReactGridLayout>
              ) : (
                ''
              )}
            </div>
          </Panel>
        </Collapse>
      </ReactResizeDetector>
      <HasAddChartModal visible={showAddChartModal} onOk={onAddChart} onCancel={() => setShowAddChartModal(false)} />
      <HasRemovePanelModal
        visible={showRemovePanelModal}
        onOk={onRemovePanel}
        onCancel={() => setShowRemovePanelModal(false)}
      />
      <HasRemoveChartModal
        visible={showRemoveChartModal}
        onOk={onRemoveChart}
        onCancel={() => setShowRemoveChartModal(false)}
      />
      <HasAddPanelModal
        visible={showEditPanelModal}
        onOk={onEditPanel}
        onCancel={() => setShowEditPanelModal(false)}
        existingTitle={panel.title}
        existingTitleFr={panel.titleFr}
      />
    </>
  );
};

export default DashboardPanel;
