import { PlusOutlined } from '@ant-design/icons';
import { Empty, Tooltip } from 'antd';
import React from 'react';
import { HasButton, HasEmptyState, HasInvestigationForm, HasStatusToggle, HasText, HasTitle } from '../../components';
import { PermissionsContext } from '../../context';
import i18n from '../../i18n/config';
import {
  Incident,
  Investigation,
  InvestigationForm,
  InvestigationStatus,
  LogEntry,
  PermissionType,
  UserRole,
} from '../../models';
import { EntityAction, InvestigationService } from '../../services';
import actionService from '../../services/action.service';
import { COLORS, InvestigationStatusLabel } from '../../shared';
import { getObjectValueByKey, hasPermissionType, isObjectEmpty, isUserRoleEqualsTo } from '../../utils';

interface InvestigationDetailsProps {
  incident: Incident;
  investigation: Investigation;
}

interface InvestigationDetailsState {
  loading: boolean;
  hasActions: boolean;
}

class HasInvestigationDetails extends React.Component<InvestigationDetailsProps, InvestigationDetailsState> {
  state = {
    loading: false,
    hasActions: false,
  };

  constructor(props: Readonly<InvestigationDetailsProps>) {
    super(props);
  }

  parseInvestigation = (): { key: string; data: LogEntry[] }[] | null => {
    const { private: isInvestigationPrivate, ...restOfInvestigation } = this.props.investigation;
    if (isObjectEmpty(restOfInvestigation)) {
      return null;
    } else {
      const {
        investigation: {
          formElements: { hazardsAndRisks, riskOfHazardIdentified, ...restOfFormElements },
        },
        investigation,
      } = this.props;
      let result: { key: string; data: LogEntry[] }[] = [];

      Object.keys(restOfFormElements)
        .filter((key) => key !== 'id')
        .forEach((key: any) => {
          if (key === 'riskAssessmentConducted') {
            result.push(
              { key: 'hazardsAndRisks', data: getObjectValueByKey(investigation.formElements, 'hazardsAndRisks') },
              {
                key: 'riskOfHazardIdentified',
                data: getObjectValueByKey(investigation.formElements, 'riskOfHazardIdentified'),
              }
            );
          }
          result.push({ key: key, data: getObjectValueByKey(restOfFormElements, key) });
        });

      result.forEach((element) => {
        element.data.forEach((data) => {
          data.fieldKey = element.key;
        });
      });

      return result;
    }
  };

  createNewInvestigation = () => {
    this.setState({ loading: true });
    InvestigationService.createEmptyInvestigation({
      incidentId: this.props.incident.id,
      status: InvestigationStatus.OPEN,
    }).then(() => {
      InvestigationService.investigationChanged(EntityAction.REFRESH, {} as Investigation);
      this.setState({ loading: false });
    });
  };

  investigationStatusChanged = (checked: boolean) => {
    const { investigation } = this.props;
    investigation.status = checked ? InvestigationStatus.OPEN : InvestigationStatus.CLOSED;
    InvestigationService.investigationChanged(EntityAction.UPDATE, investigation);
  };

  investigationAccessChanged = (checked: boolean) => {
    const { investigation } = this.props;
    investigation.private = checked;
    InvestigationService.investigationChanged(EntityAction.UPDATE, investigation);
  };

  areQuestionsAnswered = () => {
    const { private: isInvestigationPrivate, ...restOfInvestigation } = this.props.investigation;
    if (!isObjectEmpty(restOfInvestigation)) {
      return Object.entries(this.props.investigation.formElements).every(
        (entry) => entry[0] === 'id' || entry[1].length > 0
      );
    } else {
      return false;
    }
  };

  investigationHasAction = async () => {
    const { ...restOfInvestigation } = this.props.investigation;

    if (!isObjectEmpty(restOfInvestigation) && restOfInvestigation.incidentId) {
      return (await (await actionService.getByIncidentId(restOfInvestigation.incidentId.toString())).data.length) > 0;
    } else {
      return false;
    }
  };

  investigationFieldChanged = (field: keyof InvestigationForm, value: LogEntry) => {
    let investigation = { ...this.props.investigation };
    investigation.formElements[field].push(value);
    InvestigationService.investigationChanged(EntityAction.UPDATE, investigation);
  };

  componentDidMount() {
    const { loading } = this.state;
    const { setPermissionsForUser } = this.context;
    setPermissionsForUser();
    this.investigationHasAction().then((hasActions) => {
      this.setState({
        loading: loading,
        hasActions: hasActions,
      });
    });
  }

  render() {
    const { investigation } = this.props;
    const investigationForm = this.parseInvestigation();
    const questionsAnswered = this.areQuestionsAnswered();
    const { hasActions } = this.state;
    const isClosable = questionsAnswered && hasActions;
    const { permissions } = this.context;
    return (
      <>
        {!investigation.private || isUserRoleEqualsTo(UserRole.OWNER) || isUserRoleEqualsTo(UserRole.SUPER_ADMIN) ? (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                flexShrink: 0,
              }}
            >
              <div className={'ant-descriptions-title'}>{i18n.t('incidents.eventInvestigation')}</div>
              {hasPermissionType(PermissionType.INVESTIGATIONS_MARK_AS_PRIVATE_OR_PUBLIC, permissions) &&
              (isUserRoleEqualsTo(UserRole.OWNER) || isUserRoleEqualsTo(UserRole.SUPER_ADMIN)) ? (
                <Tooltip title={i18n.t('privateInvestigation.tooltip')}>
                  <HasStatusToggle
                    disabled={false}
                    isChecked={investigation.private}
                    onChange={this.investigationAccessChanged}
                    leftLabel={i18n.t('incidents.public')}
                    rightLabel={i18n.t('incidents.private')}
                  />
                </Tooltip>
              ) : (
                ''
              )}
              {investigationForm && hasPermissionType(PermissionType.INVESTIGATIONS_CLOSE, permissions) && (
                <Tooltip
                  title={i18n.t('incidents.investigationHasNoAction')}
                  overlayStyle={{ fontSize: '12px', display: hasActions || !questionsAnswered ? 'none' : 'auto' }}
                >
                  <Tooltip
                    title={i18n.t('incidents.notAllAnswered')}
                    overlayStyle={{ fontSize: '12px', display: questionsAnswered ? 'none' : 'auto' }}
                  >
                    <div>
                      <HasStatusToggle
                        disabled={!isClosable}
                        isChecked={investigation.status === InvestigationStatus.OPEN}
                        onChange={this.investigationStatusChanged}
                        leftLabel={i18n.t(InvestigationStatusLabel[InvestigationStatus.CLOSED])}
                        rightLabel={i18n.t(InvestigationStatusLabel[InvestigationStatus.OPEN])}
                      />
                    </div>
                  </Tooltip>
                </Tooltip>
              )}
            </div>
            <>
              {investigationForm ? (
                <HasInvestigationForm
                  investigation={investigation}
                  investigationForm={investigationForm}
                  fieldChangedCallback={this.investigationFieldChanged}
                />
              ) : (
                <HasEmptyState description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                  <HasTitle
                    content={i18n.t('incidents.noInvestigation')}
                    level={4}
                    style={{ fontSize: '14px', color: COLORS.BLACK_RGBA(0.45), textAlign: 'center' }}
                  />
                  {hasPermissionType(PermissionType.INVESTIGATIONS_CREATE, permissions) && (
                    <HasButton
                      type="primary"
                      onClick={this.createNewInvestigation}
                      loading={this.state.loading}
                      icon={<PlusOutlined style={{ verticalAlign: 'middle' }} />}
                    >
                      {i18n.t('incidents.newInvestigation')}
                    </HasButton>
                  )}
                </HasEmptyState>
              )}
            </>
          </React.Fragment>
        ) : (
          <div style={{ display: 'flex', margin: 'auto' }}>
            <strong>{i18n.t('incidents.privateInvestigation')}</strong>
          </div>
        )}
      </>
    );
  }
}

HasInvestigationDetails.contextType = PermissionsContext;

export default HasInvestigationDetails;
