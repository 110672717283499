import React, { memo, ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HasTermsAndConditionsPage } from '../components';
import { UserRole } from '../models';
import {
  HasAccountValidation,
  HasActionsPage,
  HasChartsPage,
  HasCompaniesPage,
  HasContactPage,
  HasDashboardPage,
  HasHistoricalEvents,
  HasIncidentListPage,
  HasIncidentPageWrapper,
  HasLoginPage,
  HasNotFoundErrorPage,
  HasServerErrorPage,
  HasSettingsWrapper,
  HasUserDetailsPage,
} from '../pages';
import { HasChartCreatePage } from '../pages/charts/chart-creation/chart.create';
import { HasChartsLibraryPage } from '../pages/charts/charts.library';
import HasTeams from '../pages/teams/teams';
import { RouteConfig, RW_ROLES, WEB_ACCEPTED_ROLES } from '../shared';
import { isTokenExpired } from '../utils';
import { authGuard, ProtectedRoute, roleGuard } from './index';

const buildRoute = (route: RouteConfig, index: number): ReactNode => {
  return route.canActivate ? (
    <ProtectedRoute key={index} {...route} />
  ) : (
    <Route key={index} path={route.path} exact={route.exact} component={route.component} />
  );
};

const Routes = () => {
  return (
    <Switch>
      {config.map((route, i) => buildRoute(route, i))}
      <Redirect from="/" to={isTokenExpired() ? PageURLs.LOGIN : PageURLs.DASHBOARD} />
      <Route component={HasNotFoundErrorPage} />
    </Switch>
  );
};

export default memo(Routes);

const PageURLs = Object.freeze({
  LOGIN: '/login',
  RECOVER: '/recover',
  REGISTER: '/register',
  ACCOUNT_VALIDATION: '/user/confirm',
  DASHBOARD: '/home',
  SETTINGS_WRAPPER: '/settings/:view',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_CONTACTS: '/settings/contacts',
  SETTINGS_PHONE_NUMBERS: '/settings/phone-numbers',
  EVENT_LIST: '/events',
  HISTORICAL_EVENTS: '/historical-upload',
  EVENT_WRAPPER: '/events/:view/:id',
  EVENT_REPORT: '/events/event/:id',
  INVESTIGATION: '/events/investigation/:id',
  REVIEW: '/events/review/:id',
  ACTION: '/events/action/:id',
  EXPORT_PDF: '/events/export/:id',
  COMPANIES: '/companies',
  NOT_FOUND: '/not-found',
  SERVER_ERROR: '/error',
  TERMS_AND_CONDITIONS: '/privacy-policy',
  CONTACT: '/contact',
  ACTIONS: '/actions',
  CHARTS: '/charts',
  CHARTS_CREATE: '/charts/new',
  CHARTS_LIBRARY: '/charts/library',
  CHARTS_EDIT: '/charts/edit/:id',
  TEAMS_LIST: '/teams',
  USER_DETAILS: '/user',
});

const PageRoles = Object.freeze({
  DASHBOARD: WEB_ACCEPTED_ROLES,
  SETTINGS: RW_ROLES,
  INCIDENT_LIST: WEB_ACCEPTED_ROLES,
  INCIDENT_WRAPPER: WEB_ACCEPTED_ROLES,
  INCIDENT: WEB_ACCEPTED_ROLES,
  INVESTIGATION: RW_ROLES,
  REVIEW: RW_ROLES,
  ACTION: WEB_ACCEPTED_ROLES,
  COMPANIES: [UserRole.OWNER],
  ACTIONS: WEB_ACCEPTED_ROLES,
  TEAMS: [UserRole.OWNER, UserRole.SUPER_ADMIN],
});

const config: RouteConfig[] = [
  {
    path: PageURLs.LOGIN,
    exact: true,
    component: HasLoginPage,
  },
  {
    path: PageURLs.RECOVER,
    exact: true,
    component: HasLoginPage,
  },
  {
    path: PageURLs.REGISTER,
    exact: true,
    component: HasLoginPage,
  },
  {
    path: PageURLs.ACCOUNT_VALIDATION,
    exact: true,
    component: HasAccountValidation,
  },
  {
    path: PageURLs.DASHBOARD,
    component: HasDashboardPage,
    exact: true,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.DASHBOARD,
  },
  {
    path: PageURLs.EVENT_LIST,
    component: HasIncidentListPage,
    exact: true,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.INCIDENT_LIST,
  },
  {
    path: PageURLs.HISTORICAL_EVENTS,
    component: HasHistoricalEvents,
    exact: true,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.INCIDENT_LIST,
  },
  {
    path: PageURLs.EVENT_WRAPPER,
    component: HasIncidentPageWrapper,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.INCIDENT_WRAPPER,
  },
  {
    path: PageURLs.SETTINGS_WRAPPER,
    component: HasSettingsWrapper,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.SETTINGS,
  },
  {
    path: PageURLs.COMPANIES,
    component: HasCompaniesPage,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.COMPANIES,
  },
  {
    path: PageURLs.ACTIONS,
    component: HasActionsPage,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.ACTIONS,
  },
  {
    path: PageURLs.TERMS_AND_CONDITIONS,
    component: HasTermsAndConditionsPage,
    exact: true,
  },
  {
    path: PageURLs.CONTACT,
    component: HasContactPage,
    exact: true,
  },
  {
    path: PageURLs.NOT_FOUND,
    component: HasNotFoundErrorPage,
    exact: true,
  },
  {
    path: PageURLs.SERVER_ERROR,
    component: HasServerErrorPage,
    exact: true,
  },
  // {
  //   path: PageURLs.CHARTS,
  //   component: HasChartsPage,
  //   exact: true,
  // },
  // {
  //   path: PageURLs.CHARTS_LIBRARY,
  //   component: HasChartsLibraryPage,
  //   exact: true,
  // },
  // {
  //   path: PageURLs.CHARTS_CREATE,
  //   component: HasChartCreatePage,
  //   exact: true,
  // },
  // {
  //   path: PageURLs.CHARTS_EDIT,
  //   component: HasChartCreatePage,
  //   exact: true,
  // },
  {
    path: PageURLs.USER_DETAILS,
    component: HasUserDetailsPage,
    exact: true,
  },
  {
    path: PageURLs.TEAMS_LIST,
    component: HasTeams,
    canActivate: [authGuard, roleGuard],
    forRole: PageRoles.TEAMS,
    exact: true,
  },
];
