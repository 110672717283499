import React from 'react';
import ChatBot from './lib';
import { ChatBotProps } from '../../../shared';

class HasChatBot extends React.Component<ChatBotProps, {}> {
  render() {
    return <ChatBot {...this.props} />;
  }
}

export default HasChatBot;
