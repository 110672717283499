import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartCollapsablePanel, ChartInstance, ChartInstanceDataFilter } from '../../../models';
import { ChartService } from '../../../services';
import { DASHBOARD_CHART_COLORS, TimeIntervals } from '../../../shared';
import { escapeRegExp, getDatesFromInterval } from '../../../utils';

interface BarChartProps {
  chartInstance: ChartInstance;
  panel: ChartCollapsablePanel;
  selectedCompanies: number[] | undefined;
  language: string;
}

const COLORS = DASHBOARD_CHART_COLORS;

const AbstractBarChart: React.FC<BarChartProps> = ({ chartInstance, panel, selectedCompanies, language }) => {
  const [values, setValues] = useState<any[]>([]);
  const [sqlMappings, setSqlMappings] = useState<string[]>([]);
  const [xAxis, setXAxis] = useState<string>();

  useEffect(() => {
    if (chartInstance.sqlMapping) {
      const splittedSqlMappings = chartInstance.sqlMapping.split('$');
      setXAxis(splittedSqlMappings[0]);
      setSqlMappings(splittedSqlMappings.slice(1));
    }
  }, [chartInstance.sqlMapping]);

  useEffect(() => {
    if (chartInstance.sqlMappingFr && chartInstance.sqlMapping) {
      const splittedSqlMappingsEn = chartInstance.sqlMapping.split('$');
      const splittedSqlMappingsFr = chartInstance.sqlMappingFr.split('$');
      let oldValues = JSON.stringify(values);
      if (language === 'en') {
        splittedSqlMappingsFr.forEach(
          (sqlMappingFr, index) =>
            (oldValues = oldValues.replace(new RegExp(escapeRegExp(sqlMappingFr), 'g'), splittedSqlMappingsEn[index]))
        );
        setValues(JSON.parse(oldValues));
        setXAxis(splittedSqlMappingsEn[0]);
        setSqlMappings(splittedSqlMappingsEn.slice(1));
      } else {
        splittedSqlMappingsEn.forEach(
          (sqlMappingEn, index) =>
            (oldValues = oldValues.replace(new RegExp(escapeRegExp(sqlMappingEn), 'g'), splittedSqlMappingsFr[index]))
        );
        setValues(JSON.parse(oldValues));
        setXAxis(splittedSqlMappingsFr[0]);
        setSqlMappings(splittedSqlMappingsFr.slice(1));
      }
    }
  }, [language]);

  useEffect(() => {
    if (chartInstance.id && selectedCompanies) {
      let chartInstanceDataFilter: ChartInstanceDataFilter = { companies: selectedCompanies };
      if (panel.timeInterval !== TimeIntervals.CUSTOM) {
        const timeInterval = getDatesFromInterval(panel.timeInterval);
        chartInstanceDataFilter.startDate = timeInterval?.startDate;
        chartInstanceDataFilter.endDate = timeInterval?.endDate;
      } else if (panel.startDate && panel.endDate) {
        chartInstanceDataFilter.startDate = panel.startDate;
        chartInstanceDataFilter.endDate = panel.endDate;
      }
      loadChartInstanceData(chartInstance.id, chartInstanceDataFilter);
    }
  }, [selectedCompanies, panel.timeInterval, chartInstance.id, panel.startDate, panel.endDate]);

  const loadChartInstanceData = async (chartInstanceId: number, chartInstanceDataFilter: ChartInstanceDataFilter) => {
    await ChartService.getChartInstanceData(chartInstanceId, chartInstanceDataFilter).then(({ data }) => {
      setValues(language === 'en' ? data : translateValues(data));
    });
  };

  const translateValues = (oldValues: any[]) => {
    if (chartInstance.sqlMappingFr && chartInstance.sqlMapping) {
      const splittedSqlMappingsEn = chartInstance.sqlMapping.split('$');
      const splittedSqlMappingsFr = chartInstance.sqlMappingFr.split('$');
      let newValues = JSON.stringify(oldValues);
      splittedSqlMappingsEn.forEach(
        (sqlMappingEn, index) =>
          (newValues = newValues.replace(new RegExp(escapeRegExp(sqlMappingEn), 'g'), splittedSqlMappingsFr[index]))
      );
      setXAxis(splittedSqlMappingsFr[0]);
      setSqlMappings(splittedSqlMappingsFr.slice(1));
      return JSON.parse(newValues);
    }
    return oldValues;
  };

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props;
    return value ? (
      <text x={x + width / 2 - 4} y={y - 5}>
        {value}
      </text>
    ) : (
      ''
    );
  };

  return (
    <ResponsiveContainer minWidth={100}>
      <BarChart
        data={values}
        margin={{
          right: 25,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey={xAxis} />
        <YAxis padding={{ top: 20 }} width={40} />
        <Tooltip />
        <Legend wrapperStyle={{ paddingLeft: 40 }} />
        {sqlMappings.map((mapping, index) => (
          <Bar key={mapping} type="monotone" dataKey={mapping} fill={COLORS[index % COLORS.length]}>
            <LabelList dataKey={mapping} content={renderCustomizedLabel} data={values} />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AbstractBarChart;
