import { SelectValue } from 'antd/lib/select';
import React from 'react';
import i18n from '../../../i18n/config';
import { Company, UserRole } from '../../../models';
import { Option } from '../../../shared';
import { isUserRoleEqualsTo } from '../../../utils';
import { HasCheckbox, HasDropdown, HasModal, HasText, HasTextInput } from '../../atoms';

interface AddDashboardModalProps {
  visible: boolean;
  onOk: (title: string, titleFr: string, visibleForCreator: boolean, companyId: number) => void;
  onCancel: any;
  isDuplicateDashboard?: boolean;
  usersCompany?: Company;
  usersVisibleCompany?: Company[];
}

interface AddDashboardModalState {
  title?: string;
  titleFr?: string;
  visibleForCreator: boolean;
  companyId?: number;
}

class HasAddDashboardModal extends React.Component<AddDashboardModalProps, AddDashboardModalState> {
  constructor(props: AddDashboardModalProps) {
    super(props);

    this.state = {
      visibleForCreator:
        !isUserRoleEqualsTo(UserRole.ADMIN) &&
        !isUserRoleEqualsTo(UserRole.EMPLOYEE) &&
        !isUserRoleEqualsTo(UserRole.LIMITED_EMPLOYEE_DIVISION)
          ? false
          : true,
    };
  }

  onCancel = () => {
    this.props.onCancel();
  };

  onOk = () => {
    let companyId = this.state.companyId ? this.state.companyId : this.props.usersCompany?.id;
    if (this.state.title && companyId) {
      this.props.onOk(
        this.state.title,
        this.state.titleFr ? this.state.titleFr : this.state.title,
        this.state.visibleForCreator,
        companyId
      );
    }
  };

  onCompanyChangeSelection = (_value: SelectValue, option: Option<Company>) => {
    const selectedCompany = option.forObject;
    this.setState((previousState) => {
      return { ...previousState, companyId: selectedCompany.id };
    });
  };

  onCheckboxChangeSelection = (checked: boolean) => {
    this.setState((previousState) => {
      return { ...previousState, visibleForCreator: checked };
    });
  };

  onTitleEnChangeSelection = (value: string) => {
    this.setState((previousState) => {
      return { ...previousState, title: value };
    });
  };

  onTitleFrChangeSelection = (value: string) => {
    this.setState((previousState) => {
      return { ...previousState, titleFr: value };
    });
  };

  render() {
    const { visible, isDuplicateDashboard, usersCompany, usersVisibleCompany } = this.props;

    return (
      <HasModal
        title={isDuplicateDashboard ? i18n.t('charts.duplicateDashboard') : i18n.t('charts.addDashboard')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        onOk={this.onOk}
        closable={false}
        visible={visible}
        okButtonProps={{ disabled: !this.state.title }}
        destroyOnClose
      >
        <div className="d-flex text-center" style={{ flexDirection: 'column' }}>
          <HasText
            type="secondary"
            content={i18n.t('charts.dashboardTitle')}
            className="mb-2 text-center"
            style={{ fontSize: '16px' }}
          />
          <div className="d-flex" style={{ justifyContent: 'center', alignItems: 'baseline' }}>
            <HasText content="En:" style={{ width: '30px' }}></HasText>
            <HasTextInput
              className="mb-2 text-center"
              style={{ maxWidth: '300px', marginLeft: '15px' }}
              onChange={(event) => this.onTitleEnChangeSelection(event.target.value)}
            />
          </div>
          <div className="d-flex" style={{ justifyContent: 'center', alignItems: 'baseline' }}>
            <HasText content="Fr:" style={{ width: '30px' }}></HasText>
            <HasTextInput
              className="mb-2 text-center"
              style={{ maxWidth: '300px', marginLeft: '15px' }}
              onChange={(event) => this.onTitleFrChangeSelection(event.target.value)}
            />
          </div>
          {usersVisibleCompany && usersCompany && isUserRoleEqualsTo(UserRole.SUPER_ADMIN) ? (
            <HasDropdown<Company>
              value={this.state.companyId ? this.state.companyId : usersCompany.id}
              size="large"
              onChange={this.onCompanyChangeSelection}
              style={{ fontSize: '14px', width: '300px', margin: 'auto', marginBottom: '10px' }}
              options={usersVisibleCompany.map<Option<Company>>((company) => ({
                value: company.id,
                label: company.name,
                forObject: company,
              }))}
            />
          ) : (
            ''
          )}
          {isUserRoleEqualsTo(UserRole.SUPER_ADMIN) ? (
            <HasCheckbox
              style={{ maxWidth: '300px', margin: 'auto' }}
              content={i18n.t('charts.visibleForCreator')}
              value={this.state.visibleForCreator}
              onChange={(event) => this.onCheckboxChangeSelection(event.target.checked)}
            />
          ) : (
            ''
          )}
        </div>
      </HasModal>
    );
  }
}

export default HasAddDashboardModal;
