import { ExclamationOutlined, SolutionOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import React, { ReactNode, ReactText } from 'react';
import { HasAvatar, HasTitle } from '../../components';
import i18n from '../../i18n/config';
import { Company, NewIncident } from '../../models';
import { ChatBotStep, COLORS, FORM_RESULT_KEYS } from '../../shared';
import { EventUtils } from '../../utils';

interface EventDetailsProps {
  event: NewIncident;
  onFormResultChange: (key: ReactText, value: string) => void;
  formSteps: ChatBotStep[] | undefined;
  formLabels: { key: string; question: string }[];
  company: Company;
}

const { Step } = Steps;

const { SUB_REASON, SUB_CAUSE } = FORM_RESULT_KEYS;

const HasEventDetails: React.FC<EventDetailsProps> = ({
  event,
  onFormResultChange,
  formSteps,
  formLabels,
  company,
}): JSX.Element => {
  const getIcon = (hasAnswer: boolean): JSX.Element => (
    <HasAvatar
      icon={
        hasAnswer ? <SolutionOutlined className="align-middle" /> : <ExclamationOutlined className="align-middle" />
      }
      size="default"
      style={{ backgroundColor: COLORS.NEW_ORANGE }}
    />
  );

  const getEventDetails = (): JSX.Element[] => {
    const steps: JSX.Element[] = [];

    event.formResult.forEach((result, index) => {
      if (![SUB_REASON, SUB_CAUSE].includes(result.key.toString())) {
        let icon: JSX.Element;
        const title = (
          <HasTitle
            key={index}
            content={i18n.t(EventUtils.getEntryTitle(formLabels, result.key))}
            level={4}
            style={{ fontSize: '12px' }}
          />
        );
        let description: ReactNode;
        if (
          !result.value ||
          (event.invalidEntryKeys && event.invalidEntryKeys.map((item) => item.key).includes(result.key))
        ) {
          description = EventUtils.getEntryValueComponent({
            event,
            formSteps: formSteps!,
            company,
            onChange: onFormResultChange,
            key: result.key,
            type: result.type,
          });
          icon = getIcon(false);
        } else {
          description = EventUtils.getEntryDescription(event.formResult, result);
          icon = getIcon(true);
        }
        steps.push(<Step key={index} title={title} description={description} icon={icon} />);
      }
    });

    return steps;
  };

  return (
    <Steps current={event.formResult.length} direction="vertical" className="px-2">
      {getEventDetails()}
    </Steps>
  );
};

export { HasEventDetails };
