import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import React from 'react';

interface OwnDatePickerProps {}

class HasDatePicker extends React.Component<OwnDatePickerProps & DatePickerProps, {}> {
  render() {
    return <DatePicker {...this.props} />;
  }
}

export default HasDatePicker;
