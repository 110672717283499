import { Form } from 'antd';
import React from 'react';
import { HasButton, HasText, HasTextArea } from '../../..';
import { ErrorMessages, TEXT_AREA_MAX_LENGTH } from '../../../../shared';
import { getElementKey } from '../../../../utils';
import i18n from '../../../../i18n/config';

interface CommentProps {
  forEntity: string;
  onSave: any;
  closeEditModeCallback?: any;
}

class HasComment extends React.Component<CommentProps, {}> {
  handleSubmit = (values: any) => {
    this.props.onSave(values[getElementKey('comment_input', this.props.forEntity)]);
    this.props.closeEditModeCallback && this.props.closeEditModeCallback();
  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} layout={'horizontal'} className="mt-2">
        <Form.Item
          name={getElementKey('comment_input', this.props.forEntity)}
          colon={false}
          rules={[
            {
              required: true,
              message: (
                <HasText content={ErrorMessages.GENERIC_INPUT_REQUIRED} type="danger" style={{ fontSize: '12px' }} />
              ),
            },
            {
              max: TEXT_AREA_MAX_LENGTH,
              message: (
                <HasText
                  content={ErrorMessages.INVESTIGATION_FREE_TEXT_LENGTH}
                  type="danger"
                  style={{ fontSize: '12px' }}
                />
              ),
            },
            {
              whitespace: true,
              message: (
                <HasText content={ErrorMessages.GENERIC_INPUT_REQUIRED} type="danger" style={{ fontSize: '12px' }} />
              ),
            },
          ]}
        >
          <HasTextArea rows={4} placeholder={i18n.t('incidents.answerPlaceholder')} />
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }} className="text-right">
          <HasButton type="primary" size="small" htmlType="submit" style={{ marginTop: '8px' }}>
            {i18n.t('incidents.post')}
          </HasButton>
        </Form.Item>
      </Form>
    );
  }
}

export default HasComment;
