import { httpClient } from '.';
import i18n from '../i18n/config';
import { Translation } from '../models';
import { ACCEPT_LANGUAGE, LANGUAGES } from '../shared';
import { buildUrl } from '../utils';
import { URL } from './rest.service';

class TranslationService {
  translate(entity: Translation) {
    const currentLanguage = LANGUAGES.find((language) => language.id === i18n.language);
    return httpClient.post<Translation>(buildUrl(URL.TRANSLATE, []), entity, {
      headers: { 'Content-Type': 'application/json', [ACCEPT_LANGUAGE]: currentLanguage?.id },
    });
  }
}

export default new TranslationService();
