import { Card, Tooltip } from 'antd';
import React, { CSSProperties } from 'react';
import i18n from '../../../i18n/config';
import { IncidentGlance, IncidentType } from '../../../models';
import { COLORS, MAIN_REPORT_TYPES } from '../../../shared';
import { formatTextToTitlecase, IncidentIcons, IncidentTrendIcon } from '../../../utils';
import { HasImage, HasText, HasTitle } from '../../atoms';

interface IncidentCardProps {
  incident: IncidentGlance;
  onClick: (type: IncidentType) => void;
  style?: CSSProperties;
  className?: string;
}

class HasIncidentCard extends React.Component<IncidentCardProps, {}> {
  render() {
    const {
      incident: { type, count, trend },
      onClick,
      style,
      className,
    } = this.props;
    return (
      <Card
        onClick={() => onClick(type)}
        className={className}
        style={{ ...style, cursor: 'pointer', display: 'flex' }}
        bodyStyle={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <HasImage
          src={IncidentIcons[type]}
          alt={formatTextToTitlecase(type.toString())}
          style={{ height: '48px', marginBottom: '0px', objectFit: 'contain' }}
          className="has-avatar"
        />
        <Tooltip
          title={i18n.t('dataDisplay.last24Months')}
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
          placement="top"
        >
          <div className="d-flex align-items-center justify-content-center">
            <HasTitle content={count} level={4} style={{ margin: 0, fontSize: '26px' }} />
          </div>
        </Tooltip>
        <Tooltip
          title={i18n.t('dataDisplay.rolling4Months')}
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
          placement="top"
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <HasText
              content={i18n.t('dataDisplay.trend')}
              style={{ fontSize: '12px', marginBottom: '5px', fontWeight: 500, color: COLORS.GRAY_DARKISH }}
            />
            {IncidentTrendIcon[trend](!MAIN_REPORT_TYPES.includes(type))}
          </div>
        </Tooltip>
      </Card>
    );
  }
}

export default HasIncidentCard;
