import { DeleteOutlined, LockOutlined, UnlockOutlined, LockFilled } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { Attachment } from '../../../models';
import { ListItemWithMetaProps, ConfirmMessages } from '../../../shared';
import { getDate, getFileIconByType, catchClickEvent } from '../../../utils';
import { HasText } from '../../atoms';
import { HasList } from '../../molecules';
import { Tooltip, Popconfirm } from 'antd';
import i18n from '../../../i18n/config';

interface AttachmentsListProps {
  attachments: Attachment[];
  onDelete: (attachment: Attachment) => void;
  onDownload: (attachment: Attachment) => void;
  onPrivateChange: (attachment: Attachment) => void;
}

class HasAttachmentsList extends React.Component<AttachmentsListProps, {}> {
  catchEventThenCallBack = (
    event: any,
    callback: ((attachment: Attachment) => void) | null,
    attachment: Attachment
  ) => {
    if (event) {
      catchClickEvent(event);
    }
    if (callback) {
      callback(attachment);
    }
  };

  getDeleteIcon = (attachment: Attachment): ReactNode => {
    return (
      <Popconfirm
        title={ConfirmMessages.DELETE_ENTITY(i18n.t('nounsWithArticle.attachment'))}
        okText={i18n.t('shared.yes')}
        cancelText={i18n.t('shared.no')}
        onConfirm={(event: any) => this.catchEventThenCallBack(event, this.props.onDelete, attachment)}
        onCancel={(event: any) => this.catchEventThenCallBack(event, null, attachment)}
        placement="top"
      >
        <DeleteOutlined
          onClick={(event: any) => this.catchEventThenCallBack(event, null, attachment)}
          style={{ verticalAlign: 'middle', padding: '20px', margin: '-20px' }}
          className="icon-hover danger"
        />
      </Popconfirm>
    );
  };

  getAttachmentActionIcons = (attachment: Attachment): ReactNode[] => {
    const icons: ReactNode[] = [];
    if (attachment.own) {
      if (attachment.private) {
        icons.push(
          <LockOutlined
            onClick={(event: any) => this.catchEventThenCallBack(event, this.props.onPrivateChange, attachment)}
            style={{ verticalAlign: 'middle', padding: '20px' }}
            className={'icon-hover primary-vibrant'}
          />
        );
      } else {
        icons.push(
          <UnlockOutlined
            onClick={(event: any) => this.catchEventThenCallBack(event, this.props.onPrivateChange, attachment)}
            style={{ verticalAlign: 'middle', padding: '20px' }}
            className={'icon-hover primary-vibrant'}
          />
        );
      }
      icons.push(this.getDeleteIcon(attachment));
    } else if (!attachment.private) {
      icons.push(this.getDeleteIcon(attachment));
    }
    return icons;
  };

  getListItemConfig = (attachment: Attachment): ListItemWithMetaProps => ({
    item: {
      actions: this.getAttachmentActionIcons(attachment),
      style: { padding: '10px 15px' },
    },
    meta: {
      avatar: (
        <div>
          {getFileIconByType(attachment.file.fileType, { verticalAlign: 'middle', fontSize: '34px' })}
          {attachment.private && !attachment.own && (
            <Tooltip title={i18n.t('attachment.isPrivate')}>
              <LockFilled
                onClick={(event: any) => this.catchEventThenCallBack(event, null, attachment)}
                style={{ position: 'absolute', marginLeft: '-20px', marginTop: '20px', fontSize: '24px' }}
                className={'icon-hover'}
              />
            </Tooltip>
          )}
        </div>
      ),
      title: <HasText content={attachment.file.fileName} className="text-break" />,
      description: getDate(attachment.uploadedAt),
    },
    onRowClick: () => this.props.onDownload(attachment),
  });

  render() {
    const { attachments } = this.props;
    return (
      <HasList<Attachment>
        listConfig={{ size: 'small', style: { width: '100%' } }}
        listItemsConfig={attachments.map((attachment) => this.getListItemConfig(attachment))}
        interactive
      />
    );
  }
}

export default HasAttachmentsList;
