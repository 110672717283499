import { LogEntry } from '../models';

function getNextSeqNo(entries: LogEntry[] | undefined): number {
  const length: number | undefined = entries?.length;
  return entries && length ? entries[length - 1].seqNo + 1 : 0;
}

export function getNewLogEntry(forEntries: LogEntry[] | undefined, value: string | undefined): LogEntry {
  return {
    value,
    seqNo: getNextSeqNo(forEntries),
    date: new Date().toISOString(),
  };
}
