import { AxiosResponse } from 'axios';
import { Observable, Subject } from 'rxjs';
import { NewPhoneNumber, PhoneNumber } from '../models';
import { SubscriberEvent } from '../shared';
import { buildUrl } from '../utils';
import { httpClient } from './http.client';
import { EntityAction, RestService, URL } from './rest.service';

class PhoneService implements RestService<PhoneNumber> {
  private usersChangedSubject = new Subject<SubscriberEvent<PhoneNumber>>();

  get(params?: any): Promise<AxiosResponse<PhoneNumber>> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<AxiosResponse<PhoneNumber[]>> {
    throw new Error('Method not implemented.');
  }

  getById(resourceId: string | number, params?: any): Promise<AxiosResponse<PhoneNumber>> {
    throw new Error('Method not implemented.');
  }

  post(body?: any, params?: any): Promise<AxiosResponse<PhoneNumber>> {
    throw new Error('Method not implemented.');
  }

  put(body?: any, params?: any): Promise<AxiosResponse<PhoneNumber>> {
    throw new Error('Method not implemented.');
  }

  create(resource: PhoneNumber): Promise<AxiosResponse<PhoneNumber>> {
    return httpClient.post<PhoneNumber>(URL.PHONE, resource);
  }

  update(resource: PhoneNumber): Promise<AxiosResponse<PhoneNumber>> {
    return httpClient.put<PhoneNumber>(URL.PHONE, resource);
  }

  delete(resourceId: string | number): Promise<AxiosResponse<PhoneNumber>> {
    return httpClient.delete<PhoneNumber>(buildUrl(URL.PHONE, [resourceId]));
  }

  getByCompany(companyId: number): Promise<AxiosResponse<PhoneNumber[]>> {
    return httpClient.get<PhoneNumber[]>(buildUrl(URL.PHONE, ['company', companyId]));
  }

  newPhoneNumber(resource: NewPhoneNumber): Promise<AxiosResponse<NewPhoneNumber>> {
    return httpClient.post<NewPhoneNumber>(URL.PHONE, resource);
  }

  // SUBSCRIPTIONS

  getPhoneNumbersChangedListener(): Observable<SubscriberEvent<PhoneNumber>> {
    return this.usersChangedSubject.asObservable();
  }

  phoneNumbersChanged(event: EntityAction, phone: PhoneNumber) {
    this.usersChangedSubject.next({ event: event, entity: phone });
  }
}

export default new PhoneService();
