import { GlobalOutlined, PlusOutlined, RightOutlined, RollbackOutlined, SolutionOutlined } from '@ant-design/icons';
import { Divider, Empty, Form, Steps, Tooltip } from 'antd';
import en_US from 'antd/lib/calendar/locale/en_US';
import { FormItemProps, FormProps } from 'antd/lib/form';
import { StepProps } from 'antd/lib/steps';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { ReactNode } from 'react';
import {
  HasAlert,
  HasAvatar,
  HasButton,
  HasCollapse,
  HasDatePicker,
  HasDropdown,
  HasEmptyState,
  HasHireTypeRadioGroup,
  HasSpinner,
  HasStatusToggle,
  HasText,
  HasTextArea,
  HasTitle,
} from '../../components';
import i18n from '../../i18n/config';
import { history as browserHistory } from '../../index';
import { Incident, IncidentType, Review, ReviewContent, ReviewHistory, ReviewStatus, User } from '../../models';
import { EntityAction, ReviewService, TranslationService } from '../../services';
import {
  COLORS,
  CollapsePanelWithContent,
  DATE_FORMAT,
  ErrorMessages,
  Option,
  TEXT_AREA_MAX_LENGTH,
} from '../../shared';
import {
  ReviewEntryFormatter,
  ReviewQuestions,
  getDate,
  getObjectValueByKey,
  getUserFullName,
  i18nLabelParser,
  isObjectEmpty,
} from '../../utils';

interface ReviewDetailsProps {
  incident: Incident;
  review: Review;
  users: User[];
  editMode: string | null;
}

interface ReviewDetailsState {
  loading: boolean;
  translationResult: { [k: string]: string };
  resultIdsTranslated: string[];
  historyEntryTranslated: {
    [k: string]: Array<string>;
  };
}

type FormBuilderParam = {
  key: keyof Review | keyof ReviewContent;
  formProps: Omit<FormProps, 'key'>;
  formItemProps: Omit<FormItemProps, 'colon' | 'style' | 'children'>;
  formComponent: JSX.Element;
};

class HasReviewDetails extends React.Component<ReviewDetailsProps, ReviewDetailsState> {
  state: ReviewDetailsState = {
    loading: true,
    translationResult: {},
    resultIdsTranslated: [],
    historyEntryTranslated: {
      comment: [],
      execSummary: [],
      riskType: [],
    },
  };

  private doNotTranslate: Record<string, boolean> = { riskType: true };

  getSaveButton = () => (
    <Form.Item style={{ marginRight: 0, marginBottom: '10px', float: 'right' }}>
      <HasButton htmlType="submit" type="primary" size="small">
        {i18n.t('shared.save')}
      </HasButton>
    </Form.Item>
  );

  componentDidMount() {
    const { review, incident } = this.props;
    if (review && !isObjectEmpty(review)) {
      if (!review.reviewContent) {
        const emptyContent: ReviewContent = { comment: '', execSummary: '' };
        if (incident.type === IncidentType.HOUSE_KEEPING) {
          emptyContent.riskType = '';
        }
        ReviewService.reviewChanged(EntityAction.UPDATE, { ...review, reviewContent: JSON.stringify(emptyContent) });
      } else {
        let reviewContent: ReviewContent = { ...JSON.parse(this.props.review.reviewContent) };
        if (incident.type === IncidentType.HOUSE_KEEPING) {
          if (reviewContent.riskType === null || reviewContent.riskType === undefined) {
            reviewContent.riskType = '';
            ReviewService.reviewChanged(EntityAction.UPDATE, {
              ...review,
              reviewContent: JSON.stringify(reviewContent),
            });
          }
        }
      }
    }
    this.setState({ loading: false });
  }

  handleHistoricalValues = (field: keyof Review | keyof ReviewContent): ReviewHistory => {
    const history: ReviewHistory = JSON.parse(this.props.review.history);
    const { review } = this.props;

    if (['reviewBy', 'reviewDate'].includes(field)) {
      switch (field) {
        case 'reviewBy':
          if (history?.[field]?.length) {
            const updateField = history?.[field]?.concat({
              value: review.reviewBy.fullName,
            });
            return { ...history, [field]: updateField };
          } else {
            return review.reviewBy ? { ...history, [field]: [{ value: review?.reviewBy?.fullName }] } : history;
          }
        case 'reviewDate':
          if (history?.[field]?.length) {
            const updateField = history?.[field as keyof ReviewHistory]?.concat({
              value: getDate(review?.reviewDate),
            });
            return { ...history, [field]: updateField };
          } else {
            return review.reviewDate ? { ...history, [field]: [{ value: getDate(review.reviewDate) }] } : history;
          }
        default:
          return {};
      }
    } else {
      const reviewContent: ReviewContent = JSON.parse(review.reviewContent);
      switch (field) {
        case 'execSummary': {
          if (history?.[field]?.length) {
            const updateField = history?.[field as keyof ReviewHistory]?.concat({
              value: reviewContent?.execSummary?.toString(),
            });
            return { ...history, [field]: updateField };
          } else {
            return reviewContent.execSummary
              ? { ...history, [field]: [{ value: reviewContent.execSummary }] }
              : history;
          }
        }
        case 'comment': {
          if (history?.[field]?.length) {
            const updateField = history?.[field as keyof ReviewHistory]?.concat({
              value: reviewContent?.comment?.toString(),
            });
            return { ...history, [field]: updateField };
          } else {
            return reviewContent.comment ? { ...history, [field]: [{ value: reviewContent.comment }] } : history;
          }
        }
        case 'riskType': {
          if (history?.[field]?.length && reviewContent.riskType) {
            const updateField = history?.[field as keyof ReviewHistory]?.concat({
              value: reviewContent?.riskType,
            });
            return { ...history, [field]: updateField };
          } else {
            return reviewContent.riskType ? { ...history, [field]: [{ value: reviewContent.riskType }] } : history;
          }
        }
        default:
          return {};
      }
    }
  };

  reviewFieldChanged = (field: keyof Review, value: any) => {
    const history: ReviewHistory = this.handleHistoricalValues(field);
    const updatedReview = Object.assign({ ...this.props.review, [field]: value, history: JSON.stringify(history) }, {});
    ReviewService.reviewChanged(EntityAction.UPDATE, updatedReview);
  };

  reviewContentFieldChanged = (field: keyof ReviewContent, value: any) => {
    const history: ReviewHistory = this.handleHistoricalValues(field);
    let reviewContent: ReviewContent = { ...JSON.parse(this.props.review.reviewContent), [field]: value };
    const updatedReview = Object.assign(
      { ...this.props.review, reviewContent: JSON.stringify(reviewContent), history: JSON.stringify(history) },
      {}
    );
    ReviewService.reviewChanged(EntityAction.UPDATE, updatedReview);
  };

  createForm = (formConfig: FormBuilderParam): JSX.Element => {
    const { key, formProps, formItemProps, formComponent } = formConfig;
    return (
      <Form {...formProps} key={key}>
        <Form.Item {...formItemProps} colon={false} style={{ marginBottom: '0px' }}>
          {formComponent}
        </Form.Item>
        {this.getSaveButton()}
      </Form>
    );
  };

  getReviewQuestionForm = (key: keyof ReviewContent | keyof Review, value: string): ReactNode => {
    const { review, users } = this.props;
    switch (key) {
      case 'reviewDate':
        return this.createForm({
          key,
          formProps: {
            onFinish: (values) => this.reviewFieldChanged(key, new Date(values.reviewDate).toISOString()),
            initialValues: { reviewDate: review.reviewDate ? moment(review.reviewDate) : null },
            className: 'd-flex flex-row justify-content-between',
            layout: 'inline',
          },
          formItemProps: {
            name: 'reviewDate',
            rules: [
              {
                required: true,
                message: (
                  <HasText
                    content={ErrorMessages.INPUT_REQUIRED(i18n.t('review.reviewDate').toLowerCase())}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
            ],
          },
          formComponent: (
            <HasDatePicker
              locale={{ ...en_US, lang: { ...en_US.lang, today: i18n.t('shared.today') } }}
              placeholder={i18n.t('incidents.selectReviewDate')}
              style={{ width: '180px' }}
              format={DATE_FORMAT}
            />
          ),
        });
      case 'reviewBy':
        return isEmpty(users) ? (
          <HasAlert message={i18n.t('action.noUsersAvailable')} type="warning" showIcon />
        ) : (
          this.createForm({
            key,
            formProps: {
              onFinish: (values) =>
                this.reviewFieldChanged(key, this.props.users.filter((user) => user.id === values.reviewBy)[0]),
              initialValues: { reviewBy: review.reviewBy?.id },
              className: 'd-flex flex-row justify-content-between',
              layout: 'inline',
            },
            formItemProps: {
              name: 'reviewBy',
              rules: [
                {
                  required: true,
                  message: (
                    <HasText
                      content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.reviewUser').toLowerCase())}
                      type="danger"
                      style={{ fontSize: '12px' }}
                    />
                  ),
                },
              ],
            },
            formComponent: (
              <HasDropdown<User>
                placeholder={i18n.t('incidents.selectUser')}
                style={{ width: '180px' }}
                options={users.map<Option<User>>((user) => ({
                  value: user.id,
                  label: getUserFullName(user),
                  forObject: user,
                }))}
              />
            ),
          })
        );
      case 'execSummary':
        return this.createForm({
          key,
          formProps: {
            onFinish: (values) => this.reviewContentFieldChanged(key, values.execSummary),
            initialValues: { execSummary: value },
            layout: 'vertical',
          },
          formItemProps: {
            name: 'execSummary',
            rules: [
              {
                required: true,
                message: (
                  <HasText
                    content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.summary').toLowerCase())}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
              {
                whitespace: true,
                message: (
                  <HasText
                    content={ErrorMessages.INPUT_REQUIRED(i18n.t('shared.summary').toLowerCase())}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
              {
                max: TEXT_AREA_MAX_LENGTH,
                message: (
                  <HasText
                    content={ErrorMessages.INVESTIGATION_FREE_TEXT_LENGTH}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
            ],
          },
          formComponent: <HasTextArea rows={3} placeholder={i18n.t('incidents.answerPlaceholder')} />,
        });
      case 'comment':
        return this.createForm({
          key,
          formProps: {
            onFinish: (values) => this.reviewContentFieldChanged(key, values.comment),
            initialValues: { comment: value },
            layout: 'vertical',
          },
          formItemProps: {
            name: 'comment',
            rules: [
              {
                required: true,
                message: (
                  <HasText
                    content={ErrorMessages.INPUT_REQUIRED(i18n.t('review.comments').toLowerCase())}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
              {
                whitespace: true,
                message: (
                  <HasText
                    content={ErrorMessages.INPUT_REQUIRED(i18n.t('review.comments').toLowerCase())}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
              {
                max: TEXT_AREA_MAX_LENGTH,
                message: (
                  <HasText
                    content={ErrorMessages.INVESTIGATION_FREE_TEXT_LENGTH}
                    type="danger"
                    style={{ fontSize: '12px' }}
                  />
                ),
              },
            ],
          },
          formComponent: <HasTextArea rows={3} placeholder={i18n.t('incidents.answerPlaceholder')} />,
        });
      case 'riskType':
        return (
          <HasHireTypeRadioGroup
            save={(answer) => this.reviewContentFieldChanged(key, answer)}
            previousValues={value}
          />
        );
    }
  };
  handleTranslationForEntry = (key?: string, entryValue?: string) => {
    if (key && entryValue) {
      if (key && this.state.resultIdsTranslated.includes(key)) {
        this.setState((previousState) => {
          delete previousState.translationResult[key];
          return {
            ...previousState,
            resultIdsTranslated: previousState.resultIdsTranslated.filter((existingId) => existingId !== key),
          };
        });
      } else {
        let translation = '';
        TranslationService.translate({ textToTranslate: entryValue }).then((result) => {
          translation = result.data.translatedText!;

          this.setState((previousState) => {
            previousState.resultIdsTranslated.push(key);

            return {
              ...previousState,
              translationResult: { ...previousState.translationResult, [key]: translation },
            };
          });
        });
      }
    }
  };

  getReviewContent = (
    key: keyof ReviewContent | keyof Review,
    value: string,
    translateOptions?: {
      onShowTranslation?: (key?: string, id?: string) => void;
      translationResult: { [k: string]: string };
      resultIdsTranslated: string[];
    }
  ): ReactNode => {
    let isEntryTranslated = false;
    if (key && translateOptions) {
      isEntryTranslated = translateOptions.resultIdsTranslated.includes(key);
    }

    let showTranslateButton = !!value;

    return (
      <>
        <div style={{ display: 'grid', gridTemplateColumns: '95% 5%' }}>
          <div className="d-flex flex-row justify-content-end">
            <HasText
              content={
                isEntryTranslated
                  ? key
                    ? isEntryTranslated === true
                      ? translateOptions?.translationResult[key]
                      : 'false'
                    : i18nLabelParser(value)
                  : i18nLabelParser(value)
              }
              style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
            />
          </div>
          <div className="d-flex flex-row justify-content-end">
            {showTranslateButton && translateOptions && !translateOptions.resultIdsTranslated.includes(key!) && (
              <Tooltip title={i18n.t('incidents.translate')}>
                <GlobalOutlined
                  onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(key, value)}
                  className="align-middle"
                  style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                />
              </Tooltip>
            )}
            {showTranslateButton && translateOptions?.resultIdsTranslated.includes(key!) && (
              <Tooltip title={i18n.t('incidents.showOriginal')}>
                <RollbackOutlined
                  onClick={() => translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(key, value)}
                  className="align-middle"
                  style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </>
    );
  };

  getCollapsePanelConfigs = (history: ReviewHistory, key: any): CollapsePanelWithContent[] => [
    {
      key: 'review_old_entries',
      header: (
        <HasText
          content={i18n.t('dataDisplay.moreEntries', {
            count: history?.[key as keyof ReviewHistory]?.length,
          })}
          strong
          style={{ fontSize: '12px' }}
        />
      ),
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 8 }}>
          {history &&
            ['riskType'].includes(key) &&
            history.riskType?.map((item, index) => {
              const riskTypeArray = item.value.split(',');
              const riskTypeLabel = riskTypeArray.map((item) => ` ${i18n.t(item.replace('$$$', ''))}`).join();
              return (
                <div
                  style={{ display: 'grid', alignItems: 'flex-end', gridTemplateColumns: '95% 5%' }}
                  key={`${item.value}_${index}`}
                >
                  <div className={'d-flex flex-row justify-content-end'}>
                    <HasText content={riskTypeLabel} key={index} style={{ fontSize: '12px', marginLeft: 4 }} />
                  </div>
                </div>
              );
            })}
          {history &&
            ['comment', 'execSummary', 'reviewBy', 'reviewDate'].includes(key) &&
            history?.[key as keyof ReviewHistory]?.map((item, index) => (
              <div style={{ display: 'grid', gridTemplateColumns: '95% 5%' }} key={`${item.value}_${index}`}>
                <div
                  className={
                    ['comment', 'execSummary'].includes(key) ? 'd-flex flex-row justify-content-end' : undefined
                  }
                >
                  <HasText
                    content={
                      this.state.historyEntryTranslated[key] && this.state.historyEntryTranslated[key][index]
                        ? this.state.historyEntryTranslated[key][index]
                        : item.value
                    }
                    key={index}
                    style={{ fontSize: '12px' }}
                  />
                </div>
                {['comment', 'execSummary'].includes(key) && (
                  <div className="d-flex flex-row justify-content-end">
                    {!this.state.historyEntryTranslated[key]?.[index] ? (
                      <Tooltip title={i18n.t('incidents.translate')}>
                        <GlobalOutlined
                          key={index}
                          onClick={() => {
                            TranslationService.translate({ textToTranslate: item.value }).then((result) => {
                              this.setState((prevState) => {
                                const prevArray = prevState.historyEntryTranslated[key];
                                prevArray[index] = result.data.translatedText!;
                                return {
                                  historyEntryTranslated: {
                                    ...prevState.historyEntryTranslated,
                                    [key]: prevArray,
                                  },
                                };
                              });
                            });
                          }}
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={i18n.t('incidents.showOriginal')}>
                        <RollbackOutlined
                          key={index}
                          onClick={() => {
                            this.setState((prevState) => {
                              const prevArray = prevState.historyEntryTranslated[key];
                              prevArray[index] = '';
                              return {
                                historyEntryTranslated: {
                                  ...prevState.historyEntryTranslated,
                                  [key]: prevArray,
                                },
                              };
                            });
                          }}
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      ),
      className: 'has-collapse has-collapse-panel',
    },
  ];

  getReviewQuestions = (): StepProps[] => {
    const { review, editMode } = this.props;
    const isEdit = editMode && JSON.parse(editMode);
    const reviewContent: ReviewContent = JSON.parse(review.reviewContent);
    const history: ReviewHistory = JSON.parse(review.history);

    const commonQuestions = Object.keys(review)
      .filter((key) => !['id', 'attachments', 'incidentId', 'status', 'reviewContent', 'history'].includes(key))
      .map((key: any) => {
        const value = getObjectValueByKey(review, key);
        const icon = (
          <HasAvatar
            icon={<SolutionOutlined style={{ verticalAlign: 'middle' }} />}
            size="default"
            style={{ backgroundColor: value ? COLORS.NEW_ORANGE : COLORS.ERROR }}
          />
        );
        const title = (
          <HasTitle
            content={i18n.t(ReviewQuestions[key]).toLocaleUpperCase('en-GB')}
            level={4}
            style={{ fontSize: '12px' }}
          />
        );
        const description = (
          <React.Fragment>
            {history?.[key as keyof ReviewHistory] && (
              <HasCollapse
                bordered={false}
                expandIcon={(panelProps: any) => (
                  <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ verticalAlign: 'middle' }} />
                )}
                panelConfigs={this.getCollapsePanelConfigs(history, key)}
                className="has-collapse"
              />
            )}
            {isEdit ? (
              this.getReviewQuestionForm(key, '')
            ) : (
              <HasText
                content={value ? i18nLabelParser(ReviewEntryFormatter[key](value)) : i18n.t('incidents.noEntry')}
                style={{ fontSize: '12px' }}
              />
            )}
            <Divider style={{ margin: '16px 0 8px 0' }} />
          </React.Fragment>
        );
        return {
          title: title,
          description: description,
          icon: icon,
        };
      });
    const reviewContentQuestions = Object.keys(reviewContent).map((key: any) => {
      const value = getObjectValueByKey(reviewContent, key);
      const icon = (
        <HasAvatar
          icon={<SolutionOutlined style={{ verticalAlign: 'middle' }} />}
          size="default"
          style={{ backgroundColor: value ? COLORS.NEW_ORANGE : COLORS.ERROR }}
        />
      );
      const title = (
        <HasTitle
          content={i18n.t(ReviewQuestions[key]).toLocaleUpperCase('en-GB')}
          level={4}
          style={{ fontSize: '12px' }}
        />
      );
      const description = (
        <React.Fragment>
          {history?.[key as keyof ReviewHistory] && (
            <HasCollapse
              bordered={false}
              expandIcon={(panelProps: any) => (
                <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ verticalAlign: 'middle' }} />
              )}
              panelConfigs={this.getCollapsePanelConfigs(history, key)}
              className="has-collapse"
            />
          )}
          {isEdit
            ? this.getReviewQuestionForm(key, value)
            : this.getReviewContent(
                key,
                value,
                this.doNotTranslate[key]
                  ? undefined
                  : {
                      onShowTranslation: this.handleTranslationForEntry,
                      translationResult: this.state.translationResult,
                      resultIdsTranslated: this.state.resultIdsTranslated,
                    }
              )}
          <Divider style={{ margin: '16px 0 8px 0' }} />
        </React.Fragment>
      );
      return {
        title: title,
        description: description,
        icon: icon,
      };
    });
    return [...commonQuestions, ...reviewContentQuestions];
  };

  reviewStatusChanged = (checked: boolean) => {
    const { review } = this.props;
    review.status = checked ? ReviewStatus.OPEN : ReviewStatus.CLOSED;
    ReviewService.reviewChanged(EntityAction.REFRESH, review);
  };

  createNewReview = () => {
    this.setState({ loading: true });
    const { incident } = this.props;
    const emptyContent: ReviewContent = { comment: '', execSummary: '' };
    if (incident.type === IncidentType.HOUSE_KEEPING) {
      emptyContent.riskType = '';
    }
    ReviewService.createEmptyReview({
      status: ReviewStatus.OPEN,
      incidentId: incident.id,
      reviewContent: JSON.stringify(emptyContent),
    })
      .then(() => ReviewService.reviewChanged(EntityAction.CREATE, {} as Review))
      .finally(() => this.setState({ loading: false }));
  };

  isReviewClosable = (): boolean => {
    const {
      review: { reviewDate, reviewBy, reviewContent },
      incident,
    } = this.props;
    if (this.props.review && !isObjectEmpty(this.props.review)) {
      const { execSummary, riskType }: ReviewContent = JSON.parse(reviewContent);
      return (
        reviewDate !== null &&
        reviewBy !== null &&
        execSummary !== '' &&
        (incident.type === IncidentType.HOUSE_KEEPING ? riskType !== '' : true)
      );
    } else {
      return false;
    }
  };

  render() {
    const { incident, review, editMode } = this.props;
    const { loading } = this.state;
    const { Step } = Steps;
    const isClosable = this.isReviewClosable();
    const isEdit = editMode && JSON.parse(editMode);
    return loading ? (
      <HasSpinner size="large" />
    ) : isObjectEmpty(review) ? (
      <HasEmptyState description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <HasTitle
          content={i18n.t('incidents.noReview')}
          level={4}
          style={{ fontSize: '14px', color: COLORS.BLACK_RGBA(0.45), textAlign: 'center' }}
        />
        <HasButton
          type="primary"
          onClick={this.createNewReview}
          icon={<PlusOutlined style={{ verticalAlign: 'middle' }} />}
        >
          {i18n.t('incidents.newReview')}
        </HasButton>
      </HasEmptyState>
    ) : (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            flexShrink: 0,
          }}
        >
          <div className={'ant-descriptions-title'}>{i18n.t('incidents.eventReview')}</div>
          {!isEdit && (
            <Tooltip
              title={i18n.t('incidents.notAllAnswered')}
              overlayStyle={{ fontSize: '12px', display: isClosable ? 'none' : 'auto' }}
            >
              <div>
                <HasStatusToggle
                  disabled={!isClosable}
                  isChecked={review.status === ReviewStatus.OPEN}
                  onChange={this.reviewStatusChanged}
                  leftLabel={i18n.t('incidents.closed')}
                  rightLabel={i18n.t('incidents.open')}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <Steps type="default" current={4} size="default" direction="vertical">
          {this.getReviewQuestions().map((config, index) => (
            <Step key={index} {...config} />
          ))}
        </Steps>
        <div style={{ alignSelf: 'flex-end' }}>
          {isEdit ? (
            <HasButton type="primary" onClick={() => browserHistory.push({ pathname: `${incident.id}` })}>
              {i18n.t('shared.done')}
            </HasButton>
          ) : (
            <HasButton
              type="primary"
              onClick={() => browserHistory.push({ pathname: `${incident.id}`, search: '?edit=true' })}
            >
              {i18n.t('shared.edit')}
            </HasButton>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HasReviewDetails;
