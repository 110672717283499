import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { HasCoordinatedDropdowns, HasText } from '../../components';
import { Company, Division } from '../../models';
import { ErrorMessages, Option } from '../../shared';

interface CompanyAndDivisionSelectorProps extends WithTranslation {
  companies: Company[];
  onFinish: (company: Company, division: Division) => void;
}

interface CompanyAndDivisionSelectorState {
  divisionOptions: Option<Division>[];
  selectedCompany: Company;
  selectedDivision: Division;
}

class HasCompanyAndDivisionSelector extends React.Component<
  CompanyAndDivisionSelectorProps,
  CompanyAndDivisionSelectorState
> {
  state: CompanyAndDivisionSelectorState = {
    divisionOptions: [],
    selectedCompany: {} as Company,
    selectedDivision: {} as Division,
  };

  componentDidMount() {
    const { companies } = this.props;
    const selectedCompany = companies[0];
    const selectedDivision = this.getFirstDivisionForCompany(selectedCompany);
    const divisionOptions = this.buildDivisionOptions(selectedCompany);
    this.setState({ selectedCompany, selectedDivision, divisionOptions });
  }

  getFirstDivisionForCompany = ({ divisions }: Company): Division => {
    return divisions.length > 0 ? divisions[0] : ({} as Division);
  };

  buildDivisionOptions = ({ divisions }: Company): Option<Division>[] => {
    return divisions.map((division) => ({ value: division.id, label: division.name, forObject: division }));
  };

  onCompanyChange = (value: SelectValue, { forObject: selectedCompany }: Option<Company>) => {
    const selectedDivision = selectedCompany.divisions.length > 0 ? selectedCompany.divisions[0] : ({} as Division);
    this.setState({
      selectedCompany,
      selectedDivision,
      divisionOptions: this.buildDivisionOptions(selectedCompany),
    });
  };

  onDivisionChange = (value: SelectValue, { forObject: selectedDivision }: Option<Division>) => {
    this.setState({ selectedDivision });
  };

  onFinish = (values: any) => {
    const { onFinish } = this.props;
    const { selectedCompany, selectedDivision } = this.state;
    onFinish(selectedCompany, selectedDivision);
  };

  render() {
    const { companies, t } = this.props;
    const { selectedCompany, selectedDivision, divisionOptions } = this.state;
    return companies.length > 0 ? (
      <HasCoordinatedDropdowns<Company, Division>
        asForm
        parentDropdownProps={{
          placeholder: t('report.selectCompany'),
          options: companies.map((company) => ({ value: company.id, label: company.name, forObject: company })),
          onChange: this.onCompanyChange,
          style: { minWidth: '180px' },
        }}
        childDropdownProps={{
          placeholder: t('report.selectDivision'),
          options: divisionOptions,
          onChange: this.onDivisionChange,
          style: { minWidth: '180px' },
        }}
        formProps={{
          layout: 'vertical',
          hideRequiredMark: true,
          initialValues: { company: selectedCompany.id, division: selectedDivision.id },
          fields: [
            { name: 'company', value: selectedCompany.id },
            { name: 'division', value: selectedDivision.id },
          ],
          onFinish: this.onFinish,
        }}
        parentFromItemProps={{
          name: 'company',
          label: t('shared.company'),
          colon: false,
          rules: [
            {
              required: true,
              message: (
                <HasText
                  content={ErrorMessages.SELECT_REQUIRED(t('shared.company').toLowerCase())}
                  type="danger"
                  style={{ fontSize: '12px' }}
                />
              ),
            },
          ],
        }}
        childFormItemProps={{
          name: 'division',
          label: t('shared.division'),
          colon: false,
          dependencies: ['company'],
          rules: [
            {
              required: true,
              message: (
                <HasText
                  content={ErrorMessages.SELECT_REQUIRED(t('shared.division').toLowerCase())}
                  type="danger"
                  style={{ fontSize: '12px' }}
                />
              ),
            },
          ],
        }}
        submitButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          block: true,
        }}
        submitButtonText={t('shared.done')}
      />
    ) : (
      <div>
        <HasText content={t('report.noAvailableCompanies')} />
      </div>
    );
  }
}

export default withTranslation()(HasCompanyAndDivisionSelector);
