import { AttachmentService } from '.';
import { URL } from '../rest.service';

class IncidentAttachmentService extends AttachmentService {
  protected entityURL = URL.INCIDENT;
  protected uploadSubURL = 'attachDocument';
  protected attachmentURL = URL.INCIDENT_ATTACHMENT;
}

export default new IncidentAttachmentService();
